const groupRiskDetections = (inputRiskDetections) => {
	let grouped = {};
	const keys = ["riskEventType", "riskLevel"];

	inputRiskDetections.forEach((item) => {
		keys.reduce((previousValue, currentValue, currentIndex) => {
			previousValue[item[currentValue]] =
				previousValue[item[currentValue]] || (currentIndex + 1 === keys.length ? [] : {});
			return previousValue[item[currentValue]];
		}, grouped).push(item);
	});
	return grouped;
};

export { groupRiskDetections };
// from https://stackoverflow.com/questions/35506433/grouping-by-multiple-fields-per-object
