import {
	Box,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import * as React from "react";
import { TabPanel } from "./Shared";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import styles from "./RequestForm.module.scss";

export interface ISecurityProps {
	activeTabIndex: number;
	privacy: string;
	setPrivacy: Function;
	sensitivity: string;
	setSensitivity: Function;
}

export const Security = (props: ISecurityProps) => {
	const { setPrivacy, setSensitivity, activeTabIndex, privacy, sensitivity } = props;
	return (
		<TabPanel value={activeTabIndex} index={3}>
			<Box>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="body2" className={styles.formRow}>
						Select the privacy & security settings for your workspace...
					</Typography>
					<Typography className={styles.steps}>(step 4/4)</Typography>
				</Stack>
				<Divider variant="middle" className={styles.devider} />
				<FormControl>
					<Typography className={styles.sectionRow} variant="body2">
						Privacy settings <span className={styles.asterisk}>*</span>
					</Typography>
					<RadioGroup
						defaultValue="private"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setPrivacy((event.target as HTMLInputElement).value);
						}}
					>
						<Stack
							direction="row"
							className={styles.formRow}
							justifyContent="flex-start"
							alignItems="flex-start"
						>
							<span className={styles.labelWrapper}>
								<FormControlLabel
									value="private"
									control={<Radio checked={privacy === "private"} />}
									label={
										<Typography className={styles.formLabelWrapper}>
											Private
										</Typography>
									}
									className={styles.formLabel}
								/>
							</span>
							<Typography className={styles.helpText}>
								Private Teams aren't open for everyone to join them. These Teams
								won't show up in search results or in the Teams gallery. Owners must
								approve access.
							</Typography>
						</Stack>
						<Stack direction="row">
							<span className={styles.labelWrapper}>
								<FormControlLabel
									value="public"
									control={<Radio checked={privacy === "public"} />}
									label={
										<Typography className={styles.formLabelWrapper}>
											Public
										</Typography>
									}
									className={styles.formLabel}
								/>
							</span>
							<Typography className={styles.helpText}>
								Public Teams can be seen by everyone from the Teams gallery and
								people can join them without getting approval from a team owner.
							</Typography>
						</Stack>
					</RadioGroup>
				</FormControl>
			</Box>
			<Divider variant="middle" className={styles.divider} />
			<Box>
				<FormControl className={styles.sectionRow}>
					<Typography className={styles.formRow} variant="body2">
						Sensitivity classification
						<span className={styles.asterisk}>*</span>
					</Typography>
					<RadioGroup
						defaultValue="nonSensitiveUnrestricted"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setSensitivity((event.target as HTMLInputElement).value);
						}}
					>
						<FormControlLabel
							value="nonSensitiveUnrestricted"
							control={<Radio checked={sensitivity === "nonSensitiveUnrestricted"} />}
							label={
								<Typography className={styles.formLabelWrapperWide}>
									Non-sensitive (Unrestricted)
									<Tooltip title="Business data that is specifically prepared and approved for public consumption.">
										<IconButton>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</Typography>
							}
						/>
						<FormControlLabel
							value="nonSensitiveInternal"
							control={<Radio checked={sensitivity === "nonSensitiveInternal"} />}
							label={
								<Typography className={styles.formLabelWrapperWide}>
									Non-sensitive (Internal)
									<Tooltip title="Business data that isn’t intended for public consumption. If you need to share this content with external partners, confirm with other data owners that it's OK to share and then change the label to Non-sensitive (Unrestricted) . Examples include a company internal telephone directory, organizational charts, internal standards, and most internal communication.">
										<IconButton>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</Typography>
							}
						/>
						<FormControlLabel
							value="sensitive"
							control={<Radio checked={sensitivity === "sensitive"} />}
							label={
								<Typography className={styles.formLabelWrapperWide}>
									Sensitive
									<Tooltip title="Sensitive business data that could cause damage to the business if shared with unauthorized people. Examples include contracts, security reports, forecast summaries, and sales account data.">
										<IconButton>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</Typography>
							}
						/>
						<FormControlLabel
							value="highlySensitive"
							control={<Radio checked={sensitivity === "highlySensitive"} />}
							label={
								<Typography className={styles.formLabelWrapperWide}>
									Highly sensitive
									<Tooltip title="Very sensitive business data that would cause damage to the business if it was shared with unauthorized people. Examples include employee and customer information, passwords, source code, and pre-announced financial reports.">
										<IconButton>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</Typography>
							}
						/>
					</RadioGroup>
				</FormControl>
			</Box>
		</TabPanel>
	);
};
