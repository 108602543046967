import { useMemo, useState } from "react";
import { CircularProgress, TextField, Checkbox, Chip, Autocomplete } from "@mui/material";
import { ConfiguratorUserTypes } from "utilities/constants/enums";
import { debounce } from "utilities/debounce";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import _ from "lodash";

import styles from "./QueryableDropdownSelector.module.scss";

const QueryableDropdownSelector = ({
	configuratorUserType,
	currentSelectedItems,
	customOptions = [],
	handleOnClick,
	fetchData,
}) => {
	const defaultValues =
		configuratorUserType === ConfiguratorUserTypes.Creators
			? [
					{
						displayName: "Everyone except external users",
						type: "GROUP",
						id: "EVERYONE_EXCEPT_EXTERNAL_USERS",
					},
			  ]
			: [];

	const [values, setValues] = useState(
		_.isEmpty(currentSelectedItems) ? defaultValues : currentSelectedItems,
	);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState(_.union(defaultValues, customOptions, values));

	const onHandleSearch = ({ currentTarget: { value } }) => {
		debouncedSearch(value);
	};

	const setGroupingProperty = (option) => {
		if (option.type === "ROLE") {
			return "Roles";
		}
		if (option.type === "GROUP" || option.displayName.startsWith("IST-")) {
			return "Groups";
		}
		return "Users";
	};

	const prepareOptions = (options) => {
		const grouped = options.map((option) => {
			return {
				...option,
				groupingType: setGroupingProperty(option),
			};
		});
		const sorted = _.sortBy(grouped, "groupingType");
		const unique = _.uniqBy(sorted, "displayName");
		return unique;
	};

	const debouncedSearch = useMemo(
		() => debounce((searchValue) => initializeSearch(searchValue), 750),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const initializeSearch = async (searchValue) => {
		setLoading(true);
		const fetchedOptions = await fetchData(searchValue);
		// add the fetched options to the list of options if it doesn't exist in the options list
		const newOptions = fetchedOptions?.filter(
			(option) => !options.some((o) => o.displayName === option.displayName),
		);
		setOptions([...options, ...newOptions]);
		setLoading(false);
	};

	const handleSetValues = (newValue) => {
		let updatedSelectedItems = [];
		const exitsInValues = values.some((currentValue) => currentValue.id === newValue.id);

		if (exitsInValues) {
			updatedSelectedItems = values.filter(
				(currentCreator) => currentCreator.id !== newValue.id,
			);
		} else {
			updatedSelectedItems = values.concat(newValue);
		}
		setValues(updatedSelectedItems);
		handleOnClick(updatedSelectedItems);
	};

	const clearValues = () => {
		setValues([]);
		handleOnClick([]);
	};

	return (
		<Autocomplete
			data-testid="dropdown.options"
			id="queryable-dropdown-selector"
			multiple
			open={open}
			limitTags={1}
			onOpen={() => {
				initializeSearch("");
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			value={values}
			groupBy={(option) => option.groupingType}
			options={prepareOptions(options)}
			disableCloseOnSelect
			isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
			fullWidth
			onChange={clearValues}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						key={index}
						variant="outlined"
						label={option.displayName}
						size="small"
						{...getTagProps({ index })}
						onDelete={() => {
							handleSetValues(option);
						}}
					/>
				))
			}
			getOptionLabel={(option) => option.displayName}
			renderOption={(props, option, { selected }) => (
				<div onClick={() => handleSetValues(option)} className={styles.dropDown}>
					<Checkbox
						icon={
							<CheckBoxOutlineBlankIcon
								className={styles.checkboxDropdown}
								fontSize="small"
							/>
						}
						checkedIcon={
							<CheckBoxIcon className={styles.checkboxDropdown} fontSize="small" />
						}
						checked={selected}
					/>
					{option.prettyDisplayName ?? option.displayName}
				</div>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					size="small"
					onChange={onHandleSearch}
					className={styles.textField}
					InputProps={{
						style: {
							fontSize: 15,
							minHeight: 50,
						},
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color="inherit" size={20} />
								) : (
									params.InputProps.endAdornment
								)}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

export { QueryableDropdownSelector };
