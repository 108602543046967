import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { PrimaryButton } from "components/Common/Buttons/Button";
import styles from "./CompanyDetails.module.scss";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { selectRequesters } from "features/requesters";
import { fetchRequesters } from "actions/requesterActions";
import { selectUsers, usersSelectors } from "features/users";
import { selectApprovers, selectCustomerDetails } from "features/customer";
import {
	fetchAppRoleAssignments,
	updateAppRoleAssignments,
	updateCustomerInfo,
} from "actions/customerActions";
import { fetchUsers, sendMail } from "actions/userActions";
import { appRoleAssignmentsSelectors, selectAppRoleAssignments } from "features/appRoleAssignments";
import { PortalRoles } from "./PortalRoles";
import {
	AppRoleAssignment,
	CompanyApprover,
	CustomerInfo,
	HardwareApproverObject,
	Requester,
	User,
} from "types";
import useRole from "utilities/roleUtils/roleCheck";
import { HardwareApprovers } from "./HardwareApprovers";
import CompanyRoles from "./CompanyRoles/CompanyRoles";
import { updateHardwareApprovers } from "actions/hardwareApproversActions";
import { selectHardwareApprovers } from "features/hardware/hardwareApprovers";
import { hardwareApproverRoleDescription } from "./HardwareApprovers/hardwareApproverUtils";
import _ from "lodash";

export const CompanyDetails = () => {
	const {
		dispatch,
		auth,
		auth: { account },
	} = useAuth();
	const { isAdmin } = useRole();

	useApiOnce(fetchAppRoleAssignments, useAppSelector(selectAppRoleAssignments));
	useApiOnce(fetchUsers, useAppSelector(selectUsers));
	useApiOnce(fetchRequesters, useAppSelector(selectRequesters));
	const approversState = useAppSelector(selectHardwareApprovers);

	const customerInfo = useAppSelector(selectCustomerDetails) as CustomerInfo;
	const originalAppRoleAssignments = useAppSelector(appRoleAssignmentsSelectors.selectEntities);

	// Company roles
	const [selectedDepartmentHead, setSelectedDepartmentHead] = useState<Requester>({
		mail: customerInfo?.headUserEmail ?? "",
	} as Requester);
	const [selectedPrimeUser, setSelectedPrimeUser] = useState<Requester>({
		mail: customerInfo?.primeUserEmail ?? "",
	} as Requester);
	const [selectedCompanyApprovers, setSelectedCompanyApprovers] = useState<CompanyApprover[]>(
		customerInfo.approvers ?? [],
	);

	// Portal roles
	const [roleAssignments, setRoleAssignments] = useState<AppRoleAssignment[]>(
		[] as AppRoleAssignment[],
	);
	useEffect(() => {
		setRoleAssignments(Object.values(originalAppRoleAssignments) as AppRoleAssignment[]);
	}, [originalAppRoleAssignments]);

	// Hardware approvers
	// Fill in state from hardware approvers here, as its needed for the save button
	const [selectedHardwareApprovers, setSelectedHardwareApprovers] =
		useState<HardwareApproverObject>({
			global: [],
			manager: [],
		} as HardwareApproverObject);

	// States that keep track of any changes made to the company roles
	const [hardwareApprovesEqualOriginal, setHardwareApprovesEqualOriginal] = useState(true);
	const [companyRolesEqualOriginal, setCompanyRolesEqualOriginal] = useState(true);
	const [portalRolesEqualOriginal, setPortalRolesEqualOriginal] = useState(true);

	const users = useAppSelector(usersSelectors.selectEntities);

	const getRoleUpdates = (selectedUsers: any[], roleId: string, roleValue: string) => {
		const currentUsersWithRole = originalAppRoleAssignments[roleId]?.roleAssignedUsers ?? [];
		const usersToRemove = currentUsersWithRole
			?.filter(
				(current) => !selectedUsers.some((selected) => selected.userId === current.userId),
			)
			?.map(({ userId }) => userId);

		const usersToAdd = selectedUsers
			?.filter(
				(selected) =>
					!currentUsersWithRole.some((current) => current.userId === selected.userId),
			)
			?.map(({ userId }) => userId);

		return {
			roleId,
			roleValue,
			addUsersToRole: usersToAdd,
			removeUsersFromRole: usersToRemove,
			updated: usersToAdd.length > 0 || usersToRemove.length > 0,
			newRoleAssignedUsers: selectedUsers.map(({ userId, userDisplayName }) => ({
				userId,
				userDisplayName,
			})),
		};
	};

	const [isSaving, setIsSaving] = useState(false);

	const onSave = async (
		selectedDepartmentHead: Requester,
		selectedPrimeUser: Requester,
		approvers: CompanyApprover[],
		roleAssignments: AppRoleAssignment[],
	) => {
		setIsSaving(true);

		if (!hardwareApprovesEqualOriginal) {
			const hardwareApproverResult = await dispatch(
				updateHardwareApprovers({
					auth,
					body: selectedHardwareApprovers,
				}),
			);

			const newGlobalApprovers = selectedHardwareApprovers.global.filter(
				(newApprover) =>
					!approversState.approvers.global.some(
						(originalApprover) => originalApprover.id === newApprover.id,
					),
			);

			const newManagerApprovers = selectedHardwareApprovers.manager.filter(
				(newApprover) =>
					!approversState.approvers.manager.some(
						(originalApprover) => originalApprover.id === newApprover.id,
					),
			);

			const allNewApprovers = [...newGlobalApprovers, ...newManagerApprovers];

			const shouldInformNewApprovers =
				allNewApprovers.length > 0 &&
				(hardwareApproverResult as any).meta.requestStatus === "fulfilled";

			if (shouldInformNewApprovers) {
				allNewApprovers.forEach((approver) => {
					const user = users[approver.id] as User;
					const mailBody = {
						mailType: "ROLE_ASSIGNMENT",
						recipientMail: user.mail,
						recipientName: user.displayName,
						messageBody: {
							roleName: `${_.capitalize(
								approver.approverType.toLowerCase(),
							)} Hardware Approver`,
							roleDescription: hardwareApproverRoleDescription(approver.approverType),
							userPrincipalName: user.userPrincipalName,
							linkToIronstonePage: `${window.location.origin}`,
							displayNameOfRoleAssigner: account?.name ?? account?.username,
							company: customerInfo?.departmentName,
						},
					};
					dispatch(sendMail({ auth, body: mailBody }));
				});
			}
		}

		if (!portalRolesEqualOriginal) {
			const updates = roleAssignments.map(({ roleId, roleAssignedUsers, roleValue }) =>
				getRoleUpdates(roleAssignedUsers, roleId, roleValue),
			);
			const rolesToUpdate = updates.filter((update) => update.updated);
			const promises = rolesToUpdate.map((roleUpdate) =>
				dispatch(
					updateAppRoleAssignments({
						auth,
						body: {
							roleId: roleUpdate.roleId,
							addUsersToRole: roleUpdate.addUsersToRole,
							removeUsersFromRole: roleUpdate.removeUsersFromRole,
						},
						newRoleAssignedUsers: roleUpdate.newRoleAssignedUsers,
					}),
				),
			);

			// Use Promise.allSettled to handle all promises
			const results = await Promise.allSettled(promises);

			results.forEach((result, index) => {
				if (result.status === "fulfilled") {
					const roleId = rolesToUpdate[index].roleId;
					const roleInfo = originalAppRoleAssignments[roleId];
					if (!roleInfo) return;

					const roleName = roleInfo.roleDisplayName;
					const roleDescription = roleInfo.roleDescription;
					const userIds = rolesToUpdate[index].addUsersToRole;

					userIds.forEach((userId) => {
						const user = users[userId];
						if (!user) return;

						const mailBody = {
							mailType: "ROLE_ASSIGNMENT",
							recipientMail: user.mail,
							recipientName: user.displayName,
							messageBody: {
								roleName: roleName,
								roleDescription: roleDescription,
								userPrincipalName: user.userPrincipalName,
								linkToIronstonePage: `${window.location.origin}`,
								displayNameOfRoleAssigner: account?.name ?? account?.username,
								company: customerInfo?.departmentName,
							},
						};

						dispatch(sendMail({ auth, body: mailBody }));
					});
				}
			});
		}

		if (!companyRolesEqualOriginal) {
			const updatedCustomerInfo = {
				...customerInfo,
				approvers: approvers?.map((approver) => approver.mail).join(","),
			};

			// If this is a number, it means that it has been changed and that we should alter the headUserId
			if (!isNaN(Number(selectedDepartmentHead.id))) {
				updatedCustomerInfo.headUserId = selectedDepartmentHead.id;
				updatedCustomerInfo.headUserEmail = selectedDepartmentHead.mail;
			}

			if (!isNaN(Number(selectedPrimeUser.id))) {
				updatedCustomerInfo.primeUserId = selectedPrimeUser.id;
				updatedCustomerInfo.primeUserEmail = selectedPrimeUser.mail;
			}

			await dispatch(
				updateCustomerInfo({
					auth,
					id: customerInfo.departmentId,
					body: updatedCustomerInfo,
				}),
			);
		}

		setIsSaveButtonDisabled(true);
		setIsSaving(false);
	};

	const companyApprovers = useAppSelector(selectApprovers);

	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

	useEffect(() => {
		const disabled = () => {
			if (!isAdmin) return true;
			if (!customerInfo || !companyApprovers) return true;

			if (
				companyRolesEqualOriginal &&
				portalRolesEqualOriginal &&
				hardwareApprovesEqualOriginal
			) {
				return true;
			}
			return false;
		};

		setIsSaveButtonDisabled(disabled());
	}, [
		selectedCompanyApprovers,
		selectedDepartmentHead,
		selectedPrimeUser,
		customerInfo,
		companyApprovers,
		companyRolesEqualOriginal,
		isSaveButtonDisabled,
		portalRolesEqualOriginal,
		hardwareApprovesEqualOriginal,
		isAdmin,
	]);

	return (
		<>
			<Grid container className={styles.contentContainer}>
				<PortalRoles
					appRoleAssignments={roleAssignments}
					setRoleAssignments={setRoleAssignments}
					setPortalRolesEqualOriginal={setPortalRolesEqualOriginal}
				/>
			</Grid>
			<Grid container className={styles.contentContainer}>
				<HardwareApprovers
					isSaving={isSaving}
					selectedHardwareApprovers={selectedHardwareApprovers}
					setSelectedHardwareApprovers={setSelectedHardwareApprovers}
					setHardwareApprovesEqualOriginal={setHardwareApprovesEqualOriginal}
				/>
			</Grid>
			<Grid container className={styles.contentContainer}>
				<CompanyRoles
					isSaving={isSaving}
					selectedDepartmentHead={selectedDepartmentHead}
					selectedPrimeUser={selectedPrimeUser}
					selectedCompanyApprovers={selectedCompanyApprovers}
					setSelectedDepartmentHead={setSelectedDepartmentHead}
					setSelectedPrimeUser={setSelectedPrimeUser}
					setSelectedCompanyApprovers={setSelectedCompanyApprovers}
					setCompanyRolesEqualOriginal={setCompanyRolesEqualOriginal}
				/>
			</Grid>
			{isAdmin && (
				<Grid container className={styles.footer}>
					<PrimaryButton
						text="Save changes"
						size="medium"
						variantStyle="contained"
						isLoading={isSaving}
						action={() =>
							onSave(
								selectedDepartmentHead,
								selectedPrimeUser,
								selectedCompanyApprovers,
								roleAssignments,
							)
						}
						isDisabled={isSaveButtonDisabled}
					/>
				</Grid>
			)}
		</>
	);
};
