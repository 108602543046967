import { Grid } from "@mui/material";
import { AsyncThunk } from "@reduxjs/toolkit";
import { fetchAggregatedData } from "actions/aggregatedDataActions";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import {
	fetchAzureBilling,
	fetchMarketplaceItemsCost,
	fetchOffice365Billing,
	fetchReservedInstancesCost,
} from "actions/costActions";
import { useAuth } from "hooks/hooks";
import { useEffect } from "react";
import { ActionLoadingStatus, DataGenerationTypes } from "utilities/constants/enums";
import {
	DATA_GENERATION_MAX_DURATION_IN_MINUTES,
	getDataGenerationStatus,
	updateDataGenerationStatus,
} from "utilities/dataGenerationStatus";
import { getLoadingStatusIcon } from "utilities/loadingStatus";

const actions: Record<string, AsyncThunk<any, any, any>> = {
	[`${DataGenerationTypes.azureCost}`]: fetchAzureBilling,
	[`${DataGenerationTypes.office365Cost}`]: fetchOffice365Billing,
	[`${DataGenerationTypes.azureReservedInstancesCost}`]: fetchReservedInstancesCost,
	[`${DataGenerationTypes.azureMarketplaceItemsCost}`]: fetchMarketplaceItemsCost,
	[`${DataGenerationTypes.aggregatedData}`]: fetchAggregatedData,
};

interface DataGenerationStatusViewInputProps {
	portalHasAccess: boolean;
	setIsPreparingData: (isPreparingData: boolean) => void;
	timeElapsed: number;
}

const dataGenerationType: {
	[key: string]: string;
} = {
	office365Cost: "Generating license cost data",
	azureCost: "Generating Azure cost data",
	azureMarketplaceItemsCost: "Generating Azure Marketplace items cost data",
	azureReservedInstancesCost: "Generating Azure Reserved Instances data",
	aggregatedData: "Generating data for security overview",
};

export const DataGenerationStatusView = ({
	portalHasAccess,
	setIsPreparingData,
	timeElapsed,
}: DataGenerationStatusViewInputProps) => {
	const { kickstartedDataGeneration, dataGenerationStatuses } = getDataGenerationStatus();
	const { dispatch, auth } = useAuth();
	// Data generation is considered complete once all data generation statuses are either completed or failed
	// Failed will never be true if we haven't exceeded the max duration
	const isDataGenerationComplete =
		kickstartedDataGeneration &&
		Object.values(dataGenerationStatuses).every(
			(status) =>
				status === ActionLoadingStatus.COMPLETED || status === ActionLoadingStatus.FAILED,
		);

	const handleStatusChange = (type: DataGenerationTypes, status: ActionLoadingStatus) => {
		updateDataGenerationStatus(type, status);
	};

	// Try to fetch data if
	// 1. Portal has access
	// 2. Data generation is not complete (once we've ensured it's complete, we don't need to try to fetch data anymore)
	// 3. Time elapsed is greater than the max duration (max limit for how long user will have to wait for data generation to complete)
	const shouldTryToFetchData =
		portalHasAccess && timeElapsed < (DATA_GENERATION_MAX_DURATION_IN_MINUTES + 1) * 60;

	if (timeElapsed % 10 === 0 && shouldTryToFetchData) {
		Object.entries(actions).forEach(([key, action]) => {
			// If data generation is already completed, don't try to fetch data again
			if (
				dataGenerationStatuses[key as DataGenerationTypes] ===
					ActionLoadingStatus.COMPLETED ||
				dataGenerationStatuses[key as DataGenerationTypes] === ActionLoadingStatus.FAILED
			) {
				return;
			}
			setIsPreparingData(true);
			dispatch(action({ auth })).then((res: any) => {
				if (res.meta.requestStatus === "fulfilled") {
					handleStatusChange(key as DataGenerationTypes, ActionLoadingStatus.COMPLETED);
					return;
				}
				// Only consider data fetching failed if we've exceeded the max duration
				if (
					res.meta.requestStatus === "rejected" &&
					timeElapsed > DATA_GENERATION_MAX_DURATION_IN_MINUTES * 60
				) {
					handleStatusChange(key as DataGenerationTypes, ActionLoadingStatus.FAILED);
					return;
				}
			});
		});
	}

	useEffect(() => {
		setIsPreparingData(!isDataGenerationComplete);
	}, [isDataGenerationComplete, setIsPreparingData]);

	const getIcon = (status: ActionLoadingStatus) => {
		if (!portalHasAccess) {
			return <QueryBuilderIcon fontSize="large" />;
		}

		return getLoadingStatusIcon(status);
	};

	return (
		<>
			{Object.entries(dataGenerationStatuses).map(([key, status]) => {
				return (
					<Grid container direction="row" alignItems="center" key={key}>
						<Grid item>{getIcon(status)}</Grid>
						<Grid item ml={3}>
							<span>{`${dataGenerationType[key]}`}</span>
						</Grid>
					</Grid>
				);
			})}
		</>
	);
};
