import { createAsyncThunk } from "@reduxjs/toolkit";
import { Department } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

export const fetchDepartments = createAsyncThunk(
	"departments/fetch",
	apiGet<Department[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_DEPARTMENTS(),
		[SCOPES.GRAPH_READ],
	),
);
