// import { HardwareCheckout } from "./HardwareCheckout";

import { Grid, Skeleton, Typography } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "components/Common/Buttons/Button";
import styles from "./HardwareBundlesView.module.scss";
import { selectProducts } from "features/hardware/products";
import { ReactComponent as BundleBox } from "assets/cost/Group.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { CalculatedTotalCost } from "../HardwareBundles/CalculatedTotalCost";
import { ProductList } from "../HardwareBundles/ProductList";
import { fetchHardwareBundles } from "actions/hardwareBundlesActions";
import { fetchProducts } from "actions/productActions";
import {
	selectHardwareBundles,
	selectHardwareBundlesSortedByCreatedAt,
	clearBundleItems,
	editBundle,
} from "features/hardware/hardwareBundles";
import {
	useAppSelector,
	useApiOnce,
	useAppDispatch,
	useApiOnceWithAndWithoutCache,
} from "hooks/hooks";
import { bundleCountBySku } from "../HardwareBundles/ProductList/util";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { HardwareBundle } from "types";
import { Fragment, useState } from "react";
import { getIconsForBundle } from "utilities/iconUtils/hardwareIconUtils";
import clsx from "clsx";
import {
	setBundleIdToDelete,
	setHardwareDrawerOpen,
	setHardwareDrawerState,
} from "features/hardware/hardwarePage";
import { HardwareDrawerType } from "utilities/constants/enums";
import { DeleteBundleDialog } from "../HardwareBundles/DeleteBundleDialog";
import { theme } from "theme";
import { isAdmin } from "utilities/roleUtils/roleCheck";
import { selectRoles } from "features/roles";

const HardwareBundlesView = () => {
	const [bundleOpen, setBundleOpen] = useState<{ [key: string]: boolean }>({});
	const { navigateToPage } = useAppNavigate();
	const edit = (bundle: HardwareBundle) => {
		dispatch(editBundle(bundle));
		dispatch(
			setHardwareDrawerOpen({
				drawerType: HardwareDrawerType.Bundle,
				forceOpen: window.innerWidth > theme.breakpoints.values.md ? true : false,
			}),
		);
		navigateToPage(NAVIGATION_NAMES.HARDWARE_EDIT_BUNDLE.path);
	};

	const handleCreateBundle = () => {
		dispatch(clearBundleItems());
		dispatch(
			setHardwareDrawerState({
				isDrawerOpen: true,
				activeDrawerType: HardwareDrawerType.Bundle,
				headerText: "Create new bundle",
				primaryButtonText: "Create bundle",
				secondaryButtonText: "Cancel",
			}),
		);
		navigateToPage(NAVIGATION_NAMES.HARDWARE_CREATE_BUNDLE.path);
	};

	const productState = useAppSelector(selectProducts);

	const bundleState = useAppSelector(selectHardwareBundles);
	const bundles = useAppSelector(selectHardwareBundlesSortedByCreatedAt);

	const dispatch = useAppDispatch();

	useApiOnceWithAndWithoutCache(fetchProducts, productState);

	useApiOnce(fetchHardwareBundles, bundleState);

	const handleSetBundleOpen = (bundleId: string) => {
		const bundleIsOpen = bundleOpen[bundleId];
		setBundleOpen({ ...bundleOpen, [bundleId]: !bundleIsOpen });
	};

	const onHandleDeletePressed = (e: React.MouseEvent, bundleId: string) => {
		e.stopPropagation();
		dispatch(setBundleIdToDelete(bundleId));
	};

	const roles = useAppSelector(selectRoles);

	return (
		<Grid container className={styles.bundlesSettingsWrapper}>
			<Grid container className={styles.bundlesSettingsContainer}>
				<Grid item className={styles.createNewBundleButtonContainer}>
					<PrimaryButton
						size="medium"
						className={styles.addToCartButton}
						text={
							<Grid className={styles.editBundleButtonContent}>
								<BundleBox />
								<Typography>Create new bundle</Typography>
							</Grid>
						}
						variantStyle="contained"
						action={handleCreateBundle}
					/>
				</Grid>
				<Grid container className={styles.bundleLists}>
					{bundles.map((bundle) => {
						const countBySku = bundleCountBySku(bundle);
						// Max shown icons is 6, as we don't have space for more
						const iconsWithCount = getIconsForBundle(bundle).slice(0, 6);
						return (
							<Fragment key={bundle.id}>
								<Grid
									key={bundle.id}
									container
									className={clsx(styles.bundleContainer, {
										[styles.closedBorder]: !bundleOpen[bundle.id],
									})}
									onClick={() => handleSetBundleOpen(bundle.id)}
								>
									<Grid className={styles.openIconContainer}>
										{bundleOpen[bundle.id] ? (
											<KeyboardArrowUpIcon
												onClick={() => setBundleOpen({})}
												className={styles.openIcon}
											/>
										) : (
											<KeyboardArrowDownIcon
												onClick={() =>
													setBundleOpen({
														...bundleOpen,
														[bundle.id]: true,
													})
												}
												className={styles.openIcon}
											/>
										)}
									</Grid>
									<Grid className={styles.bundleNameContainer}>
										<Typography
											variant="h3"
											fontWeight={500}
											className={styles.truncate}
										>
											{bundle.name}
										</Typography>
									</Grid>
									<Grid className={styles.iconsContainer}>
										Items:{" "}
										{iconsWithCount.map(({ icon, count }, index) => {
											return (
												<Grid className={styles.iconContainer} key={index}>
													<Typography
														variant="description"
														className={styles.iconCount}
													>
														{count} x
													</Typography>
													{icon}
												</Grid>
											);
										})}
									</Grid>
									<Grid>
										<CalculatedTotalCost
											countBySku={countBySku}
											className={styles.totalBundleCost}
										/>
									</Grid>
									<Grid className={styles.editContainer}>
										<SecondaryButton
											action={() => edit(bundle)}
											variantStyle="contained"
											size="medium"
											icon={<EditIcon />}
											text={
												<Grid className={styles.editButtonContainer}>
													<Typography>Edit bundle</Typography>
												</Grid>
											}
											hidden={!isAdmin(roles)}
										/>
										<SecondaryButton
											text={
												<Grid
													container
													className={styles.deleteButtonContainer}
												>
													<DeleteIcon />
													<Typography>Delete bundle</Typography>
												</Grid>
											}
											size="medium"
											action={(e) => onHandleDeletePressed(e, bundle.id)}
											variantStyle="contained"
											isLoading={false}
										/>
									</Grid>
								</Grid>
								<Grid container className={styles.productList}>
									{bundleOpen[bundle.id] && (
										<ProductList products={bundle?.products} />
									)}
								</Grid>
							</Fragment>
						);
					})}
					{bundleState.isLoading &&
						Array.from(Array(10).keys()).map((index) => (
							<Grid key={index} container className={styles.bundleContainerSkeleton}>
								<Skeleton variant="text" className={styles.skeleton} />
							</Grid>
						))}
				</Grid>
			</Grid>
			<DeleteBundleDialog />
		</Grid>
	);
};

export { HardwareBundlesView };
