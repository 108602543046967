import { Grid, Tab, Tabs } from "@mui/material";

import styles from "./UserDrawerTabSelector.module.scss";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectUserDrawerState, setActiveTab } from "features/user";

export const TABS = {
	USER_DETAILS: 0,
	LICENSES: 1,
	DEVICES: 2,
	DELETE_USER: 3,
};

export const UserDrawerTabSelector = () => {
	const { dispatch } = useAuth();

	const { activeTab } = useAppSelector(selectUserDrawerState);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		dispatch(setActiveTab(newValue));
	};

	return (
		<Grid container className={styles.drawerTabContainer}>
			<Tabs value={activeTab} onChange={handleTabChange} className={styles.tabsContainer}>
				<Tab label="User Details" value={TABS.USER_DETAILS} className={styles.tab} />
				<Tab label="Licenses" value={TABS.LICENSES} className={styles.tab} />
				<Tab label="Devices" value={TABS.DEVICES} className={styles.tab} />
				<Tab label="Delete user" value={TABS.DELETE_USER} className={styles.tab} />
			</Tabs>
		</Grid>
	);
};
