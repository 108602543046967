import styles from "./ProductStatusLabel.module.scss";

const ProductStatusLabel = ({ label }: { label: string }) => {
	if (!label) {
		return <></>;
	}

	const target = label.toUpperCase();

	const { style, text } = [
		{
			style: styles.inactive,
			text: "Inactive",
		},
		{
			style: styles.active,
			text: "Active",
		},
	].find(({ text }) => target.includes(text.toUpperCase())) ?? {
		style: styles.inactive,
		text: label,
	};

	if (!style || !text) return <>{label}</>;

	return <span className={style}>{text}</span>;
};

export { ProductStatusLabel };
