import { Controller, UseFormReturn } from "react-hook-form";
import { useEffect, useState } from "react";
import {
	Autocomplete,
	Grid,
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	InputAdornment,
	IconButton,
	Tooltip,
	Typography,
	createFilterOptions,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Refresh, Visibility, VisibilityOff } from "@mui/icons-material";
import styles from "./PersonalDetails.module.scss";
import { Search } from "@mui/icons-material";
import { MobilePhoneInput } from "components/MobilePhoneInput";
import { userFormValidation } from "utilities/userFormValidation";
import { useAppSelector, useAuth } from "hooks/hooks";
import { departmentsSelectors } from "features/departments";
import { selectManager, usersSelectors } from "features/users";
import { getOneTimePassword } from "actions/userActions";
import { FormEmployee, User } from "types";
import { PayloadAction } from "@reduxjs/toolkit";
import clsx from "clsx";
import { RootState } from "store";
import { CountrySelector } from "components/Common/CountrySelector";

interface Props {
	formProps: UseFormReturn;
	employeeData: FormEmployee & Omit<User, "manager">;
	availableMailDomains: { id: number; name: string }[];
}

const PersonalDetails = ({
	formProps,
	formProps: {
		register,
		control,
		formState: { errors },
		clearErrors,
		setValue,
		trigger,
	},
	employeeData: {
		userPrincipalName,
		mailSuffix,
		department,
		manager: initialManagerValue,
		password: initialPasswordValue,
		country,
	},
	availableMailDomains,
}: Props) => {
	const availableDepartments = useAppSelector(departmentsSelectors.selectAll);

	const {
		auth: {
			account: { localAccountId },
		},
	} = useAuth();

	const { isLoading: isManagerOfThisUserLoading } = useAppSelector((state: RootState) =>
		selectManager(state, localAccountId),
	);

	const { userPrincipalName: loggedInUserPrincipalName } =
		useAppSelector((state) => usersSelectors.selectById(state, localAccountId)) ?? {};

	const { dispatch, auth } = useAuth();

	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState(initialPasswordValue ?? "");

	const handleShowPassword = () => setShowPassword(!showPassword);

	const [currentDepartment, setCurrentDepartment] = useState(department ?? "");

	const users = useAppSelector(usersSelectors.selectAll);

	const defaultProps = {
		options: users,
		getOptionLabel: ({ displayName }: User) => displayName ?? "",
	};

	const [manager, setManager] = useState(initialManagerValue ?? ({} as User));

	useEffect(() => {
		if (!isManagerOfThisUserLoading) {
			// Wait until manager has been fetched before setting the initial value of the manager field
			setManager(initialManagerValue ?? ({} as User));
		}
	}, [isManagerOfThisUserLoading, initialManagerValue]);

	const handleOnBlur = async (e: any) => {
		await trigger(e.target.name);
	};

	return (
		<Grid container item xs={12}>
			<Grid container direction="column" mb={2}>
				<Typography variant="body1" fontWeight={500}>
					Employee details
				</Typography>
				<Typography variant="description">All fields are required</Typography>
			</Grid>
			<Grid container item xs={12} classes={{ root: !errors.givenName ? styles.input : "" }}>
				<Grid item xs={5} className={styles.inputField}>
					<InputLabel>
						<Typography variant="body1">First name</Typography>
					</InputLabel>
					<TextField
						classes={{ root: styles.errorText }}
						variant="outlined"
						fullWidth
						size="small"
						InputProps={{
							classes: {
								input: styles.formTextInput,
							},
						}}
						error={!!errors.givenName}
						helperText={userFormValidation.givenName.getHelperText(errors)}
						{...register("givenName", userFormValidation.givenName.validationProps)}
						onBlur={handleOnBlur}
					/>
				</Grid>
			</Grid>
			<Grid container item xs={12} classes={{ root: !errors.surname ? styles.input : "" }}>
				<Grid item xs={5} className={styles.inputField}>
					<InputLabel>
						<Typography variant="body1">Last name</Typography>
					</InputLabel>
					<TextField
						classes={{ root: styles.errorText }}
						variant="outlined"
						fullWidth
						size="small"
						InputProps={{
							classes: {
								input: styles.formTextInput,
							},
						}}
						error={!!errors.surname}
						helperText={userFormValidation.surname.getHelperText(errors)}
						{...register("surname", userFormValidation.surname.validationProps)}
						onBlur={handleOnBlur}
					/>
				</Grid>
			</Grid>
			<Grid container item xs={12} classes={{ root: !errors.mailPrefix ? styles.input : "" }}>
				<Grid item xs={5} className={styles.inputEmailField}>
					<InputLabel>
						<Typography variant="body1">Email</Typography>
					</InputLabel>
					<TextField
						classes={{ root: styles.errorText }}
						variant="outlined"
						size="small"
						InputProps={{
							classes: {
								input: styles.formTextInput,
							},
						}}
						fullWidth
						error={!!errors.mailPrefix}
						helperText={userFormValidation.mailPrefix.getHelperText(errors)}
						{...register(
							"mailPrefix",
							userFormValidation.mailPrefix.getValidationProps(
								users,
								mailSuffix,
								loggedInUserPrincipalName,
								userPrincipalName,
								false,
							),
						)}
						onBlur={handleOnBlur}
					/>
				</Grid>

				<Grid
					container
					justifyContent="center"
					alignItems="center"
					item
					xs={0.5}
					classes={{ root: styles.atSign }}
				>
					<Typography variant="h2">@</Typography>
				</Grid>
				<Grid item xs={5} className={styles.inputEmailField}>
					<InputLabel id="email-suffix-label">
						<Typography variant="body1">Email domain</Typography>
					</InputLabel>
					<FormControl
						fullWidth
						variant="outlined"
						classes={{
							root: styles.selectInput,
						}}
					>
						<Controller
							render={({ field }) => (
								<Select
									{...field}
									labelId="email-suffix-label"
									id="email-suffix-select"
									defaultValue={mailSuffix}
									size="small"
									classes={{
										select: styles.selectTextInput,
									}}
								>
									{availableMailDomains.map((domain, key) => {
										return (
											<MenuItem key={key} value={domain.name}>
												{domain.name}
											</MenuItem>
										);
									})}
								</Select>
							)}
							name="mailSuffix"
							control={control}
							defaultValue=""
						/>
					</FormControl>
				</Grid>
			</Grid>
			<Grid container item xs={12} classes={{ root: styles.input }}>
				<Grid item xs={5} className={styles.inputField}>
					<InputLabel>
						<Typography variant="body1">Department</Typography>
					</InputLabel>
					<Autocomplete
						options={availableDepartments}
						value={currentDepartment}
						onChange={(_, newValue: any) => {
							if (newValue?.name?.startsWith("Add")) {
								const inputValue = newValue?.inputValue ?? "";
								setCurrentDepartment(inputValue);
								return;
							}
							const nameValue = newValue?.name ?? "";
							setCurrentDepartment(nameValue);
						}}
						freeSolo
						disableClearable
						clearOnBlur
						handleHomeEndKeys
						selectOnFocus
						renderOption={(props, option) => {
							return <li {...props}>{option["name"] || ""}</li>;
						}}
						getOptionLabel={(option) => {
							// Value selected with enter, right from the input
							if (typeof option === "string") {
								return option || "";
							}
							// Add "x" option created dynamically
							if (option.inputValue) {
								return option.inputValue;
							}
							// Regular option
							return option.name;
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder="Search departments"
								InputProps={{
									style: {
										height: 40,
										alignContent: "center",
									},
									...params.InputProps,
									type: "search",
								}}
								{...register("department")}
							/>
						)}
						filterOptions={(options, params) => {
							const filter = createFilterOptions();
							const filtered = filter(options, params);

							const { inputValue } = params;
							// Suggest the creation of a new value
							const isExisting = options.some((option) =>
								option.name.includes(inputValue),
							);
							if (inputValue !== "" && !isExisting) {
								filtered.push({
									inputValue,
									name: `Add "${inputValue}"`,
								});
							}

							return filtered;
						}}
					/>
				</Grid>
			</Grid>

			<Grid container item xs={12} classes={{ root: styles.input }}>
				<Grid item xs={5} className={styles.inputField}>
					<InputLabel>
						<Typography variant="body1">Manager</Typography>
					</InputLabel>
					<Autocomplete
						{...defaultProps}
						value={manager}
						onChange={(_, newValue: any) => {
							setManager(newValue);
						}}
						disabled={isManagerOfThisUserLoading}
						disableClearable
						autoHighlight
						isOptionEqualToValue={(option, value) =>
							option.userPrincipalName === value.userPrincipalName
						}
						renderOption={(props, option) => {
							return (
								<Tooltip
									key={option["id"]}
									title={option["mail"] || option["userPrincipalName"] || ""}
								>
									<li {...props}>{option["displayName"]}</li>
								</Tooltip>
							);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								{...register("manager")}
								// Override register's onBlur function to prevent setting the value to plain text
								onBlur={(e) => {
									e.preventDefault();
									setValue("manager", manager);
								}}
								variant="outlined"
								placeholder="Search for employee"
								InputProps={{
									style: {
										height: 40,
										alignContent: "center",
									},
									...params.InputProps,
									startAdornment: (
										<>
											<InputAdornment position="start">
												<Search />
											</InputAdornment>
											{params.InputProps.startAdornment}
										</>
									),
								}}
							/>
						)}
					/>
				</Grid>
			</Grid>

			<Grid
				container
				item
				xs={12}
				classes={{ root: !errors.privateEmail ? styles.input : "" }}
			>
				<Grid item xs={5} className={styles.inputField}>
					<InputLabel>
						<Grid container alignItems="center">
							<Typography variant="body1" mr={1}>
								Private email
							</Typography>
							<Tooltip title="The private email address is required for sending account information to the user">
								<IconButton classes={{ root: styles.tooltip }}>
									<InfoOutlinedIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</InputLabel>
					<TextField
						fullWidth
						size="small"
						InputProps={{
							classes: {
								input: styles.formTextInput,
							},
						}}
						classes={{ root: styles.errorText }}
						error={!!errors.privateEmail}
						helperText={userFormValidation.privateEmail.getHelperText(errors)}
						{...register(
							"privateEmail",
							userFormValidation.privateEmail.validationProps,
						)}
						onBlur={handleOnBlur}
					/>
				</Grid>
			</Grid>

			<Grid container item xs={12} classes={{ root: styles.input }}>
				<Grid item xs={5} className={styles.inputField}>
					<InputLabel>
						<Grid container alignItems="center">
							<Typography variant="body1" mr={1}>
								Country
							</Typography>
						</Grid>
					</InputLabel>
					<CountrySelector value={country} register={register} />
				</Grid>
			</Grid>

			<Grid item xs={5} className={styles.inputField}>
				<MobilePhoneInput
					required={true}
					formProps={formProps}
					handleOnBlur={handleOnBlur}
					fullWidth
					isOnboarding={true}
				/>
			</Grid>

			<Grid
				container
				item
				xs={12}
				classes={{ root: clsx(styles.input, styles.wrapForsmallScreen) }}
			>
				<Grid item xs={5} className={clsx(styles.inputField, styles.passwordInputField)}>
					<InputLabel>
						<Grid container alignItems="center">
							<Typography variant="body1" mr={0.5}>
								One-time Password
							</Typography>
							<Tooltip title="The one-time password will automatically be sent by SMS after the registartion is complete">
								<IconButton classes={{ root: styles.tooltip }}>
									<InfoOutlinedIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</InputLabel>
					<TextField
						InputProps={{
							endAdornment: (
								<>
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleShowPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								</>
							),
							classes: {
								input: styles.formTextInput,
							},
							onChange: (e) => {
								setPassword(e.target.value);
							},
						}}
						value={password}
						classes={{ root: styles.errorText }}
						variant="outlined"
						type={showPassword ? "text" : "password"}
						fullWidth
						size="small"
						error={!!errors.password}
						helperText={userFormValidation.password.getHelperText(errors)}
						{...register("password", userFormValidation.password.validationProps)}
						onBlur={handleOnBlur}
					/>
				</Grid>
				<Grid item xs={6} className={styles.generatePasswordContainer}>
					<IconButton
						onClick={async () => {
							const { payload: { otp } = {} } = (await dispatch(
								getOneTimePassword({ auth }),
							)) as PayloadAction<{ otp: string }>;
							if (!otp) return;
							clearErrors("password");
							setPassword(otp);
							setValue("password", otp);
						}}
						classes={{ root: styles.generatePassword }}
					>
						<Refresh />
						<Typography variant="body1" className={styles.generatePasswordText}>
							Generate new password
						</Typography>
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

export { PersonalDetails };
