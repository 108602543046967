import dayjs from "dayjs";
import _ from "lodash";

/*
  Receives a ISO-string and moves it 1 months back, and
  if the previous month doesn't contain the same number of
  days it will handle this.
  Example:
  previousMonthToDate(["2020-12-01", "2020-12-31"]);
  returns => {from: "2020-11-01", to: "2020-11-30"}
*/
export function previousMonthToDate(dates) {
	let newDates = [];
	dates.forEach((date) => {
		// Example: 2021-12-31 becomes an array with [2021, 12, 31]
		const dateSplit = date.split("-");
		const dateSplitYear = dateSplit[0];
		const dateSplitMonth = dateSplit[1];
		const dateSplitDay = dateSplit[2];

		// Final example: 2021-12-31 becomes 2021-9-31
		let monthAsNumber = parseInt(dateSplitMonth);
		// Make sure that the month is not set to 0. If it does we decrease with -1 so that we get December instead
		let newdateYear = dateSplitYear;
		let newdateMonth = monthAsNumber - 1; // 12 - 1 = 11
		let newdateDay = dateSplitDay;
		let newDate = `${dateSplitYear}-${newdateMonth}-${newdateDay}`; // Example: "2021-11-31"
		// If the month is a negative value we decrease the year and fix the correct month
		if (Math.sign(newdateMonth) === -1 || newdateMonth === 0) {
			newdateYear = parseInt(dateSplitYear) - 1; // Example: year 2021 becomes 2020
			newdateMonth = 12 + newdateMonth; // Example: month -3 becomes 9 (12-3=9)
			newDate = `${newdateYear}-${newdateMonth}-${newdateDay}`; // Example: "2020-9-01"
		}

		// Check if the day-number exists in that year and month, if not we find out which is the last day number of the month and then update
		const lastDayThatMonth = new Date(newdateYear, newdateMonth, 0).getDate(); // returns 30 for (2020,11,0) because there is only 30days in September 2021
		if (newdateDay > lastDayThatMonth) {
			newDate = `${newdateYear}-${newdateMonth}-${lastDayThatMonth}`; // Example: "2020-11-30"
		}

		// Finally add zeros to single numbers. Example: 2020-6-1 becomes 2020-06-01
		let finalDate = "";
		newDate.split("-").forEach((item, count) => {
			if (item.length === 1) {
				finalDate += `0${item}`; // Example "1" becomes "01"
			} else {
				finalDate += item; // Example "20" becomes "20"
			}

			if (count !== 2) {
				finalDate += "-"; // Example add "-" after all nubmers but the last "2020-01-20"
			}
		});

		newDates.push(finalDate);
	});

	return { from: newDates[0], to: newDates[1] };
}

/*
    Returns the last month and its year as a ISO string
    Example:
    - the current date it 19.10.2021 and we run this function
    -> The function will return the last month and its year
        which is 2021-09.

    Returns: 2021-09
*/
export function getYearAndLastMonthAsString() {
	const now = new Date();
	const thisMonth = now.getMonth();
	const lastMonth = new Date(now.setMonth(thisMonth - 1));

	// Dates are 0-based like arrays, so the month number for last month will be for this month (Eg. 9 is 10 for october and not september)
	const lastMonthToHuman = lastMonth.getMonth() + 1;
	// Add 0 to single digit months. (Eg. 9 becomes 09)
	const lastMonthToString =
		lastMonthToHuman.toString().length > 1
			? lastMonthToHuman.toString()
			: `0${lastMonthToHuman.toString()}`;

	// Returns for example: 2021-09
	return `${lastMonth.getFullYear()}-${lastMonthToString}`;
}

/*
    Returns the month and its year as a ISO string
    Example:
    - the current date it 19.10.2021 and we run this function
    -> The function will return the month and its year
        which is 2021-10.

    Returns: 2021-10
*/
export function getYearAndMonthAsString() {
	const now = new Date();

	// Dates are 0-based like arrays, so the month number for last month will be for this month (Eg. 9 is 10 for october and not september)
	const thisMonthToHuman = now.getMonth() + 1;

	// Add 0 to single digit months. (Eg. 9 becomes 09)
	const thisMonthToString =
		thisMonthToHuman.toString().length > 1
			? thisMonthToHuman.toString()
			: `0${thisMonthToHuman.toString()}`;

	// Returns for example: 2021-09
	return `${now.getFullYear()}-${thisMonthToString}`;
}

/*
    Returns the first day of the last month in ISO standard
    Example:
    - the current date it 19.10.2021 and we run this function
    -> The function will return the year, month and day
        which is "2021-09-01".

    Returns: "2021-09-01"
*/
export function getFirstDayOfLastMonth() {
	return (
		new Date(new Date().setMonth(new Date().getMonth() - 1))
			.toISOString()
			.split("T")[0]
			.slice(0, -2) + "01"
	);
}

/*
    Returns the last day of the last month in ISO standard
    Example:
    - the current date it 16.11.2021 and we run this function
    -> The function will return the year, month and day
        which is "2021-10-31".

    Returns: "2021-10-31"
*/
export function getLastDayOfLastMonth() {
	return new Date(new Date().setDate(0)).toISOString().split("T")[0];
}

/*
    Returns the first day of the current month in ISO standard
    Example:
    - the current date it 16.11.2021 and we run this function
    -> The function will return the year, month and day
        which is "2021-11-01".

    Returns: "2021-11-01"
*/
export function getFirstDayOfCurrentMonth() {
	return new Date().toISOString().split("T")[0].slice(0, -2) + "01";
}

/*
    Returns the current date in ISO standard
    Example:
    - the current date it 16.11.2021 and we run this function
    -> The function will return the year, month and day
        which is "2021-11-16".

    Returns: "2021-11-16"
*/
export function getCurrentYearMonthDayAsISO() {
	return new Date().toISOString().split("T")[0];
}

/**
 * Returns the current month minus or plus the "months"-variable
 * Example when the current year is 2021 and month is 11:
 * getMonth(0); // Returns "2021-11"
 * getMonth(-1); // Returns "2021-10"
 * getMonth(4); // Returns "2022-03"
 */
export function getYearAndMonthAsIsoString(months) {
	const currentDate = new Date();
	let date = currentDate.setMonth(currentDate.getMonth() + months);
	return new Date(date).toISOString().split("T")[0].slice(0, -3);
}

/**
 * Returns hours and minutes based on a duration provided in minutes
 */
export const asHoursAndMinutes = (time) => {
	const hours = Math.floor(time / 60);
	const minutes = time % 60;
	let hoursAndMinutes = "";

	hoursAndMinutes += hours > 0 ? `${hours}h ` : "";
	hoursAndMinutes += minutes > 0 ? `${minutes}m` : "";

	return hoursAndMinutes;
};

export const now = dayjs();

/**
 * Returns the number of months since the given date
 *
 * Example when the current year is 2021 and month is 11:
 *
 * monthsSinceDate(dayjs("November 2021")); Returns `0` ;
 * monthsSinceDate(dayjs("October 2021"));  Returns `1` ;
 */
export function monthsSinceDate(date) {
	return Math.abs(date.diff(now, "month"));
}

/**
 * Returns `true` if the number of months since the date is within the range [start, end] inclusive
 * Example when the current year is 2021 and month is 11:
 *
 * monthsSinceDateInRangeInclusive(dayjs("November 2021"), 0, 1)); Returns `true` ;
 * monthsSinceDateInRangeInclusive(dayjs("November 2021"), 1, 2)); Returns `false` ;
 * monthsSinceDateInRangeInclusive(dayjs("October 2021"), 0, 1));  Returns `true` ;
 * monthsSinceDateInRangeInclusive(dayjs("October 2021"), 2, 3));  Returns `false` ;
 */
export function monthsSinceDateInRangeInclusive(date, start, end) {
	return _.inRange(monthsSinceDate(date), start, end + 1);
}

const acceptableDateFormats = ["YYYYMMDD", "DD.MM.YYYY", "DD-MM-YYYY", "YYYY MMMM", "YYYY-MM"];
/**
 * Returns the unix value of the given date
 * @param {string} date - a date string in the format @see acceptableDateFormats above
 */
export const toUnix = (date) => {
	if (!date) return null;
	const dateObject = dayjs(date, acceptableDateFormats);
	return dateObject.isValid() ? dateObject.unix() : null;
};
