import styles from "./TermDurationLabel.module.scss";

const TermDurationLabel = ({ label }: { label: string }) => {
	if (!label) {
		return <></>;
	}

	const target = label.toUpperCase();

	const { style, text } = [
		{
			style: styles.monthlyTermDurationLabel,
			label: "P1M",
			text: "Monthly",
		},
		{
			style: styles.annualTermDurationLabel,
			label: "P1Y",
			text: "Annual",
		},
	].find(({ label }) => target.includes(label)) ?? {
		style: styles.unknownTermLabel,
		text: label,
	};

	if (!style || !text) return <>{label}</>;

	return <span className={style}>{text}</span>;
};

export { TermDurationLabel };
