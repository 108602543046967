import { Grid, Typography } from "@mui/material";
import {
	fetchLicenseGroups,
	fetchLicenseSubscriptions,
	fetchSubscribedSkus,
} from "actions/licenseActions";
import {
	selectActiveLicenseManagementId,
	selectLicenseGroups,
	selectLicenseSubscriptions,
	selectLicenseSubscriptionsWithConsumptionDetails,
	selectSubscribedSkus,
} from "features/licenses/licenses";
import { selectLicenseActions } from "features/scheduledActions";
import {
	useApiOnce,
	useApiWithAndWithoutCache,
	useAppSelector,
	useFetchLicensePrices,
} from "hooks/hooks";
import { LicenseCard } from "./LicenseCard";

import styles from "./LicenseManagementView.module.scss";
import { LicenseDetailsView } from "./LicenseDetailsView";
import { fetchScheduledActions } from "actions/scheduledActionActions";
import { useMemo } from "react";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { LicenseAssignmentView } from "./LicenseAssignmentView";
import { LicenseManagementButtonSection } from "./LicenseManagementButtonSection";
import { fetchLicenseNames } from "actions/licenseNameActions";
import { licenseNameSelectors, selectlicenseNames } from "features/licenses/licenseNames";

export const LicenseManagementView = ({ page }: { page: string }) => {
	const licenseState = useAppSelector(selectLicenseSubscriptions);
	const subscribedSkus = useAppSelector(selectSubscribedSkus);
	const licenseNameState = useAppSelector(selectlicenseNames);
	useApiOnce(fetchScheduledActions, useAppSelector(selectLicenseActions));
	useApiOnce(fetchSubscribedSkus, subscribedSkus);
	useApiWithAndWithoutCache(fetchLicenseSubscriptions, licenseState);
	useApiOnce(fetchLicenseGroups, useAppSelector(selectLicenseGroups));
	useApiOnce(fetchLicenseNames, licenseNameState);
	useFetchLicensePrices();

	const licenses = useAppSelector(selectLicenseSubscriptionsWithConsumptionDetails);
	const activeLicenseManagementId = useAppSelector(selectActiveLicenseManagementId);
	const licenseNames = useAppSelector(licenseNameSelectors.selectEntities);

	const isLoading =
		licenseState.isLoading || subscribedSkus.isLoading || licenseNameState.isLoading;

	const content = useMemo(() => {
		switch (page) {
			case NAVIGATION_NAMES.LICENSE_MANAGEMENT.path:
				return activeLicenseManagementId === "" ? (
					<Grid container className={styles.cardsContainer}>
						<Grid container item className={styles.titleSectionContainer}>
							<Grid container item className={styles.titleSection}>
								<Typography variant="h2">Manage Licenses</Typography>
								<LicenseManagementButtonSection />
							</Grid>
						</Grid>
						{!isLoading
							? licenses.map((license) => (
									<LicenseCard
										key={license.provisioningId}
										name={
											licenseNames[license.provisioningId]
												?.licenseDisplayName ?? license.friendlyName
										}
										licenseVariants={license.variants}
										used={license.consumedQuantity}
										total={license.totalQuantity}
										isLoadingSubscriptions={subscribedSkus.isLoading}
									/>
							  ))
							: Array.from({ length: 12 }, (_, i) => i).map((_, i) => (
									<LicenseCard
										key={i}
										name="Microsoft 365 E5"
										licenseVariants={[]}
										used={0}
										total={0}
										isLoadingSubscriptions={true}
									/>
							  ))}
					</Grid>
				) : (
					<Grid container item className={styles.detailsWrapper}>
						<LicenseDetailsView />
					</Grid>
				);
			case NAVIGATION_NAMES.ASSIGN_LICENSES.path:
				return <LicenseAssignmentView />;
			default:
				return null;
		}
	}, [
		page,
		activeLicenseManagementId,
		licenses,
		subscribedSkus.isLoading,
		licenseNames,
		isLoading,
	]);

	return (
		<Grid container item className={styles.wrapper}>
			{content}
		</Grid>
	);
};
