import React, { useState, useEffect, useCallback } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { REGEX } from "utilities/constants/constants";
import { useAppSelector, useAuth } from "hooks/hooks";
import { useUser } from "hooks/userHooks";
import { selectRecipientMailState, setRecipientMailState } from "features/scheduledActions";

interface EmailInputProps {
	explanationText: string;
	required: boolean;
	className?: string;
}

const EmailInput = ({ explanationText, required, className }: EmailInputProps) => {
	const user = useUser();
	const recipientMailState = useAppSelector(selectRecipientMailState);
	const isEmailValid = recipientMailState.isValid;
	const { dispatch } = useAuth();
	const [emailInput, setEmailInput] = useState("");

	const validateEmail = useCallback(
		(email: string) => {
			if (!required && email === "") {
				// If the field is not required and the user has not entered anything, we consider it valid.
				return true;
			}

			const isValid = REGEX.MAIL_COMPLETE.test(email);
			return isValid;
		},
		[required],
	);

	useEffect(() => {
		setEmailInput(recipientMailState.mail ?? "");
	}, [recipientMailState]);

	useEffect(() => {
		// This useEffect will run when the user's email changes, i.e when the component is first rendered
		const isValid = validateEmail(user.mail);
		if (isValid) {
			dispatch(setRecipientMailState({ mail: user.mail, isValid: true }));
			setEmailInput(user.mail);
		}
	}, [user.mail, dispatch, validateEmail]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const email = e.target.value;
		setEmailInput(email); // Update local state with every change
		if (validateEmail(email)) {
			// Only dispatch to store if email is valid
			dispatch(setRecipientMailState({ mail: email, isValid: true }));
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid item>
					<Typography variant="body1" fontWeight={500} display="inline">
						Email{" "}
					</Typography>
					<Typography variant="description" color="text.secondary">
						({explanationText})
					</Typography>
				</Grid>
				<TextField
					value={emailInput} // Use local state value
					onChange={handleChange}
					error={!isEmailValid}
					helperText={!isEmailValid ? "Please enter a valid email address." : ""}
					fullWidth
					required={required}
					InputProps={{ className: className }}
					onBlur={(e) => {
						dispatch(
							setRecipientMailState({
								mail: e.target.value,
								isValid: validateEmail(e.target.value),
							}),
						);
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default EmailInput;
