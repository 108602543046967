import { useAppDispatch, useAppSelector, useAuth } from "hooks/hooks";
import {
	selectBundleIdToDelete,
	setBundleIdToDelete,
	setHardwareDrawerClosed,
} from "features/hardware/hardwarePage";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { Typography } from "@mui/material";
import { hardwareBundlesSelectors, selectHardwareBundles } from "features/hardware/hardwareBundles";
import { postHardwareBundle } from "actions/hardwareBundlesActions";
import { useState } from "react";
import _ from "lodash";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { useAppNavigate } from "hooks/useAppNavigate";

export const DeleteBundleDialog = () => {
	const [isDeletingBundle, setIsDeletingBundle] = useState(false);
	const bundleIdToDelete = useAppSelector(selectBundleIdToDelete) ?? "";
	const bundleState = useAppSelector(selectHardwareBundles);
	const showDialog = bundleIdToDelete !== "";
	const bundles = useAppSelector(hardwareBundlesSelectors.selectAll);
	const dispatch = useAppDispatch();
	const { navigateToPage } = useAppNavigate();
	const { auth } = useAuth();

	const deleteBundle = async () => {
		setIsDeletingBundle(true);
		const deletedStateEntities = _.omit(bundleState.entities, bundleIdToDelete);
		const body = {
			...deletedStateEntities,
		};
		await dispatch(
			postHardwareBundle({
				auth,
				body: body,
			}),
		);
		await new Promise((resolve) => setTimeout(resolve, 2000));
		dispatch(setBundleIdToDelete(""));
		dispatch(setHardwareDrawerClosed());
		navigateToPage(NAVIGATION_NAMES.HARDWARE_BUNDLES.path);
		setIsDeletingBundle(false);
	};

	return showDialog ? (
		<PrimaryDialog
			title={`Delete bundle`}
			size="small"
			primaryAction={() => deleteBundle()}
			disabledActions={!bundleIdToDelete}
			primaryButtonText="Delete"
			onLeaveAction={() => dispatch(setBundleIdToDelete(""))}
			secondaryButtonText="Cancel"
			isDestructiveAction={true}
			isLoading={isDeletingBundle}
			zIndex={1350}
		>
			<Typography variant="body1" noWrap>
				Are you sure you want to delete this bundle? <br />
				<b>{bundles.find((bundle) => bundle.id === bundleIdToDelete)?.name}</b>
			</Typography>
		</PrimaryDialog>
	) : null;
};
