import { MouseEventHandler } from "react";
import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Grid,
} from "@mui/material";
import { DownloadableLink } from "components/Common/DownloadableLink";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";

import styles from "./AdminsWithoutMFADialog.module.scss";
import { UserDisplayInfo } from "components/Common/UserInfoDisplay";

type Admin = {
	id: string;
	userDisplayName: string;
	userPrincipalName: string;
	role: string;
};

interface UsersWithoutMFADialogProps {
	admins: Admin[];
	onClose: MouseEventHandler;
}

const AdminsWithoutMFADialog = ({
	admins = [],
	onClose,
}: UsersWithoutMFADialogProps): JSX.Element => {
	return (
		<PrimaryDialog
			title="Admins without MFA"
			primaryButtonText={"Close"}
			primaryAction={onClose}
			onLeaveAction={onClose}
			size="large"
		>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TableContainer className={styles.tableContainer}>
						<Table size="small" aria-label="Users without MFA Enabled" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell className={styles.role}>Role</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{admins.length > 0 ? (
									admins.map((admin, key) => (
										<TableRow key={key}>
											<TableCell>
												<UserDisplayInfo
													id={admin.id}
													name={admin.userDisplayName}
													mail={admin.userPrincipalName}
												/>
											</TableCell>
											<TableCell>{admin.role}</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell colSpan={3} align="center">
											All admins have MFA enabled
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={12}>
					<DownloadableLink
						data={admins}
						fileName={"admins-without-mfa.csv"}
						shouldFormatNumbers={false}
					/>
				</Grid>
			</Grid>
		</PrimaryDialog>
	);
};

export { AdminsWithoutMFADialog };
