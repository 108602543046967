import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchLicensePrices } from "actions/licensePriceActions";
import { RootState } from "store";
import { LicensePrice } from "types";

export const licensePriceAdapter = createEntityAdapter<LicensePrice>({
	selectId: (licensePrice) => {
		const parts = licensePrice.UniqueProviderOfferId.split(":");
		// ProductId:SkuId:TermDuration
		return `${parts[1]}:${parts[2]}:${parts[3]}`;
	},
});

const initialState = licensePriceAdapter.getInitialState({
	isLoading: true,
	isFetching: false,
});

const licensePriceSlice = createSlice({
	name: "licensePrice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLicensePrices.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchLicensePrices.fulfilled, (state, { payload }) => {
				licensePriceAdapter.setAll(state, mapLicensePrices(payload.LicensePrices));
				state.isLoading = false;
				state.isFetching = false;
			});
	},
});

const mapLicensePrices = (prices: LicensePrice[]): LicensePrice[] =>
	prices.map((price) => ({
		...price,
		CostPeriod: price.BillingCycleName.toUpperCase() === "ANNUAL" ? "year" : "month",
	}));

export const selectLicensePrices = (state: RootState) => state.licensePrices;

export const selectLicensePriceDiscount = createSelector(selectLicensePrices, (state) => {
	const prices = licensePriceAdapter.getSelectors().selectAll(state);
	const allPricesWithDiscounts = prices.filter((price) => price.CustomerDiscountInPercent !== 0);
	const { mostCommonDiscountValue } = allPricesWithDiscounts.reduce(
		(acc, price) => {
			const discount = Number(price.CustomerDiscountInPercent);
			acc.counts[discount] = (acc.counts[discount] || 0) + 1;

			// Check if this discount is the most common
			if (
				!acc.mostCommonDiscountValue ||
				acc.counts[discount] > acc.counts[acc.mostCommonDiscountValue]
			) {
				acc.mostCommonDiscountValue = discount;
			}

			return acc;
		},
		{ counts: {} as Record<string, number>, mostCommonDiscountValue: 0 },
	);
	return mostCommonDiscountValue;
});

export const licensePricesSelectors = licensePriceAdapter.getSelectors(
	(state: RootState) => state.licensePrices,
);

export default licensePriceSlice.reducer;
