export const tableDataWithOtherCollector = (data, numDetailedBarsInChart) => {
	const sorted = Object.entries(data).sort((a, b) => {
		const aTotal = a[1].nonCompliant + a[1].compliant + a[1].notAvailable;
		const bTotal = b[1].nonCompliant + b[1].compliant + b[1].notAvailable;
		if (aTotal > bTotal) return -1;
		if (aTotal < bTotal) return 1;
		return 0;
	});

	const topMostDevices = sorted
		.filter(([label]) => label !== "Unknown")
		.slice(0, numDetailedBarsInChart)
		.reduce((acc, curr) => ({ ...acc, [curr[0]]: curr[1] }), {});

	const rest = sorted.slice(numDetailedBarsInChart).reduce(
		(acc, curr) => {
			return {
				nonCompliant: acc.nonCompliant + curr[1].nonCompliant,
				compliant: acc.compliant + curr[1].compliant,
				notAvailable: acc.notAvailable + curr[1].notAvailable,
			};
		},
		{
			nonCompliant: 0,
			compliant: 0,
			notAvailable: 0,
		},
	);
	topMostDevices.Other = rest;

	const labelsArray = Object.keys(topMostDevices);

	return { topMostDevices, labelsArray };
};
