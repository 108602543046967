import { CheckRiskDetection, NoDuplicatesRiskDetections } from "types";

export const groupDuplicates = (
	inputRiskDetections: CheckRiskDetection[],
): NoDuplicatesRiskDetections[] => {
	return inputRiskDetections.reduce(
		(previousValue: NoDuplicatesRiskDetections[], currentValue: CheckRiskDetection) => {
			const index = previousValue.findIndex(
				(x) =>
					x.userId === currentValue.userId &&
					x.riskLevel === currentValue.riskLevel &&
					x.riskEventType === currentValue.riskEventType,
			);
			if (index >= 0) {
				previousValue[index].riskySignInsCount += 1;
				return previousValue;
			}
			return [...previousValue, { ...currentValue, riskySignInsCount: 1 }];
		},
		[],
	);
};
