import PropTypes from "prop-types";
import {
	Typography,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
	TableContainer,
	Box,
} from "@mui/material";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";

import styles from "./EnterpriseAppsDialog.module.scss";

const EnterpriseAppsDialog = ({ size, onClose, Check_ServicePrincipals = {} }) => {
	const renderRiskLevel = (riskLevel) => {
		switch (riskLevel) {
			case "Medium":
				return <Box className={styles.riskLevelMedium}></Box>;
			case "High":
				return <Box className={styles.riskLevelHigh}></Box>;
			default:
				return <Box className={styles.riskLevelNone}></Box>;
		}
	};

	const prepareScopesForDisplay = (scopes) => {
		const scopesDisplayedToUser =
			scopes > 2 // Due to very little space in the view
				? scopes.slice(0, 2).join(", ") + "..."
				: `${scopes.length} scopes`;

		return scopesDisplayedToUser;
	};

	return (
		<PrimaryDialog
			title="Enterprise apps"
			primaryButtonText={"Close"}
			primaryAction={onClose}
			onLeaveAction={onClose}
			size="large"
		>
			<Typography className={styles.subTitle} variant="body2">
				Added last 14 days
			</Typography>
			<TableContainer>
				<Table aria-label="Enterprise apps" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>App name</TableCell>
							<TableCell></TableCell>
							<TableCell>Risk status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Check_ServicePrincipals.CheckValues.SPs_CreatedLast14Days.length > 0 ? (
							Check_ServicePrincipals.CheckValues.SPs_CreatedLast14Days.map(
								(servicePrincipal, key) => {
									const scopes = servicePrincipal.oauth2PermissionGrants
										.map((oauth2Grant) => oauth2Grant.scope.split(" "))
										.flatMap((scopes) => scopes);

									return (
										<TableRow key={key}>
											<TableCell>
												{`${servicePrincipal.displayName} -
													${prepareScopesForDisplay(scopes)}`}
											</TableCell>
											<TableCell></TableCell>
											<TableCell>
												{renderRiskLevel(servicePrincipal.riskLevel)}
											</TableCell>
										</TableRow>
									);
								},
							)
						) : (
							<TableRow>
								<TableCell colSpan={3} align="center" className={styles.noItemCell}>
									No enterprise apps added last two weeks
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</PrimaryDialog>
	);
};

EnterpriseAppsDialog.propTypes = {
	onClose: PropTypes.func,
	Check_ServicePrincipals: PropTypes.shape({
		Check: PropTypes.string,
		CheckValues: PropTypes.shape({
			SPs_CreatedLast14Days: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
					riskLevel: PropTypes.string,
					accountEnabled: PropTypes.bool,
					displayName: PropTypes.string,
					oauth2PermissionGrants: PropTypes.arrayOf(
						PropTypes.shape({
							scope: PropTypes.string,
							principalId: PropTypes.string,
							consentType: PropTypes.string,
						}),
					),
				}),
			),
			createdDateTime: PropTypes.string,
			notes: PropTypes.string,
		}),
		Date: PropTypes.string,
	}),
};

export { EnterpriseAppsDialog };
