import { Grid, Typography } from "@mui/material";
import styles from "./BundleCart.module.scss";
import { useAppDispatch, useAppSelector, useAuth } from "hooks/hooks";
import {
	setBundleInfo,
	addItemToBundle,
	removeItemFromBundle,
	deleteBundleItem,
	selectBundleCountBySku,
	selectBundleCount,
	selectBundlePriceTotal,
	selectActiveEditedBundle,
	hardwareBundlesSelectors,
	selectBundleItems,
} from "features/hardware/hardwareBundles";
import { HardwareBundle, HardwareProductExtended } from "types";
import { postHardwareBundle } from "actions/hardwareBundlesActions";
import { mapToHardwareBundleInfo } from "utilities/features/products";
import { v4 as uuidv4 } from "uuid";
import { selectProducts } from "features/hardware/products";
import { InputTextField } from "components/Common/InputTextField";
import { ChangeEvent, useMemo, useState } from "react";
import { PrimaryButton, SecondaryButton } from "components/Common/Buttons/Button";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import {
	selectHardwarePurchaseType,
	setBundleIdToDelete,
	setHardwareDrawerClosed,
} from "features/hardware/hardwarePage";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import DeleteIcon from "@mui/icons-material/Delete";
import { SelectedHardwareListItem } from "../../HardwareDrawer/SelectedHardwareListItem";
import { DeleteBundleDialog } from "../DeleteBundleDialog";
import { FindBundleItemsButton } from "../../HardwareList/Product/AddToBundleButton";
import { HardwarePurchaseType } from "utilities/constants/enums";

const BundleCart = () => {
	const { navigateToPage } = useAppNavigate();
	const dispatch = useAppDispatch();
	const { isLoading: isProductsLoading } = useAppSelector(selectProducts);
	const cartItems = useAppSelector(selectBundleItems) as HardwareProductExtended[];
	const countBySku = useAppSelector(selectBundleCountBySku);
	const cartPriceTotal = useAppSelector(selectBundlePriceTotal);
	const cartItemsCount = useAppSelector(selectBundleCount);
	const allBundles = useAppSelector(hardwareBundlesSelectors.selectEntities);
	const productState = useAppSelector(selectProducts);
	const activeBundle = useAppSelector(selectActiveEditedBundle);
	const hardwarePurchaseType = useAppSelector(selectHardwarePurchaseType);
	const [isLoading, setIsLoading] = useState(false);

	const [nameError, setNameError] = useState("");
	const currentNameIsNotUnique = useMemo(() => {
		return Object.values(allBundles).some(
			(bundle) => bundle?.name.trim() === activeBundle?.name.trim(),
		);
	}, [allBundles, activeBundle?.name]);
	const { auth } = useAuth();

	const createBundle = (bundleId?: string) => {
		const id = bundleId ?? uuidv4();

		const bundleObject: HardwareBundle = {
			id: id,
			products: mapToHardwareBundleInfo(productState.entities, countBySku),
			name: activeBundle?.name ?? "",
			description: activeBundle?.description ?? "",
			published: true,
			createdAt: activeBundle?.createdAt ?? new Date(),
		};
		return { [bundleObject.id]: bundleObject };
	};

	const currentPageIsCheckout = window.location.pathname.includes("checkout");

	const saveBundle = async (bundleId?: string) => {
		setIsLoading(true);
		setNameError("");

		if (!activeBundle?.published && currentNameIsNotUnique) {
			setNameError("Name must be unique");
			setIsLoading(false);
			return;
		}

		const body = {
			...allBundles,
			...createBundle(bundleId),
		};

		await dispatch(
			postHardwareBundle({
				auth,
				body: body,
			}),
		);

		setIsLoading(false);
		dispatch(setHardwareDrawerClosed());
		if (!currentPageIsCheckout) {
			// Do not send users to "My Bundles" page if they are on the checkout page
			navigateToPage(NAVIGATION_NAMES.HARDWARE_BUNDLES.path);
		}
	};

	const handleUpdateHardwareBundleInfo = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			setBundleInfo({
				...{ name: activeBundle?.name, description: activeBundle?.description },
				[e.target.name]: e.target.value,
			}),
		);
	};

	const maxCharsName = 25;

	return (
		<Grid container direction="column" className={styles.cartContainer}>
			<Grid container className={styles.textInputContainer}>
				<Grid container className={styles.textInputs}>
					<Grid container item flexDirection="column">
						<InputTextField
							name="name"
							placeholder="Bundle name"
							label="Bundle name"
							value={activeBundle?.name}
							onChange={(event) => {
								const isValid = event.target.value.length <= maxCharsName;
								setNameError(
									isValid
										? ""
										: `Name must be less than ${maxCharsName} characters`,
								);

								handleUpdateHardwareBundleInfo(
									event as ChangeEvent<HTMLInputElement>,
								);
							}}
							error={nameError !== ""}
							helperText={nameError}
						/>
					</Grid>
					<Grid container item flexDirection="column">
						<InputTextField
							name="description"
							placeholder="Bundle description"
							label="Bundle description (optional)"
							multiline={true}
							minRows={2}
							value={activeBundle?.description}
							onChange={(event) => {
								handleUpdateHardwareBundleInfo(
									event as ChangeEvent<HTMLInputElement>,
								);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container>
					<Typography variant="body1" pl={3}>
						{`Items in bundle (${cartItemsCount})`}
					</Typography>
				</Grid>
			</Grid>
			<Grid container className={styles.bundleItemsContainer}>
				{(!isProductsLoading
					? cartItems
					: Array.from({ length: cartItemsCount }, () => ({} as HardwareProductExtended))
				)?.map((cartItem) => (
					<SelectedHardwareListItem
						key={cartItem.sku}
						{...cartItem}
						count={countBySku[cartItem.sku]}
						deleteItem={() => dispatch(deleteBundleItem(cartItem.sku))}
						decrementCount={() => dispatch(removeItemFromBundle(cartItem.sku))}
						incrementCount={() => dispatch(addItemToBundle(cartItem.sku))}
						hardwarePurchaseType={hardwarePurchaseType}
					/>
				))}
				{!isProductsLoading && cartItems.length === 0 && (
					<Grid container className={styles.noItemsContainer}>
						<Typography variant="body1" mb={2}>
							No items in bundle
						</Typography>
						<FindBundleItemsButton />
					</Grid>
				)}
			</Grid>
			<Grid container className={styles.footer}>
				<Grid container alignItems="center" mb={2}>
					<Typography variant="body1" display="inline" fontWeight={500}>
						{formatCostString("Total: ", cartPriceTotal, " ,-")}
					</Typography>
					<Typography variant="caption" color="text.secondary" display="inline" pl={1}>
						{hardwarePurchaseType === HardwarePurchaseType.Buy
							? "excl. vat"
							: "per month"}
					</Typography>
				</Grid>
				<PrimaryButton
					text={activeBundle?.published ? "Save changes" : "Create bundle"}
					action={() => {
						saveBundle(activeBundle?.id);
					}}
					size="medium"
					variantStyle="contained"
					isLoading={isLoading}
					isDisabled={
						!activeBundle?.name ||
						activeBundle?.name.length === 0 ||
						cartItemsCount === 0 ||
						nameError !== ""
					}
				/>

				{activeBundle?.published && (
					<SecondaryButton
						text={
							<Grid container className={styles.deleteButtonContainer}>
								<DeleteIcon />
								<Typography>Delete bundle</Typography>
							</Grid>
						}
						size="medium"
						action={(e) => dispatch(setBundleIdToDelete(activeBundle.id))}
						variantStyle="contained"
						className={styles.deleteButton}
						isLoading={false}
					/>
				)}
				<DeleteBundleDialog />
			</Grid>
		</Grid>
	);
};

export { BundleCart };
