import { Chart } from "chart.js";
import { selectPreviewCostData, selectRecommendations } from "features/recommendations";
import { useAppSelector } from "hooks/hooks";
import { useEffect, useRef, useState } from "react";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

import SquareRoundedIcon from "@mui/icons-material/SquareRounded";

import styles from "./RecommendationsPreviewChart.module.scss";
import { Grid, Skeleton, Typography } from "@mui/material";

export const RecommendationsPreviewChart = () => {
	const [timer, setTimer] = useState(0);
	const { isLoading: isRecommendationsLoading } = useAppSelector(selectRecommendations);

	useEffect(() => {
		const interval = setInterval(() => {
			setTimer(timer + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [timer]);

	const { monthlyCost, monthlyCostAfterSavings, yearlySavings } =
		useAppSelector(selectPreviewCostData);

	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		let interval: any;
		const chart = new Chart(canvasRef?.current?.getContext("2d") as CanvasRenderingContext2D, {
			type: "bar",
			data: {
				labels: ["Estimated current cost", "Estimated cost after savings"],
				datasets: [
					{
						barPercentage: 0.4,
						label: "Cost per month",
						data: [monthlyCost, monthlyCostAfterSavings],
						backgroundColor: [
							!isRecommendationsLoading ? "#00608A" : "#dfdfdf",
							!isRecommendationsLoading ? "#47AE81" : "#dfdfdf",
						],
						borderWidth: 1,
					},
				],
			},
			options: {
				animation: {
					duration: isRecommendationsLoading ? 0 : 1000,
				},
				plugins: {
					legend: {
						display: false,
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						grid: {
							display: false,
						},
						beginAtZero: true,
					},
					y: {
						grid: {
							display: true,
						},
						border: {
							display: false,
						},
						ticks: {
							display: true,
							callback: function (value: any, index) {
								if (index === 0) {
									return null;
								}
								return this.getLabelForValue(value);
							},
							maxTicksLimit: 10,
						},
					},
				},
			},
		});

		if (isRecommendationsLoading) {
			chart.data.datasets[0].data = [200000, 130000];
			chart.update();
		} else {
			// When report generation finishes, clear the interval and set real data
			clearInterval(interval);

			// Update chart with real data
			chart.data.datasets[0].data = [monthlyCost, monthlyCostAfterSavings];
			chart.update();
		}

		// Clean up effect
		return () => {
			clearInterval(interval);
			chart.destroy();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [monthlyCost, monthlyCostAfterSavings]);

	return (
		<Grid container className={styles.container}>
			<Grid container className={styles.infoContainer}>
				<Grid container className={styles.yellowInfoContainer}>
					<Typography variant="body1" fontWeight={500}>
						IT for your employees required
					</Typography>
					<Typography variant="body1">
						As a part of the <b>Ironstone service “IT for your employees”</b> you get
						continuous in-depth license cost details and the ability to act on
						recommendations to save money, <b>directly in the portal!</b>
					</Typography>
				</Grid>
				<Grid container className={styles.costSavingInfoContainer}>
					<Typography variant="h1" textAlign={"center"}>
						Ironstone license optimization tool estimates
					</Typography>
					<Grid container className={styles.borderedContainer}>
						<Typography variant="h3" fontWeight={500} textAlign={"center"}>
							Potential savings
						</Typography>
						{!isRecommendationsLoading ? (
							<Typography className={styles.yugeGreenSavingsNumber}>
								{formatCostString("~ ", yearlySavings, " kr / year")}
							</Typography>
						) : (
							<Grid container justifyContent={"center"}>
								<Skeleton className={styles.skeleton} />
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid container className={styles.savingsContainer}>
					<Grid item className={styles.savingsItem}>
						<SquareRoundedIcon className={styles.blueIcon} />
						<Grid item>
							<Typography variant="body2" fontWeight={500}>
								Estimated current cost
							</Typography>
							<Typography variant="body1">
								{formatCostString("", monthlyCost, " kr / month ")}
							</Typography>
						</Grid>
					</Grid>
					<Grid item className={styles.savingsItem}>
						<SquareRoundedIcon className={styles.greenIcon} />
						<Grid item>
							<Typography variant="body2" fontWeight={500}>
								Estimated cost after savings
							</Typography>
							<Typography variant="body1">
								{formatCostString(
									"",
									Math.max(monthlyCostAfterSavings, 0) || 0,
									" kr / month",
								)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<div className={styles.chartContainer}>
				<canvas ref={canvasRef} />
			</div>
		</Grid>
	);
};
