import { useState } from "react";
import { Grid, Paper, Typography, List } from "@mui/material";
import { UpdatesCard } from "./UpdatesCard";
import { ContentItem } from "./ContentItem";
import { ServiceStatusItem } from "./ServiceStatusItem";
import { ServiceStatuses } from "./ServiceStatuses";
import { IRONSTONE_SUPPORT_URL, CUSTOMER_FACING_STATUS } from "utilities/constants/constants";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { TwoMonthsCostChart } from "components/Dashboard/TwoMonthsCostChart";
import { TwoMonthsCostChartLegend } from "components/Dashboard/TwoMonthsCostChart/TwoMonthsCostLegend";
import { ServiceStatusGroup, SecurityIncident } from "types";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { EscalateIncidentDialog } from "components/Dashboard/EscalateIncidentDialog/EscalateIncidentDialog";
import GppGoodIcon from "@mui/icons-material/GppGood";

import styles from "./Dashboard.module.scss";
import { SecurityIncidentsLegend } from "./SecurityIncidentsLegend";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { selectCustomerDetails } from "features/customer";
import { selectTotalCost } from "features/costs";
import { fetchAllCosts } from "actions/costActions";
import {
	closeSecurityIncident,
	escalateSecurityIncident,
	fetchSecurityIncidents,
} from "actions/securityIncidentActions";
import { securityIncidentSelectors, selectSecurityIncidents } from "features/securityIncidents";
import { selectServiceStatuses, serviceStatusSelectors } from "features/serviceStatuses";
import { fetchServiceStatuses } from "actions/serviceStatusActions";
import { SecurityIncidentSkeleton } from "./Skeleton/SecurityIncidentSkeleton";
import { ServiceStatusSkeleton } from "./Skeleton/ServiceStatusSkeleton";
import { usersSelectors } from "features/users";
import { ExternalLink } from "components/Common/Links";
import { CARD_STATUS_COLOR } from "utilities/constants/enums";

const Dashboard = (): JSX.Element => {
	const [isEscalateIncidentDialogOpen, setIsEscalateIncidentDialogOpen] = useState(false);
	const [isCloseIncidentDialogOpen, setIsCloseIncidentDialogOpen] = useState(false);
	const [selectedIncident, setSelectedIncident] = useState<SecurityIncident | undefined>(
		undefined,
	);

	const {
		dispatch,
		auth,
		auth: { account },
	} = useAuth();

	const userGraphData = useAppSelector((state) =>
		usersSelectors.selectById(state, account.idTokenClaims?.oid ?? ""),
	);

	const totalCost = useAppSelector(selectTotalCost);
	const totalCostState = useAppSelector(selectTotalCost);
	const securityIncidents = useAppSelector(securityIncidentSelectors.selectAll);
	const securityIncidentState = useAppSelector(selectSecurityIncidents);
	const serviceStatuses = useAppSelector(serviceStatusSelectors.selectAll);
	const serviceStatusState = useAppSelector(selectServiceStatuses);

	useApiOnce(fetchAllCosts, totalCostState);
	useApiOnce(fetchSecurityIncidents, securityIncidentState);
	useApiOnce(fetchServiceStatuses, serviceStatusState);

	const customerInfo = useAppSelector(selectCustomerDetails);

	const onEscalateClick = async (id: number) => {
		setSelectedIncident(
			securityIncidents.find((incident: SecurityIncident) => incident.id === id),
		);
		setIsEscalateIncidentDialogOpen(true);
	};

	const onCloseAlertClick = async (id: number) => {
		setSelectedIncident(
			securityIncidents.find((incident: SecurityIncident) => incident.id === id),
		);
		setIsCloseIncidentDialogOpen(true);
	};

	const onCloseIncident = async (id: number) => {
		setIsCloseIncidentDialogOpen(false);
		setSelectedIncident(undefined);
		await dispatch(
			closeSecurityIncident({
				auth,
				id,
				body: {
					status: "Resolved",
					resolution_summary: `This ticket was closed by ${userGraphData?.displayName} | ${userGraphData?.mail}`,
				},
			}),
		);
	};

	const onEscalateIncident = async (id: number) => {
		setIsEscalateIncidentDialogOpen(false);
		setSelectedIncident(undefined);
		await dispatch(
			escalateSecurityIncident({
				auth,
				id,
				body: {
					mail: userGraphData?.mail,
					displayName: userGraphData?.displayName,
					mobilePhone: userGraphData?.mobilePhone,
					status: "Open",
				},
			}),
		);
	};

	const sortIncidents = (a: SecurityIncident, b: SecurityIncident) => {
		const sortOrder = [
			CUSTOMER_FACING_STATUS.AWAITING_REPLY.id,
			CUSTOMER_FACING_STATUS.BEING_PROCESSED.id,
			CUSTOMER_FACING_STATUS.RESOLVED.id,
		];

		if (a.status === b.status) {
			return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
		}

		return (
			sortOrder.indexOf(a.customer_facing_status) -
			sortOrder.indexOf(b.customer_facing_status)
		);
	};

	const overallSecurityIncidentStatus = securityIncidents?.some(
		(incident: SecurityIncident) =>
			incident.customer_facing_status === CUSTOMER_FACING_STATUS.AWAITING_REPLY.id ||
			incident.customer_facing_status === CUSTOMER_FACING_STATUS.BEING_PROCESSED.id,
	)
		? CARD_STATUS_COLOR.RED
		: CARD_STATUS_COLOR.GREEN;

	const overallCostStatus =
		totalCost?.totalCost / totalCost?.totalCostPreviousMonth > 1.5
			? CARD_STATUS_COLOR.RED
			: CARD_STATUS_COLOR.GREEN;

	return (
		<Grid container className={styles.wrapper}>
			{isCloseIncidentDialogOpen && (
				<PrimaryDialog
					title="Close incident?"
					size="small"
					primaryButtonText="Close incident"
					secondaryButtonText="Cancel"
					onLeaveAction={() => setIsCloseIncidentDialogOpen(false)}
					primaryAction={() => onCloseIncident(selectedIncident?.id ?? 0)}
				>
					By closing, Ironstone will no longer monitor this incident.
				</PrimaryDialog>
			)}
			{isEscalateIncidentDialogOpen && (
				<EscalateIncidentDialog
					onCloseDialog={() => setIsEscalateIncidentDialogOpen(false)}
					onEscalateIncident={onEscalateIncident}
					incident={selectedIncident}
					isPremium={customerInfo.extendedSecurity === true}
				/>
			)}
			<Paper className={styles.container}>
				<Grid container spacing={4} className={styles.dashboardContainer}>
					<Grid item xs={4.75} className={styles.updatesCardContainer}>
						<UpdatesCard
							overallStatus={overallSecurityIncidentStatus}
							title="Security incidents"
							isLinkExternal={true}
							linkLabel="Go to ticket overview"
							linkTo={IRONSTONE_SUPPORT_URL}
						>
							<SecurityIncidentsLegend />
							<List classes={{ root: styles.updatesCardListContainer }}>
								{securityIncidentState.isLoading ? (
									<div className={styles.securityIncidentSkeletonWrapper}>
										{Array.from(Array(8)).map((_, i) => (
											<SecurityIncidentSkeleton key={i} />
										))}
									</div>
								) : securityIncidents?.length > 0 ? (
									securityIncidents.sort(sortIncidents).map((incident) => {
										return (
											<ContentItem
												key={incident.id}
												subject={incident.subject}
												customer_facing_status={
													incident.customer_facing_status
												}
												created_at={incident.created_at}
												updated_at={incident.updated_at}
												id={Number(incident.id)}
												isPremium={customerInfo.extendedSecurity === true}
												onEscalateClick={onEscalateClick}
												onCloseAlertClick={onCloseAlertClick}
											/>
										);
									})
								) : (
									<Grid className={styles.noIncidentsContainer} mt={3} px={2}>
										<GppGoodIcon className={styles.noIncidentsIcon} />
										<Typography variant="h3" textAlign={"center"}>
											Great job, no security incidents detected!
										</Typography>
									</Grid>
								)}
							</List>
						</UpdatesCard>
					</Grid>
					<Grid item xs={4.75} className={styles.updatesCardContainer}>
						<UpdatesCard
							overallStatus={overallCostStatus}
							title="Cost"
							isLinkExternal={false}
							linkLabel="Go to cost management"
							linkTo={NAVIGATION_NAMES.COST_MANAGEMENT.path}
						>
							<>
								<TwoMonthsCostChart />
								<TwoMonthsCostChartLegend />
							</>
						</UpdatesCard>
					</Grid>
					<Grid item xs={2.5} className={styles.updatesCardContainer}>
						<ServiceStatuses>
							<Typography classes={{ root: styles.serviceStatusTitle }} variant="h2">
								Service Status
							</Typography>
							{serviceStatusState.isLoading ? (
								<Grid container direction="column">
									{Array.from(Array(8)).map((_, i) => (
										<ServiceStatusSkeleton key={i} />
									))}
								</Grid>
							) : serviceStatuses ? (
								<Grid container>
									{serviceStatuses.map(
										(serviceStatusGroup: ServiceStatusGroup) => (
											<ServiceStatusItem
												key={serviceStatusGroup.name}
												name={serviceStatusGroup.name}
												// Todo fix baceknd from up and down to online and offline
												networkStatus={
													serviceStatusGroup.groupState === "UP"
														? "Online"
														: "Offline"
												}
											/>
										),
									)}
								</Grid>
							) : (
								<Grid container justifyContent="center" padding={3}>
									<Typography>No service statuses detected.</Typography>
								</Grid>
							)}
						</ServiceStatuses>
						<Grid item xs={12} className={styles.linkFooterContainer}>
							<ExternalLink
								textDecoration="underline"
								variant="body2"
								prefixedExternalLinkIcon
								href={IRONSTONE_SUPPORT_URL}
							>
								Go to service ticket overview
							</ExternalLink>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
};

export { Dashboard };
