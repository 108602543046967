import { Grid } from "@mui/material";
import { QueryableDropdownSelector } from "../QueryableDropdownSelector";
import { SCOPES } from "utilities/constants/constants";
import { getUsersForDropdown, getGroupsForDropdown } from "components/api/searchUserOrGroups";
import { SelectedUser } from "types";
import { ConfiguratorUserTypes } from "utilities/constants/enums";

import styles from "./UserConfigurator.module.scss";
import { useAuth } from "hooks/hooks";

type DataTypeName =
	| "internalCollaborationData"
	| "internalProjectData"
	| "externalCollaborationData"
	| "externalProjectData";

type QueryableCustomOptions = {
	displayName: string;
	type: string;
	id: string;
};

interface UserConfiguratorProps {
	dataTypeName: DataTypeName;
	propertyName: ConfiguratorUserTypes;
	selectedUsers: SelectedUser[];
	handleChange: (
		dataTypeName: DataTypeName,
		propertyName: ConfiguratorUserTypes,
		updatedUsers: SelectedUser[],
	) => void;
	enabled: boolean;
	customOptions?: QueryableCustomOptions[];
}

const UserConfigurator = ({
	dataTypeName,
	propertyName,
	selectedUsers,
	handleChange,
	enabled,
	customOptions,
}: UserConfiguratorProps): JSX.Element => {
	const {
		auth: { instance, account, authConfig },
	} = useAuth();

	const fetchUsersAndGroupsByQuery = async (searchValue: string): Promise<SelectedUser[]> => {
		const token = await instance.acquireTokenSilent({
			scopes: [SCOPES.GRAPH_READ],
			account: account,
		});

		const users: SelectedUser[] = await getUsersForDropdown({
			token,
			url: authConfig.MS_GRAPH_API_ENDPOINTS.GET_USERS(),
			subscriptionKey: authConfig.OCP_APIM_SUBSCRIPTION_KEY,
			searchValue,
		});
		const groups: Pick<SelectedUser, "id" | "displayName" | "type">[] =
			await getGroupsForDropdown({
				token,
				url: authConfig.MS_GRAPH_API_ENDPOINTS.GET_GROUPS(),
				subscriptionKey: authConfig.OCP_APIM_SUBSCRIPTION_KEY,
				searchValue,
				groupType: "SharePoint",
			});

		if (users === null || groups === null) {
			return [];
		}
		return [...users, ...groups] as SelectedUser[];
	};

	const handleOnClick = (updatedUsers: SelectedUser[]): void => {
		handleChange(dataTypeName, propertyName, updatedUsers);
	};

	return (
		<Grid container className={styles.container} alignItems="center" justifyContent="center">
			<Grid item xs={12}>
				{enabled && (
					<QueryableDropdownSelector
						configuratorUserType={propertyName}
						currentSelectedItems={selectedUsers}
						handleOnClick={handleOnClick}
						fetchData={fetchUsersAndGroupsByQuery}
						customOptions={customOptions}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export { UserConfigurator };
