import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { createOrder, fetchOrders } from "actions/orderActions";
import { RootState } from "store";
import _ from "lodash";
import { Order } from "types";
import dayjs, { Dayjs } from "dayjs";
import { monthsSinceDateInRangeInclusive } from "utilities/dates/dates";

const ordersAdapter = createEntityAdapter<Order>({
	selectId: ({ orderNumber }) => orderNumber,
});

interface InitialState {
	searchIds: string[];
	isLoading: boolean;
	isFetching: boolean;
}

const ordersSlice = createSlice({
	name: "orders",
	initialState: ordersAdapter.getInitialState({
		searchIds: ["Initial"],
		isLoading: true,
		isFetching: false,
	} as InitialState),
	reducers: {
		setSearchedOrders(state, { payload }) {
			state.searchIds = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchOrders.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchOrders.fulfilled, (state, { payload }) => {
				const mapped = payload.map((order) => mapOrders(order));
				ordersAdapter.upsertMany(state, mapped);
				state.isFetching = false;
				state.isLoading = false;
			});
		builder.addCase(createOrder.fulfilled, (state, { payload }) => {
			ordersAdapter.addOne(state, payload);
		});
	},
});

export const { setSearchedOrders } = ordersSlice.actions;

export const ordersSelectors = ordersAdapter.getSelectors<RootState>((state) => state.orders);

export const selectOrders = (state: RootState) => state.orders;
export const selectOrderPeriods = createSelector(ordersSelectors.selectAll, (orders) =>
	_(orders)
		.map(({ orderIssueDate }) => dayjs(orderIssueDate).format("MMMM YYYY"))
		.uniq()
		.value(),
);

const selectSearchedOrderIds = (state: RootState) => state.orders.searchIds;

export const selectSearchedOrders = createSelector(
	ordersSelectors.selectEntities,
	selectSearchedOrderIds,
	(ordersById, ids) => {
		if (ids.length === 1 && ids[0] === "Initial") return _.values(ordersById) as Order[];
		return _.values(_.pick(ordersById, ids)) as Order[];
	},
);

const createOrdersCostSelector = (predicate: any) =>
	createSelector(ordersSelectors.selectAll, (orders) => {
		const totals = _.chain(orders)
			.map((order) => ({ ...order, orderIssueDate: dayjs(order.orderIssueDate) }))
			.filter(predicate) // Filter first to prevent expensive operations on all items (i.e., groupBy, sumBy, reduce, orderBy)
			.groupBy(({ orderIssueDate }) => orderIssueDate.format("MMMM YYYY")) // Include year for correct sorting
			.mapValues((orders) =>
				_.sumBy(
					orders.flatMap(({ orderedItems }) => orderedItems),
					({ price, quantity }) => price * quantity,
				),
			) // Find total for period
			.reduce(
				(acc, totalCost, period) => [...acc, { period, totalCost }],
				[] as { period: string; totalCost: number }[],
			)
			.orderBy(({ period }) => dayjs(period), "desc")
			.value();
		const currentMonth = dayjs().format("MMMM YYYY");
		if (!totals.find(({ period }) => period === currentMonth))
			totals.push({ period: currentMonth, totalCost: 0 }); // Add current month if not present
		return totals;
	});

type OrderWithDayjsDate = Omit<Order, "orderIssueDate"> & { orderIssueDate: Dayjs };

export const selectHardwareCostCurrentAndLastMonth = createOrdersCostSelector(
	({ orderIssueDate }: OrderWithDayjsDate) =>
		monthsSinceDateInRangeInclusive(orderIssueDate, 0, 1),
);

export const selectHardwareCostLastFiveMonths = createOrdersCostSelector(
	({ orderIssueDate }: OrderWithDayjsDate) =>
		monthsSinceDateInRangeInclusive(orderIssueDate, 1, 5),
);

export default ordersSlice.reducer;

const mapOrders = (order: Order) => {
	const uniqueOrderedItemsWithCountPerProductId = Object.values(
		order.orderedItems.reduce(
			(acc, item) => ({
				...acc,
				[item.productId]: {
					...item,
					quantity: (acc[item.productId]?.quantity ?? 0) + item.quantity,
				},
			}),
			{} as { [productId: string]: { quantity: number } },
		),
	);

	return {
		...order,
		orderedItems: uniqueOrderedItemsWithCountPerProductId,
	} as Order;
};
