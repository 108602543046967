import {
	Grid,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {
	selectCurrentMarketplaceItemsCost,
	selectCurrentReservedInstancesCost,
	selectMarketplaceItemsCost,
	selectReservedInstancesCost,
} from "features/costs";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import type { ReactElement } from "react";
import styles from "./AzureCostTable.module.scss";
import _ from "lodash";
import { fetchMarketplaceItemsCost, fetchReservedInstancesCost } from "actions/costActions";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { BillableLine } from "types";
import { TotalCostCard } from "../TotalCostCard/TotalCostCard";

export const MarketplaceItemsTable = (): ReactElement => {
	const { totalForecasted: { exclVat: total = 0 } = {}, billableLines = [] } =
		useAppSelector(selectCurrentMarketplaceItemsCost) ?? {};

	const { isLoading, isFetching } = useAppSelector(selectMarketplaceItemsCost);
	useApiOnce(fetchMarketplaceItemsCost, { isLoading, isFetching });

	return (
		<AzureCostTable
			{...{ title: "Marketplace items", total, isLoading, billableLines }}
		></AzureCostTable>
	);
};

export const ReservedInstancesTable = (): ReactElement => {
	const { totalForecasted: { exclVat: total = 0 } = {}, billableLines = [] } =
		useAppSelector(selectCurrentReservedInstancesCost) ?? {};

	const { isLoading, isFetching } = useAppSelector(selectReservedInstancesCost);
	useApiOnce(fetchReservedInstancesCost, { isLoading, isFetching });

	return (
		<AzureCostTable
			{...{ title: "Reserved instances", total, isLoading, billableLines }}
		></AzureCostTable>
	);
};

interface AzureCostTableProps {
	title: string;
	total: number;
	billableLines: BillableLine[];
	isLoading: boolean;
}

const AzureCostTable = ({
	title,
	total,
	billableLines,
	isLoading,
}: AzureCostTableProps): ReactElement => {
	return (
		<Grid container p={3}>
			<Grid item xs={12}>
				<Typography variant="h2">{title}</Typography>
				<TotalCostCard sum={total} />
			</Grid>
			<TableContainer className={styles.tableContainer}>
				<Table>
					<TableHead className={styles.tableHead}>
						<TableRow>
							<TableCell>
								<Typography variant="body1" fontWeight={500} noWrap>
									Name
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1" fontWeight={500} noWrap>
									Quantity
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="body1" fontWeight={500} noWrap>
									Total cost
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{!isLoading && _.size(billableLines) === 0 && (
							<TableRow classes={{ root: styles.rowStyle }}>
								<TableCell colSpan={3}>
									<Typography variant="body1" align="center">
										No subscriptions found
									</Typography>
								</TableCell>
							</TableRow>
						)}
						{!isLoading
							? billableLines.map(
									({
										subscriptionId,
										subscriptionName,
										quantity,
										totalForecasted,
										unitPrice,
									}) => (
										<TableRow
											key={subscriptionId}
											classes={{ root: styles.rowStyle }}
										>
											<TableCell>
												<Typography variant="body1" noWrap>
													{subscriptionName}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" noWrap>
													{quantity}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" noWrap align="right">
													{formatCostString(
														"",
														totalForecasted?.exclVat ??
															unitPrice?.exclVat * quantity ??
															0,
														",-",
													)}
												</Typography>
											</TableCell>
										</TableRow>
									),
							  )
							: _.range(3).map((key) => (
									<TableRow key={key}>
										{_.range(3).map((key) => (
											<TableCell key={key}>
												<Skeleton variant="text" height={"8vh"} />
											</TableCell>
										))}
									</TableRow>
							  ))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
};
