import { Grid, MenuItem, Select, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useAppSelector } from "hooks/hooks";
import { selectCurrentAzureCost } from "features/costs";
import { DownloadableLink } from "components/Common/DownloadableLink";
import styles from "./AzureCostDetails.module.scss";
import { CostChart } from "../../CostChart";
import { ChartBarProp } from "types";
import { useState } from "react";
import _ from "lodash";
import { DataUpdateInterval } from "components/Common/DataUpdateInterval";
import { TotalCostCard } from "../TotalCostCard/TotalCostCard";
import clsx from "clsx";

const AzureCostDetails = () => {
	const { isLoading, billingDataCurrent } = useAppSelector(selectCurrentAzureCost);

	const now = dayjs(billingDataCurrent.toDate);
	const fromDate = dayjs(billingDataCurrent.fromDate);

	const lastMonthToDate = dayjs(billingDataCurrent.lastMonthToDate);
	const lastMonthFromDate = dayjs(billingDataCurrent.lastMonthFromDate);

	const toDayAndMonth = (date: Dayjs) => date.format("MMM DD.");
	const toMonth = (date: Dayjs) => date.format("MMMM");

	const currentInterval = `${toDayAndMonth(fromDate)} - ${toDayAndMonth(now)}`;

	const lastMonthInterval = `${toDayAndMonth(lastMonthFromDate)} - ${toDayAndMonth(
		lastMonthToDate,
	)}`;

	const currentMonth = `${toMonth(now)}`;
	const lastMonth = `${toMonth(now.subtract(1, "month"))}`;

	const chartBarLabelByProperty = {
		totalPreTax: `Current this month (${currentInterval})`,
		restOfMonthForecast: `Predicted total this month (${currentMonth})`,
		lastMonthTotalPreTax: `Last month (${lastMonthInterval})`,
		restOfLastMonthTotal: `Total last month (${lastMonth})`,
	};

	type AzureCostType = "Subscriptions" | "Resource groups" | "Resources" | "Tags";

	const costs = {
		Subscriptions: {
			headerText: "Subscriptions",
			chartData: billingDataCurrent.subscriptions,
			chartBarPropertyName: "subscriptionName" as ChartBarProp,
			chartBarLabelByProperty,
			isLoading,
		},
		"Resource groups": {
			headerText: "Resource groups",
			chartData: billingDataCurrent.resourceGroups,
			chartBarPropertyName: "resourceGroupName" as ChartBarProp,
			chartBarLabelByProperty,
			isLoading,
		},
		Resources: {
			headerText: "Resources",
			chartData: billingDataCurrent.resources,
			chartBarPropertyName: "resourceName" as ChartBarProp,
			chartBarLabelByProperty,
			isLoading,
		},
		Tags: {
			headerText: "Tags",
			chartData: billingDataCurrent.tags,
			chartBarPropertyName: "tagCombination" as ChartBarProp,
			chartBarLabelByProperty,
			isLoading,
		},
	};

	const [selectedCost, setSelectedCost] = useState<AzureCostType>("Subscriptions");

	const SelectAzureCost = () => (
		<Grid item xs={12} md={3} mb={3}>
			<Typography variant="body1" noWrap pb={0.5} width="fit-content">
				Group Azure cost by
			</Typography>
			<Select
				value={selectedCost}
				onChange={({ target: { value } }) => {
					setSelectedCost(value as AzureCostType);
				}}
				className={styles.selectedCost}
				fullWidth
			>
				{_.values(costs).map(({ headerText }) => (
					<MenuItem key={headerText} value={headerText}>
						<Grid container justifyContent="space-between">
							<Typography variant="body1">{headerText}</Typography>
						</Grid>
					</MenuItem>
				))}
			</Select>
		</Grid>
	);

	const costProps = { ...costs[selectedCost], header: <SelectAzureCost /> };

	const downloadableLink = () => (
		<DownloadableLink
			data={costProps?.chartData}
			fileName={`${costProps?.headerText.toLowerCase()}-data.csv`}
			shouldFormatNumbers={true}
		/>
	);

	return (
		<Grid container p={4}>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h2">Azure consumption</Typography>
					<Grid container justifyContent="space-between" alignItems="flex-end">
						<Grid item>
							<TotalCostCard
								sum={_(costProps.chartData).values().sumBy("totalPreTax")}
							/>
							<div className={styles.dowloadableLinkForSmallerScreens}>
								{downloadableLink()}
							</div>
						</Grid>
						<Grid item mb={3} mr={3} className={styles.dataUpdateInterval}>
							<DataUpdateInterval
								updateIntervalText={"Cost data updates every day"}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						className={clsx(styles.container, styles.costChart)}
						p={4}
						pb={8}
					>
						<Grid item xs={12}>
							<CostChart {...costProps} />
						</Grid>
						<Grid item mt={8}>
							{costProps?.chartData && downloadableLink()}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export { AzureCostDetails };
