import { licensePricesSelectors } from "features/licenses/licensePrices";
import { useAppSelector, useWindowSize } from "hooks/hooks";

import styles from "./BuyLicensesSummaryTable.module.scss";
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { TermDurationLabel } from "components/CostManagement/LicenseCostView/LicenseCostTable/TermDurationLabel";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { theme } from "theme";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { LicensePrice, LicensePurchase } from "types";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import { getSummarizedPriceObject } from "utilities/licenseUtils/licensePriceUtils";

interface BuyLicensesSummaryTableProps {
	licenses: LicensePurchase[];
}

/**
 * @param licenses, @see {@link LicenseForRow} for details
 * returns a table with cost per license, quantity, term duration and total cost
 * of all licenses
 */
const BuyLicensesSummaryTable = ({ licenses }: BuyLicensesSummaryTableProps) => {
	const licensePrices = useAppSelector(licensePricesSelectors.selectEntities);

	const { totalPrice, costPeriod } = getSummarizedPriceObject(licenses, licensePrices);

	const { width } = useWindowSize();

	const [showTermDuration, setShowTermDuration] = useState<boolean>(false);

	useEffect(() => {
		setShowTermDuration(width > theme.breakpoints.values.sm);
	}, [width]);

	const cellWidths = {
		licenseName: showTermDuration ? "40%" : "15%",
		quantity: "10%",
		price: showTermDuration ? 130 : 200,
	};

	return (
		<TableContainer className={styles.tableContainer}>
			<Table className={styles.summaryTable}>
				<TableHead>
					<TableRow>
						<TableCell width={cellWidths.licenseName}>
							<Typography variant="description">License</Typography>
						</TableCell>
						<TableCell width={cellWidths.quantity}>
							<Typography variant="description">Quantity</Typography>
						</TableCell>
						<TableCell
							className={clsx({ [styles.hide]: !showTermDuration })}
							align="center"
						>
							<Typography variant="description" noWrap>
								Duration
							</Typography>
						</TableCell>
						<TableCell width={cellWidths.price}>
							<Typography variant="description">Price</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{licenses.map((license) => {
						const priceObject =
							licensePrices[license.costIdentifier] ?? ({} as LicensePrice);
						return (
							<TableRow className={styles.row} key={license.friendlyName}>
								<TableCell width={cellWidths.licenseName}>
									<TruncatableTypography variant="description" maxCharLength={20}>
										{license.friendlyName}
									</TruncatableTypography>
								</TableCell>
								<TableCell width={cellWidths.quantity} align="right">
									<Typography variant="description">
										{license.quantity}
									</Typography>
								</TableCell>
								<TableCell
									className={clsx({ [styles.hide]: !showTermDuration })}
									align="center"
								>
									<TermDurationLabel label={license.termDuration} />
								</TableCell>
								<TableCell align="right" width={cellWidths.price}>
									<Grid item className={styles.priceContainer}>
										<Typography variant="description" noWrap>
											{formatCostString(
												"",
												Math.round(
													priceObject?.UnitPriceAfterDiscount ?? 0,
												),
												" ,-",
											)}
										</Typography>
										<Typography variant="caption" color="text.secondary" noWrap>
											{` / ${priceObject?.CostPeriod ?? ""}`}
										</Typography>
									</Grid>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow className={styles.lastRow}>
						<TableCell colSpan={showTermDuration ? 3 : 2}>
							<Typography variant="description">Total</Typography>
						</TableCell>
						<TableCell align="right">
							<Grid item className={styles.priceSummary}>
								<Typography variant="description" noWrap>
									{formatCostString("", totalPrice, " ,- ")}
								</Typography>
								<Typography variant="caption" color="text.secondary" noWrap>
									{` / ${costPeriod}`}
								</Typography>
							</Grid>
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};

export { BuyLicensesSummaryTable };
