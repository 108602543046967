import { Grid, Skeleton, Typography } from "@mui/material";
import {
	OpenDrawerButtonContent,
	OpenDrawerPrimaryButton,
} from "components/Common/Buttons/Button/Button";

import styles from "./LicenseRemovalCard.module.scss";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { LicenseAction, LicenseSubscriptionRecommendation } from "types";
import {
	CostSavingsType,
	LicenseActionStatus,
	LicenseActionTargetType,
} from "utilities/constants/enums";
import {
	licenseActionsSelector,
	selectLicenseActions,
	selectNumScheduledActionsForSku,
	setLicenseActionSkuFilter,
} from "features/scheduledActions";
import { useAppSelector, useAuth } from "hooks/hooks";
import clsx from "clsx";
import { setIsScheduledActionsDialogOpen } from "features/recommendations";
import { ScheduledActionsPill } from "components/Common/ScheduledActionsPill";
import useRole from "utilities/roleUtils/roleCheck";

interface LicenseRemovalCardInputProps {
	recommendation: LicenseSubscriptionRecommendation;
	costSavingsUnused: number;
	handleDrawerStateChange: (open: boolean, type: CostSavingsType) => void;
}

export const LicenseRemovalCard = ({
	recommendation,
	costSavingsUnused,
	handleDrawerStateChange,
}: LicenseRemovalCardInputProps) => {
	const { dispatch } = useAuth();
	let { isLoading: isLicenseActionsLoading } = useAppSelector(selectLicenseActions);
	const licenseActions = useAppSelector(licenseActionsSelector.selectAll) ?? [];
	const numScheduledOnSku = useAppSelector(
		selectNumScheduledActionsForSku(recommendation.subscriptionSkuID),
	);

	const savings = costSavingsUnused;
	const title = "Unused licenses";
	const buttonText = "Configure licenses";
	const { numLicensesScheduledForCancellation } = getScheduledActionsStatistics(
		licenseActions,
		recommendation.subscriptionSkuID,
	);
	const idleCount = recommendation.current_Idle;
	const maxRemovable = recommendation.removable_Licenses - recommendation.current_Idle;
	const unassignedCount = Math.max(maxRemovable - numLicensesScheduledForCancellation, 0);
	const idleOptimized = idleCount <= 0;
	const unassignedOptimized = unassignedCount <= 0;
	const totalOptimized = idleOptimized && unassignedOptimized;

	const handleScheduledActionsDialogOpen = (id: string) => {
		dispatch(setLicenseActionSkuFilter(id));
		dispatch(setIsScheduledActionsDialogOpen(true));
	};

	const { isActionRole } = useRole();

	const getContent = () => {
		if (totalOptimized) {
			return (
				<Grid item xs={12} className={styles.cardContent}>
					<ScheduledActionsPill
						variant="large"
						handleScheduledActionsDialogOpen={() =>
							handleScheduledActionsDialogOpen(recommendation.subscriptionSkuID)
						}
						numScheduledLicenseActionGroups={numScheduledOnSku}
					/>
					<Typography variant="body1">{title}</Typography>
					<Grid container mt={3} justifyContent="center">
						<Grid item xs={12}>
							<Typography
								variant="h2"
								display="inline"
								className={styles.optimizedText}
							>
								0
							</Typography>
							<Typography
								variant="description"
								className={styles.licenseNotInUseDescription}
							>
								{`licenses left to remove`}
							</Typography>
						</Grid>
						<Grid container mt={3}>
							<span className={styles.optimized}>Optimized</span>
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return (
			<Grid item xs={12} className={styles.cardContent}>
				<ScheduledActionsPill
					variant="large"
					handleScheduledActionsDialogOpen={() =>
						handleScheduledActionsDialogOpen(recommendation.subscriptionSkuID)
					}
					numScheduledLicenseActionGroups={numScheduledOnSku}
				/>
				<Grid item xs={12}>
					<Typography variant="body1">{title}</Typography>
				</Grid>

				<Grid container className={styles.midSectionContainer}>
					<Grid item xs={12}>
						{!isLicenseActionsLoading ? (
							<>
								<Typography
									variant="h3"
									display="inline"
									className={clsx({
										[styles.optimizedText]: unassignedOptimized,
									})}
								>
									{unassignedCount}
								</Typography>

								<Typography
									variant="description"
									className={styles.licenseNotInUseDescription}
								>
									unused licenses
								</Typography>
							</>
						) : (
							<div className={styles.skeletonText}>
								<Skeleton variant="text" width={200} height={28} animation="wave" />
							</div>
						)}
					</Grid>
					<Grid item xs={12}>
						{!isLicenseActionsLoading ? (
							<>
								<Typography
									variant="h3"
									display="inline"
									className={clsx({
										[styles.optimizedText]: idleOptimized,
									})}
								>
									{idleCount}
								</Typography>
								<Typography
									variant="description"
									className={styles.licenseNotInUseDescription}
								>
									inactive users
								</Typography>
							</>
						) : (
							<div className={styles.skeletonText}>
								<Skeleton variant="text" width={200} height={28} animation="wave" />
							</div>
						)}
					</Grid>
					<Grid item xs={12}>
						<Typography
							variant="description"
							fontWeight={500}
							className={styles.potentialSavings}
						>
							{`${formatCostString("Save up to ", `${savings * 12}`, " kr / year")}`}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12} maxHeight={70}>
					{!isLicenseActionsLoading ? (
						<OpenDrawerPrimaryButton
							text={<OpenDrawerButtonContent text={buttonText} />}
							action={() =>
								handleDrawerStateChange(true, CostSavingsType.RemoveUnused)
							}
							size="small"
							variantStyle={`${isActionRole ? "outlined" : "contained"}`}
							isDisabled={!isActionRole}
							disabledDescription="You do not have permission to configure licenses"
						/>
					) : (
						<div className={styles.skeletonButton}>
							<Skeleton variant="text" width={200} height={80} animation="wave" />
						</div>
					)}
				</Grid>
			</Grid>
		);
	};

	return <>{getContent()}</>;
};

const getScheduledActionsStatistics = (
	licenseActions: LicenseAction[],
	subscriptionSkuID: string,
) => {
	const scheduledActions = licenseActions.filter((licenseAction) => {
		// TODO: Figure out why this method had an if clause that checked if the action was ordered today
		// if (dayjs(licenseAction.OrderedAt).isSame(dayjs(), "day")) return false; ????
		if (licenseAction.Status !== LicenseActionStatus.Scheduled) return false;

		if (licenseAction.SkuGUID !== subscriptionSkuID) return false;
		return true;
	});

	return scheduledActions.reduce(
		(acc, action) => {
			if (action.TargetType === LicenseActionTargetType.Subscription) {
				return {
					...acc,
					numLicensesScheduledForCancellation:
						acc.numLicensesScheduledForCancellation + Math.abs(action.QuantityChange),
				};
			}
			return acc;
		},
		{
			numLicensesScheduledForCancellation: 0,
		} as {
			numLicensesScheduledForCancellation: number;
		},
	);
};
