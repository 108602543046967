import { Favorite, Search } from "@mui/icons-material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
	Autocomplete,
	CircularProgress,
	Dialog,
	Grid,
	InputAdornment,
	InputLabel,
	Skeleton,
	TextField,
	Typography,
} from "@mui/material";
import { Input } from "components/Input";
import { SummaryCart } from "components/MyPeople/EmployeeDialogSteps/Summary/SummaryCart";
import { ShippingDetails } from "components/MyPeople/EmployeeDialogSteps/UserDetails/ShippingDetails";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import styles from "./HardwareCheckout.module.scss";
import _ from "lodash";
import HardwareCheckoutComplete from "../HardwareCheckoutComplete/HardwareCheckoutComplete";
import { MobilePhoneInput } from "components/MobilePhoneInput";
import { PrimaryFormButton, SecondaryButton } from "components/Common/Buttons/Button";
import {
	mapObjectStringValues,
	trimAndRemoveDuplicateSpacesBetweenWords,
} from "utilities/stringFormattingUtility";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import {
	productsSelectors,
	selectCartCountBySku,
	selectCartCount,
	selectCartPriceTotal,
} from "features/hardware/products";
import { fetchManager, fetchUsers, sendMail } from "actions/userActions";
import { createOrder, createOrderRequest } from "actions/orderActions";
import { selectManager, selectUsers, usersSelectors } from "features/users";
import { CustomerLocation, HardwareApprover, User } from "types";
import { addItemsfromPurchaseCart } from "features/hardware/hardwareBundles";
import { HardwareDrawer } from "components/Hardware/SelectHardware/HardwareDrawer";
import { selectHardwarePurchaseType, setHardwareDrawerOpen } from "features/hardware/hardwarePage";
import {
	DeliveryOption,
	HardwareDrawerType,
	HardwarePurchaseType,
} from "utilities/constants/enums";
import { selectCustomerDetails, selectCustomerLocations } from "features/customer";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { DeliveryOptions } from "./DeliveryOptions";
import clsx from "clsx";
import { getApprovers } from "utilities/hardwareApprovers";
import { RootState } from "store";
import useRole from "utilities/roleUtils/roleCheck";
import { selectHardwareApprovers } from "features/hardware/hardwareApprovers";
import { fetchHardwareApprovers } from "actions/hardwareApproversActions";
import { fetchCustomerLocations } from "actions/customerActions";
import { DeliveryLocationSelector } from "./DeliveryLocationSelector";
import { calculateHardwarePrice } from "utilities/hardwareUtils/hardwareCostUtils";
import { IRONSTONE_SUPPORT_MAIL } from "utilities/constants/constants";

interface Props {
	onFinishCheckout: () => void;
}

const HardwareCheckout = ({ onFinishCheckout }: Props) => {
	const customerInfo = useAppSelector(selectCustomerDetails);
	const customerLocations = useAppSelector(selectCustomerLocations);
	const [selectedUser, setSelectedUser] = useState<User>();
	const [selectedDeliveryLocation, setSelectedDeliveryLocation] =
		useState<CustomerLocation | null>(null);
	const hardwarePurchaseType = useAppSelector(selectHardwarePurchaseType);

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [orderFailed, setOrderFailed] = useState(false);
	const [showCartDialog, setShowCartDialog] = useState(false);
	const userState = useAppSelector(selectUsers);
	const allUsers = useAppSelector(usersSelectors.selectEntities);

	const {
		dispatch,
		auth,
		auth: {
			account: { localAccountId },
		},
	} = useAuth();

	const { isActionRole, isAdmin } = useRole();

	const userGraphData = useAppSelector((state) =>
		usersSelectors.selectById(state, localAccountId),
	);

	const { isLoading: isManagerOfThisUserLoading } = useAppSelector((state: RootState) =>
		selectManager(state, localAccountId),
	);

	const productsById = useAppSelector(productsSelectors.selectEntities);
	const cartItems = useAppSelector(selectCartCountBySku);
	const cartItemsCount = useAppSelector(selectCartCount);
	const cartPriceTotal = useAppSelector(selectCartPriceTotal);

	const users = useAppSelector(usersSelectors.selectAll);

	useApiOnce(fetchUsers, useAppSelector(selectUsers));
	useApiOnce(fetchCustomerLocations, customerLocations);

	const approversState = useAppSelector(selectHardwareApprovers);
	useApiOnce(fetchHardwareApprovers, approversState);

	useEffect(() => {
		if (!userGraphData?.id) return;

		dispatch(fetchManager({ auth, id: userGraphData?.id }));
	}, [dispatch, auth, userGraphData?.id]);

	const defaultProps = {
		options: users,
		getOptionLabel: (option: User) => option.displayName ?? "",
		isOptionEqualToValue: (option: User, value: User) => option.id === value.id,
	};

	const formProps = useForm<FieldValues>({
		mode: "onSubmit",
	});

	const {
		control,
		register,
		handleSubmit,
		formState: { errors, touchedFields },
		setValue,
		reset,
		getValues,
		watch,
	} = formProps;

	const handleSelectUser = (user: User) => {
		// We need to reset the touchedFields, so that the useEffect below will
		// consider these values as not touched yet, and overwrite current values with selected user values
		setSelectedUser(user);
		const valuesToSet = {
			id: user.id,
			mail: user.mail ?? user.userPrincipalName,
			mobilePhone: user.mobilePhone ?? user.businessPhones?.find(Boolean) ?? "+47",
			givenName: user.givenName,
			surname: user.surname,
			streetAddress: "",
			postalCode: "",
			city: "",
		};
		Object.entries(valuesToSet).forEach(([key, value]) => {
			touchedFields[key] = true;
			setValue(key, value);
		});
	};

	const deliveryOption = watch("deliveryOption");

	useEffect(() => {
		let userData;
		const allValues = getValues();
		if (allValues.deliveryOption === DeliveryOption.B2B) {
			userData = {
				...userGraphData,
				allValues,
			};
		} else if (allValues.deliveryOption === DeliveryOption.B2CHomeDelivery) {
			userData = selectedUser ?? {};
			setSelectedDeliveryLocation(null);
		}

		const { id, givenName, surname, mail, userPrincipalName, mobilePhone, businessPhones } =
			userData ?? {};

		// Get the values of the fields that have been touched
		const touchedFieldValues = Object.keys(touchedFields).reduce((acc, key) => {
			if (touchedFields[key]) {
				acc[key] = allValues[key];
			}
			return acc;
		}, {} as { [key: string]: any });

		// Certain values should be persisted when swapping between delivery options
		const valuesToPersist = {
			deliveryOption: allValues.deliveryOption,
			country: allValues.country,
			companyName: allValues.companyName,
			note: allValues.note,
			...touchedFieldValues,
		};

		// And certain values should be set when swapping between delivery options
		const valuesToSet = {
			id,
			givenName,
			surname,
			mail: mail ?? userPrincipalName,
			mobilePhone: mobilePhone ?? businessPhones?.find(Boolean) ?? "+47",
			streetAddress: "",
			postalCode: "",
			city: "",
		};

		// Persisted values set last, so that they overwrite the values to set
		const finalValues = {
			...valuesToSet,
			...valuesToPersist,
		};

		Object.entries(finalValues).forEach(([key, value]) => {
			setValue(key, value);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedUser,
		userGraphData,
		setValue,
		customerInfo.departmentName,
		getValues,
		deliveryOption,
	]);

	const inputs = [
		{
			name: "givenName",
			label: "First name",
		},
		{
			name: "surname",
			label: "Last name",
		},
		{
			name: "mail",
			label: (
				<>
					<Typography variant="body1" display="inline" mr={1}>
						Email
					</Typography>
					<Typography variant="caption" color="text.secondary" display="inline">
						(Receiver of shipping information)
					</Typography>
				</>
			),
		},
	];

	const handleCheckout = async () => {
		const employeeData = getValues();

		mapObjectStringValues(employeeData, trimAndRemoveDuplicateSpacesBetweenWords);

		const {
			id,
			givenName,
			surname,
			mail,
			mobilePhone,
			city,
			streetAddress,
			postalCode,
			messageToApprover,
			deliveryOption,
			country,
			note,
			companyName,
		} = employeeData;

		setIsSubmitting(true);

		const currency = productsById[Object.keys(cartItems)[0]]?.priceInfo.currency;

		const orderLines = Object.entries(cartItems).map(([sku, quantity]) => {
			const { price } = calculateHardwarePrice(
				productsById[sku]?.priceInfo,
				hardwarePurchaseType,
			);
			const { displayName } = productsById[sku] ?? {};
			return {
				sku,
				quantity,
				displayName,
				price: String(price),
				link: `https://www.komplettbedrift.no/product/${sku}`,
			};
		});

		const order = {
			messageToSalesRep: "",
			purchaserFirmName:
				deliveryOption === DeliveryOption.B2B ? companyName : `${givenName} ${surname}`,
			deliveryOption,
			isLeasingOrder: hardwarePurchaseType === HardwarePurchaseType.Lease,
			purchaserFirmAddress: streetAddress,
			purchaserFirmPostalCode: postalCode,
			purchaserFirmCity: city,
			purchaserFirmCountryCode: country?.toUpperCase() === "SWEDEN" ? "SE" : "NO", // Default to Norway if country is undefined or not supported
			contactPersonName: `${givenName} ${surname}`,
			contactPersonEmail: mail,
			contactPersonPhone: mobilePhone,
			orderLines: orderLines,
			// Extra fields for the mail sent by the Logic App to the requester / approver
			totalPrice: String(cartPriceTotal),
			currency: currency,
			messageToApprover,
			hardwareNote: note,
		} as { [key: string]: any };

		const submitOrder = isActionRole ? createOrder : createOrderRequest;
		const idForOrder = isActionRole ? id : localAccountId;

		const res = await dispatch(submitOrder({ auth, id: idForOrder, body: order }));

		// If success, and user is action role (i.e its an actual order, not an order request)
		const shouldSendMail = res.meta.requestStatus === "fulfilled" && isActionRole;

		if (shouldSendMail) {
			dispatch(
				sendMail({
					auth,
					body: {
						mailType: "HARDWARE_ORDER",
						recipientMail: userGraphData?.mail ?? userGraphData?.userPrincipalName,
						recipientName: `${userGraphData?.givenName} ${userGraphData?.surname}`,
						messageBody: {
							onboardedUserMail: mail,
							hardwareBought: orderLines.map(
								({ displayName: name, quantity, price: cost, link }) => ({
									name,
									quantity,
									cost,
									link,
								}),
							),
							totalCost: cartPriceTotal,
							shippingAddress: `${streetAddress}, ${postalCode} ${city}, ${country}`,
							linkToIronstonePage: `${window.location.origin}${NAVIGATION_NAMES.ORDERS.path}`,
						},
					},
				}),
			);
		} else {
			setOrderFailed(true);
		}

		setIsSubmitting(false);
		setIsSubmitted(true);
	};

	const [approvers, setApprovers] = useState<HardwareApprover[]>([]);
	const [message, setMessage] = useState("");
	const [loadingApproverFinalized, setLoadingApproverFinalized] = useState(false);

	useEffect(() => {
		if (
			isManagerOfThisUserLoading === false &&
			approversState.isLoading === false &&
			userState.isLoading === false
		) {
			const manager = allUsers[userGraphData?.manager ?? ""] ?? ({} as User);
			const { approvers, message } = getApprovers(manager, approversState.approvers);
			setApprovers(approvers);
			setMessage(message);
			setLoadingApproverFinalized(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isManagerOfThisUserLoading, approversState.isLoading, userState.isLoading]);

	const disableSubmitButton =
		!_.isEmpty(errors) || cartItemsCount === 0 || (!isActionRole && approvers.length === 0);

	const isHomeDelivery = watch("deliveryOption") === DeliveryOption.B2CHomeDelivery;

	const handleSelectDeliveryLocation = (location: CustomerLocation | null) => {
		setSelectedDeliveryLocation(location);
		if (!location) {
			// Reset the form if the user deselects the location
			reset({
				streetAddress: "",
				postalCode: "",
				city: "",
				country: "",
			});
			return;
		}
		// Reset any errors that might have been set
		delete errors.streetAddress;
		delete errors.postalCode;
		delete errors.city;
		delete errors.country;
		// Set the values of the selected location
		setValue("streetAddress", location.address);
		setValue("postalCode", location.postalCode);
		setValue("city", location.city);
		setValue("country", location.countryCode);
	};

	if (orderFailed) {
		return (
			<Grid container className={styles.orderFailedContainer}>
				<Typography variant="h2">Unfortunately, something went wrong</Typography>
				<Typography variant="body1">
					We were unable to process your order. <br />
					Please contact support at:{" "}
					{<a href="mailto:name@email.com">{IRONSTONE_SUPPORT_MAIL}</a>} with the order
					details.
				</Typography>
			</Grid>
		);
	}

	return (
		<form onSubmit={handleSubmit(handleCheckout)} id="personal-data-form">
			<Grid container mb={6}>
				{isSubmitting && (
					<Dialog open classes={{ container: styles.submitDialog }}>
						<Grid container p={3}>
							<CircularProgress size={30} color="primary" />
							<Typography variant="h5" ml={2}>
								{`${isActionRole ? "Placing" : "Requesting"} hardware ${
									isActionRole ? "order" : ""
								}, please wait...`}
							</Typography>
						</Grid>
					</Dialog>
				)}
				{isSubmitted ? (
					<HardwareCheckoutComplete
						onFinishCheckout={onFinishCheckout}
						approvers={approvers}
						{...getValues()}
					/>
				) : (
					<>
						{showCartDialog && <HardwareDrawer />}
						<Grid item className={styles.formContainer}>
							<Grid container item className={styles.header}>
								<LocalShippingIcon />
								<Typography variant="h2">Shipping details</Typography>
							</Grid>
							<DeliveryOptions control={control} />
							<Grid item xs={12}>
								<Grid
									className={clsx({
										[styles.fixedHeight]: isActionRole,
										[styles.hidden]: !isActionRole,
									})}
								>
									{isHomeDelivery ? (
										<Autocomplete
											{...defaultProps}
											onChange={(_, newValue) => {
												handleSelectUser(
													newValue ?? ({ displayName: "" } as User),
												);
											}}
											fullWidth
											autoSelect
											autoHighlight
											size="medium"
											renderOption={(props, option) => (
												<li {...props} key={option.id}>
													<Typography variant="body1">
														{option.displayName}
													</Typography>
												</li>
											)}
											disabled={!isActionRole}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													placeholder="Search for employee"
													InputProps={{
														...params.InputProps,
														startAdornment: (
															<>
																<InputAdornment position="start">
																	<Search />
																</InputAdornment>
																{params.InputProps.startAdornment}
															</>
														),
													}}
												/>
											)}
										/>
									) : (
										<Input
											name="companyName"
											label="Company name"
											register={register}
											errors={errors}
											disabled={false}
											defaultValue={customerInfo?.departmentName ?? ""}
										/>
									)}
								</Grid>
							</Grid>
							{!isHomeDelivery && (
								<DeliveryLocationSelector
									selectedDeliveryLocation={selectedDeliveryLocation}
									handleSelectDeliveryLocation={handleSelectDeliveryLocation}
								/>
							)}
							<ShippingDetails
								formProps={formProps}
								xs={12}
								countryAlternatives={
									customerInfo?.shippingCountryAlternatives ?? []
								}
								disabled={selectedDeliveryLocation !== null && !isHomeDelivery}
							/>

							<Grid container item xs={12} mb={3}>
								<Grid item xs={12}>
									<Grid container>
										{inputs.map(({ name, label }) => (
											<Grid
												container
												key={name}
												className={styles.inputContainer}
											>
												<Input
													name={name}
													disabled={false}
													label={label}
													register={register}
													errors={errors}
												/>
											</Grid>
										))}
									</Grid>
									<Grid item xs={6}>
										<MobilePhoneInput
											formProps={formProps}
											fullWidth
											required={true}
											handleOnBlur={() => {}}
											isOnboarding={false}
										/>
									</Grid>
								</Grid>

								<Grid container justifyContent="flex-start" item xs={12}>
									{isActionRole && (
										<Grid item xs={8} mt={3}>
											<InputLabel>
												<Typography variant="body1" display="inline" mr={1}>
													Additional information
												</Typography>
												<Typography
													variant="body1"
													color="text.secondary"
													display="inline"
												>
													(optional)
												</Typography>
											</InputLabel>
											<TextField
												multiline
												placeholder="Notes will be shown inside the order history table"
												rows={3}
												fullWidth
												size="small"
												InputProps={{
													classes: {
														input: styles.formTextInput,
													},
												}}
												{...register("note")}
											/>
										</Grid>
									)}
									{!isActionRole && (
										<Grid container mt={3}>
											<Grid container item xs={8}>
												{loadingApproverFinalized ? (
													<Typography
														variant="description"
														fontWeight={500}
														className={clsx({
															[styles.noApproverStyle]:
																approvers.length === 0,
														})}
													>
														{message}
													</Typography>
												) : (
													<Grid
														container
														className={styles.approverSkeleton}
													>
														<Skeleton
															variant="text"
															width="70%"
															height={30}
														/>
													</Grid>
												)}
											</Grid>
											<Grid item xs={8} mt={0.5}>
												<TextField
													multiline
													rows={2.4}
													fullWidth
													size="small"
													InputProps={{
														classes: {
															root: styles.streetAddress,
															input: styles.formTextInput,
														},
													}}
													error={!!errors.streetAddress}
													{...register("messageToApprover")}
													disabled={approvers.length === 0}
												/>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
							<PrimaryFormButton
								text={isActionRole ? "Place order" : "Request hardware"}
								size="large"
								variantStyle="contained"
								isDisabled={disableSubmitButton}
								form="personal-data-form"
								action={() => {}}
							/>
						</Grid>

						<Grid item className={styles.orderSummaryContainer}>
							<SummaryCart />
							<Grid
								item
								xs={12}
								display="flex"
								justifyContent="flex-end"
								className={styles.createBundleFromCartButton}
							>
								{isAdmin && (
									<SecondaryButton
										text={"Create bundle from cart"}
										size="medium"
										variantStyle="outlined"
										isDisabled={!_.isEmpty(errors) || cartItemsCount === 0}
										icon={
											<Favorite
												color="primary"
												className={styles.heartIcon}
											/>
										}
										action={() => {
											dispatch(addItemsfromPurchaseCart(cartItems));
											dispatch(
												setHardwareDrawerOpen({
													drawerType: HardwareDrawerType.Bundle,
													forceOpen: true,
												}),
											);
											setShowCartDialog(true);
										}}
									/>
								)}
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
		</form>
	);
};

export { HardwareCheckout };
