import { Grid, Typography } from "@mui/material";
import {
	ConfiguredQuantityRemoval,
	LicenseAction,
	LicenseRemovalType,
	LicenseSubscription,
	LicenseSubscriptionRecommendation,
	RemovalQuantity,
} from "types";

import styles from "./UnassignedLicensesContent.module.scss";

import dayjs from "dayjs";
import {
	LicenseActionStatus,
	LicenseActionTargetType,
	MailType,
	TermDuration,
} from "utilities/constants/enums";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectCustomerDetails } from "features/customer";
import {
	selectSubscriptionExpirationThreshold,
	setConfirmationMailContent,
} from "features/recommendations";
import {
	licenseActionsSelector,
	selectNumLicensesScheduledForRemoval,
} from "features/scheduledActions";
import { UnassignedHeader } from "./UnassignedHeader/UnassignedHeader";
import { UnassignedTableFooter } from "./UnassignedTableFooter";
import { LicenseRemovalTable } from "components/Common/LicenseRemovalTable";

interface UnassignedLicenseContentInputProps {
	availableForRemoval: number;
	recommendation: LicenseSubscriptionRecommendation;
	setConfiguredQuantityRemovals: (
		configuredQuantityRemovals: ConfiguredQuantityRemoval[],
	) => void;
	setPrimaryActionDisabled: (disabled: boolean) => void;
	noSelectedUsers: boolean;
}

export const UnassignedLicenseContent = ({
	availableForRemoval,
	recommendation,
	setConfiguredQuantityRemovals,
	setPrimaryActionDisabled,
	noSelectedUsers,
}: UnassignedLicenseContentInputProps) => {
	const {
		dispatch,
		auth: { account },
	} = useAuth();

	useEffect(() => {
		// Ensure we don't disable the primary action if we e.g go back and forth between steps
		return () => {
			setPrimaryActionDisabled(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const customerInfo = useAppSelector(selectCustomerDetails);
	const subscriptionExpirationThreshold = useAppSelector(selectSubscriptionExpirationThreshold);
	const licenseActions = useAppSelector(licenseActionsSelector.selectAll) ?? [];
	const scheduledLicenseActions = licenseActions.filter(
		(action) =>
			action.TargetType === LicenseActionTargetType.Subscription &&
			action.Status === LicenseActionStatus.Scheduled,
	);

	const sortedByRemovalPriority = useMemo(() => {
		return sortSubscriptionsByRemovalPriority(recommendation.subscriptions);
	}, [recommendation.subscriptions]);

	const [subscriptionsWithRemovalQuantities, setSubscriptionsWithRemovalQuantities] = useState(
		calculateSubscriptionsWithRemovalQuantities(
			sortedByRemovalPriority,
			availableForRemoval,
			scheduledLicenseActions,
			recommendation.subscriptionSkuID,
		),
	);

	useEffect(() => {
		setSubscriptionsWithRemovalQuantities(
			calculateSubscriptionsWithRemovalQuantities(
				sortedByRemovalPriority,
				availableForRemoval,
				scheduledLicenseActions,
				recommendation.subscriptionSkuID,
			),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedByRemovalPriority, availableForRemoval]);

	const totalConfiguredRemovalQuantity = useMemo(
		() =>
			Object.values(subscriptionsWithRemovalQuantities).reduce(
				(acc, curr) => acc + curr.removalQuantity,
				0,
			),
		[subscriptionsWithRemovalQuantities],
	);

	const totalSavingsBasedOnRemovalQuantity = Object.entries(
		subscriptionsWithRemovalQuantities,
	).reduce((acc, [id, { unitPriceAfterDiscount }]) => {
		return (
			acc + unitPriceAfterDiscount * subscriptionsWithRemovalQuantities[id].removalQuantity
		);
	}, 0);

	const handleQuantityChange = (id: string, quantity: number) => {
		setSubscriptionsWithRemovalQuantities((prevState) => ({
			...prevState,
			[id]: {
				...prevState[id],
				removalQuantity: quantity,
			},
		}));
	};

	const exceedsUnassignedQuantity = totalConfiguredRemovalQuantity > availableForRemoval;

	useEffect(() => {
		setPrimaryActionDisabled(
			exceedsUnassignedQuantity || (totalConfiguredRemovalQuantity === 0 && noSelectedUsers),
		);
	}, [
		exceedsUnassignedQuantity,
		totalConfiguredRemovalQuantity,
		setPrimaryActionDisabled,
		noSelectedUsers,
	]);

	useEffect(() => {
		const changesWithActualRemovals = Object.entries(subscriptionsWithRemovalQuantities).reduce(
			(acc, [id, curr]) => {
				if (curr.removalQuantity > 0) {
					acc.push({
						subscriptionName: recommendation.subscriptionOfferName,
						subscriptionSkuId: recommendation.subscriptionSkuID,
						subscription: recommendation.subscriptions.find(
							(subscription) => subscription.id === id,
						) as LicenseSubscription,
						removalQuantity: curr.removalQuantity,
						licenseRemovalType: LicenseRemovalType.remove,
						savings: curr.unitPriceAfterDiscount * 12,
						endDate: curr.commitmentEndDate,
					});
				}
				return acc;
			},
			[] as ConfiguredQuantityRemoval[],
		);
		setConfiguredQuantityRemovals(changesWithActualRemovals);

		/* eslint-disable react-hooks/exhaustive-deps */
	}, [JSON.stringify(subscriptionsWithRemovalQuantities), recommendation, customerInfo, account]);

	// We need to send a confirmation mail after the user has completed the checkout
	// The below useEffect sets the body needed
	useEffect(() => {
		dispatch(
			setConfirmationMailContent({
				mailType: MailType.AdjustLicensesAction,
				body: {
					totalSavings: Math.round(totalSavingsBasedOnRemovalQuantity * 12),
					licenseDetails: Object.entries(subscriptionsWithRemovalQuantities)
						.map(([id, subscription]) => ({
							name: recommendation.subscriptionOfferName,
							numLicensesRemoved: subscription.removalQuantity,
							scheduledDate: dayjs(subscription.commitmentEndDate).format(
								"DD.MM.YYYY",
							),
							costSavings: Math.round(subscription.unitPriceAfterDiscount * 12),
						}))
						.filter(({ numLicensesRemoved }) => numLicensesRemoved > 0),
				},
			}),
		);

		/* eslint-disable react-hooks/exhaustive-deps */
	}, [
		dispatch,
		recommendation.subscriptionOfferName,
		JSON.stringify(subscriptionsWithRemovalQuantities),
		totalSavingsBasedOnRemovalQuantity,
	]);
	/* eslint-enable react-hooks/exhaustive-deps */

	const numScheduledForRemoval = useAppSelector(
		selectNumLicensesScheduledForRemoval(recommendation.subscriptionSkuID),
	);
	const removalThreshold = useAppSelector(selectSubscriptionExpirationThreshold);
	return (
		<>
			<UnassignedHeader availableForRemoval={availableForRemoval} />
			{availableForRemoval > 0 ? (
				<Grid container className={styles.tableSectionContainer}>
					<LicenseRemovalTable
						{...{
							availableForRemoval,
							scheduledLicenseActions,
							subscriptionExpirationThreshold,
							subscriptionsWithRemovalQuantities,
							handleQuantityChange,
						}}
					/>
					<UnassignedTableFooter
						{...{
							exceedsUnassignedQuantity,
							totalConfiguredRemovalQuantity,
							totalSavingsBasedOnRemovalQuantity,
							availableForRemoval,
						}}
						// Prices can be found in the recommendationsFile, which is already loaded when this component is rendered
						isLoadingPrices={false}
					/>
				</Grid>
			) : (
				<Grid container item xs={12} className={styles.noLicensesAvailableContainer}>
					<Typography variant="body1" fontWeight={500}>
						There are no licenses available for removal. This might be caused by one out
						of several factors:
						<br />
					</Typography>
					<ul>
						<li>
							Licenses already scheduled for removal <b>({numScheduledForRemoval})</b>
						</li>
						<li>
							No licenses expiring within the set threshold (
							<b>{removalThreshold} days</b>)
						</li>
						<li>No unassigned licenses</li>
					</ul>
				</Grid>
			)}
		</>
	);
};

const sortSubscriptionsByRemovalPriority = (subscriptions: LicenseSubscription[]) => {
	return [...subscriptions].sort((a, b) => {
		if (a.termDuration === TermDuration.P1M && b.termDuration === TermDuration.P1Y) {
			return -1;
		}
		if (b.termDuration === TermDuration.P1M && a.termDuration === TermDuration.P1Y) {
			return 1;
		}
		return a.priority.remove - b.priority.remove;
	});
};

const calculateSubscriptionsWithRemovalQuantities = (
	sortedByRemovalPriority: LicenseSubscription[],
	availableForRemoval: number,
	scheduledLicenseActions: LicenseAction[],
	provisioningId: string,
) => {
	let remainingRemoval = availableForRemoval;
	return sortedByRemovalPriority.reduce(
		(
			acc,
			{
				id,
				quantity,
				termDuration,
				unitPriceAfterDiscount,
				commitmentEndDate,
				autoRenewEnabled,
				refundableQuantity,
			},
		) => {
			const numScheduledForRemoval = getNumScheduledForRemoval(scheduledLicenseActions, id);

			const maxRemoveableQuantity = quantity - numScheduledForRemoval;

			const defaultRemovalQuantity = Math.min(
				maxRemoveableQuantity,
				remainingRemoval >= quantity ? quantity : remainingRemoval,
			);
			acc[id] = {
				removalQuantity: defaultRemovalQuantity,
				termDuration,
				unitPriceAfterDiscount,
				commitmentEndDate,
				quantity,
				autoRenewEnabled,
				subscriptionId: id,
				provisioningId,
				refundableQuantity,
			};
			remainingRemoval -= defaultRemovalQuantity;

			return acc;
		},
		{} as Record<string, RemovalQuantity>,
	);
};

export { calculateSubscriptionsWithRemovalQuantities };

const getNumScheduledForRemoval = (scheduledLicenseActions: LicenseAction[], id: string) => {
	return scheduledLicenseActions.reduce((acc, curr) => {
		if (curr.TargetGUID === id) {
			return acc + Math.abs(curr.QuantityChange);
		}
		return acc;
	}, 0);
};
