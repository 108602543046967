import { createAsyncThunk } from "@reduxjs/toolkit";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";
import { LicenseRecommendationObject } from "types";

export const fetchLicenseSubscriptionRecommendations = createAsyncThunk(
	"licenses/fetchLicenseSubscriptionRecommendations",
	apiGet<LicenseRecommendationObject>(
		(authConfig) => authConfig.FUNCTION_API_ENDPOINTS.GET_LICENSE_RECOMMENDATIONS(),
		[SCOPES.FUNCTION_READ],
	),
);
