import { Grid, Typography } from "@mui/material";
import { MonthlyCostChart } from "./CostOverview/MonthlyCostChart";
import { TotalsCards } from "./TotalsCards";
import styles from "./CostManagementView.module.scss";
import { DataUpdateInterval } from "components/Common/DataUpdateInterval";
import dayjs from "dayjs";

const CostManagementView = (): JSX.Element => {
	return (
		<Grid container className={styles.container}>
			<Grid container item xs={12} className={styles.totalsCards} p={3}>
				<Grid
					container
					item
					xs={12}
					mb={2}
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Typography variant="body1" fontWeight={600}>
						COST OVERVIEW {dayjs().format("MMMM YYYY").toUpperCase()}
					</Typography>
					<Grid>
						<DataUpdateInterval updateIntervalText={"Cost data updates every day"} />
					</Grid>
				</Grid>
				<TotalsCards />
			</Grid>
			<Grid container item xs={12} mt={2} px={3}>
				<MonthlyCostChart />
			</Grid>
		</Grid>
	);
};

export { CostManagementView };
