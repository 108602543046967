import styles from "./CostNavigation.module.scss";
import { Grid, Typography } from "@mui/material";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { useAppNavigate } from "hooks/useAppNavigate";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { selectSelectedCostView, setSelectedCostView } from "features/costs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const CostNavigation = () => {
	const { navigateToPage } = useAppNavigate();
	const selectedCostView = useAppSelector(selectSelectedCostView);
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	useEffect(() => {
		dispatch(setSelectedCostView(pathname));
	}, [pathname, dispatch]);

	const [shadow, setShadow] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setShadow(window.scrollY > 0);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Grid container className={clsx({ [styles.toolbarShadow]: shadow }, styles.wrapper)}>
			<Grid
				container
				justifyContent="space-around"
				alignItems="center"
				className={styles.toolbar}
			>
				{Object.entries({
					"COST OVERVIEW": NAVIGATION_NAMES.COST_MANAGEMENT.path,
					AZURE: NAVIGATION_NAMES.AZURE_COST_DETAILED.path,
					LICENSES: NAVIGATION_NAMES.LICENSE_COST_DETAILED.path,
					"IT FOR YOUR EMPLOYEES": NAVIGATION_NAMES.IT_FOR_YOUR_EMPLOYEES_COST.path,
					"YOUR IT-SYSTEMS": NAVIGATION_NAMES.YOUR_IT_SYSTEMS_COST.path,
					CONSULTANCY: NAVIGATION_NAMES.CONSULTANCY_COST.path,
				}).map(([label, path]) => (
					<Typography
						key={label}
						variant="body1"
						color="primary"
						classes={{
							root: clsx({
								[styles.step]: true,
								[styles.selected]: path === selectedCostView,
							}),
						}}
						px={4}
						onClick={() => {
							dispatch(setSelectedCostView(path));
							navigateToPage(path);
						}}
					>
						{label}
					</Typography>
				))}
			</Grid>
		</Grid>
	);
};
