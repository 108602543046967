import { Autocomplete, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { selectCustomerLocations, setIsCustomerLocationDialogOpen } from "features/customer";
import { useAppSelector, useAuth } from "hooks/hooks";
import { CustomerLocation } from "types";
import { CountryFlag } from "components/Common/CountrySelector";
// import { selectHardwarePurchaseType } from "features/hardware/hardwarePage";
import { useMemo } from "react";
import { EditLocationsDialog } from "../NewLocationDialog";
import useRole from "utilities/roleUtils/roleCheck";
import { HoverTooltip } from "components/Common/Tooltips";
import clsx from "clsx";

import styles from "./DeliveryLocationSelector.module.scss";

interface DeliveryLocationSelectorProps {
	selectedDeliveryLocation: CustomerLocation | null;
	handleSelectDeliveryLocation: (location: CustomerLocation | null) => void;
}

interface CustomerLocationForDropdown extends CustomerLocation {
	disabled?: boolean;
}

export const DeliveryLocationSelector = ({
	selectedDeliveryLocation,
	handleSelectDeliveryLocation,
}: DeliveryLocationSelectorProps) => {
	const { dispatch } = useAuth();
	const customerLocations = useAppSelector(selectCustomerLocations);
	// const hardwarePurchaseType = useAppSelector(selectHardwarePurchaseType);
	const { isActionRole } = useRole();

	const NEW_LOCATION_CITY = "Add new location";
	const allowedOptions = useMemo(() => {
		if (!customerLocations.locations) return [];
		// Disable locations that are not norway IF not leasing
		return customerLocations.locations
			.map((location) => {
				return {
					...location,
					disabled:
						// TODO: We currently only ship to Norway, open this up once we've set up a flow for sending LEASED hardware to other countries
						// hardwarePurchaseType === HardwarePurchaseType.Buy &&
						location.countryCode !== "NO",
				};
			})
			.sort(sortOptions);
	}, [customerLocations.locations]);

	const handleOnSelectDeliveryLocation = (location: CustomerLocationForDropdown | null) => {
		if (location?.city === NEW_LOCATION_CITY) {
			dispatch(setIsCustomerLocationDialogOpen(true));
			return;
		}

		handleSelectDeliveryLocation(location);
	};

	// No locations + user cannot add locations => hide
	const hideCustomerLocations = allowedOptions.length === 0 && !isActionRole;

	if (hideCustomerLocations) return null;

	return (
		<>
			<InputLabel>
				<Typography variant="body1" display="inline" mr={1}>
					Selected location:
				</Typography>
			</InputLabel>
			<Grid container>
				<Grid item xs={6}>
					<Autocomplete
						size="small"
						fullWidth
						options={allowedOptions}
						disabled={allowedOptions.length === 0}
						renderOption={(props, option) => <Options {...props} location={option} />}
						getOptionLabel={(option) => {
							return option.address;
						}}
						isOptionEqualToValue={(
							option: CustomerLocationForDropdown,
							value: CustomerLocationForDropdown,
						) => option.address === value.address}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder="Select delivery location"
							>
								{selectedDeliveryLocation && (
									<Typography variant="description" color="text.secondary">
										{selectedDeliveryLocation.address}
									</Typography>
								)}
							</TextField>
						)}
						onChange={(_, value) => handleOnSelectDeliveryLocation(value)}
						value={selectedDeliveryLocation}
					/>
				</Grid>
				{isActionRole && (
					<Grid item>
						<Typography
							title="Add / edit locations"
							onClick={() => dispatch(setIsCustomerLocationDialogOpen(true))}
							className={styles.addLocationButton}
						>
							Add / edit locations
						</Typography>
					</Grid>
				)}
			</Grid>
			<EditLocationsDialog />
		</>
	);
};

const Options = (
	props: React.HTMLAttributes<HTMLLIElement> & { location: CustomerLocationForDropdown },
) => {
	const { location, ...rest } = props;

	return (
		<HoverTooltip
			title={location.disabled ? `Currently only shipping to Norway` : ""}
			hide={!location.disabled}
			placement="left-start"
		>
			<li
				{...rest}
				className={clsx(styles.option, {
					[styles.disabledOption]: location.disabled,
				})}
				onClick={location.disabled ? undefined : props.onClick}
			>
				<Grid container>
					<Grid item xs={1} mr={1} mt={1}>
						<CountryFlag countryCode={location.countryCode} coloredBackground={false} />
					</Grid>
					<Grid item xs={10}>
						<Grid container>
							<Typography variant="body1">{location.city}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="description">
								{location.address}, {location.postalCode}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</li>
		</HoverTooltip>
	);
};

const sortOptions = (a: CustomerLocationForDropdown, b: CustomerLocationForDropdown) => {
	// First option is always the "Add new location" option
	if (a.city === "Add new location") return -1;
	if (b.city === "Add new location") return 1;
	if (a.disabled && !b.disabled) return 1;
	if (!a.disabled && b.disabled) return -1;
	if (a.countryCode === "NO" && b.countryCode !== "NO") return -1;
	if (a.countryCode !== "NO" && b.countryCode === "NO") return 1;

	return 0;
};
