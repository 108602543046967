import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Skeleton,
} from "@mui/material";
import {
	licenseGroupsSelectors,
	selectLicenseGroups,
	selectLicenseSubscriptions,
} from "features/licenses/licenses";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import styles from "./MyLicenses.module.scss";
import { LicenseAssignedToUser } from "types";
import { fetchLicenseGroups, fetchLicenseSubscriptions } from "actions/licenseActions";
import { useMemo } from "react";
import { fetchUsers } from "actions/userActions";
import { selectUsers } from "features/users";
import clsx from "clsx";
import { licenseNameSelectors, selectlicenseNames } from "features/licenses/licenseNames";
import { fetchLicenseNames } from "actions/licenseNameActions";

interface MyLicensesInputProps {
	licenses: LicenseAssignedToUser[];
}

const MyLicenses = ({ licenses }: MyLicensesInputProps) => {
	const licenseGroupState = useAppSelector(selectLicenseGroups);
	const licenseSubscriptionsState = useAppSelector(selectLicenseSubscriptions);
	const userState = useAppSelector(selectUsers);
	const licenseNamesState = useAppSelector(selectlicenseNames);

	useApiOnce(fetchUsers, userState);
	useApiOnce(fetchLicenseGroups, licenseGroupState);
	useApiOnce(fetchLicenseSubscriptions, licenseSubscriptionsState);
	useApiOnce(fetchLicenseNames, licenseNamesState);

	const licenseNames = useAppSelector(licenseNameSelectors.selectEntities);
	const groups = useAppSelector(licenseGroupsSelectors.selectEntities);

	const sorted = useMemo(() => {
		const sortedByState = [...licenses].sort((a, b) => a.state.localeCompare(b.state));

		return sortedByState;
	}, [licenses]);

	// TODO: Continue to check where assignedLicenses was used, and if everything still looks ooooaalllgoood

	return (
		<TableContainer className={styles.tableContainer}>
			<Table>
				<TableHead className={styles.tableHead}>
					<TableRow>
						<TableCell>License</TableCell>
						<TableCell>License group</TableCell>
						<TableCell>Assignment status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sorted.length > 0 ? (
						sorted.map(({ skuId, assignedByGroup, state }, key) => {
							const licenseName = licenseNames[skuId]?.licenseDisplayName;
							return (
								<TableRow key={key}>
									<TableCell
										className={clsx({
											[styles.errorState]: state === "Error",
										})}
									>
										{licenseNamesState.isLoading ? (
											<Skeleton height={20} width={100} />
										) : (
											licenseName
										)}
									</TableCell>
									<TableCell>
										{assignedByGroup &&
											(licenseGroupState.isLoading ? (
												<Skeleton height={20} width={100} />
											) : (
												<Typography
													className={styles.groupLabel}
													variant="caption"
												>
													{groups[assignedByGroup]?.groupName ??
														"description"}
												</Typography>
											))}
									</TableCell>
									<TableCell
										className={clsx({
											[styles.errorState]: state === "Error",
										})}
									>
										{state}
									</TableCell>
								</TableRow>
							);
						})
					) : (
						<TableRow>
							<TableCell colSpan={3} align="center">
								No licenses for this user
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export { MyLicenses };
