import { Grid, TextField, InputLabel, Typography, Tooltip, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styles from "./MobilePhoneInput.module.scss";
import { userFormValidation } from "utilities/userFormValidation";

const MobilePhoneInput = ({
	formProps: {
		register,
		formState: { errors },
	},
	isOnboarding,
	handleOnBlur,
	required,
	fullWidth = false,
}) => {
	return (
		<Grid container item xs={12} classes={{ root: !errors.mobilePhone && styles.input }}>
			<Grid item xs={fullWidth ? 12 : 5}>
				<InputLabel>
					<Grid container alignItems="center">
						<Typography variant="body1" mr={0.5}>
							Phone number
						</Typography>
						{isOnboarding && (
							<Tooltip title="The phone number is required for sending the one-time password by SMS to the user">
								<IconButton classes={{ root: styles.tooltip }}>
									<InfoOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}
					</Grid>
				</InputLabel>
				<TextField
					fullWidth
					size="small"
					error={!!errors.mobilePhone}
					classes={{ root: styles.errorText }}
					helperText={userFormValidation.mobilePhone.getHelperText(errors)}
					{...register(
						"mobilePhone",
						userFormValidation.mobilePhone.getValidationProps(required),
					)}
					onBlur={handleOnBlur}
				/>
			</Grid>
			{!fullWidth && <Grid item xs={5} />}
		</Grid>
	);
};

export { MobilePhoneInput };
