import { useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import styles from "./MyCompany.module.scss";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { selectCustomerDetails } from "features/customer";
import { CompanyDetails } from "./CompanyDetails/CompanyDetails";
import { CompanyProducts } from "./CompanyProducts";
import { CompanyDocuments } from "./CompanyDocuments";
import { CompanyInfoSection } from "./CompanyInfoSection";
import { fetchUsers } from "actions/userActions";
import { selectUsers } from "features/users";

const MyCompanyPageTabs = {
	Details: {
		label: "Users & Roles",
		value: 0,
	},
	Products: {
		label: "Products",
		value: 1,
	},
	Documents: {
		label: "Documents",
		value: 2,
	},
};

const MyCompany = () => {
	useApiOnce(fetchUsers, useAppSelector(selectUsers));
	const [activeTab, setActiveTab] = useState(MyCompanyPageTabs.Details.value);

	const handleTabChange = (tab: number) => {
		// Check if any unsaved changes are present
		setActiveTab(tab);
		// Also set the active tab in our redux store
		// Also scroll to the top of the page
	};

	const customerInfo = useAppSelector(selectCustomerDetails);

	const tabContent = () => {
		switch (activeTab) {
			case MyCompanyPageTabs.Details.value:
				return <CompanyDetails />;
			case MyCompanyPageTabs.Products.value:
				return <CompanyProducts customerInfo={customerInfo} />;
			case MyCompanyPageTabs.Documents.value:
				return <CompanyDocuments />;
			default:
				return <CompanyDetails />;
		}
	};

	return (
		<Grid container className={styles.wrapper}>
			<CompanyInfoSection customerInfo={customerInfo} />
			<Grid container className={styles.tabsContainer}>
				<Tabs value={activeTab} onChange={(e, tab) => handleTabChange(tab)}>
					<Tab
						label={MyCompanyPageTabs.Details.label}
						value={MyCompanyPageTabs.Details.value}
						className={styles.tab}
						classes={{ selected: styles.selectedTab }}
					/>
					{/*
					// TODO: Re-introduce this tab if Steffen (UX) wants to keep it
					 <Tab
						label={MyCompanyPageTabs.Products.label}
						value={MyCompanyPageTabs.Products.value}
						className={styles.tab}
					/> */}
					<Tab
						label={MyCompanyPageTabs.Documents.label}
						value={MyCompanyPageTabs.Documents.value}
						className={styles.tab}
						classes={{ selected: styles.selectedTab }}
					/>
				</Tabs>
			</Grid>
			{tabContent()}
		</Grid>
	);
};

export { MyCompany };
