import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./TermsAndConditions.module.scss";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { Grid, Typography } from "@mui/material";
import { TERMS_AND_CONDITIONS } from "utilities/constants/texts";
import { ExternalLink } from "components/Common/Links";

export enum TERMS_AND_CONDITIONS_TYPES {
	LICENSE = "license",
	LICENSE_COST_OPTIMIZATION = "licenseCostOptimization",
	LICENSE_REMOVAL = "licenseRemoval",
}

export interface TermsAndConditionsProps {
	title?: string;
	open: boolean;
	onClose: () => void;
	type: TERMS_AND_CONDITIONS_TYPES;
}

export const TermsAndConditionsDialog = ({
	title,
	open,
	onClose,
	type,
}: TermsAndConditionsProps) => {
	const getTermsAndConditions = () => {
		if (type === TERMS_AND_CONDITIONS_TYPES.LICENSE) {
			return <LicenseTermsAndConditions />;
		}
		if (type === TERMS_AND_CONDITIONS_TYPES.LICENSE_COST_OPTIMIZATION) {
			return <LicenseCostOptTermsAndConditions />;
		}
		if (type === TERMS_AND_CONDITIONS_TYPES.LICENSE_REMOVAL) {
			return <RemovalOfLicensesTermsAndConditions />;
		}
	};

	return (
		<Dialog open={open} onClose={onClose} classes={{ paper: styles.dialogPaper }} maxWidth="xl">
			<DialogTitle className={styles.titleContainer}>{title}</DialogTitle>
			<DialogContent>{getTermsAndConditions()}</DialogContent>
			<DialogActions className={styles.actionsContainer}>
				<PrimaryButton text="Close" size="medium" variantStyle="text" action={onClose} />
			</DialogActions>
		</Dialog>
	);
};

const LicenseTermsAndConditions = () => (
	<>
		<Typography variant="body1" className={styles.padding} fontWeight={500}>
			{TERMS_AND_CONDITIONS.DISCLAIMER}
		</Typography>

		<Grid item className={styles.padding}>
			{TERMS_AND_CONDITIONS.FIRST_SECTION.map((item, index) => (
				<Typography variant="body1" key={index}>
					{item}
				</Typography>
			))}
		</Grid>
		<Typography variant="body1" fontWeight={500} mt={3}>
			{"Annual commitment / monthly payment"}
		</Typography>
		<Typography variant="body1" className={styles.padding}>
			{TERMS_AND_CONDITIONS.P1Y_TERM_CONDITIONS}
		</Typography>
		<Typography variant="body1" fontWeight={500} mt={3}>
			{"Monthly commitment / monthly payment"}
		</Typography>
		<Typography variant="body1" className={styles.padding}>
			{TERMS_AND_CONDITIONS.P1M_TERM_CONDITIONS}
		</Typography>
	</>
);

const LicenseCostOptTermsAndConditions = () => (
	<>
		<Typography variant="body1" fontWeight={500} className={styles.padding}>
			{"Before you confirm the license cost optimization, make sure to read the terms:"}
		</Typography>
		{RemovalOfLicensesTermsAndConditions()}
		<Typography variant="h6" fontWeight={500}>
			{"Removal of users: "}
		</Typography>
		<Typography variant="body1" className={styles.padding}>
			The removal of users will be done by our 'Actions Engine'. <br />
			The execution of a scheduled action will be done the day after it has been ordered. The
			'soft delete' action sets the deleted user into a quarantine for 30 days, and then
			deleted.
		</Typography>
		<Typography variant="body1" className={styles.padding}>
			Read more about the new commerce license-based model here:
		</Typography>
		<Typography variant="body1" className={styles.padding} display={"inline"}>
			<ExternalLink href="https://learn.microsoft.com/en-us/partner-center/new-commerce-license-based">
				{"New commerce license-based overview - PartnerCenter Microsoft Learn"}
			</ExternalLink>
		</Typography>
	</>
);

const RemovalOfLicensesTermsAndConditions = () => (
	<>
		<Typography variant="h6" fontWeight={500}>
			{"Removal of licenses: "}
		</Typography>
		<Typography variant="body1" className={styles.padding}>
			The removal of licenses will be done by our 'Actions Engine'. <br /> The execution of a
			scheduled action will be done on the first day possible after the commitment end date.{" "}
			<br />
			Within this process, we read the scheduled action, and execute the removal of the
			licenses that you have ordered, on your behalf. Ironstone is <b>not</b> responsible for
			any misconfigurations that leads to over / under-utilization of the licenses.
		</Typography>
	</>
);
