import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
	userDrawerState: {
		activeUserId: "",
		isDrawerOpen: false,
		isLockedOpen: false,
		activeTab: 0,
	},
};

const userDrawerSlice = createSlice({
	name: "userDrawer",
	initialState: initialState,
	reducers: {
		setUserDrawerOpen: (state, { payload }) => {
			state.userDrawerState.isDrawerOpen = true;
			state.userDrawerState.activeUserId = payload.activeUserId;
		},
		setUserDrawerClosed: (state) => {
			state.userDrawerState.isDrawerOpen = false;
		},
		setLockedOpen: (state, { payload }) => {
			state.userDrawerState.isLockedOpen = payload;
		},
		setActiveTab: (state, { payload }) => {
			state.userDrawerState.activeTab = payload;
		},
	},
});

export const selectUserDrawerState = (state: RootState) => state.userDrawer.userDrawerState;

export const { setUserDrawerOpen, setUserDrawerClosed, setLockedOpen, setActiveTab } =
	userDrawerSlice.actions;

export default userDrawerSlice.reducer;
