import { Grid, IconButton, Typography } from "@mui/material";
import { LicenseSubscriptionRecommendation } from "types";
import { CostSavingsType } from "utilities/constants/enums";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./LicenseDrawerHeader.module.scss";

interface LicenseDrawerHeaderInputProps {
	costSavingsType: CostSavingsType;
	activeStep: number;
	recommendation: LicenseSubscriptionRecommendation;
	handleCloseDrawer: () => void;
}

interface Content {
	[key: string]: {
		stepInfo: string;
		steps: {
			[key: number]: {
				text: string;
				secondary?: string;
				label?: string;
			};
		};
	};
}

export const LicenseDrawerHeader = ({
	costSavingsType,
	activeStep,
	recommendation,
	handleCloseDrawer,
}: LicenseDrawerHeaderInputProps) => {
	const content: Content = {
		[CostSavingsType.RemoveUnused]: {
			stepInfo: `${activeStep + 1} / 3: `,
			steps: {
				1: {
					text: "Delete inactive licensed users",
					secondary: "(Optional step)",
					label: "Soft deleting users will make their licenses available for removal in the next step",
				},
				2: {
					text: "License removal",
				},
				3: {
					text: "Checkout",
				},
			},
		},
		[CostSavingsType.TermDurationChange]: {
			// Currently, this is used when "Request walkthrough" is clicked
			stepInfo: "",
			steps: {
				1: {
					text: recommendation?.subscriptionOfferName ?? "",
				},
			},
		},
	};

	const shouldShowHeader = content[costSavingsType].steps[activeStep + 1] !== undefined;

	const headerText = content[costSavingsType].steps[activeStep + 1]?.text ?? "";
	const extraInfo = content[costSavingsType].steps[activeStep + 1]?.secondary ?? "";
	const label = content[costSavingsType].steps[activeStep + 1]?.label ?? "";

	return shouldShowHeader ? (
		<Grid container className={styles.headerContainer}>
			<IconButton onClick={handleCloseDrawer} className={styles.closeButton}>
				<CloseIcon />
			</IconButton>
			{content[costSavingsType].stepInfo && (
				<Typography variant="h2" fontWeight={500} mb={3} color="text.secondary" mr={1}>
					{content[costSavingsType].stepInfo}
				</Typography>
			)}
			<Grid item xs={10}>
				<Typography
					variant={costSavingsType === CostSavingsType.TermDurationChange ? "h1" : "h2"}
					fontWeight={500}
					display="inline"
				>
					{headerText}
				</Typography>
				{extraInfo && (
					<Typography
						variant="h2"
						fontWeight={500}
						display="inline"
						ml={2}
						color="text.secondary"
					>
						{extraInfo}
					</Typography>
				)}
				{label && (
					<Typography variant="body2" fontWeight={400}>
						{label}
					</Typography>
				)}
			</Grid>
		</Grid>
	) : null;
};
