import { Drawer, Grid } from "@mui/material";
import { LicenseSubscriptionRecommendation } from "types";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { CostSavingsType, TicketAgreement } from "utilities/constants/enums";
import { LicenseDrawerContent } from "./LicenseDrawerContent";
import { LicenseDrawerHeader } from "./LicenseDrawerHeader";
import { useEffect, useState } from "react";
import { LicenseDrawerStepper } from "./LicenseDrawerStepper";
import { useAppSelector, useAuth } from "hooks/hooks";
import { createLicenseActions } from "actions/scheduledActionActions";
import { selectRecommendationActionBodies } from "features/recommendations";
import { usersSelectors } from "features/users";
import { createTicket } from "actions/customerActions";
import { selectActiveStep, setActiveStep } from "features/scheduledActions";

import styles from "./LicenseRecommendationDrawer.module.scss";
import { selectCustomerDetails } from "features/customer";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface LicenseRecommendationDrawerInputProps {
	recommendation: LicenseSubscriptionRecommendation;
	handleDrawerStateChange: (open: boolean, type: CostSavingsType) => void;
	type: CostSavingsType;
	open: boolean;
}

export const LicenseRecommendationDrawer = ({
	type,
	recommendation,
	open,
	handleDrawerStateChange,
}: LicenseRecommendationDrawerInputProps) => {
	const { auth, dispatch } = useAuth();

	const [isCheckoutComplete, setIsCheckoutComplete] = useState(false);
	const [showCheckoutView, setShowCheckoutView] = useState(false);
	const [primaryActionDisabled, setPrimaryActionDisabled] = useState(false);
	const recommendationActionBodies = useAppSelector(selectRecommendationActionBodies);
	const activeStep = useAppSelector(selectActiveStep);

	window.Userback?.hide();
	useEffect(() => {
		// Show Userback widget when drawer is closed
		return () => {
			window.Userback?.show();
		};
	}, []);

	const customerInfo = useAppSelector(selectCustomerDetails);
	const userGraphData = useAppSelector((state) =>
		usersSelectors.selectById(state, auth?.account.idTokenClaims?.oid ?? ""),
	);

	const handleCheckout = async (costSavingsType: CostSavingsType) => {
		setShowCheckoutView(true);
		setIsCheckoutComplete(false);
		if (CostSavingsType.RemoveUnused === costSavingsType) {
			await dispatch(
				createLicenseActions({
					auth,
					body: recommendationActionBodies,
				}),
			);
		} else if (CostSavingsType.TermDurationChange === costSavingsType) {
			const customerName = customerInfo?.departmentName ?? "Customer";
			await dispatch(
				createTicket({
					auth,
					body: {
						name: `${userGraphData?.displayName ?? "Portal user"}`,
						description: `${customerName} has requested a subscription walkthrough of ${recommendation.subscriptionOfferName}`,
						subject: `${customerName}: requesting subscription waltkthrough with TAM ${
							customerInfo?.tam ?? ""
						}`,
						category: "Other",
						type: "Incident",
						priority: 2,
						status: 2,
						email:
							userGraphData?.mail ??
							userGraphData?.userPrincipalName ??
							"userhasno@emailaddress.no",
						tags: ["ConvertToSR"],
						source: "1001",
						agreement: TicketAgreement.YourEmployees,
					},
				}),
			);
		}

		setIsCheckoutComplete(true);
	};

	const handleCloseDrawer = () => {
		handleDrawerStateChange(false, type);
	};

	useEffect(() => {
		return () => {
			// Make sure we reset the active step when the drawer is closed
			dispatch(setActiveStep(0));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={() => handleDrawerStateChange(false, type)}
			PaperProps={{ className: styles.drawer }}
			className={styles.drawerWrapper}
		>
			<Grid container>
				<Grid container className={styles.drawerHeader}>
					<LicenseDrawerHeader
						handleCloseDrawer={handleCloseDrawer}
						activeStep={activeStep}
						costSavingsType={type}
						recommendation={recommendation}
					/>
				</Grid>
				<Grid container className={styles.drawerContent}>
					<LicenseDrawerContent
						type={type}
						recommendation={recommendation}
						activeStep={activeStep}
						setPrimaryActionDisabled={setPrimaryActionDisabled}
						handleCloseDrawer={handleCloseDrawer}
						showCheckoutView={showCheckoutView}
						isCheckoutComplete={isCheckoutComplete}
					/>
				</Grid>
				<Grid container className={styles.drawerStepper}>
					<LicenseDrawerStepper
						type={type}
						disablePrimaryAction={primaryActionDisabled}
						handleDrawerStateChange={handleDrawerStateChange}
						handleCheckout={handleCheckout}
					/>
				</Grid>
			</Grid>
		</Drawer>
	);
};
