import { Grid, Skeleton, Typography } from "@mui/material";
import SavingsIcon from "@mui/icons-material/Savings";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

import styles from "./TotalSavingsCard.module.scss";

interface TotalSavingsCardInputProps {
	isLoading: boolean;
	totalPossibleSavings: number;
	yourEmployeesEnabled: boolean;
}

const TotalSavingsCard = ({
	isLoading,
	totalPossibleSavings,
	yourEmployeesEnabled,
}: TotalSavingsCardInputProps) => {
	return (
		<>
			<Typography variant="h3" fontWeight={600}>
				POTENTIAL COST SAVINGS
			</Typography>
			<Typography variant="description" color="text.secondary" className={styles.subText}>
				{yourEmployeesEnabled ? "Based on total unused licenses and inactive users" : ""}
			</Typography>
			<Grid container direction="row" alignItems="center" mt={3} mb={2}>
				<SavingsIcon className={styles.savingsIcon} />

				<Typography variant="h3" className={styles.totalSavings}>
					{!isLoading ? (
						formatCostString("", totalPossibleSavings, " kr / year")
					) : (
						<Skeleton variant="text" width={200} height={40} animation="wave" />
					)}
				</Typography>
			</Grid>
		</>
	);
};

export { TotalSavingsCard };
