import axios, { ResponseType } from "axios";

const api = axios.create();

type Response<T> = { data: T; status: number };

/**
 * Does a GET request using the Axios library
 * @param {string} uri - URI that should be called
 * @param {string} tokenType - Type of token that is used
 * @param {string} accessToken - The access token as a string
 * @param {string} silentError - If true: Do not log any errors
 * @param {string} contentType - content-type header. If none is supplied it is set to application/x-www-form-urlencoded
 * @param {string} responseType - response type you want. If none is supplied it will default to json
 * @param {string} extraHeaders - object containing other headers that you want to include in the rest call
 * @param {string} queryParameters - object containing query parameters that you want to include in the rest call
 * @example
 * // returns a response object if successful, otherwise undefined
 * get('graph.microsoft.com/v1.0/me/photo', 'Bearer', 'eyJ0eXAiOiJKV1QiLCJub25jZSI6I...', true);
 */
type headerValue = {
	[key: string]: string;
};

export async function get(
	uri: string,
	tokenType: string,
	accessToken: string,
	silentError: boolean,
	contentType: string,
	responseType: ResponseType | undefined,
	extraHeaders = {},
	queryParameters: any = null,
) {
	// concatinate headers if there are any
	var headers: headerValue = { ...extraHeaders };

	if (!contentType) {
		headers["Content-Type"] = "application/x-www-form-urlencoded";
	} else {
		headers["Content-Type"] = contentType;
	}

	if (tokenType && accessToken) {
		headers["Authorization"] = `${tokenType} ${accessToken}`;
	}

	if (queryParameters) {
		uri = uri + "?" + queryParameters;
	}

	return api
		.get(uri, {
			responseType: responseType ? responseType : "json",
			headers: headers,
		})
		.catch(function (error) {
			if (!silentError) {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.error("REST Request Error (data)", error.response.data);
					console.error("REST Request Error (status)", error.response.status);
					console.error("REST Request Error (headers)", error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.error("REST Request Error (request)", error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.error("REST Request Error (unknown)", error.message);
				}
				console.error("REST Request Error (config)", error.config);
			}
		});
}

const handleHeaders = (
	extraHeaders: {},
	contentType: string,
	tokenType: string,
	accessToken: string,
) => {
	var headers: headerValue = { ...extraHeaders };

	if (!contentType) {
		headers["Content-Type"] = "application/x-www-form-urlencoded";
	} else {
		headers["Content-Type"] = contentType;
	}

	if (tokenType && accessToken) {
		headers["Authorization"] = `${tokenType} ${accessToken}`;
	}

	return headers;
};

export const handleErrors = (silentError: boolean) => (error: any) => {
	if (!silentError) {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.log("REST Request Error (data)", error.response.data);
			console.log("REST Request Error (status)", error.response.status);
			console.log("REST Request Error (headers)", error.response.headers);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log("REST Request Error (request)", error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log("REST Request Error (unknown)", error.message);
		}
		console.log("REST Request Error (config)", error.config);
	}
};

const callAPI =
	(callAsync: any) =>
	async <T>({
		uri = "",
		tokenType = "",
		accessToken = "",
		silentError = false,
		body = "",
		contentType = "application/json",
		responseType = null,
		extraHeaders = {},
	} = {}) =>
		(await callAsync(uri, body, {
			responseType: responseType ?? "json",
			headers: handleHeaders(extraHeaders, contentType, tokenType, accessToken),
		}).catch(handleErrors(silentError))) as Response<T>;

export const post = callAPI(api.post);
export const patch = callAPI(api.patch);
export const put = callAPI(api.put);
