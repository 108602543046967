import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	AllCostTypesShortApiObject,
	MarketplaceItemsCost,
	ReservedInstancesCost,
	TotalCost,
	YourEmployeesCost,
	YourITSystemsCost,
} from "types";
import { SCOPES, AGGREGATED_COST_DATA_FOLDER_PATH } from "utilities/constants/constants";
import { apiGet } from "utilities/api";
import { queryParams } from "utilities/urlUtils";

export const fetchAzureBilling = createAsyncThunk(
	"cost/fetchAzureBilling",
	apiGet<[]>(
		(authConfig, { params: { numberOfMonths } = { numberOfMonths: 12 } }: any) =>
			authConfig.BILLING_API_ENDPOINTS.GET_AZURE_BILLING() +
			queryParams({ numberOfMonths: numberOfMonths }),
		[SCOPES.BILLING_READ],
	),
);

export const fetchOffice365Billing = createAsyncThunk(
	"cost/fetchOffice365Billing",
	apiGet<[]>(
		(authConfig, { params: { numberOfMonths } = { numberOfMonths: 2 } }: any) =>
			authConfig.BILLING_API_ENDPOINTS.GET_OFFICE365_BILLING() +
			queryParams({ numberOfMonths: numberOfMonths }),
		[SCOPES.BILLING_READ],
	),
);

export const fetchConsultancyCost = createAsyncThunk(
	"cost/fetchConsultancyCost",
	apiGet<[]>(
		(authConfig, { params: { numberOfMonths } = { numberOfMonths: 48 } }: any) =>
			authConfig.BILLING_API_ENDPOINTS.GET_CONSULTANCY_COSTS() +
			queryParams({ numberOfMonths: numberOfMonths }),
		[SCOPES.BILLING_READ],
	),
);

export const fetchYourEmployeesCost = createAsyncThunk(
	"cost/fetchYourEmployeesCost",
	apiGet<YourEmployeesCost[]>(
		(authConfig, { params: { numberOfMonths } = { numberOfMonths: 6 } }: any) =>
			authConfig.BILLING_API_ENDPOINTS.GET_YOUR_EMPLOYEES_COSTS() +
			queryParams({ numberOfMonths: numberOfMonths }),
		[SCOPES.BILLING_READ],
	),
);

export const fetchYourITSystemsCost = createAsyncThunk(
	"cost/fetchYourITSystemsCost",
	apiGet<YourITSystemsCost[]>(
		(authConfig, { params: { numberOfMonths } = { numberOfMonths: 6 } }: any) =>
			authConfig.BILLING_API_ENDPOINTS.GET_YOUR_IT_SYSTEMS_COSTS() +
			queryParams({ numberOfMonths: numberOfMonths }),
		[SCOPES.BILLING_READ],
	),
);

export const fetchMarketplaceItemsCost = createAsyncThunk(
	"cost/fetchMarketplaceItemsCost",
	apiGet<MarketplaceItemsCost[]>(
		(authConfig, { params: { numberOfMonths } = { numberOfMonths: 12 } }: any) =>
			authConfig.BILLING_API_ENDPOINTS.GET_MARKETPLACE_ITEMS_COST() +
			queryParams({ numberOfMonths: numberOfMonths }),
		[SCOPES.BILLING_READ],
	),
);

export const fetchReservedInstancesCost = createAsyncThunk(
	"cost/fetchReservedInstancesCost",
	apiGet<ReservedInstancesCost[]>(
		(authConfig, { params: { numberOfMonths } = { numberOfMonths: 12 } }: any) =>
			authConfig.BILLING_API_ENDPOINTS.GET_RESERVED_INSTANCES_COST() +
			queryParams({ numberOfMonths: numberOfMonths }),
		[SCOPES.BILLING_READ],
	),
);

// Used for the cost overview
export const fetchAllCosts = createAsyncThunk(
	"cost/fetchAllCosts",
	apiGet<AllCostTypesShortApiObject>(
		(authConfig) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(AGGREGATED_COST_DATA_FOLDER_PATH),
		[SCOPES.DATA_LAKE_READ],
	),
);

// Used for downloading complete cost data
export const fetchAllCostsFullDetails = createAsyncThunk(
	"cost/fetchAllCostsFullDetails",
	apiGet<TotalCost>(
		(authConfig, { params: { numberOfMonths } = { numberOfMonths: 12 } }: any) =>
			authConfig.BILLING_API_ENDPOINTS.GET_ALL_COSTS() +
			queryParams({ numberOfMonths: numberOfMonths }),
		[SCOPES.BILLING_READ],
	),
);

export const fetchSpecificPeriodsAllCosts = createAsyncThunk(
	"cost/fetchSpecificPeriodsAllCosts",
	apiGet<TotalCost>(
		(authConfig, { params: { periods } }: any) =>
			authConfig.BILLING_API_ENDPOINTS.GET_SPECIFIC_PERIOD_ALL_COSTS() +
			queryParams({ periods }),
		[SCOPES.BILLING_READ],
	),
);
