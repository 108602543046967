import { Grid, Typography } from "@mui/material";
import { License } from "types";

import styles from "./ChosenLicensesDetails.module.scss";
import { useAppSelector } from "hooks/hooks";
import { licensePricesSelectors } from "features/licenses/licensePrices";

interface ChosenLicensesDetailsInputProps {
	chosenLicenses: License[];
}

const ChosenLicensesDetails = ({
	chosenLicenses,
}: ChosenLicensesDetailsInputProps): JSX.Element => {
	const licensePrices = useAppSelector(licensePricesSelectors.selectEntities);
	const groupedByGroup = chosenLicenses.reduce((acc, license) => {
		const groupName = license.groupName;
		if (!acc[groupName]) {
			acc[groupName] = [];
		}
		acc[groupName].push(license);
		return acc;
	}, {} as Record<string, License[]>);

	return (
		<Grid container direction="column" my={2}>
			<Grid container direction="column">
				{Object.entries(groupedByGroup).map(([, licenses]) => {
					return (
						<Grid className={styles.licenseGroupStyle}>
							{licenses.map(
								(
									{ memberOfGroup, displayName, chosenVariant, unusedLicenses },
									index,
								) => (
									<Grid key={memberOfGroup} item container direction="column">
										<Grid container direction="column">
											{index === 0 && (
												<Typography variant="caption">
													<strong>
														Included in "{memberOfGroup}" licence group:
													</strong>
												</Typography>
											)}
											{unusedLicenses <= 0 ? (
												<Grid container justifyContent="space-between">
													<Typography variant="caption">{`1 x ${displayName}`}</Typography>
													<Typography variant="caption">
														{isNaN(
															licensePrices[
																chosenVariant.costIdentifier
															]?.UnitPriceAfterDiscount ?? 0,
														)
															? "Price based on contract"
															: `${
																	licensePrices[
																		chosenVariant.costIdentifier
																	]?.UnitPriceAfterDiscount ?? 0
															  } ,-`}
													</Typography>
												</Grid>
											) : (
												<Grid container justifyContent="space-between">
													<Typography variant="caption">{`1 x ${displayName}`}</Typography>
													<Typography variant="caption">
														{"(Already purchased)"}
													</Typography>
												</Grid>
											)}
										</Grid>
									</Grid>
								),
							)}
						</Grid>
					);
				})}
			</Grid>
		</Grid>
	);
};

export { ChosenLicensesDetails };
