import React from "react";
import styles from "./ErrorBoundary.module.scss";
import { Grid, Typography } from "@mui/material";
import { PrimaryButton } from "../Buttons/Button";

export class ErrorBoundary extends React.Component {
	state = {
		hasError: false,
		error: null,
		errorInfo: null,
	};

	static getDerivedStateFromError(error: any) {
		return { hasError: true, error };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service here
		this.setState({ error, errorInfo });
	}

	handleReset = () => {
		this.setState({ hasError: false, error: null, errorInfo: null });
	};

	render() {
		if (this.state.hasError) {
			// You can also access this.state.error and this.state.errorInfo here if needed
			return (
				<Grid container>
					<Grid container className={styles.errorBoundaryContainer}>
						<Grid item className={styles.errorBoundaryContent}>
							<Typography fontWeight={500} variant="h2">
								Something went wrong
							</Typography>
							<PrimaryButton
								text="Go back"
								action={this.handleReset}
								size="small"
								variantStyle="contained"
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return this.props.children;
	}
}
