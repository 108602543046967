import JSZip from "jszip";
import { REGEX } from "./constants/constants";

export const downloadAndUnzip = (payload: any, prefix: string) => {
	const zipBlob = new Blob([payload], { type: "application/zip" });
	const zip = new JSZip();
	return zip.loadAsync(zipBlob).then((zip) => {
		zip.forEach((relativePath, zipEntry) => {
			zipEntry
				.async("blob")
				.then((content) => {
					let fileName = "";
					const isActualInvoice = REGEX.INVOICE_FILE_NAME.test(relativePath);
					if (isActualInvoice) {
						fileName = `${prefix}__${relativePath}`;
					} else {
						// Typically attachments to the invoice have better names
						fileName = relativePath;
					}
					handleResponseAsPdf(content, fileName);
				})
				.catch((error) => {
					console.error("Error extracting file:", error);
				});
		});
	});
};

export const handleResponseAsPdf = (content: any, fileName: string) => {
	const fileURL = window.URL.createObjectURL(content);
	const link = document.createElement("a");
	link.href = fileURL;
	link.download = fileName;
	link.click();
	URL.revokeObjectURL(fileURL);
};

export const handleBase64ResponseAs = (
	fileType: string,
	base64Encoded: string,
	fileName: string,
) => {
	// Helper function to convert base64 to Uint8Array
	const base64ToUint8Array = (base64: string) => {
		const padding = "=".repeat((4 - (base64.length % 4)) % 4);
		const base64WithPadding =
			base64.replace(/-/g, "+").replace(/_/g, "/").replace(/\s/g, "") + padding; // Ensure no whitespace and add padding
		const rawData = window.atob(base64WithPadding);
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}

		return outputArray;
	};

	// Convert the base64 string to a Blob
	const base64ToBlob = (base64: string, contentType: string) => {
		const uint8Array = base64ToUint8Array(base64);
		return new Blob([uint8Array], { type: contentType });
	};

	// Determine the MIME type based on the provided fileType
	const mimeTypeMap: { [key: string]: string } = {
		pdf: "application/pdf",
		pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
		docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		png: "image/png",
		jpeg: "image/jpeg",
		jpg: "image/jpeg",
		gif: "image/gif",
		txt: "text/plain",
		csv: "text/csv",
		json: "application/json",
	};

	const contentType = mimeTypeMap[fileType.toLowerCase()] || "application/octet-stream";
	const blob = base64ToBlob(base64Encoded, contentType);

	const blobUrl = URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.href = blobUrl;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();

	// Clean up by removing the temporary link and revoking the Blob URL
	document.body.removeChild(link);
	URL.revokeObjectURL(blobUrl);
};
