import { Grid, Typography } from "@mui/material";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector, useAuth, useEntitlement } from "hooks/hooks";
import {
	selectActiveStep,
	selectErrorCreatingEntitlement,
	setActiveStep,
} from "features/entitlements";
import { EntitlementList } from "./EntitlementList";
import { GuestInvitation, InvitationAccepted } from "./GuestInvitation";

import styles from "./EntitlementDialog.module.scss";
import { goToEntitledTenant } from "./entitlementUtils";

export const EntitlementSystem = () => {
	const {
		dispatch,
		auth: { account },
	} = useAuth();
	const entitlement = sessionStorage.getItem("entitlement");
	const homeTenantId = account.homeAccountId.split(".")[1];

	const activeStep = useAppSelector(selectActiveStep);
	const errorCreatingEntitlement = useAppSelector(selectErrorCreatingEntitlement);
	const [isCreatingEntitlement, setIsCreatingEntitlement] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		return () => {
			dispatch(setActiveStep(0));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Close the window if the URL contains autoclose
	window.location.href.includes("autoclose") && window.close();

	const content = useMemo(() => {
		if (errorCreatingEntitlement) {
			return (
				<Grid container className={styles.switchingContainer}>
					<Typography variant="h2" fontWeight={500}>
						Failed to create entitlement. Reload page and try again
					</Typography>
				</Grid>
			);
		}

		if (activeStep === 1) {
			return <GuestInvitation isCreatingEntitlement={isCreatingEntitlement} />;
		} else if (activeStep === 2) {
			return <InvitationAccepted />;
		}
	}, [activeStep, isCreatingEntitlement, errorCreatingEntitlement]);

	const handleSwitchTenant = () => {
		if (!entitlement || entitlement === homeTenantId) {
			// If no entitlement or home tenant, show entitlements dialog
			setOpen(true);
		} else {
			// If in an entitlement, go to home tenant
			goToEntitledTenant(homeTenantId);
		}
	};

	const { showEntitlementSystem } = useEntitlement();
	const noEntitlementOrAtHomeTenant = !entitlement || entitlement === homeTenantId;

	// Only those with available entitlements should be shown the entitlement system
	if (!showEntitlementSystem) return null;

	return open ? (
		<PrimaryDialog
			title={"Entitlements"}
			primaryButtonText={"Close"}
			primaryAction={() => setOpen(false)}
			onLeaveAction={() => setOpen(false)}
			size="large"
		>
			<Grid container className={styles.dialogContent}>
				<EntitlementList setIsCreatingEntitlement={setIsCreatingEntitlement} />
				{content}
			</Grid>
		</PrimaryDialog>
	) : (
		<Grid className={styles.dropdown} onClick={handleSwitchTenant}>
			{noEntitlementOrAtHomeTenant ? (
				<Typography>Switch tenant</Typography>
			) : (
				<Typography>Go home</Typography>
			)}
		</Grid>
	);
};
