import { Route, Routes } from "react-router-dom";
import { NavigationNames } from "types";

interface RoutedContentProps {
	department: string;
	username: string;
	navigationNames: NavigationNames;
}

export const RoutedContent = ({ department, username, navigationNames }: RoutedContentProps) => (
	<Routes>
		{Object.values(navigationNames).map(
			(navigationName) =>
				navigationName.element && (
					<Route
						path={navigationName.path}
						key={navigationName.path}
						element={navigationName.element({
							navigationName,
							department: department,
							username,
						})}
						index={!!navigationName.index}
					/>
				),
		)}
	</Routes>
);
