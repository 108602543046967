import { FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import clsx from "clsx";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { useState } from "react";
import { SubscriptionVariant } from "types";

import styles from "./AutorenewToggle.module.scss";
import EmailInput from "components/Common/EmailInput/EmailInput";
import { useAppSelector } from "hooks/hooks";
import { selectRecipientMailState } from "features/scheduledActions";
import dayjs from "dayjs";
import useRole from "utilities/roleUtils/roleCheck";

interface AutorenewToggleInputProps {
	onToggle: () => Promise<void>;
	totalLicenseQuantity: number;
	totalConsumedQuantity: number;
	variant: SubscriptionVariant;
	disabled: boolean;
}

const AutorenewToggle = ({
	onToggle,
	variant,
	totalLicenseQuantity,
	totalConsumedQuantity,
	disabled,
}: AutorenewToggleInputProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const { quantity, autoRenewEnabled, commitmentEndDate } = variant;

	const handleSetIsOpen = (e: any, value: boolean) => {
		if (disabled) return;
		// If we click on the selected radio button, we don't want to open the dialog
		if (autoRenewEnabled === value) return;
		setIsOpen(!isOpen);
	};

	return (
		<Grid container className={clsx({ [styles.disabled]: disabled })}>
			<RadioGroup aria-label="autorenew" name="autorenew" value={autoRenewEnabled}>
				<FormGroup row>
					<FormControlLabel
						value="true"
						control={
							<Radio
								size="small"
								onClick={(e) => handleSetIsOpen(e, true)}
								disabled={disabled}
							/>
						}
						label="On"
						disabled={disabled}
						name="autorenewOn"
					/>
					<FormControlLabel
						value="false"
						control={
							<Radio
								size="small"
								onClick={(e) => handleSetIsOpen(e, false)}
								disabled={disabled}
							/>
						}
						label="Off"
						disabled={disabled}
						name="autorenewOff"
					/>
				</FormGroup>
			</RadioGroup>
			{isOpen && (
				<AutorenewToggleDialog
					onConfirmAction={onToggle}
					totalQuantityOnThisVariant={quantity}
					autoRenewEnabled={autoRenewEnabled}
					totalLicenseQuantity={totalLicenseQuantity}
					totalConsumedQuantity={totalConsumedQuantity}
					endDate={commitmentEndDate}
					handleClose={() => setIsOpen(false)}
				/>
			)}
		</Grid>
	);
};

interface AutorenewToggleDialogInputProps {
	onConfirmAction: () => Promise<void>;
	totalQuantityOnThisVariant: number;
	autoRenewEnabled: boolean;
	totalLicenseQuantity: number;
	totalConsumedQuantity: number;
	endDate: Date;
	handleClose: () => void;
}

const AutorenewToggleDialog = ({
	onConfirmAction,
	totalQuantityOnThisVariant,
	autoRenewEnabled,
	totalLicenseQuantity,
	totalConsumedQuantity,
	endDate,
	handleClose,
}: AutorenewToggleDialogInputProps) => {
	const [loading, setLoading] = useState(false);
	const anyLoosingAccess =
		totalLicenseQuantity - totalConsumedQuantity - totalQuantityOnThisVariant < 0;

	const turningOffAutoRenewalWillCauseUsersToLoseAccess = autoRenewEnabled && anyLoosingAccess;
	const numUsersToLooseAccess = Math.min(
		Math.abs(totalLicenseQuantity - totalConsumedQuantity - totalQuantityOnThisVariant),
		totalQuantityOnThisVariant,
	);

	const handleConfirm = async () => {
		setLoading(true);
		await onConfirmAction();
		setLoading(false);
		handleClose();
	};

	const { isValid: isEnteredMailValid } = useAppSelector(selectRecipientMailState);
	const { isActionRole } = useRole();

	return (
		<PrimaryDialog
			title={"Change auto renewal"}
			size="medium"
			primaryButtonText="Yes, I'm sure"
			secondaryButtonText="Cancel"
			primaryAction={handleConfirm}
			isLoading={loading}
			onLeaveAction={handleClose}
			isDestructiveAction={turningOffAutoRenewalWillCauseUsersToLoseAccess}
			disabledActions={!isEnteredMailValid || !isActionRole}
		>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<Typography variant="body1">
						Are you sure you want to turn auto renewal {autoRenewEnabled ? "off" : "on"}
						?
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body1">
						{turningOffAutoRenewalWillCauseUsersToLoseAccess ? (
							<>
								Turning off auto renewal will cause{" "}
								<b>{numUsersToLooseAccess} users </b>to lose access to this license
								at {dayjs(endDate).format("DD.MM.YYYY")} (end date).
							</>
						) : autoRenewEnabled ? (
							"Turning off auto renewal will ensure that your license expires at the end of the term."
						) : (
							"Turning on auto renewal will ensure that your license does not expire, but rather extends for another term."
						)}
					</Typography>
				</Grid>
				<Grid container item xs={4}>
					<EmailInput explanationText="Confirmation receipt" required={false} />
				</Grid>
			</Grid>
		</PrimaryDialog>
	);
};

export { AutorenewToggle };
