import { Grid, Typography } from "@mui/material";
import { setHardwareDrawerOpen } from "features/hardware/hardwarePage";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { HardwareDrawerType } from "utilities/constants/enums";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { selectCartCount, selectCartPriceTotal } from "features/hardware/products";
import { selectBundleCount, selectBundlePriceTotal } from "features/hardware/hardwareBundles";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { ReactComponent as BundleBox } from "assets/cost/Group.svg";

import styles from "./SelectedItemsSummaryButton.module.scss";
import { useEffect, useState } from "react";
import clsx from "clsx";

export const SelectedItemsSummaryButton = ({ type }: { type: HardwareDrawerType }) => {
	const dispatch = useAppDispatch();

	const itemsCountSelector =
		type === HardwareDrawerType.Cart ? selectCartCount : selectBundleCount;
	const itemsCostSelector =
		type === HardwareDrawerType.Cart ? selectCartPriceTotal : selectBundlePriceTotal;

	const itemsCount = useAppSelector(itemsCountSelector);
	const itemsCost = useAppSelector(itemsCostSelector);

	const [animate, setAnimate] = useState(false);
	useEffect(() => {
		setAnimate(true);
	}, [itemsCount]);

	const handleAnimationEnd = () => {
		setAnimate(false);
	};

	const isBundle = type === HardwareDrawerType.Bundle;

	return (
		<Grid
			container
			className={styles.buttonWrapper}
			onClick={() => dispatch(setHardwareDrawerOpen({ drawerType: type, forceOpen: true }))}
		>
			<Grid item display="flex">
				{isBundle ? (
					<BundleBox className={styles.icon} />
				) : (
					<ShoppingCartOutlinedIcon className={styles.icon} />
				)}
				<Typography variant="h6" className={styles.text}>
					My {type}
				</Typography>
				{itemsCount > 0 && (
					<span
						className={clsx({
							[styles.customBadgeCart]: type === HardwareDrawerType.Cart,
							[styles.customBadgeBundle]: type === HardwareDrawerType.Bundle,
							[styles.animateBadge]: animate,
						})}
						onAnimationEnd={handleAnimationEnd}
					>
						{itemsCount}
					</span>
				)}
			</Grid>
			{itemsCost > 0 && (
				<Grid
					item
					className={clsx({
						[styles.bundleCost]: isBundle,
						[styles.cartCost]: !isBundle,
					})}
				>
					<Typography variant="body1" fontWeight={500}>
						{formatCostString("", itemsCost, " ,-")}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};
