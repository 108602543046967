import { Box, Grid, Stack, Typography } from "@mui/material";
import { WorkspaceCard } from "../../Collaboration/WorkspaceConfigurator/WorkspaceCard";
import { TabPanel } from "./Shared";
import InternalSVG from "assets/collaboration/internal.svg";
import ExternalSVG from "assets/collaboration/external.svg";
import InternalProjectSVG from "assets/collaboration/internalProject.svg";
import ExternalProjectSVG from "assets/collaboration/externalProject.svg";
import { COLLAB_TYPES } from "utilities/constants/constants";
import styles from "./RequestForm.module.scss";
import { CollaborationSettings } from "types";

export const firstTabHelpText = [
	`This request app will help you quickly set up a
    collaboration workspace built on Microsoft Teams
    and SharePoint using predefined templates.
    Everything you need will be set up ready to go
    so that you can quickly get to work.`,
	`We will ask you some questions to help you define your workspace. Once you submit the request, your new workspace will be provisioned for you. This may take a little longer if approval is required.`,
	`New to Teams and SharePoint? Check out content in the
    Learning Portal where we introduce you to some
    best practices!`,
];

export interface IGetStartedProps {
	collabSettings: CollaborationSettings;
	setSelectedCollabType: Function;
	activeTabIndex: number;
	onCollabTypeSelected: Function;
}

export const GetStarted = (props: IGetStartedProps) => {
	const { setSelectedCollabType, onCollabTypeSelected, activeTabIndex, collabSettings } = props;
	return (
		<TabPanel value={activeTabIndex} index={0}>
			<Typography variant="body2">Welcome!</Typography>
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="body2" className={styles.formRow}>
					Choose the type of collaboration you need...
				</Typography>
				<Typography className={styles.steps}>(step 1/4)</Typography>
			</Stack>
			<Box>
				<Grid container spacing={5} justifyContent="flex-start">
					{collabSettings.workspaceSettings.internalCollaborationEnabled && (
						<Grid item>
							<WorkspaceCard
								description="For cross organisation or departmental collaboration that is not a project"
								title="Internal Collaboration"
								key={1}
								backgroundPic={InternalSVG}
								onClickCallback={() => {
									setSelectedCollabType(COLLAB_TYPES.INTERNAL_COLLABORATION);
									onCollabTypeSelected();
								}}
							></WorkspaceCard>
						</Grid>
					)}
					{collabSettings.workspaceSettings.externalCollaborationEnabled && (
						<Grid item>
							<WorkspaceCard
								description="For collaboration with people outside the organisation that is not a project"
								title="External Collaboration"
								backgroundPic={ExternalSVG}
								key={2}
								onClickCallback={() => {
									setSelectedCollabType(COLLAB_TYPES.EXTERNAL_COLLABORATION);
									onCollabTypeSelected();
								}}
							></WorkspaceCard>
						</Grid>
					)}
					{collabSettings.workspaceSettings.internalProjectEnabled && (
						<Grid item>
							<WorkspaceCard
								description="For project collaboration across the organisation or within your department"
								title="Internal Project"
								backgroundPic={InternalProjectSVG}
								key={3}
								onClickCallback={() => {
									setSelectedCollabType(COLLAB_TYPES.INTERNAL_PROJECT);
									onCollabTypeSelected();
								}}
							></WorkspaceCard>
						</Grid>
					)}
					{collabSettings.workspaceSettings.externalProjectEnabled && (
						<Grid item>
							<WorkspaceCard
								description="For project collaboration with people outside the organisation"
								title="External Project"
								backgroundPic={ExternalProjectSVG}
								key={4}
								onClickCallback={() => {
									setSelectedCollabType(COLLAB_TYPES.EXTERNAL_PROJECT);
									onCollabTypeSelected();
								}}
							></WorkspaceCard>
						</Grid>
					)}
				</Grid>
			</Box>
		</TabPanel>
	);
};
