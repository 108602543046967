import { Grid, Typography } from "@mui/material";

import styles from "./LicenseAssignmentReceiptPage.module.scss";
import { LicenseAssignmentSummary } from "../LicenseAssignmentView/LicenseAssignmentSummary";
import { LicenseAssignmentAndPurchase, SubscriptionVariantGroup, User } from "types";
import { Dictionary } from "@reduxjs/toolkit";
import { BuyLicensesSummaryTable } from "../LicenseAdjustmentDrawer/BuyLicenses/BuyLicensesSummaryTable";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { GoBackButtonContent, SecondaryButton } from "components/Common/Buttons/Button";
import { useAuth } from "hooks/hooks";
import {
	setActiveLicenseManagementId,
	setLicenseAssignmentState,
} from "features/licenses/licenses";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { FIVE_MINUTES } from "utilities/constants/constants";
import { useEffect } from "react";

interface LicenseAssignmentReceiptPageProps {
	userIdsSelectedForAssignment: string[];
	allSelectedLicenses: {
		id: string;
		assignedBy: string;
	}[];
	purchaseAndAssignments: LicenseAssignmentAndPurchase;
	licenses: Dictionary<SubscriptionVariantGroup>;
	users: Dictionary<User>;
}

export const LicenseAssignmentReceiptPage = ({
	userIdsSelectedForAssignment,
	allSelectedLicenses,
	purchaseAndAssignments,
	licenses,
	users,
}: LicenseAssignmentReceiptPageProps) => {
	const { dispatch } = useAuth();
	const { navigateToPage } = useAppNavigate();
	const handleGoBackToManageLicenses = () => {
		dispatch(setActiveLicenseManagementId(""));
		dispatch(
			setLicenseAssignmentState({
				showReceiptPage: false,
			}),
		);
		navigateToPage(NAVIGATION_NAMES.LICENSE_MANAGEMENT.path);
	};

	useEffect(() => {
		// This useEffect ensures that the receipt page is closed when the component is unmounted
		return () => {
			dispatch(
				setLicenseAssignmentState({
					showReceiptPage: false,
				}),
			);
		};
	}, [dispatch]);

	return (
		<Grid className={styles.licenseAssignmentReceiptPage}>
			<Grid item className={styles.headerContainer}>
				<Typography variant="h1">License Order Complete - Receipt</Typography>
			</Grid>
			<Grid item className={styles.infoContainer}>
				<InfoRoundedIcon className={styles.infoIcon} />
				<Typography variant="description" fontWeight={500}>
					It can take up to <b>{FIVE_MINUTES}</b> minutes for the licenses to be assigned
					to the users
				</Typography>
			</Grid>
			<Grid container className={styles.contentContainer}>
				<Grid container className={styles.assignmentAndPurchaseInfoContainer}>
					<Grid item>
						<Typography variant="body1" fontWeight={500}>
							License assignment summary
						</Typography>
						<LicenseAssignmentSummary
							userIdsSelectedForAssignment={userIdsSelectedForAssignment}
							allSelectedLicenses={allSelectedLicenses}
							purchaseAndAssignments={purchaseAndAssignments}
							licenses={licenses}
							users={users}
							isReceiptPage={true}
						/>
					</Grid>
				</Grid>
				<Grid item>
					{Object.values(purchaseAndAssignments.purchases).length > 0 && (
						<Grid item>
							<Typography variant="body1" fontWeight={500} mb={1}>
								Purchased licenses
							</Typography>
							<BuyLicensesSummaryTable
								licenses={Object.values(purchaseAndAssignments.purchases) ?? []}
							/>
						</Grid>
					)}
				</Grid>
				<Grid container item justifyContent="center" mt={3}>
					<SecondaryButton
						size="medium"
						variantStyle="outlined"
						text={<GoBackButtonContent text={"Go back to manage licenses"} />}
						action={handleGoBackToManageLicenses}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
