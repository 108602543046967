import { TableDialog } from "components/Common/Dialogs/TableDialog";
import { useAppSelector } from "hooks/hooks";
import { ReactElement } from "react";
import { InternalLink } from "components/Common/Links";
import { selectAnomalies } from "features/costs";

interface AnomaliesDialogProps {
	setIsDetailedAnomaliesDialogOpen: (showDialog: boolean) => void;
	isDetailedAnomaliesDialogOpen: boolean;
}

export const AnomaliesDialog = ({
	setIsDetailedAnomaliesDialogOpen,
	isDetailedAnomaliesDialogOpen,
}: AnomaliesDialogProps): ReactElement => {
	const { anomaliesData } = useAppSelector(selectAnomalies);

	return (
		<>
			<InternalLink onClick={() => setIsDetailedAnomaliesDialogOpen(true)}>
				View anomaly details
			</InternalLink>
			{isDetailedAnomaliesDialogOpen && (
				<TableDialog
					title="Cost anomalies"
					size="large"
					tableData={[
						{
							title: "Resource anomalies",
							description: "Resource cost that are out of the ordinary",
							data: anomaliesData?.resources,
							headers: [
								"Name",
								"Current cost",
								"Created this month",
								"% change from last month",
								"% of total",
							],
							properties: [
								{
									name: "resourceName",
									prefix: "",
									suffix: "",
								},
								{ name: "total", prefix: "kr", suffix: ",-" },
								{
									name: "isNewForThisMonth",
									prefix: "",
									suffix: "",
								},
								{
									name: "percentualChangeFromLastMonth",
									prefix: "",
									suffix: "%",
								},
								{
									name: "percentOfTotal",
									prefix: "",
									suffix: "%",
								},
							],
						},
						{
							title: "Subscription anomalies",
							description: "Subscriptions cost that are out of the ordinary",
							data: anomaliesData?.subscriptions,
							headers: [
								"Name",
								"Current cost",
								"% change from last month",
								"% of total",
							],
							properties: [
								{
									name: "subscriptionName",
									prefix: "",
									suffix: "",
								},
								{ name: "total", prefix: "kr", suffix: ",-" },
								{
									name: "percentualChangeFromLastMonth",
									prefix: "",
									suffix: "%",
								},
								{
									name: "percentOfTotal",
									prefix: "",
									suffix: "%",
								},
							],
						},
					]}
					onClose={() => setIsDetailedAnomaliesDialogOpen(false)}
				/>
			)}
		</>
	);
};
