import { createAsyncThunk } from "@reduxjs/toolkit";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";
import { Invoice } from "types";

export const fetchInvoices = createAsyncThunk(
	"invoices/fetchAll",
	apiGet<Invoice[]>(
		(authConfig) => authConfig.BILLING_API_ENDPOINTS.GET_ALL_INVOICES(),
		[SCOPES.BILLING_READ],
	),
);

export const fetchInvoice = createAsyncThunk(
	"invoices/fetch",
	apiGet<any>(
		(authConfig, { id: invoiceId }) => authConfig.BILLING_API_ENDPOINTS.GET_INVOICE(invoiceId),
		[SCOPES.BILLING_READ],
	),
);
