import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiResponse, LicenseAction } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiPut, apiGet, apiPost } from "utilities/api";

type LicenseActionResponse = ApiResponse<LicenseAction[]>;

// Fetches all actions that have been created based on recommendations
export const fetchScheduledActions = createAsyncThunk(
	"licenses/fetchScheduledActions",
	apiGet<LicenseActionResponse>(
		(authConfig) => authConfig.SCHEDULER_API_ENDPOINTS.LICENSE_ACTIONS(),
		[SCOPES.FUNCTION_READ],
	),
);

// Updates an action
export const updateScheduledAction = createAsyncThunk(
	"licenses/updateScheduledAction",
	apiPut(
		(authConfig) => authConfig.SCHEDULER_API_ENDPOINTS.LICENSE_ACTIONS(),
		[SCOPES.FUNCTION_WRITE],
	),
);

// Creates an action
export const createLicenseActions = createAsyncThunk(
	"licenses/createLicenseActions",
	apiPost(
		(authConfig) => authConfig.SCHEDULER_API_ENDPOINTS.LICENSE_ACTIONS(),
		[SCOPES.FUNCTION_WRITE],
	),
);

// Fetches all *recommendations*
export const licenseRecommendationsAction = createAsyncThunk(
	"licenses/licenseRecommendationsActions",
	apiGet<LicenseActionResponse>(
		({ authConfig, folderPath }) =>
			authConfig.SCHEDULER_API_ENDPOINTS.GET_CUSTOMER_DATA(folderPath),
		[SCOPES.FUNCTION_READ],
	),
);

export const executeLicenseActions = createAsyncThunk(
	"licenses/executeLicenseActions",
	apiPost(
		(authConfig) => authConfig.SCHEDULER_API_ENDPOINTS.EXECUTE_ALL_LICENSE_ACTIONS(),
		[SCOPES.FUNCTION_WRITE],
	),
);
