import clsx from "clsx";
import styles from "./ColorIndicator.module.scss";

interface ColorIndicatorProps {
	status: string;
}

const ColorIndicator = ({ status }: ColorIndicatorProps): JSX.Element => {
	const colorKey = status.toLowerCase().replace(/ /g, "");
	return <span className={clsx([styles.container, styles[colorKey]])} />;
};

export { ColorIndicator };
