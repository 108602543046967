import { IntuneManagementType } from "utilities/constants/enums";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as EntraIdLogo } from "assets/microsoftIcons/EntraID.svg";
import { ReactComponent as IntuneLogo } from "assets/microsoftIcons/Intune.svg";

import styles from "./EnrollmentStatus.module.scss";

interface EnrollmentStatusProps {
	isManaged?: boolean;
	intuneEnrollmentStatus?: IntuneManagementType;
	enrollmentType?: string;
}

export const EnrollmentStatus = ({
	isManaged,
	intuneEnrollmentStatus,
	enrollmentType,
}: EnrollmentStatusProps) => {
	if (isManaged) {
		return (
			<Grid container className={styles.enrollmentStatusContainer}>
				<IntuneLogo className={styles.logo} />
				<Grid item>
					<Typography variant="body1" mb={-1}>
						Intune
					</Typography>

					{intuneEnrollmentStatus && (
						<Typography variant="description" color="text.secondary">
							{intuneEnrollmentStatus}
						</Typography>
					)}
				</Grid>
			</Grid>
		);
	}

	if (enrollmentType === "AzureDomainJoined") {
		return (
			<Grid container>
				<EntraIdLogo className={styles.logo} />
				<Typography variant="body1">Entra joined</Typography>
			</Grid>
		);
	} else {
		return (
			<Grid container>
				<EntraIdLogo className={styles.logo} />
				<Typography variant="body1">Entra registered</Typography>
			</Grid>
		);
	}
};
