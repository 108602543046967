import { createSlice } from "@reduxjs/toolkit";
import { checkAccess } from "actions/accessActions";
import { RootState } from "store";

const accesSlice = createSlice({
	name: "access",
	initialState: { isLoading: true, isFetching: false, hasAccess: false },
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(checkAccess.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(checkAccess.fulfilled, (state) => ({
				...state,
				isLoading: false,
				isFetching: false,
				hasAccess: true,
			}))
			.addCase(checkAccess.rejected, (state) => {
				state.isLoading = false;
				state.isFetching = false;
				state.hasAccess = false;
			});
	},
});

export const selectAccess = (state: RootState) => state.access;

export default accesSlice.reducer;
