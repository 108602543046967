import { Grid, MenuItem, Select, Typography } from "@mui/material";

import styles from "./ListFilter.module.scss";
import {
	IntuneManagementType,
	UserActivityType,
	UserAuthenticationMethod,
	UserType,
} from "utilities/constants/enums";
import clsx from "clsx";
import { PrimaryCheckbox } from "components/Common/Checkboxes";

interface ListFilterProps {
	id: string;
	filterValues:
		| UserType[]
		| UserActivityType[]
		| string[]
		| IntuneManagementType[]
		| UserAuthenticationMethod[];
	filterOptions:
		| UserType[]
		| UserActivityType[]
		| string[]
		| IntuneManagementType[]
		| UserAuthenticationMethod[];
	icons?: { [key: string]: React.ReactElement } | undefined;
	updateFilter: (value: string[]) => void;
	noneSelectedLabel: string;
	disabled?: boolean;
}

export const ListFilter = ({
	id,
	filterValues,
	icons,
	filterOptions,
	updateFilter,
	noneSelectedLabel,
	disabled,
}: ListFilterProps) => {
	return (
		<Grid item className={clsx(styles.filterField, { [styles.disabled]: disabled })}>
			<Select
				id={id}
				multiple
				value={filterValues}
				size="small"
				displayEmpty
				className={clsx(styles.filterSelector, {
					[styles.filterActive]: filterValues.length !== 0,
				})}
				renderValue={(selected) => {
					if (selected.length === 0) {
						return noneSelectedLabel;
					}
					if (selected.length === filterOptions.length) {
						return noneSelectedLabel;
					}
					return selected.join(", ");
				}}
				onChange={(event) => updateFilter(event.target.value as string[])}
				placeholder="Select option"
			>
				{filterOptions.map((option, key) => (
					<MenuItem
						key={key}
						value={option}
						onClick={(event) => {
							const newFilterValues = [...filterValues];
							const index = newFilterValues.indexOf(option);
							if (index > -1) {
								newFilterValues.splice(index, 1);
							} else {
								newFilterValues.push(option);
							}
							updateFilter(newFilterValues);
							event.stopPropagation(); // Prevent the click from closing the dropdown
						}}
					>
						<PrimaryCheckbox
							label={""}
							checked={(filterValues as string[]).includes(option)}
							onChange={(e) => e.stopPropagation()} // Prevent the click from closing the dropdown
							size="small"
						/>
						{icons && icons[option] ? (
							<Grid ml={-2} mr={3}>
								{icons[option]}
							</Grid>
						) : null}
						<Typography ml={-2} variant="description">
							{option}
						</Typography>
					</MenuItem>
				))}
			</Select>
		</Grid>
	);
};
