import { CheckCircle } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { SummaryCart } from "components/MyPeople/EmployeeDialogSteps/Summary/SummaryCart";
import { useEffect, useRef } from "react";
import styles from "./HardwareCheckoutComplete.module.scss";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { clearCartItems } from "features/hardware/products";
import { useAppDispatch } from "hooks/hooks";
import { DeliveryOption } from "utilities/constants/enums";
import { DELIVERY_TIME } from "utilities/constants/constants";
import useRole from "utilities/roleUtils/roleCheck";

type Props = {
	onFinishCheckout: () => void;
} & { [formData: string]: any };

const HardwareCheckoutComplete = ({
	onFinishCheckout,
	givenName,
	surname,
	mail,
	mobilePhone,
	streetAddress,
	postalCode,
	city,
	deliveryOption,
	approvers,
}: Props) => {
	const dispatch = useAppDispatch();

	const componentWillUnmount = useRef(false);

	useEffect(() => {
		return () => {
			componentWillUnmount.current = true;
		};
	}, []);

	useEffect(() => {
		return () => {
			if (componentWillUnmount.current) {
				dispatch(clearCartItems());
			}
		};
	}, [dispatch]);

	const { isActionRole } = useRole();

	return (
		<Grid container className={styles.container}>
			<Grid container direction="column" alignItems="center">
				<CheckCircle classes={{ root: styles.successIcon }} color="success" />
				<Typography variant="h1">
					{isActionRole
						? "Your order is complete!"
						: "Your request for hardware is complete!"}
				</Typography>
				<Typography variant="body2">
					{isActionRole
						? "You will receive a confirmation email with all the details"
						: `${`${approvers.length} approver${
								approvers.length > 1 ? "s" : ""
						  }`} will process your request. Upon approval or rejection, you will be notified via email regarding the decision.`}
				</Typography>
			</Grid>
			<Grid
				container
				direction="column"
				justifyContent="center"
				p={2}
				classes={{ root: styles.overview }}
			>
				<Typography variant="h2">Overview</Typography>
				<Typography variant="h6">Shipping address</Typography>
				<Grid container alignItems="center" gap={1}>
					<Typography variant="body1" className={styles.labelFixedWidth}>
						Delivery type:
					</Typography>
					<Typography variant="body1">
						{deliveryOption === DeliveryOption.B2B ? "Office" : "Home"}
					</Typography>
					<Typography variant="description" display="inline" color="text.secondary">
						(
						{deliveryOption === DeliveryOption.B2B
							? DELIVERY_TIME.OFFICE
							: DELIVERY_TIME.HOME}
						)
					</Typography>
				</Grid>

				<Grid container alignItems="center" gap={1}>
					<Typography variant="body1" className={styles.labelFixedWidth}>
						Street address:
					</Typography>
					<Typography variant="body1">{streetAddress}</Typography>
				</Grid>
				<Grid container alignItems="center" gap={1}>
					<Typography variant="body1" className={styles.labelFixedWidth}>
						Postal code:
					</Typography>
					<Typography variant="body1">{postalCode}</Typography>
				</Grid>
				<Grid container alignItems="center" gap={1}>
					<Typography variant="body1" className={styles.labelFixedWidth}>
						City:
					</Typography>
					<Typography variant="body1">{city}</Typography>
				</Grid>

				<Typography variant="h6">Contact info</Typography>
				<Grid container alignItems="center" gap={1}>
					<Typography variant="body1" className={styles.labelFixedWidth}>
						Name:
					</Typography>
					<Typography variant="body1">
						{" "}
						{givenName} {surname}
					</Typography>
				</Grid>
				<Grid container alignItems="center" gap={1}>
					<Typography variant="body1" className={styles.labelFixedWidth}>
						Email:
					</Typography>
					<Typography variant="body1" className={styles.email}>
						{mail}
					</Typography>
				</Grid>
				<Grid container alignItems="center" gap={1}>
					<Typography variant="body1" className={styles.labelFixedWidth}>
						Phone:
					</Typography>
					<Typography variant="body1" className={styles.email}>
						{mobilePhone}
					</Typography>
				</Grid>
			</Grid>
			<Grid container item justifyContent="center" classes={{ root: styles.cart }}>
				<SummaryCart />
			</Grid>
			<Grid container item xs={12} justifyContent={"center"}>
				<PrimaryButton
					text="Finish"
					size="large"
					variantStyle="contained"
					action={onFinishCheckout}
				/>
			</Grid>
		</Grid>
	);
};

export default HardwareCheckoutComplete;
