import { Grid } from "@mui/material";
import {
	selectCurrentYourITSystemsCost,
	selectLastYourITSystemsCost,
	selectYourITSystemsCost,
} from "features/costs";
import { fetchYourITSystemsCost } from "actions/costActions";

import styles from "./YourITSystemsCostView.module.scss";
import { DataUpdateInterval } from "components/Common/DataUpdateInterval";
import { IronstoneServicesTable } from "../IronstoneServicesTable/IronstoneServicesTable";
import dayjs from "dayjs";
import { CostTypeCard } from "../TotalsCards/CostTypeCard";
import { ReactComponent as YourITSystemsIcon } from "assets/cost/yourItSystems.svg";
import colors from "styles/utilities/_colors.scss";
import { CostType } from "utilities/constants/enums";
import { useApiOnceWithParams, useAppSelector } from "hooks/hooks";
import { IRONSTONE_PRODUCT_DISPLAY_NAMES } from "utilities/constants/constants";

const YourITSystemsCostView = () => {
	const { isLoading, isFetching } = useAppSelector(selectYourITSystemsCost);
	useApiOnceWithParams(fetchYourITSystemsCost, { isLoading, isFetching }, { numberOfMonths: 2 });

	const cost = useAppSelector(selectCurrentYourITSystemsCost);
	const costLastPeriod = useAppSelector(selectLastYourITSystemsCost);

	return (
		<Grid container className={styles.wrapper}>
			<Grid
				container
				className={styles.topSectionContainer}
				justifyContent="space-between"
				mb={3}
			>
				<Grid item className={styles.cardContainer}>
					<CostTypeCard
						isLoading={isLoading}
						title={IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_IT_SYSTEMS}
						totalCurrentPeriod={cost?.totalCost ?? 0}
						totalPreviousPeriod={costLastPeriod?.totalCost}
						icon={<YourITSystemsIcon />}
						backgroundColor={colors.yellowDark}
						size="medium"
						secondaryTitle="All purchased addons included"
						periodLabel={`${dayjs().startOf("month").format("DD.MM")} - ${dayjs()
							.endOf("month")
							.format("DD.MM")}`}
					/>
				</Grid>
				<Grid item className={styles.updateIntervalContainer} mt={3}>
					<DataUpdateInterval updateIntervalText={"Cost data updates every day"} />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<IronstoneServicesTable
					isLoading={isLoading}
					cost={cost}
					costType={CostType.YOUR_IT_SYSTEMS}
				/>
			</Grid>
		</Grid>
	);
};

export default YourITSystemsCostView;
