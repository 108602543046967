import { Device, DeviceFilters } from "types";
import { DeviceComplianceStatus, EntraStatus, IntuneManagementType } from "./constants/enums";

const groupDeviceData = (allDevices: Device[]) => {
	const results = {
		joined: [],
		registered: [],
		intuneManaged: [],
		ironstonePolicy: [],
		customerPolicy: [],
		noPolicy: [],
		compliantDevices: [],
		nonCompliantDevices: [],
		inactiveDevices: [],
	} as {
		joined: string[];
		registered: string[];
		intuneManaged: string[];
		ironstonePolicy: string[];
		customerPolicy: string[];
		noPolicy: string[];
		compliantDevices: string[];
		nonCompliantDevices: string[];
		inactiveDevices: string[];
	};

	allDevices.forEach((device) => {
		if (device.entraStatus === EntraStatus.Joined) {
			results.joined.push(device.deviceId);
		} else {
			results.registered.push(device.deviceId);
		}

		if (device.isManaged) {
			results.intuneManaged.push(device.deviceId);
		}

		if (device.intuneEnrollmentStatus === IntuneManagementType.IronstonePolicyManaged) {
			results.ironstonePolicy.push(device.deviceId);
		} else if (device.intuneEnrollmentStatus === IntuneManagementType.CustomerPolicyManaged) {
			results.customerPolicy.push(device.deviceId);
		} else if (device.intuneEnrollmentStatus === IntuneManagementType.NoPolicy) {
			results.noPolicy.push(device.deviceId);
		}

		if (device.deviceStatuses.includes(DeviceComplianceStatus.Compliant)) {
			results.compliantDevices.push(device.deviceId);
		}
		if (device.deviceStatuses.includes(DeviceComplianceStatus.Inactive)) {
			results.inactiveDevices.push(device.deviceId);
		}
		if (device.deviceStatuses.includes(DeviceComplianceStatus.NonCompliant)) {
			results.nonCompliantDevices.push(device.deviceId);
		}
	});

	return results;
};

const isCorrectOs = (device: Device, osFilters: string[]) => {
	// If no filters are specified, the device OS is considered correct
	if (osFilters.length === 0) return true;

	return osFilters.includes(device.operatingSystemShort.toLowerCase());
};

const isCorrectIntuneManagement = (device: Device, intuneManagementFilters: string[]) => {
	// If no filters are specified, the device is considered correctly managed
	if (intuneManagementFilters.length === 0) return true;

	return intuneManagementFilters.includes(device.intuneEnrollmentStatus);
};

const isCorrectCompliance = (device: Device, complianceFilters: string[]) => {
	if (complianceFilters.length === 0) return true;
	if (device.deviceStatuses.length === 0) return false;

	return device.deviceStatuses.some((status) => complianceFilters.includes(status));
};

const filterBasedOnChosenFilters = (allDevices: Device[], chosenFilters: DeviceFilters) => {
	const filterIds = [] as string[];

	const lowerCasedChosenOsFilters = chosenFilters.osFilters.map((os) => os.toLowerCase());

	allDevices.forEach((device) => {
		// Each check checks if the chosen filters are default, if so, return all devices from given filter
		// If not default, check if the device has the correct properties, if so, return true
		// If all checks return true, add the device to the filterIds array
		if (!isCorrectOs(device, lowerCasedChosenOsFilters)) return;
		if (!isCorrectIntuneManagement(device, chosenFilters.intuneManagementFilters)) return;
		if (!isCorrectCompliance(device, chosenFilters.complianceFilters)) return;

		filterIds.push(device.deviceId);
	});

	return filterIds;
};

const calculateSummaryCells = (devices: Device[], chosenDeviceFilters: DeviceFilters) => {
	// We know that all the devices passed into this method are already filtered based on the chosen filters
	// Thus we can summaryize without any further filtering
	const summaryCells = [] as { label: string; value: number }[];

	chosenDeviceFilters.osFilters.forEach((osFilter) => {
		const devicesWithOs = devices.filter((device) => device.operatingSystemShort === osFilter);
		summaryCells.push({
			label: osFilter,
			value: devicesWithOs.length,
		});
	});

	chosenDeviceFilters.intuneManagementFilters.forEach((intuneManagementFilter) => {
		const devicesWithIntuneManagement = devices.filter(
			(device) => device.intuneEnrollmentStatus === intuneManagementFilter,
		);
		summaryCells.push({
			label: intuneManagementFilter,
			value: devicesWithIntuneManagement.length,
		});
	});

	chosenDeviceFilters.complianceFilters.forEach((complianceFilter) => {
		const devicesWithCompliance = devices.filter((device) =>
			device.deviceStatuses.includes(complianceFilter as DeviceComplianceStatus),
		);
		summaryCells.push({
			label: complianceFilter,
			value: devicesWithCompliance.length,
		});
	});

	return summaryCells.sort((a, b) => b.label.localeCompare(a.label));
};

export { groupDeviceData, filterBasedOnChosenFilters, calculateSummaryCells };
