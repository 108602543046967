import { AddToLicenseGroup } from "../AddToLicenseGroup";
import { AddToAccessGroup } from "../AddToAccessGroup";
import { Grid } from "@mui/material";
import styles from "./Groups.module.scss";

const Groups = ({ employeeData, updateEmployeeData }) => {
	return (
		<Grid container className={styles.container}>
			<Grid item className={styles.addLicenseGroup}>
				<AddToLicenseGroup
					newEmployeeData={employeeData}
					updateEmployeeData={updateEmployeeData}
				/>
			</Grid>
			<Grid item className={styles.addAccessGroup}>
				<AddToAccessGroup
					newEmployeeData={employeeData}
					updateEmployeeData={updateEmployeeData}
				/>
			</Grid>
		</Grid>
	);
};

export { Groups };
