import { TextField, TextFieldProps } from "@mui/material";
import { InputWrapper, InputWrapperProps } from "../InputWrapper";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { ForwardedRef, forwardRef } from "react";
import styles from "./InputTextField.module.scss";
import clsx from "clsx";

type formRegisterType = { disabled?: boolean } & UseFormRegister<FieldValues>;

type InputTextFieldProps = { register?: formRegisterType } & TextFieldProps & InputWrapperProps;

const InputTextField = forwardRef(
	(
		{ label, tooltipText, noLabel = false, className, ...textFieldProps }: InputTextFieldProps,
		ref: ForwardedRef<HTMLInputElement>, // Use ForwardedRef for more specific typing
	) => (
		<>
			<InputWrapper
				label={label}
				tooltipText={tooltipText}
				noLabel={noLabel}
				htmlFor={textFieldProps.id}
			>
				<TextField
					{...textFieldProps}
					inputRef={ref}
					className={clsx(styles.textField, className)}
				/>
			</InputWrapper>
		</>
	),
);

export { InputTextField };
