import {
	Grid,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import styles from "./SortableTable.module.scss";
import dayjs from "dayjs";

interface Row {
	[key: string]: any;
}

const parseTableCellValue = (input: string | { props?: { children: any } }) => {
	if (typeof input === "object" && input?.props?.children) input = input?.props?.children;
	const value = extractFirstNumber(input);
	return Number(value) || tryParseDateOrReturnString(value);
};

// Handles the {year} - {month} values in the "Users Last Login Date" section
const tryParseDateOrReturnString = (value: string) => {
	if (valueIsLikelyDate(value)) {
		return dayjs(value, acceptableDateFormats).unix();
	}
	if (typeof value !== "string" || !value.includes("-")) return value;
	const date = new Date(value.replace("-", ""));
	if (isNaN(date.getFullYear())) return value;
	return date;
};

// Handles the "Enabled / Suspended / Warning" column values and order history cost
const extractFirstNumber = (value: any) => {
	if (Array.isArray(value) && value.length > 0) value = value[0];
	if (typeof value !== "string") return value;
	if (valueIsLikelyCost(value)) {
		return value.replace(/\D/g, "");
	}
	let idx = value?.indexOf("/");
	if (idx === -1) idx = value?.indexOf(",-");
	if (idx === -1) return value;
	return value.substring(0, idx).trim();
};

const acceptableDateFormats = ["DD.MM.YYYY", "DD-MM-YYYY", "YYYY MMMM", "YYYY-MM"];
const valueIsLikelyDate = (value: string) =>
	acceptableDateFormats.some((format) => dayjs(value, format).isValid());

const valueIsLikelyCost = (value: string) => {
	if (typeof value !== "string") return false;
	return (
		value.includes("kr") ||
		value.includes(",-") ||
		value.includes("NOK") ||
		value.includes("SEK")
	);
};

const descendingComparator = (a: Row, b: Row, orderBy: string) => {
	const first = parseTableCellValue(a[orderBy].sortValue);
	const second = parseTableCellValue(b[orderBy].sortValue);
	if (first < second) return -1;
	if (first > second) return 1;
	return 0;
};

const getComparator = (order: "asc" | "desc", orderBy: string) => {
	return order === "desc"
		? (a: Row, b: Row) => -descendingComparator(a, b, orderBy)
		: (a: Row, b: Row) => descendingComparator(a, b, orderBy);
};

interface SortableTableHeadProps {
	headers: string[];
	order: "asc" | "desc";
	orderBy: string;
	handleRequestSort: (request: string) => void;
}

const SortableTableHead = ({
	headers,
	order,
	orderBy,
	handleRequestSort,
}: SortableTableHeadProps): JSX.Element => {
	const createSortHandler = (property: string) => () => handleRequestSort(property);

	return (
		<TableHead className={styles.tableHead}>
			<TableRow>
				{headers.map((header) => (
					<TableCell key={header}>
						<TableSortLabel
							active={orderBy === header}
							direction={orderBy === header ? order : "asc"}
							onClick={createSortHandler(header)}
							className={styles.tableSortLabel}
						>
							{header}
							{orderBy === header ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export interface SortableTableProps {
	headers: string[];
	rows: Row[];
	classes?: { [key: string]: string };
	isLoading?: boolean;
	initialSortHeader?: string;
	initialSortDirection?: "asc" | "desc";
}

const SortableTable = ({
	headers,
	rows,
	classes,
	isLoading = false,
	initialSortHeader = headers[0],
	initialSortDirection = "asc",
}: SortableTableProps): JSX.Element => {
	const [order, setOrder] = useState<"asc" | "desc">(initialSortDirection);
	const [orderBy, setOrderBy] = useState(initialSortHeader);

	const handleRequestSort = (property: string) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	return (
		<Grid className={styles.scrollableTableContainer}>
			<Table {...{ classes }}>
				<SortableTableHead {...{ headers, order, orderBy, handleRequestSort }} />
				<TableBody>
					{isLoading ? (
						Array.from(Array(10)).map((_, i) => (
							<TableRow
								key={i}
								hover={true}
								classes={{
									root: styles.tableRow,
								}}
							>
								{Array.from(Array(headers.length)).map((_, i) => (
									<TableCell key={i}>
										<Skeleton variant="text" width={100} height={25} />
									</TableCell>
								))}
							</TableRow>
						))
					) : rows?.length === 0 ? (
						<TableRow classes={{ root: styles.tableRow }}>
							<TableCell colSpan={8}>
								<Typography variant="body1" align="center">
									No results found with current filters
								</Typography>
							</TableCell>
						</TableRow>
					) : (
						rows.sort(getComparator(order, orderBy)).map((row, i) => (
							<TableRow key={i} classes={{ root: styles.tableRow }}>
								{Object.values(row).map((item, j) => (
									<TableCell key={`${i}${j}`} classes={{ root: styles.cell }}>
										{item.element}
									</TableCell>
								))}
							</TableRow>
						))
					)}
				</TableBody>
			</Table>
		</Grid>
	);
};

export { SortableTable };
