import { InfoOutlined } from "@mui/icons-material";

import styles from "./DeviceInfoIcon.module.scss";
import { HoverTooltip } from "components/Common/Tooltips";

interface DeviceInfoIconProps {
	type: "totalDevices" | "enrollmentStatus" | "deviceStatus";
}

export const DeviceInfoIcon = ({ type }: DeviceInfoIconProps) => {
	let hoverText = <></>;

	switch (type) {
		case "totalDevices":
			hoverText = (
				<>
					<p className={styles.hoverTitle}>Entra joined </p>
					<p>
						Indicates that the user has used his microsoft account when logging into the
						device
					</p>
					<p className={styles.hoverTitle}>Entra Registered </p>
					<p>
						Indicates that the user has used his microsoft work account when logging
						into an application on the device
					</p>
				</>
			);
			break;
		case "enrollmentStatus":
			hoverText = (
				<>
					<p className={styles.hoverTitle}>Ironstone policy </p>
					<p>Ironstone managed. The device has the recommended default policy group</p>
					<p className={styles.hoverTitle}>Customer policy </p>
					<p>Device has a policy in Intune, but is not Ironstone managed</p>
					<p className={styles.hoverTitle}>No policy </p>
					<p>The device is enrolled in Intune but does not have a policy assigned</p>
				</>
			);
			break;
		case "deviceStatus":
			hoverText = (
				<>
					<p className={styles.hoverTitle}>Compliant</p>
					<p>
						Device enrolled in Intune with policy assigned - No policy breach detected
					</p>
					<p className={styles.hoverTitle}>Non-compliant</p>
					<p>
						A device is non-compliant if it's:
						<ul className={styles.hoverList}>
							<li>Enrolled in Intune, but no policy assigned</li>
							<li>
								Enrolled in Intune with policy assigned, and the device breaches
								policy
							</li>
						</ul>
					</p>
					<p className={styles.hoverTitle}>Inactive device</p>
					<p>
						An inactive device is a device that has not checked in with either intune or
						entra for the last 30 days.
					</p>
				</>
			);
			break;
	}
	return (
		<HoverTooltip description={hoverText}>
			<InfoOutlined className={styles.infoIcon} />
		</HoverTooltip>
	);
};
