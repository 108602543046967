import { Grid, Typography } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import styles from "./SwapProduct.module.scss";
import clsx from "clsx";
import { useAppDispatch } from "hooks/hooks";
import {
	setProductIdToSwap,
	setCountToSwap,
	setProductSwapDialogState,
} from "features/hardware/hardwarePage";
import { Availability } from "types";

interface SwapProductInputProps {
	availability: Availability;
	count: number;
	idToSwap: string;
}

export const SwapProduct = ({ idToSwap, count, availability }: SwapProductInputProps) => {
	const dispatch = useAppDispatch();

	const showSwapIcon = Number(availability?.quantity ?? 0) < count;

	const openSwapDialog = () => {
		dispatch(setProductIdToSwap(idToSwap));
		dispatch(setCountToSwap(count));
		dispatch(
			setProductSwapDialogState({
				isOpen: true,
			}),
		);
	};

	return (
		<Grid
			item
			className={clsx(styles.itemSwapContainer, {
				[styles.hidden]: !showSwapIcon,
			})}
			onClick={openSwapDialog}
		>
			<SwapHorizIcon className={styles.itemSwapIcon} />
			<Typography variant="description" className={styles.itemSwapText}>
				{"View alternatives"}
			</Typography>
		</Grid>
	);
};
