import React from "react";
import { DEFAULT_SORT_ORDER } from "utilities/constants/constants";
import { RiskEventTypes, RiskLevel, NoDuplicatesRiskDetections } from "types";
import { ExpandableRowItem } from "../DetailedRisksDialog/ExpandableRowItem";

type TGroupedNoDuplicatedRisks = {
	[T in RiskLevel]: NoDuplicatesRiskDetections[];
};

interface ExpandableRowProps {
	riskLevelObj: TGroupedNoDuplicatedRisks;
	rowName: keyof RiskEventTypes;
}

const ExpandableRow = ({ riskLevelObj, rowName }: ExpandableRowProps): JSX.Element => {
	return (
		<>
			{Object.entries(riskLevelObj)
				.sort((a, b) => {
					return (
						DEFAULT_SORT_ORDER.indexOf(a[0] as RiskLevel) -
						DEFAULT_SORT_ORDER.indexOf(b[0] as RiskLevel)
					);
				})
				.map(([riskLevel, risksArray], key) => (
					<ExpandableRowItem
						key={key}
						riskLevel={riskLevel as RiskLevel}
						risksArray={risksArray}
						rowName={rowName}
					/>
				))}
		</>
	);
};

export { ExpandableRow };
