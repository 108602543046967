import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet } from "utilities/api";
import { SCOPES } from "utilities/constants/constants";

export const checkAccess = createAsyncThunk(
	"customer/checkAccess",
	apiGet<boolean>(
		(authConfig) => authConfig.ACCESS_CONTROL_API_ENDPOINTS.CHECK_ACCESS(),
		[SCOPES.FUNCTION_READ],
	),
);
