import { Grid, Typography } from "@mui/material";
import { fetchOrders } from "actions/orderActions";
import { fetchProducts } from "actions/productActions";
import dayjs from "dayjs";
import { selectOrders, selectSearchedOrders } from "features/orders";
import { selectProducts } from "features/hardware/products";
import { selectUsers, usersSelectors } from "features/users";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { ReactElement, useEffect } from "react";
import { nonNullable } from "utilities/filters";
import _ from "lodash";
import { SortableTable } from "components/Common/SortableTable";
import { fetchUsers } from "actions/userActions";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { getStatus } from "utilities/features/orders";
import { ApiParams, TrackingLink } from "types";
import { ExternalLink } from "components/Common/Links";
import { HoverTooltip } from "components/Common/Tooltips";
import { ProductDeliveryStatus } from "utilities/constants/enums";
import { AUTOPILOT_SKU, TRANSPORTATION_METHODS } from "utilities/constants/constants";
import { toUnix } from "utilities/dates/dates";
import { UserDisplayInfo } from "components/Common/UserInfoDisplay";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";
import useRole from "utilities/roleUtils/roleCheck";

export const HardwareOrdersTable = (): ReactElement => {
	const { isLoading, isFetching } = useAppSelector(selectOrders);
	const orders = useAppSelector(selectSearchedOrders);

	const {
		dispatch,
		auth,
		auth: {
			account: { localAccountId },
		},
	} = useAuth();
	const { isUser } = useRole();

	useEffect(() => {
		if (!isLoading || isFetching) return;

		const params = { auth } as ApiParams;
		if (isUser) params.id = localAccountId; // Only fetch orders for the logged in user if they have basic user role access
		dispatch(fetchOrders(params));
	}, [dispatch, auth, isLoading, isFetching, localAccountId, isUser]);

	const usersById = useAppSelector(usersSelectors.selectEntities);

	useApiOnce(fetchUsers, useAppSelector(selectUsers));
	useApiOnce(fetchProducts, useAppSelector(selectProducts));

	const ORDERED_BY = "Ordered by";
	const ORDER_NUMBER_KOMPLETT = "Order number";
	const PURCHASE_DATE = "Purchase date";
	const STATUS = "Status";
	const TRACKING = "Tracking";
	const PRODUCTS = "Products";
	const COST = "Expense";
	const NOTE = "Additional info";

	const headers = [
		ORDERED_BY,
		ORDER_NUMBER_KOMPLETT,
		PURCHASE_DATE,
		STATUS,
		TRACKING,
		NOTE,
		PRODUCTS,
		COST,
	];

	const rows = orders.filter(nonNullable).map((order) => {
		const totalCost = _.sumBy(order.orderedItems, ({ price, quantity }) => price * quantity);
		return {
			[ORDERED_BY]: {
				element: (
					<UserDisplayInfo
						id={order.orderedBy}
						variant={{ name: "body1" }}
						showMail={false}
					/>
				),
				sortValue: usersById[order.orderedBy]?.displayName,
			},
			[ORDER_NUMBER_KOMPLETT]: {
				element: <OrderNumberKomplett komplettOrderNumber={order.komplettOrderNumber} />,
				sortValue: order.komplettOrderNumber,
			},
			[PURCHASE_DATE]: {
				element: (
					<Typography variant="body1">
						{dayjs(order.orderIssueDate).format("DD.MM.YYYY")}
					</Typography>
				),
				sortValue: toUnix(order.orderIssueDate),
			},
			[STATUS]: {
				element: (
					<ProductDeliveryStatusDetails
						status={getStatus(order.statusDetails)}
						shippedDate={order.shippedDate ?? ""}
					/>
				),
				sortValue: getStatus(order.statusDetails),
			},
			[TRACKING]: {
				element: (
					<Tracking
						trackingLinks={order.trackingLinks}
						transportationMethod={order.transportationMethod}
					/>
				),
				sortValue: order.transportationMethod,
			},
			[NOTE]: {
				element: (
					<Typography variant="body1" style={{ overflowWrap: "break-word" }}>
						{order.hardwareNote ?? ""}
					</Typography>
				),
				sortValue: order.hardwareNote ?? "",
			},
			[PRODUCTS]: {
				element: (
					<>
						{order.orderedItems.map(
							({ productId, link, quantity, displayName = "" }) => {
								const isAutopilotProduct = Number(productId) === AUTOPILOT_SKU;
								const nameToDisplay = isAutopilotProduct
									? "Autopilot"
									: displayName || link;
								return (
									<Grid container key={productId}>
										<Grid container item xs={1}>
											<Grid item xs={6} container justifyContent="flex-start">
												<Typography variant="body1" fontWeight={500}>
													{quantity}
												</Typography>
											</Grid>
											<Grid item xs={6} container justifyContent="flex-start">
												<Typography variant="body1" fontWeight={500}>
													x
												</Typography>
											</Grid>
										</Grid>
										<Grid item xs={11}>
											{!isAutopilotProduct ? (
												<ExternalLink target="_blank" href={link}>
													{tripleDotTruncateString(nameToDisplay, 38)}
												</ExternalLink>
											) : (
												<HoverTooltip
													title="Autopilot enrollment"
													description={
														<p>
															Enrollment in "Microsoft Intune" is done
															automatically when the receiving user
															logs in to their computer for the first
															time.
														</p>
													}
												>
													<Typography variant="body1">
														{nameToDisplay}
													</Typography>
												</HoverTooltip>
											)}
										</Grid>
									</Grid>
								);
							},
						)}
					</>
				),
				sortValue: order.orderedItems
					.map((item) => item.displayName || item.link)
					.join(", "),
			},
			[COST]: {
				element: (
					<Typography variant="body1">
						{isNaN(totalCost) ? "N/A" : `${formatCostString("kr ", totalCost, "")},-`}
					</Typography>
				),
				sortValue: isNaN(totalCost) ? 0 : totalCost,
			},
		};
	});

	return (
		<SortableTable
			{...{ headers, rows, isLoading }}
			initialSortHeader="Purchase date"
			initialSortDirection="desc"
		/>
	);
};

interface TrackingProps {
	trackingLinks: TrackingLink[] | undefined;
	transportationMethod: string;
}

const Tracking = ({ trackingLinks, transportationMethod }: TrackingProps): ReactElement => {
	if (!trackingLinks)
		return (
			<HoverTooltip
				title={""}
				description={"Tracking information is not available for this order yet."}
			>
				<Typography variant="body1">N/A</Typography>
			</HoverTooltip>
		);
	const { trackingUrl }: TrackingLink = trackingLinks[0];
	return (
		<ExternalLink href={trackingUrl}>
			{TRANSPORTATION_METHODS[transportationMethod] ?? transportationMethod}
		</ExternalLink>
	);
};

interface OrderNumberKomplettProps {
	komplettOrderNumber: number;
}

const OrderNumberKomplett = ({ komplettOrderNumber }: OrderNumberKomplettProps): ReactElement => {
	if (!komplettOrderNumber)
		return (
			<HoverTooltip
				title={""}
				description={"Order number is not available for this order yet."}
			>
				<Typography variant="body1">N/A</Typography>
			</HoverTooltip>
		);

	return (
		<ExternalLink
			href={`https://www.komplettbedrift.no/orders/${komplettOrderNumber}`}
			target="_blank"
		>
			{JSON.stringify(komplettOrderNumber)}
		</ExternalLink>
	);
};

const ProductDeliveryStatusDetails = ({
	status,
	shippedDate,
}: {
	status: string;
	shippedDate: string;
}): ReactElement => {
	if (!status) {
		return (
			// The span below won't accept styles from a module.scss file.
			// Unsure why, but using "style" does work.
			<span
				style={{
					padding: "6px 11px 6px 11px",
					borderRadius: "8px",
					fontWeight: 500,
					whiteSpace: "nowrap",
					cursor: "pointer",
					backgroundColor: "#F2F2F2",
					color: "#000000",
				}}
			>
				{status || "Pending"}
			</span>
		);
	}
	let description = "";

	if (status === ProductDeliveryStatus.ACCEPTED) {
		description = "The product order has been received by Komplett";
	}

	if (status === ProductDeliveryStatus.SHIPPED) {
		description = `The product has been shipped from Komplett on ${dayjs(shippedDate).format(
			"DD.MM.YYYY",
		)}`;
	}

	const bgColor =
		status === ProductDeliveryStatus.ACCEPTED
			? "#027ab6"
			: status === ProductDeliveryStatus.SHIPPED
			? "#27ae60"
			: "#F2F2F2";

	return (
		<HoverTooltip title={""} description={description}>
			<span
				style={{
					padding: "6px 11px 6px 11px",
					borderRadius: "8px",
					fontWeight: 500,
					whiteSpace: "nowrap",
					cursor: "pointer",
					backgroundColor: bgColor,
					color: "#FFFFFF",
				}}
			>
				{status}
			</span>
		</HoverTooltip>
	);
};
