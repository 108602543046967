import { Grid } from "@mui/material";
import { useApiOnceWithParams, useAppSelector } from "hooks/hooks";
import {
	selectCurrentYourEmployeesCost,
	selectLastYourEmployeesCost,
	selectYourEmployeesCost,
} from "features/costs";
import { fetchYourEmployeesCost } from "actions/costActions";

import styles from "./YourEmployeesCostView.module.scss";
import { DataUpdateInterval } from "components/Common/DataUpdateInterval";
import { IronstoneServicesTable } from "../IronstoneServicesTable/IronstoneServicesTable";
import { ReactComponent as YourEmployeesIcon } from "assets/cost/myemployees.svg";
import colors from "styles/utilities/_colors.scss";
import { CostTypeCard } from "../TotalsCards/CostTypeCard";
import dayjs from "dayjs";
import { CostType } from "utilities/constants/enums";
import { IRONSTONE_PRODUCT_DISPLAY_NAMES } from "utilities/constants/constants";

const YourEmployeesCostView = () => {
	const { isLoading, isFetching } = useAppSelector(selectYourEmployeesCost);
	useApiOnceWithParams(fetchYourEmployeesCost, { isLoading, isFetching }, { numberOfMonths: 2 });

	const cost = useAppSelector(selectCurrentYourEmployeesCost);
	const costLastPeriod = useAppSelector(selectLastYourEmployeesCost);

	return (
		<Grid container className={styles.wrapper}>
			<Grid
				container
				className={styles.topSectionContainer}
				justifyContent="space-between"
				mb={3}
			>
				<Grid container className={styles.cardContainer}>
					<CostTypeCard
						isLoading={isLoading}
						title={IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_EMPLOYEES}
						totalCurrentPeriod={cost?.totalCost ?? 0}
						totalPreviousPeriod={costLastPeriod?.totalCost}
						icon={<YourEmployeesIcon />}
						backgroundColor={colors.yellowTint1}
						size="medium"
						secondaryTitle="All purchased addons included"
						periodLabel={`${dayjs().startOf("month").format("DD.MM")} - ${dayjs()
							.endOf("month")
							.format("DD.MM")}`}
					/>
				</Grid>
				<Grid item className={styles.updateIntervalContainer} mt={3}>
					<DataUpdateInterval updateIntervalText={"Cost data updates every day"} />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<IronstoneServicesTable
					isLoading={isLoading}
					cost={cost}
					costType={CostType.YOUR_EMPLOYEES}
				/>
			</Grid>
		</Grid>
	);
};

export default YourEmployeesCostView;
