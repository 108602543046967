import PropTypes from "prop-types";
import { Paper, Grid, Typography } from "@mui/material";

import { MFAChart } from "../ChartTypes/MFAChart";

import styles from "./MFAStatistics.module.scss";

const MFAStatistics = ({ isLoading, checkCredentialRegistrationValues, settings, chartStyles }) => {
	const totalMembersWithMFA = isLoading ? 10 : checkCredentialRegistrationValues.MembersWithMFA;
	const totalMembersWithoutMFA = isLoading
		? 5
		: checkCredentialRegistrationValues.TotalMemberUsers -
		  checkCredentialRegistrationValues.MembersWithMFA;
	const totalGuestsWithMFA = checkCredentialRegistrationValues?.GuestsWithMFA;
	const totalGuestsWithoutMFA = isLoading
		? 10
		: checkCredentialRegistrationValues.TotalGuestUsers -
		  checkCredentialRegistrationValues.GuestsWithMFA;

	return (
		<Paper className={styles.container}>
			<Grid container>
				<Grid item xs={12}>
					<Grid item xs={12} className={styles.titleContainer}>
						<Typography variant="body2" className={styles.fontWeight500}>
							{settings.title}
						</Typography>
					</Grid>
					<MFAChart
						isLoading={isLoading}
						settings={settings}
						totalMembersWithMFA={totalMembersWithMFA}
						totalMembersWithoutMFA={totalMembersWithoutMFA}
						totalGuestsWithMFA={totalGuestsWithMFA}
						totalGuestsWithoutMFA={totalGuestsWithoutMFA}
						usersWithoutMfaDetails={
							checkCredentialRegistrationValues?.UsersWithoutMfaDetails
						}
						chartStyles={chartStyles}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

const membersAuthMethodsShape = PropTypes.shape({
	alternateMobilePhone: PropTypes.number,
	appCode: PropTypes.number,
	appNotification: PropTypes.number,
	email: PropTypes.number,
	mobilePhone: PropTypes.number,
	officePhone: PropTypes.number,
});

MFAStatistics.propTypes = {
	checkCredentialRegistrationValues: PropTypes.shape({
		EnabledSSPR: PropTypes.number,
		GuestsAuthMethods: PropTypes.object,
		GuestsWithMFA: PropTypes.number,
		IncludePersonalData: PropTypes.bool,
		MembersAuthMethods: membersAuthMethodsShape,
		MembersWithMFA: PropTypes.number,
		PossibleToEnableSSPR: PropTypes.number,
		TotalAuthMethods: membersAuthMethodsShape,
		TotalGuestUsers: PropTypes.number,
		TotalMemberUsers: PropTypes.number,
		TotalUsers: PropTypes.number,
		UsersWithMFA: PropTypes.number,
		UsersWithoutMfaDetails: PropTypes.arrayOf(
			PropTypes.shape({
				UserType: PropTypes.string,
				id: PropTypes.string,
				userDisplayName: PropTypes.string,
				userPrincipalName: PropTypes.string,
			}),
		),
	}),
	settings: PropTypes.shape({
		disabledLabelText: PropTypes.string,
		enabledLabelText: PropTypes.string,
		title: PropTypes.string,
	}),
};

export { MFAStatistics };
