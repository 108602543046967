import { Grid, Typography, Divider, Skeleton } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";

import styles from "./TwoMonthsCostChart.module.scss";
import { formatScandinavianCostString } from "utilities/currency/numberFormattingUtility";
import { useAppSelector } from "hooks/hooks";
import { selectTotalCost } from "features/costs";

const TwoMonthsCostChartLegend = (): JSX.Element => {
	const { isLoading, ...totalCost } = useAppSelector(selectTotalCost);
	return (
		<>
			<Grid container item xs={12} className={styles.legendContainer}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="body1">{dayjs().format("MMMM YYYY")}</Typography>
					</Grid>
					<Grid item xs={12}>
						<LegendInfoRow
							title={"Current Cost"}
							style={styles.currentPeriodCost}
							value={
								isLoading ? (
									<Skeleton variant="text" width={100} height={20} />
								) : (
									formatScandinavianCostString(
										totalCost?.totalCurrentCostThisMonth,
									)
								)
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<LegendInfoRow
							title={"Estimated total cost"}
							style={styles.currentPeriodForecast}
							value={
								isLoading ? (
									<Skeleton variant="text" width={100} height={20} />
								) : (
									formatScandinavianCostString(totalCost?.totalCost)
								)
							}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} mt={1}>
					<Grid item xs={12}>
						<Typography variant="body1">
							{dayjs().subtract(1, "month").format("MMMM YYYY")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<LegendInfoRow
							title={"Total"}
							style={styles.previousPeriod}
							value={
								isLoading ? (
									<Skeleton variant="text" width={100} height={20} />
								) : (
									formatScandinavianCostString(totalCost?.totalCostPreviousMonth)
								)
							}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

interface LegendInfoInputProps {
	title: string;
	value: string | JSX.Element;
	style: string;
}

const LegendInfoRow = ({ title, value, style }: LegendInfoInputProps) => {
	return (
		<Grid container item xs={12}>
			<Grid item xs={0.7}>
				<span className={clsx([styles.valueColorBlock, style])} />
			</Grid>
			<Grid item xs={11.3}>
				<span className={styles.inlineAlign}>
					<Typography variant="body1" display="inline" pl={0.5}>
						{title}
					</Typography>
					<Typography variant="body1">{value}</Typography>
				</span>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
		</Grid>
	);
};

export { TwoMonthsCostChartLegend };
