import { REGEX } from "utilities/constants/constants.ts";
import {
	trimAndRemoveDuplicateSpacesBetweenWords,
	validateInputUsingRegex,
} from "./stringFormattingUtility.js";

const required = (name) => `${name} is required.`;

const minLength = (name) => `${name} must be at least 2 characters long.`;

const noSpacesBetweenCharacters = (name) => `${name} must not contain spaces between characters.`;

const shouldNotContainSpacesBetweenCharacters = (input) => !input || !input.trim().includes(" ");

const userFormValidation = {
	givenName: {
		validationProps: {
			required: true,
			minLength: 2,
			validate: {
				pattern: validateInputUsingRegex(REGEX.USERNAME),
			},
		},
		getHelperText: ({ givenName: { type } = {} } = {}) => {
			const name = "First name";
			switch (type) {
				case "required":
					return required(name);
				case "minLength":
					return minLength(name);
				case "pattern":
					return `${name} must consist of letters, digits or dashes/underscores only`;
				default:
					return "";
			}
		},
	},
	surname: {
		validationProps: {
			required: true,
			minLength: 2,
			validate: {
				pattern: validateInputUsingRegex(REGEX.USERNAME),
			},
		},
		getHelperText: ({ surname: { type } = {} } = {}) => {
			const name = "Last name";
			switch (type) {
				case "required":
					return required(name);
				case "minLength":
					return minLength(name);
				case "pattern":
					return `${name} must consist of letters, digits or dashes/underscores only`;
				default:
					return "";
			}
		},
	},
	mailPrefix: {
		getValidationProps: (
			users,
			mailSuffix,
			loggedInUserPrincipalName,
			existingUserPrincipalName = "",
			isLoggedInUserEdit = true,
		) => ({
			required: true,
			validate: {
				hasAddressSign: (value) => !value.includes("@"),
				alreadyExists: (value) => {
					const mailPrefix = trimAndRemoveDuplicateSpacesBetweenWords(value);
					const formValue = `${mailPrefix}@${mailSuffix}`;
					// Users should be able to edit other fields and leave mail prefix untouched when editing user
					const allowExistingMailIfEditView =
						(isLoggedInUserEdit && formValue === loggedInUserPrincipalName) || // Allow logged in user mail if it's your own (My Profile)
						formValue === existingUserPrincipalName; // Allow form mail if it is equal to the existing (Manage Employeees)
					const isNoExistingMail = !users.some(
						({ userPrincipalName }) => formValue === userPrincipalName, // Disallow if already exists (all forms)
					);
					return allowExistingMailIfEditView || isNoExistingMail;
				},
				noSpacesBetweenCharacters: shouldNotContainSpacesBetweenCharacters,
				pattern: validateInputUsingRegex(REGEX.MAIL_PREFIX, true),
			},
		}),
		getHelperText: ({ mailPrefix: { type } = {} } = {}) => {
			const name = "Email";
			switch (type) {
				case "required":
					return required(name);
				case "hasAddressSign":
					return 'Please remove the "@" character. We already added this for you!';
				case "noSpacesBetweenCharacters":
					return noSpacesBetweenCharacters(name);
				case "pattern":
					return `${name} can contain letters, digits and special characters only.`;
				case "alreadyExists":
					return "Another employee with this email already exists";
				default:
					return "";
			}
		},
	},
	privateEmail: {
		validationProps: {
			validate: {
				noSpacesBetweenCharacters: shouldNotContainSpacesBetweenCharacters,
				pattern: validateInputUsingRegex(REGEX.MAIL_COMPLETE),
			},
		},
		getHelperText: ({ privateEmail: { type } = {} } = {}) => {
			const name = "Email";
			switch (type) {
				case "required":
					return required(name);
				case "noSpacesBetweenCharacters":
					return noSpacesBetweenCharacters(name);
				case "pattern":
					return "Please enter email in the following format: name@example.com";
				default:
					return "";
			}
		},
	},
	mobilePhone: {
		getValidationProps: (required) => ({
			validate: {
				required: (value) =>
					!required || trimAndRemoveDuplicateSpacesBetweenWords(value ?? "").length > 3,
				noSpacesBetweenCharacters: shouldNotContainSpacesBetweenCharacters,
				pattern: validateInputUsingRegex(REGEX.PHONE_NUMBER, true),
			},
		}),
		getHelperText: ({ mobilePhone: { type } = {} } = {}) => {
			const name = "Phone number";
			switch (type) {
				case "required":
					return required(name);
				case "noSpacesBetweenCharacters":
					return noSpacesBetweenCharacters(name);
				case "pattern":
					return `${name} must only include country code (e.g., +47) and digits.`;
				default:
					return "";
			}
		},
	},
	password: {
		validationProps: {
			required: true,
			pattern: REGEX.PASSWORD,
		},
		getHelperText: ({ password: { type } = {} } = {}) => {
			const name = "Password";
			switch (type) {
				case "required":
					return required(name);
				case "pattern":
					return `${name} must be at least 8 characters long, and include one uppercase letter, one digit. The password must not include the letters "Æ", "Ø" or "Å".`;
				default:
					return "";
			}
		},
	},
	streetAddress: {
		getValidationProps: (required) => ({
			required,
		}),
		getHelperText: ({ streetAddress: { type } = {} } = {}) => {
			const name = "Street address";
			switch (type) {
				case "required":
					return required(name);
				default:
					return "";
			}
		},
	},
	postalCode: {
		getValidationProps: (required) => ({
			required,
			validate: {
				pattern: validateInputUsingRegex(REGEX.POSTAL_CODE),
			},
		}),
		getHelperText: ({ postalCode: { type } = {} } = {}) => {
			const name = "Postal code";
			switch (type) {
				case "required":
					return required(name);
				case "pattern":
					return "Invalid postal code.";
				default:
					return "";
			}
		},
	},
	city: {
		getValidationProps: (required) => ({
			required,
			validate: {
				pattern: validateInputUsingRegex(REGEX.CITY_NAME),
			},
		}),
		getHelperText: ({ city: { type } = {} } = {}) => {
			const name = "City";
			switch (type) {
				case "required":
					return required(name);
				case "pattern":
					return "Letters only.";
				default:
					return "";
			}
		},
	},
};

export { userFormValidation };
