import { CardMedia, Grid } from "@mui/material";
import styles from "./HardwareImage.module.scss";
import clsx from "clsx";

interface HardwareImageProps {
	productId: string;
	imageUrl: string;
	size: "x_small" | "small" | "large";
}

export const HardwareImage = ({ productId, imageUrl, size }: HardwareImageProps) => {
	const handleGoToKomplettSpecification = (sku: string) => {
		window.open(`https://www.komplettbedrift.no/product/${sku}`, "_blank");
	};

	return (
		<Grid container className={clsx(styles.container, styles[size])}>
			<CardMedia
				image={imageUrl}
				component="img"
				className={styles.image}
				onClick={() => handleGoToKomplettSpecification(productId)}
			/>
		</Grid>
	);
};
