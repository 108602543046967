import { CUSTOMER_FACING_STATUS } from "utilities/constants/constants";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { ColorIndicator } from "../ColorIndicator";

import styles from "./SecurityIncidentsLegend.module.scss";

const SecurityIncidentsLegend = (): JSX.Element => {
	return (
		<Grid container classes={{ root: styles.container }}>
			<Grid item xs={10}>
				<List classes={{ root: styles.listContainer }}>
					<ListItem classes={{ root: styles.listItem }}>
						<ColorIndicator status={CUSTOMER_FACING_STATUS.AWAITING_REPLY.id} />
						<Typography variant="caption" ml={1}>
							Awaiting your reply
						</Typography>
					</ListItem>
					<ListItem classes={{ root: styles.listItem }}>
						<ColorIndicator status={CUSTOMER_FACING_STATUS.BEING_PROCESSED.id} />
						<Typography variant="caption" ml={1}>
							Being processed
						</Typography>
					</ListItem>
					<ListItem classes={{ root: styles.listItem }}>
						<ColorIndicator status={CUSTOMER_FACING_STATUS.RESOLVED.id} />
						<Typography variant="caption" ml={1}>
							Ticket resolved
						</Typography>
					</ListItem>
				</List>
			</Grid>
		</Grid>
	);
};

export { SecurityIncidentsLegend };
