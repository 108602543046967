import { Divider, Grid, Typography } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { LicenseAssignmentAndPurchase, SubscriptionVariantGroup, User } from "types";

import styles from "./LicenseAssignmentSummary.module.scss";
import { LicenseAssignmentType } from "utilities/constants/enums";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ReactComponent as LicenseIcon } from "assets/misc/License.svg";
import clsx from "clsx";
import { useState } from "react";
import { UserDisplayInfo } from "components/Common/UserInfoDisplay";

interface LicenseAssignmentSummaryProps {
	userIdsSelectedForAssignment: string[];
	allSelectedLicenses: {
		id: string;
		assignedBy: string;
	}[];
	purchaseAndAssignments: LicenseAssignmentAndPurchase;
	licenses: Dictionary<SubscriptionVariantGroup>;
	users: Dictionary<User>;
	isReceiptPage: boolean;
}

export const LicenseAssignmentSummary = ({
	userIdsSelectedForAssignment,
	allSelectedLicenses,
	purchaseAndAssignments,
	licenses,
	users,
	isReceiptPage, // certain elements, such as the hover effect and the helper text should not show on the receipt page
}: LicenseAssignmentSummaryProps) => {
	const [hoveredSkuId, setHoveredSkuId] = useState<string | null>(null);
	return (
		<>
			<Grid container item className={styles.usersContainer}>
				<Typography variant="body1">Users</Typography>
				{userIdsSelectedForAssignment.length > 0 ? (
					userIdsSelectedForAssignment.map((id) => (
						<SelectedUser
							key={id}
							user={users[id] ?? ({} as User)}
							hoveredSkuId={hoveredSkuId}
							showHoverEffect={!isReceiptPage}
						/>
					))
				) : (
					<SelectedUser user={{ displayName: "No selected users" } as User} />
				)}
			</Grid>
			<Divider />
			<Grid item className={styles.licensesContainer}>
				<Grid item>
					<Typography variant="body1" mt={1}>
						Assigned licenses
					</Typography>
				</Grid>
				{allSelectedLicenses.length > 0 ? (
					allSelectedLicenses.map((license) => {
						const userIdsOfUsersWithLicenseAlreadyAssigned =
							userIdsSelectedForAssignment.filter((userId) =>
								users[userId]?.licenses.some(
									(assignedLicense) => assignedLicense.skuId === license.id,
								),
							);

						return (
							<Grid
								key={license.id}
								container
								className={styles.assignedLicenseRow}
								onMouseEnter={() => setHoveredSkuId(license.id)}
								onMouseLeave={() => setHoveredSkuId(null)}
							>
								<Grid container item className={styles.licenseInfoContainer}>
									<Typography variant="description" key={license.id}>
										{purchaseAndAssignments.skuInformation[license.id]
											?.assignmentQuantity ?? 0}
										{"x "}
										{licenses[license.id]?.friendlyName}
									</Typography>
									{license.assignedBy !== LicenseAssignmentType.Direct && (
										<Typography variant="caption" className={styles.groupLabel}>
											{license.assignedBy}
										</Typography>
									)}
								</Grid>
								{!isReceiptPage &&
									userIdsOfUsersWithLicenseAlreadyAssigned.map((userId) => (
										<AlreadyAssignedToUser user={users[userId]} key={userId} />
									))}
							</Grid>
						);
					})
				) : (
					<Grid container className={styles.noneSelected}>
						<LicenseIcon className={styles.licenseIcon} />
						<Typography variant="body1">No licenses selected</Typography>
					</Grid>
				)}
			</Grid>
		</>
	);
};

const AlreadyAssignedToUser = ({ user }: { user: User | undefined }) => {
	return (
		<Grid container className={styles.alreadyAssignedContainer}>
			<AccountCircleIcon className={styles.smallUserIcon} />
			<Grid item>
				<Typography variant="caption" color="text.secondary">
					{user?.displayName} already has this license. Will not be assigned to user.
				</Typography>
			</Grid>
		</Grid>
	);
};

const SelectedUser = ({
	user,
	hoveredSkuId,
	showHoverEffect,
}: {
	user: User;
	hoveredSkuId?: string | null;
	showHoverEffect?: boolean;
}) => {
	if (!user.id) {
		return (
			<Grid container className={styles.noSelectedUser}>
				<AccountCircleIcon className={styles.userIcon} />
				<Typography variant="description">No selected users</Typography>
			</Grid>
		);
	}

	const userIsHovered =
		showHoverEffect && user.licenses.some((license) => license.skuId === hoveredSkuId);
	return (
		<Grid container className={clsx({ [styles.hovered]: userIsHovered })}>
			<AccountCircleIcon className={styles.userIcon} />
			<Grid item className={styles.userNameContainer}>
				<UserDisplayInfo id={user.id} />
			</Grid>
		</Grid>
	);
};
