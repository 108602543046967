import styles from "./DateRangePicker.module.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import colors from "styles/utilities/_colors.scss";
import { DateRange, DateRangePicker as SelectDateRange } from "react-date-range";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Grid, IconButton, InputBase } from "@mui/material";
import dayjs from "dayjs";
import { formatRangesForDateRangePicker } from "utilities/dates/dateRangePicker";
import { theme } from "theme";
import { useClickOutside, useWindowSize } from "hooks/hooks";
import { useRef } from "react";
import clsx from "clsx";

interface DateRangePickerProps {
	ranges: any[];
	setRanges: (period: any[]) => void;
	showDateRangePicker: boolean;
	setShowDateRangePicker: (show: boolean) => void;
	selectablePeriods: any[];
	selectedRangeDisplay: string;
}

const DateRangePicker = ({
	ranges,
	setRanges,
	showDateRangePicker,
	setShowDateRangePicker,
	selectablePeriods,
	selectedRangeDisplay,
}: DateRangePickerProps) => {
	const formattedDateRanges = formatRangesForDateRangePicker(selectablePeriods);
	const allShownRanges = formattedDateRanges.slice(0, 7);
	const ref = useRef(null);
	useClickOutside(ref, () => setShowDateRangePicker(false));
	const { width } = useWindowSize(50);
	return (
		<div className={styles.dateRangePickerContainer}>
			{showDateRangePicker && (
				<Grid item xs={12} className={styles.dateRangePicker} ref={ref}>
					{width > theme.breakpoints.values.xl ? (
						<SelectDateRange
							onChange={(item) => {
								const dateRange = item.selection as unknown as Range;
								setRanges([dateRange]);
							}}
							moveRangeOnFirstSelection={false}
							months={2}
							ranges={ranges}
							direction="horizontal"
							preventSnapRefocus={true}
							rangeColors={[colors.blueDark]}
							weekStartsOn={1}
							shownDate={new Date()} // Sets the initially displayed month
							staticRanges={allShownRanges}
						/>
					) : (
						<DateRange
							onChange={(item) => {
								const dateRange = item.selection as unknown as Range;
								setRanges([dateRange]);
							}}
							moveRangeOnFirstSelection={false}
							months={1}
							ranges={ranges}
							direction="horizontal"
							preventSnapRefocus={true}
							rangeColors={[colors.blueDark]}
							weekStartsOn={1}
							shownDate={new Date()} // Sets the initially displayed month
						/>
					)}
				</Grid>
			)}
			<div
				className={clsx({
					[styles.inputRoot]: true,
					[styles.hidden]: showDateRangePicker,
				})}
				onClick={() => setShowDateRangePicker(true)}
			>
				<IconButton disabled className={styles.iconButton} aria-label="search">
					<CalendarTodayIcon />
				</IconButton>
				<InputBase
					value={selectedRangeDisplay}
					className={styles.input}
					placeholder="Filter date interval"
					onChange={(e) =>
						setRanges([
							{
								startDate: dayjs(e.target.value.split(" - ")[0]).toDate(),
								endDate: dayjs(e.target.value.split(" - ")[1]).toDate,
								key: "selection",
							},
						])
					}
				/>
				<IconButton disabled className={styles.iconButton}>
					<KeyboardArrowDown />
				</IconButton>
			</div>
		</div>
	);
};

export { DateRangePicker };
