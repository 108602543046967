import { HardwareBundle, HardwareBundleItem } from "types";

export const countBundledItems = (bundledProducts: HardwareBundleItem[]) =>
	bundledProducts.reduce((acc, product) => {
		return acc + product.count;
	}, 0);

export const categoryValueMap: { [key: string]: number } = {
	PC_NOTEBOOK_BUSINESS: 5,
	PHONES_MOBILE: 4,
	PC_ACCESSORIES: 1,
	MICE_KEYBOARDS: 1,
	SOUND: 1,
	MONITOR: 3,
	APPLE_PC_NOTEBOOK: 5,
};

export const sortByCategoryAndPrice = (item1: HardwareBundleItem, item2: HardwareBundleItem) => {
	// TODO: Add price in the sorting aswell?
	const categoryValueDiff = categoryValueMap[item2.group] - categoryValueMap[item1.group];
	return categoryValueDiff;
};

export const bundleCountBySku = (bundle?: HardwareBundle) => {
	const bundleProducts = Object.values(bundle?.products ?? {});
	return bundleProducts.reduce((acc, product) => ({ ...acc, [product.sku]: product.count }), {});
};
