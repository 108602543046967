import PropTypes from "prop-types";
import { Paper, Grid, Typography } from "@mui/material";

import { CompliancyChart } from "components/SecurityDashboard/ChartTypes/CompliancyChart";
import { DownloadableLink } from "components/Common/DownloadableLink/DownloadableLink";

import styles from "./DevicesStatistics.module.scss";
import { DEVICES_STATISTICS } from "data/mockResponses";

const DevicesStatistics = ({ isLoading, checkDevicesValues, settings, chartStyles }) => {
	const data = isLoading ? DEVICES_STATISTICS : checkDevicesValues;

	return (
		<Paper className={styles.container}>
			<Grid item xs={12}>
				<Grid item xs={12} className={styles.titleContainer}>
					<Typography variant="body2" className={styles.fontWeight500}>
						{settings.title}
					</Typography>
				</Grid>
				<Grid container item xs={12} className={styles.chartAndLegendContainer}>
					<CompliancyChart
						{...{
							isLoading,
							settings,
							chartStyles,
							checkDevicesValues,
						}}
					/>
					<Grid item xs={12} mt={1}>
						<DownloadableLink
							data={data?.AllDevicesDetails ?? []}
							fileName={"devices-compliance-status.csv"}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

DevicesStatistics.propTypes = {
	checkDevicesValues: PropTypes.shape({
		AllDevicesDetails: PropTypes.arrayOf(
			PropTypes.shape({
				accountEnabled: PropTypes.bool,
				createdDateTime: PropTypes.string,
				deviceId: PropTypes.string,
				deviceOwnership: PropTypes.string,
				displayName: PropTypes.string,
				isCompliant: PropTypes.bool,
				isManaged: PropTypes.bool,
				isRooted: PropTypes.bool,
				managementType: PropTypes.string,
				manufacturer: PropTypes.string,
				model: PropTypes.string,
				operatingSystem: PropTypes.string,
				operatingSystemVersion: PropTypes.string,
				registrationDateTime: PropTypes.any,
			}),
		),
		NumberOfCompliantDevices: PropTypes.shape({
			False: PropTypes.number,
			NotAvailable: PropTypes.number,
			True: PropTypes.number,
		}),
		NumberOfOperatingSystems: PropTypes.shape({
			AndroidEnterprise: PropTypes.number,
			Unknown: PropTypes.number,
		}),
		TotalNumberOfDevices: PropTypes.number,
	}),
	settings: PropTypes.shape({
		compliantLabelText: PropTypes.string,
		noStatusLabelText: PropTypes.string,
		nonCompliantLabelText: PropTypes.string,
		title: PropTypes.string,
	}),
};

export { DevicesStatistics };
