import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AuthConfig, ApiParams } from "types";

const api = axios.create();

// Add a response interceptor
api.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error?.response && error?.response?.data) {
			if (error.response.data.message) {
				error.message = error.response.data.message;
			}
		}

		// Always reject the error to maintain consistent behavior with axios
		return Promise.reject(error);
	},
);

type AxiosRequest = <T = any, R = AxiosResponse<T>, D = any>(
	url: string,
	config?: AxiosRequestConfig<D>,
) => Promise<R>;

type AxiosRequestWithBody = <T = any, R = AxiosResponse<T>, D = any>(
	url: string,
	data?: D,
	config?: AxiosRequestConfig<D>,
) => Promise<R>;

type GetUrl = (authConfig: AuthConfig, ids: { [id: string]: string }) => string;

const callAPI =
	(callAsync: AxiosRequest | AxiosRequestWithBody) =>
	<T>(getUrl: GetUrl, scopes: string[]) =>
	async ({
		body,
		headers = {},
		responseType = "json",
		auth: { instance, account, authConfig },
		...ids
	}: ApiParams) => {
		const forceNewToken = authConfig.getMSALConfig().auth.tenantId !== "common";
		const { tokenType, accessToken } = await instance.acquireTokenSilent({
			scopes,
			account,
			authority: authConfig.getMSALConfig().auth.authority,
			forceRefresh: forceNewToken,
		});

		const config = {
			headers: {
				...headers,
				Authorization: `${tokenType} ${accessToken}`,
				"Ocp-Apim-Subscription-Key": authConfig.OCP_APIM_SUBSCRIPTION_KEY,
			},
			responseType: responseType,
		};

		let task = body
			? callAsync<T>(getUrl(authConfig, ids), body, config)
			: callAsync<T>(getUrl(authConfig, ids), config);

		const { data } = await task;

		return data;
	};

export const apiGet = callAPI(api.get);
export const apiPut = callAPI(api.put);
export const apiPost = callAPI(api.post);
export const apiPatch = callAPI(api.patch);
export const apiDelete = callAPI(api.delete);
