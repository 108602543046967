import { createAsyncThunk } from "@reduxjs/toolkit";
import { HardwareProduct, ProductsMetadata } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

interface HardwareProductAPIResponse {
	products: HardwareProduct[];
	metadata: ProductsMetadata;
}

export const fetchProducts = createAsyncThunk(
	"products/fetch",
	apiGet<HardwareProductAPIResponse>(
		(authConfig) => authConfig.HARDWARE_API_ENDPOINTS.GET_PRODUCTS_V2(),
		[SCOPES.HARDWARE_READ],
	),
);
