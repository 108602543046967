import { Grid, Skeleton, Typography } from "@mui/material";
import { OpenDrawerButton, OpenDrawerButtonContent } from "components/Common/Buttons/Button/Button";

import styles from "./TermDurationChangeCard.module.scss";
import { LicenseSubscriptionRecommendation } from "types";
import { CostSavingsType } from "utilities/constants/enums";
import { selectLicenseActions } from "features/scheduledActions";
import { useAppSelector } from "hooks/hooks";
import clsx from "clsx";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

interface TermDurationChangeCardInputProps {
	recommendation: LicenseSubscriptionRecommendation;
	costSavingsTermDuration: number;
	handleDrawerStateChange: (open: boolean, type: CostSavingsType) => void;
}

export const TermDurationChangeCard = ({
	recommendation,
	costSavingsTermDuration,
	handleDrawerStateChange,
}: TermDurationChangeCardInputProps) => {
	let { isLoading: isLicenseActionsLoading } = useAppSelector(selectLicenseActions);

	const title = "Licenses on monthly commitment";
	const buttonText = "Optimize with Ironstone";

	const getContent = () => {
		if (costSavingsTermDuration <= 0) {
			return (
				<Grid item xs={12} className={styles.cardContent}>
					<Typography variant="body1">{title}</Typography>
					<Grid container mt={3} justifyContent="center">
						<Grid item xs={12}>
							<Typography
								variant="h3"
								display="inline"
								className={styles.optimizedText}
							>
								0
							</Typography>
							<Typography
								variant="description"
								className={styles.licenseNotInUseDescription}
							>
								{`licenses left to convert`}
							</Typography>
						</Grid>
						<Grid container mt={3}>
							<span className={styles.optimized}>Optimized</span>
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return (
			<Grid item xs={12} className={styles.cardContent}>
				<Grid item xs={12}>
					<Typography variant="body1">{title}</Typography>
				</Grid>
				<Grid container className={styles.midSectionContainer}>
					<Grid item xs={12}>
						<Typography
							variant="h3"
							display="inline"
							className={clsx({
								[styles.optimizedText]: recommendation.current_P1MCount <= 0,
							})}
						>
							{recommendation.current_P1MCount} of {recommendation.current_Total}
						</Typography>
						<Typography
							variant="description"
							className={styles.licenseNotInUseDescription}
						>
							licenses are monthly
						</Typography>
					</Grid>
					<Grid item xs={12} mt={4}>
						<Typography variant="description" className={styles.greyText}>
							Change licenses to annual subscription plan
						</Typography>
						<br />
						<Typography
							variant="description"
							fontWeight={500}
							className={styles.potentialSavings}
						>
							{`${formatCostString(
								"Save up to ",
								`${costSavingsTermDuration * 12}`,
								" kr / year",
							)}`}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12} maxHeight={70}>
					{!isLicenseActionsLoading ? (
						<OpenDrawerButton
							text={<OpenDrawerButtonContent text={buttonText} />}
							action={() =>
								handleDrawerStateChange(true, CostSavingsType.TermDurationChange)
							}
							size="small"
							variantStyle="outlined"
						/>
					) : (
						<div className={styles.skeletonButton}>
							<Skeleton variant="text" width={200} height={80} animation="wave" />
						</div>
					)}
				</Grid>
			</Grid>
		);
	};

	return <>{getContent()}</>;
};
