import { AccountInfo } from "@azure/msal-browser";
import jwtDecode from "jwt-decode";
import { AuthConfig } from "types";
import { SCOPES } from "utilities/constants/constants";

interface CalculateRolesProps {
	authConfig: AuthConfig;
	account: AccountInfo;
	isHomeTenant: boolean;
	instance: any;
}

export const calculateRoles = async ({
	authConfig,
	account,
	isHomeTenant,
	instance,
}: CalculateRolesProps) => {
	if (isHomeTenant) {
		return account?.idTokenClaims?.roles;
	}

	// If not in home tenant, we cannot use the roles from the idToken, as the user might have different roles in the current tenant
	const { accessToken } = await instance.acquireTokenSilent({
		scopes: [SCOPES.GRAPH_WRITE],
		account,
		forceRefresh: true,
		authority: authConfig.getMSALConfig().auth.authority,
	});
	const decodedToken = jwtDecode(accessToken) as any;
	return decodedToken.roles;
};
