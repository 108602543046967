import { Grid } from "@mui/material";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import styles from "./EditBundle.module.scss";
import { fetchProducts } from "actions/productActions";
import { fetchHardwareBundles } from "actions/hardwareBundlesActions";
import { useAppSelector, useApiOnce, useAuth, useApiOnceWithAndWithoutCache } from "hooks/hooks";
import { productsSelectors, selectProducts } from "features/hardware/products";
import { HardwareGroups, Manufacturers } from "types";
import useInfiniteLoader from "hooks/useInfiniteLoader";
import { SortBy } from "utilities/constants/enums";
import { selectHardwareBundles, selectBundleCount } from "features/hardware/hardwareBundles";
import { FilterGroup } from "../../FilterGroup";
import { HardwareList } from "../../HardwareList";
import { NoResultMessage } from "../../NoResultMessage";
import { HardwareDrawer } from "../../HardwareDrawer";
import { HardwareDrawerType } from "utilities/constants/enums";
import { SearchBanner } from "../../SearchBanner";
import { AddToBundleButton } from "../../HardwareList/Product/AddToBundleButton";
import { useFilteredProducts } from "hooks/hardwareHooks";
import {
	DEFAULT_SELECTED_HARDWARE_GROUPS,
	DEFAULT_SELECTED_HARDWARE_MANUFACTURERS,
} from "utilities/constants/constants";
import { setHardwareDrawerState } from "features/hardware/hardwarePage";

const EditBundle = () => {
	const cartItemsCount = useAppSelector(selectBundleCount);

	const productState = useAppSelector(selectProducts);
	const products = useAppSelector(productsSelectors.selectAll);

	const bundleState = useAppSelector(selectHardwareBundles);

	useApiOnceWithAndWithoutCache(fetchProducts, productState);

	// TODO: Might need to include in other bundle components to ensure that bundles are loaded in bundlestate
	useApiOnce(fetchHardwareBundles, bundleState);

	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
	const [totalProductsInSearch, setTotalProductsInSearch] = useState<number>(products.length);

	// TODO: UTLED BACKEND
	const maxPrice = 75000;

	const [pricerange, setPricerange] = useState<number[]>([0, maxPrice]);

	const handlePriceChange = (
		event: Event | SyntheticEvent<Element, Event>,
		newValue: number | number[],
	) => {
		setPricerange(newValue as number[]);
	};

	const [selectedGroups, setSelectedGroups] = useState<HardwareGroups>(
		DEFAULT_SELECTED_HARDWARE_GROUPS,
	);

	const [selectedManufacturers, setSelectedManufacturers] = useState<Manufacturers>(
		DEFAULT_SELECTED_HARDWARE_MANUFACTURERS,
	);

	const defaultSort = SortBy.NotSorted;

	const [sortBy, setSortBy] = useState<string>(defaultSort);
	const [showInStockOnly, setShowInStockOnly] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const hasNoSearchResult = selectedProducts.length === 0 && !productState.isLoading;

	const { loaderRef, renderCount, resetRenderCount } = useInfiniteLoader({
		total: totalProductsInSearch,
	});

	const handleGroupChange = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
		setSelectedGroups((state) => {
			// TODO: enum
			if (name === "PC_NOTEBOOK_BUSINESS") {
				return {
					...state,
					[name]: checked,
					APPLE_PC_NOTEBOOK: checked,
				};
			} else {
				return {
					...state,
					[name]: checked,
				};
			}
		});
	};

	const handleManufacturerChange = ({
		target: { name, checked },
	}: ChangeEvent<HTMLInputElement>) => {
		setSelectedManufacturers((state) => ({
			...state,
			[name]: checked,
		}));
	};

	const setSortedSearch = (value: string) => {
		window.scrollTo(0, 0);
		setSortBy(value);
	};

	const { filteredProducts, filteredTotalProducts } = useFilteredProducts({
		sortBy,
		setSortBy,
		products,
		selectedGroups,
		selectedManufacturers,
		showInStockOnly,
		renderCount,
		searchQuery,
		pricerange,
		setSelectedGroups,
		setSelectedManufacturers,
	});

	useEffect(() => {
		setTotalProductsInSearch(filteredTotalProducts);
		setSelectedProducts(filteredProducts);
	}, [filteredTotalProducts, filteredProducts]);

	const { dispatch } = useAuth();

	useEffect(() => {
		// Make sure that the bundle drawer is active when the user is on the edit bundle page
		dispatch(
			setHardwareDrawerState({
				activeDrawerType: HardwareDrawerType.Bundle,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container className={styles.wrapper}>
			<div className={styles.topOfScreenRef} />
			<HardwareDrawer />
			<Grid container className={styles.container}>
				<Grid container className={styles.formGroup}>
					<FilterGroup
						setSearchQuery={setSearchQuery}
						resetRenderCount={resetRenderCount}
						setShowInStockOnly={setShowInStockOnly}
						showInStockOnly={showInStockOnly}
						selectedGroups={selectedGroups}
						selectedManufacturers={selectedManufacturers}
						handleGroupChange={handleGroupChange}
						handleManufacturerChange={handleManufacturerChange}
						products={products}
						setPricerange={handlePriceChange}
						pricerange={pricerange}
						maxPrice={maxPrice}
					/>
				</Grid>
				<Grid container className={styles.editBundleContentWrapper}>
					<Grid container className={styles.searchBannerContainer}>
						<SearchBanner
							type={HardwareDrawerType.Bundle}
							searchQuery={searchQuery}
							sortBy={sortBy}
							isOnboarding={false}
							defaultSort={defaultSort}
							setSearchQuery={setSearchQuery}
							setSortBy={setSortedSearch}
							resetRenderCount={resetRenderCount}
							setShowInStockOnly={setShowInStockOnly}
							showInStockOnly={showInStockOnly}
							selectedGroups={selectedGroups}
							selectedManufacturers={selectedManufacturers}
							handleGroupChange={handleGroupChange}
							handleManufacturerChange={handleManufacturerChange}
							products={products}
							isLoading={productState.isLoading}
							cartItemsCount={cartItemsCount}
							pricerange={pricerange}
							setPricerange={handlePriceChange}
							maxPrice={maxPrice}
						/>
					</Grid>
					<Grid container className={styles.productsWrapper}>
						<Grid container className={styles.products}>
							<Grid container className={styles.hardwareListContainer}>
								<HardwareList
									selectedProducts={selectedProducts}
									setShowCheckoutDialog={() => {}}
									isLoading={productState.isLoading}
									addToCartComponent={AddToBundleButton}
								/>
								{!hasNoSearchResult && (
									<div ref={loaderRef} className={styles.loaderRef} />
								)}
							</Grid>
							<Grid container className={styles.noItemsMessageContainer}>
								{hasNoSearchResult && (
									<NoResultMessage
										searchQuery={searchQuery}
										manufacturers={selectedManufacturers}
										groups={selectedGroups}
										inStock={showInStockOnly}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export { EditBundle };
