import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

const rolesSlice = createSlice({
	name: "roles",
	initialState: [] as string[],
	reducers: {
		setRoles(_, { payload }) {
			const roles = payload as string[];
			const allRoles = roles || ["User"];
			return allRoles;
		},
	},
});

export const { setRoles } = rolesSlice.actions;

export const selectRoles = (state: RootState) => state.roles;

export default rolesSlice.reducer;
