import { Grid, Typography } from "@mui/material";
import styles from "./DataUpdateInterval.module.scss";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

interface DataUpdateIntervalProps {
	updateIntervalText: string;
}
/**
 * @param {updateIntervalText}:
 * The text passed to display and the text in the component.
 */

const DataUpdateInterval = ({ updateIntervalText }: DataUpdateIntervalProps) => {
	return (
		<Grid item xs={12} className={styles.updateIntervalContainer}>
			<div className={styles.container}>
				<CloudSyncIcon className={styles.icon} fontSize="medium" />
				<Typography noWrap>{updateIntervalText}</Typography>
			</div>
		</Grid>
	);
};

export { DataUpdateInterval };
