import { Grid, Typography } from "@mui/material";
import { LicenseActionStatus, LicenseActionTargetType } from "utilities/constants/enums";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { LicenseSubscriptionRecommendation } from "types";
import { useAppSelector } from "hooks/hooks";
import { selectLicenseActionsWithSkuGUIDKeys } from "features/scheduledActions";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import OptimizedLicensesChart from "components/Recommendations/OptimizedLicensesChart/OptimizedLicensesChart";

import styles from "./TotalSavingsChartCard.module.scss";
import { HoverTooltip } from "components/Common/Tooltips";
import dayjs from "dayjs";

interface TotalSavingsCardInputProps {
	recommendation: LicenseSubscriptionRecommendation;
	totalCostSavings: number;
	allSavingTypesOptimized: boolean;
}

export const TotalSavingsChartCard = ({
	recommendation,
	totalCostSavings,
	allSavingTypesOptimized,
}: TotalSavingsCardInputProps) => {
	const licenseActionsGroupedBySkuGUID =
		useAppSelector(selectLicenseActionsWithSkuGUIDKeys) ?? {};
	const totalYearlySavingsAllTypes = totalCostSavings * 12;

	const licenseActions = licenseActionsGroupedBySkuGUID[recommendation?.subscriptionSkuID] ?? [];

	const { scheduled, finished } = licenseActions.reduce(
		(acc, action) => {
			if (action.TargetType !== LicenseActionTargetType.Subscription) {
				return acc;
			}
			const isActionOrderedWithinLastMonth = dayjs().diff(action.OrderDate, "day") < 32;
			if (!isActionOrderedWithinLastMonth) {
				return acc;
			}
			if (
				action.Status === LicenseActionStatus.Finished ||
				action.Status === LicenseActionStatus.PartiallyFinished
			) {
				return {
					scheduled: acc.scheduled,
					finished: acc.finished + Math.abs(action.TotalSavings * 12),
				};
			}

			if (action.Status === LicenseActionStatus.Scheduled) {
				return {
					scheduled: acc.scheduled + Math.abs(action.TotalSavings * 12),
					finished: acc.finished,
				};
			}

			return acc;
		},
		{
			scheduled: 0,
			finished: 0,
		} as { scheduled: number; finished: number },
	);

	const filled = allSavingTypesOptimized ? 1 : scheduled + finished;
	const max = allSavingTypesOptimized ? 0 : totalYearlySavingsAllTypes;

	return (
		<Grid container className={styles.totalSavingsCardContent}>
			<HoverTooltip
				title={"Optimization progress"}
				description={
					<>
						<span className={styles.costContainer}>
							<SquareRoundedIcon className={styles.greenIcon} />
							<div>
								<Typography
									variant="body2"
									fontWeight={500}
									className={styles.savingsDescriptionText}
								>
									Saved
								</Typography>
								<Typography
									variant="body2"
									className={styles.savingsDescriptionText}
								>
									{formatCostString(" ", finished + scheduled, " kr / year")}
								</Typography>
							</div>
						</span>
						<br />
						<span className={styles.costContainer}>
							<SquareRoundedIcon className={styles.blueIcon} />
							<div>
								<Typography
									variant="body2"
									fontWeight={500}
									className={styles.savingsDescriptionText}
								>
									Remaining potential savings
								</Typography>
								<Typography
									variant="body2"
									className={styles.savingsDescriptionText}
								>
									{formatCostString("", max, " kr / year")}
								</Typography>
							</div>
						</span>
						<br />
					</>
				}
			>
				<Grid item className={styles.chartContainer}>
					<OptimizedLicensesChart type="circular" filled={filled} max={max + filled} />
				</Grid>
			</HoverTooltip>
		</Grid>
	);
};
