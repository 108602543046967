import React from "react";

interface SvgIconProps {
	color?: string;
}

export const LicenseIcon = ({ color }: SvgIconProps) => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 80 59"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M39.986 58.052C28.6817 58.052 17.3774 58.052 6.08089 58.052C2.26558 58.052 0 55.7786 0 51.9554C0 36.6312 0 21.3149 0 5.99077C0 2.2856 2.27345 0.0121404 5.9865 0.0121404C23.8201 0.0121404 41.6537 0.0121404 59.4874 0.0121404C60.9899 0.0121404 62.5003 -0.0271936 64.0028 0.0357393C64.4512 0.0514725 64.994 0.248133 65.3244 0.547065C70.0286 4.74784 74.7093 8.97221 79.3663 13.228C79.7046 13.5348 79.972 14.117 79.972 14.5733C80.0035 27.0654 80.0035 39.5655 79.9956 52.0577C79.9956 55.755 77.6828 58.052 73.9934 58.052C62.6576 58.052 51.3218 58.052 39.986 58.052ZM39.9546 54.8503C51.3218 54.8503 62.6891 54.8503 74.0563 54.8503C75.8657 54.8503 76.7703 53.9457 76.786 52.1364C76.7939 51.4441 76.786 50.7518 76.786 50.0674C76.786 39.5498 76.786 29.0321 76.786 18.5144C76.786 16.3983 75.9836 15.6195 73.8911 15.6038C72.1369 15.588 70.3669 15.6431 68.6205 15.5094C66.1268 15.3206 64.0657 13.1966 63.8691 10.695C63.7747 9.47567 63.7511 8.2485 63.7983 7.02917C63.9241 4.11066 63.3971 3.20599 60.0066 3.21386C42.0156 3.26106 24.0246 3.23746 6.03369 3.23746C4.01984 3.23746 3.20958 4.06345 3.20958 6.10877C3.20958 21.37 3.20958 36.6313 3.20958 51.8925C3.20958 54.0637 4.0277 54.8739 6.23822 54.8739C17.4718 54.8503 28.7132 54.8503 39.9546 54.8503ZM73.6158 12.3234C71.3423 10.2781 69.2656 8.40581 67.0629 6.41555C67.0629 7.92594 66.9764 9.2318 67.0944 10.5219C67.1888 11.5131 67.9046 12.2211 68.8958 12.2919C70.3669 12.4021 71.8694 12.3234 73.6158 12.3234Z" />
			<path d="M16.1109 25.0363C14.8443 21.457 15.5838 18.3418 18.1326 16.4066C20.6342 14.5108 23.9775 14.44 26.4712 16.2336C29.2324 18.2238 30.0112 21.221 28.7525 25.0363C31.6946 26.3579 32.3082 27.3412 32.1902 30.6216C32.1509 31.6836 32.088 32.7535 31.9857 33.8155C31.8284 35.436 31.6317 37.0565 31.4508 38.677C31.1597 41.2337 29.4998 42.9014 26.9353 42.9879C23.8673 43.0902 20.7915 43.1531 17.7235 43.0115C15.3006 42.9014 13.7273 41.3045 13.4362 38.8973C13.0901 36.0968 12.8147 33.2805 12.6495 30.4722C12.4686 27.3963 13.2553 26.2556 16.1109 25.0363ZM22.4986 41.5483C24.0797 41.4539 25.3305 41.391 26.5813 41.3124C28.5637 41.1865 29.547 40.2661 29.7437 38.2837C30.0033 35.7585 30.2472 33.2333 30.4517 30.7003C30.5068 30.0552 30.4989 29.3708 30.3573 28.7415C29.9561 26.94 28.1625 26.3186 26.7387 27.4199C24.0876 29.4731 20.4768 29.418 17.8966 27.2311C17.401 26.8063 16.9447 26.7276 16.3626 26.8692C15.1669 27.1524 14.2937 28.0807 14.3802 29.3708C14.5847 32.5568 14.8601 35.7428 15.2298 38.913C15.4029 40.3684 16.4806 41.2179 17.9438 41.3281C19.5643 41.4461 21.1927 41.4933 22.4986 41.5483ZM22.4356 27.176C25.3227 27.1682 27.6905 24.7689 27.6669 21.8818C27.6433 19.042 25.2755 16.6741 22.4435 16.6584C19.5958 16.6426 17.1571 19.0656 17.1492 21.929C17.1414 24.7846 19.5564 27.1839 22.4356 27.176Z" />
			<path d="M53.8467 22.314C58.3306 22.314 62.8146 22.314 67.2986 22.314C68.3842 22.314 68.9584 22.7546 68.927 23.5491C68.9034 24.32 68.3606 24.7291 67.33 24.7291C58.3306 24.7291 49.3312 24.737 40.3318 24.7291C39.1833 24.7291 38.5382 24.0053 38.8608 23.1164C39.1204 22.4006 39.7182 22.3062 40.3948 22.3062C44.8787 22.314 49.3627 22.314 53.8467 22.314Z" />
			<path d="M53.9019 29.6301C58.2914 29.6301 62.681 29.6301 67.0706 29.6301C67.3852 29.6301 67.7943 29.5357 67.991 29.6931C68.3686 29.992 68.8642 30.4483 68.8642 30.8337C68.8642 31.2271 68.3607 31.6518 67.9988 31.9901C67.8572 32.1238 67.519 32.0452 67.2672 32.0452C58.3308 32.0452 49.3943 32.0452 40.4578 32.0452C40.269 32.0452 40.0802 32.0609 39.8914 32.0373C39.1991 31.9429 38.8137 31.5339 38.798 30.8495C38.7901 30.1493 39.207 29.756 39.8836 29.6537C40.1589 29.6144 40.4499 29.638 40.7253 29.638C45.1227 29.6301 49.5123 29.6301 53.9019 29.6301Z" />
			<path d="M47.8447 36.9775C50.3227 36.9775 52.8007 36.9775 55.2787 36.9775C56.2856 36.9775 56.8599 37.426 56.8599 38.1733C56.8599 38.9049 56.2542 39.3847 55.2708 39.3926C50.2834 39.4005 45.296 39.4005 40.3164 39.3926C39.3173 39.3926 38.7745 38.9127 38.806 38.1261C38.8374 37.3709 39.3409 36.9854 40.3243 36.9775C42.8258 36.9775 45.3353 36.9775 47.8447 36.9775Z" />
		</svg>
	);
};
