import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { HardwareApprover, HardwareApproverObject } from "types";
import { fetchHardwareApprovers, updateHardwareApprovers } from "actions/hardwareApproversActions";

const initialState = {
	approvers: {
		global: [] as HardwareApprover[],
		manager: [] as HardwareApprover[],
	} as HardwareApproverObject,
	isLoading: true as boolean,
	isFetching: false as boolean,
};

const hardwareApproverSlice = createSlice({
	name: "hardwarePage",
	initialState: initialState,
	extraReducers: (builder) => {
		builder
			.addCase(fetchHardwareApprovers.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchHardwareApprovers.fulfilled, (state, { payload }) => {
				state.approvers = payload;
				state.isFetching = false;
				state.isLoading = false;
			})
			.addCase(fetchHardwareApprovers.rejected, (state) => {
				state.approvers = initialState.approvers;
				state.isFetching = false;
				state.isLoading = false;
			});

		builder
			.addCase(updateHardwareApprovers.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(updateHardwareApprovers.fulfilled, (state, { meta }) => {
				state.approvers = meta.arg.body as any as HardwareApproverObject;
				state.isFetching = false;
				state.isLoading = false;
			})
			.addCase(updateHardwareApprovers.rejected, (state) => {
				state.isFetching = false;
				state.isLoading = false;
			});
	},
	reducers: {},
});

export const selectHardwareApprovers = (state: RootState) => state.hardwareApprovers;

export default hardwareApproverSlice.reducer;
