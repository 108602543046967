import { Grid, Icon, Skeleton, Typography } from "@mui/material";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import colors from "styles/utilities/_colors.scss";
import styles from "./CostTypeCard.module.scss";
import clsx from "clsx";
import _ from "lodash";
import { useAppNavigate } from "hooks/useAppNavigate";
import UpdateIcon from "@mui/icons-material/Update";
import { ReactComponent as UpArrow } from "assets/cost/UpArrow.svg";
import { ReactComponent as DownArrow } from "assets/cost/DownArrow.svg";
import { AnomaliesDialog } from "components/CostManagement/AnomaliesDialog";
import { Anomalies } from "types";
import { useEffect, useRef, useState } from "react";
import { useResize } from "hooks/hooks";
import { IronstoneDiscount } from "components/Common/IronstoneDiscount";

interface CostTypeCardInputProps {
	isLoading?: boolean;
	title: string;
	totalCurrentPeriod: number;
	totalDiscountAmount?: number;
	totalPreviousPeriod?: number;
	icon: JSX.Element;
	period?: JSX.Element;
	backgroundColor: string;
	size?: "small" | "medium" | "large";
	type?: "cost" | "anomalies";
	navigationPath?: string;
	navigationTitle?: string;
	secondaryTitle?: string;
	periodLabel?: string;
	anomalies?: Anomalies;
	iconBorder?: boolean;
	hoverDescription?: string;
}

const CostTypeCard = ({
	type = "cost",
	isLoading = false,
	title,
	totalCurrentPeriod,
	totalDiscountAmount,
	totalPreviousPeriod,
	icon,
	period,
	backgroundColor,
	size = "small",
	navigationPath,
	navigationTitle,
	secondaryTitle,
	periodLabel,
	iconBorder,
	hoverDescription,
}: CostTypeCardInputProps): JSX.Element => {
	const { navigateToPage } = useAppNavigate();
	const cardRef = useRef<HTMLDivElement>(null);
	const { width } = useResize(cardRef, 10);
	const [cardStyle, setCardStyle] = useState<string>("container");
	const [isDetailedAnomaliesDialogOpen, setIsDetailedAnomaliesDialogOpen] = useState(false);

	const getCostTypeCardStyle = (size: string, width: number) => {
		if (size === "medium" && width > 360) return "container";
		if (size === "medium" && width <= 360) return "containerSmall";
		return "container";
	};

	useEffect(() => {
		const style = getCostTypeCardStyle(size, width);
		if (cardRef.current && cardStyle !== style) {
			setCardStyle(style);
		}
	}, [cardRef, width, size, cardStyle]);

	// If no previous period is provided, the cost trend will not be shown, as it's not relevant for those cards
	// If the totalCurrentPeriod below 1, the percentage trend will not be shown
	const shouldCalculateCostTrend = !!totalPreviousPeriod && totalCurrentPeriod > 1;

	const diff = shouldCalculateCostTrend
		? _.round(((totalCurrentPeriod - totalPreviousPeriod) / totalPreviousPeriod) * 100, 1)
		: 0;

	const shouldShowCostTrend = diff !== 0;

	const isAlerted = totalCurrentPeriod > 0 && type === "anomalies";
	const showDiscount = Number(totalDiscountAmount ?? 0) > 0 && width > 500;

	const borderStyle = iconBorder ? styles[`icon${isAlerted ? "Alerted" : "Regular"}Border`] : "";

	if (size === "medium") {
		return (
			<Grid
				container
				className={clsx(styles[cardStyle], {
					[styles.selectable]: type === "anomalies" && !isLoading,
				})}
				p={3}
				ref={cardRef}
				onClick={() => {
					type === "anomalies" &&
						!isLoading &&
						!isDetailedAnomaliesDialogOpen &&
						setIsDetailedAnomaliesDialogOpen(!isDetailedAnomaliesDialogOpen);
				}}
			>
				<Grid item xs={12} className={clsx({ [styles.marginBottom]: !secondaryTitle })}>
					<Typography variant="body1" fontWeight={600}>
						{title.toUpperCase()}
					</Typography>
					{period}
				</Grid>
				<Grid item xs={12} mb={0.5}>
					{secondaryTitle ? (
						<Typography variant="caption" className={styles.secondaryTitle}>
							{secondaryTitle}
						</Typography>
					) : (
						<div className={styles.dummySpacingElement}></div>
					)}
				</Grid>
				<Grid
					item
					xs={3}
					container
					justifyContent="center"
					alignItems="center"
					sx={{ backgroundColor }}
					className={clsx(styles.iconWrapperMedium, borderStyle)}
				>
					<Icon className={clsx(styles.verticalAlign, styles.bigIcon)}>{icon}</Icon>
				</Grid>
				<Grid item xs>
					{isLoading ? (
						<Grid container direction="column" ml={2}>
							<Grid container columnGap={3}>
								<Skeleton variant="text" width={150} height={40} />
								<Skeleton variant="text" width={70} height={40} />
							</Grid>
							<Skeleton variant="text" width={50} height={20} />
						</Grid>
					) : (
						<Grid container direction="column" pl={2}>
							<Grid container alignItems="flex-end">
								<MainNumber
									isLoading={isLoading}
									showAsNok={type !== "anomalies"}
									totalCurrentPeriod={totalCurrentPeriod}
									totalDiscountAmount={Number(totalDiscountAmount ?? 0)}
								/>
								{type === "anomalies" && (
									<Typography variant="body1" ml={1}>
										anomalies detected
									</Typography>
								)}
								{type === "cost" && shouldShowCostTrend && (
									<Grid item className={styles.costTrend} ml={2}>
										<Typography variant="h3" color={colors.success}>
											{diff <= 0 ? (
												<DownArrow className={styles.bigExpandableIcon} />
											) : (
												<UpArrow className={styles.bigExpandableIcon} />
											)}
										</Typography>
										<Typography
											variant="body1"
											color={diff <= 0 ? colors.success : colors.error}
											ml={0.5}
											mt={0.5}
											noWrap
										>
											{Math.abs(diff)} %
										</Typography>
									</Grid>
								)}
							</Grid>
							{type === "cost" && <Typography>ex. vat</Typography>}
						</Grid>
					)}
					{type === "anomalies" && (
						<Grid ml={2} pt={0.2}>
							<AnomaliesDialog
								isDetailedAnomaliesDialogOpen={isDetailedAnomaliesDialogOpen}
								setIsDetailedAnomaliesDialogOpen={setIsDetailedAnomaliesDialogOpen}
							/>
						</Grid>
					)}
				</Grid>
				{periodLabel && (
					<span className={styles.periodLabel}>
						{
							<>
								<UpdateIcon className={styles.periodLabelIcon} />
								{periodLabel}
							</>
						}
					</span>
				)}
				<IronstoneDiscount
					title={title}
					showDiscount={showDiscount}
					hoverDescription={hoverDescription ?? ""}
				/>
			</Grid>
		);
	}

	return (
		<Grid
			container
			className={clsx(styles.container, styles.selectable)}
			p={3}
			onClick={() => {
				navigateToPage(navigationPath ?? "/cost");
			}}
		>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
					<Typography variant="body1" className={styles.fontWeight400} mb={1.5}>
						{title}
					</Typography>
					<Grid
						item
						xs={1.5}
						py={0.5}
						container
						justifyContent="center"
						alignItems="center"
						sx={{ backgroundColor }}
						className={styles.iconWrapper}
						mb={1.5}
					>
						<Icon className={styles.verticalAlign}>{icon}</Icon>
					</Grid>
				</Grid>

				{isLoading ? (
					<Skeleton variant="text" width={80} height={20} />
				) : (
					<Typography variant="body1" className={styles.fontWeight600}>
						{formatCostString("NOK ", totalCurrentPeriod, "")}
					</Typography>
				)}
			</Grid>

			<Grid item xs={12}>
				{_.isNumber(totalCurrentPeriod) && _.isNumber(totalPreviousPeriod) ? (
					<Grid container alignItems="center">
						{shouldShowCostTrend && (
							<>
								{diff <= 0 ? (
									<DownArrow className={styles.smallExpandableIcon} />
								) : (
									<UpArrow className={styles.smallExpandableIcon} />
								)}
								{shouldShowCostTrend && (
									<Typography
										variant="caption"
										color={diff <= 0 ? colors.success : colors.error}
										ml={0.5}
									>
										{Math.abs(diff)} %
									</Typography>
								)}
								<Typography variant="caption" ml={0.5} color="primary">
									from last period
								</Typography>
							</>
						)}
					</Grid>
				) : isLoading ? (
					<Skeleton variant="text" width={150} height={20} />
				) : (
					<Typography variant="caption">Cost from last period not available</Typography>
				)}
			</Grid>
			{navigationPath && (
				<Grid item xs={12}>
					<Typography variant="body1" className={styles.navigation}>
						Go to {navigationTitle || `${title} cost`}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

const MainNumber = ({
	isLoading,
	totalCurrentPeriod,
	totalDiscountAmount,
	showAsNok,
}: {
	isLoading: boolean;
	totalCurrentPeriod: number;
	totalDiscountAmount: number;
	showAsNok: boolean;
}) => {
	if (isLoading) {
		return <Skeleton variant="text" width={150} height={40} />;
	}

	const cost = (
		<Typography fontWeight={600} variant="h1">
			{formatCostString(`${showAsNok ? "NOK " : ""}`, totalCurrentPeriod, "")}
		</Typography>
	);

	return totalDiscountAmount > 0 ? (
		<Grid item>
			<Typography variant="body1" className={styles.discountAmount}>
				{formatCostString("NOK ", totalCurrentPeriod + totalDiscountAmount, "")}
			</Typography>
			{cost}
		</Grid>
	) : (
		cost
	);
};

export { CostTypeCard };
