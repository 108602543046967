import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchRequesters } from "actions/requesterActions";
import { RootState } from "store";
import { Requester } from "types";

const requestersAdapter = createEntityAdapter<Requester>();

const requestersSlice = createSlice({
	name: "requesters",
	initialState: requestersAdapter.getInitialState({ isLoading: true, isFetching: false }),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchRequesters.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchRequesters.fulfilled, (state, { payload }) => {
				requestersAdapter.upsertMany(state, payload);
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

export const selectRequesters = (state: RootState) => state.requesters;

export const requestersSelectors = requestersAdapter.getSelectors<RootState>(
	(state) => state.requesters,
);

export default requestersSlice.reducer;
