import { Grid, Typography } from "@mui/material";

import styles from "./SignInActivity.module.scss";
import { SEARCH_NO_MATCH } from "utilities/constants/constants";
import dayjs from "dayjs";
import { UserActivityType } from "utilities/constants/enums";

interface SignInActivityProps {
	lastSignInDateTime?: string;
	signInActivityStatus: UserActivityType;
}

const SignInActivity = ({ lastSignInDateTime, signInActivityStatus }: SignInActivityProps) => {
	let appliedStyle = styles.disabledOrNeverSignedIn;
	let text = "";
	if (lastSignInDateTime === SEARCH_NO_MATCH) {
		appliedStyle = styles.hidden;
	} else if (signInActivityStatus === UserActivityType.NewUser) {
		appliedStyle = styles.active;
		text = "New user";
	} else if (signInActivityStatus === UserActivityType.AccountDisabled) {
		appliedStyle = styles.disabledOrNeverSignedIn;
		text = "Account disabled";
	} else if (signInActivityStatus === UserActivityType.NeverSignedIn) {
		appliedStyle = styles.disabledOrNeverSignedIn;
		text = "Never signed in";
	} else if (signInActivityStatus === UserActivityType.Inactive) {
		appliedStyle = styles.inactive;
		text = dayjs(lastSignInDateTime).fromNow();
	} else {
		appliedStyle = styles.active;
		text = dayjs(lastSignInDateTime).fromNow();
	}

	return (
		<Grid container className={styles.signInActivityCell}>
			<Typography variant="description" className={appliedStyle}>
				{text}
			</Typography>
		</Grid>
	);
};

export { SignInActivity };
