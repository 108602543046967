import { Divider, Grid, Typography } from "@mui/material";
import { fetchHardwareApprovers } from "actions/hardwareApproversActions";
import { selectHardwareApprovers } from "features/hardware/hardwareApprovers";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { HardwareApprover, HardwareApproverObject } from "types";
import { useEffect } from "react";
import { fetchManagerStructure } from "actions/customerActions";
import { selectCustomerDetails, selectManagerStructure } from "features/customer";
import { NoManagerWarning } from "./NoManagerWarning";
import { ApproverType } from "utilities/constants/enums";
import { ApproverRow } from "./ApproverRow/ApproverRow";

import styles from "./HardwareApprovers.module.scss";

interface HardwareApproversProps {
	isSaving: boolean;
	selectedHardwareApprovers: HardwareApproverObject;
	setSelectedHardwareApprovers: (value: HardwareApproverObject) => void;
	setHardwareApprovesEqualOriginal: (value: boolean) => void;
}

export const HardwareApprovers = ({
	isSaving,
	selectedHardwareApprovers,
	setSelectedHardwareApprovers,
	setHardwareApprovesEqualOriginal,
}: HardwareApproversProps) => {
	const approversState = useAppSelector(selectHardwareApprovers);
	const managerStructure = useAppSelector(selectManagerStructure);
	useApiOnce(fetchHardwareApprovers, approversState);
	useApiOnce(fetchManagerStructure, managerStructure);
	const approvers = approversState.approvers;

	useEffect(() => {
		// If we're loading or saving, we don't need to calculate if the selected approvers are equal to the original
		if (approversState.isLoading || managerStructure.isLoading || isSaving) return;
		const originalGlobalIds = approvers.global.map((approver) => approver.id);
		const originalManagerIds = approvers.manager.map((approver) => approver.id);

		const selectedGlobalIds = selectedHardwareApprovers.global.map((approver) => approver.id);
		const selectedManagerIds = selectedHardwareApprovers.manager.map((approver) => approver.id);

		const globalEqual =
			originalGlobalIds.length === selectedGlobalIds.length &&
			originalGlobalIds.every((id) => selectedGlobalIds.includes(id));
		const managerEqual =
			originalManagerIds.length === selectedManagerIds.length &&
			originalManagerIds.every((id) => selectedManagerIds.includes(id));

		setHardwareApprovesEqualOriginal(globalEqual && managerEqual);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [approvers, selectedHardwareApprovers]);

	useEffect(() => {
		// If we're saving, we shouldn't update the selected approvers
		if (isSaving) return;
		const managerApprovers = approvers.manager.map((approver) => {
			const numIndirectManagerOf = managerStructure.managers[approver.id]?.indirect;
			return {
				...approver,
				approverFor: numIndirectManagerOf ? numIndirectManagerOf : 0,
			};
		});
		setSelectedHardwareApprovers({
			global: approvers.global,
			manager: managerApprovers,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [approversState]);

	const handleSetSelectedApprovers = (type: ApproverType, approvers: HardwareApprover[]) => {
		const approverTypeKey = type.toLowerCase() as keyof HardwareApproverObject;
		// Clicks on endAdornment (svg) should trigger a clear of the selected approvers
		const isSvgClick = !approvers.length;
		if (isSvgClick) {
			setSelectedHardwareApprovers({
				...selectedHardwareApprovers,
				[approverTypeKey]: [],
			});
			return;
		}

		setSelectedHardwareApprovers({
			...selectedHardwareApprovers,
			[approverTypeKey]: approvers,
		});
	};

	const customerInfo = useAppSelector(selectCustomerDetails);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="h3" fontWeight={500} mb={1}>
					Hardware Approvers
				</Typography>
				<Typography variant="body1">
					Purchase requests from users will be sent to selected hardware approvers, who
					can accept or deny the request.
				</Typography>
			</Grid>
			<Grid container mt={2}>
				<Grid item className={styles.header}>
					<Typography variant="body1" className={styles.approverType}>
						Approver type
					</Typography>
					<Typography variant="body1" className={styles.selectApprovers}>
						Approvers
					</Typography>
					<Typography variant="body1" className={styles.approverFor}>
						Approver for
					</Typography>
				</Grid>
				<ApproverRow
					isLoading={approversState.isLoading || managerStructure.isLoading}
					selectedApprovers={selectedHardwareApprovers}
					approverType={ApproverType.Global}
					handleSetSelectedApprovers={handleSetSelectedApprovers}
					disabled={!customerInfo?.hasKomplettRelationship}
				/>
				<Grid item xs={12}>
					<Divider className={styles.divider} />
				</Grid>
				<ApproverRow
					isLoading={approversState.isLoading || managerStructure.isLoading}
					selectedApprovers={selectedHardwareApprovers}
					approverType={ApproverType.Manager}
					handleSetSelectedApprovers={handleSetSelectedApprovers}
					disabled={!customerInfo?.hasKomplettRelationship}
				/>
			</Grid>
			<Grid container mt={2}>
				<NoManagerWarning selectedApprovers={selectedHardwareApprovers} />
			</Grid>
		</Grid>
	);
};
