import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	LicenseAssignmentResponse,
	LicenseGroup,
	LicenseRemovalResponse,
	SubscribedSku,
	SubscriptionVariant,
	SubscriptionVariantGroup,
} from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet, apiPatch, apiPost } from "utilities/api";

export const fetchLicenseGroups = createAsyncThunk(
	"licenses/fetchLicenseGroups",
	apiGet<LicenseGroup[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_LICENSE_GROUPS(),
		[SCOPES.GRAPH_READ],
	),
);

export const adjustLicenseSubscriptionQuantities = createAsyncThunk(
	"licenses/adjustLicenseSubscriptionQuantities",
	apiPatch(
		(authConfig) =>
			authConfig.PARTNER_CENTER_API_ENDPOINTS.ADJUST_LICENSE_SUBSCRIPTION_QUANTITY(),
		[SCOPES.PARTNERCENTER_WRITE],
	),
);

export const reprocessLicenseAssignment = createAsyncThunk(
	"licenses/reprocessLicenseAssignment",
	apiPost<{ reprocessedUserId: string; reprocessedUserName: string }>(
		(authConfig, { id }) => authConfig.MS_GRAPH_API_ENDPOINTS.REPROCESS_LICENSE_ASSIGNMENT(id),
		[SCOPES.GRAPH_WRITE],
	),
);

export const fetchLicenseSubscriptions = createAsyncThunk(
	"licenses/fetchLicenseSubscriptions",
	apiGet<SubscriptionVariantGroup[]>(
		(authConfig) => authConfig.PARTNER_CENTER_API_ENDPOINTS.GET_SUBSCRIPTIONS(),
		[SCOPES.PARTNERCENTER_READ],
	),
);

export const fetchLicenseSubscription = createAsyncThunk(
	"licenses/fetchLicenseSubscription",
	apiGet<SubscriptionVariant>(
		(authConfig, { subscriptionId }) =>
			authConfig.PARTNER_CENTER_API_ENDPOINTS.GET_SUBSCRIPTION(subscriptionId),
		[SCOPES.PARTNERCENTER_READ],
	),
);

export const fetchSubscribedSkus = createAsyncThunk(
	"licenses/fetchSubscribedSkus", // subscribed skus is microsoft's term for license subscriptions in msgraph api
	apiGet<SubscribedSku[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_SUBSCRIBED_SKUS(),
		[SCOPES.GRAPH_READ],
	),
);

export const assignLicenses = createAsyncThunk(
	"licenses/assignLicenses",
	apiPost<LicenseAssignmentResponse>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.ASSIGN_LICENSES(),
		[SCOPES.GRAPH_WRITE],
	),
);

export const removeLicenses = createAsyncThunk(
	"licenses/removeLicenses",
	apiPatch<LicenseRemovalResponse>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.REMOVE_LICENSES_FROM_USERS(),
		[SCOPES.GRAPH_WRITE],
	),
);
