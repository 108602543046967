import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	TableContainer,
	Skeleton,
} from "@mui/material";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

import styles from "./ConsultancyCostTable.module.scss";
import dayjs from "dayjs";
import { asHoursAndMinutes } from "utilities/dates/dates";
import { removeBetweenBrackets } from "utilities/stringFormattingUtility";
import { ConsultancyCostEntries } from "types";
import { HourRateLabel } from "./HourRateLabel/HourRateLabel";
import SummaryTableFooter from "components/Common/SummaryTableFooter/SummaryTableFooter";
import { IRONSTONE_SUPPORT_TICKETS_URL } from "utilities/constants/constants";
import { ExternalLink } from "components/Common/Links";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import clsx from "clsx";

interface ConsultancyCostTableProps {
	isLoading: boolean;
	filtersAreDefault: boolean;
	consultancyCostEntries: ConsultancyCostEntries[];
	selectedRangeDisplay: string;
	onResetFilters: () => void;
}

const ConsultancyCostTable = ({
	isLoading,
	filtersAreDefault,
	consultancyCostEntries,
	selectedRangeDisplay,
	onResetFilters,
}: ConsultancyCostTableProps) => {
	const shouldShowTableFooter = !filtersAreDefault;
	const selectedEntriesCost = consultancyCostEntries.reduce(
		(acc, entry) => acc + (entry.billable ? entry?.cost.exclVat : 0),
		0,
	);

	const selectedEntriesDuration = consultancyCostEntries.reduce(
		(acc, entry) => acc + entry?.durationInMinutes,
		0,
	);

	const tableHeadWidths = {
		project: "10%",
		date: "10%",
		ticket: "20%",
		consultant: "10%",
		costTerms: "10%",
		hourlyRate: "10%",
		duration: "10%",
		cost: "10%",
	};

	return (
		<TableContainer className={styles.tableContainer}>
			<Table>
				<TableHead className={styles.tableHead}>
					<TableRow>
						<TableCell width={tableHeadWidths.project}>
							<Typography variant="body1" fontWeight={500}>
								Project
							</Typography>
						</TableCell>
						<TableCell width={tableHeadWidths.date}>
							<Typography variant="body1" fontWeight={500}>
								Date
							</Typography>
						</TableCell>
						<TableCell width={tableHeadWidths.ticket}>
							<Typography variant="body1" fontWeight={500}>
								Description
							</Typography>
						</TableCell>
						<TableCell width={tableHeadWidths.consultant}>
							<Typography variant="body1" fontWeight={500}>
								Consultant
							</Typography>
						</TableCell>
						<TableCell width={tableHeadWidths.costTerms}>
							<Typography variant="body1" fontWeight={500}>
								Cost terms
							</Typography>
						</TableCell>
						<TableCell
							className={styles.costTypeCell}
							width={tableHeadWidths.hourlyRate}
						>
							<Typography variant="body1" fontWeight={500} noWrap>
								Hourly rate
							</Typography>
						</TableCell>
						<TableCell className={styles.costTypeCell} width={tableHeadWidths.duration}>
							<Typography variant="body1" fontWeight={500}>
								Duration
							</Typography>
						</TableCell>
						<TableCell className={styles.costTypeCell} width={tableHeadWidths.cost}>
							<Typography variant="body1" fontWeight={500} noWrap>
								Total cost
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{!isLoading && consultancyCostEntries?.length === 0 && (
						<TableRow classes={{ root: styles.rowStyle }}>
							<TableCell colSpan={8}>
								<Typography variant="body1" align="center">
									No consultancy cost found for the selected period
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{!isLoading
						? consultancyCostEntries.map((entry, index) => {
								return (
									<TableRow key={index} classes={{ root: styles.rowStyle }}>
										<TableCell>
											<TruncatableTypography
												maxCharLength={30}
												tooltipPlacement="top"
											>
												{entry.projectName}
											</TruncatableTypography>
										</TableCell>
										<TableCell>
											<Typography variant="body1">
												{dayjs(entry.day).format("DD.MM.YYYY")}
											</Typography>
										</TableCell>
										<TableCell className={styles.ticketCell}>
											<>
												{entry.ticketId && (
													<ExternalLink
														textDecoration="underline"
														href={IRONSTONE_SUPPORT_TICKETS_URL}
													>
														<TruncatableTypography
															children={removeBetweenBrackets(
																`Ticket ${entry.ticketId}: ${entry.ticketSubject}`,
															)}
															tooltipPlacement="top"
															tooltipTitle="Ticket subject"
															maxCharLength={33}
															className={styles.linkText}
														/>
													</ExternalLink>
												)}
												<Typography variant="body1">
													{entry.note}
												</Typography>
											</>
										</TableCell>
										<TableCell>
											<Typography variant="body1">
												{entry.consultantName}
											</Typography>
										</TableCell>
										<TableCell>
											<HourRateLabel label={entry.labels[0]?.childLabel} />
										</TableCell>
										<TableCell className={styles.costTypeCell}>
											<Typography variant="body1">
												{formatCostString(
													"",
													entry.hourRate.exclVat,
													" ,-",
												)}
											</Typography>
										</TableCell>
										<TableCell className={styles.costTypeCell}>
											<Typography variant="body1">
												{asHoursAndMinutes(entry.durationInMinutes)}
											</Typography>
										</TableCell>
										<TableCell className={styles.costTypeCell}>
											<Typography
												variant="body1"
												className={clsx({
													[styles.strikethrough]: !entry.billable,
												})}
											>
												{formatCostString("", entry.cost.exclVat, " ,-")}
											</Typography>
											{!entry.billable && (
												<Typography
													variant="body1"
													className={styles.costText}
												>
													{formatCostString("", 0, " ,-")}
												</Typography>
											)}
										</TableCell>
									</TableRow>
								);
						  })
						: new Array(10).fill(0).map((_: any, index: number) => (
								<TableRow key={index} className={styles.rowStyle}>
									{Object.entries(tableHeadWidths).map(([key, value]) => (
										<TableCell key={key}>
											<Skeleton variant="text" height={50} />
										</TableCell>
									))}
								</TableRow>
						  ))}
				</TableBody>
				<SummaryTableFooter
					padding={true}
					onResetFilters={onResetFilters}
					summaryCells={[
						{
							label: "Date interval",
							value: selectedRangeDisplay,
						},
						{
							label: "Total duration",
							value: asHoursAndMinutes(selectedEntriesDuration),
						},
						{
							label: "Total cost",
							value: formatCostString("", selectedEntriesCost, " ,-"),
						},
					]}
					visible={shouldShowTableFooter}
				/>
			</Table>
		</TableContainer>
	);
};

export { ConsultancyCostTable };
