import { UnassignedHeader } from "components/Recommendations/LicenseRecommendation/ConfigureLicenseDrawer/LicenseDrawerContent/RemoveLicensesContent/UnassignedLicensesContent/UnassignedHeader";
import { UnassignedTableFooter } from "components/Recommendations/LicenseRecommendation/ConfigureLicenseDrawer/LicenseDrawerContent/RemoveLicensesContent/UnassignedLicensesContent/UnassignedTableFooter";
import {
	LicenseAction,
	RemovalQuantity,
	SubscriptionVariant,
	SubscriptionVariantGroup,
} from "types";
import {
	LicenseActionOperationType,
	LicenseActionStatus,
	LicenseActionTargetType,
} from "utilities/constants/enums";
import { useAppSelector } from "hooks/hooks";
import {
	licenseActionsSelector,
	selectNumLicensesScheduledForRemoval,
	selectRecipientMailState,
} from "features/scheduledActions";
import { useMemo, useState } from "react";
import { LicenseRemovalTable } from "components/Common/LicenseRemovalTable";
import { Grid, Typography } from "@mui/material";
import { selectActiveLicenseCompleteDetails } from "features/licenses/licenses";
import { licensePricesSelectors, selectLicensePrices } from "features/licenses/licensePrices";
import { AgreeToTerms } from "components/Common/AgreeToTerms";
import { TERMS_AND_CONDITIONS_TYPES } from "components/Common/Dialogs/TermsAndConditionsDialog/TermsAndConditions";
import { PrimaryButton } from "components/Common/Buttons/Button";
import EmailInput from "components/Common/EmailInput/EmailInput";

import styles from "./RemoveLicenses.module.scss";

interface RemoveLicensesProps {
	licenseSubscriptionVariantGroup: SubscriptionVariantGroup;
	handlePrimaryAction: (
		variant: RemovalQuantity[],
		operationType: LicenseActionOperationType,
	) => void;
}

const RemoveLicenses = ({
	licenseSubscriptionVariantGroup,
	handlePrimaryAction,
}: RemoveLicensesProps) => {
	const completeLicenseDetails = useAppSelector(selectActiveLicenseCompleteDetails);
	const licenseSubscriptionVariants =
		licenseSubscriptionVariantGroup.variants ?? ([] as SubscriptionVariant[]);
	const [agreeToTerms, setAgreeToTerms] = useState(false);
	const licenseActions = (useAppSelector(licenseActionsSelector.selectAll) ??
		[]) as LicenseAction[];
	const licensePrices = useAppSelector(licensePricesSelectors.selectEntities) ?? {};

	const scheduledLicenseActions = licenseActions.filter(
		(action) =>
			action.TargetType === LicenseActionTargetType.Subscription &&
			action.Status === LicenseActionStatus.Scheduled,
	);
	const subscriptionsPreparedForTable = licenseSubscriptionVariants.reduce((acc, curr) => {
		// Make sure to count the number of removeable licenses still on the subscription
		// aggregate to be used when calculating total available for removal

		acc[curr.subscriptionId] = {
			quantity: curr.quantity,
			removalQuantity: 0,
			termDuration: curr.termDuration,
			unitPriceAfterDiscount: licensePrices[curr.costIdentifier]?.UnitPriceAfterDiscount ?? 0,
			commitmentEndDate: curr.commitmentEndDate,
			autoRenewEnabled: curr.autoRenewEnabled,
			subscriptionId: curr.subscriptionId,
			provisioningId: curr.provisioningId,
			refundableQuantity: curr.refundableQuantity ?? [],
		};

		return acc;
	}, {} as Record<string, RemovalQuantity>);

	const [subscriptionIdsWithRemovalQuantities, setSubscriptionIdsWithRemovalQuantities] =
		useState<{ [key: string]: number }>(
			Object.entries(subscriptionsPreparedForTable).reduce(
				(acc, [id, { removalQuantity }]) => ({
					...acc,
					[id]: removalQuantity,
				}),
				{},
			),
		);

	const totalConfiguredRemovalQuantity = useMemo(
		() =>
			Object.values(subscriptionIdsWithRemovalQuantities).reduce(
				(acc, curr) => acc + curr,
				0,
			),
		[subscriptionIdsWithRemovalQuantities],
	);

	const savingsMadeBasedOnRemovalQuantities = useMemo(
		() =>
			Object.entries(subscriptionIdsWithRemovalQuantities).reduce(
				(acc, [id, removalQuantity]) => {
					const unitPrice = subscriptionsPreparedForTable[id].unitPriceAfterDiscount;
					return acc + unitPrice * removalQuantity;
				},
				0,
			),
		[subscriptionIdsWithRemovalQuantities, subscriptionsPreparedForTable],
	);

	const handleQuantityChange = (id: string, quantity: number) => {
		setSubscriptionIdsWithRemovalQuantities((prevState) => ({
			...prevState,
			[id]: quantity,
		}));
	};

	const [fakeIsRemovingLicenses, setFakeIsRemovingLicenses] = useState(false); // As we cannot currently await the primary action, we fake the loading of the button
	const onHandlePrimaryAction = () => {
		setFakeIsRemovingLicenses(true);
		const alteredVariants = Object.entries(subscriptionsPreparedForTable).filter(
			([id, variant]) => variant.removalQuantity !== subscriptionIdsWithRemovalQuantities[id],
		);

		const variantsWithAlterations = alteredVariants.map(([id, variant]) => {
			return {
				...variant,
				removalQuantity: -subscriptionIdsWithRemovalQuantities[id],
				subscriptionId: id,
			};
		});

		handlePrimaryAction(variantsWithAlterations, LicenseActionOperationType.AdjustLicenseCount);
		setTimeout(() => {
			setFakeIsRemovingLicenses(false);
		}, 10000);
	};

	const { isValid: isEnteredMailValid } = useAppSelector(selectRecipientMailState);

	const { isLoading: isLoadingPrices } = useAppSelector(selectLicensePrices);

	const subscriptionsPreparedForTableWithRemovalQuantities = Object.entries(
		subscriptionsPreparedForTable,
	).reduce(
		(acc, [id, variant]) => ({
			...acc,
			[id]: {
				...variant,
				removalQuantity: subscriptionIdsWithRemovalQuantities[id],
			},
		}),
		{},
	);

	const exceedsRemovalQuantity =
		totalConfiguredRemovalQuantity >
		completeLicenseDetails?.totalAvailableForRemovalAfterScheduledRemovals;

	const numScheduledForRemoval = useAppSelector(
		selectNumLicensesScheduledForRemoval(licenseSubscriptionVariantGroup.provisioningId),
	);

	return (
		<Grid container gap={4}>
			<UnassignedHeader
				availableForRemoval={
					completeLicenseDetails?.totalAvailableForRemovalAfterScheduledRemovals
				}
			/>
			{completeLicenseDetails?.totalAvailableForRemovalAfterScheduledRemovals > 0 ? (
				<>
					<Grid container>
						<LicenseRemovalTable
							{...{
								availableForRemoval:
									completeLicenseDetails?.totalAvailableForRemovalAfterScheduledRemovals, // numRemovalsScheduled is handled inside the table
								scheduledLicenseActions,
								subscriptionExpirationThreshold: 365, // 1 year expiration for this removal view
								subscriptionsWithRemovalQuantities:
									subscriptionsPreparedForTableWithRemovalQuantities,
								handleQuantityChange,
							}}
						/>
					</Grid>
					<UnassignedTableFooter
						availableForRemoval={
							completeLicenseDetails?.totalAvailableForRemovalAfterScheduledRemovals
						}
						totalConfiguredRemovalQuantity={totalConfiguredRemovalQuantity}
						totalSavingsBasedOnRemovalQuantity={savingsMadeBasedOnRemovalQuantities}
						exceedsUnassignedQuantity={exceedsRemovalQuantity}
						isLoadingPrices={isLoadingPrices}
					/>
					<Grid container item xs={6}>
						<EmailInput
							explanationText="Confirmation receipt on successful removal"
							required={false}
							className={styles.emailInput}
						/>
					</Grid>
					<Grid container item>
						<AgreeToTerms
							type={TERMS_AND_CONDITIONS_TYPES.LICENSE_REMOVAL}
							agreedToTerms={agreeToTerms}
							setAgreedToTerms={(e: boolean) => setAgreeToTerms(e)}
							isDisabled={
								totalConfiguredRemovalQuantity === 0 || exceedsRemovalQuantity
							}
							position="flex-start"
						/>
						<PrimaryButton
							text="Remove licenses"
							size="medium"
							action={onHandlePrimaryAction}
							variantStyle="contained"
							isDisabled={
								totalConfiguredRemovalQuantity === 0 ||
								!agreeToTerms ||
								!isEnteredMailValid ||
								exceedsRemovalQuantity
							}
							isLoading={fakeIsRemovingLicenses}
						/>
					</Grid>
				</>
			) : (
				<Grid container item xs={12} className={styles.noLicensesAvailableContainer}>
					<Typography variant="body1" fontWeight={500}>
						There are no licenses available for removal. This might be caused by one out
						of several factors:
						<br />
					</Typography>
					<ul>
						<li>
							Licenses already scheduled for removal <b>({numScheduledForRemoval})</b>
						</li>
						<li>No unassigned licenses</li>
					</ul>
				</Grid>
			)}
		</Grid>
	);
};

export { RemoveLicenses };
