import dayjs from "dayjs";
import { HoverTooltip } from "components/Common/Tooltips";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface AutorenewStatusInputProps {
	autoRenewEnabled: boolean;
	commitmentEndDate: string | Date;
}

export const AutorenewStatus = ({
	autoRenewEnabled,
	commitmentEndDate,
}: AutorenewStatusInputProps) => {
	return (
		<div>
			{autoRenewEnabled ? (
				<CheckCircleOutlineIcon color="success" />
			) : (
				<HoverTooltip
					title={"Auto renew disabled"}
					description={`Auto renew is disabled for this subscription, it will automatically expire on ${dayjs(
						commitmentEndDate,
					).format("DD.MM.YYYY")}`}
				>
					<HighlightOffIcon color="error" />
				</HoverTooltip>
			)}
		</div>
	);
};
