import { fetchSpecificPeriodsAllCosts } from "actions/costActions";
import { DownloadableDropdown } from "components/Common/DownloadableLink";
import dayjs from "dayjs";
import { useAuth } from "hooks/hooks";
import _ from "lodash";
import { useState } from "react";
import { TotalCost } from "types";
import { mergeCostData } from "utilities/totalCostUtils";

export const ExportCostDropdown = () => {
	const [isPreparingExport, setIsPreparingExport] = useState(false);

	const { dispatch, auth } = useAuth();
	const downloadOptions = [
		{ label: "Current month", value: "1" },
		{ label: "Last month", value: "2" },
		{ label: "6 months", value: "6" },
		{ label: "12 months", value: "12" },
	];
	const [selectedOption, setSelectedOption] = useState({
		value: downloadOptions[0].value,
		label: downloadOptions[0].label,
	});

	const getExportableData = async () => {
		setIsPreparingExport(true);
		let periods = [];
		if (selectedOption.value === "1") {
			periods = [dayjs().format("YYYY-MM")];
		} else if (selectedOption.value === "2") {
			periods = [dayjs().subtract(1, "month").format("YYYY-MM")];
		} else {
			periods = _.range(0, parseInt(selectedOption.value)).map((i) =>
				dayjs().subtract(i, "months").format("YYYY-MM"),
			);
		}

		const payloads: TotalCost[] = []; // Assuming TotalCost is the type of your payload

		for (const period of periods) {
			const response = await dispatch(
				fetchSpecificPeriodsAllCosts({ auth, params: { periods: [period] } }),
			);
			payloads.push(response.payload as TotalCost);
		}

		// After fetching all the data, merge them into one object
		// We fetch this data per period, because fetching them all at once might be too much data
		// for both the client and the server to handle
		const dataMapped = mergeCostData(payloads);
		setIsPreparingExport(false);
		return dataMapped;
	};

	return (
		<DownloadableDropdown
			isLoading={isPreparingExport}
			getData={getExportableData}
			options={downloadOptions}
			setSelectedOption={setSelectedOption}
			selectedOption={selectedOption}
		/>
	);
};
