import { Customer, CustomerDTO } from "types";

export const mapToViewModel = (
	folderPath: string,
	customerDataResponse: CustomerDTO,
): Customer => ({
	folderPath,
	internalCollaborationData: {
		approvers: customerDataResponse?.approvers?.internalCollaborationApprovers ?? [],
		creators: customerDataResponse?.creators?.internalCollaborationCreators ?? [],
		enabled: customerDataResponse?.workspaceSettings?.internalCollaborationEnabled ?? false,
	},
	externalCollaborationData: {
		approvers: customerDataResponse?.approvers?.externalCollaborationApprovers ?? [],
		creators: customerDataResponse?.creators?.externalCollaborationCreators ?? [],
		enabled: customerDataResponse?.workspaceSettings?.externalCollaborationEnabled ?? false,
	},

	internalProjectData: {
		approvers: customerDataResponse?.approvers?.internalProjectApprovers ?? [],
		creators: customerDataResponse?.creators?.internalProjectCreators ?? [],
		enabled: customerDataResponse?.workspaceSettings?.internalProjectEnabled ?? false,
	},

	externalProjectData: {
		approvers: customerDataResponse?.approvers?.externalProjectApprovers ?? [],
		creators: customerDataResponse?.creators?.externalProjectCreators ?? [],
		enabled: customerDataResponse?.workspaceSettings?.externalProjectEnabled ?? [],
	},
	workspaceSettings: customerDataResponse?.workspaceSettings,
});

export const mapToStorageModel = (customerData: Customer): CustomerDTO => ({
	approvers: {
		internalCollaborationApprovers: customerData.internalCollaborationData.approvers,
		externalCollaborationApprovers: customerData.externalCollaborationData.approvers,
		internalProjectApprovers: customerData.internalProjectData.approvers,
		externalProjectApprovers: customerData.externalProjectData.approvers,
	},
	creators: {
		internalCollaborationCreators: customerData.internalCollaborationData.creators,
		externalCollaborationCreators: customerData.externalCollaborationData.creators,
		internalProjectCreators: customerData.internalProjectData.creators,
		externalProjectCreators: customerData.externalProjectData.creators,
	},
	workspaceSettings: customerData.workspaceSettings,
});
