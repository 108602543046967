import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import styles from "./SimpleTableContainer.module.scss";

const SimpleTableContainer = ({ inputData, header1, header2 }) => {
	return (
		<TableContainer className={styles.tableContainer}>
			<Table>
				<TableHead className={styles.tableHead}>
					<TableRow>
						<TableCell>{header1}</TableCell>
						<TableCell>{header2}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{inputData.map((item, key) => (
						<TableRow key={key}>
							<TableCell>{item.name}</TableCell>
							<TableCell>
								{typeof item.id == "boolean"
									? item.id === true
										? "Yes"
										: "No"
									: item.id}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export { SimpleTableContainer };
