import config from "authConfig";

export const goToEntitledTenant = (homeTenantId: string) => {
	sessionStorage.setItem("entitlement", homeTenantId);
	clearAccessTokensOfPreviousTenant();
	window.location.reload();
};

export const clearAccessTokensOfPreviousTenant = () => {
	Object.entries(sessionStorage).forEach(([key, valueAsString]) => {
		try {
			const value = JSON.parse(valueAsString);
			const keyIsAccessToken = value.credentialType?.toUpperCase().includes("ACCESSTOKEN");
			const isIronstonePortalToken = value.target?.includes(config.APP_ID);

			if (keyIsAccessToken && isIronstonePortalToken) {
				localStorage.removeItem(key);
			}
		} catch (error) {
			// Ignore errors when parsing JSON
		}
	});
};
