import {
	Autocomplete,
	Grid,
	InputAdornment,
	TextField,
	Typography,
	createFilterOptions,
} from "@mui/material";
import { selectUserDrawerState } from "features/user";
import { selectManager, usersSelectors } from "features/users";
import { useAppSelector, useAuth } from "hooks/hooks";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";

import styles from "./UserPersonalDetails.module.scss";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";
import { PrimaryButton, SecondaryButton } from "components/Common/Buttons/Button";
import { useState } from "react";
import { InputTextField } from "components/Common/InputTextField";
import { departmentsSelectors } from "features/departments";
import { FieldValues, FormProviderProps, UseFormReturn, useForm } from "react-hook-form";
import { User } from "types";
import { InputWrapper } from "components/Common/InputWrapper";
import { mailDomainsSelectors } from "features/mailDomains";
import { userFormValidation } from "utilities/userFormValidation";
import { setManager, updateUser } from "actions/userActions";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import { CountrySelector } from "components/Common/CountrySelector";
import useRole from "utilities/roleUtils/roleCheck";

export const UserPersonalDetails = () => {
	const { activeUserId } = useAppSelector(selectUserDrawerState);
	const [isEditMode, setIsEditMode] = useState(false);
	const [errorText, setErrorText] = useState("");
	const user = useAppSelector((state) => usersSelectors.selectById(state, activeUserId));
	const manager = useAppSelector((state) =>
		usersSelectors.selectById(state, user?.manager || ""),
	);
	const allUsers = useAppSelector(usersSelectors.selectAll);
	const { isLoading: isManagerOfThisUserLoading } = useAppSelector((state) =>
		selectManager(state, activeUserId),
	);

	const mailPrefix = user?.mail?.substring(0, user?.mail?.indexOf("@")) || "";
	const mailSuffix = user?.mail?.substring(user?.mail?.indexOf("@") + 1) || "";

	const fields = [
		{
			label: "First name",
			propertyName: "givenName",
			value: user?.givenName || "",
			validationProps: userFormValidation.givenName.validationProps,
		},
		{
			label: "Last name",
			propertyName: "surname",
			value: user?.surname || "",
			validationProps: userFormValidation.surname.validationProps,
		},
		{
			label: "Email",
			value: mailPrefix,
			propertyName: "mailPrefix",
			validationProps: userFormValidation.mailPrefix.getValidationProps(
				allUsers ?? [],
				mailSuffix,
				user?.userPrincipalName,
			),
		},
		{
			label: "Email domain",
			value: mailSuffix,
			propertyName: "mailSuffix",
			// Doesn't need a validator, as it's a dropdown
		},
		{
			label: "Private email",
			propertyName: "privateEmail",
			value: user?.privateEmail || "",
			validationProps: userFormValidation.privateEmail.validationProps,
		},
		{ propertyName: "DUMMY_SPACING_ELEMENT_1", width: 6 },
		{
			label: "Phone",
			propertyName: "mobilePhone",
			value: user?.mobilePhone || "",
			validationProps: userFormValidation.mobilePhone.getValidationProps(false),
		},
		{ propertyName: "DUMMY_SPACING_ELEMENT_2", width: 6 },
		{ label: "Department", propertyName: "department", value: user?.department || "" },
		{ propertyName: "DUMMY_SPACING_ELEMENT_3", width: 6 },
		{ label: "Manager", propertyName: "manager", value: manager || "" },
		{ propertyName: "DUMMY_SPACING_ELEMENT_4", width: 6 },
		{
			label: "Country",
			propertyName: "country",
			value: user?.country || "",
		},
		{ propertyName: "DUMMY_SPACING_ELEMENT_5", width: 6 },
		{
			label: "Street Address",
			propertyName: "streetAddress",
			value: user?.streetAddress || "",
			validationProps: userFormValidation.streetAddress.getValidationProps(false),
		},
		{
			label: "Postal code",
			propertyName: "postalCode",
			value: user?.postalCode || "",
			width: 2.875,
			validationProps: userFormValidation.postalCode.getValidationProps(false),
		},
		{
			label: "City",
			propertyName: "city",
			value: user?.city || "",
			width: 3,
			validationProps: userFormValidation.city.getValidationProps(false),
		},
	];

	const formProps = useForm<FieldValues>({
		mode: "onBlur",
		defaultValues: fields.reduce((acc, curr) => {
			acc[curr.propertyName] = curr.value;
			return acc;
		}, {} as FieldValues),
	});

	const { auth, dispatch } = useAuth();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const handleOnSave = async (allFields: FieldValues) => {
		const { mailPrefix, mailSuffix } = allFields;
		// Remove dummy spacing element, manager is handled separately
		const { DUMMY_SPACING_ELEMENT, manager, ...data } = allFields;
		data.mail = `${mailPrefix.toLowerCase()}@${mailSuffix}`;
		setIsSubmitting(true);
		const result = await dispatch(updateUser({ auth, id: activeUserId, body: data }));
		if (manager?.id) {
			await dispatch(setManager({ auth, id: activeUserId, body: { id: manager?.id } }));
		}

		if (result.meta.requestStatus === "fulfilled") {
			setIsEditMode(false);
			setErrorText("");
		} else {
			setErrorText((result as any)?.error?.message ?? "An error occurred");
		}

		setIsSubmitting(false);
	};

	const { isActionRole } = useRole();

	return (
		<Grid>
			<Grid container className={styles.topSection}>
				<SecondaryButton
					text={"Edit details"}
					variantStyle="outlined"
					action={() => setIsEditMode(!isEditMode)}
					size="small"
					icon={<CreateIcon className={styles.editIcon} />}
					isDisabled={!isActionRole}
					disabledDescription={"You do not have permission to edit user details."}
				/>
			</Grid>
			<Grid container className={styles.userInfoContainer}>
				{fields.map((value) => {
					if (!value.label) {
						return (
							<Grid
								key={value.propertyName}
								container
								className={styles.userInfoElement}
								item
								xs={value.width}
							/>
						);
					}

					return isEditMode ? (
						<EditUserInfoElement
							key={value.propertyName}
							label={value.label}
							value={value.value}
							propertyName={value.propertyName}
							validationProps={value.validationProps}
							width={value.width}
							formProps={formProps}
						/>
					) : (
						<UserInfoElement
							key={value.propertyName}
							label={value.label}
							value={
								value.label === "Manager" && isManagerOfThisUserLoading
									? "Loading..."
									: value.value
							}
							width={value.width}
						/>
					);
				})}
			</Grid>
			{isEditMode && (
				<PrimaryButton
					text={"Save"}
					action={formProps.handleSubmit(handleOnSave)}
					size="small"
					variantStyle="contained"
					marginTop={3}
					isLoading={isSubmitting}
				/>
			)}
			<Typography className={styles.errorMessageText}>{errorText}</Typography>
		</Grid>
	);
};

interface UserInfoElementProps {
	label: string;
	value: User | string;
	width?: number;
}

const UserInfoElement = ({ label, value, width = 6 }: UserInfoElementProps) => {
	const getDisplayValue = (value: User | string) => {
		if (typeof value === "string") {
			return value;
		} else {
			return value.displayName;
		}
	};

	return (
		<Grid container className={styles.userInfoElement} item xs={width}>
			<Grid item xs={11.5}>
				<Typography variant="body1" fontWeight={500}>
					{label}
				</Typography>
			</Grid>
			<Grid item xs={11.5}>
				{value ? (
					<TruncatableTypography variant="body1" maxCharLength={5 * width}>
						{getDisplayValue(value)}
					</TruncatableTypography>
				) : (
					<Typography variant="description" color="textSecondary">
						Not registered
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};

interface EditUserInfoElementProps {
	label: string;
	value: string | User;
	propertyName: string;
	formProps: UseFormReturn<FieldValues, any>;
	validationProps: any;
	width?: number;
}

const EditUserInfoElement = ({
	label,
	value,
	propertyName,
	formProps: {
		register,
		setValue,
		formState: { errors },
	},
	validationProps,
	width = 6,
}: EditUserInfoElementProps) => {
	if (label === "Department") {
		return (
			<Grid container item xs={width}>
				<Grid item xs={11.5}>
					<InputWrapper label={label} htmlFor={label.toLowerCase()} tooltipText="">
						<DepartmentDropdown value={value as string} register={register} />
					</InputWrapper>
				</Grid>
			</Grid>
		);
	} else if (label === "Manager") {
		return (
			<Grid container item xs={width}>
				<Grid item xs={11.5}>
					<InputWrapper label={label} htmlFor={label.toLowerCase()} tooltipText="">
						<ManagerDropdown value={value as User} setValue={setValue} />
					</InputWrapper>
				</Grid>
			</Grid>
		);
	} else if (label === "Email domain") {
		return (
			<Grid container item xs={width}>
				<Grid item xs={11.5}>
					<InputWrapper label={label} htmlFor={label.toLowerCase()} tooltipText="">
						<DomainDropdown value={value as string} register={register} />
					</InputWrapper>
				</Grid>
			</Grid>
		);
	} else if (label === "Country") {
		return (
			<Grid container item xs={width}>
				<Grid item xs={11.5} pr={3}>
					<InputWrapper label={label} htmlFor={label.toLowerCase()} tooltipText="">
						<CountrySelector value={value as string} register={register} />
					</InputWrapper>
				</Grid>
			</Grid>
		);
	} else if (label === "Email") {
		return (
			<Grid container item xs={6} className={styles.userInfoElement}>
				<Grid item xs={11.5}>
					<InputTextField
						classes={{ root: styles.errorText }}
						placeholder={label}
						label={label}
						error={!!errors[propertyName]}
						className={styles.input}
						size="small"
						helperText={userFormValidation.mailPrefix.getHelperText(errors)}
						{...register("mailPrefix", validationProps)}
					/>
				</Grid>
				<Grid item xs={0.5}>
					<Typography variant="h3" mt={4} ml={-1.8} fontWeight={500}>
						@
					</Typography>
				</Grid>
			</Grid>
		);
	} else {
		const key = propertyName as keyof FormProviderProps<FieldValues>["register"];
		return (
			<Grid container item xs={width} className={styles.userInfoElement}>
				<Grid item xs={11.5}>
					<InputTextField
						classes={{ root: styles.errorText }}
						placeholder={label}
						label={label}
						error={!!errors[propertyName]}
						className={styles.input}
						size="small"
						helperText={(userFormValidation[key] as any).getHelperText(errors)}
						{...register(propertyName, validationProps)}
					/>
				</Grid>
			</Grid>
		);
	}
};

const DomainDropdown = ({ value, register }: { value: string; register: any }) => {
	// We need to ensure that the current mail suffix is also included in the available mail domain dropdown.
	// To avoid any issue where the user is not able to get the same mail address as before.
	const availableMailDomains = [...useAppSelector(mailDomainsSelectors.selectAll)];
	if (!availableMailDomains.some((domain) => domain.name === value)) {
		availableMailDomains.push({ name: value, id: 9876543210 });
	}

	const [currentDomain, setCurrentDomain] = useState<any>(value);

	return (
		<Autocomplete
			options={availableMailDomains}
			value={currentDomain}
			onChange={(_, newValue: any) => {
				const nameValue = newValue?.name ?? "";
				setCurrentDomain(nameValue);
			}}
			isOptionEqualToValue={(option, value) => option.name === value}
			clearOnBlur
			disableClearable
			handleHomeEndKeys
			renderOption={(props, option) => {
				return <li {...props}>{option["name"] || ""}</li>;
			}}
			getOptionLabel={(option) => {
				// Value selected with enter, right from the input
				if (typeof option === "string") {
					return option || "";
				}
				// Regular option
				return option.name;
			}}
			renderInput={(params) => (
				<TextField
					className={styles.autcompleteInput}
					{...params}
					variant="outlined"
					placeholder="Search mail domain"
					InputProps={{
						style: {
							height: 40,
							alignContent: "center",
						},
						...params.InputProps,
						type: "search",
					}}
					{...register("mailSuffix")}
				/>
			)}
		/>
	);
};

const DepartmentDropdown = ({ value, register }: { value: string; register: any }) => {
	const availableDepartments = useAppSelector(departmentsSelectors.selectAll);
	const [currentDepartment, setCurrentDepartment] = useState(value);

	return (
		<Autocomplete
			options={availableDepartments}
			value={currentDepartment}
			onChange={(_, newValue: any) => {
				if (newValue?.name?.startsWith("Add")) {
					const inputValue = newValue?.inputValue ?? "";
					setCurrentDepartment(inputValue);
					return;
				}
				const nameValue = newValue?.name ?? "";
				setCurrentDepartment(nameValue);
			}}
			freeSolo
			disableClearable
			renderOption={(props, option) => {
				return <li {...props}>{option["name"] || ""}</li>;
			}}
			getOptionLabel={(option) => {
				// Value selected with enter, right from the input
				if (typeof option === "string") {
					return option || "";
				}
				// Add "x" option created dynamically
				if (option.inputValue) {
					return option.inputValue;
				}
				// Regular option
				return option.name;
			}}
			renderInput={(params) => (
				<TextField
					className={styles.autcompleteInput}
					{...params}
					variant="outlined"
					placeholder="Search departments"
					InputProps={{
						style: {
							height: 40,
							alignContent: "center",
						},
						...params.InputProps,
					}}
					{...register("department")}
				/>
			)}
			filterOptions={(options, params) => {
				const filter = createFilterOptions();
				const filtered = filter(options, params);

				const { inputValue } = params;
				// Suggest the creation of a new value
				const isExisting = options.some((option) => option.name.includes(inputValue));
				if (inputValue !== "" && !isExisting) {
					filtered.push({
						inputValue,
						name: `Add "${inputValue}"`,
					});
				}

				return filtered;
			}}
		/>
	);
};

const ManagerDropdown = ({ value, setValue }: { value: User; setValue: any }) => {
	const { activeUserId } = useAppSelector(selectUserDrawerState);
	const users = useAppSelector(usersSelectors.selectAll);
	const { isLoading: isManagerOfThisUserLoading } = useAppSelector((state) =>
		selectManager(state, activeUserId),
	);
	const [shownManager, setShownManager] = useState(value);
	return (
		<Autocomplete
			options={users}
			getOptionLabel={({ displayName }: User) => displayName ?? ""}
			value={shownManager}
			onChange={(_, newValue: any) => {
				// Without the local manager state, we observe weird twitching behaviour when saving the form
				// Keep the state, at least until we better understand the issue
				setShownManager(newValue);
				setValue("manager", newValue);
			}}
			disabled={isManagerOfThisUserLoading}
			disableClearable
			isOptionEqualToValue={(option, value) =>
				option.userPrincipalName === value.userPrincipalName
			}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.id}>
						<Grid item className={styles.userNameContainer}>
							<Typography variant="description">
								{tripleDotTruncateString(option.displayName, 33)}
							</Typography>
							<TruncatableTypography
								variant="caption"
								maxCharLength={37}
								color={"text.secondary"}
								tooltipTitle={option.displayName}
								tooltipPlacement="left-start"
							>
								{option.mail ?? option.userPrincipalName}
							</TruncatableTypography>
						</Grid>
					</li>
				);
			}}
			renderInput={(params) => (
				<TextField
					className={styles.autcompleteInput}
					{...params}
					variant="outlined"
					placeholder="Search for employee"
					InputProps={{
						style: {
							height: 40,
							alignContent: "center",
						},
						...params.InputProps,
						startAdornment: (
							<>
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
								{params.InputProps.startAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
};
