import { useNavigate } from "react-router-dom";
import { useAllowedPagesForUser } from "hooks/hooks";

export const useAppNavigate = () => {
	const { allowedPagesForRole, allowedPagesForCustomer } = useAllowedPagesForUser();

	const navigate = useNavigate();
	const navigateToPage = (path: string) => {
		// If the customer has access to the page, navigate to it
		const allowedPathsForCustomer = allowedPagesForCustomer.map((page) => page.path);
		if (allowedPathsForCustomer.includes(path)) {
			navigate(path);
			return;
		}
	};

	return { allowedPagesForRole, navigateToPage };
};
