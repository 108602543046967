import { Grid, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";

import styles from "./DeliveryOptions.module.scss";
import { DeliveryOption } from "utilities/constants/enums";
import { DELIVERY_TIME } from "utilities/constants/constants";

interface DeliveryOptionsProps {
	control: any;
}

export const DeliveryOptions = ({ control }: DeliveryOptionsProps) => {
	return (
		<Grid container>
			<Typography variant="body1">Delivery options</Typography>
			<Controller
				name="deliveryOption"
				control={control}
				defaultValue={DeliveryOption.B2B}
				render={({ field }) => (
					<Grid container className={styles.deliveryOptionsSelectorContainer}>
						<Grid
							item
							className={clsx({
								[styles.deliveryOption]: true,
								[styles.deliveryOptionActive]: field.value === DeliveryOption.B2B,
							})}
							onClick={(e: any) => {
								field.onChange(DeliveryOption.B2B);
							}}
						>
							<IconButton disableRipple>
								<WorkIcon />
							</IconButton>
							<Grid item className={styles.deliveryOptionText}>
								<Typography variant="body1">Deliver to office</Typography>
								<Typography variant="caption" className={styles.secondaryText}>
									{DELIVERY_TIME.OFFICE}
								</Typography>
							</Grid>
						</Grid>
						<Grid
							item
							className={clsx({
								[styles.deliveryOption]: true,
								[styles.deliveryOptionActive]:
									field.value === DeliveryOption.B2CHomeDelivery,
							})}
							onClick={(e: any) => {
								field.onChange(DeliveryOption.B2CHomeDelivery);
							}}
						>
							<IconButton disableRipple>
								<HomeIcon />
							</IconButton>
							<Grid item className={styles.deliveryOptionText}>
								<Typography variant="body1">Home delivery</Typography>
								<Typography variant="caption" className={styles.secondaryText}>
									{DELIVERY_TIME.HOME}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				)}
			/>
		</Grid>
	);
};
