import { ReactElement } from "react";

import styles from "./AddToBundleButton.module.scss";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { addItemToBundle } from "features/hardware/hardwareBundles";
import { useAppDispatch, useAuth } from "hooks/hooks";
import { ReactComponent as BundleBox } from "assets/cost/Group.svg";
import { setHardwareDrawerClosed, setHardwareDrawerOpen } from "features/hardware/hardwarePage";
import { HardwareDrawerType } from "utilities/constants/enums";
import { theme } from "theme";

interface AddToCartButtonProps {
	sku: string;
	setShowCheckoutDialog?: (show: boolean) => void;
}

function AddToBundleButton({ sku }: AddToCartButtonProps): ReactElement {
	const dispatch = useAppDispatch();

	const handleAddItemToBundle = () => {
		dispatch(
			setHardwareDrawerOpen({
				drawerType: HardwareDrawerType.Bundle,
				forceOpen: window.innerWidth > theme.breakpoints.values.md ? true : false,
			}),
		);
		dispatch(addItemToBundle(sku));
	};

	return (
		<PrimaryButton
			size="medium"
			className={styles.addToBundleButton}
			icon={<BundleBox />}
			text="Add to bundle"
			variantStyle="outlined"
			action={handleAddItemToBundle}
		/>
	);
}

function FindBundleItemsButton(): ReactElement {
	const { dispatch } = useAuth();

	return (
		<PrimaryButton
			size="medium"
			className={styles.addToBundleButton}
			icon={<BundleBox />}
			text="Find bundle items"
			variantStyle="outlined"
			action={() => dispatch(setHardwareDrawerClosed())}
		/>
	);
}

export { AddToBundleButton, FindBundleItemsButton };
