import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchAllDevices, fetchDeviceComplianceReportStatus } from "actions/deviceActions";
import { RootState } from "store";
import { Device, DeviceFilters } from "types";
import { DeviceComplianceStatus, IntuneManagementType } from "utilities/constants/enums";
import { groupDeviceData } from "utilities/filterDeviceData";

const devicesAdapter = createEntityAdapter<Device>({
	selectId: (device) => `${device.deviceId}`,
});

const defaultDeviceFilters: DeviceFilters = {
	osFilters: [],
	intuneManagementFilters: [],
	complianceFilters: [],
};

const devicesSlice = createSlice({
	name: "devices",
	initialState: devicesAdapter.getInitialState({
		isLoading: true,
		isFetching: false,
		isError: false,
		deviceComplianceReport: {
			isLoading: true,
			isFetching: false,
			isError: false,
		},
		filterIds: [] as string[],
		deviceFilterOptions: {} as DeviceFilters,
		chosenDeviceFilters: defaultDeviceFilters,
	}),
	reducers: {
		setFilterIds: (state, { payload }) => {
			state.filterIds = payload;
		},
		setChosenDeviceFilters: (state, { payload }) => {
			state.chosenDeviceFilters = payload;
		},
		setSpecificChosenDeviceFilter: (state, { payload }) => {
			state.chosenDeviceFilters = { ...defaultDeviceFilters, ...payload };
		},
		resetFilters: (state) => {
			state.chosenDeviceFilters = defaultDeviceFilters;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAllDevices.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchAllDevices.fulfilled, (state, { payload }) => {
				devicesAdapter.setAll(state, payload.devices);
				const filters = createFilterOptions(payload.devices);
				state.deviceFilterOptions = filters;
				state.filterIds = payload.devices.map((device) => `${device.deviceId}`); // By default all devices are selected
				state.isFetching = false;
				state.isLoading = false;
			})
			.addCase(fetchAllDevices.rejected, (state) => {
				state.isFetching = false;
				state.isLoading = false;
				state.isError = true;
			});
		builder
			.addCase(fetchDeviceComplianceReportStatus.pending, (state) => {
				state.deviceComplianceReport = {
					isFetching: true,
					isLoading: true,
					isError: false,
				};
			})
			.addCase(fetchDeviceComplianceReportStatus.fulfilled, (state, { payload }) => {
				state.deviceComplianceReport = {
					isLoading: false,
					isError: false,
					isFetching: false,
				};
			})
			.addCase(fetchDeviceComplianceReportStatus.rejected, (state) => {
				state.deviceComplianceReport = {
					isError: true,
					isFetching: false,
					isLoading: false,
				};
			});
	},
});

export const selectDevices = (state: RootState) => state.devices;
export const selectDeviceComplianceReport = (state: RootState) =>
	state.devices.deviceComplianceReport;
export const selectChosenDeviceFilters = (state: RootState) => state.devices.chosenDeviceFilters;
export const selectDeviceFilterOptions = (state: RootState) => state.devices.deviceFilterOptions;

export const selectFilterIds = (state: RootState) => state.devices.filterIds;

export const devicesSelectors = devicesAdapter.getSelectors(selectDevices);

export const selectGroupedDeviceData = createSelector(
	devicesSelectors.selectAll,
	(devices: Device[]) => {
		// Assuming deviceState.entities contains the array of devices
		return groupDeviceData(devices);
	},
);

export const selectDevicesKeyedByGraphDeviceId = createSelector(
	devicesSelectors.selectAll,
	(devices) => {
		return devices.reduce((acc, device) => {
			acc[device.graphDeviceId] = device;
			return acc;
		}, {} as Record<string, Device>);
	},
);

export const { setFilterIds, setChosenDeviceFilters, setSpecificChosenDeviceFilter, resetFilters } =
	devicesSlice.actions;

export default devicesSlice.reducer;

const createFilterOptions = (devices: Device[]) => {
	// OS, Compliance, Activity
	const osFilters = Array.from(new Set(devices.map((device) => device.operatingSystemShort)));
	const intuneManagementFilters = [
		IntuneManagementType.IronstonePolicyManaged,
		IntuneManagementType.CustomerPolicyManaged,
		IntuneManagementType.NoPolicy,
	];
	const complianceFilters = [
		DeviceComplianceStatus.Compliant,
		DeviceComplianceStatus.NonCompliant,
		DeviceComplianceStatus.Inactive,
	];

	return { osFilters, intuneManagementFilters, complianceFilters };
};
