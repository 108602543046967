import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchAppRoleAssignments, updateAppRoleAssignments } from "actions/customerActions";
import { RootState } from "store";
import { AppRoleAssignment } from "types";

const appRoleAssignmensAdapter = createEntityAdapter<AppRoleAssignment>({
	selectId: ({ roleId }) => roleId,
});

const appRoleAssignmentsSlice = createSlice({
	name: "appRoleAssignments",
	initialState: appRoleAssignmensAdapter.getInitialState({ isLoading: true, isFetching: false }),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAppRoleAssignments.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchAppRoleAssignments.fulfilled, (state, { payload }) => {
				appRoleAssignmensAdapter.setAll(state, payload);
				state.isLoading = false;
				state.isFetching = false;
			});
		builder.addCase(
			updateAppRoleAssignments.fulfilled,
			(
				state,
				{
					meta: {
						arg: { newRoleAssignedUsers, body },
					},
				},
			) => {
				const roleId = body?.roleId as string;

				appRoleAssignmensAdapter.updateOne(state, {
					id: roleId,
					changes: {
						roleAssignedUsers: newRoleAssignedUsers,
					},
				});
			},
		);
	},
});

export const selectAppRoleAssignments = (state: RootState) => state.appRoleAssignments;
export const appRoleAssignmentsSelectors =
	appRoleAssignmensAdapter.getSelectors(selectAppRoleAssignments);

export const selectAppRoleAssignmentOptions = createSelector(
	appRoleAssignmentsSelectors.selectAll,
	(appRoleAssignments) =>
		appRoleAssignments.reduce((acc, { roleDisplayName, roleValue }) => {
			acc[roleValue] = roleDisplayName;
			return acc;
		}, {} as Record<string, string>),
);

export default appRoleAssignmentsSlice.reducer;
