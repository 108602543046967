import { selectCustomerDetails } from "features/customer";
import { selectRoles } from "features/roles";
import { useAppSelector } from "hooks/hooks";
import { Navigate } from "react-router-dom";
import { ROLES_ACCESS } from "utilities/constants/pages";
import { getDefaultPage } from "utilities/roleUtils/getDefaultPage";

export const DefaultNavigation = () => {
	const roles = useAppSelector(selectRoles);
	const customerInfo = useAppSelector(selectCustomerDetails);

	if (!roles || customerInfo.isLoading) return null;

	return <Navigate to={getDefaultPage(roles, ROLES_ACCESS, customerInfo).path} />;
};
