import { Grid, IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";

import { LicenseCostTable } from "./LicenseCostTable";
import { LicenseSubscriptionCostDetails } from "types";

import colors from "styles/utilities/_colors.scss";
import styles from "./LicenseCostView.module.scss";
import { fetchOffice365Billing } from "actions/costActions";
import { useApiOnce, useAppSelector, useFetchLicensePrices } from "hooks/hooks";
import {
	selectCurrentOffice365Cost,
	selectLastOffice365Cost,
	selectOffice365Cost,
} from "features/costs";
import { useState } from "react";
import { CostTypeCard } from "components/CostManagement/TotalsCards/CostTypeCard";
import { LicenseIcon } from "assets/cost/license_icon";
import { DataUpdateInterval } from "components/Common/DataUpdateInterval";
import { selectLicensePriceDiscount } from "features/licenses/licensePrices";

const LicenseCostView = () => {
	const { isLoading, isFetching } = useAppSelector(selectOffice365Cost);

	useApiOnce(fetchOffice365Billing, { isLoading, isFetching });
	useFetchLicensePrices();

	const discountAmount = useAppSelector(selectLicensePriceDiscount);
	const currentPeriod = useAppSelector(selectCurrentOffice365Cost);
	const lastPeriod = useAppSelector(selectLastOffice365Cost);

	const [searchValue, setSearchValue] = useState<string>("");
	const selectedSubscriptions =
		currentPeriod?.licenseSubscriptions?.filter(({ subscriptionName, termDuration }) => {
			const searchableTermDuration =
				termDuration === "P1M" ? "Monthly months P1M" : "Annual yearly P1Y";
			return (
				subscriptionName.toLowerCase().includes(searchValue.toLowerCase()) ||
				searchableTermDuration.toLowerCase().includes(searchValue.toLowerCase())
			);
		}) ?? [];

	const groupedSubscriptions = selectedSubscriptions.reduce((acc, subscription) => {
		const provisioningId = subscription.usageDetails?.provisioningId;
		if (!provisioningId) return acc;
		acc[provisioningId] || (acc[provisioningId] = []);
		acc[provisioningId].push(subscription);
		return acc;
	}, {} as Record<string, LicenseSubscriptionCostDetails[]>);

	return (
		<Grid container className={styles.wrapper}>
			<Grid
				container
				item
				className={styles.topSectionContainer}
				justifyContent="space-between"
				mb={3}
			>
				<Grid item>
					<Grid item container className={styles.office365LicenseCard}>
						<CostTypeCard
							isLoading={isLoading}
							title="Office 365 Licenses"
							totalCurrentPeriod={currentPeriod?.totalForecasted ?? 0}
							totalPreviousPeriod={lastPeriod?.totalForecasted}
							icon={<LicenseIcon />}
							backgroundColor={colors.bluePrimary}
							size="medium"
							periodLabel={`${dayjs().startOf("month").format("DD.MM")} - ${dayjs()
								.endOf("month")
								.format("DD.MM")}`}
							totalDiscountAmount={currentPeriod?.totalDiscountAmount ?? 0}
							hoverDescription={
								discountAmount
									? `License discount is active. ${discountAmount}% off on selected licenses.`
									: ""
							}
						/>
					</Grid>
					<Grid item className={styles.inputContainer}>
						<div className={styles.inputRoot}>
							<IconButton disabled className={styles.iconButton}>
								<SearchIcon />
							</IconButton>
							<InputBase
								value={searchValue}
								className={styles.input}
								placeholder="Search for license name or term duration"
								onChange={(e) => setSearchValue(e.target.value as string)}
							/>
						</div>
					</Grid>
				</Grid>
				<Grid item mt={3}>
					<DataUpdateInterval updateIntervalText={"Cost data updates every day"} />
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<LicenseCostTable
					isLoading={isLoading}
					groupedSubscriptions={groupedSubscriptions ?? {}}
				/>
			</Grid>
		</Grid>
	);
};

export { LicenseCostView };
