import _ from "lodash";
import { useState } from "react";
import { TableRow, TableCell, IconButton, Collapse, Box } from "@mui/material";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { RISK_EVENT_TYPES } from "utilities/constants/constants";
import { RiskEventTypes, RiskLevel, NoDuplicatesRiskDetections } from "types";
import { HoverTooltip } from "components/Common/Tooltips";

import { RiskyTypesUsers } from "../RiskyTypesUsers";
import { RiskLevelChip } from "../RiskLevelChip";

import styles from "./ExpandableRowItem.module.scss";

interface ExpandableRowItemProps {
	riskLevel: RiskLevel;
	rowName: keyof RiskEventTypes;
	risksArray: NoDuplicatesRiskDetections[];
}

const ExpandableRowItem = ({
	riskLevel,
	risksArray,
	rowName,
}: ExpandableRowItemProps): JSX.Element => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<TableRow className={styles.root}>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					<div className={styles.riskType}>
						{RISK_EVENT_TYPES[rowName]?.label ?? rowName}
						{!_.isEmpty(RISK_EVENT_TYPES[rowName]) && (
							<HoverTooltip
								title={RISK_EVENT_TYPES[rowName].label}
								description={
									<>
										<div>{RISK_EVENT_TYPES[rowName].description}</div>
									</>
								}
							>
								<InfoOutlinedIcon className={styles.infoIcon} />
							</HoverTooltip>
						)}
					</div>
				</TableCell>
				<TableCell align="right">{risksArray.length}</TableCell>
				<TableCell />
				<TableCell align="center">
					<RiskLevelChip riskLevel={riskLevel} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell className={styles.cell} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<RiskyTypesUsers riskyTypesUsers={risksArray} />
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export { ExpandableRowItem };
