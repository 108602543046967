import { Grid, Typography } from "@mui/material";

import styles from "./UnassignedHeader.module.scss";

export const UnassignedHeader = ({ availableForRemoval }: { availableForRemoval: number }) => {
	return (
		<Grid item className={styles.licenseNotInUseContainer}>
			<Typography variant="body1" fontWeight={500} mb={2}>
				Available for removal
			</Typography>
			<Typography variant="h2" display="inline">
				{Math.max(availableForRemoval, 0)}
			</Typography>
			<Typography variant="description" className={styles.licenseNotInUseDescription}>
				{availableForRemoval === 1 ? "license" : "licenses"}
			</Typography>
		</Grid>
	);
};
