import { createAsyncThunk } from "@reduxjs/toolkit";
import { AggregatedData } from "types/AggregatedData";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

export const fetchAggregatedData = createAsyncThunk(
	"aggregatedData/fetch",
	apiGet<AggregatedData>(
		(authConfig) => authConfig.DATA_LAKE_API_ENDPOINTS.GET_AGGREGATED_DATA(),
		[SCOPES.DATA_LAKE_READ],
	),
);
