import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserLicenseAssignmentState } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

export const fetchLicenseAssignmentErrors = createAsyncThunk(
	"licenses/fetchLicenseAssignmentErrors",
	apiGet<UserLicenseAssignmentState[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_LICENSE_ASSIGNMENT_ERRORS(),
		[SCOPES.GRAPH_READ],
	),
);

export const fetchLicenseAssignmentErrorsForUser = createAsyncThunk(
	"licenses/fetchLicenseAssignmentErrorsForUser",
	apiGet<UserLicenseAssignmentState>(
		(authConfig, { id }) =>
			authConfig.MS_GRAPH_API_ENDPOINTS.GET_LICENSE_ASSIGNMENT_ERRORS_FOR_USER(id),
		[SCOPES.GRAPH_READ],
	),
);
