import { Grid, IconButton, InputLabel, Tooltip, Typography } from "@mui/material";
import styles from "./InputWrapper.module.scss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import clsx from "clsx";

export type InputWrapperProps = {
	tooltipText?: string;
	htmlFor?: string;
	label?: React.ReactNode;
	children?: React.ReactNode;
	noLabel?: boolean;
	isDisabled?: boolean;
};

const InputWrapper = ({
	htmlFor,
	label,
	tooltipText,
	children,
	noLabel = false,
	isDisabled = false,
}: InputWrapperProps) => {
	return (
		<>
			{!noLabel && (
				<InputLabel htmlFor={htmlFor}>
					<Grid container alignItems="center">
						{label && typeof label === "string" ? (
							<Typography
								className={clsx({
									[styles.disabledLabel]: isDisabled,
								})}
							>
								{label}
							</Typography>
						) : (
							{ label }
						)}
						{tooltipText && (
							<Tooltip title={tooltipText}>
								<IconButton classes={{ root: styles.tooltip }}>
									<InfoOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}
					</Grid>
				</InputLabel>
			)}
			{children}
		</>
	);
};

export { InputWrapper };
