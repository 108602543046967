import { Autocomplete, Checkbox, Grid, Skeleton, TextField, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { fetchAppRoleAssignments } from "actions/customerActions";
import { appRoleAssignmentsSelectors, selectAppRoleAssignments } from "features/appRoleAssignments";
import { selectUsers, usersSelectors } from "features/users";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { AppRoleAssignment, CustomerInfo, RoleAssignedUser, User } from "types";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";
import PeopleIcon from "@mui/icons-material/People";
import { fetchUsers } from "actions/userActions";
import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { HoverTooltip } from "components/Common/Tooltips";
import { InternalLink } from "components/Common/Links";
import { PortalRolesDrawer } from "./PortalRolesDrawer";

import styles from "./PortalRoles.module.scss";
import useRole from "utilities/roleUtils/roleCheck";
import { selectCustomerDetails } from "features/customer";
import { IRONSTONE_PRODUCT_DISPLAY_NAMES, ROLES_CONSTANTS } from "utilities/constants/constants";
import _ from "lodash";

interface RoleAssignedUserWithRoleInfo extends RoleAssignedUser {
	roleValue: string;
	roleDisplayName: string;
}

interface PortalRolesProps {
	appRoleAssignments: AppRoleAssignment[];
	setRoleAssignments: (updatedAppRoleAssignments: AppRoleAssignment[]) => void;
	setPortalRolesEqualOriginal: (value: boolean) => void;
}

export const PortalRoles = ({
	appRoleAssignments,
	setRoleAssignments,
	setPortalRolesEqualOriginal,
}: PortalRolesProps) => {
	const customerInfo = useAppSelector(selectCustomerDetails);
	const [activeRoleValue, setActiveRoleValue] = useState<string | null>(null);
	const appRoleAssignmentsState = useAppSelector(selectAppRoleAssignments);
	const originalAppRoleAssignments = useAppSelector(appRoleAssignmentsSelectors.selectEntities);
	useApiOnce(fetchAppRoleAssignments, appRoleAssignmentsState);
	useApiOnce(fetchUsers, useAppSelector(selectUsers));

	useEffect(() => {
		const newSelectedUsersEqualOld = appRoleAssignments.every((roleAssignment) => {
			const originalRoleAssignment = originalAppRoleAssignments[roleAssignment.roleId];
			if (!originalRoleAssignment) {
				return false;
			}

			const originalUsers = originalRoleAssignment.roleAssignedUsers.map(
				({ userId }) => userId,
			);

			const newUsers = roleAssignment.roleAssignedUsers.map(({ userId }) => userId);

			return _.isEqual(originalUsers, newUsers);
		});

		setPortalRolesEqualOriginal(newSelectedUsersEqualOld);
	}, [appRoleAssignments, originalAppRoleAssignments, setPortalRolesEqualOriginal]);

	const allUsersWithTheirRoles = useMemo(() => {
		return appRoleAssignments.reduce((acc, roleAssignment) => {
			roleAssignment.roleAssignedUsers.forEach((user) => {
				acc[user.userId] = {
					...user,
					roleValue: roleAssignment.roleValue,
					roleDisplayName: roleAssignment.roleDisplayName,
				};
			});
			return acc;
		}, {} as Record<string, RoleAssignedUserWithRoleInfo>);
	}, [appRoleAssignments]);

	const handleUpdateRoleAssignment = (updatedRoleAssignment: AppRoleAssignment) => {
		const updatedRoleAssignments = appRoleAssignments.map((roleAssignment) =>
			roleAssignment.roleValue === updatedRoleAssignment.roleValue
				? updatedRoleAssignment
				: roleAssignment,
		);
		setRoleAssignments(updatedRoleAssignments);
	};

	const enrichedAppRoleAssignments = useMemo(() => {
		const mapped = appRoleAssignments.map((roleAssignment) => ({
			...roleAssignment,
			roleAssignmentAvailable: roleAssignmentAvailable(roleAssignment, customerInfo),
		}));
		const sorted = [...mapped].sort((a, b) => {
			// Available roles first
			const aAvailable = a.roleAssignmentAvailable;
			const bAvailable = b.roleAssignmentAvailable;
			if (aAvailable && !bAvailable) return -1;
			if (!aAvailable && bAvailable) return 1;
			return a.roleDisplayName.localeCompare(b.roleDisplayName);
		});

		return sorted;
	}, [appRoleAssignments, customerInfo]);

	return (
		<Grid container>
			<Typography variant="h3" fontWeight={500} mb={3}>
				Portal roles & access control
			</Typography>
			<Grid container>
				{!appRoleAssignmentsState.isLoading &&
					enrichedAppRoleAssignments.map((role) => (
						<HoverTooltip
							title={`Missing required product`}
							description={`Required product: ${IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_EMPLOYEES} or ${IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_IT_SYSTEMS}`}
							key={role.roleValue}
							hide={role.roleAssignmentAvailable}
							placement="left-start"
						>
							<Grid
								item
								xs={6}
								className={clsx({
									[styles.disabled]: !role.roleAssignmentAvailable,
								})}
							>
								<PortalRoleDropDown
									appRoleAssignment={role}
									handleUpdateRoleAssignment={handleUpdateRoleAssignment}
									allUsersWithTheirRoles={allUsersWithTheirRoles}
									activeRoleValue={activeRoleValue}
									setActiveRoleValue={setActiveRoleValue}
									disabled={!role.roleAssignmentAvailable}
								/>
							</Grid>
						</HoverTooltip>
					))}
				{appRoleAssignmentsState.isLoading &&
					Array.from({ length: 4 }).map((_, index) => (
						<Grid item xs={6} key={index}>
							<Skeleton variant="rectangular" className={styles.loading} />
						</Grid>
					))}
			</Grid>
		</Grid>
	);
};

interface PortalRoleDropDownProps {
	appRoleAssignment: AppRoleAssignment;
	handleUpdateRoleAssignment: (updatedRoleAssignment: AppRoleAssignment) => void;
	allUsersWithTheirRoles: Record<string, RoleAssignedUserWithRoleInfo>;
	activeRoleValue: string | null;
	setActiveRoleValue: (role: string | null) => void;
	disabled: boolean;
}

const PortalRoleDropDown = ({
	appRoleAssignment,
	handleUpdateRoleAssignment,
	allUsersWithTheirRoles,
	activeRoleValue,
	setActiveRoleValue,
	disabled,
}: PortalRoleDropDownProps) => {
	const { isLoading } = useAppSelector(selectAppRoleAssignments);
	const { roleDisplayName, roleValue, roleAssignedUsers: selectedUsers } = appRoleAssignment;
	const allUsers = useAppSelector(usersSelectors.selectEntities);
	const { isAdmin } = useRole();

	const handleSetValue = (value: User[]) => {
		const newSelectedUsers = value.reduce((acc, user) => {
			acc[user.id] = user;
			return acc;
		}, {} as Record<string, User>);
		handleUpdateRoleAssignment({
			...appRoleAssignment,
			roleAssignedUsers: Object.values(newSelectedUsers).map(
				(user) =>
					({
						userId: user.id,
						userDisplayName: user.displayName,
					} as RoleAssignedUser),
			),
		});
	};

	const sortedOptions = Object.values(allUsers).sort((a, b) => {
		if (!a || !b) return 0;
		const aSelected = selectedUsers.some((user) => user.userId === a.id);
		const bSelected = selectedUsers.some((user) => user.userId === b.id);
		if (aSelected === bSelected) return 0;
		if (aSelected && !bSelected) return -1;
		if (!aSelected && bSelected) return 1;
		return 0;
	});

	const shownOptionAlreadyHasAnotherRole = (option: User) => {
		const userWithRole = allUsersWithTheirRoles[option.id];

		if (!userWithRole) return false;
		if (userWithRole.roleValue !== roleValue) return true;
		return false;
	};

	return (
		<Grid container item className={styles.portalRoleContainer}>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="body1">{roleDisplayName}</Typography>
				</Grid>
				<InternalLink onClick={() => setActiveRoleValue(roleValue)}>
					<Typography variant="description" className={styles.accessLink}>
						View access
					</Typography>
				</InternalLink>
			</Grid>
			{isLoading ? (
				<Skeleton variant="rectangular" height={40} width="100%" />
			) : (
				<Autocomplete
					disabled={disabled}
					fullWidth
					options={sortedOptions}
					multiple
					value={selectedUsers.map((user) => allUsers[user.userId])}
					onChange={(_, newValue: any) => {
						handleSetValue(newValue);
					}}
					disableClearable={!isAdmin}
					clearOnBlur
					handleHomeEndKeys
					isOptionEqualToValue={(option, value) => {
						if (value === undefined) return false;
						return option.userPrincipalName === value.userPrincipalName;
					}}
					groupBy={(option) =>
						selectedUsers.find(({ userId }) => userId === option.id)
							? `${roleDisplayName}`
							: "Other"
					}
					classes={{
						paper: styles.dropdownStyle,
					}}
					getOptionLabel={(option) => option?.displayName ?? ""}
					disableCloseOnSelect
					renderOption={(props, option, { selected }) => {
						const alreadyHasAntoherRole = shownOptionAlreadyHasAnotherRole(option);
						// show if user already has another role or if the logged in user is not an action role
						const disabledOption = alreadyHasAntoherRole || !isAdmin;
						let tooltipDescription = `Please remove the ${roleDisplayName.toLowerCase()} role from ${
							option.displayName
						} before assigning it to another role.`;
						let tooltipTitle = `${option.displayName} already has a role`;
						if (!isAdmin) {
							tooltipTitle = "You do not have permission to assign roles.";
							tooltipDescription = "";
						}

						return (
							<HoverTooltip
								title={tooltipTitle}
								description={tooltipDescription}
								hide={!disabledOption}
								placement="left-start"
							>
								<li
									{...props}
									key={option.id}
									className={clsx(styles.option, {
										[styles.selected]: allUsersWithTheirRoles[option.id],
										[styles.disabledOption]: disabledOption,
									})}
									onClick={disabledOption ? undefined : props.onClick}
								>
									<Checkbox
										icon={
											<CheckBoxOutlineBlankIcon
												className={styles.unchecked}
												fontSize="small"
											/>
										}
										checkedIcon={
											<CheckBoxIcon
												className={styles.checked}
												fontSize="small"
											/>
										}
										checked={selected}
										disableRipple={alreadyHasAntoherRole}
									/>
									<Grid item className={styles.userNameContainer}>
										<Typography variant="description">
											{tripleDotTruncateString(option.displayName, 33)}
										</Typography>
										<Typography variant="caption" color={"text.secondary"}>
											{tripleDotTruncateString(
												option.mail ?? option.userPrincipalName,
												45,
											)}
										</Typography>
									</Grid>
								</li>
							</HoverTooltip>
						);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							placeholder={
								Object.values(selectedUsers).length > 0
									? ""
									: `Select ${roleDisplayName.toLowerCase()}s`
							}
							InputProps={{
								style: {
									height: 40,
									alignContent: "center",
								},
								...params.InputProps,
								type: "search",
							}}
						/>
					)}
					renderTags={(value, getTagProps) => (
						<div className={styles.tagContainer}>
							<PeopleIcon className={styles.icon} />
							<Typography variant="body1" fontWeight={500}>
								{value.length}
							</Typography>
						</div>
					)}
				/>
			)}
			<PortalRolesDrawer
				roleValue={roleValue}
				roleDisplayName={roleDisplayName}
				activeRoleValue={activeRoleValue}
				setActiveRoleValue={setActiveRoleValue}
			/>
		</Grid>
	);
};

const roleAssignmentAvailable = (roleAssignment: AppRoleAssignment, customerInfo: CustomerInfo) => {
	const hasAccess = customerInfo?.yourEmployeesEnabled || customerInfo?.yourItSystemsEnabled;
	if (hasAccess) return true;
	if (roleAssignment.roleValue === ROLES_CONSTANTS.ADMIN) return true;
	return false;
};
