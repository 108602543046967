import { HardwareBundleItem, HardwareProduct, HardwareProductExtended } from "types";
import { Dictionary } from "@reduxjs/toolkit";

import { handleName } from "utilities/stringFormattingUtility";
import _ from "lodash";

export const mapToProducts = (products: HardwareProduct[]) =>
	products.map((product) => {
		const searchTerms = [];
		// Key to understand the product pricing:
		// priceNet: Price from Komplett API, the actual price the customer needs to pay
		// discount: The discount the customer gets on the product vs the full price for "regular b2b customers"
		// priceNetExcludingCustomerDiscount: The price the customer would have to pay if they were a "regular b2b customer"
		const priceNetExcludingCustomerDiscount =
			Number(product.priceInfo.priceNet) + Number(product.priceInfo.discount);
		searchTerms.push(...product.description.toLowerCase().split(" "));
		searchTerms.push(product.sku);
		searchTerms.push(product.ean);
		return {
			...product,
			displayName: handleName(product.displayName, product.description),
			imageUrl: product.imageUrl.replace("p/50", "p/250"),
			searchTerms,
			subGroup: getSubGroupPrettyName(product.subGroup),
			group: renameGroups(product.manufacturer, product.group, product.subGroup),
			priceInfo: {
				...product.priceInfo,
				priceNetExcludingCustomerDiscount,
			},
		};
	});

const getSubGroupPrettyName = (subGroup: string) => {
	if (subGroup.includes("COMMERCIAL")) {
		// Certain subgroups are extremely specific, such as including the screen size of a laptop
		return "COMMERCIAL";
	}
	return subGroup;
};

const renameGroups = (manufacturer: string, group: string, subGroup: string) => {
	if (subGroup === "MOBILE_ACCESSORIES" || group === "PHONES_TABLET_ACC") return "PC_ACCESSORIES";

	// From here on: basically a method for handling the special child Apple
	if (manufacturer.toUpperCase() !== "APPLE") return group;
	if (subGroup === "APPLE_KEYBOARD" || subGroup === "APPLE_MICE_PAD") {
		return "MICE_KEYBOARDS";
	}

	if (subGroup === "AIRPODS") {
		return "SOUND";
	}

	return group;
};

export const mapToHardwareBundleInfo = (
	allProducts: Dictionary<HardwareProductExtended>,
	listOfSkews: { [key: string]: number },
): { [key: string]: HardwareBundleItem } => {
	const chosenProducts = Object.keys(listOfSkews).reduce(
		(acc: { [key: string]: HardwareBundleItem }, productSkew) => {
			const product = allProducts[productSkew];
			if (product) {
				const unrelevantFields = ["priceInfo", "searchTerms", "availability"];
				const relevantProductInfo = _.omitBy(product, (value, key) =>
					unrelevantFields.includes(key),
				) as HardwareBundleItem;
				acc[productSkew] = {
					...relevantProductInfo,
					count: listOfSkews[productSkew],
				};
			}
			return acc;
		},
		{},
	);
	return chosenProducts;
};
