import {
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import clsx from "clsx";

import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { ProductStatusLabel } from "./ProductStatusLabel/ProductStatusLabel";
import { Product, YourEmployeesCost, YourITSystemsCost } from "types";
import { PRODUCT_DESCRIPTIONS } from "utilities/constants/texts";

import styles from "./IronstoneServicesTable.module.scss";
import _ from "lodash";
import { CostType } from "utilities/constants/enums";

interface IronstoneServicesTableProps {
	isLoading: boolean;
	cost?: YourITSystemsCost | YourEmployeesCost | undefined;
	costType: CostType;
}

const IronstoneServicesTable = ({ isLoading, costType, cost }: IronstoneServicesTableProps) => {
	const products = Object.entries(cost ?? {})
		.reduce((acc, [key, value]) => {
			if (key.startsWith("product")) {
				// Your employees data structure can have several deals inside each product, so we need to flatten it
				if (costType === CostType.YOUR_EMPLOYEES) {
					value.forEach((product: Product) => {
						acc.push({
							...product,
							productNumber: key,
							key: `${key}-${product.dealName}`,
						});
					});
				} else {
					acc.push({
						key,
						productNumber: key,
						...value,
					});
				}
			}

			return acc;
		}, [] as Product[])
		.sort((a, b) => sortProducts(a, b));

	const tableHeadWidths = {
		name: {
			label: "Name",
			width: "20%",
		},
		status: {
			label: "Status",
			width: "10%",
		},
		description: {
			label: "Description",
			width: "40%",
		},
		activeUnits: {
			label: costType === CostType.YOUR_EMPLOYEES ? "Active Users" : "Quantity",
			width: "10%",
		},
		unitCost: {
			label: "Unit Price",
			width: "10%",
		},
		totalCost: {
			label: "Total cost month",
			width: "10%",
		},
	};

	const hasProducts = products && products.length > 0;

	return (
		<TableContainer className={styles.tableContainer}>
			<Table
				className={clsx({
					[styles.table]: true,
					[styles.noItemsTable]: !isLoading && !hasProducts,
				})}
			>
				<TableHead className={styles.tableHead}>
					<TableRow>
						{Object.entries(tableHeadWidths).map(([key, value]) => (
							<TableCell key={key} width={value.width}>
								<Typography
									variant="body1"
									fontWeight={500}
									className={clsx({
										[styles.costCell]: key.toLowerCase().includes("cost"),
									})}
								>
									{value.label}
								</Typography>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{isLoading ? (
						_.range(costType === CostType.YOUR_EMPLOYEES ? 8 : 5).map((index) => (
							<TableRow key={index} className={styles.loadingRowStyle}>
								{Object.entries(tableHeadWidths).map(([key]) => (
									<TableCell key={key}>
										<Skeleton variant="text" height={50} />
									</TableCell>
								))}
							</TableRow>
						))
					) : hasProducts ? (
						products.map(
							({
								key,
								productNumber,
								displayName,
								dealName,
								billableCount,
								unitPrice,
								totalProductCost,
							}: Product) => (
								<TableRow key={key} className={styles.rowStyle}>
									<TableCell
										className={clsx({
											[styles.firstCellStyle]: true,
											[styles.inactiveFirstCell]: billableCount === 0,
										})}
									>
										<Typography variant="body1" fontWeight={500}>
											{displayName}
										</Typography>
										{dealName && (
											<Typography variant="body1">{dealName}</Typography>
										)}
									</TableCell>
									<TableCell
										className={clsx({
											[styles.cell]: billableCount > 0,
											[styles.inactiveCell]: billableCount === 0,
										})}
									>
										<Typography variant="body1">
											<ProductStatusLabel
												label={billableCount === 0 ? "Inactive" : "Active"}
											/>
										</Typography>
									</TableCell>
									<TableCell
										className={clsx({
											[styles.cell]: billableCount > 0,
											[styles.inactiveCell]: billableCount === 0,
										})}
									>
										<Typography variant="body1">
											{
												PRODUCT_DESCRIPTIONS[costType][
													productNumber.toUpperCase()
												]
											}
										</Typography>
									</TableCell>
									<TableCell
										className={clsx({
											[styles.cell]: billableCount > 0,
											[styles.inactiveCell]: billableCount === 0,
										})}
									>
										<Typography variant="body1">
											{billableCount === 0 ? "" : billableCount}
										</Typography>
									</TableCell>
									<TableCell
										className={clsx({
											[styles.costCell]: true,
											[styles.cell]: billableCount > 0,
											[styles.inactiveCell]: billableCount === 0,
										})}
									>
										<Typography variant="body1">
											{billableCount === 0
												? ""
												: formatCostString("", unitPrice, " ,-")}
										</Typography>
									</TableCell>
									<TableCell
										className={clsx({
											[styles.lastCellStyle]: true,
											[styles.inactiveLastCell]: billableCount === 0,
										})}
									>
										<Typography variant="body1" fontWeight={500}>
											{billableCount === 0
												? ""
												: formatCostString("", totalProductCost, " ,-")}
										</Typography>
									</TableCell>
								</TableRow>
							),
						)
					) : (
						<TableRow>
							<TableCell
								className={clsx({
									[styles.noItemsCell]: true,
									[styles.cell]: true,
								})}
								colSpan={Object.entries(tableHeadWidths).length}
								align="center"
							>
								<Typography variant="body1">
									{`No products or addons found for:
									${costType === CostType.YOUR_EMPLOYEES ? "IT for your emlpoyees" : "your IT-systems"}
									`}
								</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

// Sorts products based on the product status (inactive/active) and total cost, inactivity is determined by the billableCount
const sortProducts = (a: Product, b: Product) => {
	const aActive = a.billableCount > 0;
	const bActive = b.billableCount > 0;
	const totalProductCostDifference = b.totalProductCost - a.totalProductCost;

	if (aActive && !bActive) {
		return -1;
	}

	if (!aActive && bActive) {
		return 1;
	}

	if (totalProductCostDifference !== 0) {
		return totalProductCostDifference;
	}

	return a.displayName.localeCompare(b.displayName);
};

export { IronstoneServicesTable };
