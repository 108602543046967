import { Typography } from "@mui/material";
import dayjs from "dayjs";

interface ExpiryDateComponentProps {
	autoRenewEnabled: boolean;
	commitmentEndDate: string;
}

export const ExpiryDateComponent = ({
	autoRenewEnabled,
	commitmentEndDate,
}: ExpiryDateComponentProps) => {
	const subscriptionExpiresSoon =
		dayjs(commitmentEndDate).isBefore(dayjs().add(1, "month")) && !autoRenewEnabled;
	const subscriptionHasExpired = dayjs(commitmentEndDate).isBefore(dayjs()) && !autoRenewEnabled;

	let expiryDateText = dayjs(commitmentEndDate).format("DD.MM.YYYY");

	if (subscriptionHasExpired || subscriptionExpiresSoon) {
		expiryDateText = `${subscriptionHasExpired ? "Expired" : "Expires"} ${dayjs(
			commitmentEndDate,
		).fromNow()}`;
	}

	return (
		<Typography
			variant="body1"
			color={subscriptionExpiresSoon || subscriptionHasExpired ? "error" : "textPrimary"}
		>
			{expiryDateText}
		</Typography>
	);
};
