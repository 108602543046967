import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { SecondaryButton } from "components/Common/Buttons/Button";
import styles from "./TeamsDialog.module.scss";

export interface ITeamsDialogProps {
	title?: string;
	content: string;
	onClose: () => void;
	isOpen: boolean;
	isActionBtnHidden?: boolean;
}

export const TeamsDialog = (props: ITeamsDialogProps) => {
	const { content, isOpen, onClose, isActionBtnHidden, title } = props;
	return (
		<div>
			<Dialog
				classes={{
					root: styles.dialogRoot,
				}}
				open={isOpen}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<Typography gutterBottom>{content}</Typography>
					</DialogContentText>
				</DialogContent>
				{!isActionBtnHidden && (
					<DialogActions>
						<SecondaryButton
							text="Close"
							size="large"
							variantStyle="outlined"
							action={() => {
								onClose();
							}}
						/>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
};
