import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchDepartments } from "actions/departmentActions";
import { RootState } from "store";
import { Department } from "types";

const departmentsAdapter = createEntityAdapter<Department>();

const departmentsSlice = createSlice({
	name: "departments",
	initialState: departmentsAdapter.getInitialState({ isLoading: true, isFetching: false }),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDepartments.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchDepartments.fulfilled, (state, { payload }) => {
				departmentsAdapter.setAll(
					state,
					payload.map((department: any, id: number) => ({ id, ...department })),
				);
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

export const selectDepartments = (state: RootState) => state.departments;

export const departmentsSelectors = departmentsAdapter.getSelectors(selectDepartments);

export default departmentsSlice.reducer;
