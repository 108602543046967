import { createAsyncThunk } from "@reduxjs/toolkit";
import { LicenseNameDTO } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

export const fetchLicenseNames = createAsyncThunk(
	"licenses/fetchlicenseNames",
	apiGet<LicenseNameDTO[]>(
		(authConfig) => authConfig.DATA_LAKE_API_ENDPOINTS.GET_LICENSE_NAMES(),
		[SCOPES.DATA_LAKE_READ],
	),
);
