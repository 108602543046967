import { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheckOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LanIcon from "@mui/icons-material/LanOutlined";
import { Grid, Paper, Skeleton } from "@mui/material";
import styles from "./RequestForm.module.scss";
import { COLLABORATION_FOLDER_PATH, IRONSTONE_SUPPORT_MAIL } from "utilities/constants/constants";
import { get, post } from "utilities/rest";
import _ from "lodash";
import { TeamsDialog } from "components/Common/Dialogs/TeamsDialog/TeamsDialog";
import { firstTabHelpText, GetStarted } from "./GetStarted";
import { Organisation } from "./Organisation";
import { WorkspaceDetails } from "./WorkspaceDetails";
import { Security } from "./Security";
import { getTabProps } from "./Shared";
import { IAdPeoplePickerOption } from "components/Common/Pickers/ADPeoplePicker";
import { CollaborationSettings, CollabType } from "types";
import { app } from "@microsoft/teams-js";
import { PrimaryButton } from "components/Common/Buttons/Button";

export interface IDropDownOption {
	id: string;
	label: string;
}

export interface IRequestFormProps {
	apiConfig: any;
	authToken: string;
	teams: app.Context;
	user: IAdPeoplePickerOption;
}

export const CollaborationRequestForm = ({
	apiConfig,
	authToken,
	teams,
	user,
}: IRequestFormProps): JSX.Element => {
	const [selectedCollabType, setSelectedCollabType] = useState<CollabType | null>(null);
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [isDialogActionBtnsHidden, setIsDialogActionBtnsHidden] = useState<boolean>(true);
	const [dialogMessage, setDialogMessage] = useState<string>("");
	const [selectedRegion, setSelectedRegion] = useState<IDropDownOption | null>(null);
	const [selectedDepartment, setSelectedDepartment] = useState<string | null>(null);
	const [workspaceName, setWorkspaceName] = useState<string>("");
	const [workspaceDescription, setWorkspaceDescription] = useState<string>("");
	const [primaryOwner, setPrimaryOwner] = useState<IAdPeoplePickerOption | null>(null);
	const [secondaryOwner, setSecondaryOwner] = useState<IAdPeoplePickerOption | null>(null);
	const [members, setMembers] = useState<Array<IAdPeoplePickerOption>>([]);
	const [privacy, setPrivacy] = useState<string>("private");
	const [sensitivity, setSensitivity] = useState<string>("nonSensitiveUnrestricted");
	const [selectedValueHasNoTeamsLicense, setSelectedValueHasNoTeamsLicense] = useState(false);

	const [collabSettings, setCollabSettings] = useState<CollaborationSettings>();

	const resetState = () => {
		setSelectedCollabType(null);
		setSelectedRegion(null);
		setSelectedDepartment(null);
		setWorkspaceName("");
		setWorkspaceDescription("");
		setPrimaryOwner(null);
		setSecondaryOwner(null);
		setMembers([]);
		setPrivacy("private");
		setSensitivity("nonSensitiveUnrestricted");
	};

	const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTabIndex(newValue);
	};

	const onCollabTypeSelected = () => {
		setActiveTabIndex(1);
	};

	const submitRequest = async (postData: any) => {
		const response = await post({
			uri: apiConfig.MS_GRAPH_API_ENDPOINTS.POST_COLLABORATION_REQUEST(),
			tokenType: "Bearer",
			accessToken: authToken,
			body: JSON.stringify(postData),
			contentType: "application/json",
			extraHeaders: {
				"Ocp-Apim-Subscription-Key": apiConfig.OCP_APIM_SUBSCRIPTION_KEY,
			},
		});

		if (response === undefined) {
			setDialogMessage(
				`Something went wrong submitting your request, please contact support at: ${IRONSTONE_SUPPORT_MAIL}`,
			);
			setIsDialogOpen(true);
		}

		if (!_.isEmpty(response?.data) && response?.status === 200) {
			setDialogMessage(
				"Your request was submitted successfully. You'll receive an email with more information when the workspace is ready. This may take longer if approval is required.",
			);
			setIsDialogActionBtnsHidden(false);
			resetState();
			setActiveTabIndex(0);
		}
	};

	const onFormSubmitClick = () => {
		if (onValidateForm()) {
			let domain = teams?.sharePointSite?.teamSiteDomain?.split(".")[0] ?? "";

			let postData = {
				tenantId: teams?.user?.tenant?.id,
				domain: domain,
				rootSharePointURL: `https://${domain}.sharepoint.com/`,
				requester: {
					id: user.id,
					displayName: user.displayName,
					mail: user.mail,
					userPrincipalName: user.userPrincipalName,
				},
				teamsSettings: {
					teamName: workspaceName,
				},
				collaborationType: selectedCollabType,
				region: selectedRegion,
				department: selectedDepartment,
				workspaceName: workspaceName.replace(/\s/g, ""),
				workspaceDescription: workspaceDescription,
				primaryOwner: primaryOwner,
				secondaryOwner: secondaryOwner,
				members: members,
				private: privacy === "private" ? true : false,
				sensitivity: sensitivity,
			};
			submitRequest(postData);
			setIsDialogActionBtnsHidden(true);
			setDialogMessage("Sending your request... Please wait.");
		} else {
			setDialogMessage(
				"Some of the form values are not filled out completely. Please complete them and try again.",
			);
		}
		setIsDialogOpen(true);
		setIsDialogActionBtnsHidden(false);
	};

	const onValidateForm = () =>
		selectedCollabType &&
		selectedDepartment &&
		selectedRegion &&
		workspaceName &&
		workspaceDescription &&
		primaryOwner &&
		secondaryOwner &&
		members;

	const getCustomerData = async (apiConfig: any) => {
		const response = await get(
			apiConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(COLLABORATION_FOLDER_PATH),
			"Bearer",
			authToken,
			true,
			"application/json",
			undefined,
			{
				"Ocp-Apim-Subscription-Key": apiConfig.OCP_APIM_SUBSCRIPTION_KEY,
			},
			null,
		);
		if (!_.isEmpty(response?.data)) {
			setCollabSettings(response?.data);
		}
	};

	useEffect(() => {
		if (apiConfig) {
			getCustomerData(apiConfig);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!collabSettings ? (
				<Grid container className={styles.paper}>
					<Grid item xs={12}>
						<Skeleton
							variant="rectangular"
							animation="wave"
							height="100%"
							width="100vh"
						/>
					</Grid>
				</Grid>
			) : (
				<Paper className={styles.paper}>
					<Grid p={0} container>
						<Grid item xs={2} md={3}>
							<Box className={styles.navBox}>
								<Tabs
									orientation="vertical"
									variant="standard"
									value={activeTabIndex}
									onChange={onTabChange}
									className={styles.tabsRoot}
									classes={{ indicator: styles.tabIndicator }}
								>
									<Tab
										label={
											<>
												<DashboardCustomizeIcon
													className={styles.navIcon}
												></DashboardCustomizeIcon>
												<Typography className={styles.tabText}>
													Get started
												</Typography>
											</>
										}
										classes={{ selected: styles.selectedTab }}
										{...getTabProps(0)}
										onClick={() => {
											setActiveTabIndex(0);
										}}
										className={styles.navTab}
									/>
									<Tab
										label={
											<>
												<LanIcon className={styles.navIcon}></LanIcon>
												<Typography className={styles.tabText}>
													Organisation
												</Typography>
											</>
										}
										classes={{ selected: styles.selectedTab }}
										{...getTabProps(1)}
										onClick={() => {
											setActiveTabIndex(1);
										}}
										className={styles.navTab}
									/>
									<Tab
										label={
											<>
												<FactCheckIcon
													className={styles.navIcon}
												></FactCheckIcon>
												<Typography className={styles.tabText}>
													Workspace details
												</Typography>
											</>
										}
										classes={{ selected: styles.selectedTab }}
										{...getTabProps(2)}
										onClick={() => {
											setActiveTabIndex(2);
										}}
										className={styles.navTab}
									/>
									<Tab
										label={
											<>
												<AdminPanelSettingsIcon
													className={styles.navIcon}
												></AdminPanelSettingsIcon>
												<Typography className={styles.tabText}>
													Security
												</Typography>
											</>
										}
										classes={{ selected: styles.selectedTab }}
										{...getTabProps(3)}
										onClick={() => {
											setActiveTabIndex(3);
										}}
										className={styles.navTab}
									/>
								</Tabs>
							</Box>
						</Grid>
						<Grid item xs={7} md={6} className={styles.middleColumn}>
							<GetStarted
								collabSettings={collabSettings}
								activeTabIndex={activeTabIndex}
								onCollabTypeSelected={onCollabTypeSelected}
								setSelectedCollabType={setSelectedCollabType}
							/>
							<Organisation
								activeTabIndex={activeTabIndex}
								selectedDepartment={selectedDepartment}
								selectedRegion={selectedRegion}
								setSelectedDepartment={setSelectedDepartment}
								setSelectedRegion={setSelectedRegion}
								authToken={authToken}
								apiConfig={apiConfig}
							/>
							<WorkspaceDetails
								activeTabIndex={activeTabIndex}
								members={members}
								primaryOwner={primaryOwner}
								secondaryOwner={secondaryOwner}
								setMembers={setMembers}
								setPrimaryOwner={setPrimaryOwner}
								setSecondaryOwner={setSecondaryOwner}
								setWorkspaceDescription={setWorkspaceDescription}
								setWorkspaceName={setWorkspaceName}
								workspaceDescription={workspaceDescription}
								workspaceName={workspaceName}
								setSelectedValueHasNoTeamsLicense={
									setSelectedValueHasNoTeamsLicense
								}
								authToken={authToken}
								apiConfig={apiConfig}
							/>
							<Security
								activeTabIndex={activeTabIndex}
								privacy={privacy}
								setPrivacy={setPrivacy}
								sensitivity={sensitivity}
								setSensitivity={setSensitivity}
							/>
							{activeTabIndex < 3 && (
								<PrimaryButton
									text="Next"
									size="large"
									variantStyle="contained"
									action={() => {
										setActiveTabIndex(activeTabIndex + 1);
									}}
									isDisabled={selectedValueHasNoTeamsLicense}
								/>
							)}
							{activeTabIndex === 3 && (
								<PrimaryButton
									text="Submit"
									size="large"
									variantStyle="contained"
									action={() => {
										onFormSubmitClick();
									}}
								/>
							)}
						</Grid>
						<Grid item xs={3} md={3} className={styles.sideBarPanel}>
							<div>
								{activeTabIndex === 0 && (
									<>
										<Typography variant="body2" className={styles.formRow}>
											About
										</Typography>
										<Typography className={styles.sideBarPanelText}>
											{firstTabHelpText[0]}
										</Typography>
										<Typography className={styles.sideBarPanelText}>
											{firstTabHelpText[1]}
										</Typography>
										<Typography className={styles.sideBarPanelText}>
											{firstTabHelpText[2]}
										</Typography>
									</>
								)}
								{activeTabIndex !== 0 && (
									<>
										<Typography variant="body2" className={styles.formRow}>
											Selected options
										</Typography>
										<Typography className={styles.boldLabel}>
											Collaboration type:
										</Typography>
										<Typography className={styles.selectedOption}>
											{selectedCollabType
												? selectedCollabType.displayName
												: ""}
										</Typography>
										<Typography className={styles.boldLabel}>
											Region:
										</Typography>
										<Typography className={styles.selectedOption}>
											{selectedRegion?.label}
										</Typography>
										<Typography className={styles.boldLabel}>
											Department:
										</Typography>
										<Typography className={styles.selectedOption}>
											{selectedDepartment}
										</Typography>
										<Typography className={styles.boldLabel}>
											Workspace name:
										</Typography>
										<Typography className={styles.selectedOption}>
											{workspaceName || ""}
										</Typography>
										<Typography className={styles.boldLabel}>
											Description:
										</Typography>
										<Typography className={styles.selectedOption}>
											{workspaceDescription || ""}
										</Typography>
										<Typography className={styles.boldLabel}>
											Primary owner:
										</Typography>
										<Typography className={styles.selectedOption}>
											{primaryOwner?.displayName || ""}
										</Typography>
										<Typography className={styles.boldLabel}>
											Secondary owner:
										</Typography>
										<Typography className={styles.selectedOption}>
											{secondaryOwner?.displayName || ""}
										</Typography>
										<Typography className={styles.boldLabel}>
											Members:
										</Typography>
										<Typography className={styles.selectedOption}>
											{members?.length > 0
												? members.map((m) => m["displayName"]).join(", ")
												: ""}
										</Typography>
									</>
								)}
							</div>
						</Grid>
					</Grid>
					<TeamsDialog
						title=""
						content={dialogMessage}
						isOpen={isDialogOpen}
						isActionBtnHidden={isDialogActionBtnsHidden}
						onClose={() => {
							setIsDialogOpen(false);
						}}
					/>
				</Paper>
			)}
		</>
	);
};
