import { createStaticRanges } from "react-date-range";
import dayjs from "dayjs";

// "Periods" we can create selectable ranges from
interface FormattedPeriod {
	label: string;
	startDate: Date;
	endDate: Date;
}

// "SelectableRanges" are the selectable ranges that will show in the date range picker
interface SelectableRanges {
	label: string;
	range: () => { startDate: Date; endDate: Date; key: string };
	isSelected: (selectedRange: any) => boolean;
}

// Used to create selectable ranges for the date-range-picker found in our common components
const formatRangesForDateRangePicker = (rangesFromFetchedData: any) => {
	// find the range that is the earliest period, default to start of current month
	const firstPeriodStartDate = rangesFromFetchedData.reduce(
		(currentFirstPeriod: Date, range: any) => {
			const startDate = dayjs(range, "MMMM YYYY").toDate();
			if (currentFirstPeriod === null || startDate < currentFirstPeriod) {
				return startDate;
			}
			return currentFirstPeriod;
		},
		dayjs().toDate(),
	);

	if (rangesFromFetchedData.length === 0) {
		return [];
	}

	const selectableRanges = rangesFromFetchedData.map((range: string) =>
		createSelectableRange({
			label: range,
			startDate: dayjs(range, "MMMM YYYY").toDate(),
			endDate: dayjs(range, "MMMM YYYY").endOf("month").toDate(),
		}),
	) as SelectableRanges[];

	const currentPeriodIsSelectable = selectableRanges.find(
		({ label }) => dayjs().format("MMMM YYYY") === label,
	);

	// Ensure that the current period is selectable
	if (!currentPeriodIsSelectable) {
		selectableRanges.unshift(
			createSelectableRange({
				label: dayjs().format("MMMM YYYY").toString(),
				startDate: dayjs().startOf("month").toDate(),
				endDate: dayjs().toDate(),
			}),
		);
	}

	// "Static ranges" are ranges that will show as selectable ranges in the date range picker
	const ranges = createStaticRanges([...selectableRanges]).sort((a, b) =>
		dayjs(b.range().startDate).diff(a.range().startDate),
	);
	ranges.unshift(
		createSelectableRange({
			label: "All periods",
			startDate: firstPeriodStartDate,
			endDate: dayjs().toDate(),
		}),
	);
	// Add "All Periods" to the top of the list
	return ranges;
};

export const filterEntriesOnSelectedRange = (
	startDate: Date,
	endDate: Date,
	entries: any[],
	property: string,
) =>
	entries.filter(({ [property]: filterDate }) =>
		dayjs(filterDate).isBetween(dayjs(startDate), dayjs(endDate), "day", "[]"),
	);

const createSelectableRange = ({ label, startDate, endDate }: FormattedPeriod) => ({
	label,
	range: () => ({ startDate, endDate, key: "selection" }),
	isSelected: (selectedRange: any) => isSelected({ selectedRange, startDate, endDate }),
});

const isSelected = ({
	selectedRange,
	startDate,
	endDate,
}: {
	selectedRange: any;
	startDate: Date;
	endDate: Date;
}) => {
	if (!startDate || !endDate) {
		return false;
	}

	return (
		dayjs(startDate).isSame(selectedRange.startDate) &&
		dayjs(endDate).isSame(selectedRange.endDate)
	);
};

export { formatRangesForDateRangePicker };
