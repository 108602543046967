import React, { useEffect, useRef, useState } from "react";
import styles from "./Carousel.module.scss";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import clsx from "clsx";
import { useWindowSize } from "hooks/hooks";
import { theme } from "theme";

interface Props {
	productStateIsLoading: boolean;
	children: React.ReactNode;
	numBundles: number;
}

const Carousel = ({ productStateIsLoading, numBundles, children }: Props) => {
	const [scrollEnd, setScrollEnd] = useState(false);
	const [scrollStart, setScrollStart] = useState(true);
	const scrl = useRef<HTMLDivElement>(null);
	const { width: windowWidth } = useWindowSize();
	const isSmallScreen = windowWidth < theme.breakpoints.values.md;
	const scrollJump = isSmallScreen ? 484 : 800;
	const numBundlesSuggestShowRightArrow = isSmallScreen ? numBundles >= 2 : numBundles >= 3;

	const slide = (shift: number) => {
		if (scrl.current) {
			scrl.current.scrollLeft += shift;
		}
	};

	useEffect(() => {
		const currentScroll = scrl.current;
		const handleScroll = () => {
			if (currentScroll) {
				if (currentScroll.scrollLeft < 2) {
					setScrollStart(true);
				} else {
					setScrollStart(false);
				}

				if (
					currentScroll.scrollLeft + currentScroll.offsetWidth + 2 >=
					currentScroll.scrollWidth
				) {
					setScrollEnd(true);
				} else {
					setScrollEnd(false);
				}
			}
		};

		// Add the event listener
		currentScroll?.addEventListener("scroll", handleScroll);

		// Clean up the event listener
		return () => {
			currentScroll?.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Grid container className={styles.carousel}>
			<Grid container className={styles.titleAndButtonContainer}>
				{!productStateIsLoading && (
					<Grid container>
						<div
							className={clsx({
								[styles.leftArrowContainer]: true,
								[styles.leftArrowContainerShadow]:
									!scrollStart && numBundlesSuggestShowRightArrow,
							})}
						>
							<IconButton
								onClick={() => slide(-1 * scrollJump)}
								disableFocusRipple
								disableRipple
							>
								<ArrowBackIosNewIcon className={styles.leftArrowIcon} />
							</IconButton>
						</div>
						<div
							className={clsx({
								[styles.rightArrowContainer]: true,
								[styles.rightArrowContainerShadow]:
									!scrollEnd && numBundlesSuggestShowRightArrow,
							})}
						>
							<IconButton
								onClick={() => slide(scrollJump)}
								disableFocusRipple
								disableRipple
							>
								<ArrowForwardIosIcon className={styles.rightArrowIcon} />
							</IconButton>
						</div>
					</Grid>
				)}
			</Grid>
			<div ref={scrl} className={styles.scrollList}>
				{children}
			</div>
		</Grid>
	);
};

export default Carousel;
