import { Divider, Grid, Typography } from "@mui/material";
import { CustomerInfo } from "types";
import clsx from "clsx";
import { CountryFlag } from "components/Common/CountrySelector";
import { ReactComponent as YourEmployeesIcon } from "assets/IronstoneProductLogos/YourEmployees.svg";
import { ReactComponent as YourITSystemsIcon } from "assets/IronstoneProductLogos/YourITSystems.svg";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { fetchCustomerBranding } from "actions/brandingActions";
import { selectCustomerBranding } from "features/branding";
import LanguageIcon from "@mui/icons-material/Language";

import styles from "./CompanyInfoSection.module.scss";
import { IRONSTONE_PRODUCT_DISPLAY_NAMES } from "utilities/constants/constants";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";

interface CompanyInfoSectionProps {
	customerInfo: CustomerInfo;
}

export const CompanyInfoSection = ({ customerInfo }: CompanyInfoSectionProps) => {
	useApiOnce(fetchCustomerBranding, useAppSelector(selectCustomerBranding));

	const { branding } = useAppSelector(selectCustomerBranding);

	const urlPresent = customerInfo?.companyUrl !== "" || customerInfo?.companyUrl;

	return (
		<Grid container className={styles.container}>
			<Grid className={styles.companyInfo}>
				<Grid
					container
					className={clsx({
						[styles.logoContainer]: branding?.logoBase64,
						[styles.hide]: !branding?.logoBase64,
					})}
				>
					<img
						src={`data:image/png;base64,${branding?.brandingBase64}`}
						alt="Company Logo"
						className={styles.logo}
					/>
				</Grid>
				<Grid container className={styles.details}>
					<Typography variant="h2" fontWeight={500}>
						{customerInfo?.departmentName}
					</Typography>
					<Grid container className={styles.locationInfo}>
						<CountryFlag country={customerInfo?.location} coloredBackground={false} />
						<Typography variant="body1">{customerInfo?.location}</Typography>
					</Grid>
					<Grid
						container
						className={clsx({
							[styles.urlInfo]: urlPresent,
							[styles.hide]: !urlPresent,
						})}
					>
						<LanguageIcon className={styles.languageIcon} />
						<Typography
							variant="description"
							fontWeight={500}
							className={styles.url}
							onClick={() => window.open(customerInfo?.companyUrl)}
						>
							{customerInfo?.companyUrl}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Divider orientation="vertical" flexItem className={styles.divider} />
			<Grid className={styles.companyInfoProducts}>
				<Grid item>
					<Grid item xs={12} mb={2}>
						<Typography variant="body1">Products</Typography>
					</Grid>
					<Grid container gap={1}>
						<ProductStatus
							icon={<YourEmployeesIcon />}
							label="IT for your employees"
							active={customerInfo?.yourEmployeesEnabled}
						/>
						<ProductStatus
							icon={<YourITSystemsIcon />}
							label={IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_IT_SYSTEMS}
							active={customerInfo?.yourItSystemsEnabled}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Divider orientation="vertical" flexItem className={styles.divider} />
			<Grid className={styles.companyInfoContacts}>
				<Typography variant="body1">Ironstone contacts</Typography>
				<Grid container item className={styles.contacts}>
					<Grid container item className={styles.contactInfo}>
						<Typography variant="body1" className={styles.contactType}>
							Primary
						</Typography>
						<Typography variant="body1" className={styles.contactName}>
							{customerInfo.commercialLead}
						</Typography>
					</Grid>
					<Grid container item className={styles.contactInfo}>
						<Typography variant="body1" className={styles.contactType}>
							Technical
						</Typography>
						<Typography variant="body1" className={styles.contactName}>
							{tripleDotTruncateString(customerInfo.tam, 20)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

interface ProductStatusProps {
	icon: JSX.Element;
	label: string;
	active: boolean;
}

const ProductStatus = ({ icon, label, active }: ProductStatusProps) => {
	return (
		<Grid container className={styles.productInfo}>
			<Grid
				container
				className={clsx(
					clsx({ [styles.activeProduct]: active, [styles.inactiveProduct]: !active }),
				)}
			>
				<div className={styles.productIcon}>{icon}</div>
				<Typography variant="body1">{label}</Typography>
			</Grid>
			<Typography className={clsx(styles.productStatusLabel, { [styles.active]: active })}>
				{active ? "Active" : "Inactive"}
			</Typography>
		</Grid>
	);
};
