import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
	IRONSTONE_PRODUCT_DISPLAY_NAMES,
	IRONSTONE_SUPPORT_URL,
	SUB_HEADER_NAMES,
} from "utilities/constants/constants";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { openInNewTab } from "utilities/openLink";
import { getSubHeaderForRole } from "utilities/roleUtils/getSubHeaderForRole";
import { useAllowedPagesForUser, useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { selectCustomerDetails } from "features/customer";
import { selectRoles } from "features/roles";
import { DrawerListSection } from "components/DrawerList/DrawerListSection";
import { DrawerListItem } from "components/DrawerList/DrawerListItem";
import { NavigationName, ProductType } from "types";
import { customerHasAccessTo } from "utilities/accessUtils/allowedPagesForCustomer";
import { fetchLicenseSubscriptionRecommendations } from "actions/recommendationActions";
import { selectNumberOfRecommendations } from "features/recommendations";
import { fetchCustomerInfo } from "actions/customerActions";
import { selectLicenseRecommentaions } from "features/scheduledActions";
import { setActiveLicenseManagementId } from "features/licenses/licenses";
import clsx from "clsx";

import styles from "./DrawerList.module.scss";

interface DrawerListProps {
	isProfileDrawerListOpen?: boolean;
	setNavClosed?: () => {};
}

const DrawerList = ({ isProfileDrawerListOpen, setNavClosed }: DrawerListProps): JSX.Element => {
	const { dispatch } = useAuth();

	// Critical data for both the drawer list and other components, do not remove if not introduced elsewhere were it's needed
	const customerInfo = useAppSelector(selectCustomerDetails);
	useApiOnce(fetchCustomerInfo, customerInfo);
	const licenseSubscriptionReommendations = useAppSelector(selectLicenseRecommentaions);
	useApiOnce(fetchLicenseSubscriptionRecommendations, licenseSubscriptionReommendations);

	const userRoles = useAppSelector(selectRoles);

	const { allowedPagesForRole } = useAllowedPagesForUser();

	const filterNavigationList = (navigationsNames: NavigationName[]) =>
		navigationsNames.filter((navigationName) =>
			allowedPagesForRole.find((page) => page.value === navigationName.value),
		);

	const settingsList = filterNavigationList([
		NAVIGATION_NAMES.MY_COMPANY,
		NAVIGATION_NAMES.COLLABORATION,
	]);
	const overviewList = filterNavigationList([
		NAVIGATION_NAMES.DASHBOARD,
		NAVIGATION_NAMES.SECURITY_DASHBOARD,
	]);
	const costList = filterNavigationList([
		NAVIGATION_NAMES.COST_MANAGEMENT,
		NAVIGATION_NAMES.INVOICE,
	]);
	const myEmployeesList = filterNavigationList([
		NAVIGATION_NAMES.MY_PEOPLE,
		NAVIGATION_NAMES.DEVICE_OVERVIEW,
		NAVIGATION_NAMES.HARDWARE,
		NAVIGATION_NAMES.ORDERS,
	]);

	const getAccessTooltipText = (accessibleByProducts: ProductType[]) => {
		let text = `Requires `;
		const accessibleByKomplettRelationship = accessibleByProducts.includes(
			ProductType.HARDWARE_SOLUTION,
		);
		const accessibleByYourEmployees = accessibleByProducts.includes(ProductType.YOUR_EMPLOYEES);
		const accessibleByYourITSystems = accessibleByProducts.includes(
			ProductType.YOUR_IT_SYSTEMS,
		);
		const accessibleWithEither = accessibleByYourEmployees && accessibleByYourITSystems;

		if (accessibleByKomplettRelationship) {
			text += "hardware solution";
			return text;
		}

		if (accessibleWithEither) {
			text += `${IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_EMPLOYEES} or ${IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_IT_SYSTEMS}`;
			return text;
		}

		if (accessibleByYourITSystems) {
			text += IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_IT_SYSTEMS;
			return text;
		}

		if (accessibleByYourEmployees) {
			text += IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_EMPLOYEES;
			return text;
		}

		return text;
	};

	const drawerListItem = (navigationName: NavigationName) => {
		const customerHasAccess = customerHasAccessTo(customerInfo, navigationName.value);
		return (
			<DrawerListItem
				key={navigationName.value}
				navigationName={navigationName}
				disabled={!customerHasAccess}
				toolTip={
					customerHasAccess
						? ""
						: getAccessTooltipText(navigationName.accessibleWithProducts)
				}
				onNavigate={() => setNavClosed && setNavClosed()}
				prefixIcon={navigationName.prefixIcon}
			/>
		);
	};

	const numRecommendations = useAppSelector(selectNumberOfRecommendations);

	return (
		<div className={clsx({ [styles.blurred]: isProfileDrawerListOpen })}>
			<DrawerListSection sectionHeader="Overview">
				{overviewList.map(drawerListItem)}
			</DrawerListSection>
			<DrawerListSection sectionHeader="Cost">
				{[...costList.map(drawerListItem)]}
			</DrawerListSection>
			<DrawerListSection sectionHeader="License management">
				{allowedPagesForRole.includes(NAVIGATION_NAMES.LICENSE_MANAGEMENT) && (
					<>
						<DrawerListItem
							key={NAVIGATION_NAMES.LICENSE_MANAGEMENT.value}
							navigationName={NAVIGATION_NAMES.LICENSE_MANAGEMENT}
							onNavigate={() => {
								dispatch(setActiveLicenseManagementId(""));
								setNavClosed && setNavClosed();
							}}
							prefixIcon={NAVIGATION_NAMES.LICENSE_MANAGEMENT.prefixIcon}
						/>
					</>
				)}
				{allowedPagesForRole.includes(NAVIGATION_NAMES.RECOMMENDATIONS) && (
					<DrawerListItem
						key={NAVIGATION_NAMES.RECOMMENDATIONS.value}
						navigationName={NAVIGATION_NAMES.RECOMMENDATIONS}
						postfixNotificationAmount={numRecommendations}
						onNavigate={() => setNavClosed && setNavClosed()}
						prefixIcon={NAVIGATION_NAMES.RECOMMENDATIONS.prefixIcon}
					/>
				)}
			</DrawerListSection>
			<DrawerListSection
				sectionHeader={getSubHeaderForRole(SUB_HEADER_NAMES.MY_PEOPLE.id, userRoles ?? [])}
			>
				{myEmployeesList.map(drawerListItem)}
			</DrawerListSection>
			<DrawerListSection sectionHeader="Settings">
				{settingsList.map(drawerListItem)}
			</DrawerListSection>
			<DrawerListSection>
				<DrawerListItem
					navigationName={NAVIGATION_NAMES.SUPPORT}
					prefixIcon={<ContactSupportIcon />}
					postfixIcon={<OpenInNewIcon className={styles.endIcon} />}
					onClick={() => openInNewTab(IRONSTONE_SUPPORT_URL)}
					onNavigate={() => setNavClosed && setNavClosed()}
				/>
			</DrawerListSection>
		</div>
	);
};

export { DrawerList };
