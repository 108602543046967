import React from "react";
import { Grid, Skeleton, Typography } from "@mui/material";

import { CostDifferenceWidget } from "components/CostDifferenceWidget";

import styles from "./InfoBadge.module.scss";

import clsx from "clsx";

import { RISK_LEVELS } from "utilities/constants/enums";

interface InfoBadgeProps {
	isLoading: boolean;
	topSectionBackgroundColor?: string;
	label: string | React.ReactNode;
	secondaryLabel?: string;
	link?: React.ReactNode;
	currentCount: string | number;
	ofTotalText?: string | React.ReactNode;
	trendWidgetStartNumber?: number;
	trendWidgetNewNumber?: number;
	level?: RISK_LEVELS;
	borderColor?: string;
	onClick?: () => void;
}

const InfoBadge = ({
	isLoading = false,
	topSectionBackgroundColor,
	level,
	borderColor,
	label,
	secondaryLabel,
	link,
	currentCount,
	ofTotalText,
	trendWidgetStartNumber,
	trendWidgetNewNumber,
	onClick,
}: InfoBadgeProps): JSX.Element => {
	const shouldShowTrendWidget =
		trendWidgetStartNumber !== 0 &&
		trendWidgetStartNumber !== undefined &&
		trendWidgetStartNumber !== null;
	return (
		<Grid className={clsx(styles.paper, !isLoading && styles.clickable)} onClick={onClick}>
			<Grid container className={styles.container}>
				<Grid
					container
					item
					xs={12}
					style={{
						...(level === "low" && { backgroundColor: topSectionBackgroundColor }),
					}}
					className={styles.topSectionContainer}
				>
					<Grid item xs={8} data-testid="infobadge.title">
						<Typography variant="body1" className={styles.title}>
							{label}
						</Typography>
						<Typography variant="caption">{secondaryLabel}</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					item
					xs={12}
					className={clsx(styles.bottomSectionContainer, {
						[styles.grayBorder]: level === RISK_LEVELS.LOW || borderColor === "gray",
						[styles.yellowBorder]:
							level === RISK_LEVELS.MEDIUM || borderColor === "yellow",
						[styles.redBorder]: level === RISK_LEVELS.HIGH || borderColor === "red",
					})}
				>
					{isLoading ? (
						<Grid container direction="column" p={2}>
							<Skeleton variant="text" width={150} height={30} />
						</Grid>
					) : (
						<Grid item xs={12} data-testid="infobadge.count" p={2}>
							<Typography variant="h2">
								{currentCount}
								{ofTotalText && (
									<span
										data-testid="infobadge.count.ofTotal"
										className={styles.ofTotalText}
									>
										{ofTotalText}
									</span>
								)}
							</Typography>
						</Grid>
					)}
					{link && (
						<Grid item xs={12} data-testid="infobadge.link" pl={2} pb={2}>
							{!isLoading ? (
								link
							) : (
								<Skeleton variant="text" width={150} height={28} />
							)}
						</Grid>
					)}
					{shouldShowTrendWidget && (
						<Grid item xs={12} data-testid="infobadge.trend" pl={1.5} pb={2}>
							<Typography>
								<CostDifferenceWidget
									startNumber={trendWidgetStartNumber}
									newNumber={trendWidgetNewNumber}
								/>
							</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export { InfoBadge };
