import { Grid, Snackbar, Alert, Typography, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./GlobalSnackbar.module.scss";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectGlobalSnackbarState, setOpen } from "features/snackbars";
import clsx from "clsx";
import Slide from "@mui/material/Slide";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon

export const GlobalSnackbar = () => {
	const [hover, setHover] = useState(false);
	const { open, duration, snackbarText, snackbarSeverity } =
		useAppSelector(selectGlobalSnackbarState);
	const { dispatch } = useAuth();

	const [timeLeft, setTimeLeft] = useState(duration);

	useEffect(() => {
		if (open) {
			setTimeLeft(duration);
		}
	}, [open, duration]);

	// Effect to decrease timeLeft
	useEffect(() => {
		if (timeLeft > 0 && !hover) {
			const timer = setTimeout(() => {
				setTimeLeft(timeLeft - 60);
			}, 60);
			return () => clearTimeout(timer);
		} else if (timeLeft <= 0) {
			dispatch(setOpen(false));
		}
	}, [timeLeft, hover, dispatch]);

	const progressBarWidth = (timeLeft / duration) * 99;

	let icon = <></>;

	switch (snackbarSeverity) {
		case "error":
			icon = <ErrorOutlineOutlinedIcon />;
			break;
		case "success":
			icon = <CheckCircleOutlinedIcon />;
			break;
		case "info":
			icon = <InfoOutlinedIcon />;
			break;
		case "warning":
			icon = <WarningAmberOutlinedIcon />;
			break;
		default:
			break;
	}

	const handleClose = () => {
		dispatch(setOpen(false));
	};

	return (
		<Grid item xs={12}>
			<Snackbar
				open={open}
				autoHideDuration={null} // disable automatic hiding
				onClose={handleClose}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				className={styles.snackbar}
				TransitionComponent={Slide}
			>
				<div>
					<div
						style={{ width: `${progressBarWidth}%` }}
						className={clsx(styles.progressBar, styles[snackbarSeverity])}
					/>
					<Alert
						variant="filled"
						className={clsx(styles[`${snackbarSeverity}Alert`])}
						icon={icon}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={handleClose}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						<Typography
							variant="description"
							fontWeight={600}
							className={styles.snackbarText}
						>
							{snackbarText}
						</Typography>
					</Alert>
				</div>
			</Snackbar>
		</Grid>
	);
};
