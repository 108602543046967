import { Typography } from "@mui/material";

import styles from "./TotalCostCard.module.scss";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

interface TotalCostCardProps {
	sum: number;
}

const TotalCostCard = ({ sum }: TotalCostCardProps) => {
	return (
		<div className={styles.container}>
			<Typography variant="body1">Total cost</Typography>
			<Typography variant="body1" fontWeight={600}>
				{formatCostString("NOK ", sum, "")}
			</Typography>
		</div>
	);
};

export { TotalCostCard };
