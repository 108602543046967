import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Grid,
	Skeleton,
	IconButton,
	InputBase,
} from "@mui/material";
import { DownloadableDocument } from "components/Common/DownloadableDocument";
import { useApiOnceWithParams, useAppSelector } from "hooks/hooks";
import { selectCustomerDownloadStatuses, selectCustomerFiles } from "features/customer";
import { fetchCustomerDocuments } from "actions/customerActions";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ListFilter } from "components/Common/ListFilter";

import styles from "./CompanyDocuments.module.scss";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import { ALL_DOCUMENT_TYPES_QUERY } from "utilities/constants/constants";
import dayjs from "dayjs";

export const CompanyDocuments = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [filterTypes, setFilterTypes] = useState<string[]>([]);
	const customerFileState = useAppSelector(selectCustomerFiles);

	useApiOnceWithParams(fetchCustomerDocuments, customerFileState, {
		pageName: ALL_DOCUMENT_TYPES_QUERY,
	});

	const filteredDocuments = customerFileState.documents.filter((doc) => {
		return (
			doc.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
			(filterTypes.length === 0 || filterTypes.includes(doc.pageName))
		);
	});

	const handleSearchChange = (event: any) => {
		setSearchTerm(event.target.value);
	};

	return (
		<Grid container className={styles.container}>
			<Grid item xs={12}>
				<Typography variant="h3" fontWeight={500}>
					Documents
				</Typography>
				<Grid item xs={12} className={styles.searchAndFilterContainer}>
					<div className={styles.inputRoot}>
						<IconButton disabled aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							value={searchTerm}
							className={styles.input}
							placeholder="Search documents"
							inputProps={{ "aria-label": "search for documents" }}
							onChange={handleSearchChange}
						/>
					</div>
					<ListFilter
						id="document-type-filter"
						filterValues={filterTypes}
						filterOptions={customerFileState.documentTypes ?? []}
						updateFilter={setFilterTypes}
						noneSelectedLabel="All document types"
					/>
				</Grid>
				<TableContainer className={styles.tableContainer}>
					<Table aria-label="company documents">
						<TableHead>
							<TableRow>
								<TableCell>Document name</TableCell>
								<TableCell>Document type</TableCell>
								<TableCell>Date</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{customerFileState.isLoading ? (
								Array.from(new Array(5)).map((_, index) => (
									<TableRow key={index}>
										<TableCell>
											<Skeleton width="100%" />
										</TableCell>
										<TableCell>
											<Skeleton width="100%" />
										</TableCell>
										<TableCell>
											<Skeleton width="100%" />
										</TableCell>
										<TableCell>
											<Skeleton width="100%" />
										</TableCell>
									</TableRow>
								))
							) : filteredDocuments.length > 0 ? (
								filteredDocuments.map((doc) => {
									return (
										<TableRow key={doc.id} className={styles.tableRow}>
											<TableCell>
												<TruncatableTypography maxCharLength={50}>
													{doc.title}
												</TruncatableTypography>
											</TableCell>
											<TableCell>
												<Grid className={styles.defaultLabel}>
													{doc.pageName}
												</Grid>
											</TableCell>
											<TableCell>
												{dayjs(doc.lastModified).format("DD.MM.YYYY")}
											</TableCell>
											<TableCell>
												<DownloadableDocument
													id={doc.id}
													documentType={doc.pageName}
													documentTitle="Download"
													selector={selectCustomerDownloadStatuses}
													fileType={doc.fileType}
												/>
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<TableRow>
									<TableCell colSpan={4} align="center">
										No documents found
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};
