import {
	Grid,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

import styles from "./LicenseCostTable.module.scss";
import { LicenseSubscriptionCostDetails } from "types";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { TermDurationLabel } from "./TermDurationLabel";
import { ExpiryDateComponent } from "./ExpiryDateComponent";
import clsx from "clsx";
import { HoverTooltip } from "components/Common/Tooltips";
import dayjs from "dayjs";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { selectShowOffice365Discount } from "features/costs";
import { InternalLink } from "components/Common/Links";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import {
	selectLicenseSubscriptions,
	selectSubscribedSkus,
	setActiveLicenseManagementId,
} from "features/licenses/licenses";
import { fetchLicenseSubscriptions, fetchSubscribedSkus } from "actions/licenseActions";

interface LicenseCostTableProps {
	isLoading: boolean;
	groupedSubscriptions: Record<string, LicenseSubscriptionCostDetails[]>;
}

const LicenseCostTable = ({ isLoading, groupedSubscriptions }: LicenseCostTableProps) => {
	const showDiscount = useAppSelector(selectShowOffice365Discount);
	const tableHeadWidths = {
		licenseName: "15%",
		termDuration: "7%",
		commitmentEndDate: "10%",
		totalQuantity: "10%",
		unitPrice: "15%",
		totalDiscount: "0%",
		totalPrice: "10%",
	};

	const { dispatch } = useAuth();

	const { navigateToPage } = useAppNavigate();

	const subscribedSkusState = useAppSelector(selectSubscribedSkus);
	const licenseSubscriptionsState = useAppSelector(selectLicenseSubscriptions);

	useApiOnce(fetchSubscribedSkus, subscribedSkusState);
	useApiOnce(fetchLicenseSubscriptions, licenseSubscriptionsState);

	const handleGoToLicenseDetails = (skuId: string) => {
		navigateToPage(NAVIGATION_NAMES.LICENSE_MANAGEMENT.path);
		// If all data necessary to show the details of the license is loaded, set the active license management id
		// If not, lead the user to the license management page
		if (!subscribedSkusState.isLoading && !licenseSubscriptionsState.isLoading) {
			dispatch(setActiveLicenseManagementId(skuId));
		}
	};

	return (
		<Grid container>
			<TableContainer className={styles.tableContainer}>
				<Table>
					<TableHead className={styles.tableHead}>
						<TableRow>
							<TableCell width={tableHeadWidths.licenseName}>
								<Typography variant="body1" fontWeight={500} noWrap>
									License name
								</Typography>
							</TableCell>
							<TableCell width={tableHeadWidths.termDuration}>
								<Typography variant="body1" fontWeight={500} noWrap>
									Term duration
								</Typography>
							</TableCell>
							<TableCell
								width={tableHeadWidths.commitmentEndDate}
								className={styles.paddedCell}
							>
								<Typography variant="body1" fontWeight={500} noWrap>
									End date
								</Typography>
							</TableCell>
							<TableCell
								width={tableHeadWidths.totalQuantity}
								className={styles.costTypeCell}
							>
								<Typography variant="body1" fontWeight={500} noWrap>
									Total licenses
								</Typography>
							</TableCell>
							<TableCell
								className={styles.costTypeCell}
								width={tableHeadWidths.unitPrice}
							>
								<Typography variant="body1" fontWeight={500}>
									Price per license
								</Typography>
							</TableCell>
							<TableCell className={styles.costTypeCell} width={"10%"}>
								<Typography
									variant="body1"
									fontWeight={500}
									className={clsx({
										[styles.hide]: !showDiscount,
									})}
								>
									Total discount
								</Typography>
							</TableCell>
							<TableCell
								className={styles.costTypeCell}
								width={tableHeadWidths.totalPrice}
							>
								<Typography variant="body1" fontWeight={500}>
									Total price
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{!isLoading && Object.values(groupedSubscriptions)?.length === 0 && (
							<TableRow classes={{ root: styles.rowStyle }} key="noSubscriptions">
								<TableCell colSpan={8}>
									<Typography variant="body1" align="center">
										No subscriptions found
									</Typography>
								</TableCell>
							</TableRow>
						)}
						{!isLoading
							? Object.entries(groupedSubscriptions).map(([key, subscriptions]) => {
									const { subscriptionName } = subscriptions[0]; // common variable across grouped subscriptions
									const prettyDisplayName = subscriptionName?.replace(
										/( - Yearly.*| - Monthly.*)/,
										"",
									);
									return (
										<TableRow key={key} className={styles.rowStyle}>
											<TableCell
												className={clsx({
													[styles.verticalAlignTop]:
														subscriptions.length > 1,
												})}
											>
												<Typography variant="body1" noWrap>
													<InternalLink
														onClick={() =>
															handleGoToLicenseDetails(key)
														}
													>
														{prettyDisplayName}
													</InternalLink>
												</Typography>
											</TableCell>
											<TableCell>
												<div
													className={styles.multipleSubsTermDurationStyle}
												>
													{subscriptions.map(
														({ subscriptionId, termDuration }) => (
															<TermDurationLabel
																label={termDuration}
																key={subscriptionId}
															/>
														),
													)}
												</div>
											</TableCell>
											<TableCell className={styles.paddedCell}>
												<div className={styles.multipleSubsStyle}>
													{subscriptions.map(
														({
															subscriptionId,
															autoRenewEnabled,
															commitmentEndDate,
														}) => (
															<ExpiryDateComponent
																key={subscriptionId}
																autoRenewEnabled={autoRenewEnabled}
																commitmentEndDate={
																	commitmentEndDate
																}
															/>
														),
													)}
												</div>
											</TableCell>
											<TableCell className={styles.totalQuantity}>
												<div className={styles.multipleSubsStyle}>
													{subscriptions.map(
														({ subscriptionId, quantity }) => (
															<Typography
																variant="body1"
																key={subscriptionId}
															>
																{quantity}
															</Typography>
														),
													)}
												</div>
											</TableCell>
											<TableCell className={styles.costTypeCell}>
												<div className={styles.multipleSubsStyle}>
													{subscriptions.map(
														({ subscriptionId, unitPricePreTax }) => (
															<Typography
																variant="body1"
																key={subscriptionId}
															>
																{isNaN(unitPricePreTax)
																	? "-"
																	: formatCostString(
																			"",
																			unitPricePreTax,
																			" ,-",
																	  )}
															</Typography>
														),
													)}
												</div>
											</TableCell>
											<TableCell className={styles.costTypeCell}>
												<div
													className={clsx(styles.multipleSubsStyle, {
														[styles.hide]: !showDiscount,
													})}
												>
													{subscriptions.map(
														({
															subscriptionId,
															discountAmountPreTax,
														}) => (
															<div
																key={subscriptionId}
																className={
																	styles.discountLabelContainer
																}
															>
																<Typography
																	variant="body1"
																	className={styles.discountLabel}
																>
																	{formatCostString(
																		"",
																		discountAmountPreTax,
																		" ,-",
																	)}
																</Typography>
															</div>
														),
													)}
												</div>
											</TableCell>
											<TableCell className={styles.costTypeCell}>
												<div className={styles.multipleSubsStyle}>
													{subscriptions.map(
														({
															subscriptionId,
															totalForecastedPreTax,
															billingCycle,
															commitmentEndDate,
														}) => (
															<div
																key={subscriptionId}
																className={
																	styles.priceAndBillingCycleContainer
																}
															>
																{billingCycle.toUpperCase() ===
																	"ANNUAL" && (
																	<HoverTooltip
																		title={"Billing cycle"}
																		description={`License is billed yearly. \n Next billing date is ${dayjs(
																			commitmentEndDate,
																		).format("DD.MM.YYYY")}`}
																	>
																		<span
																			className={
																				styles.billingCycleLabel
																			}
																		>
																			{billingCycle.toUpperCase() ===
																			"MONTHLY"
																				? "Billed monthly"
																				: "Billed yearly"}
																		</span>
																	</HoverTooltip>
																)}
																<Typography variant="body1">
																	{formatCostString(
																		"",
																		totalForecastedPreTax,
																		" ,-",
																	)}
																</Typography>
															</div>
														),
													)}
												</div>
											</TableCell>
										</TableRow>
									);
							  })
							: new Array(10).fill(0).map((_: any, index: number) => (
									<TableRow key={index}>
										{Object.entries(tableHeadWidths).map(([key, value]) => (
											<TableCell key={key} width={value}>
												<Skeleton
													variant="text"
													height={"5vh"}
													width={"100%"}
												/>
											</TableCell>
										))}
									</TableRow>
							  ))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
};

export { LicenseCostTable };
