import { Alert, Grid, Typography } from "@mui/material";
import { selectManagerStructure } from "features/customer";
import { selectHardwareApprovers } from "features/hardware/hardwareApprovers";
import { selectAllMembers } from "features/users";
import { useAppSelector } from "hooks/hooks";
import { HardwareApproverObject } from "types";

interface NoManagerWarningProps {
	selectedApprovers?: HardwareApproverObject;
}

export const NoManagerWarning = ({ selectedApprovers }: NoManagerWarningProps) => {
	const allMembers = useAppSelector(selectAllMembers);
	const approversState = useAppSelector(selectHardwareApprovers);
	const managerStructure = useAppSelector(selectManagerStructure);

	const anyGlobalApprovers = selectedApprovers?.global && selectedApprovers.global.length > 0;

	// If there are any global approvers, there is no need to show the warning as we can assume that the global approvers will handle the requests
	if (anyGlobalApprovers || approversState.isLoading || managerStructure.isLoading) return null;
	const membersWithoutManager = managerStructure.managers.usersWithNoManager;
	const allMembersWithManager = allMembers?.filter(
		(member) => !managerStructure.managers.usersWithNoManager.includes(member.id),
	);

	const numUsersWithSelectedManager =
		selectedApprovers?.manager?.reduce((acc, approver) => {
			const numDirectManagerOf = managerStructure.managers[approver.id]?.direct;
			return acc + (numDirectManagerOf ? numDirectManagerOf : 0);
		}, 0) ?? 0;

	const nuMusersWithNoApprover =
		allMembersWithManager.length - numUsersWithSelectedManager + membersWithoutManager.length;

	return (
		<Grid item xs={12}>
			<Alert severity="info">
				<Typography variant="body1">
					It is recommended to select at least one <b>"Global approver"</b> to ensure
					users without a manager are able to have their requests handled
					<br />
					<b>Users without an approver:</b> {nuMusersWithNoApprover}
				</Typography>
			</Alert>
		</Grid>
	);
};
