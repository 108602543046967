import { Paper, Grid, Typography } from "@mui/material";

import { SignInsChart } from "../ChartTypes/SignInsChart";
import { CheckSignIns } from "types";

import styles from "./SignIns.module.scss";

interface SignInsProps {
	isLoading: boolean;
	checkSignInsData: CheckSignIns["CheckValues"];
	settings: {
		dateFormat: string;
		failedLabelText: string;
		interruptedLabelText: string;
		successLabelText: string;
		title: string;
	};
	chartStyles: string;
}

const SignIns = ({
	isLoading,
	checkSignInsData,
	settings,
	chartStyles,
}: SignInsProps): JSX.Element => {
	return (
		<Paper className={styles.container}>
			<Grid container>
				<Grid item xs={12} className={styles.titleContainer}>
					<Typography variant="body2" className={styles.fontWeight500}>
						{settings.title}
					</Typography>
				</Grid>
				<Grid item xs={12} className={styles.chartAndLegendContainer}>
					<SignInsChart
						isLoading={isLoading}
						data={checkSignInsData}
						settings={settings}
						chartStyles={chartStyles}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

export { SignIns };
