import { Grid, Typography, Skeleton } from "@mui/material";
import { AutoCompleteWithFetch } from "components/Common/AutocompleteWithFetch";
import styles from "./LicenseGroups.module.scss";
import { useAppSelector } from "hooks/hooks";
import { selectLicenseGroups, selectLicenseSubscriptions } from "features/licenses/licenses";

const LicenseGroups = ({
	availableLicenseGroups,
	initialLicenseGroups,
	chosenLicenseGroups,
	onClick,
}) => {
	const isSelected = (groupId) =>
		chosenLicenseGroups.some((picked) => picked.groupID === groupId);

	const handleUpdateValues = (value) => {
		if (!value?.groupID) {
			// Clearing all picked licenseGroups if the user clears the input
			chosenLicenseGroups.forEach((picked) => onClick(picked, true));
			return;
		}

		const isAlreadyAssigned = initialLicenseGroups.some(
			(licenseGroup) => licenseGroup.groupID === value.groupID,
		);

		// If group was part of the initial license groups, we need to use the group-data from the initial license groups
		if (isAlreadyAssigned) {
			const initialGroup = initialLicenseGroups.find(
				(licenseGroup) => licenseGroup.groupID === value.groupID,
			);
			onClick(initialGroup, isSelected(value.groupID));
		}

		onClick(value, isSelected(value.groupID));
	};

	const { isLoading: isLicenseLoading } = useAppSelector(selectLicenseSubscriptions);
	const { isLoading: isLicenseGroupsLoading } = useAppSelector(selectLicenseGroups);

	return (
		<>
			<Grid item xs={12}>
				<Typography variant="body2">
					<b>Add license group</b>
				</Typography>
				<Typography variant="description">
					Adjustments & purchases might not be applied immediately. Please allow up to 10
					minutes for changes to take effect.
				</Typography>
			</Grid>
			<Grid item className={styles.autocompleteContainer}>
				{!isLicenseLoading && !isLicenseGroupsLoading ? (
					<AutoCompleteWithFetch
						multiple={true}
						getData={() => availableLicenseGroups}
						selectedValues={chosenLicenseGroups}
						propertyToDisplay="groupName"
						propertyToDisplayEnd="memberCount"
						endPropertyExplanation="Users: "
						propertyToIdentify="groupID"
						handleSetValues={handleUpdateValues}
						placeholder="Select one or more license groups"
					/>
				) : (
					<Skeleton variant="rectangular" height={56} radius={4} />
				)}
			</Grid>
		</>
	);
};

export { LicenseGroups };
