import { Box, Divider, FormControl, Stack, TextField, Typography } from "@mui/material";
import { ADPeoplePicker, IAdPeoplePickerOption } from "components/Common/Pickers/ADPeoplePicker";

import { TabPanel } from "./Shared";

import styles from "./RequestForm.module.scss";

export interface IWorkspaceDetailsProps {
	workspaceName: string;
	workspaceDescription: string;
	activeTabIndex: number;
	setWorkspaceName: Function;
	setWorkspaceDescription: Function;
	primaryOwner: IAdPeoplePickerOption | null;
	setPrimaryOwner: Function;
	secondaryOwner: IAdPeoplePickerOption | null;
	setSecondaryOwner: Function;
	members: IAdPeoplePickerOption[] | null;
	setMembers: Function;
	setSelectedValueHasNoTeamsLicense: Function;
	apiConfig: any;
	authToken: string;
}

export const WorkspaceDetails = ({
	activeTabIndex,
	members,
	setMembers,
	primaryOwner,
	setPrimaryOwner,
	secondaryOwner,
	setSecondaryOwner,
	setWorkspaceDescription,
	setWorkspaceName,
	workspaceDescription,
	workspaceName,
	setSelectedValueHasNoTeamsLicense,
	apiConfig,
	authToken,
}: IWorkspaceDetailsProps) => {
	return (
		<TabPanel value={activeTabIndex} index={2}>
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="body2" className={styles.formRow}>
					Please provide some details about your workspace and ownership...
				</Typography>
				<Typography className={styles.steps}>(step 3/4)</Typography>
			</Stack>
			<Divider variant="middle" className={styles.devider} />
			<Box className={styles.workSpaceDetailsWrapper}>
				<FormControl>
					<Stack direction="column" className={styles.marginBottom20}>
						<Typography className={styles.formLabelWrapperWide}>
							What is the name of your workspace?
							<span className={styles.asterisk}>*</span>
						</Typography>
						<TextField
							classes={{
								root: styles.input,
							}}
							inputProps={{
								className: styles.paddedInput,
							}}
							value={workspaceName}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setWorkspaceName(event.target.value);
							}}
						/>
					</Stack>
					<Stack direction="column" className={styles.marginBottom20}>
						<Typography className={styles.formLabelWrapperWide}>
							Describe what this workspace Is for
							<span className={styles.asterisk}>*</span>
						</Typography>
						<TextField
							multiline
							maxRows={4}
							classes={{
								root: styles.input,
							}}
							inputProps={{
								className: styles.multiLineInput,
							}}
							value={workspaceDescription}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setWorkspaceDescription(event.target.value);
							}}
						/>
					</Stack>
					<Stack direction="column">
						<Typography className={styles.formLabelWrapperWide}>
							Who is the primary owner?
							<span className={styles.asterisk}>*</span>
						</Typography>
						<ADPeoplePicker
							label="Primary owner"
							allowMulti={false}
							selectedValue={primaryOwner}
							setSelectedValueHasNoTeamsLicense={setSelectedValueHasNoTeamsLicense}
							onChange={(newVal: any) => {
								setPrimaryOwner(newVal || null);
							}}
							apiConfig={apiConfig}
							authToken={authToken}
						/>
					</Stack>
					<Stack direction="column">
						<Typography className={styles.formLabelWrapperWide}>
							Who is the secondary owner?
							<span className={styles.asterisk}>*</span>
						</Typography>
						<ADPeoplePicker
							label="Secondary owner"
							allowMulti={false}
							selectedValue={secondaryOwner}
							setSelectedValueHasNoTeamsLicense={setSelectedValueHasNoTeamsLicense}
							onChange={(newVal: any) => {
								setSecondaryOwner(newVal || null);
							}}
							apiConfig={apiConfig}
							authToken={authToken}
						/>
					</Stack>
					<Stack direction="column">
						<Typography className={styles.formLabelWrapperWide}>
							Who will you be working with?
							<span className={styles.asterisk}>*</span>
						</Typography>
						<ADPeoplePicker
							label="Members"
							selectedValue={members}
							setSelectedValueHasNoTeamsLicense={() => false} // members doesn't need to have a teams license
							allowMulti={true}
							onChange={(newVal: any) => {
								setMembers(newVal || null);
							}}
							apiConfig={apiConfig}
							authToken={authToken}
						/>
					</Stack>
				</FormControl>
			</Box>
		</TabPanel>
	);
};
