import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { reprocessLicenseAssignment } from "actions/licenseActions";
import {
	fetchLicenseAssignmentErrors,
	fetchLicenseAssignmentErrorsForUser,
} from "actions/licenseAssignmentErrorsActions";
import { RootState } from "store";
import { LicenseAssignmentTableRowData, UserLicenseAssignmentState } from "types";

const licenseAssignmentErrorsAdapter = createEntityAdapter<UserLicenseAssignmentState>({
	selectId: (licenseAssignmentError) => licenseAssignmentError.userId,
});

interface InitialState {
	reprocessing: {
		// used to keep track of users we're currently reprocesing licenses for
		[userId: string]: {
			isReprocessing: boolean;
			reprocessingSuccess: boolean;
		};
	};
	isErrorDialogOpen: boolean;
	isLoading: boolean;
	isFetching: boolean;
}

const licenseAssignmentErrorsSlice = createSlice({
	name: "licenseAssignmentErrors",
	initialState: licenseAssignmentErrorsAdapter.getInitialState({
		isLoading: true,
		isFetching: false,
		isErrorDialogOpen: false,
		reprocessing: {},
	} as InitialState),
	reducers: {
		setIsErrorDialogOpen(state, { payload }) {
			state.isErrorDialogOpen = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLicenseAssignmentErrors.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchLicenseAssignmentErrors.fulfilled, (state, { payload }) => {
				state.isFetching = false;
				state.isLoading = false;
				licenseAssignmentErrorsAdapter.upsertMany(state, payload);
			})
			.addCase(fetchLicenseAssignmentErrors.rejected, (state) => {
				state.isFetching = false;
				state.isLoading = false;
			});
		builder.addCase(fetchLicenseAssignmentErrorsForUser.fulfilled, (state, { payload }) => {
			licenseAssignmentErrorsAdapter.upsertOne(state, payload);
		});
		builder
			.addCase(reprocessLicenseAssignment.pending, (state, { meta }) => {
				const userId = meta.arg.id;
				state.reprocessing[userId] = {
					isReprocessing: true,
					reprocessingSuccess: false,
				};
			})
			.addCase(reprocessLicenseAssignment.fulfilled, (state, { meta }) => {
				const userId = meta.arg.id;
				state.reprocessing[userId] = {
					isReprocessing: false,
					reprocessingSuccess: true,
				};
			})
			.addCase(reprocessLicenseAssignment.rejected, (state, { meta }) => {
				const userId = meta.arg.id;
				state.reprocessing[userId] = {
					isReprocessing: false,
					reprocessingSuccess: false,
				};
			});
	},
});

export const selectLicenseAssignmentErrors = (state: RootState) => state.licenseAssignmentErrors;

export const licenseAssignmentErrorsSelectors = licenseAssignmentErrorsAdapter.getSelectors(
	selectLicenseAssignmentErrors,
);

export const selectReprocessing = (state: RootState) => state.licenseAssignmentErrors.reprocessing;

export const selectUsersWithLicenseErrors = createSelector(
	[licenseAssignmentErrorsSelectors.selectAll],
	(usersWithLicenseErrors) => {
		return usersWithLicenseErrors.filter(
			(assignmentState) => assignmentState.licenseAssignmentErrors.length > 0,
		);
	},
);

export const selectEnrichedErrors = createSelector(
	[licenseAssignmentErrorsSelectors.selectAll],
	(usersWithLicenseErrors) => {
		return usersWithLicenseErrors.reduce(
			(acc: LicenseAssignmentTableRowData[], assignmentState: UserLicenseAssignmentState) => {
				const assignedWithErrors = assignmentState.licenseAssignmentErrors.filter(
					(licenseAssignmentError) => licenseAssignmentError.state === "Error",
				);
				const enrichedLicenseAssignmentErrors = assignedWithErrors.flatMap(
					(licenseAssignmentError) => {
						return {
							userDisplayName: assignmentState.displayName,
							userMail: assignmentState.mail,
							userId: assignmentState.userId,
							otherAssignedLicenses: (assignmentState as any)?.otherAssignedLicenses,
							skuId: licenseAssignmentError.skuId,
							disabledPlans: licenseAssignmentError.disabledPlans,
							assignedByGroup: licenseAssignmentError.assignedByGroup,
							state: licenseAssignmentError.state,
							error: licenseAssignmentError.error,
							lastUpdatedDateTime: licenseAssignmentError.lastUpdatedDateTime,
							licenseDisplayName: (licenseAssignmentError as any)?.licenseDisplayName,
						};
					},
				);

				acc.push(...enrichedLicenseAssignmentErrors);
				return acc;
			},
			[] as LicenseAssignmentTableRowData[],
		);
	},
);

export const selectIsLicenseAssignmentErrorDialogOpen = (state: RootState) =>
	state.licenseAssignmentErrors.isErrorDialogOpen;

export const selectNumLicenseAssignmentErrors = createSelector(
	[licenseAssignmentErrorsSelectors.selectAll],
	(usersWithLicenseErrors) => {
		return usersWithLicenseErrors.reduce(
			(acc: number, assignmentState: UserLicenseAssignmentState) => {
				return acc + assignmentState.licenseAssignmentErrors.length;
			},
			0,
		);
	},
);

export const selectNumErrorsBySku = (skuId: string) =>
	createSelector(
		licenseAssignmentErrorsSelectors.selectAll,
		(licenseAssignmentErrors) =>
			licenseAssignmentErrors.filter((licenseAssignment) =>
				licenseAssignment.licenseAssignmentErrors.some(
					(assignment) => assignment.skuId === skuId && assignment.state === "Error",
				),
			).length,
	);

export const { setIsErrorDialogOpen } = licenseAssignmentErrorsSlice.actions;

export default licenseAssignmentErrorsSlice.reducer;
