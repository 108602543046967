import { Select, MenuItem } from "@mui/material";
import styles from "./FilterDropdownSelect.module.scss";
import { SortBy } from "utilities/constants/enums";

interface Props {
	searchQuery: string;
	onChange: (value: any) => void;
	sortBy: string;
}

const FilterDropdown = ({ searchQuery, onChange, sortBy }: Props) => {
	return (
		<Select
			labelId="department-label"
			id="department-select"
			className={styles.dropdown}
			value={sortBy}
			onChange={onChange}
			inputProps={{ MenuProps: { disableScrollLock: true } }}
			disabled={searchQuery.length > 0}
		>
			<MenuItem
				key={0}
				value={SortBy.NotSorted}
				classes={{ root: styles.inputTextHeader }}
				disabled
			>
				Sort By
			</MenuItem>
			<MenuItem key={1} value={SortBy.Availability} classes={{ root: styles.inputText }}>
				Availability
			</MenuItem>
			<MenuItem key={2} value={SortBy.DiscountAmount} classes={{ root: styles.inputText }}>
				Discount Amount
			</MenuItem>
			<MenuItem
				key={2}
				value={SortBy.DiscountPercentage}
				classes={{ root: styles.inputText }}
			>
				Discount Percentage
			</MenuItem>
			<MenuItem key={3} value={SortBy.NameAscending} classes={{ root: styles.inputText }}>
				Name (A-Z)
			</MenuItem>
			<MenuItem key={4} value={SortBy.NameDescending} classes={{ root: styles.inputText }}>
				Name (Z-A)
			</MenuItem>
			<MenuItem key={5} value={SortBy.PriceLowToHigh} classes={{ root: styles.inputText }}>
				Price (Low-High)
			</MenuItem>
			<MenuItem key={6} value={SortBy.PriceHighToLow} classes={{ root: styles.inputText }}>
				Price (High-Low)
			</MenuItem>
		</Select>
	);
};

export { FilterDropdown };
