import { useLocation } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";

import styles from "./DrawerListItem.module.scss";
import { NavigationName } from "types";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES_BY_PATH } from "utilities/constants/pages";
import clsx from "clsx";
import { useState } from "react";

interface DrawerListItemProps {
	navigationName: NavigationName;
	prefixIcon?: JSX.Element;
	postfixIcon?: JSX.Element;
	postfixNotificationAmount?: number;
	toolTip?: string;
	disabled?: boolean;
	hidden?: boolean;
	onClick?: () => void;
	onNavigate?: () => void;
}

const DrawerListItem = ({
	navigationName,
	prefixIcon,
	postfixIcon,
	postfixNotificationAmount,
	toolTip = "",
	disabled,
	hidden,
	onClick,
	onNavigate,
}: DrawerListItemProps): JSX.Element => {
	const { pathname } = useLocation();
	const { navigateToPage } = useAppNavigate();
	const [isHovered, setIsHovered] = useState(false);

	const onSelect = () => {
		onClick ? onClick() : navigateToPage(navigationName.path);
		onNavigate && onNavigate();
	};

	if (hidden) {
		return <></>;
	}

	const newLabel = false; // Add logic: navigationName === NAVIGATION_NAMES.NEW_NAVIGATION_ITEM if needed, will expose a "New" label on the navigation item

	let isSelected =
		NAVIGATION_NAMES_BY_PATH[pathname as string]?.selector === navigationName.selector;

	const isHighlighted = isSelected || isHovered;

	return (
		<Tooltip
			title={toolTip}
			classes={{ tooltip: styles.popper }}
			PopperProps={{
				modifiers: [
					{
						name: "offset",
						options: {
							offset: [-5, -20],
						},
					},
				],
			}}
		>
			<span onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				<ListItemButton
					key={navigationName.value}
					selected={isSelected}
					onClick={onSelect}
					className={clsx(styles.listItemRoot, {
						[styles.listItemHighlighted]: isHighlighted,
					})}
					disabled={disabled}
				>
					{prefixIcon && (
						<ListItemIcon
							className={clsx({
								[styles.highlightedIcon]: isHighlighted,
								[styles.icon]: !isHighlighted,
							})}
						>
							{prefixIcon}
						</ListItemIcon>
					)}
					<ListItemText
						className={clsx(styles.listItemText, {
							[styles.listItemWithIconText]: prefixIcon,
							[styles.listItemHighlightedText]: isHighlighted,
						})}
						primary={navigationName.label}
						disableTypography={true}
					/>
					{postfixNotificationAmount ? (
						<ListItemIcon className={styles.notificationIcon}>
							<div className={styles.notificationCircle}>
								{postfixNotificationAmount}
							</div>
						</ListItemIcon>
					) : null}
					{newLabel && <div className={styles.newLabel}>New</div>}
					{postfixIcon && <ListItemIcon>{postfixIcon}</ListItemIcon>}
				</ListItemButton>
			</span>
		</Tooltip>
	);
};

export { DrawerListItem };
