import React from "react";
import { Typography, Grid } from "@mui/material";
import TableDialogTable from "./TableDialogTable";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";

const TableDialog = ({ title, tableData, onClose, size }) => {
	return (
		<PrimaryDialog
			title={title}
			primaryButtonText={"Close"}
			primaryAction={onClose}
			onLeaveAction={onClose}
			size={size}
		>
			<Grid container spacing={3}>
				{tableData.map((table, key) => {
					return (
						<React.Fragment key={`${table.title}-${key}`}>
							<Grid item xs={12}>
								<Typography variant="h2">{table.title}</Typography>
								<Typography variant="caption">{table.description}</Typography>
							</Grid>
							<Grid item xs={12}>
								<TableDialogTable
									tableData={table.data}
									tableHeaders={table.headers}
									tableRowProperties={table.properties}
									title={table.title}
								/>
							</Grid>
						</React.Fragment>
					);
				})}
			</Grid>
		</PrimaryDialog>
	);
};

export { TableDialog };
