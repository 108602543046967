import { Grid, Skeleton, Typography } from "@mui/material";

import styles from "./UnassignedTableFooter.module.scss";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

interface UnassignedTableFooterProps {
	availableForRemoval: number;
	totalSavingsBasedOnRemovalQuantity: number;
	totalConfiguredRemovalQuantity: number;
	exceedsUnassignedQuantity: boolean;
	isLoadingPrices: boolean;
}

export const UnassignedTableFooter = ({
	availableForRemoval,
	totalConfiguredRemovalQuantity,
	totalSavingsBasedOnRemovalQuantity,
	exceedsUnassignedQuantity,
	isLoadingPrices,
}: UnassignedTableFooterProps) => {
	const getTotalSavingsContent = () => {
		if (isLoadingPrices) {
			return <Skeleton variant="text" className={styles.totalSavingsLoading} />;
		} else if (exceedsUnassignedQuantity) {
			return "-";
		} else {
			return formatCostString("", totalSavingsBasedOnRemovalQuantity * 12, " kr / year");
		}
	};

	return (
		<Grid item xs={12} className={styles.tableFooter}>
			{
				<Typography variant="body1">
					Removing <b>{totalConfiguredRemovalQuantity}</b> of {availableForRemoval} unused
					licenses
				</Typography>
			}
			{exceedsUnassignedQuantity ? (
				<Typography variant="body1" color="error" fontWeight={500}>
					Exceeded limit of licenses available for removal. Total licenses available for
					removal: {availableForRemoval}
				</Typography>
			) : (
				<Grid container gap={3}>
					<Typography variant="body1">Total savings:</Typography>
					<Typography variant="body1" className={styles.totalSavings}>
						{getTotalSavingsContent()}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};
