import { Bar } from "react-chartjs-2";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { LicenseSubscriptionRecommendation } from "types";

import colors from "styles/utilities/_colors.scss";
import styles from "./LicenseCommitmentDurationSplitChart.module.scss";
import { Grid, Typography } from "@mui/material";

interface InputProps {
	recommendation: LicenseSubscriptionRecommendation;
}

export const LicenseCommitmentDurationSplitChart = ({ recommendation }: InputProps) => {
	const monthly = recommendation.current_P1MCount;
	const annual = recommendation.current_P1YCount;
	const monthlyPercentage = Math.round((monthly / (monthly + annual)) * 100);
	const annualPercentage = 100 - monthlyPercentage;

	const data = {
		labels: ["Annual", "Monthly"],
		datasets: [
			{
				label: "Current",
				data: [annual, monthly],
				backgroundColor: [colors.blueTint1, colors.yellowDark],
				borderColor: "#F2F2F2",
				borderWidth: 1,
				borderRadius: 2,
				barThickness: 40,
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			afterDrawPlugin: false,
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
				border: {
					display: false,
				},
			},
			y: {
				border: {
					display: false,
				},
				ticks: {
					maxTicksLimit: 8,
				},
			},
		},
	};

	return (
		<>
			<div className={styles.chartContainer}>
				<Bar data={data} options={options} id="licenseCommitmentDurationSplitChart" />
			</div>
			<Grid className={styles.labelsContainer}>
				<Grid className={styles.labelContainer}>
					<div className={styles.iconAndTextContainer}>
						<SquareRoundedIcon className={styles.blueIcon} />
						<Typography variant="body1" fontWeight={500} display="inline">
							Annual:
						</Typography>
						<Typography variant="body1" display="inline" ml={1}>
							{annual} license{annual > 1 ? "s" : ""} {`(${annualPercentage}%)`}
						</Typography>
					</div>
					<Typography
						variant="description"
						display="inline"
						ml={5}
						color="text.secondary"
					>
						A 12 month commitment, 20% reduction in price
					</Typography>
				</Grid>
				<Grid className={styles.labelContainer} mt={3}>
					<div className={styles.iconAndTextContainer}>
						<SquareRoundedIcon className={styles.yellowIcon} />
						<Typography variant="body1" fontWeight={500} display="inline">
							Monthly:
						</Typography>
						<Typography variant="body1" display="inline" ml={1}>
							{monthly} license{monthly > 1 ? "s" : ""} {`(${monthlyPercentage}%)`}
						</Typography>
					</div>
					<Typography
						variant="description"
						display="inline"
						ml={5}
						color="text.secondary"
					>
						A flexible term where you can add or remove licenses monthly
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};
