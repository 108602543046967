import { Grid, IconButton, Typography } from "@mui/material";
import styles from "./SearchBanner.module.scss";
import clsx from "clsx";
import { FilterDropdown } from "../FilterDropdown";
import SearchIcon from "@mui/icons-material/Search";
import { SecondaryButton } from "components/Common/Buttons/Button";
import { InputTextField } from "components/Common/InputTextField";
import { FilterDialog } from "../FilterDialog";
import { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { FilterGroupProps } from "../FilterGroup";
import { SelectedItemsSummaryButton } from "./SelectedItemsSummaryButton/SelectedItemsSummaryButton";
import { HardwareDrawerType } from "utilities/constants/enums";

interface Props extends FilterGroupProps {
	type: HardwareDrawerType;
	searchQuery: string;
	sortBy: string;
	isOnboarding?: boolean;
	defaultSort: string;
	setSearchQuery: (value: string) => void;
	setSortBy: (value: string) => void;
	resetRenderCount: () => void;
	isLoading?: boolean;
	cartItemsCount: number;
}

const SearchBanner = ({
	type,
	searchQuery,
	sortBy,
	isOnboarding,
	defaultSort,
	setSortBy,
	resetRenderCount,
	setSearchQuery,
	isLoading,
	selectedGroups,
	selectedManufacturers,
	cartItemsCount,
	...restFilterGroupProps
}: Props) => {
	const [isFilterlistOpen, setFilterlistOpen] = useState(false);
	const numberOfFiltersChosen =
		Object.values(selectedGroups).filter((group) => group).length +
		Object.values(selectedManufacturers).filter((manufacturer) => manufacturer).length;
	return (
		<Grid
			container
			className={clsx(styles.header, {
				[styles.shadow]: isOnboarding,
				[styles.onBoardingOffsett]: isOnboarding,
			})}
		>
			<Grid container className={styles.headerContainer}>
				<InputTextField
					noLabel
					className={styles.searchWrapper}
					placeholder="Search items.."
					value={searchQuery}
					onKeyDown={(e) => {
						if (e.key === "Enter") e.preventDefault();
					}}
					onChange={({ target: { value } }) => {
						if (value.length > 0) setSortBy(defaultSort);
						setSearchQuery(value);
						resetRenderCount();
					}}
					InputProps={{
						startAdornment: (
							<IconButton type="button" aria-label="search">
								<SearchIcon />
							</IconButton>
						),
					}}
				/>
				<Grid container className={styles.filterAndsortContainer}>
					<FilterDropdown
						onChange={({ target: { value } }) => setSortBy(value)}
						searchQuery={searchQuery}
						sortBy={sortBy}
					/>
					<SecondaryButton
						className={clsx(styles.showForMediumScreens, styles.filterButton)}
						text={
							<Grid container>
								<FilterListIcon />
								<Typography>
									{`Filters${
										numberOfFiltersChosen > 0
											? ` (${numberOfFiltersChosen})`
											: ""
									}`}
								</Typography>
							</Grid>
						}
						size="medium"
						variantStyle="text"
						action={() => setFilterlistOpen(true)}
						isDisabled={isLoading}
					/>
					{isFilterlistOpen && (
						<FilterDialog
							resetRenderCount={resetRenderCount}
							setSearchQuery={setSearchQuery}
							onClose={() => setFilterlistOpen(false)}
							selectedGroups={selectedGroups}
							selectedManufacturers={selectedManufacturers}
							{...restFilterGroupProps}
						/>
					)}
					<SelectedItemsSummaryButton type={type} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export { SearchBanner };
