import { Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./SelectedItemChipProps.module.scss";
import clsx from "clsx";

interface SelectedItemChipProps {
	text: string;
	id: string;
	deselect: (id: string) => void;
	type: string;
}

export const SelectedItemChip = ({
	text,
	id,
	deselect: deselectUser,
	type,
}: SelectedItemChipProps) => {
	return (
		<Grid
			item
			className={clsx(styles.selectedItemChip, {
				[styles.groupStyle]: type.toUpperCase() === "GROUP",
			})}
		>
			<Typography variant="caption">{text}</Typography>
			<IconButton size="small" onClick={() => deselectUser(id)}>
				<CloseIcon className={styles.closeIcon} />
			</IconButton>
		</Grid>
	);
};
