import { Grid, Skeleton, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import styles from "./ScheduledActionsCard.module.scss";
import { InternalLink } from "components/Common/Links";

interface ScheduledActionsCardInputProps {
	isScheduledActionsLoading: boolean;
	numScheduledLicenseActionGroups: number;
}

const ScheduledActionsCard = ({
	isScheduledActionsLoading,
	numScheduledLicenseActionGroups,
}: ScheduledActionsCardInputProps) => {
	return (
		<>
			<Typography variant="h3" fontWeight={600}>
				Scheduled actions
			</Typography>
			<Typography variant="description" color="text.secondary" className={styles.subText}>
				{"Actions performed based on recommendations"}
			</Typography>
			<Grid container direction="row" alignItems="center" mt={1.5}>
				<CalendarMonthIcon className={styles.calendarIcon} />

				<Typography variant="h3" className={styles.totalSavings}>
					{!isScheduledActionsLoading ? (
						`${numScheduledLicenseActionGroups} action${
							numScheduledLicenseActionGroups > 1 ||
							numScheduledLicenseActionGroups === 0
								? "s"
								: ""
						}`
					) : (
						<Skeleton variant="text" width={100} height={40} animation="wave" />
					)}
				</Typography>
				<Grid container direction="row" alignItems="center" mt={0.5}>
					<InternalLink>View scheduled actions</InternalLink>
				</Grid>
			</Grid>
		</>
	);
};

export { ScheduledActionsCard };
