import _ from "lodash";
import { utils, write } from "xlsx";

const excelDownload = (data, fileName, sheetNames) => {
	const workbook = utils.book_new();

	data.forEach((sheetData, index) => {
		const sheetName = sheetNames[index] || `Sheet${index + 1}`;
		const detailsSheetName = `${sheetName}-Details`;

		let detailsData = [];

		// Format numbers if needed, flatten nested objects, and handle arrays
		sheetData = sheetData.map((item) => {
			const newItem = flattenObject(item, null, (arrKey, arrData) => {
				const startRow = detailsData.length + 2; // Add 2 for header row and 1-based index
				const endRow = startRow + arrData.length - 1;
				detailsData = detailsData.concat(
					arrData.map((d) => ({ period: item.period, [`${arrKey}_id`]: item.id, ...d })),
				);

				return {
					ref: `'${detailsSheetName}'!A${startRow}:A${endRow}`,
					text: `${arrData.length} detailed items`,
				};
			});

			for (const [key, value] of Object.entries(newItem)) {
				if (_.isNumber(value)) {
					newItem[key] = value.toString().replace(".", ",");
				}
			}

			return newItem;
		});

		// Create a worksheet from the data
		const worksheet = utils.json_to_sheet(sheetData, { cellStyles: true });
		const workSheetWithHyperlinks = formatHyperLinks(worksheet);

		// Add the worksheet with hyperlinks to the workbook
		utils.book_append_sheet(workbook, workSheetWithHyperlinks, sheetName);

		// Create and add the details sheet if there's any data
		if (detailsData.length) {
			// We should also flatten the details data
			const flattenedDetailsData = detailsData.map((item) => flattenObject(item));
			const detailsWorksheet = utils.json_to_sheet(flattenedDetailsData, {
				cellStyles: true,
			});

			utils.book_append_sheet(workbook, detailsWorksheet, detailsSheetName);
		}
	});

	// Write the workbook to a binary string and save it
	const wbout = write(workbook, { bookType: "xlsx", type: "binary" });

	const buffer = new ArrayBuffer(wbout.length);
	const view = new Uint8Array(buffer);
	for (let i = 0; i < wbout.length; i++) {
		view[i] = wbout.charCodeAt(i) & 0xff;
	}

	const blob = new Blob([buffer], { type: "application/octet-stream" });

	const link = document.createElement("a");
	const url = URL.createObjectURL(blob);
	link.href = url;
	link.download = fileName;
	link.style.display = "none";
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const flattenObject = (obj, prefix = "", processArray = null) => {
	if (obj === null) return {};

	return Object.keys(obj).reduce((acc, key) => {
		const newKey = prefix ? `${prefix}.${key}` : key;
		if (Array.isArray(obj[key])) {
			if (processArray) {
				const arrayData = processArray(newKey, obj[key]);
				acc[newKey] = `=HYPERLINK("#${arrayData.ref}", "${arrayData.text}")`;
			}
		} else if (typeof obj[key] === "object") {
			Object.assign(acc, flattenObject(obj[key], newKey, processArray));
		} else {
			acc[newKey] = obj[key];
		}
		return acc;
	}, {});
};

const formatHyperLinks = (worksheet) => {
	// Iterate through all cells in the worksheet
	for (const cellRef in worksheet) {
		// Get the cell object from the worksheet
		const cell = worksheet[cellRef];

		// Check if the cell has a formula that starts with "=HYPERLINK("
		if (cell && cell.v && typeof cell.v === "string" && cell.v.startsWith("=HYPERLINK(")) {
			// Assign the formula to the cell using the f property
			cell.f = cell.v;
			// Set the cell value to the text between the second set of double quotes in the formula
			cell.v = cell.v.match(/"(.*?)"/g)[1].replace(/"/g, "");
		}
	}

	return worksheet;
};

export default excelDownload;
