import { REGEX } from "utilities/constants/constants.ts";
import _ from "lodash";

/* 
    Adds a spce on 1000's in numbers
        Space is because you can copy this to both excel and the calculator and
        it will automatically understand the number without you having to be in
        a country with a comma or dot as the thousandsseparator.
        Also, the international bureau of weights and measures and SI/ISO 31-0
        recommends a space to cope with the uncertanty of countries having their
        own standards on using dot or comma.
*/
export const splitNumberByThousands = (number) => {
	return number.toString().replace(REGEX.SPLIT_NUMBER_BY_THOUSANDS, " ");
};

/*
    1. Rounds the number to an integer
    2. Splits the number by thousands using a space
    3. Adds a prefix to the number
    4. Adds a suffix to the number
    Example: formatCostString("kr ", 100033948, ",-") becomes "kr 100 033 948,-""
    
    NB: If the number comming in is null or undefined it will return 0
           Example: "kr 0,-"
*/
export const formatCostString = (prefix, number, suffix) => {
	if (!_.isNil(number)) {
		const roundedNumber = Math.round(number);
		const splittedNumber = splitNumberByThousands(roundedNumber);
		const finalNumberAsFormattedString = `${prefix}${splittedNumber}${suffix}`;
		return finalNumberAsFormattedString;
	}
	return `${prefix}0${suffix}`;
};

/*
    Formats the number as a scandinavian currency number
    Example: 
        formatScandinavianCostString(10000) becomes
        "kr 10 000,-"
*/
export const formatScandinavianCostString = (number) => {
	const costString = formatCostString("kr ", number, ",-");
	return costString;
};
