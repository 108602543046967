import React from "react";
import { List, ListSubheader } from "@mui/material";

import styles from "./DrawerListSection.module.scss";

interface DrawerListSectionProps {
	sectionHeader?: string;
	children?: React.ReactNode;
}

const DrawerListSection = ({ sectionHeader, children }: DrawerListSectionProps): JSX.Element => (
	<>
		{children && React.Children.toArray(children).length > 0 && (
			<List
				subheader={
					<ListSubheader className={styles.listSubHeaderContainer}>
						{sectionHeader && (
							<span className={styles.subHeaderText}>
								{sectionHeader.toUpperCase()}
							</span>
						)}
					</ListSubheader>
				}
			>
				{children}
			</List>
		)}
	</>
);

export { DrawerListSection };
