import * as React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import styles from "./UpdatesCard.module.scss";
import { ExternalLink, InternalLink } from "components/Common/Links";
import { CARD_STATUS_COLOR } from "utilities/constants/enums";

interface UpdatesCardProps {
	overallStatus: CARD_STATUS_COLOR;
	title: string;
	linkTo: string;
	isLinkExternal: boolean;
	linkLabel: string;
	children?: React.ReactElement | React.ReactElement[];
}

const UpdatesCard = ({
	overallStatus,
	title,
	linkTo,
	isLinkExternal,
	linkLabel,
	children,
}: UpdatesCardProps): JSX.Element => {
	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Paper
						className={clsx([styles.container, styles[`${overallStatus}Status`]])}
						elevation={1}
					>
						<Grid
							container
							spacing={3}
							classes={{ root: styles.gridContainer }}
							alignContent="flex-start"
						>
							<Grid item xs={12}>
								<Typography
									align="center"
									variant="h2"
									classes={{ root: styles.title }}
								>
									{title}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								{children}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12} className={styles.linkFooterContainer}>
					<div className={styles.linkFooter}>
						{isLinkExternal ? (
							<ExternalLink
								textDecoration="underline"
								variant="body2"
								href={linkTo}
								prefixedExternalLinkIcon
							>
								{linkLabel}
							</ExternalLink>
						) : (
							<InternalLink textDecoration="underline" variant="body2" href={linkTo}>
								{linkLabel}
							</InternalLink>
						)}
					</div>
				</Grid>
			</Grid>
		</>
	);
};

export { UpdatesCard };
