import { Skeleton, Typography } from "@mui/material";

import SquareRoundedIcon from "@mui/icons-material/SquareRounded";

import styles from "./LegendItem.module.scss";
import clsx from "clsx";
import CircleIcon from "@mui/icons-material/Circle";

interface LegendItemProps {
	mainLabel: React.ReactNode;
	subLabel?: string;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	name?: string;
	color?: string;
	isLoading?: boolean;
	noPadding?: boolean;
	round?: boolean;
}

export const LegendItem = ({
	mainLabel,
	subLabel,
	onClick,
	color,
	isLoading,
	noPadding,
	round = false,
}: LegendItemProps) => {
	const legendIsString = typeof mainLabel === "string";
	return (
		<div
			className={clsx(styles.indicatorContainer, {
				[styles.padding]: !noPadding,
				[styles.clickable]: !!onClick && !isLoading,
			})}
			onClick={(e) => !isLoading && onClick && onClick(e)}
		>
			<div
				className={clsx({
					[styles.standardLegend]: legendIsString,
					[styles.componentLegend]: !legendIsString,
				})}
			>
				{round ? (
					<CircleIcon style={{ color }} className={styles.legendIcon} />
				) : (
					<SquareRoundedIcon style={{ color }} className={styles.legendIcon} />
				)}
				{legendIsString ? (
					<div className={styles.legendTextContainer}>
						<div className={styles.mainLabelContainer}>
							{!isLoading ? (
								<Typography className={styles.legendText}>{mainLabel}</Typography>
							) : (
								<Skeleton variant="text" className={styles.mainLabelSkeleton} />
							)}
						</div>
						{subLabel && (
							<>
								{!isLoading ? (
									<Typography
										variant="subtitle1"
										className={styles.legendSubText}
									>
										{subLabel}
									</Typography>
								) : (
									<Skeleton variant="text" className={styles.subLabelSkeleton} />
								)}
							</>
						)}
					</div>
				) : (
					<>{mainLabel}</>
				)}
			</div>
		</div>
	);
};
