import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { userFormValidation } from "utilities/userFormValidation";
import styles from "./Input.module.scss";

const Input = ({ disabled, defaultValue = "", errors, register, name, label }) => (
	<Grid container classes={{ root: !errors[name] && styles.input }}>
		<InputLabel>
			<Typography variant="body1">{label}</Typography>
		</InputLabel>
		<TextField
			disabled={disabled}
			classes={{ root: styles.errorText }}
			variant="outlined"
			name={name}
			defaultValue={defaultValue}
			fullWidth
			size="small"
			InputProps={{
				classes: {
					input: styles.formTextInput,
				},
			}}
			error={!!errors[name]}
			helperText={userFormValidation[name]?.getHelperText(errors) ?? ""}
			{...register(name, userFormValidation[name]?.validationProps ?? {})}
		/>
	</Grid>
);

export { Input };
