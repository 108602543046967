import { useEffect, useState } from "react";
import { Grid, Typography, Stack, Skeleton } from "@mui/material";
import { WorkspaceConfigurator } from "../WorkspaceConfigurator";
import { ArchivalSettingsConfigurator } from "../ArchivalSettingsConfigurator";
import { PrimaryButton } from "components/Common/Buttons/Button";

import styles from "./WorkspaceSettingsForm.module.scss";
import useRole from "utilities/roleUtils/roleCheck";

const WorkspaceSettingsForm = ({
	isDataLoaded,
	workspaceSettings,
	internalCollaborationData,
	externalCollaborationData,
	internalProjectData,
	externalProjectData,
	updateCustomerData,
	archivalSettingsData,
	updateArchivalSettingsData,
	saveCollaborationSettings,
}) => {
	const onSave = () => {
		saveCollaborationSettings();
	};
	const { isActionRole } = useRole();

	useEffect(() => {
		if (isDataLoaded) {
			setIsLoading(false);
		}
	}, [isDataLoaded]);

	const [isLoading, setIsLoading] = useState(true);

	return (
		<Grid container xs={12} item className={styles.collaborationStructureCreatorContainer}>
			<Grid
				container
				item
				xs={12}
				className={styles.headerSectionContainer}
				alignItems="center"
			>
				<Grid container item xs={8}>
					<Stack direction={"row"} className={styles.headerSectionWrapper}>
						<Stack
							direction={"column"}
							style={{ minWidth: "200px" }}
							justifyContent="center"
						>
							<Typography variant="body1">Lifecycle & archiving</Typography>
							<Typography variant="caption">
								Archiving after days of inactivity
							</Typography>
						</Stack>
						{isLoading ? (
							<Grid item xs={12}>
								<Skeleton variant="wave" height={60} width={400} />
							</Grid>
						) : (
							<ArchivalSettingsConfigurator
								handleChange={updateArchivalSettingsData}
								archivalSettings={archivalSettingsData}
							/>
						)}
					</Stack>
				</Grid>
			</Grid>
			<Grid
				container
				xs={12}
				className={styles.collaborationStructureCreatorContentContainer}
			>
				<Grid container xs={12}>
					<Grid
						container
						item
						xs={12}
						direction="row"
						className={styles.titleSectionContainer}
					>
						<Grid item xs={4} className={styles.titleSectionElement}>
							<Typography variant="body1">Collaboration types (Required)</Typography>
							<Typography variant="caption">
								Select which type of collaboration are available for end users
							</Typography>
						</Grid>
						<Grid item xs={4} className={styles.titleSectionElement}>
							<Typography variant="body1">
								Add collaboration creators (Required)
							</Typography>
							<Typography variant="caption">
								Define who in the organisation is able to create workspaces
							</Typography>
						</Grid>
						<Grid item xs={4} className={styles.titleSectionElement}>
							<Typography variant="body1">Approvers</Typography>
							<Typography variant="caption">
								Define if approval is required for the collaboration.
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				{
					<WorkspaceConfigurator
						title="Internal Collaboration"
						description="Cross-organisation or departmental, non-project"
						dataTypeName={"internalCollaboration"}
						creators={internalCollaborationData?.creators}
						approvers={internalCollaborationData?.approvers}
						enabled={workspaceSettings?.internalCollaborationEnabled}
						handleChange={updateCustomerData}
						isDataLoaded={isDataLoaded}
					/>
				}
				{
					<WorkspaceConfigurator
						title="Internal Projects"
						dataTypeName={"internalProject"}
						description="Cross-organisation or departmental"
						creators={internalProjectData?.creators}
						approvers={internalProjectData?.approvers}
						enabled={workspaceSettings?.internalProjectEnabled}
						handleChange={updateCustomerData}
						isDataLoaded={isDataLoaded}
					/>
				}
				{
					<WorkspaceConfigurator
						title="External Collaboration"
						description="With customers, partners, suppliers etc. non project"
						dataTypeName={"externalCollaboration"}
						creators={externalCollaborationData?.creators}
						approvers={externalCollaborationData?.approvers}
						enabled={workspaceSettings?.externalCollaborationEnabled}
						handleChange={updateCustomerData}
						isDataLoaded={isDataLoaded}
					/>
				}
				{
					<WorkspaceConfigurator
						title="External Projects"
						description="With customers, partners, suppliers etc."
						dataTypeName={"externalProject"}
						creators={externalProjectData?.creators}
						approvers={externalProjectData?.approvers}
						enabled={workspaceSettings?.externalProjectEnabled}
						handleChange={updateCustomerData}
						isDataLoaded={isDataLoaded}
					/>
				}
			</Grid>
			<PrimaryButton
				text="Save"
				size="large"
				action={onSave}
				variantStyle="contained"
				isDisabled={!isActionRole}
				disabledDescription="You do not have the required permissions to save the settings"
			/>
		</Grid>
	);
};

export { WorkspaceSettingsForm };
