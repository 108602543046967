import { SCOPES } from "utilities/constants/constants.ts";
import { get } from "utilities/rest.ts";

const ensureEnterpriseAppApiPermissions = async (instance, account, authConfig) => {
	const { tokenType, accessToken } = await instance.acquireTokenSilent({
		scopes: [SCOPES.FUNCTION_READ],
		account,
	});

	// Returns JSON with {"missingPermissions:["missingPermissionName"], "excessivePermissions":["excessivePermissionName"]}
	const customerEnterpriseAppPermissionsCheck = await get(
		authConfig.FUNCTION_API_ENDPOINTS.GET_COMPARE_APP_PERMISSIONS(),
		tokenType,
		accessToken,
		"application/json",
		{
			"Ocp-Apim-Subscription-Key": authConfig.OCP_APIM_SUBSCRIPTION_KEY,
		},
	);

	// If the status provided by the GET_COMPARE_APP_PERMISSIONS is not "Ok" we return the missing permissions
	if (customerEnterpriseAppPermissionsCheck?.data.status !== "Ok") {
		return customerEnterpriseAppPermissionsCheck?.data;
	}

	if (customerEnterpriseAppPermissionsCheck?.data) {
		return customerEnterpriseAppPermissionsCheck.data;
	} else if (!customerEnterpriseAppPermissionsCheck) {
		// If this is blank the user havn't consented for the application before
		return { missingPermissions: ["All"], excessivePermissions: [""] };
	}
};

export { ensureEnterpriseAppApiPermissions };
