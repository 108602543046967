import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

import styles from "./RemoveLicensesCheckout.module.scss";

import { LicenseRemovalRow } from "./LicenseRemovalRow/LicenseRemovalRow";
import {
	ConfiguredQuantityRemoval,
	IdleUsersDetails,
	LicenseSubscriptionRecommendation,
} from "types";
import { useAppSelector, useAuth } from "hooks/hooks";
import {
	setConfirmationMailContent,
	setRecommendationActionBodies,
} from "features/recommendations";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { useEffect } from "react";
import { MailType } from "utilities/constants/enums";
import dayjs from "dayjs";
import { selectCustomerDetails } from "features/customer";
import { buildRecommendationActionBody } from "utilities/licenseUtils/licenseActionUtils";
import EmailInput from "components/Common/EmailInput/EmailInput";
import { selectRecipientMailState } from "features/scheduledActions";

interface RemoveLicensesCheckoutInputProps {
	recommendation: LicenseSubscriptionRecommendation;
	selectedUsersDetails: IdleUsersDetails[];
	configuredRemovalQuantities: ConfiguredQuantityRemoval[];
}

export const RemoveLicensesCheckout = ({
	recommendation,
	selectedUsersDetails,
	configuredRemovalQuantities,
}: RemoveLicensesCheckoutInputProps) => {
	const {
		dispatch,
		auth: { account },
	} = useAuth();
	const customerInfo = useAppSelector(selectCustomerDetails);

	const totalSavings = configuredRemovalQuantities.reduce(
		(acc, curr) => acc + curr.savings * Math.abs(curr.removalQuantity),
		0,
	);

	// We need to send a confirmation mail after the user has completed the checkout
	// The below useEffect sets the body needed
	useEffect(() => {
		dispatch(
			setConfirmationMailContent({
				mailType: MailType.SoftDeleteUsersAction,
				body: {
					totalSavings: Math.round(totalSavings),
					usersDetails: selectedUsersDetails.map(({ displayName, skusToRemove }) => {
						return {
							name: displayName,
							numLicensesRemoved: skusToRemove.length,
						};
					}),
					licenseDetails: configuredRemovalQuantities.map(
						({ subscription, removalQuantity, savings }) => {
							return {
								name: recommendation.subscriptionOfferName,
								numLicensesRemoved: removalQuantity,
								costSavings: Math.round(savings),
								scheduledDate: dayjs(subscription?.commitmentEndDate).format(
									"DD.MM.YYYY",
								),
							};
						},
					),
				},
			}),
		);

		/* eslint-disable react-hooks/exhaustive-deps */
	}, [dispatch, configuredRemovalQuantities, JSON.stringify(selectedUsersDetails)]);

	const { mail } = useAppSelector(selectRecipientMailState);

	useEffect(() => {
		const changes = {
			softDeleteUsers: selectedUsersDetails,
			removeLicenses: configuredRemovalQuantities,
		};

		const actions = buildRecommendationActionBody({
			customerName: customerInfo.departmentName,
			account,
			recommendation: recommendation,
			changes,
			confirmationMailRecipient: mail,
		});

		dispatch(setRecommendationActionBodies(actions));
	}, [configuredRemovalQuantities, customerInfo.departmentName, account, recommendation]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<>
			<Grid container>
				{selectedUsersDetails.length > 0 && (
					<>
						<Typography variant="body1" fontWeight={500} mb={1}>
							Soft deleted users
						</Typography>
						<Grid container className={styles.tableSectionContainer}>
							<TableContainer className={styles.userDetailsTableContainer}>
								<Table className={styles.table}>
									<TableHead className={styles.tableHead}>
										<TableRow className={styles.tableHeadRow}>
											<TableCell>User</TableCell>
											<TableCell>License</TableCell>
										</TableRow>
									</TableHead>
									<TableBody className={styles.tableBody}>
										{selectedUsersDetails.map(
											({ displayName, userPrincipalName }) => {
												return (
													<TableRow
														key={userPrincipalName}
														className={styles.userRow}
													>
														<TableCell>
															<Typography
																variant="description"
																fontWeight={400}
															>
																{displayName}
															</Typography>
															<br />
															<Typography
																variant="description"
																color="text.secondary"
																fontWeight={400}
															>
																{userPrincipalName}
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																variant="description"
																fontWeight={400}
															>
																{
																	recommendation.subscriptionOfferName
																}
															</Typography>
														</TableCell>
													</TableRow>
												);
											},
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</>
				)}
			</Grid>
			<Grid item xs={12} mt={6}>
				<Typography variant="body1" fontWeight={500} mb={1}>
					Removed licenses
				</Typography>

				<Grid container direction={"row"} className={styles.bottomTableSectionContainer}>
					<TableContainer className={styles.tableContainer}>
						<Table className={styles.table}>
							<TableHead className={styles.tableHead}>
								<TableRow className={styles.tableHeadRow}>
									<TableCell>License</TableCell>
									<TableCell>Scheduled for removal (end date)</TableCell>
									<TableCell>Term Duration</TableCell>
									<TableCell align="right">Savings</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{configuredRemovalQuantities.map((removal) => (
									<LicenseRemovalRow
										key={removal.subscription.id}
										subscriptionId={removal.subscription.id}
										recommendation={recommendation}
										removalQuantity={Math.abs(removal.removalQuantity)}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid container className={styles.totalSavingsContainer}>
					<Typography variant="body1" fontWeight={500} display={"inline"}>
						Total savings
					</Typography>
					<Typography variant="body1" className={styles.potentialSavings}>
						{formatCostString("", totalSavings, " ,- / year")}
					</Typography>
				</Grid>
				<Grid container className={styles.emailInputContainer}>
					<EmailInput
						required={false}
						explanationText="Recommendation action receipt"
						className={styles.emailInput}
					/>
				</Grid>
			</Grid>
		</>
	);
};
