import { Grid } from "@mui/material";
import clsx from "clsx";
import { PrimaryButton, SecondaryButton } from "components/Common/Buttons/Button";
import { useEffect, useState } from "react";
import { CostSavingsType } from "utilities/constants/enums";

import styles from "./LicenseDrawerStepper.module.scss";
import { TERMS_AND_CONDITIONS_TYPES } from "components/Common/Dialogs/TermsAndConditionsDialog/TermsAndConditions";
import { selectActiveStep, setActiveStep } from "features/scheduledActions";
import { useAppSelector, useAuth } from "hooks/hooks";
import { AgreeToTerms } from "components/Common/AgreeToTerms";

interface StepperInputProps {
	type: CostSavingsType;
	disablePrimaryAction: boolean;
	handleDrawerStateChange: (open: boolean, type: CostSavingsType) => void;
	handleCheckout: (costSavingsType: CostSavingsType) => void;
}

export const LicenseDrawerStepper = ({
	type,
	disablePrimaryAction,
	handleDrawerStateChange,
	handleCheckout,
}: StepperInputProps) => {
	const { dispatch } = useAuth();
	const [agreedToTerms, setAgreedToTerms] = useState(false);
	const activeStep = useAppSelector(selectActiveStep);

	useEffect(() => {
		setAgreedToTerms(false);
	}, [activeStep]);

	const onHandleCheckout = () => {
		dispatch(setActiveStep(activeStep + 1));
		handleCheckout(type);
	};

	const getActionsForType = (type: CostSavingsType) => {
		switch (type) {
			case CostSavingsType.RemoveUnused:
				return removeIdleActions();
			case CostSavingsType.TermDurationChange:
				return requestWalkthroughActions();
			default:
				return null;
		}
	};

	const requestWalkthroughActions = () => {
		switch (activeStep) {
			case 0:
				return (
					<Grid className={styles.stepperActions}>
						<div className={clsx(styles.stepperActions, styles.firstStepStyle)}>
							<PrimaryButton
								text={"Request walkthrough"}
								action={onHandleCheckout}
								variantStyle="contained"
								size="large"
							/>
						</div>
					</Grid>
				);
			case 1:
				return null;
			default:
				return null;
		}
	};

	const removeIdleActions = () => {
		switch (activeStep) {
			case 0:
				return (
					<Grid className={styles.stepperActions}>
						<div className={clsx(styles.stepperActions, styles.firstStepStyle)}>
							<SecondaryButton
								text={"Cancel"}
								action={() =>
									handleDrawerStateChange(false, CostSavingsType.RemoveUnused)
								}
								variantStyle="outlined"
								size="large"
							/>
							<PrimaryButton
								text={"Next: License removal"}
								action={() => dispatch(setActiveStep(activeStep + 1))}
								variantStyle="contained"
								size="large"
								isDisabled={disablePrimaryAction}
							/>
						</div>
					</Grid>
				);
			case 1:
				return (
					<Grid className={styles.stepperActions}>
						<div className={clsx(styles.stepperActions, styles.firstStepStyle)}>
							<SecondaryButton
								text={"Back"}
								action={() => dispatch(setActiveStep(activeStep - 1))}
								variantStyle="outlined"
								size="large"
							/>
							<PrimaryButton
								text={"Next: Summary"}
								action={() => dispatch(setActiveStep(activeStep + 1))}
								variantStyle="contained"
								size="large"
								isDisabled={disablePrimaryAction}
							/>
						</div>
					</Grid>
				);
			case 2:
				return (
					<Grid className={styles.stepperActions}>
						<Grid
							container
							justifyContent={"space-between"}
							alignItems={"center"}
							p={3}
						>
							<AgreeToTerms
								type={TERMS_AND_CONDITIONS_TYPES.LICENSE_COST_OPTIMIZATION}
								agreedToTerms={agreedToTerms}
								setAgreedToTerms={setAgreedToTerms}
								position={"flex-end"}
							/>
							<SecondaryButton
								text={"Back"}
								action={() => dispatch(setActiveStep(activeStep - 1))}
								variantStyle="outlined"
								size="large"
							/>
							<PrimaryButton
								text={"Checkout"}
								action={onHandleCheckout}
								variantStyle="contained"
								isDisabled={!agreedToTerms}
								size="large"
							/>
						</Grid>
					</Grid>
				);
			case 3:
				return null;
			default:
				return null;
		}
	};

	return getActionsForType(type);
};
