import { Dictionary } from "@reduxjs/toolkit";
import { LicensePrice, LicensePurchase } from "types";

/* 
    Function that accepts an array of licenses, the licensePrice object, and calculates the total price of the licenses 
    This function is capable of normalizing the price to monthly billing cycle if some of the licenses are billed annually 

    If all licenses are monthly, "month" is the cost period. 
    If all licenses are yearly, "year" is the cost period. 
    If a combination, "month" is the cost period with annual prices normalized for that period. 
*/
export const getSummarizedPriceObject = (
	licenses: LicensePurchase[],
	licensePrices: Dictionary<LicensePrice>,
) => {
	const costPeriod = licenses.every(
		(license) => licensePrices[license.costIdentifier]?.CostPeriod === "year",
	)
		? "year"
		: "month";

	const totalPrice = licenses.reduce((acc, license) => {
		const isAnnual = licensePrices[license.costIdentifier]?.CostPeriod === "year";
		const shouldShowMonthlyPrice = costPeriod === "month";
		const price = licensePrices[license.costIdentifier]?.UnitPriceAfterDiscount ?? 0;
		const quantity = license.quantity;
		const normalizedPrice = isAnnual && shouldShowMonthlyPrice ? price / 12 : price;
		return acc + normalizedPrice * quantity;
	}, 0);

	return { totalPrice, costPeriod };
};
