import { useState, useEffect } from "react";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import { CheckCircleOutlined } from "@mui/icons-material";
import { IRONSTONE_PERMISSIONS_PAGE } from "utilities/constants/constants";

import { logHotjarEvent } from "utilities/hotjar/logHotjarEvent";

import styles from "./ConsentDialog.module.scss";
import { ExternalLink } from "components/Common/Links";
import { HotjarEventTypes } from "utilities/constants/enums";

interface ConsentDialogProps {
	isMissingPermissions: boolean;
	portalIsInTenant: boolean;
	userRoles: {
		userHasCorrectRoles: boolean;
		checkingUserRoles: boolean;
	};
	redirectUrl: string;
	onLogout: () => void;
}

const ConsentDialog = ({
	isMissingPermissions,
	portalIsInTenant,
	userRoles,
	redirectUrl,
	onLogout,
}: ConsentDialogProps) => {
	// We should wait delayInMs before rendering the dialog to avoid a flickering effect
	const delayInMs = 100;
	const [shouldRender, setShouldRender] = useState(false);

	const redirectToAdminConsent = async () => {
		window.location.replace(redirectUrl);
		return Promise.resolve();
	};

	useEffect(() => {
		setTimeout(() => {
			logHotjarEvent({
				eventType: HotjarEventTypes.SHOW,
				eventText: "consent_dialog",
			});
			setShouldRender(true);
		}, delayInMs);
	}, []);

	const permissionInformation = () => {
		const missingUserPermissions = (
			<div>
				<b> Missing user access </b>
				<br />
				<div>
					It looks like you don't have access to configure this application. Please check
					if you have logged in with a <b>global administrator </b>account or contact your
					administrator for help
				</div>
			</div>
		);

		const correctUserRoles = (
			<div>
				Updated permissions will give you access to:
				<ul className={styles.permissionList}>
					<li>
						<CheckCircleOutlined className={styles.checkCircle} />
						<b> Onboard</b> and <b>offboard</b> users
					</li>
					<li>
						<CheckCircleOutlined className={styles.checkCircle} />
						<b> Buy</b> and <b>add licenses</b> to users
					</li>
					<li>
						<CheckCircleOutlined className={styles.checkCircle} />
						<b> Dashboards</b> views: Overview, cost and security
					</li>
					<li>
						<CheckCircleOutlined className={styles.checkCircle} />
						<b> Collaboration</b> settings
					</li>
				</ul>
				<p>
					<ExternalLink
						variant="body1"
						href={IRONSTONE_PERMISSIONS_PAGE}
						prefixedExternalLinkIcon
						textDecoration="underline"
					>
						Read more about why we need these permissions
					</ExternalLink>
					<br />
					Click "View permissions" to be redirected to view and update our permissions.
				</p>
			</div>
		);

		return (
			<Grid container direction="row" alignItems="center">
				<Grid item>
					{!userRoles.checkingUserRoles ? (
						userRoles.userHasCorrectRoles ? (
							<>{correctUserRoles}</>
						) : (
							<>{missingUserPermissions}</>
						)
					) : null}

					{userRoles.checkingUserRoles && (
						<>
							<Grid container direction="row" alignItems="center">
								<Grid item ml={3}>
									<CircularProgress size={30} />
								</Grid>
								<Grid item ml={3}>
									<span>Checking user access</span>
								</Grid>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
		);
	};

	// In order to let the user focus solely on the dialog, we hide the rest of the page
	const showBackdrop = !portalIsInTenant;

	return (
		<>
			{shouldRender && (
				<Backdrop open={showBackdrop} className={styles.backdrop}>
					<PrimaryDialog
						title={"We need to update our permissions"}
						primaryAction={
							userRoles.userHasCorrectRoles ? redirectToAdminConsent : onLogout
						}
						size="medium"
						disabledActions={userRoles.checkingUserRoles}
						primaryButtonText={
							userRoles.userHasCorrectRoles ? "View permissions" : "Logout"
						}
						removeContentMarginAndPadding={true}
					>
						<>
							<Grid container className={styles.ingressContainer}>
								{
									<>
										<p>
											To give you the best portal experience, we need you to
											update our permissions <br />
										</p>
									</>
								}
							</Grid>
							<Grid container p={3} pb={0}>
								{(isMissingPermissions || !portalIsInTenant) && (
									<Grid item>{permissionInformation()}</Grid>
								)}
							</Grid>
						</>
					</PrimaryDialog>
				</Backdrop>
			)}
		</>
	);
};

export { ConsentDialog };
