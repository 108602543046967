import { Paper, List, ListItem } from "@mui/material";
import React from "react";

import styles from "./ServiceStatuses.module.scss";

interface ServiceStatusesProps {
	children?: React.ReactElement | React.ReactElement[];
}

const ServiceStatuses = ({ children }: ServiceStatusesProps): JSX.Element => {
	return (
		<>
			<Paper classes={{ root: styles.paperContainer }}>
				<List className={styles.listContainer}>
					{React.Children.map(children, (child) => (
						<ListItem divider={true}>{child}</ListItem>
					))}
				</List>
			</Paper>
		</>
	);
};

export { ServiceStatuses };
