import { ReactElement } from "react";

import styles from "./AddToCartButton.module.scss";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { addItemToCart } from "features/hardware/products";
import { useAppDispatch } from "hooks/hooks";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { setHardwareDrawerOpen } from "features/hardware/hardwarePage";
import { HardwareDrawerType } from "utilities/constants/enums";
import { theme } from "theme";

export interface AddToCartButtonProps {
	sku: string;
}

function AddToCartButton({ sku }: AddToCartButtonProps): ReactElement {
	const dispatch = useAppDispatch();
	return (
		<PrimaryButton
			size="medium"
			className={styles.addToCartButton}
			text="Add to cart"
			icon={<AddShoppingCartIcon />}
			variantStyle="contained"
			action={() => {
				dispatch(
					setHardwareDrawerOpen({
						drawerType: HardwareDrawerType.Cart,
						forceOpen: window.innerWidth > theme.breakpoints.values.md ? true : false,
					}),
				);
				dispatch(addItemToCart(sku));
			}}
		/>
	);
}

export { AddToCartButton };
