import _ from "lodash";

export const toCapitalizedWords = (name) => {
	var words = name.match(/[A-Za-z][a-z]*/g) || [];

	return words.map(capitalize).join(" ");
};

export const capitalize = (word) => {
	if (!word) return "";
	word = word.toString();
	return word.charAt(0).toUpperCase() + word.substring(1);
};

export const mapObjectStringValues = (obj, fn) =>
	Object.keys(obj).map((k) => (obj[k] = typeof obj[k] == "string" ? fn(obj[k]) : obj[k]));

export const removeAllSpaces = (str) => str.replaceAll(" ", "");

export const removeBetweenBrackets = (str) => str.replace(/\[.*?\]/g, "");

export const trimAndRemoveDuplicateSpacesBetweenWords = (text) =>
	text.split(" ").filter(Boolean).join(" ");

export const prepareAndValidateInputUsingRegex = (regex, prepare) => (text) => {
	if (_.isEmpty(text)) return true;
	return regex.test(prepare(text));
};

export const validateInputUsingRegex = (regex, shouldRemoveAllSpaces = false) =>
	prepareAndValidateInputUsingRegex(
		regex,
		shouldRemoveAllSpaces ? removeAllSpaces : trimAndRemoveDuplicateSpacesBetweenWords,
	);

export const isLetter = (c) => c.toLowerCase() != c.toUpperCase(); /* eslint eqeqeq: 0 */

export const handleMidDisplayNameBreak = (displayName, description) =>
	displayName + _.takeWhile(description.slice(displayName.length), isLetter).join("");

export const handleName = (displayName, description) => {
	if (displayName.length === 0 || description.length === 0) return "";

	const hasTrailingCharacterAfterDisplayName = isLetter(description[displayName.trim().length]);

	if (hasTrailingCharacterAfterDisplayName)
		return handleMidDisplayNameBreak(displayName, description);

	if (displayName.indexOf("(") === -1 || displayName.indexOf(")") !== -1) return displayName;

	return displayName + description.substring(displayName.length, description.indexOf(")") + 1);
};

/**
 * IMPORTANT: Before using this method, make sure to check out the 'TruncatableTypography' component.
 * This component should be prioritized over this method for handling text truncation in UI elements.
 *
 * Truncates a string to the specified length and adds three dots ("...") at the end if the original string was longer.
 *
 * @param {string} str - The string to be truncated.
 * @param {number} n - The maximum length of the truncated string, including the three dots.
 * @returns {string} The truncated string with three dots added if it was longer than the specified maximum length,
 * or the original string if it was shorter.
 */
export const tripleDotTruncateString = (str, n) => {
	if (str?.length > n) {
		return `${str.substring(0, n)}...`;
	}
	return str;
};
