import { createSlice } from "@reduxjs/toolkit";
import { fetchCustomerBranding } from "actions/brandingActions";
import { RootState } from "store";
import { Branding } from "types";

const brandingSlice = createSlice({
	name: "branding",
	initialState: {
		isFetching: false,
		isLoading: true,
		branding: {} as Branding,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCustomerBranding.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchCustomerBranding.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.isFetching = false;
				state.branding = payload;
			});
	},
});

export const selectCustomerBranding = (state: RootState) => state.branding;
export default brandingSlice.reducer;
