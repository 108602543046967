import { Grid } from "@mui/material";

import { RiskySignInsTable } from "../RiskySignInsTable";
import { CheckRiskDetection, RiskySignInsCollection } from "types";
import { groupRiskDetections } from "./helpers/groupRiskDetections";
import { groupDuplicates } from "./helpers/groupDuplicates";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";

interface DetailedRisksDialogProps {
	onClose: () => void;
	Check_RiskDetections: CheckRiskDetection[];
}

const DetailedRisksDialog = ({
	onClose,
	Check_RiskDetections = [],
}: DetailedRisksDialogProps): JSX.Element => {
	const cleanedUpRiskDetections = groupDuplicates(Check_RiskDetections);
	const collection = groupRiskDetections(cleanedUpRiskDetections) as RiskySignInsCollection;
	return (
		<PrimaryDialog
			title="Risky sign ins"
			primaryButtonText={"Close"}
			primaryAction={onClose}
			onLeaveAction={onClose}
			size="large"
		>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<RiskySignInsTable collection={collection} />
				</Grid>
			</Grid>
		</PrimaryDialog>
	);
};

export { DetailedRisksDialog };
