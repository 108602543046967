import { Grid, Skeleton, Typography } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { memo } from "react";
import { Device, User } from "types";
import { TableCellGrid } from "../TableCellGrid";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import { UserCell } from "../UserCell";
import { EnrollmentStatus } from "../EnrollmentStatus";
import { ComplianceStatus } from "../ComplianceStatus";
import clsx from "clsx";

import styles from "./DeviceRow.module.scss";

interface DeviceRowProps {
	device: Device;
	columns: Record<string, { width: string; key: string; sortable: boolean }>;
	windowWidth: number;
	sinceLastSignedIn: string;
	inactiveForAbove30Days: boolean;
	users: Dictionary<User>;
	isUsersLoading: boolean;
}

export const DeviceRow = memo(
	({
		device,
		columns,
		windowWidth,
		sinceLastSignedIn,
		inactiveForAbove30Days,
		users,
		isUsersLoading,
	}: DeviceRowProps) => {
		const { displayName, operatingSystem, ownerUserId, deviceOwnerInfo, complianceInfo } =
			device;

		return (
			<Grid container item className={styles.tableRow} px={3}>
				<TableCellGrid
					title="Device"
					width={columns.Device.width}
					windowWidth={windowWidth}
				>
					<TruncatableTypography variant="body1" maxCharLength={20}>
						{displayName}
					</TruncatableTypography>
					<TruncatableTypography
						variant="description"
						color="text.secondary"
						maxCharLength={20}
					>
						{operatingSystem}
					</TruncatableTypography>
				</TableCellGrid>
				<TableCellGrid title="User" width={columns.User.width} windowWidth={windowWidth}>
					<UserCell
						id={ownerUserId}
						UPN={deviceOwnerInfo?.userPrincipalName ?? ""}
						users={users}
						isUsersLoading={isUsersLoading}
					/>
				</TableCellGrid>
				<TableCellGrid
					title="Enrollment Status"
					width={columns["Enrollment Status"].width}
					windowWidth={windowWidth}
				>
					<EnrollmentStatus {...{ ...device }} />
				</TableCellGrid>
				<TableCellGrid
					title="Device Status"
					width={columns["Device Status"].width}
					windowWidth={windowWidth}
				>
					<ComplianceStatus device={device} />
				</TableCellGrid>
				<TableCellGrid
					title="Explanation"
					width={columns.Explanation.width}
					windowWidth={windowWidth}
				>
					<Grid
						item
						className={clsx({
							[styles.hidden]: !complianceInfo?.ComplianceExplanation?.title,
						})}
					>
						<Typography variant="description" fontWeight={500} display="block">
							{complianceInfo?.ComplianceExplanation?.title}
						</Typography>
						<Typography variant="description">
							{complianceInfo?.ComplianceExplanation?.description}
						</Typography>
					</Grid>
				</TableCellGrid>
				<TableCellGrid
					title="Last active"
					width={columns["Last active"].width}
					windowWidth={windowWidth}
				>
					<Grid
						item
						className={clsx({
							[styles.active]: !inactiveForAbove30Days,
							[styles.inactive]: inactiveForAbove30Days,
						})}
					>
						<Typography
							variant="description"
							fontWeight={500}
							className={clsx({
								[styles.inactiveFont]: inactiveForAbove30Days,
							})}
						>
							{sinceLastSignedIn}
						</Typography>
					</Grid>
				</TableCellGrid>
			</Grid>
		);
	},
);

interface LoadingDeviceRowProps {
	columns: Record<string, { width: string; key: string; sortable: boolean }>;
	windowWidth: number;
}

export const LoadingDeviceRow = ({ columns, windowWidth }: LoadingDeviceRowProps) => {
	return (
		<Grid container item className={styles.tableRow} px={3}>
			<TableCellGrid title="Device" width={columns.Device.width} windowWidth={windowWidth}>
				<Skeleton variant="text" width={120} />
				<Skeleton variant="text" width={50} />
			</TableCellGrid>
			<TableCellGrid title="User" width={columns.User.width} windowWidth={windowWidth}>
				<Skeleton variant="text" width={100} />
				<Skeleton variant="text" width={120} />
			</TableCellGrid>
			<TableCellGrid
				title="Enrollment Status"
				width={columns["Enrollment Status"].width}
				windowWidth={windowWidth}
			>
				<Skeleton variant="text" width={100} />
			</TableCellGrid>
			<TableCellGrid
				title="Device Status"
				width={columns["Device Status"].width}
				windowWidth={windowWidth}
			>
				<Skeleton variant="text" width={100} />
			</TableCellGrid>
			<TableCellGrid
				title="Explanation"
				width={columns.Explanation.width}
				windowWidth={windowWidth}
			>
				<Skeleton variant="text" width={100} />
			</TableCellGrid>
			<TableCellGrid
				title="Last active"
				width={columns["Last active"].width}
				windowWidth={windowWidth}
			>
				<Skeleton variant="text" width={100} />
			</TableCellGrid>
		</Grid>
	);
};
