import { createAsyncThunk } from "@reduxjs/toolkit";
import { SCOPES } from "utilities/constants/constants";
import { apiGet, apiPost } from "utilities/api";
import { Device } from "types";
import { DeviceComplianceStatus, IntuneManagementType } from "utilities/constants/enums";

/**
 * Fetches the device compliance report. Used to check if the compliance report has been created
 * @async
 * @function fetchDeviceComplianceReportStatus
 * @returns A list of device compliance reports
 **/
export const fetchDeviceComplianceReportStatus = createAsyncThunk(
	"devices/fetchReport",
	apiGet<string[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.DEVICE_COMPLIANCE_REPORT_STATUS(),
		[SCOPES.GRAPH_READ],
	),
);

export const createDeviceCompliancyReport = createAsyncThunk(
	"devices/createReport",
	apiPost(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.DEVICE_COMPLIANCE_REPORT(),
		[SCOPES.GRAPH_WRITE],
	),
);

export const fetchAllDevices = createAsyncThunk(
	"devices/fetch",
	apiGet<{
		devices: Device[];
		meta: {
			osFiltes: string[];
			intuneManagementFilters: IntuneManagementType[];
			complianceFilters: DeviceComplianceStatus[];
		};
	}>((authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.DEVICES(), [SCOPES.GRAPH_READ]),
);
