import { LicenseSubscriptionRecommendation } from "types";
import { CostSavingsType } from "utilities/constants/enums";

interface QuantityAndPriceToRemove {
	quantity: number;
	price: number;
}
/* Calculate cost savings return four different cost savings values:
 * 1. unassigned: cost savings from removing unassigned licenses
 * 2. idle: cost savings from removing idle licenses
 * 3. termDuration: cost savings from converting P1M licenses to P1Y
 * 4. total: total cost savings from all three above
 *
 * For each of the above, we calculate the total cost savings and the isolated cost savings.
 * Total cost savings is the total possible cost savings on the subscription, if all actions are carried out.
 * Total isolated cost savings is the total possible cost savings ON ONE action. I.e you cannot always save the total isolated cost savings, if any other actions on the subscription has been carried out.
 */
export const caclulateCostSavings = (
	recommendation: LicenseSubscriptionRecommendation,
	type: CostSavingsType,
) => {
	if (type === CostSavingsType.RemoveUnused) {
		const maxRemoval = Math.min(
			recommendation.current_Unassigned + recommendation.current_Idle,
			recommendation.maxRemoveableQuantityBasedOnScheduledActions,
		);

		const toRemove = getRemoveableQuantityAndPrice(recommendation, maxRemoval);
		return calculateUnusedCostSavings(toRemove);
	} else {
		return maxPotentialSavingsTermDuration(
			recommendation.possible_convertible_P1M,
			// Due to unassigned and idle licenses already being taken into account, we can use the possible_convertible_P1M as the convertible licenses count
			// This will fail IF, a user schedules a removal of P1M licenses BEFORE the recommendations script tells the user that this is OK (i.e it has been done trhough manage licenses view)
			// This can be solved by grabbing the number of P1M licenses that are scheduled for removal and subtracting that from the possible_convertible_P1M
			recommendation,
		);
	}
};

export const caculateTotalCostSavings = (recommendation: LicenseSubscriptionRecommendation) => {
	if (!recommendation) {
		return {
			unused: 0,
			termDuration: 0,
			totalCostSavings: 0,
		};
	}

	const unusedLicensesCostSavings = caclulateCostSavings(
		recommendation,
		CostSavingsType.RemoveUnused,
	);
	const termDurationCostSavings = caclulateCostSavings(
		recommendation,
		CostSavingsType.TermDurationChange,
	);
	const totalPossibleCostSavings = unusedLicensesCostSavings;
	return {
		unused: unusedLicensesCostSavings,
		termDuration: termDurationCostSavings,
		totalCostSavings: totalPossibleCostSavings,
	};
};

const getRemoveableQuantityAndPrice = (
	recommendation: LicenseSubscriptionRecommendation,
	removeableQuantity: number,
) => {
	const subscriptionsSortedByPrice = [...recommendation.subscriptions].sort(
		(a, b) => b.unitPriceAfterDiscount - a.unitPriceAfterDiscount,
	);
	const toRemove: QuantityAndPriceToRemove[] = [];
	let quantityToRemove = removeableQuantity;
	for (const subscription of subscriptionsSortedByPrice) {
		if (quantityToRemove > 0) {
			if (subscription.quantity > quantityToRemove) {
				toRemove.push({
					quantity: quantityToRemove,
					price: subscription.unitPriceAfterDiscount,
				});
				quantityToRemove = 0;
			} else {
				toRemove.push({
					quantity: subscription.quantity,
					price: subscription.unitPriceAfterDiscount,
				});
				quantityToRemove -= subscription.quantity;
			}
		} else {
			break;
		}
	}
	return toRemove;
};

const calculateUnusedCostSavings = (toRemove: QuantityAndPriceToRemove[]) =>
	toRemove.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);

const maxPotentialSavingsTermDuration = (
	convertibleLicensesCount: number,
	recommendation: LicenseSubscriptionRecommendation,
) => {
	// We're taking all future P1M licenses and converting them to P1M, so we're only interested in the P1Y count
	const { monthlyUnitPrice, yearlyUnitPrice } = calculateUnitPrices(recommendation);
	const totalCostP1M = convertibleLicensesCount * monthlyUnitPrice;
	const totalCostP1Y = convertibleLicensesCount * yearlyUnitPrice;

	return totalCostP1M - totalCostP1Y;
};

export const calculateUnitPrices = (recommendation: LicenseSubscriptionRecommendation) => {
	let { monthlyUnitPrice, yearlyUnitPrice } = recommendation.subscriptions.reduce(
		(acc, curr) => {
			if (curr.termDuration === "P1M") {
				return { ...acc, monthlyUnitPrice: curr.unitPriceAfterDiscount };
			}
			return { ...acc, yearlyUnitPrice: curr.unitPriceAfterDiscount };
		},
		{ monthlyUnitPrice: 0, yearlyUnitPrice: 0 },
	);

	if (monthlyUnitPrice === 0) {
		monthlyUnitPrice = yearlyUnitPrice * 1.2;
	}

	if (yearlyUnitPrice === 0) {
		yearlyUnitPrice = monthlyUnitPrice * 0.8;
	}

	return { monthlyUnitPrice, yearlyUnitPrice };
};
