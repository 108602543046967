import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccessGroup } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";
import { queryParams } from "utilities/urlUtils";

export const fetchGroups = createAsyncThunk(
	"groups/fetchGroups",
	apiGet<AccessGroup[]>(
		(authConfig, { groupType, groupName }) =>
			authConfig.MS_GRAPH_API_ENDPOINTS.GET_GROUPS() + queryParams({ groupType, groupName }),
		[SCOPES.GRAPH_READ],
	),
);
