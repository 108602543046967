import { Grid } from "@mui/material";
import { UserDetails } from "../UserDetails";
import { Employee, User } from "types";
import { Dispatch, SetStateAction } from "react";
import styles from "./PersonalInfo.module.scss";

interface Props {
	employeeData: Employee;
	saveForm: (newData: any) => void;
	enableSaveButton?: () => void;
	isOnboarding: boolean;
	setIsFormDataDirty?: Dispatch<SetStateAction<boolean>>;
	showShippingDetails?: boolean;
}

const PersonalInfo = ({
	employeeData,
	saveForm,
	enableSaveButton,
	isOnboarding,
	setIsFormDataDirty,
	showShippingDetails,
}: Props) => {
	return (
		<Grid container className={styles.wrapper}>
			<Grid item className={styles.onboarding}>
				<UserDetails
					employeeData={employeeData as Employee & User}
					saveForm={saveForm}
					enableSaveButton={enableSaveButton}
					setIsFormDataDirty={setIsFormDataDirty}
					isShippingAddressRequired={false}
					showShippingDetails={showShippingDetails}
					isOnboarding={isOnboarding}
				/>
			</Grid>
		</Grid>
	);
};

export { PersonalInfo };
