import { Drawer, Grid, IconButton, Typography } from "@mui/material";
import styles from "./HardwareDrawer.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { BundleCart } from "../HardwareBundles/BundleCart";
import { Cart } from "components/MyPeople/EmployeeDialogSteps/Cart";
import { useEffect, useState } from "react";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectHardwareDrawerState, setHardwareDrawerClosed } from "features/hardware/hardwarePage";
import { HardwareDrawerType } from "utilities/constants/enums";
import { INNER_WIDTH_LARGE_ENOUGH_TO_PUSH_CONTENT } from "utilities/constants/constants";
import clsx from "clsx";
import { HardwareBundle } from "types";
import { selectActiveEditedBundle } from "features/hardware/hardwareBundles";

export enum HardwareDrawerContentType {
	CART = "cart",
	CART_ONBOARDING = "cart_onboarding",
	BUNDLE_EDIT = "bundle_edit",
	BUNDLE_CREATE = "bundle_create",
}

interface HardwareDrawerInputProps {
	isOnboarding?: boolean;
	onboardingCheckoutMethod?: () => void;
}

const HardwareDrawer = ({
	isOnboarding = false,
	onboardingCheckoutMethod,
}: HardwareDrawerInputProps) => {
	const { dispatch } = useAuth();
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const { activeDrawerType, isDrawerOpen } = useAppSelector(selectHardwareDrawerState);
	const activeBundle = useAppSelector(selectActiveEditedBundle);
	const type = activeDrawerType.includes("Bundle")
		? HardwareDrawerType.Bundle
		: HardwareDrawerType.Cart;
	const { ariaLabel, headerText } = getDrawerTypeValues(type, activeBundle);

	useEffect(() => {
		const handleResize = () => {
			setInnerWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Drawer
			anchor="right"
			open={isDrawerOpen}
			onClose={() => dispatch(setHardwareDrawerClosed())}
			PaperProps={{ className: styles.drawer }}
			className={styles.hardwareDrawerWrapper}
			variant={
				innerWidth > INNER_WIDTH_LARGE_ENOUGH_TO_PUSH_CONTENT ? "persistent" : "temporary"
			}
			aria-labelledby={ariaLabel}
			hideBackdrop={innerWidth > INNER_WIDTH_LARGE_ENOUGH_TO_PUSH_CONTENT}
		>
			<Grid
				container
				className={clsx(styles.headerContainer, {
					[styles.bundleHeader]: type === HardwareDrawerType.Bundle,
					[styles.cartHeader]: type === HardwareDrawerType.Cart,
				})}
			>
				<Typography variant="h2">{headerText}</Typography>

				<Typography
					variant="body1"
					onClick={() => dispatch(setHardwareDrawerClosed())}
					className={styles.closeText}
				>
					Close
					<IconButton className={styles.closeButton}>
						<CloseIcon />
					</IconButton>
				</Typography>
			</Grid>

			<Grid container className={styles.cartContainer} id="drawerContent">
				{activeDrawerType === HardwareDrawerType.Cart ? (
					<Cart
						isOnboarding={isOnboarding}
						onboardingCheckoutMethod={onboardingCheckoutMethod}
					/>
				) : (
					<BundleCart />
				)}
			</Grid>
		</Drawer>
	);
};

const getDrawerTypeValues = (
	type: HardwareDrawerType,
	activeBundle?: HardwareBundle | null | undefined,
) => {
	switch (type) {
		case HardwareDrawerType.Cart:
			return {
				ariaLabel: "Cart",
				headerText: "Cart",
				primaryButtonText: "Checkout",
			};
		case HardwareDrawerType.Bundle:
			return {
				ariaLabel: activeBundle?.published ? "Edit bundle" : "Create bundle",
				headerText: activeBundle?.published ? "Edit bundle" : "Create bundle",
				primaryButtonText: activeBundle?.published ? "Save changes" : "Create bundle",
			};
		default:
			return {
				ariaLabel: "Cart",
				headerText: "Cart",
				primaryButtonText: "Checkout",
			};
	}
};

export { HardwareDrawer };
