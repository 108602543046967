import { Grid } from "@mui/material";
import {
	ConfiguredQuantityRemoval,
	IdleUsersDetails,
	LicenseSubscriptionRecommendation,
} from "types";

import styles from "./IdleLicensesContent.module.scss";
import { useState } from "react";
import { IdleUsersConfiguration } from "./IdleUsersConfiguration";
import { useAppSelector } from "hooks/hooks";
import { makeSelectIdleUsersDetail } from "features/recommendations";
import { RemoveLicensesCheckout } from "./RemoveLicensesCheckout/RemoveLicensesCheckout";
import { UnassignedLicenseContent } from "./UnassignedLicensesContent";
import {
	selectActiveStep,
	selectNumLicensesScheduledForRemoval,
	selectUsersScheduledForRemoval,
} from "features/scheduledActions";

interface ContentInputProps {
	recommendation: LicenseSubscriptionRecommendation;
	setPrimaryActionDisabled: (disabled: boolean) => void;
}

export const UnusedLicenseContent = ({
	recommendation,
	setPrimaryActionDisabled,
}: ContentInputProps) => {
	const activeStep = useAppSelector(selectActiveStep);
	const idleUsersOnSku =
		useAppSelector(makeSelectIdleUsersDetail(recommendation.subscriptionSkuID)) ||
		([] as IdleUsersDetails[]);
	const usersScheduledForRemoval = useAppSelector(selectUsersScheduledForRemoval);
	const idleUsersOnSkuWithDeletedStatuses = idleUsersOnSku.reduce((acc, user) => {
		if (usersScheduledForRemoval.indexOf(user.id) === -1) {
			return [...acc, { ...user, deleted: false }];
		}

		return [...acc, { ...user, deleted: true }];
	}, [] as IdleUsersDetails[]);

	const [configuredRemovalQuantities, setConfiguredRemovalQuantities] = useState<
		ConfiguredQuantityRemoval[]
	>([]);
	const [selectedUsers, setSelectedUsers] = useState([] as string[]);
	const selectedUsersDetails = idleUsersOnSkuWithDeletedStatuses.filter(
		({ id }) => selectedUsers.indexOf(id) !== -1,
	);
	const numScheduledForRemoval = useAppSelector(
		selectNumLicensesScheduledForRemoval(recommendation.subscriptionSkuID),
	);

	// The maximum number of licenses that can be removed is the minimum of the following:
	// 1. The number of selected (idle) users + the number of unassigned licenses
	// 2. The calculated max removeable quantity (i.e the limit - aggregated number of licenses based on all subscriptions)
	// 3. The number of idle users + the number of unassigned licenses (Can this be removed?)
	// 4. Remove the number of licenses that are already scheduled for removal
	const maxRemoveableQuantity =
		Math.min(
			selectedUsers.length + recommendation.current_Unassigned,
			recommendation.maxRemoveableQuantity,
			recommendation.current_Idle + recommendation.current_Unassigned,
		) - Number(numScheduledForRemoval ?? 0);

	return (
		<>
			{activeStep === 0 && (
				<Grid container className={styles.configurationPageContainer}>
					<IdleUsersConfiguration
						recommendation={recommendation}
						idleUsersDetails={idleUsersOnSkuWithDeletedStatuses}
						selectedUsers={selectedUsers}
						maxRemoveableQuantity={maxRemoveableQuantity}
						setSelectedUsers={setSelectedUsers}
					/>
				</Grid>
			)}
			{activeStep === 1 && (
				<Grid container className={styles.licenseRemovalContainer}>
					<UnassignedLicenseContent
						availableForRemoval={maxRemoveableQuantity}
						recommendation={recommendation}
						setConfiguredQuantityRemovals={setConfiguredRemovalQuantities}
						setPrimaryActionDisabled={setPrimaryActionDisabled}
						noSelectedUsers={selectedUsers.length === 0}
					/>
				</Grid>
			)}
			{activeStep === 2 && (
				<Grid container className={styles.checkoutPageContainer}>
					<RemoveLicensesCheckout
						recommendation={recommendation}
						selectedUsersDetails={selectedUsersDetails.filter(
							({ deleted }) => !deleted,
						)}
						configuredRemovalQuantities={configuredRemovalQuantities}
					/>
				</Grid>
			)}
		</>
	);
};
