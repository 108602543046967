import styles from "./HourRateLabel.module.scss";

const HourRateLabel = ({ label }: { label: string }) => {
	if (!label) {
		return <></>;
	}

	const target = label.toUpperCase();

	const { style, text } = [
		{
			style: styles.normalRateLabel,
			label: "NORMAL",
			text: "Normal working hours",
		},
		{
			style: styles.afterHours100Label,
			label: "AFTER HOURS 100",
			text: "Overtime 100%",
		},
		{
			style: styles.afterHours150Label,
			label: "AFTER HOURS 150",
			text: "Overtime 150%",
		},
		{
			style: styles.afterHours200Label,
			label: "AFTER HOURS 200",
			text: "Overtime 200%",
		},
	].find(({ label }) => target.includes(label)) ?? {
		style: styles.unknownRateLabel,
		text: label,
	};

	if (!style || !text) return <>{label}</>;

	return <span className={style}>{text}</span>;
};

export { HourRateLabel };
