import { ChangeEvent, ReactElement, useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { InputBase, IconButton, Grid, Table } from "@mui/material";

import styles from "./Search.module.scss";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import {
	ordersSelectors,
	selectOrderPeriods,
	selectOrders,
	selectSearchedOrders,
	setSearchedOrders,
} from "features/orders";
import { Order } from "types";
import _ from "lodash";
import { usersSelectors } from "features/users";
import dayjs from "dayjs";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { getStatus } from "utilities/features/orders";
import { DateRangePicker } from "components/Common/DateRangePicker";
import SummaryTableFooter from "components/Common/SummaryTableFooter/SummaryTableFooter";
import { filterEntriesOnSelectedRange } from "utilities/dates/dateRangePicker";

export function Search(): ReactElement {
	const { isLoading } = useAppSelector(selectOrders);
	const orders = useAppSelector(ordersSelectors.selectAll);
	const usersById = useAppSelector(usersSelectors.selectEntities);
	const searchedOrders = useAppSelector(selectSearchedOrders);

	const dispatch = useAppDispatch();

	const periods = useAppSelector(selectOrderPeriods);

	const defaultStartRange = {
		startDate: dayjs(dayjs().startOf("year").format("YYYY-MM-DD")).toDate(),
		endDate: dayjs().endOf("month").toDate(),
		key: "selection",
	};

	const [ranges, setRanges] = useState([defaultStartRange]);
	const [searchValue, setSearchValue] = useState("");

	const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false);

	const filtersAreDefault =
		dayjs(ranges[0].startDate).isSame(defaultStartRange.startDate, "day") &&
		dayjs(ranges[0].endDate).isSame(defaultStartRange.endDate, "day") &&
		searchValue === "";

	const selectedRangeDisplay = filtersAreDefault
		? ""
		: `${
				dayjs(ranges[0].startDate).format("DD.MM.YY") +
				" - " +
				dayjs(ranges[0].endDate).format("DD.MM.YY")
		  }`;

	const onHandleChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
		const searchValue = value.toLowerCase();
		setSearchValue(searchValue);
	};

	useEffect(() => {
		if (isLoading) return;

		const ordersWithinDateRange = filtersAreDefault
			? orders
			: filterEntriesOnSelectedRange(
					ranges[0].startDate,
					ranges[0].endDate,
					orders,
					"orderIssueDate",
			  );

		if (!searchValue) {
			dispatch(setSearchedOrders(_.map(ordersWithinDateRange, "orderNumber")));
			return;
		}

		const matchesSearchInput = (property: string | number) =>
			property?.toString().toLowerCase().includes(searchValue);

		const filteredOrders = ordersWithinDateRange.filter(
			({
				orderedBy,
				orderedFor,
				orderIssueDate,
				statusDetails,
				orderNumber,
				orderedItems,
			}: Order) =>
				[
					usersById[orderedBy]?.displayName ?? "",
					usersById[orderedFor]?.displayName ?? "",
					dayjs(orderIssueDate).format("DD.MM.YYYY"),
					getStatus(statusDetails),
					orderNumber,
					..._.map(orderedItems, "displayName"),
					formatCostString(
						"kr ",
						_.sumBy(orderedItems, ({ price, quantity }) => price * quantity),
						"",
					),
				].some(matchesSearchInput),
		);

		dispatch(setSearchedOrders(_.map(filteredOrders, "orderNumber")));
	}, [isLoading, searchValue, ranges, dispatch, filtersAreDefault, orders, usersById]);

	const handleResetFilters = () => {
		setRanges([defaultStartRange]);
		setSearchValue("");
	};

	return (
		<>
			<Grid container className={styles.inputContainer}>
				<Grid item xs>
					<div className={styles.inputRoot}>
						<IconButton disabled className={styles.iconButton} aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							value={searchValue}
							className={styles.input}
							placeholder="Employee name, order number or product"
							inputProps={{ "aria-label": "search for employee" }}
							onChange={onHandleChange}
						/>
					</div>
				</Grid>
				<Grid item xs>
					<DateRangePicker
						showDateRangePicker={showDateRangePicker}
						setShowDateRangePicker={setShowDateRangePicker}
						ranges={ranges}
						setRanges={setRanges}
						selectedRangeDisplay={selectedRangeDisplay}
						selectablePeriods={periods}
					/>
				</Grid>
			</Grid>
			<Table>
				<SummaryTableFooter
					padding={false}
					onResetFilters={handleResetFilters}
					summaryCells={[
						{
							label: "Date interval",
							value: selectedRangeDisplay,
						},
						{
							label: "Total orders",
							value: searchedOrders.length,
						},
						{
							label: "Total expense",
							value: formatCostString(
								"",
								_.sumBy(
									searchedOrders.flatMap(({ orderedItems }) => orderedItems),
									({ price, quantity }) => price * quantity,
								),
								" ,-",
							),
						},
					]}
					visible={!filtersAreDefault}
				/>
			</Table>
		</>
	);
}
