import { SUB_HEADER_NAMES } from "utilities/constants/constants.ts";

export const getSubHeaderForRole = (subHeaderId, loggedInUserRoles) => {
	if (!subHeaderId || !loggedInUserRoles || loggedInUserRoles.length === 0) {
		return "";
	} else {
		const upperCasedLoggedInUserRoles = loggedInUserRoles.map((role) => role.toUpperCase());
		if (upperCasedLoggedInUserRoles.includes("USER")) {
			return SUB_HEADER_NAMES[subHeaderId].nameForUser;
		} else {
			return SUB_HEADER_NAMES[subHeaderId].nameForAllOtherRoles;
		}
	}
};
