import clsx from "clsx";
import { CircularProgress, Grid, Typography } from "@mui/material";

import styles from "./ContentView.module.scss";
import { useAllowedPagesForUser, useAppSelector } from "hooks/hooks";
import { DataUpdateInterval } from "components/Common/DataUpdateInterval";
import { noAccessText } from "utilities/constants/texts";
import { NavigationName, PRODUCT_TYPES } from "types";
import { selectCustomerDetails } from "features/customer";
import { useLocation } from "react-router-dom";

interface ContentViewProps {
	isIFrame?: boolean;
	url?: string;
	title?: string;
	navigationName: any;
	padding?: boolean;
	children?: any;
	iframeMode?: boolean;
	showLabel?: boolean;
	showDataUpdateInterval?: boolean;
	centralizeHeader?: boolean;
}

const ContentView = ({
	isIFrame = false,
	url,
	title,
	navigationName,
	padding = true,
	children,
	iframeMode = false,
	showLabel = true,
	showDataUpdateInterval = false,
	centralizeHeader = true,
}: ContentViewProps) => {
	const { pathname } = useLocation();
	const { isLoading: isCustomerInfoLoading, isMocked } = useAppSelector(selectCustomerDetails);

	const { allowedPagesForCustomer, allowedPagesForRole } = useAllowedPagesForUser();
	const allowedPageForCustomer = allowedPagesForCustomer.includes(navigationName) || isMocked;
	const allowedPageForRole = allowedPagesForRole?.includes(navigationName);

	if (isCustomerInfoLoading) {
		return (
			<Grid className={styles.globalLoading}>
				<CircularProgress />
			</Grid>
		);
	}

	if (allowedPageForRole && allowedPageForCustomer) {
		return (
			<>
				{isIFrame ? (
					<iframe src={url} title={title} className={styles.iframe} />
				) : (
					<main
						className={clsx(styles.main, {
							[styles.contentIFrameMode]: !!iframeMode,
							[styles.contentContainer]: !iframeMode,
						})}
					>
						{showLabel && (
							<Grid
								container
								className={clsx({
									[styles.sectionTitle]: true,
									[styles.centralizeHeader]: true,
								})}
							>
								<Grid
									className={clsx({
										[styles.standardTitleTextContainer]: !centralizeHeader,
										[styles.centralizedTitleTextContainer]: centralizeHeader,
									})}
								>
									<Typography
										className={clsx({
											[styles.iframeModeSectionTitle]: !!iframeMode,
										})}
										variant="h2"
									>
										{navigationName.label}
									</Typography>
									{showDataUpdateInterval && (
										<Grid item>
											<DataUpdateInterval
												updateIntervalText={"Data updates daily"}
											/>
										</Grid>
									)}
								</Grid>
							</Grid>
						)}
						<div
							className={clsx({
								[styles.content]: padding,
								[styles.centerContent]: true,
							})}
						>
							{children}
						</div>
					</main>
				)}
			</>
		);
	} else {
		return (
			<main className={clsx(styles.content, styles.notAllowed)}>
				{getNoAccessText(
					navigationName,
					pathname,
					allowedPageForCustomer,
					allowedPageForRole,
				)}
			</main>
		);
	}
};

export { ContentView };

const getNoAccessText = (
	navigationName: NavigationName,
	pathname: string,
	allowedPageForCustomer: boolean,
	allowedPageForRole: boolean,
) => {
	const requiredProductsPrettyNames =
		navigationName?.accessibleWithProducts?.map((product) => PRODUCT_TYPES[product]) ?? [];

	const hasMultipleProducts = requiredProductsPrettyNames.length > 1;
	const displayText = hasMultipleProducts
		? requiredProductsPrettyNames.join(" or ")
		: requiredProductsPrettyNames[0];

	const pageDoesNotExist = navigationName?.accessibleWithProducts === undefined;

	if (pageDoesNotExist) {
		return (
			<Typography variant="h2" textAlign={"center"}>
				The page <b>{pathname}</b> does not exist!
			</Typography>
		);
	}

	return (
		<>
			<Typography variant="h2" textAlign={"center"} mb={2}>
				{`${navigationName.label}`}
			</Typography>
			{!allowedPageForCustomer ? (
				<Typography variant="h2" textAlign={"center"}>
					{`This page requires the ${displayText} product.`}
				</Typography>
			) : (
				!allowedPageForRole && (
					<Typography variant="h2" textAlign={"center"}>
						{noAccessText}
					</Typography>
				)
			)}
		</>
	);
};
