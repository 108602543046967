import { Grid, Typography, Breadcrumbs } from "@mui/material";

import styles from "./LicenseAssignmentView.module.scss";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import {
	licenseGroupsSelectors,
	licenseSubscriptionsSelectors,
	selectActiveLicenseCompleteDetails,
	selectAllLicenseSubscriptionVariants,
	selectLicenseAssignmentState,
	selectLicenseSubscriptions,
	selectPreselectedUserIdForAssignment,
	setActiveLicenseManagementId,
	setPreselectedUserIdForAssignment,
	subscribedSkusSelectors,
} from "features/licenses/licenses";
import { selectUsers, usersSelectors } from "features/users";
import { fetchUsers } from "actions/userActions";
import { fetchLicenseSubscriptions } from "actions/licenseActions";
import { useEffect, useMemo, useState } from "react";
import {
	LicenseAction,
	LicenseAssignmentAndPurchase,
	LicenseAutoCompleteType,
	SubscriptionVariantGroup,
	User,
} from "types";
import { LicenseActionOperationType, LicenseAssignmentType } from "utilities/constants/enums";
import { AgreeToTerms } from "components/Common/AgreeToTerms";
import { TERMS_AND_CONDITIONS_TYPES } from "components/Common/Dialogs/TermsAndConditionsDialog/TermsAndConditions";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { BuyLicensesSummaryTable } from "../LicenseAdjustmentDrawer/BuyLicenses/BuyLicensesSummaryTable";
import { createLicenseActions, executeLicenseActions } from "actions/scheduledActionActions";
import { createLicenseActionBody } from "utilities/licenseUtils/licenseActionUtils";
import { selectCustomerDetails } from "features/customer";
import { assignLicenses } from "actions/licenseActions";
import { getPurchasesAndAssignments } from "utilities/licenseUtils/licenseAssignmentUtils";
import { SelectLicensesAndGroups } from "./SelectLicensesAndGroups";
import { SelectUsers } from "./SelectUsers";
import { PurchaseLicenses } from "./PurchaseLicenses/PurchaseLicenses";
import { LicenseAssignmentSummary } from "./LicenseAssignmentSummary";
import { LicenseAssignmentDialog } from "./LicenseAssigmentDialog";
import { LicenseAssignmentReceiptPage } from "../LicenseAssignmentReceiptPage";
import EmailInput from "components/Common/EmailInput/EmailInput";
import { selectRecipientMailState } from "features/scheduledActions";
import { HoverTooltip } from "components/Common/Tooltips";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import { licenseNameSelectors } from "features/licenses/licenseNames";

export const LicenseAssignmentView = () => {
	const {
		dispatch,
		auth,
		auth: { account },
	} = useAuth();
	const { navigateToPage } = useAppNavigate();
	const completeLicenseDetails = useAppSelector(selectActiveLicenseCompleteDetails);
	const [hasAgreedToTerms, setHasAgreedToTerms] = useState<boolean>(false);

	useApiOnce(fetchUsers, useAppSelector(selectUsers));
	const users = useAppSelector(usersSelectors.selectEntities);
	// Entering this page from certain components (such as the user drawer) will preselect a user
	// to make the experience more seamless
	const preselectedUser = useAppSelector(selectPreselectedUserIdForAssignment) ?? "";
	const [userIdsSelectedForAssignment, setUserIdsSelectedForAssignment] = useState<string[]>(
		preselectedUser ? [preselectedUser] : [],
	);
	// Cleanup preselected user on component unmount
	useEffect(() => {
		return () => {
			dispatch(setPreselectedUserIdForAssignment(""));
		};
	}, [dispatch]);
	const [assignmentButtonLoading, setAssignmentButtonLoading] = useState<boolean>(false);

	const customerInfo = useAppSelector(selectCustomerDetails);
	const licenseNames = useAppSelector(licenseNameSelectors.selectEntities);
	useApiOnce(fetchLicenseSubscriptions, useAppSelector(selectLicenseSubscriptions));
	const licenseGroups = useAppSelector(licenseGroupsSelectors.selectEntities);
	const licenses = useAppSelector(licenseSubscriptionsSelectors.selectEntities);
	const allVariantsBySubId = useAppSelector(selectAllLicenseSubscriptionVariants);
	const subscribedSkus = useAppSelector(subscribedSkusSelectors.selectEntities);
	const licensesAndGroupsPreparedForAutoComplete = useMemo(() => {
		const licensesAndGroups = [
			...Object.values(licenseGroups).map((group) => ({
				id: group!.groupID,
				name: group!.groupName,
				type: LicenseAssignmentType.Group,
			})),
			...Object.values(licenses)
				.map((license) => ({
					id: license?.provisioningId ?? "",
					name: license?.provisioningId
						? licenseNames[license.provisioningId]?.licenseDisplayName ||
						  license?.friendlyName
						: license?.friendlyName, // Use friendlyName if no match in licenseNames / licenseNames is loading
					type: LicenseAssignmentType.Direct,
				}))
				.filter((license) => license.id !== ""),
			// No id, no license we can purchase => filter out
		];
		return licensesAndGroups as LicenseAutoCompleteType[];
	}, [licenseGroups, licenses, licenseNames]);

	const [selectedLicensesAndGroups, setSelectedLicensesAndGroups] = useState<
		LicenseAutoCompleteType[]
	>([]);

	const handleNavigateToMainPage = () => {
		dispatch(setActiveLicenseManagementId(""));
		navigateToPage(NAVIGATION_NAMES.LICENSE_MANAGEMENT.path);
	};

	const handleNavigateToLicenseDetailsPage = () => {
		navigateToPage(NAVIGATION_NAMES.LICENSE_MANAGEMENT.path);
	};

	const [chosenVariants, setChosenVariants] = useState<{ [key: string]: string }>({});

	const handleSelectVariant = (skuId: string, subscriptionIdentifier: string) => {
		setChosenVariants({ ...chosenVariants, [skuId]: subscriptionIdentifier });
	};

	const handleRemoveChosenVariant = (skuId: string) => {
		const { [skuId]: removed, ...rest } = chosenVariants;
		setChosenVariants(rest);
	};

	const handleToggleSelectLicense = (type?: string, id?: string) => {
		if (!id) {
			// No id triggers deselect all
			setSelectedLicensesAndGroups([]);
			setChosenVariants({});
			return;
		}

		const isSelected = selectedLicensesAndGroups.map((license) => license.id).includes(id);

		if (type === LicenseAssignmentType.Direct) {
			if (isSelected) {
				// Deselect
				let newSelected = selectedLicensesAndGroups.filter((license) => license.id !== id);
				setSelectedLicensesAndGroups(newSelected);
				handleRemoveChosenVariant(id);
				return;
			} else {
				// Select
				const license = licensesAndGroupsPreparedForAutoComplete.find(
					(license) => license.id === id,
				);
				if (!license) return;
				setSelectedLicensesAndGroups([...selectedLicensesAndGroups, license]);
				const variantGroup = licenses[license.id] ?? ({} as SubscriptionVariantGroup);
				handleSelectVariant(license.id, variantGroup.preferredVariantId);
				return;
			}
		} else {
			// Group
			const licensesInGroup =
				licenseGroups[id]?.licenses.map((license) => license.skuId) ?? [];

			if (isSelected) {
				// Deselect
				let newSelected = selectedLicensesAndGroups.filter(
					(license) => license.id !== id && !licensesInGroup.includes(license.id),
				);
				setSelectedLicensesAndGroups(newSelected);
				// Remove all variants that were selected directly, which will now be assigned through the group instead
				licensesInGroup.forEach((id) => handleRemoveChosenVariant(id));
				return;
			} else {
				// Select
				const group = licensesAndGroupsPreparedForAutoComplete.find(
					(group) => group.id === id,
				);
				if (!group) return;
				const newSelectedGroupsAndLicenses = selectedLicensesAndGroups
					.filter((license) => !licensesInGroup.includes(license.id))
					.concat(group);
				setSelectedLicensesAndGroups(newSelectedGroupsAndLicenses);
				// If group is selected, make sure to deselect all direct assignments of licenses in group
				// and select the group itself
				licensesInGroup.forEach((id) => {
					handleRemoveChosenVariant(id);
					const variantGroup = licenses[id] ?? ({} as SubscriptionVariantGroup);
					handleSelectVariant(id, variantGroup.preferredVariantId);
				});
				return;
			}
		}
	};

	// This object is a core part of the logic of this page
	// It keeps track of purchases, any direct or group assignments that need to be made
	// and also has a "skuInformation" property that keeps track of the quantity assigned & purchased, identified by skuId for easy lookup
	const [purchaseAndAssignments, setPurchaseAndAssignments] =
		useState<LicenseAssignmentAndPurchase>({
			purchases: {},
			directAssignments: {},
			groupAssignments: {},
			skuInformation: {},
		} as LicenseAssignmentAndPurchase);

	useEffect(() => {
		const groupIds = selectedLicensesAndGroups
			.filter((license) => license.type === LicenseAssignmentType.Group)
			.map(({ id }) => id);
		const licensesInSelectedGroups = groupIds.reduce((acc, curr) => {
			const licenses = licenseGroups[curr]?.licenses.map((license) => license.skuId) ?? [];
			acc[curr] = licenses;
			return acc;
		}, {} as { [groupId: string]: string[] });

		const purchaseAndAssignments = getPurchasesAndAssignments(
			allVariantsBySubId,
			licenses,
			subscribedSkus,
			userIdsSelectedForAssignment.map((id) => users[id] ?? ({} as User)),
			chosenVariants,
			licensesInSelectedGroups,
		);

		setPurchaseAndAssignments(purchaseAndAssignments);

		// We only want to recalculate when the chosen variants change, or the user has changed selected users
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chosenVariants, userIdsSelectedForAssignment]);

	const { mail: recipientMail } = useAppSelector(selectRecipientMailState);

	const handlePurchaseAndAssignLicenses = async (
		purchaseAndAssignments: LicenseAssignmentAndPurchase,
	) => {
		setAssignmentButtonLoading(true);
		// Build and create pruchases, i.e license adjustments, that our action engine will handle
		const allPurchases = Object.values(purchaseAndAssignments.purchases ?? {}) ?? [];
		const purchaseBodies = allPurchases.map((adjustment) => {
			const purchaseInfo =
				adjustment.purchaseType === LicenseActionOperationType.CreateNewSubscription
					? {
							TermDuration: adjustment.termDuration,
							ProductId: adjustment.costIdentifier.split(":")[0],
							SkuId: adjustment.costIdentifier.split(":")[1],
					  }
					: undefined;
			return createLicenseActionBody({
				customerName: customerInfo?.departmentName ?? "",
				account: account,
				operationType: adjustment.purchaseType,
				targetFriendlyName: adjustment.variant.friendlyName ?? "",
				variant: adjustment.variant,
				quantityChange: adjustment.quantity,
				purchaseInfo: purchaseInfo,
				confirmationMailRecipient: recipientMail,
			});
		});

		if (purchaseBodies.length > 0) {
			const createActionsResult = await dispatch(
				createLicenseActions({
					auth,
					body: purchaseBodies,
					meta: {
						// Doesn't matter if this is a create or an adjustment, as it's just to toggle an indicator state
						// shown in the loading dialgo
						operationType: LicenseActionOperationType.AdjustLicenseCount,
					},
				}),
			);
			const createdActions = (createActionsResult?.payload as any)?.data as LicenseAction[];
			// Execute the license actions immediately after creating them, as these are always "purchase" in this case
			dispatch(
				executeLicenseActions({
					auth,
					body: createdActions,
				}),
			);
		}
		setAssignmentButtonLoading(false);

		// Build and create assignments, that the backend API will handle separately from the action engine
		const directAssignments = Object.entries(purchaseAndAssignments.directAssignments).map(
			([userId, assignments]) => ({
				userId,
				skuIds: assignments,
			}),
		);

		const groupAssignments = Object.entries(purchaseAndAssignments.groupAssignments).map(
			([userId, assignments]) => ({
				userId,
				groupIds: assignments,
			}),
		);

		dispatch(
			assignLicenses({
				auth,
				body: {
					directAssignments,
					groupAssignments,
				},
				licenseGroups: Object.values(licenseGroups ?? {}),
			}),
		);
	};

	const allSelectedLicenses = useMemo(() => {
		return selectedLicensesAndGroups.reduce((acc, curr) => {
			if (curr.type === LicenseAssignmentType.Direct) {
				return [...acc, { id: curr.id, assignedBy: LicenseAssignmentType.Direct }];
			}
			// Group
			const group = licenseGroups[curr.id];
			if (!group) return acc;
			const licensesInGroup = group.licenses ?? [];
			return [
				...acc,
				...licensesInGroup.map((license) => ({
					id: license.skuId,
					assignedBy: group.groupName,
				})),
			];
		}, [] as { id: string; assignedBy: string }[]);

		// We only want to recalculate when the selected licenses change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLicensesAndGroups]);

	const {
		purchaseButtonDisabled,
		purchaseButtonDisabledText,
		agreeToTermsDisabled,
		agreeToTermsRequired,
	} = useMemo(() => {
		const noAssignments =
			Object.keys(purchaseAndAssignments.directAssignments).length === 0 &&
			Object.keys(purchaseAndAssignments.groupAssignments).length === 0;
		const agreeToTermsRequired = Object.keys(purchaseAndAssignments.purchases).length > 0;
		const purchaseButtonDisabledText = () => {
			if (noAssignments && userIdsSelectedForAssignment.length === 0) return "No assignments";
			if (userIdsSelectedForAssignment.length === 0)
				return "No users selected - selections will not result in any purchases or assignments";
			if (allSelectedLicenses.length === 0)
				return "No licenses selected - selections will not result in any purchases or assignments";
			if (userIdsSelectedForAssignment.length > 0 && noAssignments) {
				return `The selected user${
					userIdsSelectedForAssignment.length > 1 ? "s" : ""
				} already have all the selected licenses assigned`;
			}
			if (!hasAgreedToTerms && agreeToTermsRequired)
				return "In order to purchase licenses, you must agree to the terms and conditions";
			return "";
		};
		const text = purchaseButtonDisabledText();

		return {
			purchaseButtonDisabled: text !== "",
			purchaseButtonDisabledText: text,
			agreeToTermsDisabled: noAssignments,
			agreeToTermsRequired,
		};
	}, [
		hasAgreedToTerms,
		purchaseAndAssignments,
		userIdsSelectedForAssignment,
		allSelectedLicenses,
	]);

	const { showLoadingDialog, showReceiptPage } = useAppSelector(selectLicenseAssignmentState);

	return (
		<>
			{!showReceiptPage && (
				<Grid container item className={styles.container}>
					<Grid item className={styles.leftSideSpacer} />
					<Grid container item className={styles.mainContentContainer}>
						<Grid container item className={styles.mainContent}>
							<Grid item className={styles.titleContainer}>
								<Typography variant="h2">Assign licenses</Typography>
								<Breadcrumbs
									className={styles.breadcrumbs}
									separator="›"
									aria-label="breadcrumb"
								>
									<Typography
										variant="body1"
										onClick={handleNavigateToMainPage}
										className={styles.firstBreadcrumb}
									>
										Manage licenses
									</Typography>
									{completeLicenseDetails?.friendlyName && (
										<TruncatableTypography
											variant="body1"
											maxCharLength={30}
											onClick={handleNavigateToLicenseDetailsPage}
											className={styles.firstBreadcrumb}
										>
											{completeLicenseDetails?.friendlyName}
										</TruncatableTypography>
									)}
									<Typography variant="body1" className={styles.activeBreadcrumb}>
										Assign licenses
									</Typography>
								</Breadcrumbs>
							</Grid>
							<Grid container className={styles.sectionHeaderAndContentContainer}>
								<div className={styles.numberContainer}>
									<Typography>1</Typography>
								</div>
								<SelectUsers
									userIdsSelectedForAssignment={userIdsSelectedForAssignment}
									setUserIdsSelectedForAssignment={
										setUserIdsSelectedForAssignment
									}
								/>
							</Grid>
							<Grid container className={styles.sectionHeaderAndContentContainer}>
								<div className={styles.numberContainer}>
									<Typography>2</Typography>
								</div>
								<SelectLicensesAndGroups
									selectedValues={selectedLicensesAndGroups}
									options={licensesAndGroupsPreparedForAutoComplete}
									purchaseAndAssignments={purchaseAndAssignments}
									handleToggleSelectLicense={handleToggleSelectLicense}
								/>
							</Grid>
							<Grid
								container
								className={styles.purchaseSectionHeaderAndContentContainer}
							>
								<Grid container>
									<div className={styles.purchaseSectionNumberContainer}>
										<Typography>3</Typography>
									</div>
									<Typography variant="body1">
										Purchase missing licenses
									</Typography>
								</Grid>
								<PurchaseLicenses
									allSelectedLicenses={allSelectedLicenses}
									userIdsSelectedForAssignment={userIdsSelectedForAssignment}
									purchaseAndAssignments={purchaseAndAssignments}
									chosenVariants={chosenVariants}
									handleSelectVariant={handleSelectVariant}
								/>
							</Grid>
							<Grid container className={styles.actionsContainer}>
								{Object.values(purchaseAndAssignments.purchases).length > 0 && (
									<Grid
										container
										className={styles.purchaseLicenseSummaryContainer}
									>
										<Grid item>
											<Typography variant="body1" mb={1}>
												Purchased licenses
											</Typography>
											<BuyLicensesSummaryTable
												licenses={
													Object.values(
														purchaseAndAssignments.purchases,
													) ?? []
												}
											/>
										</Grid>
									</Grid>
								)}
								<Grid item mt={1} xs={12}>
									<EmailInput
										explanationText="Assignment receipt"
										required={false}
										className={styles.emailInput}
									/>
								</Grid>
								<AgreeToTerms
									type={TERMS_AND_CONDITIONS_TYPES.LICENSE}
									agreedToTerms={hasAgreedToTerms}
									position="flex-start"
									setAgreedToTerms={setHasAgreedToTerms}
									isDisabled={agreeToTermsDisabled}
									required={agreeToTermsRequired}
								/>
								<HoverTooltip
									title={""}
									description={purchaseButtonDisabledText}
									hide={!purchaseButtonDisabled}
								>
									<div>
										<PrimaryButton
											text={"Assign licenses"}
											isDisabled={purchaseButtonDisabled}
											variantStyle="contained"
											size="medium"
											action={() =>
												handlePurchaseAndAssignLicenses(
													purchaseAndAssignments,
												)
											}
											isLoading={assignmentButtonLoading}
										/>
									</div>
								</HoverTooltip>
							</Grid>
						</Grid>
					</Grid>
					<Grid item className={styles.summaryContainer}>
						<Grid container item className={styles.stickyContainer}>
							<Grid item className={styles.summary}>
								<Typography variant="body1" fontWeight={500}>
									Summary
								</Typography>
								<LicenseAssignmentSummary
									userIdsSelectedForAssignment={userIdsSelectedForAssignment}
									allSelectedLicenses={allSelectedLicenses}
									purchaseAndAssignments={purchaseAndAssignments}
									licenses={licenses}
									users={users}
									isReceiptPage={false}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			{showLoadingDialog && (
				<LicenseAssignmentDialog purchaseAndAssignments={purchaseAndAssignments} />
			)}
			{showReceiptPage && (
				<LicenseAssignmentReceiptPage
					userIdsSelectedForAssignment={userIdsSelectedForAssignment}
					allSelectedLicenses={allSelectedLicenses}
					purchaseAndAssignments={purchaseAndAssignments}
					licenses={licenses}
					users={users}
				/>
			)}
		</>
	);
};
