import { Divider, Grid, Typography } from "@mui/material";
import { LicenseSubscriptionRecommendation } from "types";
import { ReactComponent as ExamineSkybert } from "assets/skybert/Examine.svg";

import { CostSavingsType } from "utilities/constants/enums";
import { LicenseCommitmentDurationSplitChart } from "./LicenseCommitmentDurationSplitChart";
import { caclulateCostSavings, calculateUnitPrices } from "utilities/licenseUtils/costSavingsUtils";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

import styles from "./RequestWalkthroughContent.module.scss";

interface RequestWalkthroughContentInputProps {
	recommendation: LicenseSubscriptionRecommendation;
}

export const RequestWalkthroughContent = ({
	recommendation,
}: RequestWalkthroughContentInputProps) => {
	const termDurationSavings = caclulateCostSavings(
		recommendation,
		CostSavingsType.TermDurationChange,
	);
	const { monthlyUnitPrice, yearlyUnitPrice } = calculateUnitPrices(recommendation);

	return (
		<Grid container display={"flex"} alignContent={"flex-start"} gap={2}>
			<Grid container px={3}>
				<Typography variant="h2" fontWeight={500}>
					Term durations for license
				</Typography>
			</Grid>
			<Grid container justifyContent={"flex-start"} px={3}>
				<LicenseCommitmentDurationSplitChart recommendation={recommendation} />
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid container mt={3} px={3}>
				<Typography variant="body1" fontWeight={500} display="inline" mb={2}>
					Current {recommendation.subscriptionOfferName} prices:
				</Typography>
				<Grid container direction="column" gap={1}>
					<Grid item>
						<Typography variant="body1" display="inline" className={styles.priceText}>
							Annual:
						</Typography>
						<Typography variant="body2" display="inline">
							{formatCostString(" ", yearlyUnitPrice, " kr / per license")}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body1" display="inline" className={styles.priceText}>
							Monthly:
						</Typography>
						<Typography variant="body2" display="inline">
							{formatCostString(" ", monthlyUnitPrice, " kr / per license")}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid container direction="column" px={3}>
				<Typography variant="body2" color="text.secondary">
					Potential savings with 100% commitment to annual licenses
				</Typography>
				<span className={styles.potentialSavings}>
					<Typography variant="body1">
						{formatCostString(" ", termDurationSavings * 12, " kr / year")}
					</Typography>
				</span>
			</Grid>
			<Grid item xs={12} mt={5} mb={2.6}>
				<Divider />
			</Grid>
			<Grid
				container
				direction="column"
				px={3}
				className={styles.requestWalkthroughContainer}
			>
				<Grid container>
					<Typography variant="body1" fontWeight={500} mt={1.4}>
						Request walkthrough with Ironstone
					</Typography>
					<svg width={50} height={50}>
						<ExamineSkybert />
					</svg>
				</Grid>
				<Typography variant="body2">
					Unsure about how to divide your annual and monthly license commitments?
					Ironstone is here to help!
					<br /> We offer a step-by-step walkthrough to ensure all your license
					commitments aligns perfectly with your needs to potentially save cost.
					<br /> Get in touch with us for expert guidance.
				</Typography>
			</Grid>
		</Grid>
	);
};
