import { Box } from "@mui/material";
import styles from "./RequestForm.module.scss";

export interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export const getTabProps = (index: number) => {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
};

export const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
};

export const dropDownClasses = {
	root: styles.dropDown,
	input: styles.dropwDownInput,
	inputRoot: styles.input,
	listbox: styles.dropdownListBox,
};
