import { Grid, Typography } from "@mui/material";
import { PrimaryButton } from "components/Common/Buttons/Button";

import styles from "./RecommendationsPreview.module.scss";
import { useAppSelector } from "hooks/hooks";
import { selectRecommendations } from "features/recommendations";
import { RecommendationsPreviewChart } from "./RecommendationsPreviewChart";

import { OpenInNew } from "@mui/icons-material";

const RecommendationsPreview = () => {
	const { isLoading } = useAppSelector(selectRecommendations);

	const handleGoToContact = () => {
		window.open("https://meetings.hubspot.com/frede-sund", "_blank");
	};

	return (
		<Grid container className={styles.upgradeContainer}>
			<Grid item xs={12} className={styles.generateReportContainer}>
				<RecommendationsPreviewChart />
				<Typography variant="body1" textAlign={"center"}>
					Want to learn more about how your business can optimize license cost? <br />
					Book a meeting with one of our experts
				</Typography>
				{!isLoading && (
					<PrimaryButton
						text={
							<Grid item className={styles.primaryButtonContent}>
								<Typography variant="body1" display="inline">
									Book a meeting
								</Typography>
								<OpenInNew fontSize="small" className={styles.externalLinkIcon} />
							</Grid>
						}
						size="medium"
						variantStyle="contained"
						action={handleGoToContact}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export { RecommendationsPreview };
