import colors from "./styles/utilities/_colors.scss";

import { createTheme, responsiveFontSizes } from "@mui/material/styles/index.js";

declare module "@mui/material/styles" {
	interface TypographyVariants {
		description: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		description?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		description: true;
	}
}

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
		xxl: true;
	}
}

const FONT_WEIGHT = {
	REGULAR: 400,
	MEDIUM: 500,
	SEMIBOLD: 600,
};

// We create instance for original theme
// to get the spacing() function for declaring padding and margin
const originalTheme = createTheme({
	typography: {
		fontFamily: "Barlow, sans-serif",
		description: {
			fontFamily: "Barlow, sans-serif",
			fontWeight: FONT_WEIGHT.REGULAR,
			lineHeight: "14px",
			fontSize: 14,
			letterSpacing: "0",
		},
	},
});

const primaryHover = colors.secondary;

let theme = createTheme(
	colors && primaryHover
		? {
				components: {
					MuiTypography: {
						styleOverrides: {
							root: {
								color: colors.textPrimary,
							},
						},
					},
					MuiTableContainer: {
						styleOverrides: {
							root: {
								border: `1px solid ${colors.borderColor}`,
								borderRadius: 4,
							},
						},
					},
					MuiTableHead: {
						styleOverrides: {
							root: {
								backgroundColor: colors.tableHeadBackground,
							},
						},
					},
					MuiAvatar: {
						styleOverrides: {
							root: {
								backgroundColor: colors.success,
							},
						},
					},
					MuiTableCell: {
						styleOverrides: {
							head: {
								color: colors.textPrimary,
							},
							stickyHeader: {
								backgroundColor: colors.tableHeadBackground,
							},
						},
					},
					MuiStepLabel: {
						styleOverrides: {
							labelContainer: {
								textAlign: "center",
								lineHeight: 1,
							},
						},
					},
					MuiInputLabel: {
						styleOverrides: {
							outlined: {
								background: colors.textSecondary,
								marginLeft: -2,
								paddingLeft: 5,
								paddingRight: 5,
							},
							shrink: {
								background: originalTheme.palette.background.default,
								marginLeft: -2,
								paddingLeft: 5,
								paddingRight: 5,
							},
						},
					},
					MuiFormHelperText: {
						styleOverrides: {
							root: {
								marginTop: 0,
								height: 0,
							},
						},
					},
					MuiFormControl: {
						styleOverrides: {
							fullWidth: {
								minHeight: 72,
							},
						},
					},
					MuiButton: {
						styleOverrides: {
							root: {
								textTransform: "none",
								width: 150,
								heigth: 40,
							},
							contained: {
								boxShadow: "none",
							},
							containedPrimary: {
								"&:hover": {
									backgroundColor: primaryHover,
									boxShadow: "none",
								},
							},
						},
					},
					MuiCssBaseline: {
						styleOverrides: {
							"@global": {
								body: {
									margin: 0,
								},
							},
						},
					},
					MuiTab: {
						styleOverrides: {
							root: {
								borderBottom: `3px solid ${colors.borderColor}`,
							},
						},
					},
					MuiTabs: {
						styleOverrides: {
							indicator: {
								height: 3,
								backgroundColor: colors.blueDark,
							},
						},
					},
					MuiListItemButton: {
						styleOverrides: {
							root: {
								height: 35,
								marginTop: 5,
								marginBottom: 5,
							},
						},
					},
					MuiListItemIcon: {
						styleOverrides: {
							root: {
								color: colors.grey600,
								minWidth: 40,
							},
						},
					},
					MuiListSubheader: {
						styleOverrides: {
							root: {
								borderBottom: `1px solid ${colors.borderColor}`,
								lineHeight: "30px",
								marginTop: 10,
								marginRight: 9,
								color: colors.textAccent,
							},
						},
					},
					MuiRadio: {
						styleOverrides: {
							root: {
								main: colors.textAccent,
								"&.Mui-checked": {
									color: colors.yellowPrimary,
								},
							},
						},
					},
					MuiLink: {
						styleOverrides: {
							root: {
								color: colors.link,
							},
						},
					},
					MuiPaper: {
						styleOverrides: {
							elevation: {
								boxShadow: "0 2px 10px -4px rgb(0 0 0 / 14%)",
							},
						},
					},
				},
				palette: {
					background: {
						default: colors.primary,
					},
					primary: {
						main: colors.textAccent,
						contrastText: colors.textSecondary,
					},
					info: {
						main: colors.info,
					},
					warning: {
						main: colors.warning,
					},
					error: {
						main: colors.error,
					},
					success: {
						main: colors.success,
					},
				},
				typography: {
					fontFamily: "Barlow, Oxygen, sans-serif",
					h1: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.REGULAR,
						fontSize: 32,
						letterSpacing: "0",
					},
					h2: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.REGULAR,
						fontSize: 23,
						letterSpacing: "0",
					},
					h3: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.REGULAR,
						fontSize: 20,
						letterSpacing: "0",
					},
					h4: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.REGULAR,
						fontSize: 14,
						letterSpacing: "0",
					},
					body1: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.REGULAR,
						fontSize: 16,
						letterSpacing: "0",
					},
					subtitle1: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.REGULAR,
						lineHeight: "16px",
						fontSize: 16,
						letterSpacing: "2%",
					},
					button: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.SEMIBOLD,
						lineHeight: "16px",
						fontSize: 16,
						letterSpacing: "0",
					},
					description: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.REGULAR,
						lineHeight: "14px",
						fontSize: 14,
						letterSpacing: "0",
					},
					overline: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.REGULAR,
						lineHeight: "16px",
						fontSize: 14,
						letterSpacing: "4%",
					},
					caption: {
						fontFamily: "Barlow, sans-serif",
						fontWeight: FONT_WEIGHT.REGULAR,
						lineHeight: "16px",
						fontSize: 12,
						letterSpacing: "4%",
					},
				},
				breakpoints: {
					values: {
						xs: 0,
						sm: 600,
						md: 900,
						lg: 1200,
						xl: 1536,
						xxl: 2000,
					},
				},
		  }
		: {},
);
theme = responsiveFontSizes(theme, { factor: 1 });

export { theme };
