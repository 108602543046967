import { useAppSelector } from "hooks/hooks";
import { selectRoles } from "features/roles";
import { ROLES_CONSTANTS } from "utilities/constants/constants";

export const isAdmin = (roles: string[]) => roles.includes(ROLES_CONSTANTS.ADMIN);
export const isPortalRead = (roles: string[]) => roles.includes(ROLES_CONSTANTS.PORTALREAD);
export const isUser = (roles: string[]) => roles.includes(ROLES_CONSTANTS.USER);
export const isHR = (roles: string[]) => roles.includes(ROLES_CONSTANTS.HR);
export const isFinance = (roles: string[]) => roles.includes(ROLES_CONSTANTS.FINANCE);
export const isActionRole = (roles: string[]) => isAdmin(roles) || isHR(roles);

const useRole = () => {
	const roles = useAppSelector(selectRoles);

	const isAdminCheck = roles.includes(ROLES_CONSTANTS.ADMIN);
	const isPortalReadCheck = roles.includes(ROLES_CONSTANTS.PORTALREAD);
	const isUserCheck = roles.includes(ROLES_CONSTANTS.USER);
	const isHRCheck = roles.includes(ROLES_CONSTANTS.HR);
	const isFinanceCheck = roles.includes(ROLES_CONSTANTS.FINANCE);

	// Certain roles should be allowed (in general) to perform actions on the pages they are allowed to access
	const isActionRoleCheck = isAdminCheck || isHRCheck;

	return {
		isActionRole: isActionRoleCheck,
		isAdmin: isAdminCheck,
		isPortalRead: isPortalReadCheck,
		isUser: isUserCheck,
		isHR: isHRCheck,
		isFinance: isFinanceCheck,
	};
};

export default useRole;
