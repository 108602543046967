import React from "react";
import PropTypes from "prop-types";
import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
} from "@mui/material";
import { ExpandableRow } from "../ExpandableRow";
import { RiskEventTypes, RiskySignInsCollection } from "types";

interface RiskySignInsTableProps {
	collection: RiskySignInsCollection;
}

const RiskySignInsTable = ({ collection }: RiskySignInsTableProps): JSX.Element => {
	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell width="5%" />
						<TableCell width="37%">Risk type</TableCell>
						<TableCell width="140px" align="right">
							Number of risks
						</TableCell>
						<TableCell />
						<TableCell width="15%" align="center">
							Risk level
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.entries(collection).length > 0 ? (
						Object.entries(collection).map(([riskType, item]) => (
							<ExpandableRow
								riskLevelObj={item}
								key={riskType}
								rowName={riskType as keyof RiskEventTypes}
							/>
						))
					) : (
						<TableRow>
							<TableCell colSpan={6} align="center">
								No risky sign ins detected
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

RiskySignInsTable.propTypes = {
	collection: PropTypes.object,
};

export { RiskySignInsTable };
