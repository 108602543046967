import { LicenseSubscriptionRecommendation } from "types";
import { CostSavingsType } from "utilities/constants/enums";
import { UnusedLicenseContent } from "./RemoveLicensesContent";
import { RequestWalkthroughContent } from "../RequestWalkthroughContent";
import { TaskCompleted } from "./TaskCompleted";

interface LicenseDrawerContentInputProps {
	type: CostSavingsType;
	recommendation: LicenseSubscriptionRecommendation;
	activeStep: number;
	handleCloseDrawer: () => void;
	setPrimaryActionDisabled: (disabled: boolean) => void;
	showCheckoutView: boolean;
	isCheckoutComplete: boolean;
}

export const LicenseDrawerContent = ({
	type,
	recommendation,
	setPrimaryActionDisabled,
	showCheckoutView,
	isCheckoutComplete,
	handleCloseDrawer,
}: LicenseDrawerContentInputProps) => {
	if (showCheckoutView) {
		return (
			<TaskCompleted
				costSavingsType={type}
				handleClose={handleCloseDrawer}
				isCheckoutComplete={isCheckoutComplete}
			/>
		);
	}

	return (
		<>
			{type === CostSavingsType.RemoveUnused && (
				<UnusedLicenseContent
					recommendation={recommendation}
					setPrimaryActionDisabled={setPrimaryActionDisabled}
				/>
			)}
			{type === CostSavingsType.TermDurationChange && (
				<RequestWalkthroughContent recommendation={recommendation} />
			)}
		</>
	);
};
