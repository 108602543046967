import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import dayjs from "dayjs";
import { thickBottomBorder } from "utilities/chartJsCustomPlugins";
import ChartDataLabels from "chartjs-plugin-datalabels";
import colors from "styles/utilities/_colors.scss";
import styles from "./TwoMonthsCostChart.module.scss";
import { useAppSelector } from "hooks/hooks";
import { selectTotalCost, selectTotalCostObject } from "features/costs";
import securityDashboardSettings from "settings/securityDashboard.json";

const TwoMonthsCostChart = (): JSX.Element => {
	const { isLoading, ...totalCost } = useAppSelector(selectTotalCost);

	const totalCostObjectCurrentMonth = useAppSelector(selectTotalCostObject);

	const allCostTypesEqualPresency = (costObject: any, ...costTypes: any) => {
		return costTypes.every(
			(type: any) =>
				(costObject[type].current > 0 && costObject[type].previous > 0) ||
				(costObject[type].previous === 0 && costObject[type].current === 0),
		);
	};

	// Using the function to check if all
	// relevant cost types are either present or not present for both current and previous month
	const priceComparisonMakesSense = allCostTypesEqualPresency(
		totalCostObjectCurrentMonth,
		"azure",
		"office365",
		"yourEmployees",
		"yourITSystems",
	);

	const percentageDifference = Math.round(
		((totalCost?.totalCostPreviousMonth - totalCost?.totalCost) /
			totalCost?.totalCostPreviousMonth) *
			100,
	);

	const { dateFormat } = securityDashboardSettings.monthlyCost;

	const canvasRef = useRef<HTMLCanvasElement>(null);
	useEffect(() => {
		const labels = [
			dayjs().subtract(1, "month").format(dateFormat),
			dayjs().format(dateFormat),
		];

		const chartReadyData = {
			labels,
			datasets: [
				{
					data: [
						isLoading ? 1 : totalCost.totalCostPreviousMonth,
						isLoading ? 0.5 : totalCost.totalCost,
					],
					backgroundColor: [
						isLoading ? colors.loading : colors.blueDark,
						isLoading ? colors.loading : colors.yellowPrimary,
					],
					maxBarThickness: 80,
				},
				{
					data: [
						0,
						isLoading ? 0 : totalCost.totalCost - totalCost.totalCurrentCostThisMonth,
					],
					backgroundColor: [isLoading ? colors.loading : colors.yellowTint4],
					maxBarThickness: 80,
				},
			],
		};

		const chart = new Chart(canvasRef?.current?.getContext("2d") as CanvasRenderingContext2D, {
			type: "bar",
			data: chartReadyData,
			plugins: [ChartDataLabels, thickBottomBorder],
			options: {
				animation: {
					duration: 0,
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					datalabels: {
						font: {
							size: 14,
							weight: "bold",
						},
						align: "end",
						offset: 10,
						color: percentageDifference < 0 ? colors.error : colors.success,
						formatter: (_, context) => {
							if (context.dataIndex !== 1 || context.datasetIndex !== 1) {
								return "";
							}

							if (!priceComparisonMakesSense) {
								return "";
							}

							let percentageDifferenceOnlyNumber = Math.abs(percentageDifference);

							if (
								percentageDifference < 0 &&
								percentageDifferenceOnlyNumber !== Infinity
							) {
								return `↑ ${percentageDifferenceOnlyNumber} %`;
							}

							if (
								percentageDifference > 0 &&
								percentageDifferenceOnlyNumber !== Infinity
							) {
								return `↓ ${percentageDifferenceOnlyNumber} %`;
							}

							return "";
						},
					},
					tooltip: {
						enabled: true,
						callbacks: {
							label: function (context) {
								let label = context.dataset.label || "";

								if (label) {
									label += ": ";
								}

								return label;
							},
							afterBody: function () {
								return [
									`Current cost: ${Math.round(
										totalCost.totalCurrentCostThisMonth,
									)} ,-`,
									`Total Estimated Cost: ${Math.round(totalCost.totalCost)} ,-`,
								];
							},
						},
					},
				},
				layout: {
					padding: {
						top: percentageDifference !== 0 && priceComparisonMakesSense ? 25 : 0,
					},
				},
				scales: {
					x: {
						stacked: true,
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
					},
					y: {
						stacked: true,
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
						ticks: {
							display: !isLoading,
							callback: function (value: any, index) {
								if (index === 0) {
									return null;
								}
								return this.getLabelForValue(value);
							},
							maxTicksLimit: 10,
						},
					},
				},
			},
		});

		return () => chart.destroy();
	}, [totalCost, priceComparisonMakesSense, percentageDifference, isLoading, dateFormat]);

	return (
		<div className={styles.center}>
			<div className={styles.costChartDashboard}>
				<canvas ref={canvasRef} />
			</div>
		</div>
	);
};

export { TwoMonthsCostChart };
