import LaptopIcon from "@mui/icons-material/Laptop";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import HeadsetIcon from "@mui/icons-material/Headset";
import MonitorIcon from "@mui/icons-material/Monitor";
import MouseIcon from "@mui/icons-material/Mouse";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import VideocamIcon from "@mui/icons-material/Videocam";
import SettingsIcon from "@mui/icons-material/Settings";

import { HardwareBundle, HardwareBundleItem } from "types";

export const getIcon = (group: string, subGroup: string, description: string) => {
	const unknownIcon = { identifier: 0, icon: <SettingsIcon style={{ color: "#333333" }} /> };
	switch (group) {
		case "PC_NOTEBOOK_BUSINESS":
			return { identifier: 1, icon: <LaptopIcon style={{ color: "#333333" }} /> };
		case "APPLE_PC_NOTEBOOK":
			return { identifier: 1, icon: <LaptopMacIcon style={{ color: "#333333" }} /> };
		case "PHONES_MOBILE":
			return { identifier: 2, icon: <PhoneIphoneIcon style={{ color: "#333333" }} /> };
		case "SOUND":
			return { identifier: 3, icon: <HeadsetIcon style={{ color: "#333333" }} /> };
		case "MONITOR":
			return { identifier: 4, icon: <MonitorIcon style={{ color: "#333333" }} /> };
		case "MICE_KEYBOARDS":
			if (subGroup.includes("KEYBOARD")) {
				return { identifier: 5, icon: <KeyboardIcon style={{ color: "#333333" }} /> };
			} else if (subGroup.includes("MOUSE") || subGroup.includes("MICE")) {
				return { identifier: 6, icon: <MouseIcon style={{ color: "#333333" }} /> };
			} else if (subGroup.includes("WEBCAMS")) {
				return { identifier: 7, icon: <VideocamIcon style={{ color: "#333333" }} /> };
			} else if (description.includes("MUS") || description.includes("MOUSE")) {
				return { identifier: 6, icon: <MouseIcon style={{ color: "#333333" }} /> };
			} else if (description.includes("KEYBOARD") || description.includes("TASTATUR")) {
				return { identifier: 5, icon: <KeyboardIcon style={{ color: "#333333" }} /> };
			} else {
				return unknownIcon;
			}
		default:
			return unknownIcon;
	}
};

export const getIconsForBundle = (bundle: HardwareBundle) => {
	const iconsAndCountUniqueGroupAndSubGroup = Object.values(bundle.products).reduce(
		(acc, product: HardwareBundleItem) => {
			const { group, subGroup, description } = product;
			const { identifier, icon } = getIcon(
				group.toUpperCase(),
				subGroup.toUpperCase(),
				description.toUpperCase(),
			);
			if (acc[identifier]) {
				acc[identifier].count++;
			} else {
				acc[identifier] = {
					count: 1,
					icon: icon,
				};
			}
			return acc;
		},
		{} as { [key: string]: { count: number; icon: JSX.Element } },
	);

	return Object.values(iconsAndCountUniqueGroupAndSubGroup);
};

export const getHardwareIconBasedOnOperatingSystem = (os: string) => {
	if (os?.toLowerCase().includes("windows")) {
		return <LaptopIcon />;
	} else if (os?.toLowerCase().includes("mac")) {
		return <LaptopMacIcon />;
	} else if (os?.toLowerCase().includes("android") || os?.toLowerCase().includes("ios")) {
		return <PhoneIphoneIcon />;
	} else {
		return <LaptopIcon />;
	}
};
