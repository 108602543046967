import { useEffect, useState } from "react";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { getUsersForDropdown } from "components/api/searchUserOrGroups";
import { get } from "utilities/rest";
import styles from "./ADPeoplePicker.module.scss";
import { AssignedLicense, ServicePlan, User } from "types";

export interface IAdPeoplePickerOption {
	id: string;
	displayName: string;
	mail: string;
	userPrincipalName: string;
	label: string;
	type: string;
}

export interface IADPeoplePickerProps {
	label: string;
	allowMulti?: boolean;
	onChange: Function;
	selectedValue: IAdPeoplePickerOption | IAdPeoplePickerOption[] | [] | null;
	authToken?: string;
	apiConfig?: any;
	setSelectedValueHasNoTeamsLicense: Function;
}

const dropDownClasses = {
	root: styles.dropDown,
	input: styles.dropwDownInput,
	inputRoot: styles.input,
	listbox: styles.dropdownListBox,
};

export const ADPeoplePicker = ({
	label,
	allowMulti,
	onChange,
	selectedValue,
	setSelectedValueHasNoTeamsLicense,
	authToken,
	apiConfig,
}: IADPeoplePickerProps): JSX.Element => {
	const [users, setUsers] = useState([]);
	const [showError, setShowError] = useState(false);

	const fetchUsersByQuery = async (searchValue: string) => {
		const users = await getUsersForDropdown({
			token: authToken,
			url: apiConfig.MS_GRAPH_API_ENDPOINTS.GET_USERS(),
			subscriptionKey: apiConfig?.OCP_APIM_SUBSCRIPTION_KEY,
			searchValue,
		});
		if (users) {
			setUsers(users);
		}
	};

	const getLicenseDetailsForUser = async (id: string): Promise<User> => {
		const url = apiConfig.MS_GRAPH_API_ENDPOINTS.GET_USER(id);

		const response = await get(
			url,
			"Bearer",
			authToken ?? "",
			false,
			"application/json",
			undefined,
			{},
		);

		if (response?.data?.id) {
			return response.data as User;
		}

		return {} as User;
	};

	useEffect(() => {
		fetchUsersByQuery("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectedValueHasTeamsLicense = async (id: string): Promise<void> => {
		const user = await getLicenseDetailsForUser(id);

		const hasTeamsLicense = (user as any).licenseDetails?.some(
			(assignedLicense: AssignedLicense | any) =>
				assignedLicense?.servicePlans?.some(
					(servicePlan: ServicePlan) =>
						servicePlan.servicePlanName.includes("TEAMS") &&
						servicePlan.provisioningStatus === "Success",
				),
		);
		setSelectedValueHasNoTeamsLicense(!hasTeamsLicense);
		setShowError(!hasTeamsLicense);
	};

	return (
		<Autocomplete
			multiple={allowMulti}
			disablePortal
			onChange={(event: any, newValue: any) => {
				onChange(newValue);
				selectedValueHasTeamsLicense(newValue.id);
			}}
			onInputChange={(event: any, newInputValue: any) => {
				if (newInputValue.length < 2) {
					setShowError(false);
					setSelectedValueHasNoTeamsLicense(false);
				}

				if (newInputValue.length >= 2) {
					fetchUsersByQuery(newInputValue);
				}
			}}
			classes={dropDownClasses}
			options={users}
			value={selectedValue || null}
			renderOption={(props, option) => {
				if (option) {
					return (
						<Tooltip title={option["mail"] || option["userPrincipalName"]}>
							<li {...props} key={option["id"]}>
								{option["displayName"]}
							</li>
						</Tooltip>
					);
				}
			}}
			renderInput={(params) => (
				<TextField
					classes={{ root: styles.input }}
					{...params}
					// Check that the selectedValues is not an array,
					// as the error message should only be shown when a primary or a secondary owner is selected
					error={!Array.isArray(selectedValue) && showError}
					helperText={
						!Array.isArray(selectedValue) && showError
							? "User does not have a Teams license"
							: ""
					}
				/>
			)}
		/>
	);
};
