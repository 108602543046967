import CalendarMonth from "@mui/icons-material/CalendarMonth";

import styles from "./ScheduledActionsPill.module.scss";
import { Grid, Typography } from "@mui/material";
import clsx from "clsx";

interface ScheduledActionsPillProps {
	variant: "large" | "small";
	numScheduledLicenseActionGroups: number;
	handleScheduledActionsDialogOpen: () => void;
}

export const ScheduledActionsPill = ({
	variant,
	numScheduledLicenseActionGroups,
	handleScheduledActionsDialogOpen,
}: ScheduledActionsPillProps) => {
	return (
		<Grid
			item
			xs={12}
			className={clsx({
				[styles.large]: variant === "large",
				[styles.small]: variant === "small",
				[styles.hidden]: numScheduledLicenseActionGroups === 0,
			})}
			onClick={() => handleScheduledActionsDialogOpen()}
		>
			<CalendarMonth fontSize="small" />
			{variant === "large" && (
				<Typography variant="description" fontWeight={500}>
					{numScheduledLicenseActionGroups}
				</Typography>
			)}
		</Grid>
	);
};
