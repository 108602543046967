import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { NoDuplicatesRiskDetections } from "types";

import colors from "styles/utilities/_colors.scss";
import styles from "./RiskyTypesUsers.module.scss";
import { RiskEventType } from "utilities/constants/enums";
import { UserDisplayInfo } from "components/Common/UserInfoDisplay";

interface RiskyUsersTableProps {
	riskyTypesUsers: NoDuplicatesRiskDetections[];
}

const RiskyTypesUsers = ({ riskyTypesUsers }: RiskyUsersTableProps): JSX.Element => {
	const showCountry = riskyTypesUsers.some(
		(user) =>
			user.riskEventType === RiskEventType.unlikelyTravel ||
			user.riskEventType === RiskEventType.newCountry ||
			user.riskEventType === RiskEventType.impossibleTravel ||
			user.riskEventType === RiskEventType.mcasImpossibleTravel,
	);

	return (
		<Table size="small" aria-label="Users">
			<TableHead style={{ backgroundColor: colors.brownTint4 }}>
				<TableRow>
					<TableCell>User</TableCell>
					{showCountry && <TableCell>Country</TableCell>}
					<TableCell align="center" width="130px">
						Risky sign ins
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{riskyTypesUsers.map((rowDetails) => (
					<TableRow key={rowDetails.userId} className={styles.root}>
						<TableCell component="th" scope="row">
							<UserDisplayInfo
								id={rowDetails.userId}
								name={rowDetails.userDisplayName}
								mail={rowDetails.userPrincipalName}
							/>
						</TableCell>
						{showCountry && (
							<TableCell>{`${rowDetails.location.city} - ${rowDetails.location.state} - ${rowDetails.location.countryOrRegion}`}</TableCell>
						)}
						<TableCell align="right">{rowDetails.riskySignInsCount}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

export { RiskyTypesUsers };
