export const successfulConsentFlow = (url: string): Boolean => {
	if (!url) {
		return false;
	}

	if (url.includes("error=")) {
		return false;
	}

	if (url.includes("code=")) {
		return true;
	}

	return false;
};
