import { useState } from "react";
import { Grid } from "@mui/material";
import { InfoBadge } from "components/InfoBadge";
import { AzureCostDetails } from "./AzureCostDetails";
import { TableDialog } from "components/Common/Dialogs/TableDialog/TableDialog";
import colors from "styles/utilities/_colors.scss";
import { useApiOnceWithParams, useAppSelector } from "hooks/hooks";
import {
	selectAzureCost,
	selectCurrentAzureCost,
	selectMarketplaceItemsCost,
	selectReservedInstancesCost,
} from "features/costs";
import {
	fetchAzureBilling,
	fetchMarketplaceItemsCost,
	fetchReservedInstancesCost,
} from "actions/costActions";
import { RISK_LEVELS } from "utilities/constants/enums";
import { CostTypeCard } from "components/CostManagement/TotalsCards/CostTypeCard";
import { ReactComponent as AzureCost } from "assets/cost/azure_cost.svg";
import dayjs from "dayjs";
import _ from "lodash";
import { MarketplaceItemsTable, ReservedInstancesTable } from "./AzureCostTable";
import { AnomaliesDialog } from "components/CostManagement/AnomaliesDialog/AnomaliesDialog";
import { InternalLinkContent } from "components/Common/Links";
import styles from "./AzureCostView.module.scss";

const AzureCostView = () => {
	const { isLoading } = useAppSelector(selectAzureCost);
	const { billingDataCurrent, billingDataLastMonth } = useAppSelector(selectCurrentAzureCost);

	useApiOnceWithParams(fetchAzureBilling, useAppSelector(selectAzureCost), { numberOfMonths: 2 });
	useApiOnceWithParams(fetchReservedInstancesCost, useAppSelector(selectReservedInstancesCost), {
		numberOfMonths: 1,
	});
	useApiOnceWithParams(fetchMarketplaceItemsCost, useAppSelector(selectMarketplaceItemsCost), {
		numberOfMonths: 1,
	});

	const [isDetailedNewResourcesDialogOpen, setIsDetailedNewResourcesDialogOpen] = useState(false);
	const [isDetailedAnomaliesDialogOpen, setIsDetailedAnomaliesDialogOpen] = useState(false);

	return (
		<Grid className={styles.wrapper}>
			<Grid container>
				<Grid container p={3}>
					<Grid
						container
						item
						gap="20px"
						justifyContent="flex-start"
						wrap="nowrap"
						className={styles.cardsContainer}
					>
						<div className={styles.costTypeCardContainer}>
							<Grid item className={styles.costTypeCard}>
								<CostTypeCard
									isLoading={isLoading}
									title={"Azure"}
									totalCurrentPeriod={
										billingDataCurrent?.totalForecastedDiscounted
									}
									totalPreviousPeriod={
										billingDataLastMonth?.totalPreTaxDiscounted
									}
									icon={<AzureCost />}
									periodLabel={dayjs().format("MMMM")}
									backgroundColor={colors.azure}
									size="medium"
									hoverDescription={
										billingDataCurrent.azureDiscount
											? `Azure discount is active. ${billingDataCurrent.azureDiscount}% off on azure consumption.`
											: ""
									}
									totalDiscountAmount={
										billingDataCurrent.azureDiscount
											? billingDataCurrent.totalForecasted -
											  billingDataCurrent.totalForecastedDiscounted
											: 0
									}
								/>
							</Grid>
						</div>
						<div className={styles.infoBadge}>
							<InfoBadge
								isLoading={isLoading}
								label="New resources"
								secondaryLabel={"Added last 30 days"}
								level={RISK_LEVELS.MEDIUM}
								topSectionBackgroundColor={colors.blueTint6}
								currentCount={`${_.size(
									billingDataCurrent.newResourcesThisMonth,
								)} resources`}
								link={<InternalLinkContent>View new resources</InternalLinkContent>}
								onClick={() =>
									!isLoading && setIsDetailedNewResourcesDialogOpen(true)
								}
							/>
						</div>
						<div className={styles.infoBadge}>
							<InfoBadge
								isLoading={isLoading}
								label="Cost anomalies"
								secondaryLabel={"This month"}
								level={RISK_LEVELS.HIGH}
								topSectionBackgroundColor={colors.blueTint6}
								currentCount={`${
									_.size(billingDataCurrent.anomalies?.resources) +
									_.size(billingDataCurrent.anomalies?.subscriptions)
								} anomalies`}
								link={
									<AnomaliesDialog
										isDetailedAnomaliesDialogOpen={
											isDetailedAnomaliesDialogOpen
										}
										setIsDetailedAnomaliesDialogOpen={
											setIsDetailedAnomaliesDialogOpen
										}
									/>
								}
								onClick={() =>
									!isLoading &&
									!isDetailedAnomaliesDialogOpen &&
									setIsDetailedAnomaliesDialogOpen(true)
								}
							/>
						</div>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<AzureCostDetails />
				</Grid>

				<Grid item xs={12}>
					<Grid item xs={6} className={styles.azureCostTable}>
						<MarketplaceItemsTable />
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Grid item xs={6} className={styles.azureCostTable}>
						<ReservedInstancesTable />
					</Grid>
				</Grid>
			</Grid>

			{isDetailedNewResourcesDialogOpen && (
				<TableDialog
					title="New resources this month"
					size="large"
					tableData={[
						{
							title: "New resources",
							description: "Resources that were created this month",
							data: billingDataCurrent.newResourcesThisMonth,
							headers: ["Name", "Current cost"],
							properties: [
								{
									name: "resourceName",
									prefix: "",
									suffix: "",
								},
								{ name: "total", prefix: "kr", suffix: ",-" },
							],
						},
					]}
					onClose={() => setIsDetailedNewResourcesDialogOpen(false)}
				/>
			)}
		</Grid>
	);
};

export { AzureCostView };
