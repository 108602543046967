import { MouseEventHandler, ReactElement } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { FormEventHandler } from "react";
import styles from "./Button.module.scss";
import clsx from "clsx";
import WestIcon from "@mui/icons-material/West";
import { HoverTooltip } from "components/Common/Tooltips";

interface ButtonProps {
	text: string | ReactElement;
	size: "small" | "medium" | "large";
	action?: MouseEventHandler;
	variantStyle: "text" | "outlined" | "contained" | undefined;
	isDisabled?: boolean;
	disabledDescription?: string;
	marginLeft?: number;
	marginRight?: number;
	marginTop?: number;
	marginBottom?: number;
	isLoading?: boolean;
	className?: string;
	icon?: ReactElement;
	hidden?: boolean;
}

const BoilerplateButton =
	(name: string) =>
	({
		text,
		size,
		action,
		variantStyle,
		isDisabled,
		disabledDescription,
		marginLeft,
		marginRight,
		marginTop,
		marginBottom,
		isLoading,
		className,
		icon,
		hidden,
		...props
	}: ButtonProps): JSX.Element => {
		const usedButtonStyle = styles[`${size}Button`];
		const usedButtonTextStyle = styles[`${size}ButtonText`];
		const usedButtonColor = styles[`all${name}ButtonColor`];

		if (isDisabled && disabledDescription) {
			return (
				<HoverTooltip title={"Action disabled"} description={disabledDescription}>
					<div>
						<Button
							variant={variantStyle}
							className={clsx(usedButtonStyle, usedButtonColor, {
								[styles.hidden]: hidden,
							})}
							disabled={isDisabled}
							onClick={action}
							sx={{
								ml: marginLeft,
								mr: marginRight,
								mt: marginTop,
								mb: marginBottom,
							}}
							{...props}
						>
							{icon && <span className={styles.icon}>{icon}</span>}
							<div className={usedButtonTextStyle}>{text}</div>
						</Button>
					</div>
				</HoverTooltip>
			);
		}

		return (
			<LoadingButton
				loading={isLoading}
				variant={variantStyle}
				onClick={action}
				className={clsx(
					usedButtonStyle,
					{ [usedButtonColor]: !isLoading, [styles.hidden]: hidden },
					className,
				)}
				disabled={isDisabled}
				sx={{ ml: marginLeft, mr: marginRight, mt: marginTop, mb: marginBottom }}
				disableRipple={name.includes("OpenDrawer")}
				{...props}
			>
				{icon && <span className={styles.icon}>{icon}</span>}
				<div className={usedButtonTextStyle}>{text}</div>
			</LoadingButton>
		);
	};

interface FormButtonProps extends ButtonProps {
	form: string;
	onSubmit?: FormEventHandler<HTMLButtonElement>;
}

const BoilerplateFormButton =
	(name: string) =>
	({
		text,
		size,
		variantStyle,
		isDisabled,
		form,
		onSubmit,
		marginLeft,
		marginRight,
		marginTop,
		marginBottom,
	}: FormButtonProps): JSX.Element => {
		const usedButtonStyle = styles[`${size}Button`];
		const usedButtonTextStyle = styles[`${size}ButtonText`];
		const usedButtonColor = styles[`all${name}ButtonColor`];

		return (
			<Button
				variant={variantStyle}
				className={`${usedButtonStyle} ${usedButtonColor}`}
				disabled={isDisabled}
				type="submit"
				form={form}
				onSubmit={onSubmit}
				sx={{ ml: marginLeft, mr: marginRight, mt: marginTop, mb: marginBottom }}
			>
				<div className={usedButtonTextStyle}>{text}</div>
			</Button>
		);
	};

const PrimaryFormButton = BoilerplateFormButton("Primary");

const SecondaryFormButton = BoilerplateFormButton("Secondary");

const PrimaryButton = BoilerplateButton("Primary");

const SecondaryButton = BoilerplateButton("Secondary");

const PrimaryDeleteButton = BoilerplateButton("Delete");

const OpenDrawerButton = BoilerplateButton("OpenDrawer");
const OpenDrawerPrimaryButton = BoilerplateButton("OpenDrawerPrimary");

const OpenDrawerButtonContent = ({ text }: { text: string }): JSX.Element => {
	return (
		<Grid container>
			<Typography fontSize={14}>{text}</Typography>
			<EastIcon className={styles.arrowIcon} fontSize="small" />
		</Grid>
	);
};

const GoBackButtonContent = ({ text }: { text: string }): JSX.Element => {
	return (
		<Grid container gap={1}>
			<WestIcon fontSize="small" />
			<Typography fontSize={14}>{text}</Typography>
		</Grid>
	);
};

export {
	PrimaryButton,
	SecondaryButton,
	PrimaryDeleteButton,
	PrimaryFormButton,
	SecondaryFormButton,
	OpenDrawerButton,
	OpenDrawerPrimaryButton,
	OpenDrawerButtonContent,
	GoBackButtonContent,
};
