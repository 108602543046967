import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchGroups } from "actions/groupActions";
import { RootState } from "store";
import { AccessGroup } from "types";

const groupsAdapter = createEntityAdapter<AccessGroup>();

const groupSlice = createSlice({
	name: "groups",
	initialState: groupsAdapter.getInitialState({ isLoading: true, isFetching: false }),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGroups.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchGroups.fulfilled, (state, { payload }) => {
				const sorted = payload.sort((a, b) => b.memberCount - a.memberCount);
				groupsAdapter.setAll(state, sorted);
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

export const selectGroups = (state: RootState) => state.groups;

export const groupSelectors = groupsAdapter.getSelectors(selectGroups);

export default groupSlice.reducer;
