import { createAsyncThunk } from "@reduxjs/toolkit";
import { SCOPES, HARDWARE_APPROVERS_FOLDER_PATH } from "utilities/constants/constants";
import { apiGet, apiPost } from "utilities/api";
import { HardwareApproverObject } from "types";

export const fetchHardwareApprovers = createAsyncThunk(
	"approver/fetch",
	apiGet<HardwareApproverObject>(
		(authConfig) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(HARDWARE_APPROVERS_FOLDER_PATH),
		[SCOPES.DATA_LAKE_READ],
	),
);

export const updateHardwareApprovers = createAsyncThunk(
	"approvers/post",
	apiPost<HardwareApproverObject>(
		(authConfig) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.POST_CUSTOMER_DATA(HARDWARE_APPROVERS_FOLDER_PATH),
		[SCOPES.DATA_LAKE_WRITE],
	),
);
