import {
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	Typography,
	Grid,
	Skeleton,
} from "@mui/material";
import { TermDurationLabel } from "components/CostManagement/LicenseCostView/LicenseCostTable/TermDurationLabel";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/hooks";
import { LicensePrice, SubscriptionVariant } from "types";

import styles from "./LicenseSubscriptionTable.module.scss";
import { selectActiveLicenseCompleteDetails } from "features/licenses/licenses";
import { licensePricesSelectors } from "features/licenses/licensePrices";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { AutorenewToggle } from "./AutorenewToggle";
import { LicenseActionOperationType } from "utilities/constants/enums";
import clsx from "clsx";
import {
	selectNewlyCreatedSubscriptionForSku,
	selectScheduledLicenseActionsForSku,
} from "features/scheduledActions";
import _ from "lodash";
import { Dictionary } from "@reduxjs/toolkit";
import useRole from "utilities/roleUtils/roleCheck";

interface LicenseSubscriptionTableProps {
	handleCreateLicenseAction: (
		variants: SubscriptionVariant[],
		operationType: LicenseActionOperationType,
	) => Promise<void>;
}

export const LicenseSubscriptionTable = ({
	handleCreateLicenseAction,
}: LicenseSubscriptionTableProps) => {
	const licensePrices = useAppSelector(licensePricesSelectors.selectEntities);
	const completeLicenseDetails = useAppSelector(selectActiveLicenseCompleteDetails);
	const variants = completeLicenseDetails?.variants ?? [];
	const totalQuantity = variants.reduce((acc, variant) => acc + variant.quantity, 0);
	const scheduledOrInProgressLicenseActionsForSku = useAppSelector(
		selectScheduledLicenseActionsForSku(completeLicenseDetails.provisioningId),
	);
	const newlyCreatedSubscription = useAppSelector(
		selectNewlyCreatedSubscriptionForSku(completeLicenseDetails.provisioningId),
	);

	const handleChangeAutoRenewStatus = async (variant: SubscriptionVariant) => {
		const operationType = variant.autoRenewEnabled
			? LicenseActionOperationType.AutorenewOff
			: LicenseActionOperationType.AutorenewOn;
		handleCreateLicenseAction([variant], operationType);
	};

	return (
		<Table className={styles.table}>
			<TableHead>
				<TableRow>
					<TableCell>Term duration</TableCell>
					<TableCell>
						<Grid item className={styles.eightyPercent}>
							Quantity
						</Grid>
					</TableCell>
					<TableCell>
						<Grid item className={styles.eightyPercent}>
							Scheduled for removal
						</Grid>
					</TableCell>
					<TableCell className={styles.costHeader}>Cost</TableCell>
					<TableCell>Auto renewal</TableCell>
					<TableCell>End Date</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{variants.map((variant) => {
					return (
						<RegularRow
							key={variant.subscriptionId}
							variant={variant}
							scheduledOrInProgressLicenseActionsForSku={
								scheduledOrInProgressLicenseActionsForSku
							}
							licensePrices={licensePrices}
							totalQuantity={totalQuantity}
							completeLicenseDetails={completeLicenseDetails}
							handleChangeAutoRenewStatus={handleChangeAutoRenewStatus}
						/>
					);
				})}
				{!_.isEmpty(newlyCreatedSubscription) && (
					<NewSubscriptionRow variant={newlyCreatedSubscription} />
				)}
			</TableBody>
		</Table>
	);
};

interface RegularRowProps {
	variant: SubscriptionVariant;
	scheduledOrInProgressLicenseActionsForSku: Record<string, any>;
	licensePrices: Dictionary<LicensePrice>;
	totalQuantity: number;
	completeLicenseDetails: any;
	handleChangeAutoRenewStatus: (variant: SubscriptionVariant) => Promise<void>;
}

const RegularRow = ({
	variant,
	scheduledOrInProgressLicenseActionsForSku,
	licensePrices,
	totalQuantity,
	completeLicenseDetails,
	handleChangeAutoRenewStatus,
}: RegularRowProps) => {
	const { isActionRole } = useRole();
	const priceObject = licensePrices[variant.costIdentifier] ?? ({} as LicensePrice);
	return (
		<TableRow key={variant.subscriptionId} className={styles.tableRow}>
			<TableCell>
				<TermDurationLabel label={variant.termDuration} />
			</TableCell>
			<TableCell>
				<Grid item className={styles.eightyPercent}>
					{variant.quantity}
				</Grid>
			</TableCell>
			<TableCell>
				<Grid item className={styles.eightyPercent}>
					{`${Math.abs(
						scheduledOrInProgressLicenseActionsForSku[variant.subscriptionId]
							?.removals ?? 0,
					)}`}
				</Grid>
			</TableCell>
			<TableCell>
				{formatCostString(
					"",
					Math.round(priceObject?.UnitPriceAfterDiscount ?? 0),
					` ,- / ${priceObject?.CostPeriod ?? ""}`,
				)}
			</TableCell>
			<TableCell>
				<AutorenewToggle
					variant={variant}
					totalConsumedQuantity={completeLicenseDetails?.consumedQuantity ?? 0}
					totalLicenseQuantity={totalQuantity}
					onToggle={async () => await handleChangeAutoRenewStatus(variant)}
					disabled={
						(!isActionRole ||
							scheduledOrInProgressLicenseActionsForSku[variant.subscriptionId]
								?.autoRenewChanged) ??
						false
					}
				/>
			</TableCell>
			<TableCell>
				<Typography
					variant="body1"
					className={clsx({
						[styles.endDateAndNotRenewed]: !variant.autoRenewEnabled,
					})}
				>
					{dayjs(variant.commitmentEndDate).format("DD.MM.YYYY")}
				</Typography>
			</TableCell>
		</TableRow>
	);
};

const NewSubscriptionRow = ({ variant }: { variant: SubscriptionVariant }) => (
	<TableRow key={variant.subscriptionId} className={styles.tableRow}>
		<TableCell colSpan={1}>
			<Typography variant="body1">
				<TermDurationLabel label={variant.termDuration} />
			</Typography>
		</TableCell>
		<TableCell colSpan={1}>
			<Grid item className={styles.eightyPercent}>
				{variant.quantity}
			</Grid>
		</TableCell>
		<TableCell colSpan={4}>
			<Skeleton height={40} />
		</TableCell>
	</TableRow>
);
