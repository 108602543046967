import dayjs from "dayjs";
import { formatCostString } from "./currency/numberFormattingUtility.js";

const thickBottomBorder = {
	id: "thickBottomBorder",
	beforeDatasetsDraw: (chart) => {
		const {
			ctx,
			chartArea: { bottom, right },
		} = chart;

		ctx.save();
		ctx.beginPath();
		ctx.moveTo(3, bottom); // start at bottom left
		ctx.lineTo(right, bottom); // draw to bottom right
		ctx.lineWidth = 4; // set line width
		ctx.strokeStyle = "rgba(170, 170, 170, 0.2)"; // set line color
		ctx.stroke();
		ctx.restore();
	},
};

const marginOnGridLines = ({ marginStart = 0, marginEnd = 0 }) => {
	return {
		id: "marginOnGridLines",
		beforeDatasetsDraw: (chart) => {
			const {
				ctx,
				scales: { y, x },
			} = chart;

			// foreach line in x axis
			y.ticks.forEach((tick, index) => {
				ctx.save();
				ctx.beginPath();
				ctx.moveTo(x.left + marginStart, y.getPixelForTick(index));
				ctx.lineTo(x.right - marginEnd, y.getPixelForTick(index));
				ctx.strokeStyle = "rgba(170, 170, 170, 0.5)";
				ctx.stroke();
				ctx.restore();
			});
		},
	};
};

const getLabel = (context) => {
	const chart = context.chart;
	const dataset = chart.data.datasets[context.datasetIndex];
	const label = dataset.label;
	const propertyToLookFor = label.replace(/[\s-]/g, "").toLowerCase();
	const costDataFromIndex = dataset.data[context.dataIndex] ?? {};
	const period = costDataFromIndex.x;
	const total =
		costDataFromIndex[
			Object.keys(costDataFromIndex).find((key) => key.toLowerCase() === propertyToLookFor) ??
				""
		];

	const invoicePeriod = total > 0 ? getInvoicePeriod(period, label) : "";

	return `  ${label}: ${formatCostString("", total, " ,-")}    ${invoicePeriod}`;
};

const getAfterBody = (context) => {
	const chart = context[0].chart;
	const datasets = chart.data.datasets;
	const dataIndex = context[0].dataIndex;
	let totalCost = 0;

	datasets.forEach((dataset) => {
		const costDataFromIndex = dataset.data[dataIndex] ?? {};
		const propertyToLookFor = dataset.label.replace(/[\s-]/g, "").toLowerCase();
		const cost =
			costDataFromIndex[
				Object.keys(costDataFromIndex).find(
					(key) => key.toLowerCase() === propertyToLookFor,
				) ?? ""
			];

		totalCost += cost;
	});

	return `\nTotal cost: ${formatCostString("", totalCost, " ,- (ex. VAT)")}`;
};

const getInvoicePeriod = (period, label) => {
	const lowerCased = label.toLowerCase();
	const isAzureConsuptiom = lowerCased.includes("azure");
	const isOffice365 = lowerCased.includes("license");

	if (isAzureConsuptiom || isOffice365) {
		const invoicePeriod = dayjs(period, "MMMM YY").add(1, "month").format("MMMM");
		return `(${invoicePeriod} invoice)`;
	}

	return "";
};

export { thickBottomBorder, marginOnGridLines, getLabel, getAfterBody };
