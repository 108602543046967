import { HardwareProduct, PriceInfo } from "types";
import { HardwarePurchaseType } from "utilities/constants/enums";

export const calculateHardwarePrice = (
	priceInfo: PriceInfo | undefined,
	hardwarePurchaseType: HardwarePurchaseType,
) => {
	if (!priceInfo) {
		return {
			subText: "",
			price: 0,
			priceExcludingCustomerDiscount: 0,
			discountPercentage: 0,
		};
	}
	const { priceNet, priceNetExcludingCustomerDiscount, discountPercentage, leasingFactor } =
		priceInfo ?? ({ priceNet: 0, priceNetExcludingCustomerDiscount: 0 } as PriceInfo);

	let subText = "excl. vat";
	let shownPriceNet = priceNet;
	let shownPriceNetExcludingCustomerDiscount = priceNetExcludingCustomerDiscount;

	if (hardwarePurchaseType === HardwarePurchaseType.Lease) {
		subText = "per month";
		shownPriceNet = priceNet * leasingFactor;
		shownPriceNetExcludingCustomerDiscount = priceNetExcludingCustomerDiscount * leasingFactor;
	}

	return {
		price: Number(shownPriceNet),
		priceExcludingCustomerDiscount: Number(shownPriceNetExcludingCustomerDiscount),
		discountPercentage: Number(discountPercentage),
		subText,
	};
};

// Util used for setting base price within redux handlers
export const calculateHardwareProductBasePrice = (
	product: HardwareProduct | undefined,
	hardwarePurchaseType: HardwarePurchaseType,
) => {
	if (!product) return 0;
	let basePrice = product.priceInfo.priceNet ?? 0;
	if (hardwarePurchaseType === HardwarePurchaseType.Lease) {
		// Apply leasing factor to basePrice if purchaseType is lease
		basePrice = basePrice * (product.priceInfo?.leasingFactor ?? 1);
	}
	return basePrice;
};
