import { useMemo } from "react";
import { LinearProgress, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import { useAppSelector, useAuth } from "hooks/hooks";
import { HoverTooltip } from "components/Common/Tooltips";
import { IRONSTONE_SUPPORT_MAIL } from "utilities/constants/constants";
import { DocumentType, DownloadFileStatusEnum } from "utilities/constants/enums";
import { InternalLink } from "../Links";

import styles from "./DownloadableDocument.module.scss";
import { fetchInvoice } from "actions/invoiceActions";
import { selectCustomerDetails } from "features/customer";
import { fetchCustomerDocument } from "actions/customerActions";

interface DownloadableDocumentProps {
	id: string | number;
	documentTitle: string;
	selector: (state: any) => any;
	documentType: DocumentType;
	documentDescription?: string;
	fileType: string;
}

export const DownloadableDocument = ({
	id,
	documentTitle,
	selector,
	documentType,
	documentDescription,
	fileType,
}: DownloadableDocumentProps) => {
	const { auth, dispatch } = useAuth();
	const documentDownloadStatuses = useAppSelector(selector);
	const customerDetails = useAppSelector(selectCustomerDetails);

	const content = useMemo(() => {
		const handleGetDocument = async (id: string | number) => {
			if (documentType === DocumentType.Invoice) {
				await dispatch(
					fetchInvoice({
						auth,
						id,
						headers: {
							Accept: "application/zip",
						},
						responseType: "blob",
						customerName: customerDetails?.departmentName ?? "",
						invoiceText: documentDescription,
					}),
				);
			} else {
				await dispatch(fetchCustomerDocument({ auth, attachmentId: id, fileType }));
			}
		};

		switch (documentDownloadStatuses[id]) {
			case DownloadFileStatusEnum.Downloading:
				return <LinearProgress color="inherit" classes={{ root: styles.progress }} />;
			case DownloadFileStatusEnum.Error:
				return (
					<HoverTooltip
						title="Download failed"
						description={`Unfortunately, we were unable to download the document. Please contact support at ${IRONSTONE_SUPPORT_MAIL} for assistance. Make sure to include the document ID: ${id} in your email.`}
					>
						<Typography variant="body2" color="error">
							Download failed
						</Typography>
					</HoverTooltip>
				);
			default:
				return (
					<InternalLink variant="body2" onClick={() => handleGetDocument(id)}>
						<DownloadIcon
							sx={{
								fontSize: 16,
								mr: 1,
							}}
						/>
						<span className={styles.linkText}>{`${documentTitle}`}</span>
					</InternalLink>
				);
		}
	}, [
		documentDownloadStatuses,
		id,
		documentTitle,
		auth,
		dispatch,
		documentType,
		customerDetails?.departmentName,
		documentDescription,
		fileType,
	]);

	return <>{content}</>;
};
