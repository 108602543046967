import { useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid, IconButton, InputBase, MenuItem, Select } from "@mui/material";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";

import SearchIcon from "@mui/icons-material/Search";

import { SecondaryButton } from "components/Common/Buttons/Button";
import { useAppSelector, useAuth } from "hooks/hooks";
import { LicenseActionStatus } from "utilities/constants/enums";
import {
	selectCostSavingRecommendations,
	setIsScheduledActionsDialogOpen,
} from "features/recommendations";

import styles from "./ScheduledActionsDialog.module.scss";
import { ScheduledActionsTable } from "./ScheduledActionsTable";
import {
	selectLicenseActionSkuFilter,
	selectSortedGroupedLicenseActions,
	setLicenseActionSkuFilter,
	setLicenseActionsFilter,
	setLicenseActionsSearchValue,
} from "features/scheduledActions";
import { debounce } from "utilities/debounce";

interface ScheduledChangesDialogInputProps {
	isScheduledActionsLoading: boolean;
}

const ScheduledChangesDialog = ({
	isScheduledActionsLoading,
}: ScheduledChangesDialogInputProps) => {
	const { dispatch } = useAuth();

	const skuFilter = useAppSelector(selectLicenseActionSkuFilter);
	const [localSearchValue, setLocalSearchValue] = useState("");
	const [localStatusFilter, setLocalStatusFilter] = useState("All");
	const [localSkuFilter, setLocalSkuFilter] = useState("All");
	const [isLoading, setIsLoading] = useState(false);
	const costSavingRecommendations = useAppSelector(selectCostSavingRecommendations);

	useEffect(() => {
		if (!skuFilter) return;
		setLocalSkuFilter(skuFilter);
	}, [skuFilter]);

	const initializeSearch = (
		searchValue: string,
		statusFilter: LicenseActionStatus,
		skuFilter: string,
	) => {
		dispatch(setLicenseActionsSearchValue(searchValue));
		dispatch(setLicenseActionsFilter(statusFilter));
		dispatch(setLicenseActionSkuFilter(skuFilter));
		setIsLoading(false);
	};

	const debouncedSearch = useMemo(
		() =>
			debounce(
				(
					localSearchValue: string,
					localStatusFilter: LicenseActionStatus,
					localSkuFilter: string,
				) => initializeSearch(localSearchValue, localStatusFilter, localSkuFilter),
				750,
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[localSearchValue, localStatusFilter, localSkuFilter],
	);

	const handleStatusFilterChange = (value: LicenseActionStatus) => {
		setIsLoading(true);
		setLocalStatusFilter(value);
		debouncedSearch(localSearchValue, value, localSkuFilter);
	};

	const handleSkuFilterChange = (value: string) => {
		setIsLoading(true);
		setLocalSkuFilter(value);
		debouncedSearch(localSearchValue, localStatusFilter, value);
	};

	const handleSearchValueChange = (value: string) => {
		setIsLoading(true);
		setLocalSearchValue(value);
		debouncedSearch(value, localStatusFilter, localSkuFilter);
	};

	const groupedActions = useAppSelector(selectSortedGroupedLicenseActions);

	const [isOpen, setIsOpen] = useState<Record<string, boolean>>(
		Object.entries(groupedActions).reduce((acc, [groupId, { groupStatus }]) => {
			// By default open all groups that have scheduled actions

			acc[groupId] = groupStatus === LicenseActionStatus.Scheduled;
			return acc;
		}, {} as Record<string, boolean>),
	);

	const handleExpandAll = () => {
		setIsOpen((prevIsOpen) => {
			return Object.keys(prevIsOpen).reduce((acc, groupId) => {
				acc[groupId] = !allExpanded;
				return acc;
			}, {} as Record<string, boolean>);
		});
	};

	const handleSetIsOpen = (groupActionId: string) => {
		setIsOpen((prevIsOpen) => {
			return {
				...prevIsOpen,
				[groupActionId]: !prevIsOpen[groupActionId],
			};
		});
	};

	const allExpanded = useMemo(() => {
		return Object.values(isOpen).every((isOpen) => isOpen);
	}, [isOpen]);

	return (
		<PrimaryDialog
			title={"Scheduled actions"}
			size="large"
			onLeaveAction={() => dispatch(setIsScheduledActionsDialogOpen(false))}
		>
			<Grid container className={styles.contentContainer}>
				<Grid container flexDirection={"row"} px={2}>
					<Grid container item xs={10} gap={2}>
						<Grid item xs={6}>
							<div className={styles.inputRoot}>
								<IconButton disabled className={styles.iconButton}>
									<SearchIcon />
								</IconButton>
								<InputBase
									value={localSearchValue}
									className={styles.input}
									placeholder="Search for names, license or scheduled by"
									onChange={(e) => {
										setLocalSearchValue(e.target.value);
										handleSearchValueChange(e.target.value);
									}}
								/>
							</div>
						</Grid>
						<Grid item xs={2}>
							<Select
								className={styles.dropdown}
								value={localStatusFilter}
								onChange={({ target: { value } }) => {
									if (value) {
										setLocalStatusFilter(value as LicenseActionStatus);
										handleStatusFilterChange(value as LicenseActionStatus);
									}
								}}
								inputProps={{ MenuProps: { disableScrollLock: true } }}
							>
								<MenuItem
									key={1}
									value={"All"}
									classes={{ root: styles.inputText }}
								>
									All Statuses
								</MenuItem>
								<MenuItem
									key={2}
									value={LicenseActionStatus.Finished}
									classes={{ root: styles.inputText }}
								>
									{LicenseActionStatus.Finished}
								</MenuItem>
								<MenuItem
									key={3}
									value={LicenseActionStatus.Scheduled}
									classes={{ root: styles.inputTextHeader }}
								>
									{LicenseActionStatus.Scheduled}
								</MenuItem>
								<MenuItem
									key={4}
									value={LicenseActionStatus.Cancelled}
									classes={{ root: styles.inputText }}
								>
									{LicenseActionStatus.Cancelled}
								</MenuItem>
								<MenuItem
									key={5}
									value={LicenseActionStatus.Error}
									classes={{ root: styles.inputText }}
								>
									{LicenseActionStatus.Error}
								</MenuItem>
								<MenuItem
									key={6}
									value={LicenseActionStatus.PartiallyFinished}
									classes={{ root: styles.inputText }}
								>
									{LicenseActionStatus.PartiallyFinished}
								</MenuItem>
							</Select>
						</Grid>
						<Grid item xs={3}>
							<Select
								className={styles.dropdownSku}
								value={localSkuFilter}
								onChange={({ target: { value } }) => {
									if (value) {
										setLocalSkuFilter(value as string);
										handleSkuFilterChange(value as string);
									}
								}}
								inputProps={{ MenuProps: { disableScrollLock: true } }}
							>
								<MenuItem
									key={0}
									value={"All"}
									classes={{ root: styles.inputText }}
								>
									All licenses
								</MenuItem>
								{costSavingRecommendations.map((recommendation) => (
									<MenuItem
										key={recommendation.subscriptionSkuID}
										value={recommendation.subscriptionSkuID}
										classes={{ root: styles.inputText }}
									>
										{recommendation.subscriptionOfferName}
									</MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
					<Grid item xs={2} className={styles.expandAllButtonContainer}>
						<SecondaryButton
							text={allExpanded ? "Collapse all" : "Expand all"}
							size="small"
							variantStyle="outlined"
							action={handleExpandAll}
						/>
					</Grid>
				</Grid>

				{!isLoading ? (
					<ScheduledActionsTable
						{...{
							isOpen,
							handleSetIsOpen,
							groupedActions,
							isScheduledActionsLoading,
						}}
					/>
				) : (
					<div className={styles.loading}>
						<CircularProgress />
					</div>
				)}
			</Grid>
		</PrimaryDialog>
	);
};

export default ScheduledChangesDialog;
