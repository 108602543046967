import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import {
	fetchScheduledActions,
	createLicenseActions,
	updateScheduledAction,
	executeLicenseActions,
} from "actions/scheduledActionActions";
import dayjs from "dayjs";
import { RootState } from "store";
import { GroupedActions, LicenseAction, LicenseActionEffect, SubscriptionVariant } from "types";
import { LICENSE_ACTION_DATE_FORMAT } from "utilities/constants/constants";
import {
	LicenseActionOperationType,
	LicenseActionStatus,
	LicenseActionTargetType,
} from "utilities/constants/enums";

// License actions slice. These are actions that can be taken on a license subscription.
// E.g changing license subscription quantity, creating license subscriptions, etc.
const scheduledActionsAdapter = createEntityAdapter<LicenseAction>({
	selectId: (licenseAction) => licenseAction.ActionId,
});

const scheduledActionsSlice = createSlice({
	name: "scheduledActions",
	initialState: scheduledActionsAdapter.getInitialState({
		isLoading: true,
		isFetching: false,
		licenseActionsFilter: {
			searchValue: "",
			statusFilter: "All",
			skuFilter: "All",
			activeStep: 0,
		},
		purchaseActionInfo: {
			isPurchasing: false,
			isError: false,
		},
		recipientMailState: {
			mail: "",
			isValid: true,
		},
		// Keeps track of the last action(s) that were scheduled for execution, used on confirmation pages
		lastScheduledActionIds: [] as string[],
	}),
	reducers: {
		setLicenseActionsFilter: (state, { payload }) => {
			state.licenseActionsFilter.statusFilter = payload;
		},
		setLicenseActionsSearchValue: (state, { payload }) => {
			state.licenseActionsFilter.searchValue = payload;
		},
		setLicenseActionSkuFilter: (state, { payload }) => {
			state.licenseActionsFilter.skuFilter = payload;
		},
		setActiveStep: (state, { payload }) => {
			state.licenseActionsFilter.activeStep = payload;
		},
		updateLicenseActionStatus: (state, { payload }) => {
			const { ids, status } = payload;
			const updatedActions = ids.reduce((acc: LicenseAction[], curr: string) => {
				const action = state.entities[curr];
				if (!action) return acc;
				const updatedAction = updateAction(action, status);
				acc.push(updatedAction);
				return acc;
			}, [] as LicenseAction[]);
			scheduledActionsAdapter.upsertMany(state, updatedActions);
		},
		setRecipientMailState: (state, { payload }) => {
			state.recipientMailState = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchScheduledActions.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchScheduledActions.fulfilled, (state, { payload }) => {
				const { data } = payload as any;
				const scheduledActions = data.map(mapScheduledAction);
				scheduledActionsAdapter.upsertMany(state, scheduledActions);
				state.isLoading = false;
				state.isFetching = false;
			})
			.addCase(fetchScheduledActions.rejected, (state) => {
				state.isLoading = false;
				state.isFetching = false;
			});
		builder
			.addCase(updateScheduledAction.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(updateScheduledAction.fulfilled, (state, { payload }) => {
				const { data } = payload as any;
				const scheduledActions = data.map(mapScheduledAction);
				scheduledActionsAdapter.upsertMany(state, scheduledActions);
			})
			.addCase(updateScheduledAction.rejected, (state) => {
				state.isLoading = false;
			});
		builder
			.addCase(createLicenseActions.pending, (state, { meta }) => {
				state.isFetching = true;
				const actions = meta.arg.body as LicenseAction[];
				const scheduledActions = actions
					.map(mapScheduledActionToDatabase)
					.map((action: any) => checkIfShouldChangeStatusToInProgress(action));
				scheduledActionsAdapter.upsertMany(state, scheduledActions);
				const isLicensePurchase = checkIfAnyLicensePurchase(scheduledActions);
				if (isLicensePurchase) {
					state.purchaseActionInfo.isPurchasing = true;
				}
				state.lastScheduledActionIds = scheduledActions.map((action) => action.ActionId);
			})
			.addCase(createLicenseActions.fulfilled, (state, { payload }) => {
				const { data } = payload as any;
				const scheduledActions = data.map(mapScheduledAction);
				scheduledActionsAdapter.upsertMany(state, scheduledActions);
				state.isLoading = false;
				const isLicensePurchase = checkIfAnyLicensePurchase(scheduledActions);
				if (isLicensePurchase) {
					state.purchaseActionInfo.isPurchasing = false;
					state.purchaseActionInfo.isError = false;
				}
			})
			.addCase(createLicenseActions.rejected, (state, { payload }) => {
				const { data } = payload as any;
				const scheduledActions = data.map(mapScheduledAction);
				scheduledActionsAdapter.upsertMany(state, scheduledActions);
				state.isLoading = false;
				state.purchaseActionInfo = {
					isPurchasing: false,
					isError: true,
				};
			});
		builder
			.addCase(executeLicenseActions.pending, (state, { meta }) => {
				state.isFetching = true;
				const actions = meta.arg.body as LicenseAction[];
				const updatedActions = actions.map((action) => {
					return updateAction(action, LicenseActionStatus.InProgress);
				});
				scheduledActionsAdapter.upsertMany(state, updatedActions);
			})
			.addCase(executeLicenseActions.fulfilled, (state) => {
				// Why not set the status to finished here?
				// When we set the status to finished, all UI components that are subscribed to the license actions
				// will see these actions as finished, we need them to be InProgress until we have fetched the
				// updated SUBSCRIPTION that was affected by the action(s)
				state.isLoading = false;
			})
			.addCase(executeLicenseActions.rejected, (state, { meta }) => {
				const actions = meta.arg.body as LicenseAction[];
				const updatedActions = actions.map((action) => {
					return updateAction(action, LicenseActionStatus.Error);
				});
				scheduledActionsAdapter.upsertMany(state, updatedActions);
				state.isLoading = false;
			});
	},
});

export const selectLicenseActions = (state: RootState) => state.scheduledActions;
export const selectLicenseRecommentaions = (state: RootState) => state.scheduledActions;
export const selectPurchaseActionInfo = (state: RootState) =>
	state.scheduledActions.purchaseActionInfo;

export const selectLicenseActionSavingsLastMonth = (state: RootState) => {
	const actions = Object.values(state.scheduledActions.entities) as LicenseAction[];
	const daysAgo = dayjs().subtract(32, "day");
	const savingsLastMonth = actions.reduce((acc, curr) => {
		// Only count finished, partially finished and scheduled actions
		if (
			curr.Status !== LicenseActionStatus.Finished &&
			curr.Status !== LicenseActionStatus.PartiallyFinished &&
			curr.Status !== LicenseActionStatus.Scheduled
		) {
			return acc;
		}
		// Only count actions that happened in the last 32 days
		if (!dayjs(curr.ExecutionDate).isAfter(daysAgo)) {
			return acc;
		}

		return acc + curr.TotalSavings * 12;
	}, 0);

	return savingsLastMonth;
};

export const selectLastScheduledActions = createSelector(
	(state: RootState) => state.scheduledActions.lastScheduledActionIds,
	(state: RootState) => state.scheduledActions.entities,
	(ids, entities) => {
		return ids.map((id) => entities[id]);
	},
);

export const selectLicenseActionsWithSkuGUIDKeys = (state: RootState) =>
	Object.values(state.scheduledActions.entities).reduce((acc, curr) => {
		if (!curr) return acc;
		const sorted = [...(acc[curr.SkuGUID] ?? []), curr].sort((a, b) => {
			return dayjs(a.TableTimestamp).isAfter(dayjs(b.TableTimestamp)) ? -1 : 1;
		});

		acc[curr.SkuGUID] = sorted;
		return acc;
	}, {} as Record<string, LicenseAction[]>);

export const selectUsersScheduledForRemoval = (state: RootState) => {
	const actions = Object.values(state.scheduledActions.entities) as LicenseAction[];
	return actions.reduce((acc, curr) => {
		if (
			curr.TargetType !== LicenseActionTargetType.User ||
			curr.Status !== LicenseActionStatus.Scheduled
		)
			return acc;
		acc.push(curr.TargetGUID);
		return acc;
	}, [] as string[]);
};

export const selectGroupedLicenseActions = (state: RootState) => {
	const actions = Object.values(state.scheduledActions.entities ?? {}) as LicenseAction[];
	return processAndGroupLicenseActions(actions);
};

export const selectSortedGroupedLicenseActions = (state: RootState) => {
	const { searchValue, statusFilter, skuFilter } = state.scheduledActions.licenseActionsFilter;
	const actions = Object.values(state.scheduledActions.entities) as LicenseAction[];
	return processAndGroupLicenseActions(actions, searchValue, statusFilter, skuFilter);
};

export const selectLicenseActionSkuFilter = (state: RootState) =>
	state.scheduledActions.licenseActionsFilter.skuFilter;

export const selectActiveStep = (state: RootState) =>
	state.scheduledActions.licenseActionsFilter.activeStep;

export const selectRecipientMailState = (state: RootState) =>
	state.scheduledActions.recipientMailState;

export const {
	setLicenseActionsFilter,
	setLicenseActionsSearchValue,
	setLicenseActionSkuFilter,
	setActiveStep,
	updateLicenseActionStatus,
	setRecipientMailState,
} = scheduledActionsSlice.actions;

export const licenseActionsSelector = scheduledActionsAdapter.getSelectors(selectLicenseActions);

export default scheduledActionsSlice.reducer;

/**
 * ${skuGuid} is the sku guid of the license
 * This selector is used to "recalculate" the state of a subscription, based on ongoing or scheduled actions
 **/
export const selectScheduledLicenseActionsForSku = (skuGuid: string) =>
	createSelector(licenseActionsSelector.selectAll, (actions) => {
		return actions.reduce((acc, curr) => {
			if (curr.SkuGUID !== skuGuid) return acc;
			if (
				curr.Status !== LicenseActionStatus.Scheduled &&
				curr.Status !== LicenseActionStatus.InProgress
			)
				return acc;
			if (curr.TargetType !== LicenseActionTargetType.Subscription) return acc;

			if (!acc[curr.TargetGUID]) {
				acc[curr.TargetGUID] = {
					removals: 0,
					additions: 0,
					autoRenewChanged: false,
				};
			}

			if (curr.QuantityChange < 0) {
				acc[curr.TargetGUID].removals += curr.QuantityChange;
			} else if (curr.QuantityChange > 0) {
				acc[curr.TargetGUID].additions += curr.QuantityChange;
			} else if (
				(curr.Operation === LicenseActionOperationType.AutorenewOff ||
					curr.Operation === LicenseActionOperationType.AutorenewOn) &&
				curr.Status === LicenseActionStatus.InProgress
				// Only autorenew actions that are in progress are considered when determining if the autoRenewChanged
				// flag should be set to true, if this is true, certain actions will be blocked
			) {
				acc[curr.TargetGUID].autoRenewChanged = true;
			}
			return acc;
		}, {} as Record<string, { removals: number; additions: number; autoRenewChanged: boolean }>);
	});

export const selectNumScheduledActionsForSku = (skuGuid: string) =>
	createSelector(licenseActionsSelector.selectAll, (actions) => {
		return actions.reduce((acc, curr) => {
			if (curr.SkuGUID !== skuGuid) return acc;
			if (
				curr.Status !== LicenseActionStatus.Scheduled &&
				curr.Status !== LicenseActionStatus.InProgress
			)
				return acc;
			if (curr.TargetType !== LicenseActionTargetType.Subscription) return acc;
			acc += 1;
			return acc;
		}, 0);
	});

export const selectNumLicensesScheduledForRemoval = (skuGuid: string) =>
	createSelector(licenseActionsSelector.selectAll, (actions) => {
		return actions.reduce((acc, curr) => {
			if (curr.SkuGUID !== skuGuid) return acc;
			if (
				curr.Status !== LicenseActionStatus.Scheduled &&
				curr.Status !== LicenseActionStatus.InProgress
			)
				return acc;
			if (curr.TargetType !== LicenseActionTargetType.Subscription) return acc;
			if (curr.QuantityChange > 0) return acc;
			acc += Math.abs(curr.QuantityChange);
			return acc;
		}, 0);
	});

export const selectNewlyCreatedSubscriptionForSku = (skuGuid: string) =>
	createSelector(licenseActionsSelector.selectAll, (actions) => {
		return actions.reduce((acc, curr) => {
			if (!curr) return acc;
			if (curr.SkuGUID !== skuGuid) return acc;
			if (
				curr.Status !== LicenseActionStatus.Scheduled &&
				curr.Status !== LicenseActionStatus.InProgress
			)
				return acc;
			if (curr.Operation !== LicenseActionOperationType.CreateNewSubscription) return acc;

			return {
				termDuration: curr.PurchaseInfo.TermDuration,
				autoRenewEnabled: true,
				quantity: curr.QuantityChange,
			} as SubscriptionVariant;
		}, {} as SubscriptionVariant);
	});

export const selectLicenseActionEffectsOnSubscriptions = (state: RootState) => {
	const actions = Object.values(state.scheduledActions.entities) as LicenseAction[];
	return calculateLicenseActionEffects(actions);
};

export const calculateLicenseActionEffects = (actions: LicenseAction[]) => {
	const recentlyAffectedSubscriptions = actions.reduce((acc, curr) => {
		const isInProgress = curr.Status === LicenseActionStatus.InProgress;
		const isSubscriptionAction = curr.TargetType === LicenseActionTargetType.Subscription;
		if (!isInProgress || !isSubscriptionAction) return acc;

		// Subscriptions that have InProgress actions within the last (10) minutes are considered recently affected
		const updatedWithinThershold = dayjs(curr.TableTimestamp).isAfter(
			dayjs().subtract(5, "minutes"),
		); // may not be necessary check, as we always want to calculate effects of InProgress actions
		if (!updatedWithinThershold) return acc;

		if (!acc[curr.SkuGUID]) {
			acc[curr.SkuGUID] = {};
		}

		if (!acc[curr.SkuGUID][curr.TargetGUID]) {
			acc[curr.SkuGUID][curr.TargetGUID] = {
				lastModifiedAt: new Date(curr.TableTimestamp),
				autoRenewal: { modified: false, newValue: false, action: {} as LicenseAction },
				quantity: { modified: false, changeQuantity: 0, actions: [] as LicenseAction[] },
			} as LicenseActionEffect;
		}

		const updatedEffect = updateCurrentEffects(acc[curr.SkuGUID][curr.TargetGUID], curr);
		acc[curr.SkuGUID][curr.TargetGUID] = updatedEffect;

		return acc;
	}, {} as Record<string, { [key: string]: LicenseActionEffect }>);
	// Keeps track of the skuGuid and the subscriptionGuids that have been affected
	// by actions InProgress within the last ${FIVE_MINUTES} minutes
	return recentlyAffectedSubscriptions;
};

const updateCurrentEffects = (currentEffects: LicenseActionEffect, action: LicenseAction) => {
	const actionUpdatedAt = new Date(action.TableTimestamp);
	if (actionUpdatedAt > currentEffects.lastModifiedAt) {
		currentEffects.lastModifiedAt = actionUpdatedAt;
	}

	let updatedEffects = { ...currentEffects };
	switch (action.Operation) {
		case LicenseActionOperationType.AdjustLicenseCount:
			updatedEffects = {
				...currentEffects,
				quantity: {
					modified: true,
					changeQuantity: currentEffects.quantity.changeQuantity + action.QuantityChange,
					actions: [...currentEffects.quantity.actions, action],
				},
			};
			break;
		case LicenseActionOperationType.AutorenewOn:
		case LicenseActionOperationType.AutorenewOff:
			updatedEffects = {
				...currentEffects,
				autoRenewal: {
					modified: true,
					newValue: action.Operation === LicenseActionOperationType.AutorenewOn,
					action,
				},
			};
			break;
		default:
			return updatedEffects;
	}

	return updatedEffects;
};

const processAndGroupLicenseActions = (
	licenseActions: LicenseAction[],
	searchValue: string = "",
	statusFilter: string = "All",
	skuFilter: string = "All",
) => {
	const processedActions = licenseActions
		.reduce((acc: LicenseAction[], action: LicenseAction) => {
			// Filter
			if (!filterLicenseAction(action, searchValue, statusFilter, skuFilter)) return acc;

			acc.push(action);
			return acc;
		}, [])
		.sort(sortLicenseActions);

	return groupActions(processedActions);
};

// Grouping by group action id, status and targetType gives us the ability to group actions that are conducted together
// but have different statuses (e.g. scheduled and finished) and target types. Happens when you soft delete users w/ licenses
const groupActions = (actions: LicenseAction[]): Record<string, GroupedActions> => {
	return actions.reduce((groups, action) => {
		// Only group actions that are scheduled, finished or partially finished in the same group
		const statusGrouper =
			action.Status === LicenseActionStatus.Cancelled
				? "Cancelled"
				: "ScheduledOrFinishedOrError";
		const grouperId = action.SkuGUID + statusGrouper;

		if (!groups[grouperId]) {
			groups[grouperId] = {
				groupStatus: action.Status,
				skuGUID: action.SkuGUID,
				licenseDisplayName: "",
				licenseActions: [],
				numScheduledActions: 0,
				userRemovals: 0,
				licenseRemovals: 0,
			};
		}

		if (action.TargetType !== LicenseActionTargetType.Subscription) {
			groups[grouperId].userRemovals++;
		}

		if (action.TargetType === LicenseActionTargetType.Subscription) {
			groups[grouperId].licenseDisplayName = action.TargetFriendlyName;
			groups[grouperId].licenseRemovals += action.QuantityChange;
			if (action.Status === LicenseActionStatus.Scheduled)
				groups[grouperId].numScheduledActions++;
		}

		groups[grouperId].licenseActions.push(action);

		return groups;
	}, {} as Record<string, GroupedActions>);
};

const filterLicenseAction = (
	action: LicenseAction,
	searchValue: string,
	statusFilter: string,
	skuFilter: string,
) => {
	const maxDaysOld = dayjs().subtract(1.5, "year");
	return (
		dayjs(action.OrderDate).isAfter(maxDaysOld) &&
		(action.TargetFriendlyName.toLowerCase().includes(searchValue?.toLowerCase()) ||
			action.TargetType.toLowerCase().includes(searchValue?.toLowerCase()) ||
			action.OrderedBy.toLowerCase().includes(searchValue?.toLowerCase())) &&
		(action.Status === statusFilter || statusFilter === "All") &&
		(action.SkuGUID === skuFilter || skuFilter === "All")
	);
};

const sortLicenseActions = (a: LicenseAction, b: LicenseAction) => {
	const priorityA = getStatusPriority(a.Status);
	const priorityB = getStatusPriority(b.Status);

	// Sort by status priority
	if (priorityA < priorityB) return -1;
	if (priorityA > priorityB) return 1;

	// If statuses are the same, sort by date
	if (dayjs(a.ExecutionDate).isBefore(dayjs(b.ExecutionDate))) return -1;
	if (dayjs(a.ExecutionDate).isAfter(dayjs(b.ExecutionDate))) return 1;

	return 0;
};

const mapScheduledActionToDatabase = (action: LicenseAction) => {
	// Two special columns in our database are stored as strings, but can be viewed as objects (StatusInfo and PurchaseInfo)
	const statusInfoAsString = JSON.stringify(action.StatusInfo);
	const purchaseInfoAsString = JSON.stringify(action.PurchaseInfo);
	return {
		...action,
		StatusInfo: statusInfoAsString,
		PurchaseInfo: purchaseInfoAsString,
	};
};

const mapScheduledAction = (action: LicenseAction) => {
	// Two special columns in our database are stored as strings, but can be viewed as objects (StatusInfo and PurchaseInfo)
	const parsed =
		typeof action.StatusInfo === "string" ? JSON.parse(action.StatusInfo) : action.StatusInfo;
	const parsedPurchaseInfo =
		typeof action.PurchaseInfo === "string"
			? JSON.parse(action.PurchaseInfo)
			: action.PurchaseInfo;

	// Sometimes, we create an action, and then kickstart execution of that action.
	// Then, we should show "InProgress" as status, not "Scheduled". If the user refreshes right after creating the action,
	// redux will receive a "Scheduled" action from the backend, but it's really "InProgress", as the backend might not yet have
	// updated the status to "InProgress". A couple of checks below ensures that we show "InProgress" correctly.
	const isOrderedWithin5MinutesAgo = dayjs(action.OrderDate).isAfter(
		dayjs().subtract(5, "minutes"),
	);
	const isExecutedToday = dayjs(action.ExecutionDate).isSame(dayjs(), "day");
	const isScheduled = action.Status === LicenseActionStatus.Scheduled;
	const moveActionToInProgress = isScheduled && isOrderedWithin5MinutesAgo && isExecutedToday;

	const status = moveActionToInProgress ? LicenseActionStatus.InProgress : action.Status;

	return {
		...action,
		StatusInfo: parsed,
		PurchaseInfo: parsedPurchaseInfo ?? {},
		Status: status,
	};
};

const getStatusPriority = (status: string) => {
	switch (status) {
		case LicenseActionStatus.Scheduled:
			return 1;
		case LicenseActionStatus.Finished:
			return 2;
		case LicenseActionStatus.PartiallyFinished:
			return 3;
		case LicenseActionStatus.Error:
			return 4;
		case LicenseActionStatus.Cancelled:
			return 5;
		default:
			return 6;
	}
};

const updateAction = (action: LicenseAction, status: LicenseActionStatus) => {
	const statusInfoEntry = {
		Status: status,
		Message: `${action.Description} - updated to ${status}`,
		OrderedBy: action.OrderedBy,
		ActivityDate: dayjs().format(LICENSE_ACTION_DATE_FORMAT),
	};

	// When the action is updated through executeLicenseActions the StatusInfo and PurchaseInfo properties
	// are formatted as strings (prepared for the database), so we need to parse them before updating them for the redux store
	// -> So that we don't have to parse them every time we want to use them
	const parsed =
		typeof action.StatusInfo === "string" ? JSON.parse(action.StatusInfo) : action.StatusInfo;
	const parsedPurchaseInfo =
		typeof action.PurchaseInfo === "string"
			? JSON.parse(action.PurchaseInfo)
			: action.PurchaseInfo;

	return {
		...action,
		StatusInfo: [...parsed, statusInfoEntry],
		PurchaseInfo: parsedPurchaseInfo,
		Status: status,
		// We update the table timestamp to trigger certain features
		TableTimestamp: dayjs().format(LICENSE_ACTION_DATE_FORMAT),
	};
};

const checkIfAnyLicensePurchase = (actions: LicenseAction[]) =>
	actions.some(
		(action) =>
			action.Operation === LicenseActionOperationType.AdjustLicenseCount ||
			action.Operation === LicenseActionOperationType.CreateNewSubscription,
	);

const checkIfShouldChangeStatusToInProgress = (action: LicenseAction) => {
	if (action.Status !== LicenseActionStatus.Scheduled) {
		return action;
	}

	const executionDateIsInThePast = dayjs(action.ExecutionDate).isBefore(dayjs());
	if (executionDateIsInThePast) {
		return updateAction(action, LicenseActionStatus.InProgress);
	}

	return action;
};
