import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { splitNumberByThousands } from "utilities/currency/numberFormattingUtility";
import styles from "./TableDialogTable.module.scss";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import _ from "lodash";

// Method for formatting output correctly without
// cluttering the other code
function formatOutputForCells(item, property) {
	const itemDataPrefix = property.prefix;
	const itemDataSuffix = property.suffix;
	const itemName = property.name;
	let itemData = item[itemName];

	if (_.isNumber(itemData)) {
		let itemDataAsFloat = parseFloat(itemData).toFixed(0);
		let itemDataAsStringWithNumbers = splitNumberByThousands(itemDataAsFloat);
		return `${itemDataPrefix} ${itemDataAsStringWithNumbers} ${itemDataSuffix}`;
	}

	if (_.isBoolean(itemData)) {
		let yesOrNo = !!itemData ? "Yes" : "No";
		return `${itemDataPrefix} ${yesOrNo} ${itemDataSuffix}`;
	}

	if (_.isNull(itemData)) {
		return "Not applicable";
	}

	return `${itemDataPrefix} ${itemData} ${itemDataSuffix}`;
}

const TableDialogTable = ({ tableData = [], tableHeaders, tableRowProperties, title = "" }) => {
	return (
		<TableContainer className={styles.tableContainer}>
			<Table stickyHeader>
				<TableHead className={styles.tableHead}>
					<TableRow>
						{tableHeaders.map((item, key) => {
							return <TableCell key={`${item}-${key}`}>{item}</TableCell>;
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{tableData && tableData.length > 0 ? (
						tableData.map((item, key) => (
							<TableRow key={`TableRow-${item}-${key}`}>
								{tableRowProperties.map((property) => {
									return (
										<TableCell key={`TableCell-${property.name}-${key}`}>
											{formatOutputForCells(item, property)}
										</TableCell>
									);
								})}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								className={clsx({
									[styles.noItemsCell]: true,
								})}
								colSpan={Object.entries(tableRowProperties).length}
								align="center"
							>
								<Typography>{`No ${title.toLowerCase()} found`}</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default TableDialogTable;
