import { CircularProgress, Grid, Typography } from "@mui/material";
import { ReactComponent as GoodJobSkybert } from "assets/skybert/GoodJob.svg";

import { CostSavingsType } from "utilities/constants/enums";
import { SecondaryButton } from "components/Common/Buttons/Button";

import styles from "./TaskCompleted.module.scss";
import { useAppSelector, useAuth } from "hooks/hooks";
import { useEffect, useState } from "react";
import { sendMail } from "actions/userActions";
import { selectConfirmationMailContent } from "features/recommendations";
import { selectRecipientMailState } from "features/scheduledActions";

interface TaskCompletedInputProps {
	costSavingsType: CostSavingsType;
	handleClose: () => void;
	isCheckoutComplete: boolean;
}

interface Content {
	[key: string]: {
		completedTitle: string;
		waitingTitle: string;
		description: string;
		skybert: JSX.Element;
	};
}

export const TaskCompleted = ({
	costSavingsType,
	handleClose,
	isCheckoutComplete,
}: TaskCompletedInputProps) => {
	const confirmationMailContent = useAppSelector(selectConfirmationMailContent);
	const [isMailSent, setIsMailSent] = useState(false);
	const { auth, dispatch } = useAuth();

	useEffect(() => {
		if (
			isCheckoutComplete &&
			!isMailSent &&
			costSavingsType !== CostSavingsType.TermDurationChange
		) {
			dispatch(
				sendMail({
					auth,
					body: {
						mailType: confirmationMailContent.mailType,
						recipientMail: mailState.mail,
						messageBody: confirmationMailContent.body,
					},
				}),
			);
			setIsMailSent(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCheckoutComplete]);

	const mailState = useAppSelector(selectRecipientMailState);

	const content: Content = {
		[CostSavingsType.RemoveUnused]: {
			completedTitle: "Unused licenses removed",
			waitingTitle: "Removing unused licenses, deleting selected inactive users",
			description: mailState.mail
				? `A confirmation will be sent to ${mailState.mail} with all the details`
				: "Nice work!",
			skybert: <GoodJobSkybert />,
		},
		[CostSavingsType.TermDurationChange]: {
			completedTitle: "Walkthrough requested",
			waitingTitle: "Requesting walkthrough",
			description: "Nice work! We'll get in touch to schedule a walkthrough",
			skybert: <GoodJobSkybert />,
		},
	};

	return (
		<Grid container>
			<Grid container p={3} justifyContent={"center"}>
				<Grid item xs={5} pt={12} className={styles.content}>
					<Grid container spacing={2} justifyContent={"center"}>
						<Grid item xs={12}>
							<Typography variant="h1" fontWeight={500} align="center">
								{isCheckoutComplete
									? content[costSavingsType].completedTitle
									: content[costSavingsType].waitingTitle}
							</Typography>
						</Grid>
						<Grid item xs={12} className={styles.description}>
							{isCheckoutComplete ? (
								<Typography variant="body1" align="center">
									{content[costSavingsType].description}
								</Typography>
							) : (
								<Grid container className={styles.loading}>
									<CircularProgress />
									<br />
									Please wait...
								</Grid>
							)}
						</Grid>
						<Grid item display={"flex"} justifyContent={"center"}>
							<SecondaryButton
								size="large"
								variantStyle="outlined"
								action={() => handleClose()}
								isDisabled={!isCheckoutComplete}
								text={"Close"}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={1} pt={3.5} className={styles.skybert}>
					<svg width="200" height="200" viewBox="0 0 200 200">
						{content[costSavingsType].skybert}
					</svg>
				</Grid>
			</Grid>
		</Grid>
	);
};
