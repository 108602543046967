// Source: https://github.com/robcalcroft/react-use-infinite-loader/blob/master/useInfiniteLoader.js

import { useEffect, useRef, useState } from "react";

export default function useInfiniteLoader({
	startFromPage = 0,
	total = 0,
	initialise = true,
	rootMargin = "100px 0px 0px 0px",
	threshold = 0,
	debug = false,
}) {
	function log(...args) {
		if (debug) {
			// eslint-disable-next-line no-console
			console.log(...args);
		}
	}

	const PAGE_SIZE = 10;
	const [renderCount, setRenderCount] = useState(PAGE_SIZE);
	const loadMore = () => setRenderCount((count) => count + PAGE_SIZE);
	const resetRenderCount = () => setRenderCount(PAGE_SIZE);

	if (typeof loadMore !== "function") {
		throw new TypeError("useInfiniteLoader: loadMore must be a function and is required");
	}
	const loaderRef = useRef(null);
	const page = useRef(startFromPage);
	const observer = useRef(null);

	function resetPage() {
		page.current = startFromPage;
	}

	useEffect(() => {
		if (!observer.current && initialise === true) {
			log("Initialised");
			observer.current = new IntersectionObserver(
				([target]) => {
					log("Observer invoked");
					if (target.intersectionRatio <= 0) {
						log("Intersection ratio not met, bailing");
						return;
					}
					if (renderCount >= total) {
						log("Can load more is false, bailing");
						return;
					}
					log("Loading more...");
					loadMore(page.current);
					page.current += 1;
				},
				{ rootMargin, threshold },
			);
			if (loaderRef.current) {
				log("Observing loader ref");
				observer.current.observe(loaderRef.current);
			}
		}
		return () => {
			if (observer && observer.current) {
				observer.current.disconnect();
				observer.current = undefined;
			}
		};
		// eslint-disable-next-line
	}, [loadMore, page, initialise]);
	return { loaderRef, page: page.current, resetPage, resetRenderCount, renderCount, total };
}
