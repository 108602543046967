import { Grid, IconButton, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import {
	selectCartCountBySku,
	selectCartPriceTotal,
	selectCartItems,
	removeItemFromCart,
	addItemToCart,
	deleteCartItem,
	selectCartCount,
	clearCartItems,
} from "features/hardware/products";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { useAppNavigate } from "hooks/useAppNavigate";
import { SelectedHardwareListItem } from "components/Hardware/SelectHardware/HardwareDrawer/SelectedHardwareListItem";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import styles from "./Cart.module.scss";
import { selectHardwarePurchaseType } from "features/hardware/hardwarePage";
import { HardwarePurchaseType } from "utilities/constants/enums";

const Cart = ({ isOnboarding, onboardingCheckoutMethod }) => {
	const dispatch = useAppDispatch();
	const cartItems = useAppSelector(selectCartItems);
	const cartItemsCount = useAppSelector(selectCartCount);
	const countBySku = useAppSelector(selectCartCountBySku);
	const cartPriceTotal = useAppSelector(selectCartPriceTotal);
	const hardwarePurchaseType = useAppSelector(selectHardwarePurchaseType);
	const { navigateToPage } = useAppNavigate();

	const handlePrimaryButtonClick = () => {
		if (isOnboarding) {
			onboardingCheckoutMethod();
		} else {
			navigateToPage(NAVIGATION_NAMES.HARDWARE_CHECKOUT.path);
		}
	};

	return (
		<Grid container className={styles.cartContainer}>
			<Grid container className={styles.cartItemsCountContainer}>
				<Typography variant="body1">{`Items in cart (${cartItemsCount})`}</Typography>
				<IconButton
					onClick={() => dispatch(clearCartItems())}
					className={styles.emptyCartButton}
				>
					<RemoveShoppingCartIcon className={styles.emptyCartIcon} />
					<Typography variant="subtitle1" className={styles.cartTotalText}>
						Empty cart
					</Typography>
				</IconButton>
			</Grid>
			<Grid container className={styles.cartItemsContainer}>
				{cartItems?.map((cartItem) => (
					<SelectedHardwareListItem
						key={cartItem.sku}
						{...cartItem}
						count={countBySku[cartItem.sku]}
						deleteItem={() => dispatch(deleteCartItem(cartItem.sku))}
						decrementCount={() => dispatch(removeItemFromCart(cartItem.sku))}
						incrementCount={() => dispatch(addItemToCart(cartItem.sku))}
						hardwarePurchaseType={hardwarePurchaseType}
					/>
				))}
			</Grid>

			<Grid container className={styles.cartTotalsContainer}>
				<Grid item container alignItems="center" mb={2}>
					<Typography variant="body1" display="inline" fontWeight={500}>
						{formatCostString("Total: ", cartPriceTotal, " ,-")}
					</Typography>
					<Typography
						variant="description"
						color="text.secondary"
						display="inline"
						ml={1}
					>
						{hardwarePurchaseType === HardwarePurchaseType.Buy
							? " excl. vat"
							: "per month"}
					</Typography>
				</Grid>
				<PrimaryButton
					text={isOnboarding ? "Next: Summary" : "Checkout"}
					variantStyle="contained"
					action={handlePrimaryButtonClick}
					size="large"
					isDisabled={cartItems.length === 0}
				/>
			</Grid>
		</Grid>
	);
};

export { Cart };
