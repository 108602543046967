import { Grid, Typography, List, ListItem, ListItemIcon } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { HardwareGroup, HardwareGroups, Manufacturer, Manufacturers } from "types";
import { useMemo } from "react";
import { HARDWARE_GROUP_LABELS } from "utilities/constants/constants";

interface Props {
	searchQuery: string;
	manufacturers: Manufacturers;
	groups: HardwareGroups;
	inStock: boolean;
}

const NoResultMessage = ({ searchQuery, manufacturers, groups, inStock }: Props) => {
	const selectedManufacturers = useMemo(
		() =>
			Object.keys(manufacturers).filter((key) => manufacturers[key as Manufacturer] === true),
		[manufacturers],
	);

	const selectedGroups = useMemo(
		() => Object.keys(groups).filter((key) => groups[key as HardwareGroup] === true),
		[groups],
	);

	const labels = selectedGroups.map((group) => HARDWARE_GROUP_LABELS[group as HardwareGroup]);

	const searchQueryText = useMemo(() => {
		return searchQuery.length > 0 ? ` for "${searchQuery}"` : "";
	}, [searchQuery]);

	return (
		<Grid marginBottom={"100vh"}>
			{/* marginBottom is only here to stop the view from jumping when the no result message is shown */}
			<Typography variant="h6">
				{`Unfortunately, your search ${searchQueryText} did not return
					any results.`}
			</Typography>
			<List>
				<Typography variant="body1">Search tips</Typography>
				<ListItem>
					<ListItemIcon>
						<CircleIcon fontSize="small" />
					</ListItemIcon>
					Try search for product brand or more general words
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<CircleIcon fontSize="small" />
					</ListItemIcon>
					Double-check your spelling!
				</ListItem>
				<ListItem>
					<ListItemIcon>
						<CircleIcon fontSize="small" />
					</ListItemIcon>
					Try broaden your search using fewer or more general words
				</ListItem>
				<ListItem>
					<Grid display="flex" direction="column">
						<ListItemIcon>
							<CircleIcon fontSize="small" />
							<Typography variant="body1" ml={2.5}>
								Try to use the filters. Current filter settings:
							</Typography>
						</ListItemIcon>
						{selectedManufacturers.length > 0 && (
							<Grid item ml={6} mt={1}>
								Brands: <b>{selectedManufacturers.join(", ")}</b>
							</Grid>
						)}

						{labels.length > 0 && (
							<Grid item ml={6}>
								Product types: <b>{labels.join(", ")}</b>
							</Grid>
						)}
						<Grid item ml={6}>
							In stock:{" "}
							{inStock ? (
								<b>Only show products in stock</b>
							) : (
								<b>Showing all products</b>
							)}
						</Grid>
					</Grid>
				</ListItem>
			</List>
		</Grid>
	);
};

export { NoResultMessage };
