import { createAsyncThunk } from "@reduxjs/toolkit";
import { MailDomain } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

export const fetchMailDomains = createAsyncThunk(
	"mailDomains/fetch",
	apiGet<MailDomain[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_MAIL_DOMAINS(),
		[SCOPES.GRAPH_READ],
	),
);
