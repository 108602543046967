import { useMemo, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Country, getCode, getData } from "country-list";
import { fetchCustomerCountries } from "actions/customerActions";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import {
	selectCustomerCountries,
	selectCustomerCountriesKeyedByCountryCode,
} from "features/customer";

import styles from "./CountrySelector.module.scss";
import { TruncatableTypography } from "../TruncateableTypography";
import clsx from "clsx";

interface CountryFlagProps {
	country?: string;
	countryCode?: string;
	coloredBackground: boolean;
}

// Your existing CountryFlag component
const CountryFlag = ({ country, countryCode, coloredBackground }: CountryFlagProps) => {
	const code = country ? getCode(country ?? "N/A") : countryCode;
	if (!code) return null;
	return (
		<span className={clsx(styles.flag, { [styles.coloredBackground]: coloredBackground })}>
			<img src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`} alt={code} />
		</span>
	);
};

interface CountrySelectorProps {
	value: string;
	register: any;
}

const CountrySelector = ({ value, register }: CountrySelectorProps) => {
	const countriesData = getData();

	useApiOnce(fetchCustomerCountries, useAppSelector(selectCustomerCountries));
	const customerCountryByKey = useAppSelector(selectCustomerCountriesKeyedByCountryCode);

	// Needed to stop MUI from complaining about empty country objects on users
	const emptyCountry = {
		name: "",
		code: "",
	} as Country;

	const sortedAndPrioritized = useMemo(() => {
		const sorted = [emptyCountry, ...countriesData].sort((a, b) => {
			const numEmployeesOnCountryA = customerCountryByKey[a.code]?.numberOfEmployees ?? 0;
			const numEmployeesOnCountryB = customerCountryByKey[b.code]?.numberOfEmployees ?? 0;

			if (a.name === "") return -1; // Empty country should always be first
			if (numEmployeesOnCountryA > numEmployeesOnCountryB) return -1;
			if (numEmployeesOnCountryA < numEmployeesOnCountryB) return 1;
			return 0;
		});
		return sorted;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countriesData, customerCountryByKey]);

	const selectedCountry = countriesData.find((country) => country.name === value) ?? emptyCountry;

	const [currentSelectedCountry, setCurrentSelectedCountry] = useState<Country>(selectedCountry);

	return (
		<Autocomplete
			value={currentSelectedCountry}
			options={sortedAndPrioritized}
			onChange={(_, newValue: any) => {
				setCurrentSelectedCountry(newValue ?? {});
			}}
			isOptionEqualToValue={(option, value) => option?.name === value?.name}
			getOptionLabel={(option) => option.name ?? ""}
			renderOption={(props, option) => (
				<li {...props} key={option.code}>
					<CountryFlag country={option.name} coloredBackground />
					<TruncatableTypography variant="body1" maxCharLength={30} ml={1.5}>
						{option.name}
					</TruncatableTypography>
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder="Select a country"
					className={styles.autcompleteInput}
					InputProps={{
						style: {
							height: 40,
							display: "flex",
							alignContent: "center",
						},
						...params.InputProps,
					}}
					{...register("country")}
				/>
			)}
		/>
	);
};

export { CountrySelector, CountryFlag };
