import { createAsyncThunk } from "@reduxjs/toolkit";
import { SCOPES } from "utilities/constants/constants";
import { apiGet, apiPost } from "utilities/api";
import { Order } from "types";
import { queryParams } from "utilities/urlUtils";

export const fetchOrders = createAsyncThunk(
	"orders/fetch",
	apiGet<Order[]>(
		(authConfig, { id }) =>
			authConfig.HARDWARE_API_ENDPOINTS.GET_ORDERINFO() + queryParams({ userID: id }),
		[SCOPES.HARDWARE_READ],
	),
);

export const createOrder = createAsyncThunk(
	"orders/createOrder",
	apiPost<Order>(
		(authConfig, { id }) => authConfig.HARDWARE_API_ENDPOINTS.CREATE_ORDER(id),
		[SCOPES.HARDWARE_WRITE],
	),
);

export const createOrderRequest = createAsyncThunk(
	"orders/createOrderRequest",
	apiPost<any>(
		(authConfig, { id }) => authConfig.HARDWARE_API_ENDPOINTS.CREATE_ORDER_REQUEST(id),
		[SCOPES.HARDWARE_WRITE],
	),
);
