import { Grid, Typography } from "@mui/material";
import { SubscriptionVariant } from "types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import styles from "./LicenseCard.module.scss";
import { getIcon } from "utilities/microsoftIconsUtils";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectSubscribedSkus, setActiveLicenseManagementId } from "features/licenses/licenses";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { selectCustomerDetails } from "features/customer";
import { TruncatableTypography } from "components/Common/TruncateableTypography";

interface LicenseCardInputProps {
	name: string;
	licenseVariants: SubscriptionVariant[];
	used: number;
	total: number;
	isLoadingSubscriptions: boolean;
}

export const LicenseCard = ({
	name,
	licenseVariants,
	used,
	total,
	isLoadingSubscriptions,
}: LicenseCardInputProps) => {
	const icon = getIcon({ iconName: name as string });
	const { isError: errorFetchingSubscribedSkus } = useAppSelector(selectSubscribedSkus);

	const { dispatch } = useAuth();

	const handleSetActiveLicenseManagement = () => {
		dispatch(setActiveLicenseManagementId(licenseVariants[0].provisioningId));
	};

	const unusedLicenses = Math.max(Number(total - used), 0);

	const customerInfo = useAppSelector(selectCustomerDetails);

	const showNumUnused = customerInfo?.yourEmployeesEnabled && !errorFetchingSubscribedSkus;

	return !isLoadingSubscriptions ? (
		<Grid
			container
			className={clsx(styles.card, styles.cardHover)}
			key={name}
			onClick={handleSetActiveLicenseManagement}
		>
			<Grid container className={styles.titleContainer}>
				{icon ? (
					<svg width="30" height="30" viewBox="0 0 60 60">
						{icon}
					</svg>
				) : null}
				<TruncatableTypography variant="body1" maxCharLength={35}>
					{name}
				</TruncatableTypography>
			</Grid>
			<Grid container className={styles.licenseInfo}>
				<Grid item>
					<Typography variant="h3" fontWeight={500}>
						{total} licenses
					</Typography>
					<Typography variant="body1" color="text.secondary" mt={1}>
						{showNumUnused && `${unusedLicenses} unused`}
					</Typography>
				</Grid>
				<div className={styles.manageLink}>
					Manage license <ArrowForwardIcon fontSize="small" />
				</div>
			</Grid>
		</Grid>
	) : (
		<Grid container className={styles.card} key={name}>
			<Grid container className={styles.titleContainer}>
				<Skeleton variant="rectangular" width={30} height={30} />
				<Skeleton variant="text" width={250} height={30} />
			</Grid>
			<Grid container className={styles.licenseInfo}>
				<Grid item>
					<Skeleton variant="text" width={200} height={30} />
					<Skeleton variant="text" width={175} height={30} />
				</Grid>
				<div className={styles.manageLink}>
					<Skeleton variant="text" width={175} height={30} />
				</div>
			</Grid>
		</Grid>
	);
};
