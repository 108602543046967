import { createAsyncThunk } from "@reduxjs/toolkit";
import { HardwareBundle } from "types";
import { SCOPES, HARDWARE_FOLDER_PATH } from "utilities/constants/constants";
import { apiGet, apiPost } from "utilities/api";

export const fetchHardwareBundles = createAsyncThunk(
	"bundles/fetch",
	apiGet<{ [key: string]: HardwareBundle }>(
		(authConfig) => authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(HARDWARE_FOLDER_PATH),
		[SCOPES.DATA_LAKE_READ],
	),
);

export const postHardwareBundle = createAsyncThunk(
	"bundles/post",
	apiPost<{ [key: string]: HardwareBundle }>(
		(authConfig) => authConfig.DATA_LAKE_API_ENDPOINTS.POST_CUSTOMER_DATA(HARDWARE_FOLDER_PATH),
		[SCOPES.DATA_LAKE_WRITE],
	),
);
