import { Typography } from "@mui/material";
import clsx from "clsx";
import { Device } from "types";
import { DeviceComplianceStatus } from "utilities/constants/enums";

import styles from "./ComplianceStatus.module.scss";

interface ComplianceStatusProps {
	device: Device;
}

export const ComplianceStatus = ({ device }: ComplianceStatusProps) => {
	if (device.deviceStatuses.length === 0) return <></>;

	const sorted = [...device.deviceStatuses].sort((a, b) => {
		// Sorted reversed alphabetically
		if (a < b) return 1;
		if (a > b) return -1;
		return 0;
	});

	return (
		<>
			{sorted.map((deviceStatus) => (
				<Typography
					key={deviceStatus}
					variant="description"
					className={clsx({
						[styles.green]: deviceStatus === DeviceComplianceStatus.Compliant,
						[styles.yellow]: deviceStatus === DeviceComplianceStatus.Inactive,
						[styles.red]: deviceStatus === DeviceComplianceStatus.NonCompliant,
					})}
				>
					{deviceStatus}
				</Typography>
			))}
		</>
	);
};
