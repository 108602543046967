import { useAuth } from "hooks/hooks";
import { useIdleTimer } from "react-idle-timer";
import { useEffect, useRef, useState } from "react";
import { pagePost } from "actions/pageActions";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { v4 as uuidv4 } from "uuid";

export const PageLogger = () => {
	const { auth, dispatch } = useAuth();

	const location = window.location.pathname;
	const [activityLevel, setActivityLevel] = useState(0);
	const [rowKey, setRowKey] = useState<string | null>(null);
	const currentPageRef = useRef(location); // Use a ref to track the current page
	const logTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the timeout id
	const [invisibleSince, setInvisibleSince] = useState<number | null>(null);
	const [, setIsPageVisible] = useState(true); // State to track page visibility
	const isPageActiveRef = useRef(true); // Ref to track overall page activity combining visibility and idle status
	const invisibleSinceRef = useRef(invisibleSince); // Ref to track the time the page has been invisible
	const activityLevelRef = useRef(activityLevel);

	useEffect(() => {
		invisibleSinceRef.current = invisibleSince;
	}, [invisibleSince]); // This ensures the ref is always up-to-date

	useEffect(() => {
		activityLevelRef.current = activityLevel;
	}, [activityLevel]); // This ensures the ref is always up-to-date

	const handleVisibilityChange = () => {
		const isVisible = document.visibilityState === "visible";
		setIsPageVisible(isVisible); // Update state based on page visibility
		isPageActiveRef.current = isVisible && !isIdle(); // Update ref based on visibility and idle status
		if (!isVisible) {
			setInvisibleSince(Date.now());
		} else {
			// If the page becomes visible again, generate a new rowKey
			// As the user is considered to have left the page and returned (i.e a new session)
			const numMsInvisible = invisibleSinceRef.current
				? Date.now() - invisibleSinceRef.current
				: 0;
			if (numMsInvisible > 30000) {
				// If the tab was invisible for more than 30 seconds
				// Generate a new rowKey, as this is considered a new session
				const newRowKey = uuidv4();
				setRowKey(newRowKey);
			}
			setInvisibleSince(null);
		}
	};

	useEffect(() => {
		// Add visibility change event listener when component mounts
		document.addEventListener("visibilitychange", handleVisibilityChange);

		// Remove event listener when component unmounts
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { isIdle, getActiveTime, reset } = useIdleTimer({
		onAction: () => {
			setActivityLevel((prev) => prev + 1);
		},
		debounce: 500,
		timeout: 20_000, // User is considered idle after 20 seconds
	});

	// Function to log the initial page hit and set the rowKey
	const logInitialPageHit = async () => {
		const rowKey = uuidv4();
		const pageName = Object.values(NAVIGATION_NAMES).find(
			(nav) => nav.path === location,
		)?.label;
		const body = {
			page: pageName,
			for: 0,
			activityLevel: 0,
			RowKey: rowKey,
		};

		await dispatch(
			pagePost({
				auth,
				body: body,
			}),
		);

		setRowKey(rowKey);
	};

	// Function to update the time spent on the page
	const updatePageHit = (locationForUpdate: string) => {
		if (!rowKey) return; // Don't update if no rowKey or user is inactive

		const activeTime = getActiveTime();

		const pageName = Object.values(NAVIGATION_NAMES).find(
			(nav) => nav.path === locationForUpdate,
		)?.label;

		const body = {
			page: pageName,
			for: activeTime, // Use the total active time instead of msSpent
			activityLevel: activityLevelRef.current,
			RowKey: rowKey,
		};

		dispatch(
			pagePost({
				auth,
				body: body,
			}),
		);
	};

	useEffect(() => {
		logTimeoutRef.current = setTimeout(() => {
			// Effect for initial page hit logging with 1-second delay
			// To ensure that the user is not just passing through the page / wildly clicking on navigation items
			logInitialPageHit();
		}, 1000);

		return () => {
			if (logTimeoutRef.current) {
				clearTimeout(logTimeoutRef.current); // Clear the timeout if the component unmounts or location changes
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	// Effect to periodically call updatePageHit every 30 seconds
	useEffect(() => {
		const interval = setInterval(() => {
			if (isPageActiveRef.current) {
				updatePageHit(location);
			}
		}, 30_000);

		return () => {
			clearInterval(interval);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowKey]);

	useEffect(() => {
		const activeTime = getActiveTime();
		if (activeTime > 1000) {
			// If this component is being unmounted, update the page hit
			updatePageHit(currentPageRef.current);
		}

		const resetStatistics = () => {
			setActivityLevel(0);
			reset();
		};

		resetStatistics();
		currentPageRef.current = location; // Update the ref to the new location
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, reset]);

	return null;
};
