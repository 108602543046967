import { StatusDetails } from "types";
import { ProductDeliveryStatus } from "utilities/constants/enums";

export const getStatus = (statusDetails: StatusDetails) => {
	const { accepted, shipped, invoiced } = statusDetails;

	if (accepted && shipped && invoiced) {
		return ProductDeliveryStatus.SHIPPED;
	} else if (accepted && shipped) {
		return ProductDeliveryStatus.SHIPPED;
	} else if (accepted) {
		return ProductDeliveryStatus.ACCEPTED;
	} else {
		return "";
	}
};
