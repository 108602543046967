export const allowedPageForRole = (loggedInUserRoles, roleAccessDefaults) => {
	let allowedPages = new Set();
	loggedInUserRoles?.forEach((role) => {
		const roleKey = role.toUpperCase();
		const rolePages = roleAccessDefaults[roleKey]?.PAGES ?? [];
		rolePages.forEach((page) => allowedPages.add(page));
	});

	return Array.from(allowedPages);
};
