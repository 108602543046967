import { ProductType } from "types";
import { NAVIGATION_NAMES } from "utilities/constants/pages.tsx";

export const getAllowedPagesForCustomer = ({
	yourEmployeesEnabled,
	yourItSystemsEnabled,
	deviceManagementEnabled,
	hasKomplettRelationship,
	deprecatedPeopleServiceEnabled,
}) => {
	const navigationValues = Object.values(NAVIGATION_NAMES);
	// By default, some pages are allowed for *all* customers
	const defaultPages = navigationValues.filter((navigationName) =>
		navigationName.accessibleWithProducts.includes(ProductType.DEFAULT),
	);
	// Allowed pages for customers with product "IT for Your Employees enabled"
	const yourEmployeesPages = navigationValues.filter((navigationName) =>
		navigationName.accessibleWithProducts.includes(ProductType.YOUR_EMPLOYEES),
	);
	// Allowed pages for customers with "Your IT Systems enabled"
	const yourItSystemsPages = navigationValues.filter((navigationName) =>
		navigationName.accessibleWithProducts.includes(ProductType.YOUR_IT_SYSTEMS),
	);
	// Allowed pages for deprecated "People" service enabled
	const deprecatedPeoplePages = navigationValues.filter((navigationName) =>
		navigationName.accessibleWithProducts.includes(ProductType.PEOPLE_SERVICE),
	);
	const hardwareManagementPages = navigationValues.filter((navigationName) =>
		navigationName.accessibleWithProducts.includes(ProductType.HARDWARE_SOLUTION),
	);

	if (
		yourEmployeesEnabled === undefined &&
		yourItSystemsEnabled === undefined &&
		deprecatedPeopleServiceEnabled === undefined &&
		deviceManagementEnabled === undefined
	) {
		// At first render, we might not have all the data from the customerInfo object yet.
		// In this case, we need to return all pages as allowed pages, as we don't know which ones to hide yet.
		return Array.from(
			new Set([
				...defaultPages,
				...yourEmployeesPages,
				...yourItSystemsPages,
				...deprecatedPeoplePages,
			]),
		);
	}

	let allowedPagesForCustomer = new Set([...defaultPages]);

	if ((deviceManagementEnabled || yourEmployeesEnabled) && hasKomplettRelationship)
		allowedPagesForCustomer = new Set([...allowedPagesForCustomer, ...hardwareManagementPages]);

	if (yourEmployeesEnabled)
		allowedPagesForCustomer = new Set([...allowedPagesForCustomer, ...yourEmployeesPages]);

	if (yourItSystemsEnabled)
		allowedPagesForCustomer = new Set([...allowedPagesForCustomer, ...yourItSystemsPages]);

	if (deprecatedPeopleServiceEnabled)
		allowedPagesForCustomer = new Set([...allowedPagesForCustomer, ...deprecatedPeoplePages]);

	return Array.from(allowedPagesForCustomer);
};

export const customerHasAccessTo = (customerInfo, page) => {
	const allowedPagesForCustomer = getAllowedPagesForCustomer(customerInfo);
	return allowedPagesForCustomer.map((page) => page.value).includes(page);
};
