import { get } from "utilities/rest";

const getUsersForDropdown = async ({ token, subscriptionKey, url, searchValue }) => {
	const userQuery = searchValue === "" ? "" : `search="displayName:${searchValue}"`;

	const users = await get(
		url,
		token?.tokenType ?? "Bearer",
		token?.accessToken ?? token,
		false,
		"application/json",
		undefined,
		{
			"Ocp-Apim-Subscription-Key": subscriptionKey,
		},
		userQuery,
	);

	if (users?.data === undefined) {
		return null;
	}

	const formatted = users.data.map((user) => ({
		id: user.id,
		displayName: user.displayName || "",
		mail: user.mail || "",
		userPrincipalName: user.userPrincipalName || "",
		label: user.displayName || "",
		type: "USER",
		assignedLicenses: user.assignedLicenses || [],
		department: user.department || "Unknown",
	}));

	return formatted;
};

const getGroupsForDropdown = async ({
	token,
	url,
	subscriptionKey,
	searchValue,
	groupType = "ALL",
}) => {
	let groupQuery =
		searchValue === ""
			? `groupType=${groupType}`
			: `groupType=${groupType}&groupName=${searchValue}`;

	const groups = await get(
		url,
		token?.tokenType ?? "Bearer",
		token?.accessToken ?? token,
		false,
		"application/json",
		undefined,
		{
			"Ocp-Apim-Subscription-Key": subscriptionKey,
		},
		groupQuery,
	);

	if (groups?.data === undefined) {
		return null;
	}

	const formatted = groups.data.map((group) => ({
		id: group.id,
		displayName: group.prettyDisplayName ?? group.displayName,
		type: "GROUP",
		groupType: group.groupType,
	}));

	return formatted;
};

export { getUsersForDropdown, getGroupsForDropdown };
