import { memo, ReactElement } from "react";

import { Grid, Skeleton, Typography } from "@mui/material";
import styles from "./Product.module.scss";
import { HardwareProduct } from "types";
import { ExternalLink } from "components/Common/Links";
import { HardwareProductPrice } from "./ProductPrice/ProductPrice";
import { HardwareImage } from "components/Hardware/HardwareImage";
import { StockIndicator } from "../../HardwareBundles/StockIndicator";

export const handleDescription = (description: string, name: string) =>
	description
		.substring(name.length)
		.split(", ")
		.filter((str) => str.trim().length >= 2);

interface ProductProps {
	product?: HardwareProduct;
	isLoading?: boolean;
	addToCartComponent?: () => React.ReactNode;
}

function Product({ product, isLoading, addToCartComponent }: ProductProps): ReactElement {
	const { imageUrl, displayName, description, sku, availability, priceInfo, url } =
		product || ({} as HardwareProduct);

	return (
		<Grid key={sku} container direction="column">
			{!isLoading ? (
				<Grid container className={styles.productContainer}>
					<>
						<HardwareImage
							imageUrl={imageUrl ?? ""}
							productId={sku ?? ""}
							size="large"
						/>
						<Grid container className={styles.imageAndDescriptionContainer}>
							<Grid
								container
								item
								className={styles.descriptionContainer}
								direction="column"
							>
								<Typography variant="body1" fontWeight={500}>
									{displayName}
								</Typography>
								<Typography
									className={styles.productDescription}
									variant="description"
								>
									{(description &&
										displayName &&
										handleDescription(description, displayName).join(", ")) ||
										" "}
								</Typography>
								<Grid container>
									<StockIndicator
										idToSwap={sku}
										availability={availability}
										requestedQuantity={1}
										variant="horizontal"
									/>
								</Grid>
								<Grid className={styles.externalLinkContainer}>
									<ExternalLink
										href={url}
										textDecoration="underline"
										target="_blank"
									>
										View Specification
									</ExternalLink>
								</Grid>
							</Grid>

							<Grid container direction="column" className={styles.priceContainer}>
								<Grid>
									<HardwareProductPrice priceInfo={priceInfo} />
								</Grid>
								{addToCartComponent && addToCartComponent()}
							</Grid>
						</Grid>
					</>
				</Grid>
			) : (
				<Skeleton className={styles.skeleton} />
			)}
		</Grid>
	);
}

export default memo(
	Product,
	(prevProps, nextProps) =>
		!!prevProps.product &&
		prevProps.product?.sku === nextProps.product?.sku &&
		prevProps.product.availability.inStock === nextProps.product.availability.inStock,
);
