import { Grid, Typography } from "@mui/material";
import { HoverTooltip } from "../Tooltips";
import { ReactComponent as IronStoneLogo } from "assets/logo/ironstone_title_only.svg";

import styles from "./IronstoneDiscount.module.scss";
import clsx from "clsx";

interface IronstoneDiscountProps {
	title: string;
	showDiscount: boolean;
	hoverDescription: string;
}

export const IronstoneDiscount = ({
	title,
	showDiscount,
	hoverDescription,
}: IronstoneDiscountProps) => {
	let shownTitle = title;

	switch (title) {
		case "Office 365 Licenses":
			shownTitle = "license";
			break;
		default:
			shownTitle = title.toLowerCase();
			break;
	}

	return (
		<HoverTooltip title={"Ironstone discount"} description={hoverDescription}>
			<Grid
				className={clsx(styles.activeDiscountContainer, { [styles.hidden]: !showDiscount })}
			>
				<IronStoneLogo />
				<Typography variant="caption">Active {shownTitle} discount</Typography>
			</Grid>
		</HoverTooltip>
	);
};
