import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Grid,
	Typography,
	IconButton,
	Skeleton,
} from "@mui/material";

import { TermDurationPicker } from "../TermDurationPicker";
import { LicensePriceDetails } from "../LicensePriceDetails";

import styles from "./AddUserLicenseDetails.module.scss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HoverTooltip } from "components/Common/Tooltips";

const AddUserLicenseDetails = ({ availableLicenses, chosenLicenses, handleTermDurationChange }) => {
	const extendedChosenLicenses = chosenLicenses.map((license) => {
		const availableLicense = availableLicenses.find(({ skuId }) => skuId === license.skuId);
		return {
			...availableLicense,
			chosenVariant: license.chosenVariant ?? availableLicense.chosenVariant,
		};
	});

	return (
		<>
			<Grid item xs={12}>
				<TableContainer className={styles.tableContainer}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead className={styles.tableHead}>
							<TableRow>
								<TableCell className={styles.tableCellName}>
									Licences added to user
								</TableCell>
								<TableCell className={styles.otherCellheader}>Group</TableCell>
								<TableCell className={styles.otherCellheader}>
									Used licenses
								</TableCell>
								<TableCell className={styles.commitmentCell}>
									Subscription duration
									<HoverTooltip description="Not all licenses have configurable subscription duration. If you're unable to configure the desired duration, head to the license management page to enable a the desired duration.">
										<IconButton classes={{ root: styles.tooltip }}>
											<InfoOutlinedIcon fontSize="small" />
										</IconButton>
									</HoverTooltip>
								</TableCell>
								<TableCell className={styles.otherCellheader}>Price</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{extendedChosenLicenses.map((license, key) => {
								const {
									memberOfGroup,
									displayName,
									usedLicenses,
									unusedLicenses,
									subscriptionVariants,
								} = license;

								const totalLicensesQuantity = subscriptionVariants?.reduce(
									(acc, curr) => acc + curr.quantity,
									0,
								);

								return (
									<TableRow key={key}>
										<TableCell classes={{ root: styles.tableCell }}>
											{displayName}
										</TableCell>
										<TableCell classes={{ root: styles.tableCell }}>
											{memberOfGroup}
										</TableCell>
										<TableCell classes={{ root: styles.tableCell }}>
											<Grid container direction="column" display={"flex"}>
												<Grid item>
													{subscriptionVariants?.length > 0 ? (
														<Typography
															fontSize={14}
															fontWeight={
																unusedLicenses > 0 ? 600 : 400
															}
														>
															{subscriptionVariants?.length > 0 &&
																`${usedLicenses} / ${totalLicensesQuantity}`}
														</Typography>
													) : (
														<Skeleton
															variant="text"
															width={85}
															height={30}
														/>
													)}
												</Grid>
											</Grid>
										</TableCell>
										<TableCell className={styles.commitmentCell}>
											{usedLicenses >= totalLicensesQuantity ? (
												<TermDurationPicker
													license={license}
													handleTermDurationChange={
														handleTermDurationChange
													}
												/>
											) : (
												<>-</>
											)}
										</TableCell>
										<TableCell classes={{ root: styles.tableCell }}>
											<LicensePriceDetails license={license} />
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</>
	);
};

export { AddUserLicenseDetails };
