import { Grid } from "@mui/material";

import { WorkspaceSettingsForm } from "./WorkspaceSettingsForm";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import {
	fetchCollaborationData,
	fetchGroupLifecyclePolicies,
	saveCustomer,
	setGroupLifecyclePolicies,
} from "actions/customerActions";
import { COLLABORATION_FOLDER_PATH } from "utilities/constants/constants";
import { selectCollaborationCustomer, setCustomer } from "features/customer";
import { mapToStorageModel } from "utilities/features/customer";
import {
	archivalSettingsSelectors,
	selectArchivalSettings,
	setArchivalSettingsData,
} from "features/archivalSettings";

import styles from "./Collaboration.module.scss";

const Collaboration = () => {
	const [archivalSettingsData] = useAppSelector(archivalSettingsSelectors.selectAll);

	const { dispatch, auth } = useAuth();

	const customerData = useAppSelector(selectCollaborationCustomer);

	useApiOnce(fetchCollaborationData, customerData);
	useApiOnce(fetchGroupLifecyclePolicies, useAppSelector(selectArchivalSettings));

	const updateCustomerData = (name, propertyName, updatedData) => {
		dispatch(
			setCustomer({
				...customerData,
				[name]: { ...customerData[name], [propertyName]: updatedData },
			}),
		);
	};

	const updateArchivalSettingsData = (propertyName, updatedData) =>
		dispatch(setArchivalSettingsData({ ...archivalSettingsData, [propertyName]: updatedData }));

	const saveCollaborationSettings = async () => {
		await Promise.all([
			dispatch(
				saveCustomer({
					auth,
					folderPath: COLLABORATION_FOLDER_PATH,
					body: mapToStorageModel(customerData),
				}),
			),
			dispatch(setGroupLifecyclePolicies({ auth, body: archivalSettingsData })),
		]);
	};

	return (
		<Grid container className={styles.wrapper}>
			<Grid item xs={12}>
				{
					<WorkspaceSettingsForm
						isDataLoaded={!customerData.isLoading}
						workspaceSettings={customerData.workspaceSettings}
						internalCollaborationData={customerData.internalCollaborationData}
						externalCollaborationData={customerData.externalCollaborationData}
						internalProjectData={customerData.internalProjectData}
						externalProjectData={customerData.externalProjectData}
						updateCustomerData={updateCustomerData}
						archivalSettingsData={archivalSettingsData}
						updateArchivalSettingsData={updateArchivalSettingsData}
						saveCollaborationSettings={saveCollaborationSettings}
					></WorkspaceSettingsForm>
				}
			</Grid>
		</Grid>
	);
};

export { Collaboration };
