import { useState, useEffect } from "react";
import { get } from "utilities/rest";
import _ from "lodash";
import config from "../../authConfig";
import { CollaborationRequestForm } from "./RequestForm/RequestForm";

const TeamsApplicationView = ({ teams, authToken }) => {
	const apiConfig = teams && new config.AuthConfig(teams.user.tenant.id);

	// get user graph data
	const [user, setUser] = useState(null);

	const getUserGraphData = async () => {
		const response = await get(
			apiConfig.MS_GRAPH_API_ENDPOINTS.GET_USER(teams.user.id),
			"Bearer",
			authToken,
			true,
			"application/json",
			undefined,
			{
				"Ocp-Apim-Subscription-Key": apiConfig.OCP_APIM_SUBSCRIPTION_KEY,
			},
			null,
		);

		if (!_.isEmpty(response?.data)) {
			setUser(response?.data);
		}
	};

	useEffect(() => {
		if (apiConfig && teams?.user?.id) {
			getUserGraphData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CollaborationRequestForm
			authToken={authToken}
			apiConfig={apiConfig}
			teams={teams}
			user={user}
		/>
	);
};

export { TeamsApplicationView };
