import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { IdleUsersDetails, LicenseSubscriptionRecommendation } from "types";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { PrimaryCheckbox } from "components/Common/Checkboxes";
import dayjs from "dayjs";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";
import clsx from "clsx";

import styles from "./IdleUsersConfiguration.module.scss";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectActiveStep, setActiveStep } from "features/scheduledActions";
import { InfoOutlined } from "@mui/icons-material";
import { HoverTooltip } from "components/Common/Tooltips";
import { AZURE_PORTAL_LINK, MICROSOFT_ADMIN_CENTER_LINK } from "utilities/constants/constants";

interface TableColumn {
	id: string;
	label: string;
	width: string;
}

interface IdleUsersConfigurationProps {
	recommendation: LicenseSubscriptionRecommendation;
	idleUsersDetails: IdleUsersDetails[];
	selectedUsers: string[];
	setSelectedUsers: (users: string[]) => void;
	maxRemoveableQuantity: number;
}

export const IdleUsersConfiguration = ({
	recommendation,
	idleUsersDetails,
	selectedUsers,
	setSelectedUsers,
	maxRemoveableQuantity,
}: IdleUsersConfigurationProps) => {
	const { dispatch } = useAuth();
	const activeStep = useAppSelector(selectActiveStep);
	const handleGoToNextStep = () => {
		dispatch(setActiveStep(activeStep + 1));
	};

	const sortedIdleUsersByLastSignIn = [...idleUsersDetails].sort((a, b) => {
		if (a.lastRegisteredSignIn == null && b.lastRegisteredSignIn == null) {
			return 0;
		}

		if (a.lastRegisteredSignIn == null) {
			return 1;
		}
		if (b.lastRegisteredSignIn == null) {
			return -1;
		}

		return dayjs(b.lastRegisteredSignIn).valueOf() - dayjs(a.lastRegisteredSignIn).valueOf();
	});

	const tableColumns = [
		{
			id: "user",
			label: "User",
			width: "35%",
		},
		{
			id: "license",
			label: "License",
			width: "25%",
		},
		{
			id: "userStatus",
			label: "User status",
			width: "15%",
		},
	] as TableColumn[];

	const table = tableColumns.reduce((acc, curr) => {
		acc[curr.id] = curr;
		return acc;
	}, {} as Record<string, TableColumn>);

	const selectableUsers = idleUsersDetails.filter(
		({ deleted, onPremisesSyncEnabled }) => !deleted && !onPremisesSyncEnabled,
	);

	const swapSelection = () => {
		if (selectedUsers.length === selectableUsers.length) {
			setSelectedUsers([]);
		} else {
			setSelectedUsers(selectableUsers.map(({ id }) => id));
		}
	};

	const handleSelectUser = (id: string) => {
		if (selectedUsers.includes(id)) {
			setSelectedUsers(selectedUsers.filter((selectedId) => selectedId !== id));
		} else {
			setSelectedUsers([...selectedUsers, id]);
		}
	};

	const noSelectableUsers =
		idleUsersDetails.length === 0 || idleUsersDetails.every(({ deleted }) => deleted);

	return (
		<Grid container pb={1} className={styles.container}>
			{!noSelectableUsers && (
				<Grid item xs={12} className={styles.warningTextContainer}>
					<Grid className={styles.warningHeader}>
						<WarningAmberIcon className={styles.warningIcon} />
						<Typography variant="body1" fontWeight={500}>
							Soft delete
						</Typography>
					</Grid>
					<ul className={styles.warningList}>
						<li>
							<Typography variant="body1" fontWeight={400} mb={2}>
								<b>Suspended for 30 days</b> Soft-deleted users accounts will be
								suspended for 30 days, restorable with all properties in Azure AD.
								After 30 days accounts are permanently deleted without the
								possibility of recovery.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" fontWeight={400} mb={2}>
								<b>Cancel the soft-deletion</b> You can cancel the soft-deletion on
								the same day as deletion has been requested.
							</Typography>
						</li>
						<li>
							<Typography variant="body1" fontWeight={400} mb={2}>
								<b>If cancelled or restored</b>, the licenses removed from users
								will be automatically reassigned to the users.
							</Typography>
						</li>
					</ul>
				</Grid>
			)}
			<Grid container className={styles.bottomSectionContainer}>
				<Typography variant="body1" fontWeight={400} mb={1}>
					Displaying users <b>inactive for 90 days</b> or more
				</Typography>
				<TableContainer className={styles.tableContainer}>
					<Table aria-label="idle users with licenses table" className={styles.table}>
						<TableHead className={styles.tableHead}>
							<TableRow>
								<TableCell width={"10%"} align={"center"}>
									{!noSelectableUsers && (
										<PrimaryCheckbox
											label={""}
											checked={
												selectedUsers.length > 0 &&
												selectedUsers.length === selectableUsers.length
											}
											onChange={swapSelection}
											size="small"
										/>
									)}
								</TableCell>
								{tableColumns.map(({ id, label, width }) => (
									<TableCell key={id} width={width}>
										<Typography fontWeight={500} noWrap>
											{label}
										</Typography>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						{!noSelectableUsers ? (
							<TableBody>
								{sortedIdleUsersByLastSignIn.map(
									({
										id,
										userPrincipalName,
										displayName,
										lastRegisteredSignIn,
										accountEnabled,
										deleted,
										onPremisesSyncEnabled,
										privilegedRole,
									}) => {
										if (deleted) {
											return null;
										}
										const userCannotBeDeleted =
											onPremisesSyncEnabled || privilegedRole;
										return (
											<TableRow
												key={userPrincipalName}
												className={clsx({
													[styles.deletedRow]: deleted,
												})}
											>
												<TableCell width={"10%"}>
													<Grid
														container
														display={"flex"}
														justifyContent={"center"}
													>
														{!userCannotBeDeleted && (
															<PrimaryCheckbox
																label=""
																checked={selectedUsers.includes(id)}
																onChange={() =>
																	handleSelectUser(id)
																}
																size="small"
															/>
														)}
													</Grid>
												</TableCell>
												<TableCell
													width={table.user.width}
													className={styles.relativeCell}
												>
													<Typography
														variant="description"
														fontWeight={500}
														className={clsx({
															[styles.warningCell]:
																userCannotBeDeleted,
														})}
													>
														{tripleDotTruncateString(displayName, 25)}
													</Typography>
													<br />
													<Typography
														variant="description"
														color="text.secondary"
														noWrap
														className={clsx({
															[styles.secondaryText]: true,
															[styles.warningCell]:
																userCannotBeDeleted,
														})}
													>
														{tripleDotTruncateString(
															userPrincipalName,
															40,
														)}
													</Typography>
													{userCannotBeDeleted && (
														<HoverTooltip
															title={
																onPremisesSyncEnabled
																	? "User is synced from on-premises"
																	: "Privileged user"
															}
															description={
																onPremisesSyncEnabled ? (
																	"In order to successfully delete user and free up license, user must be deleted from on-premises AD. Actions taken on-premises will be reflected in the portal the next day."
																) : (
																	<>
																		<Typography
																			className={
																				styles.privelegedUserTextStyle
																			}
																		>
																			This user has extended
																			privileges (e.g Admin
																			role). Deletion cannot
																			be performed through the
																			Ironstone portal and has
																			to be done in either the{" "}
																		</Typography>
																		<Typography
																			className={
																				styles.linkStyle
																			}
																			onClick={() =>
																				window.open(
																					AZURE_PORTAL_LINK,
																					"_blank",
																				)
																			}
																		>
																			Azure portal
																		</Typography>
																		<Typography
																			className={
																				styles.privelegedUserTextStyle
																			}
																		>
																			{" "}
																			or the{" "}
																		</Typography>
																		<Typography
																			className={
																				styles.linkStyle
																			}
																			onClick={() =>
																				window.open(
																					MICROSOFT_ADMIN_CENTER_LINK,
																					"_blank",
																				)
																			}
																		>
																			Microsoft 365 Admin
																			Center
																		</Typography>
																	</>
																)
															}
														>
															<div className={styles.warningLabel}>
																<InfoOutlined
																	className={styles.infoIcon}
																/>
																<Typography
																	variant="description"
																	className={styles.warningText}
																>
																	{onPremisesSyncEnabled
																		? "On-premises"
																		: "Privileged user"}
																</Typography>
															</div>
														</HoverTooltip>
													)}
												</TableCell>
												<TableCell
													width={table.license.width}
													className={clsx({
														[styles.warningCell]: userCannotBeDeleted,
													})}
												>
													{recommendation.subscriptionOfferName}
												</TableCell>
												<TableCell width={table.userStatus.width}>
													<AccountStatus
														accountEnabled={accountEnabled}
														lastRegisteredSignIn={lastRegisteredSignIn}
													/>
												</TableCell>
											</TableRow>
										);
									},
								)}
							</TableBody>
						) : (
							<TableBody>
								<TableRow key={1}>
									<TableCell colSpan={4} className={styles.noInactiveUsersCell}>
										<Typography
											variant="body1"
											fontWeight={500}
											textAlign={"center"}
											p={4}
										>
											No inactive users!
										</Typography>
										{maxRemoveableQuantity > 0 && (
											<Typography
												variant="body1"
												fontWeight={400}
												textAlign={"center"}
												mb={4}
											>
												{maxRemoveableQuantity} unassigned licenses
												available for removal
											</Typography>
										)}
										<Grid container justifyContent={"center"}>
											<PrimaryButton
												text={"Next: License removal"}
												action={handleGoToNextStep}
												size="medium"
												variantStyle="contained"
											/>
										</Grid>
									</TableCell>
								</TableRow>
							</TableBody>
						)}
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

interface AccountStatusInputProps {
	accountEnabled: boolean;
	lastRegisteredSignIn: Date | undefined;
}

const AccountStatus = ({ accountEnabled, lastRegisteredSignIn }: AccountStatusInputProps) => {
	if (!accountEnabled) {
		return (
			<HoverTooltip
				title={"Disabled"}
				description={`User is disabled - the user can no longer login. Last observed login for this user is ${dayjs(
					lastRegisteredSignIn,
				).fromNow()}`}
			>
				<div className={styles.disabledLabel}>
					Disabled
					<InfoOutlined className={styles.infoIcon} />
				</div>
			</HoverTooltip>
		);
	} else {
		return (
			<div className={styles.lastSignInLabel}>
				{lastRegisteredSignIn
					? `Last login ${dayjs(lastRegisteredSignIn).fromNow()}`
					: "Never logged in"}
			</div>
		);
	}
};
