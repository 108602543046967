import {
	devicesSelectors,
	resetFilters,
	selectDevices,
	selectGroupedDeviceData,
	setFilterIds,
	setSpecificChosenDeviceFilter,
} from "features/devices";
import { useAppSelector, useAuth } from "hooks/hooks";
import { Divider, Grid, Skeleton, Typography } from "@mui/material";
import { ReactComponent as EntraIdLogo } from "assets/microsoftIcons/EntraID.svg";
import { ReactComponent as IntuneLogo } from "assets/microsoftIcons/Intune.svg";
import { Devices } from "@mui/icons-material";
import {
	DeviceComplianceStatus,
	DeviceFilterKey,
	IntuneManagementType,
} from "utilities/constants/enums";
import { DeviceInfoIcon } from "./DeviceInfoIcon";

import styles from "./DeviceCardsSection.module.scss";
import clsx from "clsx";

const filters = {
	Intune: {
		ironstonePolicy: IntuneManagementType.IronstonePolicyManaged,
		customerPolicy: IntuneManagementType.CustomerPolicyManaged,
		noPolicy: IntuneManagementType.NoPolicy,
	},
	DeviceStatus: {
		compliant: DeviceComplianceStatus.Compliant,
		nonCompliant: DeviceComplianceStatus.NonCompliant,
		inactive: DeviceComplianceStatus.Inactive,
	},
};

export const DeviceCardsSection = () => {
	const { isLoading } = useAppSelector(selectDevices);
	const allDevices = useAppSelector(devicesSelectors.selectAll);
	const allDeviceIds = useAppSelector(devicesSelectors.selectIds);
	const { dispatch } = useAuth();

	const {
		joined,
		registered,
		intuneManaged,
		ironstonePolicy,
		customerPolicy,
		noPolicy,
		compliantDevices,
		nonCompliantDevices,
		inactiveDevices,
	} = useAppSelector(selectGroupedDeviceData);

	const handleUseTotalDevicesFilter = () => {
		dispatch(setFilterIds(allDeviceIds));
		dispatch(resetFilters());
	};

	return (
		<Grid container className={styles.deviceCardsContainer}>
			<Grid container className={styles.totalDevicesSection}>
				<Grid item className={styles.totalDevices}>
					<Grid item className={styles.deviceCardTitle}>
						<Typography variant="body1" fontWeight={500}>
							Total devices
						</Typography>
					</Grid>
					<Grid
						item
						className={styles.deviceCardContent}
						mb={1}
						onClick={handleUseTotalDevicesFilter}
					>
						{!isLoading ? (
							<Typography variant="h3">{allDevices.length}</Typography>
						) : (
							<Skeleton variant="text" width={20} height={15} />
						)}
						<Typography variant="description" className={styles.deviceCardDescription}>
							Detected in Entra
						</Typography>
					</Grid>
				</Grid>
				<Grid item className={styles.entraDevices}>
					<Grid item className={styles.deviceCardTitle}>
						<Grid item className={styles.logoAndTitle}>
							<EntraIdLogo className={styles.logo} />
							<Typography variant="body1">Entra</Typography>
						</Grid>
						<DeviceInfoIcon type="totalDevices" />
					</Grid>
					<Grid container>
						<NumberAndDescription deviceIds={joined} description="Joined" />
						<Divider orientation="vertical" className={styles.divider} />
						<NumberAndDescription deviceIds={registered} description="Registered" />
					</Grid>
				</Grid>
			</Grid>
			<Grid container className={styles.intuneDevicesSection}>
				<Grid container item className={styles.deviceCardTitle}>
					<Grid item className={styles.logoAndTitle}>
						<IntuneLogo className={styles.logo} />
						<Grid item>
							<Typography variant="body1" mb={-0.6}>
								Intune
							</Typography>
							<Typography variant="description" color="text.secondary">
								{intuneManaged.length} enrolled
							</Typography>
						</Grid>
					</Grid>
					<DeviceInfoIcon type="enrollmentStatus" />
				</Grid>
				<Grid container>
					<NumberAndDescription
						deviceIds={ironstonePolicy}
						description="Ironstone policy"
						filter={filters.Intune.ironstonePolicy}
						colorClass="green"
					/>
					<Divider orientation="vertical" className={styles.divider} />
					<NumberAndDescription
						deviceIds={customerPolicy}
						description="Customer policy"
						filter={filters.Intune.customerPolicy}
					/>
					<Divider orientation="vertical" className={styles.divider} />
					<NumberAndDescription
						deviceIds={noPolicy}
						description="No policy"
						filter={filters.Intune.noPolicy}
						colorClass="red"
					/>
				</Grid>
			</Grid>
			<Grid container className={styles.deviceStatusSection}>
				<Grid container item className={styles.deviceCardTitle}>
					<Grid item className={styles.logoAndTitle}>
						<Devices className={styles.deviceIcon} />
						<Typography variant="body1">Device status</Typography>
					</Grid>
					<DeviceInfoIcon type="deviceStatus" />
				</Grid>
				<Grid container item>
					<NumberAndDescription
						deviceIds={compliantDevices}
						description={DeviceComplianceStatus.Compliant}
						filter={filters.DeviceStatus.compliant}
						colorClass="green"
					/>
					<Divider orientation="vertical" className={styles.divider} />
					<NumberAndDescription
						deviceIds={nonCompliantDevices}
						description={DeviceComplianceStatus.NonCompliant}
						filter={filters.DeviceStatus.nonCompliant}
						colorClass="red"
					/>
					<Divider orientation="vertical" className={styles.divider} />
					<NumberAndDescription
						deviceIds={inactiveDevices}
						description={DeviceComplianceStatus.Inactive}
						filter={filters.DeviceStatus.inactive}
						colorClass="yellow"
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

interface NumberAndDescriptionProps {
	deviceIds: string[];
	description: string;
	filter?: string;
	colorClass?: "green" | "red" | "yellow" | "";
}

const NumberAndDescription = ({
	deviceIds,
	description,
	filter,
	colorClass = "",
}: NumberAndDescriptionProps) => {
	const { dispatch } = useAuth();
	const { isLoading } = useAppSelector(selectDevices);
	const handleOnClick = () => {
		let newFilter = {};
		switch (filter) {
			case filters.Intune.ironstonePolicy:
				newFilter = {
					[DeviceFilterKey.INTUNE]: [IntuneManagementType.IronstonePolicyManaged],
				};
				break;
			case filters.Intune.customerPolicy:
				newFilter = {
					[DeviceFilterKey.INTUNE]: [IntuneManagementType.CustomerPolicyManaged],
				};
				break;
			case filters.Intune.noPolicy:
				newFilter = {
					[DeviceFilterKey.INTUNE]: [IntuneManagementType.NoPolicy],
				};
				break;
			case filters.DeviceStatus.nonCompliant:
				newFilter = {
					[DeviceFilterKey.COMPLIANCE]: [DeviceComplianceStatus.NonCompliant],
				};
				break;
			case filters.DeviceStatus.inactive:
				newFilter = {
					[DeviceFilterKey.COMPLIANCE]: [DeviceComplianceStatus.Inactive],
				};
				break;
			case filters.DeviceStatus.compliant:
				newFilter = {
					[DeviceFilterKey.COMPLIANCE]: [DeviceComplianceStatus.Compliant],
				};
				break;
		}
		dispatch(setFilterIds(deviceIds)); // Since we already have the filtered ids, we can just set them directly, instead of calculating which devices to show
		dispatch(setSpecificChosenDeviceFilter({ ...newFilter }));
	};

	const showColoredNumber = colorClass !== "" && deviceIds.length > 0;

	return (
		<Grid item className={styles.deviceCardContentContainer} onClick={handleOnClick}>
			<Grid item className={styles.deviceCardContent}>
				{!isLoading ? (
					<Typography
						fontSize={18}
						className={clsx(styles.numberText, {
							[styles[colorClass]]: showColoredNumber,
						})}
					>
						{deviceIds.length}
					</Typography>
				) : (
					<Skeleton variant="text" width={20} height={15} />
				)}
				<Typography variant="description" className={styles.deviceCardDescription}>
					{description}
				</Typography>
			</Grid>
		</Grid>
	);
};
