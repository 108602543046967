import { Grid } from "@mui/material";
import { LicenseSubscriptionRecommendation } from "types";
import { LicenseRecommendationHeader } from "./LicenseRecommendationHeader";
import { LicenseRecommendationContent } from "./LicenseRecommendationContent/LicenseRecommendationContent";
import { LicenseRecommendationDrawer } from "./ConfigureLicenseDrawer/LicenseRecommendationDrawer";

import { useState } from "react";
import { CostSavingsType } from "utilities/constants/enums";

import styles from "./LicenseRecommendation.module.scss";

interface RecommendationInputProps {
	isLoading: boolean;
	recommendation: LicenseSubscriptionRecommendation | any;
}

interface DrawerState {
	insightType: CostSavingsType;
	open: boolean;
}

export const LicenseRecommendation = ({ isLoading, recommendation }: RecommendationInputProps) => {
	const [drawerState, setDrawerOpen] = useState<DrawerState>({
		insightType: CostSavingsType.RemoveUnused,
		open: false,
	});

	const handleDrawerStateChange = (open: boolean, type: CostSavingsType) => {
		setDrawerOpen({
			insightType: type,
			open,
		});
	};

	return (
		<Grid container className={styles.content}>
			<Grid container>
				<Grid container>
					<LicenseRecommendationHeader
						isLoading={isLoading}
						title={recommendation?.subscriptionOfferName ?? ""}
						recommendation={recommendation}
					/>
				</Grid>
				<Grid container>
					<LicenseRecommendationContent
						isLoading={isLoading}
						recommendation={recommendation}
						handleDrawerStateChange={handleDrawerStateChange}
					/>
				</Grid>
			</Grid>
			{drawerState.open && (
				<LicenseRecommendationDrawer
					type={drawerState.insightType}
					recommendation={recommendation}
					open={drawerState.open}
					handleDrawerStateChange={handleDrawerStateChange}
				/>
			)}
		</Grid>
	);
};
