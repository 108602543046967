import { Grid, Typography } from "@mui/material";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import {
	selectHardwarePurchaseType,
	selectProductSwapDialogState,
	setHardwareDrawerClosed,
	setProductIdToSwap,
	setProductSwapDialogState,
} from "features/hardware/hardwarePage";
import {
	addItemToCart,
	productsSelectors,
	selectSwappableProducts,
	swapCartItems,
} from "features/hardware/products";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { HardwareImage } from "../HardwareImage";
import { HardwareProductExtended } from "types";
import { StockIndicator } from "../SelectHardware/HardwareBundles/StockIndicator";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import styles from "./SwapProductDialog.module.scss";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { ExternalLink } from "components/Common/Links";
import { addItemToBundle, swapBundleItems } from "features/hardware/hardwareBundles";
import { SwapType } from "utilities/constants/enums";
import { useEffect, useState } from "react";
import { calculateHardwarePrice } from "utilities/hardwareUtils/hardwareCostUtils";

export const SwapProductDialog = () => {
	const dispatch = useAppDispatch();
	const handleClose = () => {
		dispatch(setProductSwapDialogState({ isOpen: false }));
		dispatch(setProductIdToSwap(""));
	};
	const {
		isOpen,
		productId: productIdToSwap,
		count: swapCount,
		activeSwapType,
	} = useAppSelector(selectProductSwapDialogState);
	const hardwarePurchaseType = useAppSelector(selectHardwarePurchaseType);

	const products = useAppSelector(productsSelectors.selectEntities);
	const productToSwap = products[productIdToSwap] ?? ({} as HardwareProductExtended);
	const { price: productToSwapPrice } = calculateHardwarePrice(
		productToSwap.priceInfo,
		hardwarePurchaseType,
	);

	const swappableProducts =
		useAppSelector(selectSwappableProducts(productIdToSwap, swapCount)) ??
		([] as HardwareProductExtended[]);
	const top =
		swappableProducts.filter((product) => product?.sku !== productIdToSwap).slice(0, 10) ??
		([] as HardwareProductExtended[]);

	const handleSwapProduct = (productId: string) => {
		if (activeSwapType === SwapType.AddToBundle) {
			dispatch(addItemToBundle(productId));
		} else if (activeSwapType === SwapType.AddToCart) {
			dispatch(addItemToCart(productId));
		} else if (activeSwapType === SwapType.SwapWithCartProduct) {
			dispatch(swapCartItems({ productIdToSwap, productId, count: swapCount }));
		} else if (activeSwapType === SwapType.SwapWithBundleProduct) {
			dispatch(swapBundleItems({ productIdToSwap, productId, count: swapCount }));
		}

		dispatch(setProductIdToSwap(""));
		dispatch(setProductSwapDialogState({ isOpen: false }));
	};

	const [swapButtonText, setButtonTitle] = useState("Add to cart");

	useEffect(() => {
		const getSwapButtonText = () => {
			switch (activeSwapType) {
				case SwapType.AddToCart:
					return "Add to cart";
				case SwapType.AddToBundle:
					return "Add to bundle";
				case SwapType.SwapWithBundleProduct:
				case SwapType.SwapWithCartProduct:
					return "Swap";
				default:
					return "Swap";
			}
		};
		setButtonTitle(getSwapButtonText());
	}, [activeSwapType]);

	return isOpen ? (
		<PrimaryDialog
			title={`Alternative products for ${productToSwap.displayName}`}
			size="large"
			primaryAction={() => {}}
			disabledActions={false}
			primaryButtonText=""
			secondaryButtonText="Cancel"
			onLeaveAction={handleClose}
			isLoading={false}
			zIndex={1400}
		>
			<Grid container className={styles.container}>
				<Grid container className={styles.productToSwapContainer}>
					<HardwareImage
						productId={productIdToSwap}
						imageUrl={productToSwap.imageUrl}
						size="x_small"
					/>
					<Grid item>
						<Typography variant="body1">{productToSwap.displayName}</Typography>
						<Typography variant="caption" color="text.secondary">
							{productToSwap.description}
						</Typography>
					</Grid>
					<Grid item>
						<StockIndicator
							availability={productToSwap.availability}
							requestedQuantity={1}
							variant="horizontal"
						/>
					</Grid>
					<Grid item>
						<Typography variant="body1" noWrap>
							{formatCostString(" ", productToSwapPrice, " ,- excl. vat")}
						</Typography>
					</Grid>
				</Grid>
				<Grid item className={styles.similarProductsHeader}>
					<Typography variant="body1" noWrap>
						Similar alternatives in stock
					</Typography>
				</Grid>
				<Grid container className={styles.productsList}>
					{top.map(({ sku, imageUrl, displayName, description, priceInfo }) => {
						const productPriceInfo = calculateHardwarePrice(
							priceInfo,
							hardwarePurchaseType,
						);
						const priceDiff =
							productPriceInfo?.price -
							calculateHardwarePrice(productToSwap.priceInfo, hardwarePurchaseType)
								?.price;
						return (
							<Grid container key={sku} className={styles.listItem}>
								<Grid className={styles.imageContainer}>
									<HardwareImage
										productId={sku}
										imageUrl={imageUrl}
										size="small"
									/>
								</Grid>
								<Grid className={styles.infoContainer}>
									<Typography variant="description">{displayName}</Typography>
									<Typography
										variant="caption"
										color="text.secondary"
										mt={0.8}
										mb={1}
									>
										{description.substring(displayName.length)}
									</Typography>
									<ExternalLink
										href={`https://www.komplettbedrift.no/compare?id=${productIdToSwap}&id=${sku}`}
										textDecoration="underline"
										target="_blank"
									>
										Compare products
									</ExternalLink>
								</Grid>
								<Grid className={styles.priceContainer}>
									<Grid
										item
										xs={8}
										display="flex"
										justifyContent={"space-between"}
									>
										<Typography variant="body1" mr={2}>
											{formatCostString(" ", productPriceInfo?.price, " ,- ")}
										</Typography>
										{priceDiff > 0 ? (
											<Typography
												variant="body1"
												className={styles.newProductMoreExpensive}
											>
												{`(+ ${formatCostString(
													"",
													Math.abs(priceDiff),
													"",
												)})`}
											</Typography>
										) : (
											<Typography
												variant="body1"
												className={styles.newProductLessExpensive}
											>
												{`(- ${formatCostString(
													"",
													Math.abs(priceDiff),
													"",
												)})`}
											</Typography>
										)}
									</Grid>
									<Typography variant="description" color="text.secondary">
										{productPriceInfo?.subText}
									</Typography>
								</Grid>
								<Grid className={styles.swapButtonContainer}>
									<PrimaryButton
										text={swapButtonText}
										icon={<SwapHorizIcon className={styles.itemSwapIcon} />}
										action={() => handleSwapProduct(sku)}
										size="small"
										variantStyle="contained"
									/>
								</Grid>
							</Grid>
						);
					})}
				</Grid>
				{swappableProducts.length === 0 && (
					<Grid container className={styles.noProductsContainer}>
						<Typography variant="h2" align="center">
							Hmm, we couldn't find any similar products in stock. <br /> Please refer
							to the complete product list to find a suitable alternative.
						</Typography>
						<PrimaryButton
							text="Go to product list"
							action={() => {
								dispatch(setProductSwapDialogState({ isOpen: false }));
								dispatch(setHardwareDrawerClosed());
								dispatch(setProductIdToSwap(""));
							}}
							size="medium"
							variantStyle="contained"
						/>
					</Grid>
				)}
			</Grid>
		</PrimaryDialog>
	) : null;
};
