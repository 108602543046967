import { useRef, useEffect } from "react";

/**
 * Hook to automatically update a CSS variable based on the dimensions of a React ref.
 * @param varName The name of the CSS variable to update.
 */
export const useDynamicCSSVar = (varName: string) => {
	const ref = useRef(null);

	useEffect(() => {
		const element = ref.current; // Capture the current value of ref.current

		if (element) {
			const resizeObserver = new ResizeObserver((entries) => {
				for (let entry of entries) {
					document.documentElement.style.setProperty(
						`--${varName}`,
						`${entry.contentRect.height}px`,
					);
				}
			});

			resizeObserver.observe(element);

			return () => {
				resizeObserver.unobserve(element); // Use the captured value
			};
		}
	}, [varName]); // Only recreate the effect if varName changes

	return ref;
};
