import { Grid } from "@mui/material";

import styles from "./LegendItemContainer.module.scss";
import clsx from "clsx";

interface LegendItemContainerProps {
	className?: string;
	alignWithMinWidth?: boolean;
	children?: React.ReactNode;
}

export const LegendItemContainer = ({
	className,
	children,
	alignWithMinWidth,
}: LegendItemContainerProps) => (
	<Grid
		container
		className={clsx(styles.legendContainer, className, {
			[styles.alignWithMinWidth]: alignWithMinWidth,
		})}
	>
		{children}
	</Grid>
);
