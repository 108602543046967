import { ReactElement, useState } from "react";
import { Tooltip, TooltipProps, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import styles from "./Tooltips.module.scss";

interface HoverTooltipProps {
	title?: string | ReactElement<any, any>;
	description?: string | ReactElement<any, any>;
	hide?: boolean;
	children: ReactElement<any, any>;
	placement?: TooltipProps["placement"];
}

const HoverTooltip = ({
	title,
	description,
	hide = false,
	children,
	placement = "bottom",
}: HoverTooltipProps): JSX.Element => {
	if (hide) {
		// Sometimes we want to conditionally hide the tooltip:
		// e.g in cases where something is to be wrapped for certain elements, but not others
		return children;
	}

	return (
		<Tooltip
			placement={placement}
			classes={{ tooltip: styles.tooltip }}
			title={
				<div>
					{title && typeof title === "string" ? (
						<Typography className={styles.title}>{title}</Typography>
					) : (
						title
					)}
					{description && typeof description === "string" ? (
						<Typography className={styles.description}>{description}</Typography>
					) : (
						description
					)}
				</div>
			}
		>
			{children}
		</Tooltip>
	);
};

const ButtonTooltip = ({ title, description }: Omit<HoverTooltipProps, "children">) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Tooltip
			open={isOpen}
			classes={{ tooltip: styles.tooltip }}
			title={
				<>
					{title && <h3>{title}</h3>}
					{description && <p>{description}</p>}
				</>
			}
		>
			<IconButton onClick={() => setIsOpen(!isOpen)} aria-label="Click for more info">
				<InfoOutlinedIcon />
			</IconButton>
		</Tooltip>
	);
};

export { HoverTooltip, ButtonTooltip };
