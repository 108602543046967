import { Grid, IconButton, Typography } from "@mui/material";
import { ReactComponent as IronStoneLogo } from "assets/logo/ironstone_logo_no_title.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { selectRoles } from "features/roles";
import { DrawerListItem } from "components/DrawerList/DrawerListItem";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { handleLogout } from "utilities/handleLogout";

import styles from "./DrawerAvatarSection.module.scss";
import {
	selectAppRoleAssignmentOptions,
	selectAppRoleAssignments,
} from "features/appRoleAssignments";
import { fetchAppRoleAssignments } from "actions/customerActions";
import { selectCustomerDetails } from "features/customer";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";

interface DrawerAvatarSectionProps {
	isProfileDrawerListOpen: boolean;
	setIsProfileDrawerListOpen: (value: boolean) => void;
}

const DrawerAvatarSection = ({
	isProfileDrawerListOpen,
	setIsProfileDrawerListOpen = () => {},
}: DrawerAvatarSectionProps): JSX.Element => {
	const {
		auth: { account, instance },
	} = useAuth();

	const roles = useAppSelector(selectRoles);
	useApiOnce(fetchAppRoleAssignments, useAppSelector(selectAppRoleAssignments));
	const roleOptions =
		useAppSelector(selectAppRoleAssignmentOptions) ?? ({} as Record<string, string>);
	const displayName = roleOptions[roles[0]] ?? roles;

	const shownName =
		(account?.name ?? "").length > 15
			? `${account?.name?.split(" ")[0]} ${account?.name?.split(" ")[1]?.charAt(0)}.`
			: account?.name;

	// If logged in user is impersonating another tenant, show the entitlement name in the drawer
	const activeEntitlementFromSessionStorage = sessionStorage.getItem("entitlement");
	const showEntitlementInDrawer = activeEntitlementFromSessionStorage
		? activeEntitlementFromSessionStorage !== account.homeAccountId.split(".")[1]
		: false;
	const customerInfo = useAppSelector(selectCustomerDetails);

	return (
		<>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.avatarSectionContainer}
				onClick={() => setIsProfileDrawerListOpen(!isProfileDrawerListOpen)}
			>
				{showEntitlementInDrawer ? (
					<Typography variant="description" fontWeight={500} maxWidth={140}>
						{tripleDotTruncateString(customerInfo?.departmentName, 40)}
					</Typography>
				) : (
					<>
						<IronStoneLogo className={styles.ironstoneLogo} />
						<Grid item className={styles.userContainer}>
							<Typography variant="description" className={styles.name}>
								{shownName}
							</Typography>
							<Typography variant="caption" color="text.secondary">
								{displayName ?? roles[0]}
							</Typography>
						</Grid>
					</>
				)}
				{isProfileDrawerListOpen ? (
					<IconButton onClick={() => setIsProfileDrawerListOpen(false)}>
						<KeyboardArrowUpIcon />
					</IconButton>
				) : (
					<IconButton onClick={() => setIsProfileDrawerListOpen(true)}>
						<KeyboardArrowDownIcon />
					</IconButton>
				)}
			</Grid>
			{isProfileDrawerListOpen && (
				<Grid item className={styles.profileDrawerList}>
					<DrawerListItem
						key={NAVIGATION_NAMES.MY_PROFILE.value}
						navigationName={NAVIGATION_NAMES.MY_PROFILE}
						prefixIcon={<PersonIcon />}
					/>
					<DrawerListItem
						key={NAVIGATION_NAMES.LOGOUT.value}
						navigationName={NAVIGATION_NAMES.LOGOUT}
						prefixIcon={<ExitToAppIcon />}
						onClick={() => handleLogout({ account, instance })}
					/>
				</Grid>
			)}
		</>
	);
};

export { DrawerAvatarSection };
