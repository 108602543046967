import styles from "./InvoicePaymentStatus.module.scss";

const InvoicePaymentStatus = ({
	status,
	documentType,
}: {
	status: string;
	documentType: string;
}) => {
	if (!status) {
		return <></>;
	}

	const target =
		documentType?.toUpperCase() === "CREDITNOTE"
			? `${status + " - " + documentType}`.toUpperCase()
			: status.toUpperCase();

	const { style, text } = [
		{
			style: styles.paidLabel,
			match: "Paid - CreditNote",
			text: "Credit Note - Refunded",
		},
		{
			style: styles.openLabel,
			match: "Open - CreditNote",
			text: "Credit Note - Open",
		},
		{
			style: styles.paidLabel,
			match: "Paid",
			text: "Paid",
		},
		{
			style: styles.openLabel,
			match: "Open",
			text: "Open",
		},
	].find(({ match }) => target === match.toUpperCase()) ?? {
		style: styles.unknownRateLabel,
		text: "Unknown",
	};

	if (!style || !text) return <>{status}</>;

	return <span className={style}>{text}</span>;
};

export { InvoicePaymentStatus };
