import {
	Divider,
	FormControl,
	FormGroup,
	FormLabel,
	Grid,
	Skeleton,
	Typography,
} from "@mui/material";
import styles from "./FilterGroup.module.scss";
import { Manufacturer } from "utilities/constants/enums";
import _ from "lodash";
import { PrimaryCheckbox } from "components/Common/Checkboxes";
import { ChangeEvent, SyntheticEvent } from "react";
import {
	HardwareGroup,
	HardwareGroups,
	Manufacturers,
	HardwareProductExtended as Product,
} from "types";
import { Slider } from "../Slider";
import { HARDWARE_GROUP_LABELS } from "utilities/constants/constants";

export interface FilterGroupProps {
	selectedGroups: HardwareGroups;
	selectedManufacturers: Manufacturers;
	products: Product[];
	showInStockOnly: boolean;
	handleGroupChange: (e: ChangeEvent<HTMLInputElement>) => void;
	handleManufacturerChange: (e: ChangeEvent<HTMLInputElement>) => void;
	resetRenderCount: () => void;
	setSearchQuery: (value: string) => void;
	setShowInStockOnly: (value: boolean) => void;
	setPricerange: (
		event: Event | SyntheticEvent<Element, Event>,
		value: number | number[],
	) => void;
	pricerange: number[];
	maxPrice: number;
}

const FilterGroup = ({
	selectedGroups,
	selectedManufacturers,
	products,
	showInStockOnly,
	handleGroupChange,
	handleManufacturerChange,
	resetRenderCount,
	setSearchQuery,
	setShowInStockOnly,
	setPricerange,
	pricerange,
	maxPrice,
}: FilterGroupProps) => {
	const renderCheckboxes = (selected: HardwareGroups | Manufacturers) => {
		const handleCheckboxChange =
			"PC_ACCESSORIES" in selected ? handleGroupChange : handleManufacturerChange;

		const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
			handleCheckboxChange(e);
			resetRenderCount();
			setSearchQuery("");
		};

		const entities = Object.keys(selected) as (HardwareGroup & Manufacturer)[];
		return entities
			.filter((entity) => entity !== "APPLE_PC_NOTEBOOK")
			.map((entity) => (
				<PrimaryCheckbox
					label={
						<Grid container alignItems="center">
							<Typography variant="caption">
								{HARDWARE_GROUP_LABELS[entity] ?? entity}
							</Typography>
						</Grid>
					}
					key={HARDWARE_GROUP_LABELS[entity] ?? entity}
					onChange={handleChange}
					checked={selected[entity]}
					name={entity}
					size="small"
				/>
			));
	};

	const isProductsEmpty = _.isEmpty(products);

	return (
		<FormControl className={styles.container}>
			<Grid className={styles.groups}>
				{!isProductsEmpty ? (
					<PrimaryCheckbox
						label={
							<Grid container alignItems="center">
								<Typography variant="caption">In stock</Typography>
							</Grid>
						}
						onChange={(e) => setShowInStockOnly(e.target.checked)}
						checked={showInStockOnly}
						size="small"
					/>
				) : (
					<Skeleton height={16} className={styles.skeleton} />
				)}
			</Grid>
			<Divider className={styles.groupDivider} />
			<FormLabel id="manufacturer-checkbox-group-label">
				<Typography variant="subtitle2">
					<strong>Product type</strong>
				</Typography>
			</FormLabel>

			{!isProductsEmpty ? (
				<FormGroup>{renderCheckboxes(selectedGroups)}</FormGroup>
			) : (
				<Skeleton height={200} className={styles.skeleton} />
			)}

			<Divider className={styles.groupDivider} />

			<FormLabel id="manufacturer-checkbox-group-brand-label">
				<Typography variant="subtitle2">
					<strong>Brand</strong>
				</Typography>
			</FormLabel>

			{!isProductsEmpty ? (
				<FormGroup>{renderCheckboxes(selectedManufacturers)}</FormGroup>
			) : (
				<Skeleton height={200} className={styles.skeleton} />
			)}
			<Divider className={styles.groupDivider} />
			<Grid container>
				<Slider
					title="Price (NOK)"
					unit="NOK"
					commitedValue={pricerange}
					step={10}
					maxValue={maxPrice}
					setValueRange={setPricerange}
					isLoading={isProductsEmpty}
				/>
			</Grid>
			<Divider className={styles.groupDivider} />
		</FormControl>
	);
};

export { FilterGroup };
