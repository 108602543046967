import { MouseEventHandler } from "react";
import clsx from "clsx";
import { Grid, Typography, Skeleton } from "@mui/material";

import styles from "./RiskNotification.module.scss";
import { RiskNotificationSkeleton } from "./RiskNotificationSkeleton";
import { InternalLink } from "components/Common/Links";
import { HoverTooltip } from "components/Common/Tooltips";
import InfoIcon from "@mui/icons-material/Info";

interface RiskNotificationProps {
	isLoading: boolean;
	numberOfFields: 1 | 2 | 3 | 4;
	highRiskCount: number;
	mediumRiskCount: number;
	lowRiskCount: number;
	hiddenRiskCount?: number;
	onViewDetails: MouseEventHandler;
	severityType: string;
	linkLabelText: string;
	title: string;
}

const RiskNotification = ({
	isLoading = false,
	numberOfFields = 3,
	highRiskCount = 0,
	mediumRiskCount = 0,
	lowRiskCount = 0,
	hiddenRiskCount = 0,
	onViewDetails,
	severityType = "",
	title,
	linkLabelText = "View details",
}: RiskNotificationProps): JSX.Element => {
	return (
		<Grid className={clsx(styles.paper, !isLoading && styles.clickable)}>
			<Grid container className={styles.topSectionContainer} justifyContent="space-between">
				<Typography variant="body1" fontWeight={500}>
					{title}
				</Typography>
			</Grid>
			<Grid container justifyContent="flex-end" mb={2} pr={2}>
				{!isLoading ? (
					<InternalLink
						data-testid="button.onViewDetails"
						variant="description"
						onClick={onViewDetails as React.MouseEventHandler}
					>
						{linkLabelText}
					</InternalLink>
				) : (
					<Skeleton variant="text" width={120} height={24} />
				)}
			</Grid>
			<Grid container className={styles.riskContainerWrapper}>
				<Grid
					item
					data-testid="indicator.high.container"
					className={styles.riskContainer}
					xs
					pb={3.5}
				>
					{isLoading ? (
						<RiskNotificationSkeleton />
					) : (
						<>
							<Typography
								variant="h2"
								className={clsx({
									[styles.highRisk]: highRiskCount > 0,
									[styles.lowRisk]: highRiskCount === 0,
								})}
							>
								{highRiskCount}
							</Typography>
							<Typography
								variant="body2"
								fontWeight={400}
							>{`High ${severityType}`}</Typography>
						</>
					)}
				</Grid>
				{numberOfFields > 1 && (
					<Grid
						item
						data-testid="indicator.medium.container"
						xs
						className={styles.riskContainer}
					>
						{isLoading ? (
							<RiskNotificationSkeleton />
						) : (
							<>
								<Typography
									variant="h2"
									className={clsx({
										[styles.mediumRisk]: mediumRiskCount > 0,
										[styles.lowRisk]: mediumRiskCount === 0,
									})}
								>
									{mediumRiskCount}
								</Typography>
								<Typography
									variant="body2"
									fontWeight={400}
								>{`Medium ${severityType}`}</Typography>
							</>
						)}
					</Grid>
				)}
				{numberOfFields > 2 && (
					<Grid
						item
						data-testid="indicator.low.container"
						xs
						className={styles.riskContainer}
					>
						{isLoading ? (
							<RiskNotificationSkeleton />
						) : (
							<>
								<Typography variant="h2" className={styles.lowRisk}>
									{lowRiskCount}
								</Typography>
								<Typography
									variant="body2"
									fontWeight={400}
								>{`Low ${severityType}`}</Typography>
							</>
						)}
					</Grid>
				)}
				{hiddenRiskCount > 0 && !isLoading && (
					<Grid
						item
						xs
						className={clsx(styles.riskContainer, styles.notEvaluatedBackground)}
					>
						<>
							<HoverTooltip
								title=""
								description={
									<Typography className={styles.tooltipText}>
										Certain sign-in risk detections requires Azure AD premium 2
										license from Microsoft. With 'Extended Security' from
										Ironstone we will be able to evaluate all sign-in risks for
										your company
									</Typography>
								}
							>
								<InfoIcon className={styles.infoIcon} />
							</HoverTooltip>
							<Typography variant="h2" className={styles.notEvaluated}>
								{hiddenRiskCount}
							</Typography>
							<Typography
								variant="body2"
								fontWeight={400}
							>{`Not evaluated`}</Typography>
						</>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export { RiskNotification };
