import { Drawer, Hidden } from "@mui/material";

import { DrawerList } from "../DrawerList";
import { DrawerAvatarSection } from "../DrawerAvatarSection";

import clsx from "clsx";

import styles from "./LeftNavigation.module.scss";
import { useRef, useState } from "react";
import { useClickOutside } from "hooks/hooks";

const LeftNavigation = ({ togglLeftNavigation, isNavOpen }) => {
	const [isProfileDrawerListOpen, setIsProfileDrawerListOpen] = useState(false);

	const clickOutsideRef = useRef(null);
	useClickOutside(clickOutsideRef, () => setIsProfileDrawerListOpen(false));

	return (
		<>
			<nav className={clsx(styles.drawerStandard, styles.largeScreenNavigation)}>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: styles.drawerPaper,
						}}
						variant="permanent"
						open
						ModalProps={{
							keepMounted: true,
						}}
					>
						<div ref={clickOutsideRef}>
							<DrawerAvatarSection
								isProfileDrawerListOpen={isProfileDrawerListOpen}
								setIsProfileDrawerListOpen={setIsProfileDrawerListOpen}
							/>
							<DrawerList isProfileDrawerListOpen={isProfileDrawerListOpen} />
						</div>
					</Drawer>
				</Hidden>
			</nav>
			<nav className={clsx(styles.drawerSmallScreen)}>
				<Drawer
					anchor="left"
					classes={{
						paper: styles.drawerPaper,
						root: styles.hideForLargeScreens,
					}}
					variant="temporary"
					open={isNavOpen}
					ModalProps={{
						keepMounted: true,
					}}
				>
					<div>
						<DrawerAvatarSection
							isProfileDrawerListOpen={isProfileDrawerListOpen}
							setIsProfileDrawerListOpen={setIsProfileDrawerListOpen}
						/>
						<DrawerList setNavClosed={() => togglLeftNavigation(false)} />
					</div>
				</Drawer>
			</nav>
		</>
	);
};

export { LeftNavigation };
