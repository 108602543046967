import { FormControl, FormControlLabel, Radio, RadioGroup, Typography, Grid } from "@mui/material";

import GetAppIcon from "@mui/icons-material/GetApp";
import _ from "lodash";

import styles from "./DownloadableLink.module.scss";
import { useRef, useState } from "react";
import { PrimaryButton } from "../Buttons/Button";
import excelDownload from "utilities/excelUtils/downloadExcel";
import { useAuth, useClickOutside } from "hooks/hooks";
import { InternalLink } from "../Links";
import { setGlobalSnackbarState } from "features/snackbars";

const DownloadableLink = ({ data, fileName, csvItemsDelimiter = ";", shouldFormatNumbers }) => {
	const { dispatch } = useAuth();
	return (
		<InternalLink
			variant="caption"
			onClick={() =>
				csvDownload(dispatch, data, fileName, csvItemsDelimiter, shouldFormatNumbers)
			}
		>
			<div className={styles.downloadField}>
				<div className={styles.downloadIconWrapper}>
					<GetAppIcon className={styles.downloadIcon} />
				</div>

				<span className={styles.downloadButtonText}>Open in Excel</span>
			</div>
		</InternalLink>
	);
};

export const DownloadableDropdown = ({
	isLoading,
	getData,
	options,
	setSelectedOption,
	selectedOption,
}) => {
	const { dispatch } = useAuth();
	const [open, setOpen] = useState(false);

	const handleChange = (event) => {
		const option = options.find(({ value }) => value === event.target.value);
		setSelectedOption(option);
	};

	const handleDownload = (data) => {
		const [sheetsData, sheetNames] = Object.entries(data).reduce(
			([dataArr, namesArr], [key, value]) => {
				if (value instanceof Array) {
					dataArr.push(value);
					namesArr.push(key);
				}
				return [dataArr, namesArr];
			},
			[[], []],
		);
		const fileName = selectedOption.label ? `${selectedOption.label}.xlsx` : "costs.xlsx";
		const sheetsDataWithSelectedOption = sheetsData.map((sheetData) =>
			sheetData.slice(0, selectedOption.value),
		);
		excelDownload(sheetsDataWithSelectedOption, fileName, sheetNames);
	};

	const handleOpen = () => {
		setOpen(!open);
	};

	const onGetData = async () => {
		const data = await getData();
		setOpen(false);
		handleDownload(data);
		dispatch(
			setGlobalSnackbarState({
				open: true,
				snackbarText: "File downloaded",
				snackbarSeverity: "success",
				duration: 3000,
			}),
		);
	};

	const dropdownRef = useRef(null);
	useClickOutside(dropdownRef, () => setOpen(false));

	return (
		<Grid container className={styles.exportToExcelContainer} ref={dropdownRef}>
			<Grid item className={styles.exportToExcel} onClick={handleOpen}>
				<Typography variant="body1" noWrap>
					Export
				</Typography>
				<GetAppIcon className={styles.downloadIcon} />
			</Grid>
			<Grid item hidden={!open} className={styles.exportToExcelDialog}>
				<Grid item mb={1}>
					<Typography variant="description" fontWeight={500}>
						Choose cost interval
					</Typography>
				</Grid>
				<FormControl component="fieldset">
					<RadioGroup value={selectedOption} onChange={handleChange} row={false}>
						{options.map(({ value, label }) => (
							<FormControlLabel
								key={value}
								value={value}
								checked={value === selectedOption.value}
								control={<Radio size="small" />}
								label={label}
								onClick={() => handleChange({ target: { value } })}
								labelPlacement="end"
								style={{ width: "100%" }}
							/>
						))}
					</RadioGroup>
				</FormControl>
				<Grid item>
					<PrimaryButton
						text="Export"
						size="small"
						action={onGetData}
						marginTop={2}
						isLoading={isLoading}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

const csvDownload = (dispatch, data, name, csvItemsDelimiter, shouldFormatNumbers, extraHeader) => {
	try {
		let items = data;
		const filename = name || `export.csv`;
		const d = csvItemsDelimiter || `;`;

		// Change all numbers to have a specific delimiter instead of the
		// default dot ".". By changing this to comma "," Excel will automatically
		// format numbers to their correct format
		if (shouldFormatNumbers) {
			items = items.map((item) => {
				for (const [key, value] of Object.entries(item)) {
					if (_.isNumber(value)) {
						item[key] = value.toString().replace(".", ",");
					}
				}
				return item;
			});
		}

		const header = Array.from(new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], [])));
		let csv = items.map((row) =>
			header.map((fieldName) => JSON.stringify(row[fieldName] || "")).join(d),
		);
		extraHeader && csv.unshift(extraHeader);
		csv.unshift(header.join(d));
		csv = csv.join("\r\n");

		const blob = new Blob(["\uFEFF" + csv], {
			type: "text/plain;charset=utf-8",
		});

		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, filename);
			return;
		}
		const link = document.createElement("a");
		const url = URL.createObjectURL(blob);
		link.href = url;
		link.download = filename;
		link.style.display = "none";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		dispatch(
			setGlobalSnackbarState({
				open: true,
				snackbarText: "File downloaded",
				snackbarSeverity: "success",
				duration: 3000,
			}),
		);
	} catch (error) {
		dispatch(
			setGlobalSnackbarState({
				open: true,
				snackbarText: "Error downloading file",
				snackbarSeverity: "error",
			}),
		);
	}
};

export { DownloadableLink, csvDownload };
