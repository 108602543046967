import { setLicenseAssignmentState } from "features/licenses/licenses";
import { useAppSelector, useAuth } from "hooks/hooks";
import { LoadingDialog } from "components/Common/Dialogs/Dialog";
import { ActionLoadingStatus } from "utilities/constants/enums";
import { useEffect, useMemo, useState } from "react";
import { selectPurchaseActionInfo } from "features/scheduledActions";
import { LicenseAssignmentAndPurchase } from "types";

interface LicenseAssignmentDialogProps {
	purchaseAndAssignments: LicenseAssignmentAndPurchase;
}

export const LicenseAssignmentDialog = ({
	purchaseAndAssignments,
}: LicenseAssignmentDialogProps) => {
	const { dispatch } = useAuth();
	const licensePurchaseState = useAppSelector(selectPurchaseActionInfo);

	const handleGoToSummary = () => {
		dispatch(setLicenseAssignmentState({ showLoadingDialog: false, showReceiptPage: true }));
	};

	const initialSteps = [
		{
			title: "Ordering missing licenses",
			status: ActionLoadingStatus.NOT_STARTED,
			showStep: Object.values(purchaseAndAssignments.purchases)?.length > 0,
		},
		{
			title: "Preparing license assignments",
			status: ActionLoadingStatus.IN_PROGRESS,
			showStep: true,
		},
	];

	const [steps, setSteps] = useState(initialSteps);

	const [timer, setTimer] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setTimer((prevTimer) => prevTimer + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const purchaseState = steps[0];
		const assignmentState = steps[1];
		if (purchaseState.showStep) {
			if (licensePurchaseState.isPurchasing) {
				purchaseState.status = ActionLoadingStatus.IN_PROGRESS;
			} else if (licensePurchaseState.isError) {
				purchaseState.status = ActionLoadingStatus.FAILED;
			} else if (!licensePurchaseState.isPurchasing && !licensePurchaseState.isError) {
				purchaseState.status = ActionLoadingStatus.COMPLETED;
			}
		}

		if (timer > 5) {
			assignmentState.status = ActionLoadingStatus.COMPLETED;
		}

		setSteps([purchaseState, assignmentState]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timer]);

	const shownSteps = steps.filter((step) => step.showStep);
	const disableActions = useMemo(() => {
		const anyShownStepInProgress = shownSteps.some(
			(step) => step.status === ActionLoadingStatus.IN_PROGRESS,
		);
		return anyShownStepInProgress;
	}, [shownSteps]);

	return (
		<LoadingDialog
			open={true}
			title="Assigning licenses"
			steps={shownSteps}
			onLeaveAction={handleGoToSummary}
			primaryButtonText="Go to summary"
			primaryAction={handleGoToSummary}
			disableActions={disableActions}
		/>
	);
};
