import { Grid, ListItem, Skeleton } from "@mui/material";
import type { ReactElement } from "react";

export function SecurityIncidentSkeleton(): ReactElement {
	return (
		<ListItem>
			<Grid pr={4}>
				<Skeleton variant="circular" width={20} height={20} />
			</Grid>
			<Grid container direction="column">
				<Skeleton variant="text" width={100} height={30} />
				<Skeleton variant="text" width={200} height={30} />
				<Grid item>
					<Skeleton variant="rectangular" width={400} height={60} />
				</Grid>
			</Grid>
		</ListItem>
	);
}
