import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import {
	createUser,
	deleteUser,
	fetchManager,
	fetchUser,
	fetchUserDevices,
	fetchUsers,
	setManager,
	updateUser,
} from "actions/userActions";
import { RootState } from "store";
import { Group, LicenseAssignedToUser, LicenseGroup, LicenseRemovalResponse, User } from "types";
import _ from "lodash";
import { SEARCH_NO_MATCH } from "utilities/constants/constants";
import { assignLicenses, removeLicenses } from "actions/licenseActions";
import {
	UserAuthenticationMethod,
	LicenseAssignmentState,
	UserActivityType,
	UserType,
} from "utilities/constants/enums";
import dayjs from "dayjs";

const usersAdapter = createEntityAdapter<User>();

interface InitialState {
	searchIds: string[];
	manager: {
		// Used to track if a manager is being fetched
		[userId: string]: {
			isFetching: boolean;
			isLoading: boolean;
		};
	};
	userTypeFilter: UserType[];
	userActivityFilter: UserActivityType[];
	userAuthenticationMethodFilter: UserAuthenticationMethod[];
	isLoading: boolean;
	isFetching: boolean;
	userDeviceInfo: string;
}

const usersSlice = createSlice({
	name: "users",
	initialState: usersAdapter.getInitialState({
		searchIds: [],
		manager: {},
		userTypeFilter: [] as UserType[],
		userActivityFilter: [] as UserActivityType[],
		userAuthenticationMethodFilter: [] as UserAuthenticationMethod[],
		isLoading: true,
		isFetching: false,
		userDeviceInfo: "",
	} as InitialState),
	reducers: {
		clearSearchedUsers(state) {
			state.searchIds = [];
		},
		setSearchedUsers(state, { payload }) {
			state.searchIds = payload;
		},
		setUserTypeFilter(state, { payload }) {
			state.userTypeFilter = payload;
		},
		setUserActivityFilter(state, { payload }) {
			state.userActivityFilter = payload;
		},
		setUserAuthenticationMethodFilter(state, { payload }) {
			state.userAuthenticationMethodFilter = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUsers.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchUsers.fulfilled, (state, { payload }) => {
				const mappedWithUserType = payload.map((user) => ({
					...user,
					userType:
						user.userType === UserType.Guest
							? UserType.Guest
							: user.identities?.some((i) => i.issuer === "ExternalAzureAD")
							? UserType.ExternalMember
							: UserType.Member,
					signInActivityStatus: getUserActivityStatus(user),
				}));
				usersAdapter.upsertMany(state, mappedWithUserType);
				state.isFetching = false;
				state.isLoading = false;
			});
		builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
			if (_.isEmpty(payload)) return;
			usersAdapter.upsertOne(state, payload);
		});
		builder.addCase(
			createUser.fulfilled,
			(
				state,
				{
					payload,
					meta: {
						arg: { body },
					},
				},
			) => {
				const consolidatedUpdatedUser = {
					...payload,
					country: body?.country,
					department: body?.department,
					privateEmail: body?.privateEmail,
					displayName: `${body?.givenName} ${body?.surname}`, // Display name is being set based on this pattern backend
					groups: body?.licenseGroups.map(({ groupID, groupName }: LicenseGroup) => {
						return { id: groupID, name: groupName, isLicenseGroup: true };
					}),
				};
				usersAdapter.addOne(state, consolidatedUpdatedUser);
			},
		);
		builder.addCase(
			updateUser.fulfilled,
			(
				state,
				{
					meta: {
						arg: { id, body },
					},
				},
			) => {
				const userChanges = {
					...body,
					displayName: `${body?.givenName} ${body?.surname}`, // Display name is being set based on this pattern backend
				};
				usersAdapter.updateOne(state, { id, changes: userChanges as User });
			},
		);
		builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
			usersAdapter.removeOne(state, payload.deletedUserId);
		});
		builder
			.addCase(fetchUserDevices.pending, (state, { meta }) => {
				state.userDeviceInfo = meta.arg.id ?? "";
			})
			.addCase(
				fetchUserDevices.fulfilled,
				(
					state,
					{
						payload,
						meta: {
							arg: { id },
						},
					},
				) => {
					const sorted = [
						...payload.sort((a, b) => {
							const aDate = dayjs(a.lastActivityDate);
							const bDate = dayjs(b.lastActivityDate);
							if (aDate.isBefore(bDate)) return 1;
							if (aDate.isAfter(bDate)) return -1;
							return 0;
						}),
					];
					usersAdapter.updateOne(state, { id: id ?? "", changes: { devices: sorted } });
					state.userDeviceInfo = "";
				},
			);
		builder
			.addCase(fetchManager.pending, (state, { meta }) => {
				state.manager[meta.arg.id] = {
					isFetching: true,
					isLoading: true,
				};
			})
			.addCase(fetchManager.fulfilled, (state, { payload: { id: manager }, meta }) => {
				usersAdapter.updateOne(state, {
					id: meta.arg.id ?? "",
					changes: { manager },
				});
				state.manager[meta.arg.id] = {
					isFetching: false,
					isLoading: false,
				};
			});
		builder.addCase(
			setManager.fulfilled,
			(
				state,
				{
					payload: { managerId },
					meta: {
						arg: { id },
					},
				},
			) => {
				usersAdapter.updateOne(state, {
					id: id ?? "",
					changes: { manager: managerId },
				});
			},
		);
		builder.addCase(assignLicenses.fulfilled, (state, { payload, meta }) => {
			const { directAssignments, groupAssignments } = payload.assignments;
			const licenseGroups = meta.arg.licenseGroups as LicenseGroup[];
			const licensesByGroupId = licenseGroups.reduce((acc, { groupID, licenses }) => {
				acc[groupID] = licenses.map(({ skuId }) => skuId);
				return acc;
			}, {} as Record<string, string[]>);
			directAssignments.forEach(({ userId, skuIds }) => {
				const existingUser = state.entities[userId];
				const existingLicenses = existingUser?.licenses ?? [];
				// TODO: Figure out how to get all license info here
				const newLicenses = skuIds.map((skuId) => skuToCompleteDetails(skuId));
				usersAdapter.updateOne(state, {
					id: userId,
					changes: { licenses: [...existingLicenses, ...newLicenses] },
				});
			});
			groupAssignments.forEach(({ userId, groupIds }) => {
				const existingUser = state.entities[userId];
				const existingLicenses = existingUser?.licenses ?? [];
				const newLicenses = groupIds
					.map((groupId) => {
						const licenses = licensesByGroupId[groupId] ?? [];
						return licenses.map((skuId) => skuToCompleteDetails(skuId, groupId));
					})
					.flat();
				const existingGroups = existingUser?.groups ?? [];
				const newGroups = groupIds.reduce((acc, groupId) => {
					const group = licenseGroups.find(({ groupID }) => groupID === groupId);
					if (group)
						acc.push({
							id: group.groupID,
							name: group.groupName,
							isLicenseGroup: true,
							groupType: "License",
							description: "Recently added license group",
						}); // TODO: add description, groupType should be enum
					return acc;
				}, [] as Group[]);
				usersAdapter.updateOne(state, {
					id: userId,
					changes: {
						licenses: [...existingLicenses, ...newLicenses],
						groups: [...existingGroups, ...newGroups],
					},
				});
			});
		});
		builder.addCase(removeLicenses.fulfilled, (state, { payload }) => {
			const { directRemovals, groupRemovals } = payload as LicenseRemovalResponse;
			directRemovals.forEach(({ userId, skuIds, isError }) => {
				if (isError) return;
				const existingUser = state.entities[userId];
				const existingLicenses = existingUser?.licenses ?? [];
				const newLicenses = existingLicenses.filter(({ skuId, assignedByGroup }) => {
					if (assignedByGroup) return true; // Keep group assigned licenses
					return !skuIds.includes(skuId); // Remove licenses that are included in the removal list
				});
				usersAdapter.updateOne(state, {
					id: userId,
					changes: { licenses: newLicenses },
				});
			});

			groupRemovals.forEach(({ userId, groupIds, isError }) => {
				if (isError) return;
				const existingUser = state.entities[userId];
				const existingLicenses = existingUser?.licenses ?? [];
				const newLicenses = existingLicenses.filter(
					({ assignedByGroup }) => !groupIds.includes(assignedByGroup),
				);
				const existingGroups = existingUser?.groups ?? [];
				const newGroups = existingGroups.filter(({ id }) => !groupIds.includes(id));
				usersAdapter.updateOne(state, {
					id: userId,
					changes: {
						licenses: newLicenses,
						groups: newGroups,
					},
				});
			});
		});
	},
});

const skuToCompleteDetails = (skuId: string, groupId?: string) =>
	({
		skuId,
		state: LicenseAssignmentState.Active,
		assignedByGroup: groupId ?? "",
		disabledPlans: [] as string[],
		error: "",
	} as LicenseAssignedToUser);

export const {
	setSearchedUsers,
	clearSearchedUsers,
	setUserTypeFilter,
	setUserActivityFilter,
	setUserAuthenticationMethodFilter,
} = usersSlice.actions;

export const selectIsUserDevicesLoading = (state: RootState) => state.users.userDeviceInfo !== "";
export const selectUsers = (state: RootState) => state.users;
export const selectUserFilters = (state: RootState) => ({
	userTypeFilter: state.users.userTypeFilter,
	userActivityFilter: state.users.userActivityFilter,
	userAuthenticationMethodFilter: state.users.userAuthenticationMethodFilter,
});

export const usersSelectors = usersAdapter.getSelectors(selectUsers);
export const selectAllMembers = createSelector(usersSelectors.selectAll, (users) =>
	users.filter((user) => user.userType === UserType.Member),
);

export const selectManager = (state: RootState, id: string) =>
	state.users.manager[id] ?? { user: {} };

const selectSearchedUserIds = (state: RootState) => state.users.searchIds;

export const selectSearchedUsers = createSelector(
	usersSelectors.selectEntities,
	selectSearchedUserIds,
	(usersById, ids) => {
		if (ids.length === 0) return Object.values(usersById);
		const searchedUsers = Object.values(_.pick(usersById, ids));
		if (searchedUsers.length > 0) return searchedUsers;
		return [
			{
				displayName: SEARCH_NO_MATCH,
				mail: SEARCH_NO_MATCH,
				licenses: [],
				lastSignInDateTime: SEARCH_NO_MATCH,
			},
		];
	},
);

export default usersSlice.reducer;

// Helper method to determine the user's activity status
const getUserActivityStatus = (user: User) => {
	if (!user.accountEnabled) {
		return UserActivityType.AccountDisabled;
	}

	const daysSinceLastSignIn = user.lastSignInDateTime
		? dayjs().diff(dayjs(user.lastSignInDateTime), "days")
		: null;

	// Either a new user or an inactive user
	if (user.lastSignInDateTime === null) {
		const daysSinceCreated = dayjs().diff(dayjs(user.createdDateTime), "days");
		if (daysSinceCreated > 14) return UserActivityType.NeverSignedIn; // User has never signed in and was created more than 14 days ago
		return UserActivityType.NewUser; // User has never signed in and was created less than 14 days ago
	}

	if (daysSinceLastSignIn !== null && daysSinceLastSignIn <= 90) {
		return UserActivityType.Active;
	} else {
		return UserActivityType.Inactive;
	}
};
