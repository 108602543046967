import { SliderThumb } from "@mui/material/Slider";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
interface ThumbComponentProps extends React.HTMLAttributes<unknown> {}

const CustomSliderThumb = ({ children, ...other }: ThumbComponentProps) => {
	return (
		<SliderThumb {...other}>
			{children}
			<PlayArrowIcon />
		</SliderThumb>
	);
};

export { CustomSliderThumb };
