import { Checkbox, Typography, Grid } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import styles from "./ToggleableTitle.module.scss";

const ToggleableTitle = ({ title, description, propertyName, enabled = false, handleChange }) => {
	return (
		<>
			<Checkbox
				checked={enabled}
				icon={<CheckBoxOutlineBlankIcon className={styles.checkboxChecked} />}
				checkedIcon={<CheckBoxIcon className={styles.checkbox} />}
				onClick={() => handleChange("workspaceSettings", propertyName, !enabled)}
			/>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="body1">{title}</Typography>
				</Grid>
				<Grid item xs={12} className={styles.captionWrapper}>
					<Typography variant="caption">{description}</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export { ToggleableTitle };
