import { MenuItem, Select, Typography } from "@mui/material";
import styles from "./SelectableQuantity.module.scss";
import { RefundableQuantity } from "types";
import { useMemo } from "react";

interface SelectableQuantityProps {
	id: string;
	removalQuantity: number;
	handleQuantityChange: (id: string, quantity: number) => void;
	maxSelectable: number;
	refundableQuantity: RefundableQuantity[];
}

export const SelectableQuantity = ({
	id,
	removalQuantity,
	handleQuantityChange,
	maxSelectable,
	refundableQuantity,
}: SelectableQuantityProps) => {
	const arrayLength = maxSelectable + 1 > 0 ? maxSelectable + 1 : 0;
	const selectableQuantitiesArray = Array.from(Array(arrayLength).keys());

	const refundableLableShownOnNum = useMemo(() => {
		const numRefundable = refundableQuantity.reduce((acc, curr) => acc + curr.quantity, 0);

		return Math.min(numRefundable, maxSelectable);
	}, [refundableQuantity, maxSelectable]);

	return (
		<Select
			id="select-quantity"
			value={removalQuantity}
			defaultValue={removalQuantity ?? 0}
			onChange={(e) => handleQuantityChange(id, Number(e.target.value))}
			className={styles.select}
			disabled={selectableQuantitiesArray.length === 0}
			renderValue={(value) => <Typography variant="body1">{Number(value)}</Typography>}
		>
			{selectableQuantitiesArray.map((_, i) => (
				<MenuItem value={i} key={i} className={styles.menuItem}>
					<Typography variant="body1">{Number(i)}</Typography>
					{refundableLableShownOnNum === i && Number(i) !== 0 && (
						<Typography
							fontWeight={500}
							variant="caption"
							className={styles.refundableCount}
						>
							Refundable
						</Typography>
					)}
				</MenuItem>
			))}
		</Select>
	);
};
