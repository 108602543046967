import { selectUserDrawerState } from "features/user";
import { useAppSelector } from "hooks/hooks";
import { UserPersonalDetails } from "./UserPersonalDetails";
import { UserLicenseDetails } from "./UserLicenseDetails";
import { UserDeviceDetails } from "./UserDeviceDetails";
import { DeleteUser } from "./DeleteUser";
import { Typography } from "@mui/material";

export const UserDrawerContent = () => {
	const { activeTab } = useAppSelector(selectUserDrawerState);

	const title = () => {
		if (activeTab === 0) {
			return "User Details";
		} else if (activeTab === 1) {
			return "Assigned Licenses";
		} else if (activeTab === 2) {
			return "Devices";
		} else if (activeTab === 3) {
			return "Delete User";
		}
	};

	return (
		<>
			<Typography variant="h3" fontWeight={500} mt={2}>
				{title()}
			</Typography>
			{activeTab === 0 && <UserPersonalDetails />}
			{activeTab === 1 && <UserLicenseDetails />}
			{activeTab === 2 && <UserDeviceDetails />}
			{activeTab === 3 && <DeleteUser />}
		</>
	);
};
