import { Grid, Typography } from "@mui/material";
import { PriceInfo } from "types";

import styles from "./ProductPrice.module.scss";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { useAppSelector } from "hooks/hooks";
import { selectHardwarePurchaseType } from "features/hardware/hardwarePage";
import { calculateHardwarePrice } from "utilities/hardwareUtils/hardwareCostUtils";

interface ProductPriceProps {
	priceInfo?: PriceInfo | undefined;
}

export const HardwareProductPrice = ({ priceInfo }: ProductPriceProps) => {
	const hardwarePurchaseType = useAppSelector(selectHardwarePurchaseType);
	const { price, priceExcludingCustomerDiscount, discountPercentage, subText } =
		calculateHardwarePrice(priceInfo, hardwarePurchaseType);

	return (
		<Grid container direction="row">
			{discountPercentage > 1 ? (
				<Grid container item justifyContent={"space-between"}>
					<Grid item className={styles.discountCircle}>
						<Typography className={styles.discountText}>
							- {discountPercentage}%
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h2" className={styles.discountedPrice}>
							{formatCostString("", priceExcludingCustomerDiscount, " ,-")}
						</Typography>
						<Typography variant="h2">{formatCostString("", price, " ,-")}</Typography>
						<Typography variant="description" className={styles.exMvaMargins}>
							{subText}
						</Typography>
					</Grid>
				</Grid>
			) : (
				<Grid item xs={12} display={"flex"} direction="row" justifyContent={"flex-end"}>
					<Grid item>
						<Typography variant="h2">{formatCostString("", price, " ,-")}</Typography>
						<Typography variant="description" className={styles.exMvaMargins}>
							{subText}
						</Typography>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
