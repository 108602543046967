import { createAsyncThunk } from "@reduxjs/toolkit";
import { Requester } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet, apiPost } from "utilities/api";

export const fetchRequesters = createAsyncThunk(
	"requesters/fetch",
	apiGet<Requester[]>(
		(authConfig) => authConfig.FRESHSERVICE_API_ENDPOINTS.GET_REQUESTERS(),
		[SCOPES.FRESHSERVICE_READ],
	),
);

export const createRequester = createAsyncThunk(
	"requesters/create",
	apiPost(
		(authConfig) => authConfig.FRESHSERVICE_API_ENDPOINTS.POST_REQUESTER(),
		[SCOPES.FRESHSERVICE_WRITE],
	),
);
