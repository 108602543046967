import { useEffect, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase, IconButton } from "@mui/material";

import { debounce } from "utilities/debounce";

import styles from "./MyPeopleSearch.module.scss";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { clearSearchedUsers, setSearchedUsers, usersSelectors } from "features/users";

const MyPeopleSearch = ({ searchValue, setSearchValue }) => {
	const users = useAppSelector(usersSelectors.selectAll);

	const dispatch = useAppDispatch();

	const normalize = (str) =>
		str
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
			.toLowerCase();

	const initializeSearch = (searchInput) => {
		if (searchInput.length === 0) {
			dispatch(clearSearchedUsers());
			return;
		}

		const normalizedSearchInput = normalize(searchInput);

		const matchesSearchInput = (property) => {
			const normalizedProperty = normalize(property ?? "");

			return normalizedProperty.includes(normalizedSearchInput);
		};

		const filteredUsers = users.filter(({ displayName, mail, userPrincipalName }) =>
			[displayName, mail, userPrincipalName].some(matchesSearchInput),
		);

		if (filteredUsers.length === 0) {
			dispatch(
				setSearchedUsers([
					{
						id: "no-match",
					},
				]),
			);
			return;
		}

		dispatch(setSearchedUsers(filteredUsers.map(({ id }) => id)));
	};

	const debouncedSearch = useMemo(
		() => debounce(initializeSearch, 500),
		// Prevents from running useEffect on every component's state change.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[users],
	);

	const onHandleChange = ({ currentTarget: { value } }) => {
		setSearchValue(value);
		debouncedSearch(value);
	};

	useEffect(() => {
		// Make sure to clear the search results when the component is unmounted
		return () => {
			dispatch(clearSearchedUsers());
		};
	}, [dispatch]);

	return (
		<div className={styles.inputRoot}>
			<IconButton disabled className={styles.iconButton} aria-label="search">
				<SearchIcon />
			</IconButton>
			<InputBase
				value={searchValue}
				className={styles.input}
				placeholder="Search for employee"
				inputProps={{ "aria-label": "search for employee" }}
				onChange={onHandleChange}
			/>
		</div>
	);
};

export { MyPeopleSearch };
