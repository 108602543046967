import clsx from "clsx";

import styles from "./RiskLevelChip.module.scss";

interface RiskLevelProps {
	riskLevel: string;
}
const RiskLevelChip = ({ riskLevel }: RiskLevelProps): JSX.Element => (
	<span className={clsx([styles.riskLevel, styles[riskLevel]])}>{riskLevel}</span>
);

export { RiskLevelChip };
