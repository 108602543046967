import { HotjarEventTypes } from "utilities/constants/enums";

interface HotjarInputProps {
	eventType: HotjarEventTypes;
	eventText: string;
}

export const logHotjarEvent = ({ eventType, eventText }: HotjarInputProps) => {
	const log = (window as any).hj;
	if (log) {
		log("event", `${eventType}:${eventText}`);
	}
};
