import { MouseEventHandler } from "react";
import { Link, Typography, TypographyProps } from "@mui/material";
import styles from "./Link.module.scss";
import { OpenInNew } from "@mui/icons-material";

enum LinkType {
	INTERNAL = "internal",
	EXTERNAL = "external",
}

interface LinkProps extends LinkContentProps {
	onClick?: MouseEventHandler;
	href?: string;
	marginLeft?: number;
	marginRight?: number;
	marginTop?: number;
	marginBottom?: number;
	title?: string;
	target?: string;
}

interface LinkContentProps {
	children: string | JSX.Element | JSX.Element[];
	variant?: TypographyProps["variant"];
	prefixIcon?: JSX.Element;
	textDecoration?:
		| "none"
		| "underline"
		| "overline"
		| "line-through"
		| "blink"
		| "initial"
		| "inherit";
	prefixedExternalLinkIcon?: boolean;
}

const BoilerplateLinkContent =
	(name: string) =>
	({
		children,
		prefixIcon,
		textDecoration,
		prefixedExternalLinkIcon,
		...props
	}: LinkContentProps): JSX.Element => {
		const usedLinkContentStyle =
			styles[
				`${name}LinkContent${prefixedExternalLinkIcon ? "PrefixedExternalLinkIcon" : ""}`
			];
		return (
			<div className={usedLinkContentStyle}>
				{prefixIcon}
				{typeof children === "string" ? (
					<Typography
						className={styles["linkText"]}
						sx={{ textDecoration: textDecoration ?? "none" }}
						{...props}
					>
						{children}
					</Typography>
				) : (
					<div className={styles["linkContentContainer"]}>{children}</div>
				)}

				<OpenInNew className={styles["externalLinkIcon"]} />
			</div>
		);
	};

const BoilerplateLink =
	(name: string) =>
	({
		children,
		onClick,
		href,
		variant,
		marginLeft,
		marginRight,
		marginTop,
		marginBottom,
		title,
		target,
		...props
	}: LinkProps): JSX.Element => {
		const LinkContent = BoilerplateLinkContent(name);
		return (
			<Link
				href={href}
				underline="none"
				rel="noopener noreferrer"
				component={href ? "a" : "button"}
				onClick={onClick}
				variant={variant}
				target={name === LinkType.EXTERNAL ? "_blank" : target}
				title={title}
				sx={{ ml: marginLeft, mr: marginRight, mt: marginTop, mb: marginBottom }}
				width={"fit-content"}
			>
				<LinkContent {...props}>{children}</LinkContent>
			</Link>
		);
	};

const InternalLinkContent = BoilerplateLinkContent(LinkType.INTERNAL);

const ExternalLinkContent = BoilerplateLinkContent(LinkType.EXTERNAL);

const InternalLink = BoilerplateLink(LinkType.INTERNAL);

const ExternalLink = BoilerplateLink(LinkType.EXTERNAL);

export { InternalLink, ExternalLink, InternalLinkContent, ExternalLinkContent };
