import dayjs from "dayjs";

const SUPPORT_CREATE_TICKET = [
	{
		id: "CFQ7TTC0LH00",
		productId: "CFQ7TTC0LH18",
		skuId: "0001",
		catalogItemId: "CFQ7TTC0LH18:0001:CFQ7TTC0LH00",
		termDuration: ["P1Y", "P1M"],
		billingCycle: ["monthly", "annual"], //when sending to cart pick monthly by default
		product: {
			id: "CFQ7TTC0LH00",
			title: "Advanced eDiscovery Storage",
			description:
				"The Advanced eDiscovery solution in Microsoft 365 builds on the existing Microsoft eDiscovery and analytics capabilities. Advanced eDiscovery provides an end-to-end workflow to preserve, collect, analyze, review, and export content that's responsive to your organization's internal and external investigations. It also lets legal teams manage the entire legal hold notification workflow to communicate with custodians involved in a case.",
		},
	},
	{
		id: "CFQ7TTC0LH01",
		productId: "CFQ7TTC0LH12",
		skuId: "0002",
		catalogItemId: "CFQ7TTC0LH12:0002:CFQ7TTC0LH01",
		termDuration: ["P1Y", "P1M"],
		billingCycle: ["monthly", "annual"], //when sending to cart pick monthly by default
		product: {
			id: "CFQ7TTC0LH01",
			title: "Advanced eDiscovery Storage #2",
			description:
				"The Advanced eDiscovery solution in Microsoft 365 builds on the existing Microsoft eDiscovery and analytics capabilities. Advanced eDiscovery provides an end-to-end workflow to preserve, collect, analyze, review, and export content that's responsive to your organization's internal and external investigations. It also lets legal teams manage the entire legal hold notification workflow to communicate with custodians involved in a case.",
		},
	},
];
const PURCHASEABLE_PRODUCTS = [
	{
		id: "CFQ7TTC0HL8Z",
		title: "10-year audit log retention",
		description:
			"To help meet more rigorous regulatory and internal compliance obligations or support longer running investigations, organizations can now add 10-year audit log retention to Advanced Audit. Once enabled, you can access the logs in the Microsoft 365 Compliance Center or through the Office 365 Management Activity API.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HL8Z/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HL8Z?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HDK0",
		title: "Advanced Communications",
		description:
			"Advanced meetings, calling, workflow integration, and management tools for IT.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HDK0/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HDK0?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHQD",
		title: "Advanced eDiscovery Storage",
		description:
			"500GB Storage add on for Office 365 Advanced eDiscovery to analyze non-Office 365 content",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHQD/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHQD?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0Z",
		title: "AI Builder Capacity add-on",
		description:
			"AI Builder empowers creation and consumption of insights to improve business processes. Each license offers 1M service credits per month.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0Z/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0Z?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFLS",
		title: "Azure Active Directory Premium P1",
		description:
			"Azure Active Directory Premium provides single sign-on to thousands of cloud (SaaS) apps and access to web apps you run on-premises. Built for ease of use, Azure Active Directory Premium features multi-factor authentication (MFA); access control based on device health, user location, and identity; and holistic security reports, audits, and alerts.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFLS/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFLS?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFK5",
		title: "Azure Active Directory Premium P2",
		description:
			"Azure Active Directory Premium P2: A comprehensive cloud Identity and access management solution with advanced identity protection for all your users and administrators",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFK5/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFK5?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH9J",
		title: "Azure Information Protection Premium P1",
		description:
			"Helps classify, label and protect confidential documents and emails persistently. Access to information can also be controlled by specifying permissions on shared data. It’s simple to use and deeply integrated with Office 365.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH9J/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH9J?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHZ0",
		title: "Business Apps (free)",
		description:
			"Includes Microsoft Bookings – business apps from Office 365 that’ll help you run and grow your business. Now available in a free add-on SKU for Office 365 Enterprise E3 and E5 users.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHZ0/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHZ0?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHT2",
		title: "Chat session for Virtual Agent",
		description: "Add-on license that provisions 1000 sessions per tenant per month",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHT2/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHT2?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0V",
		title: "Common Area Phone",
		description:
			"For organizations that want to use standalone common area phone devices with Microsoft’s cloud-based calling services.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0V/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0V?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHRL",
		title: "Common Data Service Database Capacity",
		description:
			"Additional log data (audit / tracing) capacity for Dynamics 365 Customer Engagement Apps, PowerApps and any application that utilizes Microsoft’s Common Data Service for Apps. Each unit increases available tenant wide shared capacity by 1 GB",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHRL/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHRL?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHQ3",
		title: "Common Data Service File Capacity",
		description:
			"Additional file capacity for Dynamics 365 Customer Engagement Apps, PowerApps and any application that utilizes Microsoft’s Common Data Service for Apps. Each unit increases available tenant wide shared capacity by 1 GB",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHQ3/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHQ3?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HBSL",
		title: "Common Data Service Log Capacity",
		description:
			"Additional log data (audit / tracing) capacity for Dynamics 365 Customer Engagement Apps, PowerApps and any application that utilizes Microsoft’s Common Data Service for Apps. Each unit increases available tenant wide shared capacity by 1 GB.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HBSL/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HBSL?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHXR",
		title: "Dynamics 365  Operations – Order Lines",
		description: "Subscription that includes 100K order lines",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHXR/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHXR?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHWJ",
		title: "Dynamics 365 Asset Management Addl Assets",
		description:
			"Adds additional Asset Management assets to tenant. See Dynamics 365 Licensing Guide for additional details.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHWJ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHWJ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD7P",
		title: "Dynamics 365 Business Central Additional Environment Addon",
		description: "Provides access to additional environments for Business Central",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD7P/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD7P?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD3R",
		title: "Dynamics 365 Business Central Database Capacity",
		description:
			"Additional database capacity for Microsoft Dynamics 365 Business Central. Each unit increases available tenant wide shared capacity by 1 GB.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD3R/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD3R?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH3F",
		title: "Dynamics 365 Business Central Device",
		description:
			"Designed for businesses that have outgrown their basic accounting software, Microsoft Dynamics 365 Business Central is a business management solution that helps companies connect their financials, sales, service, and operations to streamline business processes, improve customer interactions and make better decisions.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH3F/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH3F?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH34",
		title: "Dynamics 365 Business Central Essentials",
		description:
			"Designed for businesses that have outgrown their basic accounting software, Microsoft Dynamics 365 Business Central is a business management solution that helps companies connect their financials, sales, service, and operations to streamline business processes, improve customer interactions and make better decisions.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH34/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH34?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH33",
		title: "Dynamics 365 Business Central External Accountant",
		description:
			"Designed for businesses that have outgrown their basic accounting software, Microsoft Dynamics 365 Business Central is a business management solution that helps companies connect their financials, sales, service, and operations to streamline business processes, improve customer interactions and make better decisions.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH33/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH33?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH38",
		title: "Dynamics 365 Business Central Premium",
		description:
			"Designed for businesses that have outgrown their basic accounting software, Microsoft Dynamics 365 Business Central is a business management solution that helps companies connect their financials, sales, service, and operations to streamline business processes, improve customer interactions and make better decisions.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH38/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH38?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH39",
		title: "Dynamics 365 Business Central Team Members",
		description:
			"Designed for businesses that have outgrown their basic accounting software, Microsoft Dynamics 365 Business Central is a business management solution that helps companies connect their financials, sales, service, and operations to streamline business processes, improve customer interactions and make better decisions.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH39/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH39?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HM0T",
		title: "Dynamics 365 Commerce",
		description: "e-Commerce add on for Dynamics 365 Commerce",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HM0T/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HM0T?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH2Z",
		title: "Dynamics 365 Commerce",
		description: "User subscription that includes Dynamics 365 Commerce, Enterprise Edition",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH2Z/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH2Z?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LZTK",
		title: "Dynamics 365 Customer Engagement Applications",
		description: "User subscription that includes a trial of Customer Engagement applications.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LZTK/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LZTK?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH3J",
		title: "Dynamics 365 Customer Insights",
		description:
			"Customer Insights is a marketer-based management system which creates a unified customer database that is accessible to other systems.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH3J/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH3J?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J7C5",
		title: "Dynamics 365 Customer Service Call Intelligence Minutes Add-on",
		description:
			"This license is required if an individual with Voice SKU permissions exceeds the seeded call intelligence minutes per month threshold. Purchase of this license grants 500 additional minutes per tenant.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J7C5/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J7C5?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHPZ",
		title: "Dynamics 365 Customer Service Chat",
		description:
			"User subscription that includes Dynamics 365 Customer Service Chat. Available in limited regions. Please check availability in your region before purchasing.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHPZ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHPZ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHXC",
		title: "Dynamics 365 Customer Service Digital Messaging add-on",
		description:
			"User license for employees who need access to any combination of the following digital conversation channels within the Omnichannel for Customer Service interface: Chat, SMS (requires additional contract with Telesign), FB Messenger, and Whatsapp.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHXC/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHXC?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J7BH",
		title: "Dynamics 365 Customer Service Digital Messaging and Voice Add-in",
		description:
			"Enable digital messaging and voice channels and associated capabilities within Customer Service Enterprise",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J7BH/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J7BH?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFDZ",
		title: "Dynamics 365 Customer Service Enterprise",
		description: "User subscription that includes Dynamics 365 Customer Service Enterprise.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFDZ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFDZ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J7M0",
		title: "Dynamics 365 Customer Service Intelligent Voicebot Minutes Add-on",
		description:
			"This license is required if an individual with Voice SKU permissions exceeds the seeded voicebot minutes per month threshold. Purchase of this license grants 500 additional minutes per tenant.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J7M0/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J7M0?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFNK",
		title: "Dynamics 365 Customer Service Professional",
		description: "User subscription that includes Dynamics 365 Customer Service Professional",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFNK/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFNK?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J7N8",
		title: "Dynamics 365 Customer Service Voice Channel Add-in",
		description:
			"Enable the Customer Service voice channel and associated capabilities within Customer Service Enterprise",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J7N8/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J7N8?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HKJ7",
		title: "Dynamics 365 Customer Voice",
		description:
			"Each Dynamics 365 Customer Voice purchase provides 2K survey responses per month.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HKJ7/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HKJ7?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HKJ6",
		title: "Dynamics 365 Customer Voice Additional Response",
		description:
			"Each Dynamics 365 Customer Voice Additional Response purchase provides 1K survey responses per month.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HKJ6/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HKJ6?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HBSM",
		title: "Dynamics 365 Customer Voice USL",
		description: "User license for Dynamics 365 Customer Voice Add-On",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HBSM/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HBSM?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HM43",
		title: "Dynamics 365 E-Invoicing Documents",
		description: "Electronic invoicing block of 1,000 transactions",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HM43/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HM43?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFNL",
		title: "Dynamics 365 Field Service",
		description: "User subscription that includes Dynamics 365 Field Service",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFNL/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFNL?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFDN",
		title: "Dynamics 365 Field Service - Resource Scheduling Optimization",
		description:
			"Dynamics 365 Field Service, Enterprise Edition - Resource Scheduling Optimization",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFDN/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFDN?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LGV4",
		title: "Dynamics 365 Finance",
		description: "Base user subscription offering for Dynamics 365 Finance",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LGV4/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LGV4?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD41",
		title: "Dynamics 365 Fraud Protection - Account Protection",
		description:
			"Dynamics 365 Fraud Protection Account Protection helps merchants to protect their online customer accounts by detecting and preventing fraudulent activities",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD41/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD41?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD40",
		title: "Dynamics 365 Fraud Protection - Loss Prevention",
		description:
			"Dynamics 365 Fraud Protection Loss Prevention helps retailers to reduce shrinkage in their stores",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD40/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD40?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD4H",
		title: "Dynamics 365 Fraud Protection - Purchase Protection",
		description:
			"Dynamics 365 Fraud Protection Purchase Protection helps merchants to protect their online transactions by detecting and preventing fraudulent activities",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD4H/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD4H?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LGV7",
		title: "Dynamics 365 Guides",
		description:
			"User subscription for individuals who want to access Dynamics 365 Guides on any device.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LGV7/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LGV7?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD4G",
		title: "Dynamics 365 Human Resources",
		description: "User subscription that includes Dynamics 365 Human Resources.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD4G/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD4G?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J1XF",
		title: "Dynamics 365 Intelligent Order Management",
		description: "Intelligent Order Management capacity block of order lines",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J1XF/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J1XF?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH3N",
		title: "Dynamics 365 Marketing",
		description: "Subscription that includes Dynamics 365 Marketing",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH3N/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH3N?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HX6F",
		title: "Dynamics 365 Marketing  Interactions Add on pack",
		description:
			"Subscription that includes additional interactions for Dynamics 365 Marketing",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HX6F/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HX6F?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHVK",
		title: "Dynamics 365 Marketing Additional Application",
		description: "Subscription that includes Dynamics 365 Marketing Additional App",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHVK/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHVK?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHWM",
		title: "Dynamics 365 Marketing Additional Non-Prod Application",
		description:
			"Subscription that includes Dynamics 365 Marketing Additional Non-Prod Application",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHWM/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHWM?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHWQ",
		title: "Dynamics 365 Marketing Addnl Contacts",
		description:
			"Subscription that includes Additional Contacts for Dynamics 365 Marketing. Each unit covers 50K Contacts.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHWQ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHWQ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHWP",
		title: "Dynamics 365 Marketing Attach",
		description: "Subscription that includes Dynamics 365 Marketing",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHWP/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHWP?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHX0",
		title: "Dynamics 365 Operations – Activity",
		description:
			"User subscription that includes Dynamics 365 Operations Activity, Enterprise Edition.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHX0/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHX0?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHXQ",
		title: "Dynamics 365 Operations - Database Capacity",
		description:
			"1 GB of additional structured database storage to expand the storage capacity of the Operations tenant.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHXQ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHXQ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHVJ",
		title: "Dynamics 365 Operations – Device",
		description:
			"Device subscription plan that includes Dynamics 365 Operations, Enterprise edition - Device functionality only",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHVJ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHVJ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHZ1",
		title: "Dynamics 365 Operations - File Capacity",
		description:
			"10 GB of additional unstructured file storage to expand the capacity of the Operations tenant.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHZ1/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHZ1?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHV9",
		title: "Dynamics 365 Operations - Sandbox Tier 2:Standard Acceptance Testing",
		description:
			"Dynamics 365 Operations non-production multi-box instance for standard acceptance testing (Tier 2)",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHV9/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHV9?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHVN",
		title: "Dynamics 365 Operations - Sandbox Tier 3:Premier Acceptance Testing",
		description:
			"Dynamics 365 Operations non-production multi-box instance for premier acceptance testing. (Tier 3)",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHVN/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHVN?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHXZ",
		title: "Dynamics 365 Operations - Sandbox Tier 4:Standard Performance Testing",
		description:
			"Dynamics 365 Operations non-production multi-box instance for standard performance testing. (Tier 4)",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHXZ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHXZ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHVG",
		title: "Dynamics 365 Operations - Sandbox Tier 5:Premier Performance Testing",
		description:
			"Dynamics 365 Operations non-production multi-box instance for premier performance testing. (Tier 5)",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHVG/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHVG?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH3D",
		title: "Dynamics 365 Plan - Unified Operations Sandbox Tier 4:Standard Performance Testing",
		description:
			"Dynamics 365 for Operations non-production multi-box instance for standard performance testing. (Tier 4)",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH3D/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH3D?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD4D",
		title: "Dynamics 365 Project Operations",
		description: "Base user subscription offering for Dynamics 365 Project Operations",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD4D/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD4D?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LF90",
		title: "Dynamics 365 Remote Assist",
		description:
			"This subscription enables businesses to use Dynamics 365 Remote Assist to solve problems faster the first time. With heads-up, hands-free video calling on Microsoft HoloLens, field service technicians can collaborate with any remote expert on PC or mobile to troubleshoot issues in context. Additional hardware and software requirements vary for available features and content. To enable user access to this subscription, IT administrators must assign individual seats to users after purchase before users can install the app from the Microsoft Store or Store for Business.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LF90/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LF90?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LGV9",
		title: "Dynamics 365 Remote Assist Attach",
		description: "Subscription that includes Dynamics 365 Remote Assist Attach",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LGV9/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LGV9?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHZ4",
		title: "Dynamics 365 Sales Conversation Intelligence AddOn",
		description:
			"Subscription includes Dynamics 365 Sales Conversation Intelligence capabilities that enable smarter coaching to boost sales conversion rates.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHZ4/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHZ4?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFF1",
		title: "Dynamics 365 Sales Enterprise",
		description: "User subscription that includes Dynamics 365 Sales Enterprise",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFF1/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFF1?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHZ3",
		title: "Dynamics 365 Sales Insights",
		description: "User subscription that includes Dynamics 365 AI for Sales",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHZ3/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHZ3?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HBSJ",
		title: "Dynamics 365 Sales Premium",
		description:
			"User subscription that includes Dynamics 365 Sales Enterprise and Dynamics 365 Sales Insights",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HBSJ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HBSJ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFN5",
		title: "Dynamics 365 Sales Professional",
		description: "User subscription that includes Dynamics 365 Sales Professional",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFN5/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFN5?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH31",
		title: "Dynamics 365 Supply Chain Management",
		description: "Base user subscription offering for Dynamics 365 Supply Chain Management",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH31/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH31?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFNJ",
		title: "Dynamics 365 Team Members",
		description:
			"User subscription that includes Dynamics 365 for Sales, Customer Service, Field Service, Project Service Automation, and Team Members",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFNJ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFNJ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD42",
		title: "eCommerce",
		description: "e-Commerce base tier, includes 3K e-Commerce transactions per month.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD42/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD42?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHT4",
		title: "Enterprise Mobility + Security E3",
		description:
			"A convenient cost effective way for customers to purchase Microsoft’s end user and device cloud services:  Azure AD Premium P1, Azure Information Protection Premium P1, Microsoft Advanced Threat Analytics and Microsoft Intune.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHT4/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHT4?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFJ1",
		title: "Enterprise Mobility + Security E5",
		description:
			"Microsoft Enterprise Mobility + Security E5 is the most comprehensive cloud delivered solution for securing your company data in a mobile-first, cloud-first world.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFJ1/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFJ1?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH16",
		title: "Exchange Online (Plan 1)",
		description:
			"Messaging, calendaring, and email archiving plan accessible from Outlook on PCs, the Web and mobile devices.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH16/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH16?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH1P",
		title: "Exchange Online (Plan 2)",
		description:
			"Best messaging and calendaring plan accessible from PCs, the Web and mobile devices with advanced archiving, compliance and integrated voicemail capabilities.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH1P/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH1P?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0J",
		title: "Exchange Online Archiving for Exchange Online",
		description:
			"A cloud-based, enterprise-class archiving solution for Office 365 Enterprise K1 and Exchange Online (Plan 1). This solution can assist your organization with archiving, compliance, regulatory, and e-discovery challenges.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0J/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0J?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHQ5",
		title: "Exchange Online Archiving for Exchange Server",
		description:
			"A personal e-mail archive for users who have mailboxes on Exchange Server 2010 or later.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHQ5/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHQ5?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0L",
		title: "Exchange Online Kiosk",
		description: "Basic messaging and calendaring plan with Web email and POP access.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0L/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0L?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LGZM",
		title: "Exchange Online Protection",
		description: "Advanced anti-malware and anti-spam protection for email deployments.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LGZM/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LGZM?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HDJX",
		title: "Extra Graph Connector Capacity",
		description:
			"This offer provides additional storage capacity for indexing information in disparate systems outside of Microsoft 365 with Microsoft Graph Connectors. It includes capacity for 1 million indexed items.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HDJX/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HDJX?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD4F",
		title: "IoT Intelligence",
		description: "IoT Intelligence",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD4F/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD4F?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH1G",
		title: "Microsoft 365 Apps for business",
		description:
			"Best for businesses that need Office apps across devices and cloud file storage. Professional email and Microsoft Teams not included. For businesses with up to 300 employees.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH1G/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH1G?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LGZT",
		title: "Microsoft 365 Apps for enterprise",
		description:
			"The premium Office suite for organizations - including Word, Excel, PowerPoint, Outlook, OneNote, Access, and Skype for Business - plus online file storage and sharing. Connected to the cloud, enabling you to roam between your devices of choice as part of the Office 365 experience.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LGZT/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LGZT?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHSL",
		title: "Microsoft 365 Audio Conferencing",
		description:
			"Enables users to dial-in a number to join meetings, or dial-out to bring participants into the meeting. There are base pre-requisites required to purchase this offering.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHSL/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHSL?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH18",
		title: "Microsoft 365 Business Basic",
		description:
			"Best for businesses that need professional email, cloud file storage, and online meetings & chat. Desktop versions of Office apps like Excel, Word, and PowerPoint not included. For businesses with up to 300 employees.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH18/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH18?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LCHC",
		title: "Microsoft 365 Business Premium",
		description:
			"Best for businesses that need all the apps and services included in Business Standard plus advanced cyber threat protection and device management. For businesses with up to 300 employees.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LCHC/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LCHC?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LDPB",
		title: "Microsoft 365 Business Standard",
		description:
			"Best for businesses that need Office apps across devices plus professional email, cloud file storage, and online meetings & chat. For businesses with up to 300 employees.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LDPB/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LDPB?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHXT",
		title: "Microsoft 365 Domestic and International Calling Plan",
		description:
			"Enables online users to place and receive Domestic and International calls through the Public Switched Telephone Network (PSTN). There are base pre-requisites required to purchase this offering.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHXT/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHXT?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHXJ",
		title: "Microsoft 365 Domestic Calling Plan",
		description:
			"Enables online users to place and receive Domestic calls through the Public Switched Telephone Network (PSTN). There are base pre-requisites required to purchase this offering.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHXJ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHXJ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFLX",
		title: "Microsoft 365 E3",
		description:
			"Office 365 E3, Enterprise Mobility + Security E3, and Windows 10/11 Enterprise E3. This per-user licensed suite of products offers users best-in-class productivity across devices while providing IT security and control.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFLX/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFLX?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFLZ",
		title: "Microsoft 365 E5",
		description:
			"Office 365 E5, Enterprise Mobility + Security E5, and Windows 10/11 Enterprise E5. This per-user licensed suite of products offers customers the latest, most advanced enterprise security, management, collaboration, and business analytics.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFLZ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFLZ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHR4",
		title: "Microsoft 365 E5 Compliance",
		description:
			"A set of intelligent information protection and compliance solutions to protect and govern sensitive data across devices, apps and cloud services, along with integrated tools to assess risks and efficiently respond to regulatory requirements. A Microsoft 365 E3 subscription is a pre-requisite for this plan.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHR4/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHR4?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD6V",
		title: "Microsoft 365 E5 eDiscovery and Audit",
		description:
			"Manage eDiscovery communications using tools to send and track custodian responses and escalate with reminders and manager notifications. Investigate data leakage, examine associated user activities, and take actions to remediate such as deleting associated data",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD6V/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD6V?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD6T",
		title: "Microsoft 365 E5 Information Protection and Governance",
		description:
			"Enable secured and compliant collaboration with data loss prevention policies that help avoid leaks and restrict external sharing of sensitive data. Configure protection and governance labels and locate your sensitive data and understand how it is being used.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD6T/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD6T?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD6S",
		title: "Microsoft 365 E5 Insider Risk Management",
		description:
			"Correlate multiple signals, from activities to communications, to view and manage alerts on potential insider risks and remediate. Proactively monitor and address data access governance when employees leave or move between compliance boundaries.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD6S/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD6S?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHQB",
		title: "Microsoft 365 E5 Security ",
		description:
			"A complete set of threat protection solutions, leveraging cloud signal to detect and protect against threats to your organization, along with automated incident responses.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHQB/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHQB?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0MBMD",
		title: "Microsoft 365 F1",
		description: "Best-in-class productivity apps and expereinces for Firstline Workers",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0MBMD/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0MBMD?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH05",
		title: "Microsoft 365 F3",
		description:
			"Best-in-class productivity apps and services within Microsoft 365 for Firstline Workers",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH05/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH05?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HC36",
		title: "Microsoft 365 International Calling Plan",
		description:
			"Add-on to Microsoft 365 Enterprise Voice that includes international calling minutes.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HC36/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HC36?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J7WF",
		title: "Microsoft Cloud App Security - App Governance Add-On",
		description:
			"Microsoft Cloud App Security - App Governance provides fully integrated visibility, oversight, security and governance of Microsoft 365 apps to prevent and remediate risky or inappropriate app behavior",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J7WF/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J7WF?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHRR",
		title: "Microsoft Defender for Cloud Apps",
		description:
			"Microsoft Defender for Cloud Apps is a cross-platform cloud solution extending IT visibility, governance and control to the cloud applications.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHRR/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHRR?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J1GB",
		title: "Microsoft Defender for Endpoint",
		description:
			"Microsoft Defender For Endpoint P1 offers cloud powered endpoint protection and prevention technology for enterprises including next gen anti-malware, device and firewall controls, network protection, and host intrusion prevention with simple configuration management and a unified, easy to use console offering rich insights, visibility, and control of the organization's endpoint estate.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J1GB/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J1GB?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LGV0",
		title: "Microsoft Defender for Endpoint P2",
		description:
			"Microsoft Defender For Endpoint P2 is a comprehensive endpoint security solution and enables enterprise customers to protect, detect, investigate, and respond to advanced attacks and data breaches. It includes leading capabilities for end point detection and response, automated response and remediation and vulnerability management capabilities.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LGV0/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LGV0?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0D",
		title: "Microsoft Defender for Identity",
		description:
			"Microsoft Defender for Identity is designed to help you protect your enterprise from advanced targeted attacks by automatically analyzing, learning, and identifying normal and abnormal entity (user, devices, and resources) behavior.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0D/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0D?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH04",
		title: "Microsoft Defender for Office 365 (Plan 1)",
		description:
			"Modern email protection techniques such as Safe Links and Safe Attachment, complementing the defense of Exchange Online Protection to protect your mailboxes against sophisticated attacks.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH04/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH04?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHXH",
		title: "Microsoft Defender for Office 365 (Plan 2)",
		description:
			"Provides rich insights on advanced threats, supports proactive defense against them, and integrates seamlessly with other Office 365 security features.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHXH/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHXH?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LCH4",
		title: "Microsoft Intune",
		description:
			"Microsoft Intune simplifies how businesses manage and secure PCs using Microsoft cloud services and Windows 7. One year subscription.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LCH4/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LCH4?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0C",
		title: "Microsoft Stream Plan 2",
		description:
			"Stream enables anyone to upload and share videos across their organization to improve communication, participation, and learning. Stream Plan 2 adds intelligent cognitive capabilities such as machine generated captions, text searching inside of a video, and rich analytics",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0C/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0C?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHPG",
		title: "Microsoft Stream Storage Add-On (500 GB)",
		description: "500GB of additional storage for your organization's Stream service.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHPG/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHPG?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0JN4R",
		title: "Microsoft Teams Essentials (AAD Identity)",
		description:
			"Microsoft Teams Essentials (AAD identity) is a paid AAD-based Teams standalone SKU for small and medium businesses. It includes secure meetings, chat, and cloud storage to power teamwork from anywhere at any time at an affordable price.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0JN4R/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0JN4R?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0T",
		title: "Microsoft Teams Phone Standard",
		description:
			"For organizations that need call management capabilities (make, receive and transfer calls) in the cloud and use the Office 365 admin center to manage users.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0T/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0T?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0R",
		title: "Microsoft Teams Phone Standard - Virtual User",
		description:
			'These provide Phone System value and voice app capabilities to "virtual users," phone numbers at the organizational level. Voice apps provide the ability to create organization auto attendant and call queue capabilities.',
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0R/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0R?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HL73",
		title: "Microsoft Teams Phone with Calling Plan",
		description:
			"A cloud-based calling solution that enables users to make and receive PSTN calls in Microsoft Teams. It includes a phone system and domestic calling plan.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HL73/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HL73?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0S",
		title: "Microsoft Teams Rooms Standard",
		description: "Enables Microsoft Teams for meeting rooms.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0S/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0S?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J7V7",
		title: "Microsoft Viva",
		description:
			"The Microsoft Viva suite brings together knowledge, learning, and insights into an integrated employee experience to empower people and teams to be their best from anywhere. The Microsoft Viva Suite includes Viva Topics, Viva Learning, and Viva Insights subscriptions.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J7V7/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J7V7?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHWF",
		title: "Microsoft Viva Insights",
		description:
			"Viva Insights provides data-driven, privacy-protected insights and actionable recommendations that help everyone in the organization work smarter and achieve balance.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHWF/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHWF?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HDJW",
		title: "Microsoft Viva Topics",
		description:
			"This per-user offer enables customers to get access to topic cards/pages/knowledge centers and advanced search features including Microsoft Graph Connectors.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HDJW/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HDJW?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHSW",
		title: "Office 365 Data Loss Prevention",
		description:
			"With a data loss prevention (DLP), you can identify, monitor, and automatically protect sensitive information across Office 365. Identify sensitive information across many locations, such as Exchange Online, SharePoint, and OneDrive for business.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHSW/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHSW?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LF8Q",
		title: "Office 365 E1",
		description:
			"The online versions of Office with email, instant messaging, HD video conferencing, plus 1 TB personal file storage and sharing. Does not include the Office suite for PC or Mac.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LF8Q/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LF8Q?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LF8R",
		title: "Office 365 E3",
		description:
			"The Office suite for PC and Mac with apps for tablets and phones, plus email, instant messaging, HD video conferencing, 1 TB personal file storage and sharing, and available add-ons like PSTN calling.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LF8R/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LF8R?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LF8S",
		title: "Office 365 E5",
		description:
			"The Office suite, plus email, instant messaging, HD video conferencing, 1 TB personal file storage and sharing, and advanced security, analytics and Audio conferencing.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LF8S/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LF8S?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHS9",
		title: "Office 365 Extra File Storage",
		description:
			"Priced per gigabyte, Office 365 offers additional file storage options to support an organization’s file growth.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHS9/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHS9?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LGZW",
		title: "Office 365 F3",
		description: "Purpose-built tools and productivity experience for Firstline Workers.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LGZW/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LGZW?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHSV",
		title: "OneDrive for business (Plan 1)",
		description:
			"Convenient online companions to Microsoft Word, Excel, PowerPoint, and OneNote to do light editing of documents directly from your Web browser with OneDrive for business.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHSV/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHSV?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH1M",
		title: "OneDrive for business (Plan 2)",
		description:
			"Provides personal cloud storage, offline access and sharing along with convenient online companions to Microsoft Word, Excel, PowerPoint, and OneNote to do light editing of documents directly from your Web browser. It also includes compliance and information protection for your files including legal hold, rights management and data loss prevention.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH1M/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH1M?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH1S",
		title: "Power Apps and Power Automate capacity add-on",
		description:
			"Each license includes an additional 10,000 daily API calls and is assignable to a single user OR multiple users and/or business processes.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH1S/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH1S?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J4GS",
		title: "Power Apps Per App  (1 app or portal)",
		description:
			"Each subscription provides assigned user with the use rights to a specific custom business application.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J4GS/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J4GS?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHQM",
		title: "Power Apps per app plan",
		description:
			"Each subscription provides assigned user with the use rights to a specific custom business application.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHQM/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHQM?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH2H",
		title: "Power Apps per user plan",
		description:
			"Subscription enables licensed user to create, customize, share, and run business applications.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH2H/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH2H?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHRX",
		title: "Power Apps Portals login capacity add-on",
		description:
			"Power Apps Portals allow organizations to extend business processes to employees and external audiences. Each subscription includes 100 daily log ins by external users per month.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHRX/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHRX?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH23",
		title: "Power Apps Portals page view capacity add-on",
		description:
			"Power Apps Portals allow organizations to extend business processes to employees and external audiences. Each license includes 100,000 external user page views per month.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH23/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH23?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH13",
		title: "Power Automate per flow plan",
		description:
			"Power Automate reserved capacity serves users across your department/organization with workflow automation for a single process. Each subscription includes Power Automate capacity dedicated to one specific flow.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH13/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH13?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH3L",
		title: "Power Automate per user plan",
		description:
			"Subscription enables licensed user to create and execute automated workflows and business processes.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH3L/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH3L?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LSGZ",
		title: "Power Automate per user with attended RPA plan",
		description: "Includes Power Automate per user plan with an attended RPA",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LSGZ/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LSGZ?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LSH0",
		title: "Power Automate unattended RPA add-on",
		description:
			"Unattended RPA add-on to Power Automate per User Plan or Power Automate per Flow Plan",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LSH0/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LSH0?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HL8W",
		title: "Power BI Premium Per User",
		description:
			"Per user license that includes all Power BI Pro features plus other advanced Premium capabilities",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HL8W/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HL8W?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HL8T",
		title: "Power BI Premium Per User Add-On",
		description:
			"Per user add-on license for Power BI Pro that unlocks advanced Premium capabilities",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HL8T/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HL8T?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHQ2",
		title: "Power BI Premium P-series",
		description:
			"Power BI capacity for your organization's needs, unlocking unlimited content distribution and all premium features. P3 can utilize up to 32 virtual cores. You will need to separately purchase Power BI per user license for users who publish content or use collaboration features. Eligible for autoscale add-on via Azure subscription.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHQ2/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHQ2?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHSF",
		title: "Power BI Pro",
		description:
			"A cloud-based business analytics service that enables anyone to visualize and analyze data with greater speed, efficiency, and understanding. Power BI Pro provides extended data source support, data capacity, team collaboration and admin capabilities.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHSF/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHSF?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH1F",
		title: "Power Virtual Agent",
		description:
			"License that can be assigned to users to grant access to Virtual Agent for Customer Service.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH1F/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH1F?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHVD",
		title: "Pro Direct Support for Dynamics 365 Operations",
		description:
			"Professional Direct paid support offering for Microsoft Dynamics 365 which includes 24x7 support, escalation assistance, training and fast response times. 20-seat minimum purchase required.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHVD/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHVD?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHP3",
		title: "Project Online Essentials",
		description:
			"Collaborate on projects online, view and manage tasks, submit timesheets, and flag issues or risks. Requires Project Online or Project Server for your organization.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHP3/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHP3?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HDB1",
		title: "Project Plan 1",
		description:
			"An online project management solution to help keep your projects, resources, and teams organized and on track. Plan projects, track status, and collaborate with others from virtually anywhere.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HDB1/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HDB1?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HDB0",
		title: "Project Plan 3",
		description:
			"A complete online project management solution to help keep your projects, resources, and teams organized and on track. Plan projects, track status, and collaborate with others from virtually anywhere.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HDB0/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HDB0?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD9Z",
		title: "Project Plan 5",
		description:
			"A flexible solution for project portfolio management and everyday work. Includes the Project client for people who need full project management capabilities on their Windows desktops. Also supports project management online from virtually anywhere.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD9Z/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD9Z?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HL72",
		title: "Scheduler",
		description:
			"Scheduler enables users to email Cortana to a mailbox set up inside their tenant and delegate their scheduling needs to a digital personal assistant, including scheduling and re-scheduling personal appointments and meetings with people inside and outside of their organization.\r\nTo enable Scheduler admins must set up a new mailbox and run a PowerShell cmdlet. The service can take up to 24 hours to start working after you run the cmdlet. Purchasing the service does not immediately enable it.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HL72/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HL72?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH0N",
		title: "SharePoint (Plan 1)",
		description:
			"Collaboration plan that enables users to share, manage and search for information and resources.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH0N/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH0N?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LH14",
		title: "SharePoint (Plan 2)",
		description:
			"Best collaboration plan with advanced capabilities for rich forms, enhanced data visualization, publishing of Access databases, and hosted Visio diagrams.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LH14/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LH14?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HDK2",
		title: "SharePoint Syntex",
		description:
			"This per-user offer enables customers to get access to Advanced AI and machine teaching to amplify human expertise, automate content processing, and transform content into knowledge; and Content centers, digital image processing, form processing, document understanding, advanced taxonomy services, content connectors.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HDK2/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HDK2?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LHR5",
		title: "Skype for Business Plus CAL",
		description:
			"PSTN calling and enterprise-grade voice features using Skype for Business servers on-premises or hosted by a partner.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LHR5/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LHR5?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0GZ16",
		title: "Teams Rooms Premium",
		description:
			"Premium experiences for meeting room devices, including management, intelligence, and room software.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0GZ16/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0GZ16?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HDJR",
		title: "Universal Print",
		description:
			"Universal Print offers a modern print experience for education and commercial customers. It enables print administrators to register printers with Azure Active Directory and manage them through the Universal Print online portal. Azure AD users can easily find and print to those printers. \r\n\r\nThis is the stand-alone version of Universal Print; if you have a Windows or Microsoft 365 subscription, you may already own this benefit. Universal Print includes a base set of print-jobs that renews every month with your subscription. For more details, including how the total number of print-jobs for your organization are calculated, please read the Universal Print Licensing terms at aka.ms/up_trynow.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HDJR/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HDJR?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HL82",
		title: "Universal Print",
		description:
			"Universal Print offers a modern print experience for education and commercial customers. It enables print administrators to register printers with Azure Active Directory and manage them through the Universal Print online portal. Azure AD users can easily find and print to those printers. \r\n\r\nThis is the stand-alone version of Universal Print; if you have a Windows or Microsoft 365 subscription, you may already own this benefit. Universal Print includes a base set of print-jobs that renews every month with your subscription. For more details, including how the total number of print-jobs for your organization are calculated, please read the Universal Print Licensing terms at aka.ms/up_trynow.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HL82/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HL82?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD33",
		title: "Visio Plan 1",
		description:
			"A lightweight web-based diagramming solution that gives users an opportunity to create, share and store basic diagrams anywhere.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD33/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD33?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HD32",
		title: "Visio Plan 2",
		description:
			"Makes it easier than ever for individuals and teams to create data-linked diagrams that simplify complex information. It includes support for BPMN 2.0, AutoCAD file import, and UML 2.4. Each user can install Visio on up to five PCs running Windows 10/11, Windows 8.1, Windows 8, or Windows 7.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HD32/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HD32?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HVZG",
		title: "Viva Learning",
		description:
			"Viva Learning creates a central hub for learning in Teams where people can discover, share, assign, and learn from content libraries across an organization. It includes access to select Microsoft and LinkedIn Learning content.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HVZG/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HVZG?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LGTX",
		title: "Windows 10/11 Enterprise E3",
		description:
			"Windows 10/11 Enterprise E3 builds on Windows 10/11 Pro by delivering enterprise-grade security, management, and control features for large or mid-sized companies, or any size business that processes sensitive data, operates in regulated industries, or develops intellectual property that must remain secured. The increased security helps protect your sensitive data, identities, and devices from cybersecurity threats, and provides enhanced deployment and software and device management options.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LGTX/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LGTX?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0LFNW",
		title: "Windows 10/11 Enterprise E5",
		description:
			"Windows 10/11 Enterprise E5 includes Windows 10/11 Enterprise E3 plus Microsoft Defender Advanced Threat Protection (ATP). Microsoft Defender ATP is a unified endpoint security platform and enables enterprise customers to protect, detect, investigate, and respond to advanced attacks and data breaches. It is built-in, cloud powered, applies Artificial Intelligence to automate security incidents and leverages the Microsoft Intelligent Security Graph to integrate detection and exploration with other Microsoft Threat Protection services*. *some separate subscriptions may be required",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0LFNW/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0LFNW?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0J203",
		title: "Windows 365 Business",
		description:
			"Windows 365 Business gives you up to 300 licenses for Cloud PCs in your organization. \r\n \r\nSecurely stream your Windows experience - including your personalized apps, content, and settings – from the Microsoft cloud to any device with your Windows 365 Cloud PC.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0J203/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0J203?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HX99",
		title: "Windows 365 Business with Windows Hybrid Benefit",
		description:
			"This discounted Windows 365 Business subscription gives you up to 300 licenses for Cloud PCs in your organization. With the Windows Hybrid Benefit, customers with the Windows 11 Pro or Windows 10 Pro operating system licensed on their primary work devices qualify for discounted pricing on the Business edition.  \r\n \r\nSecurely stream your Windows experience - including your personalized apps, content, and settings – from the Microsoft cloud to any device with your Windows 365 Cloud PC",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HX99/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HX99?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
	{
		id: "CFQ7TTC0HHS9",
		title: "Windows 365 Enterprise",
		description:
			"Windows 365 Enterprise is for larger businesses needing to deploy Cloud PCs across their organization, with no license limit. It allows you to provision and manage Cloud PCs using Microsoft Endpoint Manager. To use Windows 365 Enterprise, each user needs to be licensed for: \r\n•\tWindows 11 Enterprise or Windows 10 Enterprise and Intune and Azure Active Directory P1 \r\n        or  \r\n•\tOne of the following versions of Microsoft 365: Business Premium, A3, A5, E3, E5, or F3 (including Microsoft 365 Education Student Use Benefit subscriptions) \r\n\r\nSecurely stream your Windows experience - including your personalized apps, content, and settings – from the Microsoft cloud to any device with your Windows 365 Cloud PC.",
		productType: {
			id: "OnlineServicesNCE",
			displayName: "OnlineServicesNCE",
		},
		isMicrosoftProduct: true,
		publisherName: "Microsoft Corporation",
		links: {
			skus: {
				uri: "/products/CFQ7TTC0HHS9/skus?country=no&targetSegment=commercial",
				method: "GET",
				headers: [],
			},
			self: {
				uri: "/products/CFQ7TTC0HHS9?country=no",
				method: "GET",
				headers: [],
			},
		},
	},
];

const SIGN_INS = {
	AggregatedDateSignins: [
		{
			TotalSuccessLogons: 124,
			DailyLogs: true,
			TotalConditionalAccessFailureLogons: 17,
			TotalConditionalAccessNotAppliedLogons: 1014,
			TotalFailedLogons: 1017,
			Date: 1672531200,
			TotalUknowncodesLogons: 0,
			TotalInterruptedLogons: 2,
			ActiveUsersLastCalendarDay: 38,
			CountLimit: 1143,
			TotalConditionalAccessSuccessLogons: 112,
			FailedLogonsPercentage: 88.98,
			TotalLogons: 1143,
		},
		{
			FailedLogonsPercentage: 44.42,
			TotalSuccessLogons: 1458,
			TotalConditionalAccessSuccessLogons: 1327,
			CountLimit: 2650,
			TotalFailedLogons: 1177,
			Date: 1672617600,
			ActiveUsersLastCalendarDay: 121,
			TotalConditionalAccessNotAppliedLogons: 1321,
			TotalUknowncodesLogons: 0,
			TotalLogons: 2650,
			TotalConditionalAccessFailureLogons: 2,
			DailyLogs: true,
			TotalInterruptedLogons: 15,
		},
		{
			TotalSuccessLogons: 1603,
			TotalUknowncodesLogons: 0,
			TotalFailedLogons: 1108,
			CountLimit: 2738,
			TotalConditionalAccessNotAppliedLogons: 1289,
			Date: 1672704000,
			TotalInterruptedLogons: 27,
			DailyLogs: true,
			FailedLogonsPercentage: 40.47,
			TotalConditionalAccessFailureLogons: 10,
			TotalConditionalAccessSuccessLogons: 1439,
			ActiveUsersLastCalendarDay: 137,
			TotalLogons: 2738,
		},
		{
			Date: 1672790400,
			TotalLogons: 2373,
			ActiveUsersLastCalendarDay: 118,
			TotalUknowncodesLogons: 0,
			TotalFailedLogons: 1343,
			TotalInterruptedLogons: 25,
			DailyLogs: true,
			TotalSuccessLogons: 1005,
			TotalConditionalAccessFailureLogons: 26,
			TotalConditionalAccessNotAppliedLogons: 1542,
			TotalConditionalAccessSuccessLogons: 805,
			FailedLogonsPercentage: 56.6,
			CountLimit: 2373,
		},
		{
			CountLimit: 1854,
			TotalConditionalAccessSuccessLogons: 771,
			FailedLogonsPercentage: 47.79,
			TotalLogons: 1854,
			TotalUknowncodesLogons: 0,
			ActiveUsersLastCalendarDay: 116,
			Date: 1672876800,
			TotalConditionalAccessNotAppliedLogons: 1053,
			DailyLogs: true,
			TotalInterruptedLogons: 19,
			TotalSuccessLogons: 949,
			TotalFailedLogons: 886,
			TotalConditionalAccessFailureLogons: 30,
		},
		{
			TotalLogons: 1822,
			TotalConditionalAccessSuccessLogons: 1065,
			TotalUknowncodesLogons: 0,
			TotalSuccessLogons: 1250,
			TotalFailedLogons: 556,
			Date: 1672963200,
			TotalConditionalAccessFailureLogons: 42,
			ActiveUsersLastCalendarDay: 121,
			FailedLogonsPercentage: 30.52,
			TotalInterruptedLogons: 16,
			CountLimit: 1822,
			TotalConditionalAccessNotAppliedLogons: 715,
			DailyLogs: true,
		},
		{
			FailedLogonsPercentage: 76.38,
			TotalUknowncodesLogons: 0,
			TotalConditionalAccessFailureLogons: 8,
			Date: 1673049600,
			DailyLogs: true,
			ActiveUsersLastCalendarDay: 48,
			TotalSuccessLogons: 319,
			CountLimit: 1355,
			TotalLogons: 1355,
			TotalFailedLogons: 1035,
			TotalConditionalAccessSuccessLogons: 282,
			TotalInterruptedLogons: 1,
			TotalConditionalAccessNotAppliedLogons: 1065,
		},
		{
			FailedLogonsPercentage: 86.33,
			CountLimit: 2319,
			TotalConditionalAccessNotAppliedLogons: 1813,
			TotalUknowncodesLogons: 0,
			TotalSuccessLogons: 312,
			TotalInterruptedLogons: 5,
			DailyLogs: true,
			TotalConditionalAccessFailureLogons: 246,
			Date: 1673136000,
			TotalConditionalAccessSuccessLogons: 260,
			ActiveUsersLastCalendarDay: 56,
			TotalFailedLogons: 2002,
			TotalLogons: 2319,
		},
		{
			TotalInterruptedLogons: 25,
			TotalConditionalAccessNotAppliedLogons: 1773,
			TotalLogons: 4301,
			TotalFailedLogons: 2672,
			DailyLogs: true,
			FailedLogonsPercentage: 62.13,
			TotalConditionalAccessSuccessLogons: 1427,
			Date: 1673222400,
			TotalConditionalAccessFailureLogons: 1101,
			ActiveUsersLastCalendarDay: 144,
			TotalUknowncodesLogons: 0,
			CountLimit: 4301,
			TotalSuccessLogons: 1604,
		},
		{
			TotalSuccessLogons: 1606,
			TotalLogons: 6240,
			Date: 1673308800,
			ActiveUsersLastCalendarDay: 136,
			DailyLogs: true,
			TotalConditionalAccessSuccessLogons: 1431,
			FailedLogonsPercentage: 73.91,
			TotalConditionalAccessNotAppliedLogons: 3538,
			TotalUknowncodesLogons: 0,
			TotalInterruptedLogons: 22,
			TotalConditionalAccessFailureLogons: 1271,
			TotalFailedLogons: 4612,
			CountLimit: 6240,
		},
		{
			TotalConditionalAccessFailureLogons: 130,
			TotalSuccessLogons: 1809,
			TotalUknowncodesLogons: 0,
			FailedLogonsPercentage: 63.8,
			CountLimit: 5083,
			ActiveUsersLastCalendarDay: 148,
			TotalFailedLogons: 3243,
			DailyLogs: true,
			TotalConditionalAccessNotAppliedLogons: 3324,
			TotalConditionalAccessSuccessLogons: 1629,
			TotalInterruptedLogons: 31,
			TotalLogons: 5083,
			Date: 1673395200,
		},
		{
			TotalConditionalAccessSuccessLogons: 1290,
			TotalFailedLogons: 3216,
			CountLimit: 4691,
			FailedLogonsPercentage: 68.56,
			DailyLogs: true,
			TotalLogons: 4691,
			TotalUknowncodesLogons: 2,
			TotalConditionalAccessFailureLogons: 28,
			TotalSuccessLogons: 1453,
			ActiveUsersLastCalendarDay: 142,
			TotalInterruptedLogons: 20,
			Date: 1673481600,
			TotalConditionalAccessNotAppliedLogons: 3373,
		},
		{
			TotalLogons: 5116,
			TotalConditionalAccessSuccessLogons: 1049,
			ActiveUsersLastCalendarDay: 127,
			DailyLogs: true,
			CountLimit: 5116,
			TotalUknowncodesLogons: 1,
			TotalFailedLogons: 3879,
			FailedLogonsPercentage: 75.82,
			Date: 1673568000,
			TotalSuccessLogons: 1223,
			TotalConditionalAccessNotAppliedLogons: 3240,
			TotalInterruptedLogons: 13,
			TotalConditionalAccessFailureLogons: 827,
		},
		{
			TotalConditionalAccessNotAppliedLogons: 3257,
			TotalSuccessLogons: 193,
			TotalConditionalAccessSuccessLogons: 180,
			TotalUknowncodesLogons: 0,
			ActiveUsersLastCalendarDay: 41,
			DailyLogs: true,
			Date: 1673654400,
			FailedLogonsPercentage: 94.61,
			TotalFailedLogons: 3438,
			TotalConditionalAccessFailureLogons: 197,
			CountLimit: 3634,
			TotalLogons: 3634,
			TotalInterruptedLogons: 3,
		},
		{
			Date: 1673740800,
			TotalInterruptedLogons: 3,
			CountLimit: 3458,
			TotalConditionalAccessFailureLogons: 119,
			TotalLogons: 3458,
			TotalConditionalAccessSuccessLogons: 173,
			TotalSuccessLogons: 207,
			FailedLogonsPercentage: 93.93,
			DailyLogs: true,
			ActiveUsersLastCalendarDay: 54,
			TotalConditionalAccessNotAppliedLogons: 3166,
			TotalFailedLogons: 3248,
			TotalUknowncodesLogons: 0,
		},
		{
			FailedLogonsPercentage: 68.93,
			TotalConditionalAccessSuccessLogons: 1349,
			Date: 1673827200,
			TotalLogons: 4953,
			TotalConditionalAccessNotAppliedLogons: 3433,
			TotalConditionalAccessFailureLogons: 171,
			TotalInterruptedLogons: 36,
			ActiveUsersLastCalendarDay: 133,
			DailyLogs: true,
			TotalSuccessLogons: 1502,
			CountLimit: 4953,
			TotalFailedLogons: 3414,
			TotalUknowncodesLogons: 1,
		},
		{
			FailedLogonsPercentage: 67.3,
			TotalConditionalAccessFailureLogons: 110,
			TotalUknowncodesLogons: 0,
			TotalLogons: 4554,
			TotalConditionalAccessNotAppliedLogons: 3176,
			ActiveUsersLastCalendarDay: 138,
			DailyLogs: true,
			TotalInterruptedLogons: 30,
			TotalSuccessLogons: 1459,
			TotalConditionalAccessSuccessLogons: 1268,
			CountLimit: 4554,
			TotalFailedLogons: 3065,
			Date: 1673913600,
		},
		{
			TotalSuccessLogons: 1462,
			TotalInterruptedLogons: 20,
			TotalLogons: 5042,
			DailyLogs: true,
			TotalConditionalAccessSuccessLogons: 1247,
			TotalConditionalAccessNotAppliedLogons: 3749,
			TotalFailedLogons: 3560,
			TotalConditionalAccessFailureLogons: 46,
			Date: 1674000000,
			FailedLogonsPercentage: 70.61,
			TotalUknowncodesLogons: 0,
			ActiveUsersLastCalendarDay: 139,
			CountLimit: 5042,
		},
		{
			TotalConditionalAccessSuccessLogons: 993,
			ActiveUsersLastCalendarDay: 131,
			Date: 1674086400,
			FailedLogonsPercentage: 71.44,
			DailyLogs: true,
			TotalConditionalAccessFailureLogons: 3,
			CountLimit: 4215,
			TotalSuccessLogons: 1192,
			TotalLogons: 4215,
			TotalUknowncodesLogons: 0,
			TotalConditionalAccessNotAppliedLogons: 3219,
			TotalFailedLogons: 3011,
			TotalInterruptedLogons: 12,
		},
		{
			TotalConditionalAccessSuccessLogons: 785,
			TotalConditionalAccessFailureLogons: 38,
			DailyLogs: true,
			CountLimit: 4139,
			TotalUknowncodesLogons: 0,
			ActiveUsersLastCalendarDay: 123,
			TotalConditionalAccessNotAppliedLogons: 3316,
			TotalInterruptedLogons: 18,
			TotalSuccessLogons: 976,
			TotalFailedLogons: 3145,
			Date: 1674172800,
			FailedLogonsPercentage: 75.98,
			TotalLogons: 4139,
		},
		{
			TotalFailedLogons: 3183,
			FailedLogonsPercentage: 96.75,
			TotalLogons: 3290,
			TotalConditionalAccessFailureLogons: 1,
			TotalConditionalAccessSuccessLogons: 89,
			ActiveUsersLastCalendarDay: 42,
			TotalInterruptedLogons: 2,
			TotalConditionalAccessNotAppliedLogons: 3200,
			TotalUknowncodesLogons: 0,
			DailyLogs: true,
			CountLimit: 3290,
			TotalSuccessLogons: 105,
			Date: 1674259200,
		},
		{
			TotalConditionalAccessSuccessLogons: 93,
			TotalUknowncodesLogons: 0,
			CountLimit: 2994,
			ActiveUsersLastCalendarDay: 43,
			TotalSuccessLogons: 112,
			Date: 1674345600,
			TotalFailedLogons: 2878,
			TotalInterruptedLogons: 4,
			TotalLogons: 2994,
			TotalConditionalAccessNotAppliedLogons: 2894,
			FailedLogonsPercentage: 96.13,
			DailyLogs: true,
			TotalConditionalAccessFailureLogons: 7,
		},
		{
			ActiveUsersLastCalendarDay: 133,
			TotalLogons: 5371,
			TotalFailedLogons: 3669,
			DailyLogs: true,
			CountLimit: 5371,
			TotalConditionalAccessFailureLogons: 517,
			TotalConditionalAccessNotAppliedLogons: 3379,
			FailedLogonsPercentage: 68.31,
			Date: 1674432000,
			TotalUknowncodesLogons: 0,
			TotalSuccessLogons: 1659,
			TotalConditionalAccessSuccessLogons: 1475,
			TotalInterruptedLogons: 43,
		},
		{
			DailyLogs: true,
			TotalConditionalAccessFailureLogons: 33,
			CountLimit: 4697,
			TotalSuccessLogons: 1456,
			TotalInterruptedLogons: 28,
			TotalLogons: 4697,
			TotalConditionalAccessSuccessLogons: 1305,
			ActiveUsersLastCalendarDay: 135,
			TotalConditionalAccessNotAppliedLogons: 3359,
			TotalFailedLogons: 3213,
			FailedLogonsPercentage: 68.41,
			Date: 1674518400,
			TotalUknowncodesLogons: 0,
		},
		{
			CountLimit: 4607,
			TotalInterruptedLogons: 33,
			TotalConditionalAccessNotAppliedLogons: 3230,
			TotalUknowncodesLogons: 0,
			TotalConditionalAccessSuccessLogons: 1317,
			DailyLogs: true,
			TotalLogons: 4607,
			TotalConditionalAccessFailureLogons: 60,
			Date: 1674604800,
			TotalFailedLogons: 3085,
			FailedLogonsPercentage: 66.96,
			ActiveUsersLastCalendarDay: 143,
			TotalSuccessLogons: 1489,
		},
		{
			TotalInterruptedLogons: 22,
			ActiveUsersLastCalendarDay: 140,
			FailedLogonsPercentage: 70.2,
			TotalConditionalAccessSuccessLogons: 1173,
			TotalUknowncodesLogons: 0,
			TotalSuccessLogons: 1337,
			TotalLogons: 4560,
			Date: 1674691200,
			TotalConditionalAccessNotAppliedLogons: 3369,
			DailyLogs: true,
			TotalFailedLogons: 3201,
			CountLimit: 4560,
			TotalConditionalAccessFailureLogons: 18,
		},
		{
			Date: 1674777600,
			DailyLogs: true,
			TotalInterruptedLogons: 33,
			TotalFailedLogons: 3348,
			CountLimit: 4306,
			TotalConditionalAccessNotAppliedLogons: 3478,
			TotalConditionalAccessFailureLogons: 72,
			FailedLogonsPercentage: 77.75,
			ActiveUsersLastCalendarDay: 126,
			TotalUknowncodesLogons: 0,
			TotalLogons: 4306,
			TotalSuccessLogons: 925,
			TotalConditionalAccessSuccessLogons: 756,
		},
		{
			TotalLogons: 3223,
			TotalConditionalAccessFailureLogons: 1,
			FailedLogonsPercentage: 97.08,
			ActiveUsersLastCalendarDay: 44,
			TotalUknowncodesLogons: 0,
			TotalConditionalAccessNotAppliedLogons: 3143,
			TotalConditionalAccessSuccessLogons: 79,
			CountLimit: 3223,
			Date: 1674864000,
			TotalSuccessLogons: 93,
			DailyLogs: true,
			TotalFailedLogons: 3129,
			TotalInterruptedLogons: 1,
		},
		{
			Date: 1674950400,
			DailyLogs: true,
			TotalUknowncodesLogons: 0,
			TotalConditionalAccessFailureLogons: 2,
			TotalConditionalAccessNotAppliedLogons: 3174,
			ActiveUsersLastCalendarDay: 52,
			CountLimit: 3237,
			TotalFailedLogons: 3154,
			TotalLogons: 3237,
			TotalInterruptedLogons: 3,
			TotalSuccessLogons: 80,
			FailedLogonsPercentage: 97.44,
			TotalConditionalAccessSuccessLogons: 61,
		},
		{
			ActiveUsersLastCalendarDay: 135,
			TotalConditionalAccessSuccessLogons: 1277,
			TotalConditionalAccessNotAppliedLogons: 3380,
			DailyLogs: true,
			FailedLogonsPercentage: 68.51,
			Date: 1675036800,
			TotalSuccessLogons: 1452,
			TotalConditionalAccessFailureLogons: 20,
			TotalInterruptedLogons: 21,
			TotalUknowncodesLogons: 0,
			TotalLogons: 4677,
			CountLimit: 4677,
			TotalFailedLogons: 3204,
		},
	],
};

const DEVICES_STATISTICS = {
	TotalNumberOfDevices: 314,
	NumberOfCompliantDevices: {
		NotAvailable: 241,
		True: 60,
		False: 13,
	},
	AllDevicesDetails: [
		{
			registrationDateTime: "2022-09-17T08:56:36Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "nico.bakker@bergans.com",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-19T12:35:22Z",
			operatingSystemVersion: "16.3",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-09-17T08:56:36Z",
			manufacturer: null,
			deviceId: "93764a1e-73ef-4e5b-92de-efef84709a22",
			displayName: "iPhone 14 Pro Max",
			id: "003ac320-1ee1-40ec-b095-cdfa1bf50141",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "37444ac1-6a5b-4669-b91b-a1cbba39bfd7",
			displayName: "annegrete2014",
			id: "011bc80d-f1eb-482e-9952-814ad8d967df",
		},
		{
			registrationDateTime: "2020-04-28T08:32:05Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "lasse.ulven.pedersen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T07:37:59Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-04-28T08:32:06Z",
			manufacturer: "LENOVO",
			deviceId: "3f8b7bea-6e39-49b4-b665-83c1e0542d37",
			displayName: "DESKTOP-1E5DIQT",
			id: "02fbc1ae-e2b1-45db-aea9-415aa511b1df",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-09-03T10:53:36Z",
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2021-11-25T01:17:04Z",
			manufacturer: null,
			deviceId: "41b1ec8a-e85c-4c61-8c12-f22fd6d651ca",
			displayName: "ARNE2016$",
			id: "030429fb-97f4-4a7b-9337-87495753d88e",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "640a4d93-0c63-4d50-a875-728675f1ac65",
			displayName: "LAB02PC2019$",
			id: "05d19f00-42e7-4bb2-b90d-d8f30a03cfae",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-09-14T00:28:51Z",
			manufacturer: null,
			deviceId: "ad583be7-f37c-4a4f-a887-c8081f850d40",
			displayName: "martin2016",
			id: "05e478f6-3d43-4ee5-9c88-dc5c6c10a0cc",
		},
		{
			registrationDateTime: "2022-08-31T03:41:47Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "AzureAd",
			operatingSystem: "Unknown",
			approximateLastSignInDateTime: "2022-08-31T03:41:47Z",
			operatingSystemVersion: "Unknown",
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2022-08-31T03:41:47Z",
			manufacturer: null,
			deviceId: "e9b2b233-d842-4389-9f5c-47911af54ad3",
			displayName: "007550474857",
			id: "05f7c187-0d21-4a73-b3f1-1ba993f729b3",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-20T13:00:01Z",
			operatingSystemVersion: "10.0.19045.2251",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-08-12T00:06:20Z",
			manufacturer: null,
			deviceId: "347a76bc-f4ad-4334-a255-9e7d38276795",
			displayName: "BG-BNL-NB002",
			id: "07436639-49a0-422b-85d8-b6774a5f0a6a",
		},
		{
			registrationDateTime: "2022-03-19T20:16:39Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "sm-algard@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T20:22:36Z",
			operatingSystemVersion: "10.0.19042.1466",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-19T20:16:39Z",
			manufacturer: null,
			deviceId: "1b80943c-8e93-413d-83ee-6e986f72ff52",
			displayName: "DESKTOP-A2I9BBR",
			id: "074f1b55-4425-41e6-90ad-2ed3fb1d625c",
		},
		{
			registrationDateTime: "2019-03-26T13:31:06Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-12T09:53:16Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-03-21T15:08:23Z",
			manufacturer: null,
			deviceId: "f7f00176-83cb-4fdc-a46f-81aefc28493d",
			displayName: "BG-PC041",
			id: "078cb682-2132-4882-81f1-90ed9df46aec",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-10-31T01:18:12Z",
			manufacturer: null,
			deviceId: "012d52f2-74e9-43c6-b003-9ad24fce6261",
			displayName: "ChrisCent2017",
			id: "09579d0c-5dc7-4e40-adc9-cdddf8c9dd91",
		},
		{
			registrationDateTime: "2020-06-10T08:59:47Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "kristiane.haugen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T06:13:36Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-06-10T08:59:47Z",
			manufacturer: "LENOVO",
			deviceId: "d7fd8778-4019-45f7-b456-677233436c16",
			displayName: "LAPTOP-37372EDU",
			id: "0afe8071-3fef-42f4-9a51-2ea548a5579f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-11-08T01:28:26Z",
			manufacturer: null,
			deviceId: "87620853-7c11-47c3-8f8e-346f273e8660",
			displayName: "EMELIE2018$",
			id: "0b793fae-9959-4872-82ad-e986e444e42a",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-02-19T14:32:10Z",
			manufacturer: null,
			deviceId: "6911db84-250e-492b-b833-99cb209fe6a8",
			displayName: "BG-NB041",
			id: "0c4ede91-a320-4551-aca9-b230e0f5ecd4",
		},
		{
			registrationDateTime: "2022-11-21T10:40:48Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "anne.eastwood@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-18T15:20:58Z",
			operatingSystemVersion: "10.0.22621.1105",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-07-02T10:03:49Z",
			manufacturer: "LENOVO",
			deviceId: "8055e276-956e-4274-8e16-39ac490571a7",
			displayName: "EASTWOOD",
			id: "0c617aaa-c3fa-486c-875f-d73ffbe05843",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "a6107cd7-a2ad-41ef-8e8d-27eae97dc9ff",
			displayName: "IRISWIN10$",
			id: "0da82840-f725-4213-9803-5964b55cae59",
		},
		{
			registrationDateTime: "2020-09-10T11:24:27Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "anett.kolstad@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T18:09:05Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-09-10T11:24:27Z",
			manufacturer: "LENOVO",
			deviceId: "16897897-647b-4f48-bd07-234c68014951",
			displayName: "LAPTOP-4OABOFSL",
			id: "0e07064d-e71f-4047-a430-8e346283c391",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-11-25T12:16:31Z",
			manufacturer: null,
			deviceId: "4bdd54a8-433e-4115-a3ad-af9a679aad61",
			displayName: "Anja2020",
			id: "0e104046-e105-4915-a3be-902dbe3844b0",
		},
		{
			registrationDateTime: "2021-02-19T10:13:03Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Moritz.Leidler@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-18T13:39:16Z",
			operatingSystemVersion: "10.0.19042.804",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-02-19T10:13:03Z",
			manufacturer: null,
			deviceId: "9ee126fe-18b3-4d53-a89e-26432eee9b71",
			displayName: "BG-NB048",
			id: "0e1bb11d-d327-4af5-9867-d04a35d30f63",
		},
		{
			registrationDateTime: "2020-12-01T09:42:55Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "rita.oldervik@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T06:30:16Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-12-01T09:42:55Z",
			manufacturer: "LENOVO",
			deviceId: "3a65d773-7aee-4b58-9fcb-d71a505e4963",
			displayName: "LAPTOP-6N19CC1P",
			id: "0e4e3e7e-4c35-4532-bb72-c186af942220",
		},
		{
			registrationDateTime: "2020-05-26T13:45:00Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "thea.holo@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-21T20:19:12Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-05-26T13:45:01Z",
			manufacturer: "LENOVO",
			deviceId: "3094821e-fe43-4f3f-80e1-8b3933286f41",
			displayName: "DESKTOP-71C3B9F",
			id: "0e829a12-8f59-49ea-abdb-85852909ed1d",
		},
		{
			registrationDateTime: "2019-10-21T10:55:50Z",
			model: "Latitude 7400",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "thea.holo@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T09:39:01Z",
			operatingSystemVersion: "10.0.19044.1415",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2019-10-21T10:55:50Z",
			manufacturer: "Dell Inc.",
			deviceId: "4c07cd8d-2843-4818-b105-6f7923eba2d1",
			displayName: "DESKTOP-SROSJJP",
			id: "0f2e1aa0-354b-42e6-9f61-c9ff024f3fb5",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-11-30T15:21:26Z",
			manufacturer: null,
			deviceId: "0a691c22-0841-4c54-abc8-5dabbb47a22e",
			displayName: "BG-NB047",
			id: "0faa55c7-ef4f-44e0-874b-9f97eb59c743",
		},
		{
			registrationDateTime: "2018-06-15T05:55:42Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T10:13:58Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-06-13T19:38:56Z",
			manufacturer: null,
			deviceId: "3a3178a2-ea63-4dcd-afdc-ad4dbcecd003",
			displayName: "eastwood2018",
			id: "0fac2213-81f6-4c15-8dc3-c22ed731b328",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-12-03T01:16:06Z",
			manufacturer: null,
			deviceId: "90d15536-5b02-44c1-9855-d24f5146a146",
			displayName: "Langevåg102019",
			id: "12a80eb9-ed0d-43e4-bb30-b59ecce070de",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "d66cdb48-a6c6-49c5-829d-5eb478718540",
			displayName: "yngvill2017",
			id: "1313c098-e7a6-4c26-a4e7-912e4987e07f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "1bce2b0d-addb-4c2e-a311-ed1bb483c565",
			displayName: "mark2015",
			id: "131d9ce7-2570-4191-ab1d-87d674cdefe0",
		},
		{
			registrationDateTime: "2020-08-27T07:46:07Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "janne.leander@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T18:10:39Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-08-27T07:46:08Z",
			manufacturer: "LENOVO",
			deviceId: "bdc6029b-bd33-4b3c-90a1-191213ac4f52",
			displayName: "LAPTOP-CAJ0IKH1",
			id: "13229f8d-e577-4130-8b78-ab9a02b2f359",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0.19044.2364",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-11-12T01:17:42Z",
			manufacturer: null,
			deviceId: "a4a8aeeb-ebdf-47f2-91fd-4fbfb0908d1e",
			displayName: "Futurelabs122019",
			id: "1381cfad-8164-4ec5-b5d0-5bdbf2c3fcf9",
		},
		{
			registrationDateTime: "2022-04-07T09:23:27Z",
			model: "20UJ001RMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "hans.simensen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-25T20:22:09Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-04-07T09:23:27Z",
			manufacturer: "LENOVO",
			deviceId: "dd9c0943-d697-452f-a5d5-2603cc90866c",
			displayName: "LAPTOP-D133HFT9",
			id: "1388abfc-1d58-43f6-8940-e7c67151d71f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-08-26T00:20:05Z",
			manufacturer: null,
			deviceId: "5c845e22-dac2-407e-b167-da64e99397b6",
			displayName: "BG-PC036",
			id: "16df912c-14f2-4fbe-aa87-f8510020ca4a",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-08T13:06:38Z",
			manufacturer: null,
			deviceId: "f7135d4a-7cc4-48db-9e98-2cb054af0460",
			displayName: "BG-NB052",
			id: "1722c650-8f80-4824-ab79-dc922001357f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "caf4e39d-7b7e-4a66-881b-b3008a2cb16a",
			displayName: "BAKROMVESTBY",
			id: "17a704df-dad0-4da3-8a4b-f50a52eaae2e",
		},
		{
			registrationDateTime: "2018-11-27T06:33:25Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-20T01:20:19Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-07-11T12:15:19Z",
			manufacturer: null,
			deviceId: "ee02855b-7208-4c81-8913-b21ecb59ae51",
			displayName: "BG-PC064",
			id: "18c354da-2cf8-4b80-83b8-335881f23ce0",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "9489ddf9-c6ce-4de8-9081-43d40e62094a",
			displayName: "KRIKVTH2019$",
			id: "1d082524-f506-4f18-b19a-42f69a230d0d",
		},
		{
			registrationDateTime: "2019-01-29T14:19:18Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T12:48:34Z",
			operatingSystemVersion: "10.0.22621.1105",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-01-29T11:01:04Z",
			manufacturer: null,
			deviceId: "db7dbe3e-685a-47d0-abec-ca13ed231e79",
			displayName: "BG-NB038",
			id: "1dad7689-5b48-4ae5-b300-6a6864feefe3",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "4019d204-c280-4a2d-b30f-0a1642044c57",
			displayName: "janniche2013",
			id: "1db8471e-883d-4aec-b75a-573e36f0226c",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "dd9d6bb8-c5d0-47d7-a5a2-2c99bf788c7a",
			displayName: "Mellem-PC",
			id: "1dd51634-18ae-4cc9-bc4c-bd7c24ac1611",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-12-06T08:57:56Z",
			manufacturer: null,
			deviceId: "015cd906-9f30-4610-82bf-a228a04d6e23",
			displayName: "BG-NB035",
			id: "1f0ab7ad-a83b-41b4-8530-dddf310e7ac8",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-01-10T01:19:47Z",
			manufacturer: null,
			deviceId: "5e356575-12b7-479c-9a9a-c11fd01910db",
			displayName: "ANETT2018$",
			id: "2081596a-07c8-4816-8e03-ebcb28c91599",
		},
		{
			registrationDateTime: "2019-11-04T16:05:43Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T08:41:41Z",
			operatingSystemVersion: "10.0.19045.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-11-04T16:04:24Z",
			manufacturer: null,
			deviceId: "72390691-0182-40b3-9d7e-3786ea7092d1",
			displayName: "BG-NB029",
			id: "21c61941-75f1-49a6-9366-d008b5854c82",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-09-28T00:07:29Z",
			manufacturer: null,
			deviceId: "3282e80e-4fbe-4220-aace-896ee95719cd",
			displayName: "MONIKA2015$",
			id: "21ca16fa-f2c9-4b97-86c6-1643ce21225b",
		},
		{
			registrationDateTime: "2022-08-19T10:22:27Z",
			model: "Surface Laptop 3",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "jan.tore@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-22T21:20:12Z",
			operatingSystemVersion: "10.0.19045.2486",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-01-11T08:02:52Z",
			manufacturer: "Microsoft Corporation",
			deviceId: "f5d7be6c-5e3f-43a7-987f-37b99d8450ab",
			displayName: "DESKTOP-JBL07OV",
			id: "22fb2a19-9f81-4b4e-81bb-8ed62612d7a5",
		},
		{
			registrationDateTime: "2022-12-05T07:39:12Z",
			model: "20XW006GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "bente.bonnegolt@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-29T09:58:31Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-12-05T07:39:12Z",
			manufacturer: "LENOVO",
			deviceId: "81ee1924-0755-4e19-ae71-26668ef9609a",
			displayName: "LAPTOP-F6JSEOPT",
			id: "2347773d-a24c-421a-bcbb-49c23e1c7d6b",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0 (17763)",
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2019-05-07T13:42:27Z",
			manufacturer: null,
			deviceId: "222cf1a7-dae6-49b6-ac8c-3c9acccbe705",
			displayName: "UTLAN07$",
			id: "2805f5d8-57cd-48d7-a643-cf83d1c9171e",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-08-05T00:09:29Z",
			manufacturer: null,
			deviceId: "7f9f174b-6943-403f-a98d-4eb806efae58",
			displayName: "BG-PC039",
			id: "29d60e5f-b062-4b78-b4a1-2bd941c244d3",
		},
		{
			registrationDateTime: "2022-01-26T17:54:38Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "rikke.bjorkestol@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-13T08:28:01Z",
			operatingSystemVersion: "10.0.22000.469",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-01-26T17:54:38Z",
			manufacturer: null,
			deviceId: "e72c5a60-f51f-4d8a-aff4-7a4a9c9049c3",
			displayName: "LAPTOP-KL97VH6G",
			id: "2a2c209d-6ea9-4616-b2e4-027a27dcf839",
		},
		{
			registrationDateTime: "2020-10-15T11:43:09Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "monika.lorentzen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T04:51:36Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-10-15T11:43:09Z",
			manufacturer: "LENOVO",
			deviceId: "ecc290e5-9c1a-4bf6-9e53-cda7c2559fb2",
			displayName: "LAPTOP-O1L9D0KE",
			id: "2a5c559e-10ce-41d8-9ef5-d4f9de32d784",
		},
		{
			registrationDateTime: "2021-05-06T13:08:25Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T06:37:19Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-02-19T14:32:10Z",
			manufacturer: null,
			deviceId: "d8d089a1-d3ab-4ff2-8d10-cb7b9e31ae85",
			displayName: "BG-NB045",
			id: "2a70fed6-485e-41a6-bac2-4d0dc1c3f183",
		},
		{
			registrationDateTime: "2022-07-18T16:57:45Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "marcus.jernberg@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-28T18:01:26Z",
			operatingSystemVersion: "10.0.22000.795",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-07-18T16:57:45Z",
			manufacturer: null,
			deviceId: "57c9963f-eb0b-4165-a599-600cfefc1c1a",
			displayName: "Marcus-Stasjon",
			id: "2ac19c22-8210-45cd-8427-51fcf8bbbb7a",
		},
		{
			registrationDateTime: "2020-05-04T06:18:48Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "johan.segeblad@bergans.se",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T09:07:34Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-05-04T06:18:48Z",
			manufacturer: "LENOVO",
			deviceId: "5f695cd2-6924-4d43-8a6f-93dadbb30917",
			displayName: "LAPTOP-6CHNL042",
			id: "2b618d26-f94a-4884-ac42-8ffd81ed2c3b",
		},
		{
			registrationDateTime: "2022-02-01T10:06:02Z",
			model: "Latitude 7420",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "christian.weier@bergans.de",
			managementType: "MDM",
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-19T13:08:22Z",
			operatingSystemVersion: "10.0.22000.493",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-02-01T10:06:02Z",
			manufacturer: "Dell Inc.",
			deviceId: "6c558b66-9d0f-4fea-b34f-ac917cd41546",
			displayName: "DESKTOP-3TCJ4E7",
			id: "2b9dea56-eefd-4bfa-bc08-9762a9ce223e",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-08T13:06:38Z",
			manufacturer: null,
			deviceId: "45c2b440-bdab-4181-a9d6-18f53e959fed",
			displayName: "BG-NB053",
			id: "2d1277d2-22a1-4fa6-bb4b-025b8389439c",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-11-16T11:10:19Z",
			manufacturer: null,
			deviceId: "42b1f5dc-ce82-4739-ab36-5a28393b4cae",
			displayName: "BG-BNL-NB001",
			id: "2e0024a6-e2c9-48ab-a226-d10e07766949",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2021-03-22T01:21:24Z",
			manufacturer: null,
			deviceId: "e18bfec5-34ac-4d29-9ba0-f7d54cc4357d",
			displayName: "ragnhild2017",
			id: "2fe3c53f-322b-4163-b127-a2ccd44a6c9e",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "b45879c6-03be-4bee-83f7-e7719a7c99f5",
			displayName: "SVEINJO2018$",
			id: "2fe88539-0e99-4900-a3b6-69378d8774ee",
		},
		{
			registrationDateTime: "2021-06-14T07:09:12Z",
			model: "20S0000GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "tone.gundhus@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-25T09:44:59Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-06-14T07:09:13Z",
			manufacturer: "LENOVO",
			deviceId: "bde94afa-2699-4777-aae8-4720e1545205",
			displayName: "LAPTOP-INIS2QQ9",
			id: "30ca1ef9-b281-4c12-bcf8-00d9d0feba6b",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "22715026-fed1-48d6-bb06-012fa5cc5d9d",
			displayName: "2015METTE2019$",
			id: "30dfa3bc-b6c0-490e-9caf-fcc3d9efbb9b",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "77387403-30db-4866-abf9-1fee2a53dd36",
			displayName: "BG-PC052",
			id: "32b2f4f5-d622-4949-8a0b-c381e7e38319",
		},
		{
			registrationDateTime: "2022-10-31T11:04:04Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "bent.sandmael@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T10:01:26Z",
			operatingSystemVersion: "10.0.22621.1105",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-08-31T03:41:47Z",
			manufacturer: "LENOVO",
			deviceId: "2c427c97-7967-4051-81d6-2f6e6293a69c",
			displayName: "DESKTOP-BG6KG4J",
			id: "3356b728-a247-493e-b2b6-a8cbd9ac8ff2",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-09-07T00:27:17Z",
			manufacturer: null,
			deviceId: "3aa6c246-3cf7-4c63-92a3-c4aa4dfcfbc0",
			displayName: "tone2016",
			id: "335df8cd-166e-4dd0-b271-7d477f41dd9a",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2021-04-12T00:13:21Z",
			manufacturer: null,
			deviceId: "bc3eb6e0-f4b5-4751-bc2e-bd9221b07adc",
			displayName: "bjornhild2017",
			id: "33756175-d4bb-4b1d-87c7-5f4a3ce917a6",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-09-01T00:13:48Z",
			manufacturer: null,
			deviceId: "31c90fde-c820-456a-baa5-1f09402944ca",
			displayName: "KAREN2012$",
			id: "3496c67a-1302-4de3-8a41-8873928ce977",
		},
		{
			registrationDateTime: "2022-06-09T13:58:32Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-11-08T14:47:39Z",
			operatingSystemVersion: "10.0.19045.2251",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-01-20T10:19:59Z",
			manufacturer: null,
			deviceId: "aced2335-9bf4-484e-bc73-5443ec69dc83",
			displayName: "BG-PC067",
			id: "356bbf7d-2e64-4c70-ad10-5f9575a0b46d",
		},
		{
			registrationDateTime: "2020-06-25T12:34:19Z",
			model: "20QJ001GMX",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "janniche.kristensen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T09:49:53Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-06-25T12:34:20Z",
			manufacturer: "LENOVO",
			deviceId: "e70ae331-2be7-4c2c-912f-4b27d814a115",
			displayName: "DESKTOP-JSNTI1M",
			id: "38b7c966-9483-4944-bcaf-0769f2225305",
		},
		{
			registrationDateTime: "2021-06-08T12:26:33Z",
			model: "20S0000GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "lisbeth.askeroi@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T07:52:19Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-06-08T12:26:33Z",
			manufacturer: "LENOVO",
			deviceId: "8c8f6d12-df06-447d-8c9b-61dda8f0fd82",
			displayName: "LISBETH2021",
			id: "390e7853-f21c-42e5-85bf-cee15fc7333d",
		},
		{
			registrationDateTime: "2020-08-13T12:24:36Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "emelie.knuts@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-29T17:38:41Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-08-13T12:24:36Z",
			manufacturer: "LENOVO",
			deviceId: "c8a429dd-d5ec-4955-87b4-c1d7ec48ef9e",
			displayName: "DESKTOP-7GPTFL3",
			id: "3a93fc08-c6db-40e9-8af4-2e4e64cd468c",
		},
		{
			registrationDateTime: "2022-01-19T08:54:12Z",
			model: "20UHCTO1WW",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "sofia.cederlund.asberg@bergans.se",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-28T11:45:00Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-01-19T08:54:12Z",
			manufacturer: "LENOVO",
			deviceId: "32ecf7b6-a765-4c77-b02b-5c9640a87a32",
			displayName: "LAPTOP-S46OTILF",
			id: "3babe6ad-6591-4c56-9bf5-2b91848b6f39",
		},
		{
			registrationDateTime: "2019-08-29T10:45:41Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-17T12:05:23Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-06-20T12:38:08Z",
			manufacturer: null,
			deviceId: "36381702-f8b3-43a2-b157-a316adfeb89d",
			displayName: "BG-NB042",
			id: "3bf19b10-ff64-4f2d-963f-154e0afdbc47",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-10-20T00:11:16Z",
			manufacturer: null,
			deviceId: "4478e6fd-0a43-44ef-bac4-91f258b2f496",
			displayName: "BG-NB011",
			id: "3cfa45ff-b74d-463e-950c-ac27fbcf22ba",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-10-14T00:33:33Z",
			manufacturer: null,
			deviceId: "645f84a7-c874-4e04-9dab-3d048e3979ae",
			displayName: "Ingrid2017",
			id: "3dc6d7c7-037f-4923-bec6-2d3bde3244d9",
		},
		{
			registrationDateTime: "2022-11-04T12:32:27Z",
			model: "20XY004JMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "ronny.hammer@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-25T09:54:25Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-11-04T12:32:27Z",
			manufacturer: "LENOVO",
			deviceId: "18e22588-7a81-4500-a2bc-16b9570277bd",
			displayName: "LAPTOP-MVU6K8N3",
			id: "3f9778a8-9e5a-449b-b9f0-47c3b8c6d9a3",
		},
		{
			registrationDateTime: "2022-08-10T08:30:15Z",
			model: "20UJ001RMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "andreas.gjesteby@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T06:36:34Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-08-10T08:30:16Z",
			manufacturer: "LENOVO",
			deviceId: "33653c95-1483-41c3-86fb-2f61e6158a5f",
			displayName: "ANDREAS-T14S",
			id: "4164f871-b719-4a6a-afc4-cd52a9b3824f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "d4491482-a6a5-45d5-866c-9073be621ba8",
			displayName: "MAYBENTE2017$",
			id: "4219d5aa-9411-435b-96cb-cfd0eabdd5ea",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-08-25T12:35:56Z",
			manufacturer: null,
			deviceId: "10ad080f-1a58-4eaf-a7a5-5bbe5a4efa82",
			displayName: "BG-PC074",
			id: "4281124b-64bb-4d18-90a1-54f1cc83ebb3",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "c3527b76-62a3-4cbe-9cf1-3520a72eb979",
			displayName: "GURO2014",
			id: "45311c86-1cd3-41d3-bc91-1a9cede6e3fb",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-09-14T00:02:49Z",
			manufacturer: null,
			deviceId: "8e5e00a6-2db9-473e-9e19-fa4dde7c79f4",
			displayName: "silje2018",
			id: "45b80c0e-f485-458e-8d44-a62220b48bc3",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-10-05T00:24:44Z",
			manufacturer: null,
			deviceId: "11d0d8da-cd1b-453d-a55b-bd23c3e91ed4",
			displayName: "turid2016",
			id: "45e0c960-6be0-4a2c-b7fc-b3f92d031883",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-10-27T01:19:36Z",
			manufacturer: null,
			deviceId: "9beb1284-d9e0-44bb-a77a-581965e2ba1b",
			displayName: "KRISTIANE2016",
			id: "4632f01d-35ec-4fd6-8172-276f0438a61c",
		},
		{
			registrationDateTime: "2019-06-27T11:22:21Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-12T13:48:34Z",
			operatingSystemVersion: "10.0.19045.2251",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-06-27T09:11:50Z",
			manufacturer: null,
			deviceId: "6d170461-c7d1-43b6-8f06-fc7ec44567ad",
			displayName: "BG-NB026",
			id: "48543472-90d6-4f03-a899-12aae781250b",
		},
		{
			registrationDateTime: "2020-03-10T08:59:43Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "sandra.gresslos@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-29T18:56:55Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-03-10T08:59:43Z",
			manufacturer: "LENOVO",
			deviceId: "cfe0832c-7b6e-4cec-90b4-e841385a73ba",
			displayName: "LAPTOP-N93DEEQ7",
			id: "48ec6346-b304-4a60-822d-419696ecfccf",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-06-01T00:11:20Z",
			manufacturer: null,
			deviceId: "23c96e26-62d4-4174-a93c-2ea4cd0c9676",
			displayName: "KristianMac",
			id: "4a775db2-f056-48f4-b257-7053ae486b5c",
		},
		{
			registrationDateTime: "2021-05-20T07:11:24Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "christine.finnbraten@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-16T17:44:08Z",
			operatingSystemVersion: "14.4.2",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-20T07:11:24Z",
			manufacturer: null,
			deviceId: "e1931c1c-f900-4f46-a4da-6b4f849b235a",
			displayName: "Christine Finnbråtens iPhone",
			id: "4c27eced-9e87-469b-8fb8-880be818dd43",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-11-26T18:48:21Z",
			operatingSystemVersion: "10.0.19042.1466",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-09-28T00:24:25Z",
			manufacturer: null,
			deviceId: "c277f7ec-1bf8-4c6a-ab7c-ede2f06f5941",
			displayName: "SILJE2017$",
			id: "4c9d23f1-5596-47a0-90cb-16ded26f9cd6",
		},
		{
			registrationDateTime: "2021-01-05T06:53:52Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "simen.skarra@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-11T08:04:17Z",
			operatingSystemVersion: "16.1.2",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-01-05T06:53:52Z",
			manufacturer: null,
			deviceId: "b92dbaba-842b-4f9f-b717-f4821dd1b4da",
			displayName: "iPhone 12",
			id: "4cf27f30-97c0-4edd-b444-e97aeae8f79c",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "2c3ec4ea-354c-482c-aa97-9b8a5ea79860",
			displayName: "UTLÅNSPC$",
			id: "4d4e951e-a62c-4a22-91a8-131b07e552d1",
		},
		{
			registrationDateTime: "2021-03-01T12:02:23Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "sm-algard@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-28T22:17:44Z",
			operatingSystemVersion: "10.0.19041.746",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-03-01T12:02:23Z",
			manufacturer: null,
			deviceId: "ba135956-ac5f-4702-9b80-eb72b6f5fad6",
			displayName: "algard2021",
			id: "4e849756-8293-41a7-a0a3-76634b8a2840",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "ba2136c2-eab8-4723-8c7e-d1c6d46c74ba",
			displayName: "INGEMA2018$",
			id: "4f0e1c94-8741-47d5-b758-f4caaf301cbf",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-11-02T01:20:13Z",
			manufacturer: null,
			deviceId: "63e5a9a4-6819-4ceb-a0cf-f1def035ba11",
			displayName: "siri2016",
			id: "4f4fcc7e-f67c-4b4d-873b-6cef57080a12",
		},
		{
			registrationDateTime: "2020-01-17T10:46:41Z",
			model: "Latitude 7490",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "larserik.corneliussen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T14:17:27Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-01-17T10:46:41Z",
			manufacturer: "Dell Inc.",
			deviceId: "da90f955-735b-4f7a-a23b-e1b5ec0e8165",
			displayName: "DESKTOP-3GG5AFD",
			id: "50b70b7d-9232-4605-902f-7186ee9a05ad",
		},
		{
			registrationDateTime: "2022-02-09T14:00:24Z",
			model: "Surface Pro 8",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "jan.martin.holmen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-10-31T22:03:35Z",
			operatingSystemVersion: "10.0.22000.1098",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-02-09T14:00:24Z",
			manufacturer: "Microsoft Corporation",
			deviceId: "e6c1b8c5-ad70-4e4e-ac11-48a911df1d14",
			displayName: "DESKTOP-5Q58BDF",
			id: "50fbb510-f7b4-4625-a7e9-26092f27477d",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-08-09T11:12:39Z",
			manufacturer: null,
			deviceId: "41ee5b35-e971-4965-97c3-c262f98ec503",
			displayName: "BJORNESPEN2017$",
			id: "5180ff7e-2377-462c-a6af-ffeb7cbc8b8f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2022-02-13T01:11:49Z",
			manufacturer: null,
			deviceId: "acae75c6-694e-49f2-b32a-fff275b5931e",
			displayName: "MartheTømte2019",
			id: "5185978e-8d86-4596-98fe-128c0bd89cf2",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0 (17134)",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-05-23T19:45:58Z",
			manufacturer: null,
			deviceId: "dd90540b-5c97-4f3e-91af-3bdfede6fb40",
			displayName: "jantore2016",
			id: "525cb5a2-4085-489f-9dbf-79c73012f492",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-24T00:02:55Z",
			manufacturer: null,
			deviceId: "44447293-5641-451c-9180-3f2a91c238aa",
			displayName: "JonGreg2019",
			id: "56455349-a573-4815-9a7b-abf56b6893cf",
		},
		{
			registrationDateTime: "2020-09-01T09:48:45Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "mette.andersen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T09:38:02Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-09-01T09:48:45Z",
			manufacturer: "LENOVO",
			deviceId: "2227700f-0a62-442e-af98-b9e54bcce066",
			displayName: "LAPTOP-FGM938GU",
			id: "56b6bdb7-b411-4d48-9608-cd05cbfb803b",
		},
		{
			registrationDateTime: "2020-12-15T09:51:08Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "silje.rost@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-25T06:58:06Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-12-15T09:51:08Z",
			manufacturer: "LENOVO",
			deviceId: "4d9ccf79-b7f6-4187-a29c-67d685af81d8",
			displayName: "DESKTOP-JH5EM8R",
			id: "56e04cc4-f723-466e-a18e-ab923f847a13",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "64993178-89af-4594-8bc1-203b5f2efb14",
			displayName: "dagfrode2016",
			id: "57928e02-2bdd-49fc-bdc8-55198decfb82",
		},
		{
			registrationDateTime: "2019-11-25T12:09:26Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-28T23:36:29Z",
			operatingSystemVersion: "Windows 10",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-02-21T12:25:32Z",
			manufacturer: null,
			deviceId: "15d20441-3e09-4e41-a333-a50aea7753d5",
			displayName: "BG-Print01",
			id: "57a3b080-e525-40c0-a4b4-ddbeb2ba806c",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-08T13:06:38Z",
			manufacturer: null,
			deviceId: "bf8552ac-d100-40c8-99d5-4ebf350901cc",
			displayName: "BG-PC069",
			id: "57acc1d4-b589-4da7-8d65-39159f05f960",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0 (17134)",
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2019-01-04T07:53:30Z",
			manufacturer: null,
			deviceId: "54feaf47-7a11-4c8a-8cd1-8a3c23e61d11",
			displayName: "Jon2018-12",
			id: "57e8c7b0-5b36-47d7-98bb-884b850f8f9d",
		},
		{
			registrationDateTime: "2022-08-30T06:45:30Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "maja.karajlic@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T05:13:20Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-08-30T06:45:30Z",
			manufacturer: "LENOVO",
			deviceId: "466ada2c-afc3-4830-a352-3a289f54d5f8",
			displayName: "DESKTOP-UV2H8VL",
			id: "589a0e2d-6b55-4058-a57e-8e8a2be7318a",
		},
		{
			registrationDateTime: "2021-03-10T21:29:15Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "jon.gregertsen@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-19T18:03:49Z",
			operatingSystemVersion: "10.0.19041.804",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-03-10T21:29:15Z",
			manufacturer: null,
			deviceId: "3142729c-14d0-4483-9178-84e27f5eb53d",
			displayName: "DESKTOP-AJ8K08A",
			id: "59537b61-116e-42be-86d8-6da121e88ef6",
		},
		{
			registrationDateTime: "2020-08-13T08:13:43Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "christoph.centmayer@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T10:32:48Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-08-13T08:13:43Z",
			manufacturer: "LENOVO",
			deviceId: "9a9990f2-7804-4b23-b8cb-d98ef67356ba",
			displayName: "DESKTOP-DEBSF2K",
			id: "5a61aea2-6cf5-4cba-a7b7-dd963371d506",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2019-11-25T14:53:29Z",
			manufacturer: null,
			deviceId: "3c3f7e57-d63e-4797-bfd7-5ea20e6ad365",
			displayName: "ERIK2017$",
			id: "5b46b872-f2f3-46cd-a629-3b3161c8de08",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2022-06-09T00:14:56Z",
			manufacturer: null,
			deviceId: "d9a8074f-e7c5-4b78-ba07-bd478c01ad84",
			displayName: "JUDITH2018$",
			id: "5e41bdd4-ee8e-4e7f-80fb-e180eaea00fb",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-09-15T00:29:41Z",
			manufacturer: null,
			deviceId: "43e5aba7-4974-4334-bf07-09bd02c59670",
			displayName: "granerud2014",
			id: "5feb4192-f54b-4056-aece-6eb34e6a0b8e",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "b84f69a9-6bd0-4911-9ea1-f05cc43999d9",
			displayName: "hilly2017",
			id: "60ed0340-b517-4bd5-928e-2d1e02570bf6",
		},
		{
			registrationDateTime: "2020-05-15T14:22:06Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "yngvill.ofstad@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-23T13:40:38Z",
			operatingSystemVersion: "16.1.2",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-05-15T14:22:07Z",
			manufacturer: null,
			deviceId: "86e39579-618b-47eb-8b26-b57577ef3e9b",
			displayName: "iPhone 8",
			id: "62c89313-b8f0-4507-9b9c-b0c0e6130c82",
		},
		{
			registrationDateTime: "2021-09-10T13:38:10Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "isabel.haarsaker@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T11:09:21Z",
			operatingSystemVersion: "10.0.19043.1165",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-09-10T13:38:10Z",
			manufacturer: null,
			deviceId: "1a116ed7-9e53-478f-a8ab-49b0ef1e3adc",
			displayName: "DESKTOP-4346706",
			id: "63b2a8b2-02a7-4652-9da4-e933cb6bd2df",
		},
		{
			registrationDateTime: "2022-01-03T11:28:41Z",
			model: "20ST005UMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "sandra.lonnberg@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T08:11:18Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-01-03T11:28:41Z",
			manufacturer: "LENOVO",
			deviceId: "9031c466-7b41-4e54-94a9-0f215636e54e",
			displayName: "LAPTOP-P1UBPHDG",
			id: "63dca53b-d5fc-4f55-bec3-77ab65f5255d",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-08-25T12:35:56Z",
			manufacturer: null,
			deviceId: "c30f5dd2-2c90-40b3-a678-b6a37c30a8bc",
			displayName: "BG-PC073",
			id: "63e16580-d17e-41d1-a507-5d60b5a845ce",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-20T12:30:17Z",
			manufacturer: null,
			deviceId: "66e99409-c360-48ac-a786-70d0aa268dca",
			displayName: "BG-PC066",
			id: "64046542-1f1e-4663-91c8-329192bc7ca0",
		},
		{
			registrationDateTime: "2018-07-12T07:02:38Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-18T11:38:22Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-05-24T14:17:47Z",
			manufacturer: null,
			deviceId: "b36304f1-c16b-4adc-8dd6-9c12e7653ad9",
			displayName: "arve2017",
			id: "64326d4b-b51e-4aee-a56b-c5d7ce57de8a",
		},
		{
			registrationDateTime: "2020-06-25T12:55:31Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "sm-vestby@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-18T11:16:03Z",
			operatingSystemVersion: "10.0.18363.0",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-06-25T12:55:31Z",
			manufacturer: null,
			deviceId: "047a80f7-8ece-40db-98e5-cb89b17ed1db",
			displayName: "VESTBY2019",
			id: "64f33965-dbc3-4db4-8c8d-b538aff03105",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "86a27189-8e32-4459-b3cf-2a28e11e055d",
			displayName: "annkarin2016",
			id: "65b0ed17-0f82-4d59-b4f7-630a5f4e17f4",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-12-04T10:12:08Z",
			operatingSystemVersion: "10.0.19044.2130",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-12-03T01:25:03Z",
			manufacturer: null,
			deviceId: "6735af4c-cba9-42c8-9910-96441a01228a",
			displayName: "borre2017",
			id: "65ce2770-d92c-4150-b004-592e15e336ff",
		},
		{
			registrationDateTime: "2022-05-20T11:10:12Z",
			model: "20UJ001RMX",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "anders.nessem@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-06T06:14:50Z",
			operatingSystemVersion: "10.0.22000.1219",
			isManaged: false,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-05-20T11:10:12Z",
			manufacturer: "LENOVO",
			deviceId: "fc959b83-6bf9-47ab-b23b-3784da9fddc1",
			displayName: "ANDERS-III",
			id: "685b406c-7afa-432c-a748-4662c033c30e",
		},
		{
			registrationDateTime: "2022-10-18T06:13:39Z",
			model: "20UJ0016MX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "trude.blekastad@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T06:25:14Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-01-15T08:19:18Z",
			manufacturer: "LENOVO",
			deviceId: "2cf20472-f2e5-4f09-afa5-58290210fc73",
			displayName: "DESKTOP-FG2GESS",
			id: "692bde83-854d-41e9-b963-a44d023e7204",
		},
		{
			registrationDateTime: "2020-03-12T08:58:03Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "kenneth.bjordal@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T07:02:16Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-03-12T08:58:03Z",
			manufacturer: "LENOVO",
			deviceId: "195a0c9e-2082-4673-a319-c97e79add675",
			displayName: "DESKTOP-ST70SAO",
			id: "6e47e59a-03d7-4d5f-96ec-b9c046b13855",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "5fe62cd8-0e04-4082-a151-707efc3c1539",
			displayName: "EspenBook2",
			id: "6ea52c7a-5e0e-4e54-9281-ee41bbd015a5",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-08-25T12:35:56Z",
			manufacturer: null,
			deviceId: "d7f9532f-8fca-4a09-8b1f-728b4ac21b23",
			displayName: "BG-PC043",
			id: "6ec17240-65f4-4f34-8b62-3582a9c6043b",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0 (17134)",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-08-30T08:14:06Z",
			manufacturer: null,
			deviceId: "c8337440-8580-4439-b66d-5726b8a8dcf1",
			displayName: "utlan01",
			id: "6fb45fbc-b116-4675-8cca-24464f07361e",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0 (17134)",
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2019-03-13T14:48:35Z",
			manufacturer: null,
			deviceId: "603fd5ad-dbf8-43c1-8557-27f460820353",
			displayName: "monoyn2014",
			id: "70b958ce-9456-459a-8482-c7bead12f55b",
		},
		{
			registrationDateTime: "2022-10-06T12:46:16Z",
			model: "20XW006WMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "Kristine.Thomsgard@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-22T23:04:37Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-08-31T03:41:47Z",
			manufacturer: "LENOVO",
			deviceId: "d8b480f8-dfd8-453b-8822-90072fbb9913",
			displayName: "KRISTINE-X1",
			id: "711a059a-ce80-44dd-bff3-4ae13e2d772b",
		},
		{
			registrationDateTime: "2021-03-12T11:05:37Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-17T12:02:17Z",
			operatingSystemVersion: "10.0.19044.1889",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-07-06T14:12:06Z",
			manufacturer: null,
			deviceId: "88708deb-119d-47c6-98fc-9f6d77084891",
			displayName: "BG-NB027",
			id: "7243aabd-514b-4169-b0a4-070342e14863",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-11-07T01:18:15Z",
			manufacturer: null,
			deviceId: "4c0e194b-0ed9-4ac8-8d7e-087600c7148f",
			displayName: "Tone2019",
			id: "73fb3d5c-f711-4ae9-9ce5-0e5061ea9043",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-09-16T00:30:50Z",
			manufacturer: null,
			deviceId: "88140d69-1e89-4e92-a8a2-42be2c3e9a2c",
			displayName: "charlotte2013",
			id: "75049648-8ad1-4605-ad30-db63db2d3432",
		},
		{
			registrationDateTime: "2020-03-24T20:01:52Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "sandra.gresslos@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-14T17:10:58Z",
			operatingSystemVersion: "10.0.18362.0",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-03-24T20:01:53Z",
			manufacturer: null,
			deviceId: "88085a64-31c8-45cc-a2f7-3e5703ad1d6d",
			displayName: "LAPTOP-5FNSTQ6O",
			id: "75def3f8-ecdf-4ad6-9709-2c789f9dc59b",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0.19044.2075",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-01-03T01:21:35Z",
			manufacturer: null,
			deviceId: "f5b424f4-2a50-4f8e-b1a9-72b750753301",
			displayName: "BG-NB033",
			id: "76f1fef3-f3a3-4f39-9c2b-eec65725bc53",
		},
		{
			registrationDateTime: "2020-04-07T11:35:47Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T13:13:19Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-04-01T10:38:59Z",
			manufacturer: null,
			deviceId: "923160c5-b8a3-43cb-8bf9-157aedbfd6ce",
			displayName: "BG-NB044",
			id: "7c398d1c-e160-466d-87ae-8cefd41d8eff",
		},
		{
			registrationDateTime: "2021-08-16T10:57:24Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "whuser1@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-28T08:05:47Z",
			operatingSystemVersion: "10.0.19043.1165",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-08-16T10:57:24Z",
			manufacturer: null,
			deviceId: "2bfcf79f-52bf-42f2-a054-36eb305beb5c",
			displayName: "BG-PC070",
			id: "7d6bc124-0699-4089-8997-a1ca01f24de7",
		},
		{
			registrationDateTime: "2018-07-25T07:02:16Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T15:14:28Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-06-13T11:08:33Z",
			manufacturer: null,
			deviceId: "d543464c-5575-4afe-b10d-e086829af8be",
			displayName: "BG-NB039",
			id: "7e9913e2-43e1-4aa8-b13f-663ea90b577f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "7e0c1615-543d-495d-82e6-ce91dd97d71a",
			displayName: "anne2017",
			id: "7efe5799-dce7-4057-8bf5-f2d1a5c1aa40",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2023-01-20T11:33:06Z",
			manufacturer: null,
			deviceId: "9d9bcb29-7db8-4f73-9b9a-f0495a998bce",
			displayName: "BG-NB049",
			id: "7fac8730-43b4-4a07-866b-65e9a45e5930",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-01-08T09:09:38Z",
			manufacturer: null,
			deviceId: "4c6b668d-d488-4f78-8c09-232522cff078",
			displayName: "KRISTINE-HPDEMO",
			id: "807eddcf-e5c5-45f8-8770-d6073aad07fa",
		},
		{
			registrationDateTime: "2022-02-19T12:31:18Z",
			model: "Latitude 7400",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "rikke.bjorkestol@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-19T13:22:10Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-02-19T12:31:18Z",
			manufacturer: "Dell Inc.",
			deviceId: "be9d6c43-f8e1-4525-bd36-7c32047e8a32",
			displayName: "DESKTOP-AOC98RG",
			id: "812c5838-1649-4057-b9c6-c93d8209aabb",
		},
		{
			registrationDateTime: "2022-09-26T12:11:18Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "ester.sigurdardottir@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T08:19:13Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-08-31T03:41:47Z",
			manufacturer: "LENOVO",
			deviceId: "d21e0668-6b46-4921-869b-1e9b4463d226",
			displayName: "DESKTOP-8UVA4GK",
			id: "81bd2f72-a9a7-4511-bbd7-ca8a14c92478",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "9ad85d99-10e8-4235-9549-013fa218a384",
			displayName: "anders2017",
			id: "827679ee-d411-4eba-b93c-a0df81a7352b",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-09T01:07:05Z",
			manufacturer: null,
			deviceId: "9d830ac1-bca1-4dc0-83c6-ccd7c19b5a16",
			displayName: "BG-NB032",
			id: "82d24461-f346-4afb-9ce4-746be6383cd6",
		},
		{
			registrationDateTime: "2023-01-06T09:02:22Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "sabrina.chehab@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T07:44:51Z",
			operatingSystemVersion: "10.0.22621.1105",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-09-22T11:18:28Z",
			manufacturer: "LENOVO",
			deviceId: "d1031ab2-d43c-4b70-995b-0fed740a6832",
			displayName: "DESKTOP-0VGJH3R",
			id: "8376cf16-49aa-4d47-9e64-658016e960b0",
		},
		{
			registrationDateTime: "2021-01-29T07:30:14Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "anne.eastwood@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Android",
			approximateLastSignInDateTime: "2023-01-17T15:00:55Z",
			operatingSystemVersion: "12",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-01-29T07:30:14Z",
			manufacturer: null,
			deviceId: "a9825544-0b6a-4e77-9a7b-dc825567089e",
			displayName: "samsungSM-G986B",
			id: "83d5d28b-eaca-4aec-8190-6ed9e0f3930e",
		},
		{
			registrationDateTime: "2022-11-14T12:23:32Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "petter.nessjoen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T07:20:17Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-08-03T09:57:00Z",
			manufacturer: "LENOVO",
			deviceId: "f962c74a-b729-4e90-a8ce-b78e0f19b823",
			displayName: "DESKTOP-LF3QKDN",
			id: "87c54be2-6952-4005-a91f-f843a9a1135c",
		},
		{
			registrationDateTime: "2020-11-09T10:00:56Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "erik.mellem@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2022-12-11T22:39:13Z",
			operatingSystemVersion: "14.2",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-11-09T10:00:56Z",
			manufacturer: null,
			deviceId: "ff20d6b0-812d-4459-a31a-371c763776be",
			displayName: "Erik’s iPhone",
			id: "895424d9-2d5b-4817-99b9-f3fb18d53625",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "b157e68c-b66c-4d8a-a4c2-6213c8accbe5",
			displayName: "kristine2011",
			id: "8978c2d9-71ea-4218-a269-95dc80fc1262",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "4ea3c9e1-f0db-4141-a913-4584cfb3e980",
			displayName: "glennwin10",
			id: "8b2fcac8-c105-4a7c-8342-1ce83ebbbb6d",
		},
		{
			registrationDateTime: "2020-05-13T08:32:20Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "anne.granerud@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T06:17:54Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-05-13T08:32:20Z",
			manufacturer: "LENOVO",
			deviceId: "fe92ea92-c865-41c6-b61a-1c5222c4b10f",
			displayName: "DESKTOP-EBOU3KQ",
			id: "8ba84106-c5dc-4fc4-8580-aff42ed8d0d0",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "dbffbfb0-05ed-494a-993b-fc5691f33ace",
			displayName: "UTLAN10$",
			id: "8bc35248-afaa-4b0f-847b-3059eab5d676",
		},
		{
			registrationDateTime: "2020-07-03T06:23:39Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Kristine.Thomsgard@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-27T21:29:44Z",
			operatingSystemVersion: "13.5.1",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-07-03T06:23:39Z",
			manufacturer: null,
			deviceId: "854a838c-6e12-4830-aa02-622f1828b95e",
			displayName: "Kristine",
			id: "8bfc080c-3a2a-4998-b549-f69dd850c808",
		},
		{
			registrationDateTime: "2019-11-26T08:27:13Z",
			model: "Latitude 7400",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "ann.karin@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-28T05:15:07Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2019-11-26T08:27:14Z",
			manufacturer: "Dell Inc.",
			deviceId: "a5334c38-2f0c-4a68-b97b-d8b9873d5c9d",
			displayName: "DESKTOP-PTT2ITE",
			id: "8d0e14f6-be26-48bf-a146-85ad66550df1",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-08-25T12:35:56Z",
			manufacturer: null,
			deviceId: "34f55f0a-7734-4097-80fa-a536fbec361d",
			displayName: "BG-PC056",
			id: "8d273600-8478-4ee4-b3a1-ec689ae454f1",
		},
		{
			registrationDateTime: "2021-11-01T08:11:30Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "dnt-kalender@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-11-06T17:38:39Z",
			operatingSystemVersion: "10.0.19043.1288",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-11-01T08:11:30Z",
			manufacturer: null,
			deviceId: "48b4e4f6-4617-4bc4-9bfd-997ecb169d53",
			displayName: "DNT-4040",
			id: "8fa7e2ae-b4d3-474b-835c-105c89ef2878",
		},
		{
			registrationDateTime: "2019-11-25T11:42:26Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-25T06:33:43Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-09-17T13:31:24Z",
			manufacturer: null,
			deviceId: "1ec7f91f-234a-4d69-bd20-b2a57ea5682e",
			displayName: "BG-PC046",
			id: "8fa8d564-704f-44a2-97d3-4860c6460032",
		},
		{
			registrationDateTime: "2023-01-17T13:13:48Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Britta.Wigger@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-17T13:13:48Z",
			operatingSystemVersion: "10.0.19045.2486",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2023-01-17T13:13:48Z",
			manufacturer: null,
			deviceId: "31bc8997-8af4-4ce5-9b64-d89794f91170",
			displayName: "BG-NB049",
			id: "9067e052-ee4c-4f16-98d9-04a1d906b95e",
		},
		{
			registrationDateTime: "2020-10-26T09:43:48Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "judith.winterl@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T07:44:59Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-10-26T09:43:48Z",
			manufacturer: "LENOVO",
			deviceId: "7df8755d-5848-46a6-b74e-89d17efd8693",
			displayName: "LAPTOP-8T899HPT",
			id: "90defdce-3479-4e7b-bb44-d77778b1cf2c",
		},
		{
			registrationDateTime: "2020-08-25T08:38:29Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "bjornhild.steingrimsen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T11:20:49Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-08-25T08:38:29Z",
			manufacturer: "LENOVO",
			deviceId: "9c317788-7cb3-417f-8e0b-2b5c77cd9b5e",
			displayName: "DESKTOP-08A5K0N",
			id: "913c60bc-02f1-4158-a8c4-68fd5f47fbef",
		},
		{
			registrationDateTime: "2021-05-04T11:05:07Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Christine.Schumacher@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-25T15:02:40Z",
			operatingSystemVersion: "10.0.19042.964",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-04T11:05:08Z",
			manufacturer: null,
			deviceId: "7b38cafb-bd85-416f-bb9f-972af5571de2",
			displayName: "BG-NB050",
			id: "91d8b69d-73df-458f-83c4-b170b9c53b1c",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-09-18T00:04:17Z",
			manufacturer: null,
			deviceId: "c77a7882-45f3-48e7-a46c-850378aeb316",
			displayName: "svein2016",
			id: "923be665-77bb-4ade-ac75-a37d707d8961",
		},
		{
			registrationDateTime: "2020-04-30T07:26:29Z",
			model: "20QF00AEMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "erik.mellem@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T13:05:40Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-04-30T07:26:29Z",
			manufacturer: "LENOVO",
			deviceId: "9120caec-861e-419f-9c85-f5e90e706801",
			displayName: "MELLEM-X1",
			id: "9286510d-a585-4539-b5b4-0f8d853f902c",
		},
		{
			registrationDateTime: "2022-11-21T06:39:21Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Christine.Schumacher@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-11-21T06:39:21Z",
			operatingSystemVersion: "10.0.19043.1165",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-11-21T06:39:21Z",
			manufacturer: null,
			deviceId: "783705e3-169a-4074-aa79-78d28faadbe6",
			displayName: "Christine-PC",
			id: "93031745-46a4-4b56-8b22-67eeef91c55f",
		},
		{
			registrationDateTime: "2022-08-31T03:41:47Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "AzureAd",
			operatingSystem: "Unknown",
			approximateLastSignInDateTime: "2022-08-31T03:41:47Z",
			operatingSystemVersion: "Unknown",
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2022-08-31T03:41:47Z",
			manufacturer: null,
			deviceId: "0794a020-851a-4dd6-9731-36e87df451df",
			displayName: "PC1CZAHS",
			id: "934159e0-3040-41da-9c75-33cab32209c3",
		},
		{
			registrationDateTime: "2022-01-11T08:39:07Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "anders.johannessen@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-17T17:49:07Z",
			operatingSystemVersion: "16.1.1",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-01-11T08:39:07Z",
			manufacturer: null,
			deviceId: "e82ac5d9-321b-4f1f-997f-533bbe7733f6",
			displayName: "iPhone 13 Pro",
			id: "9349a3e9-7bac-44ff-88a3-7016ea6f3af4",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-08-09T11:12:39Z",
			manufacturer: null,
			deviceId: "4cc069ab-3913-4380-8111-dcd8b701100c",
			displayName: "NO-PC0002$",
			id: "9495e04d-b5a1-469f-94b7-731e66bc30d2",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-11-18T01:23:23Z",
			manufacturer: null,
			deviceId: "c8857662-6c7d-4549-a089-a835d4752a64",
			displayName: "NO-PC0003$",
			id: "94c52b85-1eed-4ce9-9c1f-53b1bbc876d5",
		},
		{
			registrationDateTime: "2021-08-11T11:08:25Z",
			model: "20WE",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "susanne.lindborg@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T09:14:35Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-08-11T11:08:25Z",
			manufacturer: "LENOVO",
			deviceId: "667b56ee-3a67-4e32-af25-f3752cbec0fc",
			displayName: "DESKTOP-T5I7BAS",
			id: "9588842a-e6d1-487c-b0c2-d0bb63fdee72",
		},
		{
			registrationDateTime: "2022-05-12T09:41:10Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Andreas.Lantz@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T11:54:20Z",
			operatingSystemVersion: "10.0.19044.1682",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-05-12T09:41:10Z",
			manufacturer: null,
			deviceId: "b8e1560f-cbe9-4670-967e-e44ddd9d1989",
			displayName: "BG-NB059",
			id: "9589821b-3ee9-4b16-9484-357778d82876",
		},
		{
			registrationDateTime: "2020-04-02T10:10:33Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "monica.olstorn@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T08:17:40Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-04-02T10:10:33Z",
			manufacturer: "LENOVO",
			deviceId: "7e84558d-3435-491d-ac7a-ace8ef025596",
			displayName: "LAPTOP-MLVK9120",
			id: "95a72aec-04f7-4438-852c-175aa10a8125",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "e31f1856-b76e-4bfa-9ece-d4ef4a2dcbe0",
			displayName: "LIESL2019$",
			id: "95e317dc-2820-4ad2-ab0e-737ce313576e",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2023-01-20T11:33:06Z",
			manufacturer: null,
			deviceId: "c6638904-5be1-4a7a-8161-3991856fdb78",
			displayName: "BG-NB054",
			id: "96485f20-b89c-4a62-b6ec-2eb091bf1b6d",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-23T00:09:02Z",
			manufacturer: null,
			deviceId: "4f617bfd-fca3-41a1-919f-0f36ff5fd276",
			displayName: "SYSTUE2020$",
			id: "965c3ee1-51d1-40fd-a56d-c4173f8e5c2b",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-03-02T01:21:59Z",
			manufacturer: null,
			deviceId: "aca57435-7402-44ea-88bc-e71c23d6961e",
			displayName: "oystein2017",
			id: "96917f16-51c9-4b7f-9d59-a279c1dde65a",
		},
		{
			registrationDateTime: "2021-09-15T09:31:01Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Gizem.Karakoyun@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-24T15:29:18Z",
			operatingSystemVersion: "10.0.19042.1165",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-09-15T09:31:01Z",
			manufacturer: null,
			deviceId: "e02985a8-36d3-4364-a7f7-5c2cee6ca23a",
			displayName: "DIDEM-HP",
			id: "96a02fa8-1198-45e1-b39f-5cda6280c6de",
		},
		{
			registrationDateTime: "2022-06-08T10:56:40Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Anja.Brychcy@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T06:21:42Z",
			operatingSystemVersion: "10.0.19043.1706",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-06-08T10:56:40Z",
			manufacturer: null,
			deviceId: "d4514d26-b95c-4bdc-b521-c730a7ca9759",
			displayName: "BG-NB057",
			id: "96d2fd7a-4c94-4c1e-b6aa-8fc0476d4bac",
		},
		{
			registrationDateTime: "2021-01-25T15:11:26Z",
			model: "AHV",
			isCompliant: false,
			deviceOwnership: "Personal",
			registeredOwners: null,
			managementType: "MDM",
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T02:09:40Z",
			operatingSystemVersion: "10.0.19045.2546",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-01-25T15:09:01Z",
			manufacturer: "Nutanix",
			deviceId: "999f02a0-5d5c-401d-937b-1d55c47861ae",
			displayName: "BG-SERVICEW10",
			id: "97a859cf-eb9e-4845-9602-c5472adde176",
		},
		{
			registrationDateTime: "2021-05-04T07:36:26Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-09-12T12:03:45Z",
			operatingSystemVersion: "10.0.19044.1645",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-01-27T14:45:23Z",
			manufacturer: null,
			deviceId: "41e06418-2d21-445e-a38b-bce8f864451a",
			displayName: "BG-NB034",
			id: "9ac1ae18-c309-4c05-818a-c55a70e416ce",
		},
		{
			registrationDateTime: "2021-11-25T10:07:05Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "nico.bakker@bergans.com",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T13:03:14Z",
			operatingSystemVersion: "10.0.19043.1348",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-11-25T10:07:05Z",
			manufacturer: null,
			deviceId: "e165d34a-dd3c-4778-9810-4c197c1bfe65",
			displayName: "BG-BNL-NB001",
			id: "9ac21d78-4669-426d-95c3-f206253951ef",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-11-10T15:35:01Z",
			manufacturer: null,
			deviceId: "7794d83b-cb83-4c9e-b47b-6d1a26bb8026",
			displayName: "BG-TEST-W10",
			id: "9c3c43a4-ca77-482a-83fc-49cc4452bfef",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-12-13T01:03:36Z",
			manufacturer: null,
			deviceId: "2525a19e-1de5-4715-ac35-abdcb2705054",
			displayName: "JARLE2016L$",
			id: "9c5dede5-4380-4605-a7df-503526621a39",
		},
		{
			registrationDateTime: "2022-08-23T07:49:20Z",
			model: "Latitude 7480",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "simen.skarra@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-11-16T07:47:19Z",
			operatingSystemVersion: "10.0.19043.928",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-12-10T08:23:58Z",
			manufacturer: "Dell Inc.",
			deviceId: "367035d9-11c5-430d-80dd-18a00acb0ead",
			displayName: "DESKTOP-RUP96EE",
			id: "9c6f2762-a6b4-464b-b782-f0c7e9aec9c6",
		},
		{
			registrationDateTime: "2022-04-08T06:27:02Z",
			model: "20UJ001RMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "rikke.karlsen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T09:29:29Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-04-08T06:27:02Z",
			manufacturer: "LENOVO",
			deviceId: "27f2246f-829d-4561-95ad-3692f7fdeca1",
			displayName: "LAPTOP-FHUHJ8V8",
			id: "9d4822fd-0888-4a17-81fa-736730504b5c",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "285b1681-ff5f-414a-b028-e8cc46fc4ea9",
			displayName: "EJ2019",
			id: "9fecc952-eb6e-4315-a6a1-c939584e56e4",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "1afad1ae-6a47-4b92-adc9-c96007b91af6",
			displayName: "GURO2019",
			id: "9ff85a08-e5a4-4e2c-9aee-a860057adbb1",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-04-26T00:30:01Z",
			manufacturer: null,
			deviceId: "7180e9e4-21e1-4b0a-afd9-9511170e8c09",
			displayName: "PETTER2017$",
			id: "9fff552b-1b99-4651-81e5-03bde8294334",
		},
		{
			registrationDateTime: "2019-10-31T09:05:05Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-18T15:39:44Z",
			operatingSystemVersion: "10.0.19045.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-10-30T13:30:08Z",
			manufacturer: null,
			deviceId: "fdba184a-7339-4f37-ba04-3fa088406d9c",
			displayName: "Barkarby112019",
			id: "a19b0765-5910-4515-836b-1c1399395a75",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-08T13:06:38Z",
			manufacturer: null,
			deviceId: "4172e992-67d5-45f7-9ed3-f2ed624929b1",
			displayName: "BG-PC071",
			id: "a2269492-666d-49d7-98ff-efe7e04b229a",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-06-29T13:34:13Z",
			manufacturer: null,
			deviceId: "1c36f86a-3d15-4d6c-ad69-1e5f3ebd773b",
			displayName: "BG-PC042",
			id: "a2aeab63-7fb7-47a9-990d-a0cd8eaa27ed",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-08T13:06:38Z",
			manufacturer: null,
			deviceId: "d25d1347-d748-4e89-ad5a-3d03d5a75123",
			displayName: "BG-NB057",
			id: "a370bf8b-6cc0-452a-ae10-2a79d8bf448e",
		},
		{
			registrationDateTime: "2019-11-08T09:43:38Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "guro.roang@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-25T12:03:00Z",
			operatingSystemVersion: "10.0.18362.0",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-11-08T09:43:39Z",
			manufacturer: null,
			deviceId: "e942d845-4010-4ba9-ae3e-8425aeaed1ac",
			displayName: "GURO2019-1",
			id: "a3f1f5f6-3258-4499-a740-d7adfad5829c",
		},
		{
			registrationDateTime: "2022-10-24T12:11:01Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "mai.liss@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T06:25:36Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-10-21T11:19:42Z",
			manufacturer: "LENOVO",
			deviceId: "3812a70a-1100-4023-8166-bbe57fc0536a",
			displayName: "DESKTOP-3KOBN8S",
			id: "a401d80a-b7cf-41b1-b8b0-a39b82bdee50",
		},
		{
			registrationDateTime: "2019-08-19T06:44:05Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T06:01:40Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-07-08T17:19:24Z",
			manufacturer: null,
			deviceId: "416f1ce8-fecd-4893-8c48-55744f77cffc",
			displayName: "NO-PC0004",
			id: "a40799fe-5699-4259-b0fa-52d1858d14af",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "fc359077-dedd-4a9e-ac7e-626f5d7d1f12",
			displayName: "ingunn2013",
			id: "a4e75ae5-4c7c-4f92-a94c-27edae22ebc2",
		},
		{
			registrationDateTime: "2023-01-12T12:10:15Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "storemanager.ski@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T16:38:56Z",
			operatingSystemVersion: "10.0.22621.963",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2023-01-12T12:10:15Z",
			manufacturer: null,
			deviceId: "878302a7-e695-4893-8556-d452c311dfc7",
			displayName: "Razor",
			id: "a6254674-41b9-42cf-9664-1e2c20faee93",
		},
		{
			registrationDateTime: "2019-09-25T13:09:54Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T05:01:20Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-09-25T10:36:54Z",
			manufacturer: null,
			deviceId: "4d0068e0-ecec-4834-9961-c41b69534d47",
			displayName: "BG-PC049",
			id: "a688ab8b-b6a6-42a9-ac40-07bc525815fa",
		},
		{
			registrationDateTime: "2021-03-12T09:38:41Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "storemanager.tromso@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-20T08:36:54Z",
			operatingSystemVersion: "10.0.19042.1110",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-03-12T09:38:41Z",
			manufacturer: null,
			deviceId: "7131701a-91f6-4c35-8a1d-930c2fc401c2",
			displayName: "NO-PC0003",
			id: "a6bf2765-fe2f-486f-a74c-a1d8568f1d62",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "649d6e95-7a4e-4598-b476-8140a9f52669",
			displayName: "tore2016",
			id: "a74fa314-74f1-4045-acca-e820dc1fbcae",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-01-03T01:17:15Z",
			manufacturer: null,
			deviceId: "33404346-4488-4847-bc1a-a1ee221b2d8a",
			displayName: "HANS2015",
			id: "a75a3740-79d1-47b6-9c48-9786f15376f0",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "ccd5fb9c-8823-4731-bbfd-1078282f1962",
			displayName: "NO-PC0001",
			id: "a9f73e25-1b0d-41de-aabe-d61716356b1a",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-10-06T00:24:57Z",
			manufacturer: null,
			deviceId: "2ef3faa3-31a4-4159-bb71-b753c2f57a9a",
			displayName: "BG-NB018",
			id: "aa45e110-abc7-46d8-aa4f-70c6059dc07d",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "6c0eced4-9ca5-4208-9dde-1bbbc29b9b4b",
			displayName: "JANTORE2018$",
			id: "aa7a4de4-02de-417d-af9a-6147d038d2ef",
		},
		{
			registrationDateTime: "2022-07-06T07:03:36Z",
			model: "20QJ001GMX",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "mai.liss@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-08-17T04:53:48Z",
			operatingSystemVersion: "10.0.22000.739",
			isManaged: false,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-07-06T07:03:36Z",
			manufacturer: "LENOVO",
			deviceId: "f5b8c372-aa33-4c14-96b0-16c17d910f72",
			displayName: "DESKTOP-65BL265",
			id: "abc37fd4-7b7b-4e32-a528-9e0e7adf6c32",
		},
		{
			registrationDateTime: "2020-10-12T07:26:54Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "storemanager.bergen@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-24T17:09:43Z",
			operatingSystemVersion: "10.0.19041.508",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-10-12T07:26:54Z",
			manufacturer: null,
			deviceId: "70bdde9b-c668-4e9e-8886-15865831758d",
			displayName: "BERGEN-PC",
			id: "acdede07-727d-422e-89ad-e65ca7a7c089",
		},
		{
			registrationDateTime: "2018-11-27T06:20:03Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-16T13:31:56Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-07-11T12:15:19Z",
			manufacturer: null,
			deviceId: "9505f430-db3b-4e1a-b198-bba7196470e3",
			displayName: "BG-PC065",
			id: "ad1a974a-33e4-4920-83d7-f953d5e1089f",
		},
		{
			registrationDateTime: "2022-01-27T15:15:46Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "rikke.bjorkestol@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-18T15:39:53Z",
			operatingSystemVersion: "10.0.19041.1415",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-01-27T15:15:46Z",
			manufacturer: null,
			deviceId: "e0f1de9e-3db3-41db-a705-a6a66a4647f7",
			displayName: "algard2021",
			id: "ad3b7e8e-56d8-4f71-968e-a56aeaaf0b72",
		},
		{
			registrationDateTime: "2021-10-24T16:38:05Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "marcus.jernberg@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-07T15:47:37Z",
			operatingSystemVersion: "10.0.19042.1110",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-10-24T16:38:05Z",
			manufacturer: null,
			deviceId: "00678386-3656-4670-8aa8-7ee4401147b4",
			displayName: "TanjaLaptop",
			id: "ad506179-1c4c-4bf5-aaf4-750459655ca4",
		},
		{
			registrationDateTime: "2022-02-28T12:40:18Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Anne.Eggers@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-26T13:43:46Z",
			operatingSystemVersion: "16.1.1",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-02-28T12:40:18Z",
			manufacturer: null,
			deviceId: "d449f8da-c910-4bac-82e4-4c4eb63e0f33",
			displayName: "iPhone 13",
			id: "aeb5aa4b-0e5c-4917-89c3-ac97372dff9b",
		},
		{
			registrationDateTime: "2019-03-26T14:24:38Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T08:03:59Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-03-21T12:08:20Z",
			manufacturer: null,
			deviceId: "c2d5cbaa-7a87-4aac-b630-f81624cc7b85",
			displayName: "BG-PC045",
			id: "aedacabd-5e1a-4aa1-88f4-065aa139fb80",
		},
		{
			registrationDateTime: "2019-01-24T08:23:33Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-18T09:48:31Z",
			operatingSystemVersion: "10.0.19045.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-01-08T10:26:12Z",
			manufacturer: null,
			deviceId: "0fb0c039-afc8-4c5a-bfc6-2cfdb56454df",
			displayName: "BG-NB040",
			id: "b00efdf5-1f25-492b-a1a0-cc74cc7f1fe9",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2021-11-26T01:17:55Z",
			manufacturer: null,
			deviceId: "61ac635a-71ac-4d65-9558-c84c3fdc1672",
			displayName: "SUSANNE2018$",
			id: "b0754a17-357a-4bde-81e1-3b13a3acb7eb",
		},
		{
			registrationDateTime: "2021-06-21T20:42:19Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "espen.riktor@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-18T06:13:11Z",
			operatingSystemVersion: "10.0.19041.985",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-06-21T20:42:19Z",
			manufacturer: null,
			deviceId: "cf45f5ee-5a25-49a1-a5c0-fe03184816d2",
			displayName: "LAPTOP-HM031EG7",
			id: "b13f00af-4c4e-4226-96c3-8705d3f5ff2c",
		},
		{
			registrationDateTime: "2019-11-25T11:27:12Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T10:47:59Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-06-27T11:11:54Z",
			manufacturer: null,
			deviceId: "6baa9451-806d-46b6-aff6-4f55c5d1defb",
			displayName: "BG-NB043",
			id: "b3b8fe74-7736-4877-b9f5-1975170a9534",
		},
		{
			registrationDateTime: "2022-10-10T10:54:51Z",
			model: "20NX0054MX",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "adm-emil.eggen@bergansofnorway.com",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-10-10T08:44:19Z",
			operatingSystemVersion: "10.0.22000.978",
			isManaged: false,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-10-10T08:44:19Z",
			manufacturer: "LENOVO",
			deviceId: "b535b336-beaa-412d-9091-78cbd6782556",
			displayName: "BON-PC18RTW5",
			id: "b46e62c6-dc7c-4ef7-8d7a-1b3dc1ca5b5b",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "86291f03-6224-4b4f-83e7-88b7cee84e17",
			displayName: "marthe2014",
			id: "b4cb8e56-87e5-4876-9eee-28bfc1d11a14",
		},
		{
			registrationDateTime: "2021-10-12T17:45:45Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "anders.nessem@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-12-15T03:00:00Z",
			operatingSystemVersion: "10.0.22000.675",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-10-12T17:45:45Z",
			manufacturer: "LENOVO",
			deviceId: "699cef8b-2d95-4c6d-a80a-6c9feef27d93",
			displayName: "DESKTOP-34NCQP7",
			id: "b4e2ae86-0286-465f-b8d8-a9dfed405a55",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-02-08T01:07:27Z",
			manufacturer: null,
			deviceId: "61aedccd-e572-4c37-aff7-2aae390deb1a",
			displayName: "KENNETH2019$",
			id: "b4e939b9-b46f-46dc-a29c-d359745d278f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "7e6183ca-1ea3-448c-bfab-02373a38d025",
			displayName: "JON2014$",
			id: "b4eae1e7-24c9-4deb-8f90-50b158d9f12a",
		},
		{
			registrationDateTime: "2022-02-28T08:40:53Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Ben.Gutscheck@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T23:34:05Z",
			operatingSystemVersion: "10.0.19044.1586",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-02-28T08:40:53Z",
			manufacturer: null,
			deviceId: "6569495c-4131-469d-b03a-33fdd6d09674",
			displayName: "BG-NB052",
			id: "b80f4ede-2072-4af9-9628-6eafc2b38502",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-25T16:11:34Z",
			operatingSystemVersion: "10.0.19045.2486",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-06-13T00:18:15Z",
			manufacturer: null,
			deviceId: "229e3eef-bf8e-458c-87b2-29b727e49fe1",
			displayName: "BG-NB030",
			id: "b85c8811-abfe-41e1-8e1b-8957bfa08ed0",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "6e3e3837-8e85-4b47-a044-a8f074f91080",
			displayName: "vm-win10-01",
			id: "b900cd81-4115-4308-acff-32389670f364",
		},
		{
			registrationDateTime: "2022-08-09T11:58:49Z",
			model: "20QJ001GMX",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "holly.burns@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-12-14T14:51:39Z",
			operatingSystemVersion: "10.0.22000.1335",
			isManaged: false,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-08-07T07:51:38Z",
			manufacturer: "LENOVO",
			deviceId: "985724f8-b468-45a4-8981-ea2a290b7e02",
			displayName: "DESKTOP-ECCTOKF",
			id: "ba36cb37-80bc-48b4-843c-175242e08543",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-03-31T12:08:11Z",
			manufacturer: null,
			deviceId: "7444fcd0-b7bb-49d1-b906-f6b6f33cf5ac",
			displayName: "BERGEN-PC",
			id: "baa252a6-0da8-451a-9830-9b9871b6805c",
		},
		{
			registrationDateTime: "2018-11-05T13:03:54Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "sm-flagshipstore@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T12:15:40Z",
			operatingSystemVersion: "10.0.17134.0",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-11-05T13:03:55Z",
			manufacturer: null,
			deviceId: "508c1aaa-abaa-4bcd-adbe-cd823b0ce07f",
			displayName: "roar2016",
			id: "bba2b44f-3f14-4119-a802-ae270feec042",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-02-02T13:32:50Z",
			manufacturer: null,
			deviceId: "54612ff7-d917-431f-808a-c8280d158a4a",
			displayName: "BG-PC058",
			id: "bbadfb44-5fbe-4261-8215-e54c9461029d",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-08-25T12:35:56Z",
			manufacturer: null,
			deviceId: "52b04c97-ca7b-4574-9a97-23ccd618bd3b",
			displayName: "BG-PC072",
			id: "bcb58fdd-11dd-41a1-9c2a-24b1551e0ec4",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "4489b299-f418-4ebf-b14a-97e1953ce2df",
			displayName: "Resep-PC2019",
			id: "be1dbd76-e1eb-4c23-9e4a-e549f3cb94fb",
		},
		{
			registrationDateTime: "2020-09-29T12:04:24Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "bjorn.sekkeseter@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T14:29:07Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-09-29T12:04:24Z",
			manufacturer: "LENOVO",
			deviceId: "d0b0ca47-fd95-4464-a5fe-7f0fc177d210",
			displayName: "SEKKIS",
			id: "be640561-859a-4e52-b8ca-7a4d5c222d9c",
		},
		{
			registrationDateTime: "2020-06-04T06:59:33Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "Einar.Astad@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T09:24:33Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-06-04T06:59:33Z",
			manufacturer: "LENOVO",
			deviceId: "71e4fd14-e2c9-4de9-8201-d263c613c785",
			displayName: "LAPTOP-TEIB146S",
			id: "bf6b84c7-c902-4fe4-9e6b-f1adc20e49a3",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "6ec29ad2-f4c1-4f20-8400-7d408cf2e583",
			displayName: "ANETTE2016$",
			id: "bfb5c5ad-fea0-4521-a839-4f59b123c5cf",
		},
		{
			registrationDateTime: "2019-08-14T10:24:47Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-12T15:38:45Z",
			operatingSystemVersion: "10.0.19045.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-08-07T12:05:39Z",
			manufacturer: null,
			deviceId: "3957f9e1-dabd-4a60-8f75-648a30027737",
			displayName: "BG-PC047",
			id: "c05ee5bf-cfe6-480c-9cb0-fe7612be5bce",
		},
		{
			registrationDateTime: "2020-06-29T09:51:37Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "charlotte.eriksen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T20:10:37Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-06-29T09:51:37Z",
			manufacturer: "LENOVO",
			deviceId: "93950ada-f7af-4204-8f6a-2fd94bea1944",
			displayName: "DESKTOP-D4BRPT6",
			id: "c1a632c1-0f0b-4df9-ad7b-669f2d9763ce",
		},
		{
			registrationDateTime: "2023-01-30T12:00:56Z",
			model: "20L7001HMX",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "jarle.nereng@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T12:00:56Z",
			operatingSystemVersion: "10.0.22621.1105",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2023-01-30T12:00:56Z",
			manufacturer: "LENOVO",
			deviceId: "afc306c8-01e7-43cc-a01a-8b271c584804",
			displayName: "DESKTOP-CM5N6QB",
			id: "c2aaa922-c120-49b7-8b21-1dc77014d657",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-01-13T01:28:15Z",
			manufacturer: null,
			deviceId: "c9463a6a-3caa-4700-8b0f-8e01a32221dd",
			displayName: "bakromlangevag",
			id: "c3409d87-dd25-41c3-b1b6-b9c1117a48ab",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-11-28T09:25:56Z",
			manufacturer: null,
			deviceId: "1114cddb-1357-4947-ab2e-f6b4aa3e3e71",
			displayName: "HR112019",
			id: "c3df1822-aefd-4148-8c6d-eb19095338f9",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2022-05-11T00:13:11Z",
			manufacturer: null,
			deviceId: "d22cb871-4d52-48e0-93b0-62b2c176f5b2",
			displayName: "bjorn2017",
			id: "c412d215-dabd-45f2-ae8a-daa945572099",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "4de5a142-ee43-4101-8e84-619591201332",
			displayName: "RESERVE2014$",
			id: "c4c3fe9e-1121-4623-835f-6e67d884a3f9",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "6a51f7b6-032a-4aa9-9f5b-f1fc0986518e",
			displayName: "STEINERIK2017$",
			id: "c56f1580-2634-4c36-8a08-76d0d2965bac",
		},
		{
			registrationDateTime: "2022-04-07T11:36:11Z",
			model: "20UJ001RMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "arne.sandvik@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T17:25:02Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-04-07T11:36:11Z",
			manufacturer: "LENOVO",
			deviceId: "bad1c935-4273-4ec7-a960-3d7fbf45750d",
			displayName: "LAPTOP-JR5RHT2M",
			id: "c6c3cffd-6921-41cb-b53f-ab4bcf76a70f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-08T13:06:38Z",
			manufacturer: null,
			deviceId: "fc3b7b8c-dc7c-4a0f-a5da-4d0164960827",
			displayName: "BG-NB056",
			id: "c70c871f-75e9-463c-8a06-7fceab5335b4",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0 (17134)",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-07-24T10:26:36Z",
			manufacturer: null,
			deviceId: "197496c6-fb32-4475-aa2c-7859b8c030d2",
			displayName: "EKSTRAKASSE$",
			id: "c749f556-fbb6-433b-b2ee-30d542bec5d4",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0 (17134)",
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2019-01-11T09:30:43Z",
			manufacturer: null,
			deviceId: "335b417a-867e-4d3c-b4e3-8bdfc86a27bc",
			displayName: "trude2014",
			id: "c98c7372-b2ff-405f-833d-0c93a527f407",
		},
		{
			registrationDateTime: "2022-09-19T06:06:52Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "oystein.warvik@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-24T11:31:35Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-07-13T10:10:48Z",
			manufacturer: "LENOVO",
			deviceId: "5f61db8f-432d-492a-baaa-e8fd18a586e5",
			displayName: "DESKTOP-128GQ1E",
			id: "caddceb2-9ead-483c-83e5-d592fa217c79",
		},
		{
			registrationDateTime: "2019-06-21T01:16:28Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T11:05:31Z",
			operatingSystemVersion: "10.0 (14393)",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-07-11T13:15:22Z",
			manufacturer: null,
			deviceId: "d3fed488-8282-423e-87e2-12ce8501539c",
			displayName: "BergDE-01",
			id: "cafc5813-7b98-4fa4-9fb2-79e6d82ed012",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "94de1532-5cb8-4d52-a4a1-b5fb285360f7",
			displayName: "TEP-PC",
			id: "cba99eec-90cb-44d8-acda-b1f5ee944a64",
		},
		{
			registrationDateTime: "2020-06-11T12:15:14Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "vestout@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-17T17:27:35Z",
			operatingSystemVersion: "10.0.18363.0",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-06-11T12:15:15Z",
			manufacturer: null,
			deviceId: "95209a5b-d22f-46e6-8109-9d0d7159ec77",
			displayName: "VESTBY2019",
			id: "ccc5d1de-50f4-4bee-a424-f048cbd0d087",
		},
		{
			registrationDateTime: "2020-06-09T11:10:23Z",
			model: "Latitude 7400",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "karen.strubreiter@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T05:55:14Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-06-09T11:10:23Z",
			manufacturer: "Dell Inc.",
			deviceId: "16180c35-549c-4313-8e9e-63e9072855fc",
			displayName: "DESKTOP-EC2SMQI",
			id: "cd866d36-280e-488f-8d3d-b5a305411de2",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "5c7f4f66-1aa4-4e2d-a48a-eccda8bc03e2",
			displayName: "ULLA2014$",
			id: "cdaea35d-b558-4499-bafd-cb40c5daeb57",
		},
		{
			registrationDateTime: "2019-06-21T05:01:51Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-19T23:10:54Z",
			operatingSystemVersion: "10.0 (14393)",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-07-11T13:15:22Z",
			manufacturer: null,
			deviceId: "d038bfe3-75de-4eea-a90f-2aa9cfb5db41",
			displayName: "SWYXHH02",
			id: "ce1cac60-2754-4f33-b49d-4bc90b877eca",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-10-09T00:15:07Z",
			manufacturer: null,
			deviceId: "5a824571-55c0-425a-959e-dbd40eb16c4c",
			displayName: "JMH2018",
			id: "ce9f635d-e49a-4850-ac6d-50a891cc42df",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-06-30T08:36:38Z",
			manufacturer: null,
			deviceId: "79281b84-fe40-45a0-9f4b-04a7a8d72587",
			displayName: "terje2018",
			id: "cee48c60-a4b8-4d3a-9be0-67bdeeb20f8f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "7a9a3bb0-8f45-44e7-b068-33c642f64497",
			displayName: "HOLO2018$",
			id: "cf230f51-100e-4aa5-80f4-f8b3964d816a",
		},
		{
			registrationDateTime: "2021-04-26T11:45:34Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-13T10:01:05Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-02-19T14:32:10Z",
			manufacturer: null,
			deviceId: "f519e39b-8bc6-4717-9047-89b29c9d331f",
			displayName: "BG-NB019",
			id: "cfb8d306-1f9c-4800-8830-485cde295144",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-01-28T01:15:51Z",
			manufacturer: null,
			deviceId: "9cc5ad37-a39e-47f5-ba1b-01c147c6e7d3",
			displayName: "JANNE2016$",
			id: "d06117e7-6647-4204-8510-5670dea28760",
		},
		{
			registrationDateTime: "2020-10-21T12:56:11Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "marianne.stensby@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T18:30:55Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-10-21T12:56:11Z",
			manufacturer: "LENOVO",
			deviceId: "ee7eb901-cb43-49b6-89f6-8abf7d954988",
			displayName: "LAPTOP-JIA8BSJO",
			id: "d06ec3e2-1fa5-4f8c-b085-f8c0e73a08ac",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2023-01-20T11:33:06Z",
			manufacturer: null,
			deviceId: "ac7924d9-4cee-41dc-84f4-6c64ebae776f",
			displayName: "BG-NB059",
			id: "d19ba6d9-b898-46be-9725-86562e3c0803",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-08T13:06:38Z",
			manufacturer: null,
			deviceId: "31031d2e-3df4-4cd5-a331-f4ebbc3c5bd0",
			displayName: "BG-PC070",
			id: "d2e9540b-3c12-465d-90e5-dc38053fad6f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0.19045.2364",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-20T00:25:30Z",
			manufacturer: null,
			deviceId: "14f5331b-fd0c-4554-a61d-ce3d41fc7e69",
			displayName: "FITTINGROOM$",
			id: "d3a4fdf1-3c12-4da9-930a-174205a5ab29",
		},
		{
			registrationDateTime: "2022-08-31T03:41:47Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "AzureAd",
			operatingSystem: "Unknown",
			approximateLastSignInDateTime: "2022-08-31T03:41:47Z",
			operatingSystemVersion: "Unknown",
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2022-08-31T03:41:47Z",
			manufacturer: null,
			deviceId: "56ae91e9-16bd-474c-9ad7-b390e080baf9",
			displayName: "JCZLJM2",
			id: "d3ef6039-f258-49c1-8a30-602ab4ec83d5",
		},
		{
			registrationDateTime: "2021-05-21T07:35:23Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Ludmilla.Knaub@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T08:32:08Z",
			operatingSystemVersion: "10.0.19042.985",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-21T07:35:23Z",
			manufacturer: null,
			deviceId: "2346da4f-f354-4833-a43e-f843d4eaeeab",
			displayName: "BG-NB051",
			id: "d4489052-20d6-4834-ae84-dc688d249dab",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-11-27T11:18:16Z",
			manufacturer: null,
			deviceId: "ee8c8b62-510b-4fd6-9c34-ed5c0af544df",
			displayName: "BG-PC051",
			id: "d5595c4d-2664-4e1b-99c1-affb05865867",
		},
		{
			registrationDateTime: "2020-09-22T06:49:51Z",
			model: "20QD00KTMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "simen.skarra@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T06:37:27Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-09-22T06:49:51Z",
			manufacturer: "LENOVO",
			deviceId: "174742e7-9d1b-4291-b0db-d0e0d88843f4",
			displayName: "DESKTOP-4QF8BCN",
			id: "d66c1fc2-d862-423f-a63f-9ae3e7b16270",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-04-28T00:31:43Z",
			manufacturer: null,
			deviceId: "a62a2478-13de-4ba3-9265-a1c71effac0d",
			displayName: "LISBETH2019$",
			id: "d7ce3019-ae90-4ce2-946c-8e1d9232fd08",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-04T11:12:20Z",
			manufacturer: null,
			deviceId: "d833800d-392c-4000-9dd6-66c0774a0427",
			displayName: "BG-NB050",
			id: "d8044f0c-f325-449a-93f1-46aa4cfe2350",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-02-23T01:04:46Z",
			manufacturer: null,
			deviceId: "1488e5d6-bbf0-4fa9-b388-c84de1e3a67b",
			displayName: "BG-NB036",
			id: "d8b63452-c9b2-408b-bb73-78ee3cd9a5ae",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-09-02T00:23:02Z",
			manufacturer: null,
			deviceId: "90aa4a09-4cd2-45d6-ae94-ec2d84174779",
			displayName: "BG-NB028",
			id: "d96ff653-5c12-4395-8cbd-0f5816b72943",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "bfe7b537-3594-47ed-876a-246b8564cbce",
			displayName: "mette2016",
			id: "d9b5540e-8669-4ee4-8326-c06e30d2eadb",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-04-27T09:31:09Z",
			manufacturer: null,
			deviceId: "f0b572a9-bbdc-44f4-9cb1-423a41ff67b9",
			displayName: "BG-NB058",
			id: "da895264-fdff-405f-bb74-cba53da80766",
		},
		{
			registrationDateTime: "2020-04-28T07:16:14Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "anne.myrvang@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-24T12:34:02Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-04-28T07:16:14Z",
			manufacturer: "LENOVO",
			deviceId: "0fa721f8-7f74-4435-8819-8b5f6d551ca5",
			displayName: "LAPTOP-1E7MFC2A",
			id: "db0c5c07-d408-4f16-a8af-0323c11a7e7f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2021-10-04T00:12:15Z",
			manufacturer: null,
			deviceId: "66bf42a0-3e52-4840-bd7d-a84effa212e8",
			displayName: "sandra2017",
			id: "db49ef0b-acff-44a3-9d33-0e6b7ecb71b1",
		},
		{
			registrationDateTime: "2020-08-12T07:45:34Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "svein.jorgensen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-25T06:31:40Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-08-12T07:45:35Z",
			manufacturer: "LENOVO",
			deviceId: "622e38cb-f787-49c9-b92b-642e2b47800f",
			displayName: "LAPTOP-24JKH365",
			id: "db91a644-27a1-4bc1-9a9d-34a7452ec7b0",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-08-25T12:35:56Z",
			manufacturer: null,
			deviceId: "c54ee746-223d-42cd-92f7-5f398aa436b5",
			displayName: "BG-PC044",
			id: "ddcc1d7a-e2d6-43d9-9c2f-2ef392cfd810",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-09-16T00:30:50Z",
			manufacturer: null,
			deviceId: "7e46fb80-234d-4b63-b3a3-d623f60295bc",
			displayName: "RESEPSJON01$",
			id: "de2c7a27-81d6-4114-a65a-18b802804de9",
		},
		{
			registrationDateTime: "2018-07-16T13:50:33Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-12T10:00:24Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-06-11T07:35:00Z",
			manufacturer: null,
			deviceId: "67c19665-4511-4990-96e2-ccfb673ff86f",
			displayName: "bakromski",
			id: "de436048-9905-4802-9f04-e749b8d0487f",
		},
		{
			registrationDateTime: "2020-09-30T09:42:13Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-11-12T08:00:00Z",
			operatingSystemVersion: "10.0.18363.0",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-09-30T09:42:13Z",
			manufacturer: null,
			deviceId: "18aac0e7-e4c6-4970-968e-720865240f4f",
			displayName: "CLI-GLENN-ADM",
			id: "de4deee6-0840-4383-a9c7-df856d808562",
		},
		{
			registrationDateTime: "2021-01-07T08:10:31Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "jon.gregertsen@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-27T22:44:19Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-01-07T08:10:31Z",
			manufacturer: "LENOVO",
			deviceId: "491a772a-9a48-4262-881a-b8a71ae695a8",
			displayName: "LAPTOP-7OVDQBB2",
			id: "dff26e48-7c7d-436c-92b3-18d8fbc94747",
		},
		{
			registrationDateTime: "2022-08-31T03:41:47Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "AzureAd",
			operatingSystem: "Unknown",
			approximateLastSignInDateTime: "2022-08-31T03:41:47Z",
			operatingSystemVersion: "Unknown",
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2022-08-31T03:41:47Z",
			manufacturer: null,
			deviceId: "7cac5294-2c34-4883-ba92-5c7a2e57ff0a",
			displayName: "R90J2AED",
			id: "e1199266-4ff1-4a2e-bda7-99eb4b3cb0b8",
		},
		{
			registrationDateTime: "2021-07-15T12:15:01Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Verena.Stuewe@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-28T10:49:37Z",
			operatingSystemVersion: "10.0.19042.1083",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-07-15T12:15:01Z",
			manufacturer: null,
			deviceId: "51df5da3-6e1e-4d1b-a987-a7e4e453d9fb",
			displayName: "Toshiba2",
			id: "e1c0648c-8023-46f2-8401-2e914f6901d4",
		},
		{
			registrationDateTime: "2022-04-27T13:03:14Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Tatjana.Knopp@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T12:02:37Z",
			operatingSystemVersion: "10.0.19043.1586",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-04-27T13:03:14Z",
			manufacturer: null,
			deviceId: "d3dbd222-6b32-4f85-9368-d26444485a33",
			displayName: "BG-NB056",
			id: "e2666478-9fc1-49b7-b573-f98b713d2360",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-08-09T11:12:39Z",
			manufacturer: null,
			deviceId: "3c2ed684-d0a8-4dd9-ab0b-a750794a1ec9",
			displayName: "BG-PC053",
			id: "e31902cd-cbf4-4897-a46e-fa378605696b",
		},
		{
			registrationDateTime: "2019-12-04T14:15:40Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-17T12:22:54Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-12-04T10:31:41Z",
			manufacturer: null,
			deviceId: "ba39f54a-9829-48eb-9c1d-b33681eaf2d0",
			displayName: "BG-NB031",
			id: "e3214fb6-e609-45f7-a744-539e9435a4c3",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "aa67f4d4-6e54-47aa-8e04-ea672bd8e4de",
			displayName: "CHRISTOPH2017$",
			id: "e369d664-2d7e-417c-8062-cddabad741a5",
		},
		{
			registrationDateTime: "2020-11-11T15:08:43Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "sm-langevag@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-19T11:23:54Z",
			operatingSystemVersion: "10.0.19041.572",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-11-11T15:08:43Z",
			manufacturer: null,
			deviceId: "566a5a19-b1bd-41be-80d6-ab052b59833b",
			displayName: "Langevåg102019",
			id: "e39e0a58-d3a4-4118-953d-7cb62aaea463",
		},
		{
			registrationDateTime: "2021-05-10T11:27:37Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "ronny.hammer@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-19T17:06:26Z",
			operatingSystemVersion: "16.2",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-10T11:27:37Z",
			manufacturer: null,
			deviceId: "4f833d92-61a4-4da6-9826-d0c5a2a04f12",
			displayName: "iPhone 12 Pro",
			id: "e4497602-1526-4259-a9d8-b92aa2316502",
		},
		{
			registrationDateTime: "2020-10-29T15:36:39Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "hans.simensen@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-23T12:30:19Z",
			operatingSystemVersion: "16.1.1",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-10-29T15:36:40Z",
			manufacturer: null,
			deviceId: "86302711-ab82-488d-87ce-1490c06469ab",
			displayName: "iPhone SE (2nd generation)",
			id: "e4851a95-8a72-4b71-a1d6-b6913fc9db22",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-20T15:30:21Z",
			manufacturer: null,
			deviceId: "593f2d50-2809-4503-b3ae-649afa816f90",
			displayName: "BG-NB051",
			id: "e4e51db0-ae9b-42bc-a31f-9854a94375fb",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-12-22T01:03:04Z",
			manufacturer: null,
			deviceId: "a41e8857-528f-4476-a320-4f4600d1011e",
			displayName: "LARSERIK2019",
			id: "e50a4fb5-1f75-4505-82dc-f21bd7f9441f",
		},
		{
			registrationDateTime: "2021-09-27T12:52:59Z",
			model: "20WE",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "marcus.jernberg@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-24T10:30:40Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-09-27T12:53:00Z",
			manufacturer: "LENOVO",
			deviceId: "5321cf40-2d0f-4d30-9dbb-b76e4a8a05ee",
			displayName: "DESKTOP-H1SR0DG",
			id: "e5bbc5bb-9420-4183-a20b-861f827aeaa0",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-07-27T00:29:10Z",
			manufacturer: null,
			deviceId: "504da1dd-c7e4-4e3f-bb5f-302e9f98a966",
			displayName: "BG-NB024",
			id: "e853bc37-8b2b-4f33-bbba-ac1923ac15c5",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-08T13:06:38Z",
			manufacturer: null,
			deviceId: "d601ade1-48fb-4908-b18a-a16a745ea86c",
			displayName: "BG-PC068$",
			id: "eaa127e6-2412-4f48-9675-f2ec1388e40b",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-09-02T00:14:53Z",
			manufacturer: null,
			deviceId: "1922e5f4-d68b-4537-81cb-ddd6d0333987",
			displayName: "simen2015",
			id: "eb68635b-8680-43d7-aab3-6db2a1c8998c",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2021-03-26T01:05:05Z",
			manufacturer: null,
			deviceId: "f8c834a5-ed6c-464a-9f3c-8c4f9c7b1a35",
			displayName: "MARIANNE2017$",
			id: "eb93e0dd-aad1-476a-9cb4-26c24d667900",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "543e9a16-e50d-4c93-8910-a656108417d3",
			displayName: "RAGNARHOME2016$",
			id: "ebdb9b7b-682a-4560-8780-c6e74075cf43",
		},
		{
			registrationDateTime: "2022-04-27T07:19:05Z",
			model: "20ST0061MX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "siri.manum@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T15:24:32Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-04-27T07:19:05Z",
			manufacturer: "LENOVO",
			deviceId: "e7ebc3c7-8573-4b96-8ddc-3f2ba4a573ba",
			displayName: "DESKTOP-S5BMOGG",
			id: "ed2bd4e4-3d96-44c1-a76e-925de2fe93fb",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-07-28T00:30:07Z",
			manufacturer: null,
			deviceId: "7cbb3238-ad80-4f5d-adb8-469e68081e84",
			displayName: "BG-PC048",
			id: "edea050d-805f-4a32-92a7-7895329a62ca",
		},
		{
			registrationDateTime: "2022-01-03T11:28:54Z",
			model: "20ST005UMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "axel.enqvist@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-30T08:06:59Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2022-01-03T11:28:54Z",
			manufacturer: "LENOVO",
			deviceId: "872ee436-c888-48fa-a9c5-97ba0fe2281b",
			displayName: "LAPTOP-4F34676S",
			id: "ee3e9d7b-83f2-45e5-b56e-0b9d5c555c4c",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: false,
			createdDateTime: "2020-08-19T08:36:53Z",
			manufacturer: null,
			deviceId: "1c0b3d7b-ba2a-465b-82cd-3e6c703ffd3a",
			displayName: "Iris2018",
			id: "eee2f1cc-40af-4559-a5d8-0cc1a76550f6",
		},
		{
			registrationDateTime: "2020-08-11T08:17:50Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-23T07:45:15Z",
			operatingSystemVersion: "10.0.19044.2486",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-10T16:23:58Z",
			manufacturer: null,
			deviceId: "f85dd861-fc16-41c2-bef2-362579885265",
			displayName: "BG-NB046",
			id: "eef70c04-bf26-4b65-99e5-e64bcc437030",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: "10.0 (17134)",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-07-08T17:19:24Z",
			manufacturer: null,
			deviceId: "fb66c8e5-a237-487f-beee-00eaea9f5a09",
			displayName: "LivBarZav2018",
			id: "f106361c-9896-4446-b5c3-a2b02675092f",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-19T08:36:54Z",
			manufacturer: null,
			deviceId: "98a4dd9e-58c4-44c2-87b9-81b57eb3c4c5",
			displayName: "monica2016",
			id: "f498f194-612f-4207-b903-6ac834e5c202",
		},
		{
			registrationDateTime: "2019-08-28T07:55:59Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "tone.pilaro@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-20T07:54:01Z",
			operatingSystemVersion: "10.0.18362.0",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-08-28T07:55:59Z",
			manufacturer: null,
			deviceId: "a70b2f8f-3d42-4d4d-a399-a3d980b6375b",
			displayName: "PILARO-G7516HVP",
			id: "f5049633-0844-492d-a91f-131bffa0a5a1",
		},
		{
			registrationDateTime: "2022-07-11T05:43:20Z",
			model: null,
			isCompliant: false,
			deviceOwnership: null,
			registeredOwners: "anja.johnsen@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-12-21T16:10:53Z",
			operatingSystemVersion: "10.0.22000.739",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-12-19T10:39:12Z",
			manufacturer: null,
			deviceId: "ca4cb760-e6eb-4f14-93c7-ae956ffecda7",
			displayName: "Anja201811",
			id: "f5d4a4e8-a2c9-4930-897d-a2372dd0ccae",
		},
		{
			registrationDateTime: "2018-11-22T09:08:32Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-17T13:13:44Z",
			operatingSystemVersion: "10.0.19044.1645",
			isManaged: true,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2018-07-30T06:31:43Z",
			manufacturer: null,
			deviceId: "ce9ee40a-1375-4327-83a4-7cde0973cde6",
			displayName: "solvi2016",
			id: "f63c2cdc-b65f-4ee0-a6bf-f47a26a6a499",
		},
		{
			registrationDateTime: "2021-09-20T08:11:41Z",
			model: "20S0000GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "Ragnhild.saastad@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-22T18:31:28Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2021-09-20T08:11:41Z",
			manufacturer: "LENOVO",
			deviceId: "923645aa-7b14-4173-8d66-d60d174cd48b",
			displayName: "LAPTOP-FAMG4NSV",
			id: "f6673d98-afda-48da-82f8-585f1ca2efc8",
		},
		{
			registrationDateTime: "2019-06-04T19:43:56Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "lisbeth.askeroi@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2022-09-30T05:20:55Z",
			operatingSystemVersion: "13.6.1",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2019-06-04T19:43:56Z",
			manufacturer: null,
			deviceId: "e6d997fb-a9d5-4aeb-b4af-1be3517b9c34",
			displayName: "Lisbeths iPhone",
			id: "f674069f-0a9c-42fb-b115-b99338bd7960",
		},
		{
			registrationDateTime: "2022-01-23T20:23:37Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "johannes.flem@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-11-04T13:01:59Z",
			operatingSystemVersion: "10.0.18363.0",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-01-23T20:23:37Z",
			manufacturer: null,
			deviceId: "b927ea39-1881-4dc1-bcf1-a7b6254c3e52",
			displayName: "LAPTOP-0SG20AOT",
			id: "f6b159e0-168f-4d62-ac81-02aa5be179d6",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-09-05T00:26:21Z",
			manufacturer: null,
			deviceId: "3585a636-cfb0-4960-8aa5-69158a2273f7",
			displayName: "mailiss2016",
			id: "f8e04dec-e8f3-4f23-a31b-6878a63d4362",
		},
		{
			registrationDateTime: "2020-06-22T08:09:26Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "martin.furnes@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-24T13:41:44Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-06-22T08:09:26Z",
			manufacturer: "LENOVO",
			deviceId: "ab8b5ca9-deb1-4e3c-ba25-e18ef7bcf7a6",
			displayName: "LAPTOP-E341RU80",
			id: "f8e8d1e9-2abf-466d-99e9-39de16151648",
		},
		{
			registrationDateTime: "2022-03-31T14:24:41Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Verena.Stuewe@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-20T09:58:08Z",
			operatingSystemVersion: "10.0.19043.1586",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-03-31T14:24:41Z",
			manufacturer: null,
			deviceId: "189df950-a611-475d-a0b3-cb887dc4187b",
			displayName: "BG-NB055",
			id: "f8f892d5-12c9-4284-ad73-4fc9be92b185",
		},
		{
			registrationDateTime: "2020-08-15T09:47:57Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "judith.winterl@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-17T19:16:01Z",
			operatingSystemVersion: "13.5.1",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-08-15T09:47:58Z",
			manufacturer: null,
			deviceId: "355ff835-2295-4d70-bac7-075e2e9ee2a9",
			displayName: "iPhone von Judith",
			id: "f90527fb-cefb-4de9-8d73-be70df4e9f5c",
		},
		{
			registrationDateTime: "2020-12-28T06:02:18Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "bjornhild.steingrimsen@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "iOS",
			approximateLastSignInDateTime: "2023-01-16T16:30:40Z",
			operatingSystemVersion: "16.2",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2020-12-28T06:02:18Z",
			manufacturer: null,
			deviceId: "3dfb72b1-3d7b-4b46-8cde-e1319bb4c50b",
			displayName: "iPhone 11 Pro",
			id: "fa05af88-7eab-4c25-ba4d-7809f4d86bf8",
		},
		{
			registrationDateTime: "2022-11-17T12:28:27Z",
			model: "20QJ001GMX",
			isCompliant: false,
			deviceOwnership: "Company",
			registeredOwners: "erik.mellem@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2022-12-13T11:13:09Z",
			operatingSystemVersion: "10.0.22621.819",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-05-05T07:02:49Z",
			manufacturer: "LENOVO",
			deviceId: "b3609184-ba15-47ac-8775-e3b77ca3be3b",
			displayName: "DESKTOP-PDHB3QO",
			id: "fb9be208-cad7-4b62-bdb0-e2509e11aeda",
		},
		{
			registrationDateTime: null,
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "ServerAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: null,
			operatingSystemVersion: null,
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-02-19T14:32:10Z",
			manufacturer: null,
			deviceId: "2f1c1318-81fb-42fe-9fa4-2503fe8a6dac",
			displayName: "BG-NB048",
			id: "fc60a67e-a059-4658-9b43-9a43bb5aeedb",
		},
		{
			registrationDateTime: "2020-05-15T11:55:59Z",
			model: "20QJ001GMX",
			isCompliant: true,
			deviceOwnership: "Company",
			registeredOwners: "yngvill.ofstad@bergans.no",
			managementType: "MDM",
			trustType: "AzureAd",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-29T19:32:02Z",
			operatingSystemVersion: "10.0.22000.1455",
			isManaged: true,
			isRooted: false,
			accountEnabled: true,
			createdDateTime: "2020-05-15T11:56:00Z",
			manufacturer: "LENOVO",
			deviceId: "1fb78156-8f15-498e-ac57-aa66e9a0400c",
			displayName: "DESKTOP-S13CAJB",
			id: "fd2755d6-5b8f-484c-a017-072b5aaf43f4",
		},
		{
			registrationDateTime: "2021-03-24T14:19:56Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: null,
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-29T19:45:33Z",
			operatingSystemVersion: "10.0.19042.928",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-03-24T14:19:56Z",
			manufacturer: null,
			deviceId: "a6877ec9-d0b9-4dc9-9e0e-d6f30b6fe45e",
			displayName: "DESKTOP-JUHS1RT",
			id: "fdb34e9a-19ca-4e76-86ba-a1e985aa25e9",
		},
		{
			registrationDateTime: "2022-06-16T12:02:53Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "Jana.Feuerherdt@bergans.de",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-11T06:59:13Z",
			operatingSystemVersion: "10.0.19044.1766",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2022-06-16T12:02:53Z",
			manufacturer: null,
			deviceId: "a8dba4e7-45d9-42fd-9103-2868bc615753",
			displayName: "BG-NB035",
			id: "fdb72f68-e028-4176-ace7-fb477032cc02",
		},
		{
			registrationDateTime: "2021-05-10T13:04:37Z",
			model: null,
			isCompliant: null,
			deviceOwnership: null,
			registeredOwners: "lasse.ulven.pedersen@bergans.no",
			managementType: null,
			trustType: "Workplace",
			operatingSystem: "Windows",
			approximateLastSignInDateTime: "2023-01-26T10:16:58Z",
			operatingSystemVersion: "10.0.19041.928",
			isManaged: null,
			isRooted: null,
			accountEnabled: true,
			createdDateTime: "2021-05-10T13:04:37Z",
			manufacturer: null,
			deviceId: "a9073a8d-9c29-4a62-9ba5-3ec4f653dc5d",
			displayName: "DESKTOP-885TNU7",
			id: "fdf63e73-9455-49e6-b16c-95ce71179ce7",
		},
	],
	NumberOfOperatingSystems: {
		Android: 1,
		Unknown: 4,
		Windows: 296,
		iOS: 13,
	},
};

const months = [
	dayjs().format("MMMM YYYY"),
	dayjs().subtract(1, "month").format("MMMM YYYY"),
	dayjs().subtract(2, "month").format("MMMM YYYY"),
	dayjs().subtract(3, "month").format("MMMM YYYY"),
	dayjs().subtract(4, "month").format("MMMM YYYY"),
	dayjs().subtract(5, "month").format("MMMM YYYY"),
	dayjs().subtract(6, "month").format("MMMM YYYY"),
	dayjs().subtract(7, "month").format("MMMM YYYY"),
	dayjs().subtract(8, "month").format("MMMM YYYY"),
	dayjs().subtract(9, "month").format("MMMM YYYY"),
	dayjs().subtract(10, "month").format("MMMM YYYY"),
	dayjs().subtract(11, "month").format("MMMM YYYY"),
	dayjs().subtract(12, "month").format("MMMM YYYY"),
];

export const TOTAL_COST = {
	period: months[0],
	totalCost: 201948.50543590306,
	totalCostPreviousMonth: 721192.6013462702,
	isFetching: false,
	isLoading: false,
	azure: [
		{
			period: months[0],
			totalForecasted: 104803.5298357007,
			totalPreTaxAzure: 95399.3098357007,
			totalForecastedAzure: 95399.3098357007,
			totalForecastedMarketplaceItems: 500.62,
			totalForecastedReservedInstances: 8903.6,
		},
		{
			period: months[1],
			totalForecasted: 18938.157514575854,
			totalPreTaxAzure: 9529.117514575853,
			totalForecastedAzure: 9529.117514575853,
			totalForecastedMarketplaceItems: 500.62,
			totalForecastedReservedInstances: 8908.42,
		},
		{
			period: months[2],
			totalForecasted: 110932.41875902019,
			totalPreTaxAzure: 101054.16875902019,
			totalForecastedAzure: 101054.16875902019,
			totalForecastedMarketplaceItems: 500.62,
			totalForecastedReservedInstances: 9377.63,
		},
		{
			period: months[3],
			totalForecasted: 103175.88658625564,
			totalPreTaxAzure: 98605.66658625564,
			totalForecastedAzure: 98605.66658625564,
			totalForecastedMarketplaceItems: 500.62,
			totalForecastedReservedInstances: 4069.6,
		},
		{
			period: months[4],
			totalForecasted: 124646.53467201618,
			totalPreTaxAzure: 102869.88467201617,
			totalForecastedAzure: 102869.88467201617,
			totalForecastedMarketplaceItems: 500.62,
			totalForecastedReservedInstances: 21276.03,
		},
		{
			period: months[5],
			totalForecasted: 104255.86752760108,
			totalPreTaxAzure: 102317.54752760108,
			totalForecastedAzure: 102317.54752760108,
			totalForecastedMarketplaceItems: 1938.32,
			totalForecastedReservedInstances: 0,
		},
		{
			period: months[6],
			totalForecasted: 96624.14194768277,
			totalPreTaxAzure: 96624.14194768277,
			totalForecastedAzure: 96624.14194768277,
			totalForecastedMarketplaceItems: 0,
			totalForecastedReservedInstances: 0,
		},
		{
			period: months[7],
			totalForecasted: 93844.49667046768,
			totalPreTaxAzure: 91345.61667046767,
			totalForecastedAzure: 91345.61667046767,
			totalForecastedMarketplaceItems: 2498.88,
			totalForecastedReservedInstances: 0,
		},
		{
			period: months[8],
			totalForecasted: 96660.40926489649,
			totalPreTaxAzure: 96490.53926489649,
			totalForecastedAzure: 96490.53926489649,
			totalForecastedMarketplaceItems: 169.87,
			totalForecastedReservedInstances: 0,
		},
		{
			period: months[9],
			totalForecasted: 122807.62956355537,
			totalPreTaxAzure: 122807.62956355537,
			totalForecastedAzure: 122807.62956355537,
			totalForecastedMarketplaceItems: 0,
			totalForecastedReservedInstances: 0,
		},
		{
			period: months[10],
			totalForecasted: 98581.4157972312,
			totalPreTaxAzure: 24908.611946913214,
			totalForecastedAzure: 98411.54579723119,
			totalForecastedMarketplaceItems: 169.87,
			totalForecastedReservedInstances: 0,
		},
		{
			period: months[11],
			totalForecasted: 98581.4157972312,
			totalPreTaxAzure: 24908.611946913214,
			totalForecastedAzure: 98411.54579723119,
			totalForecastedMarketplaceItems: 169.87,
			totalForecastedReservedInstances: 0,
		},
	],
	office365: [
		{
			period: months[0],
			totalForecasted: 91000.8,
		},
		{
			period: months[1],
			totalForecasted: 91000.84999999995,
		},
		{
			period: months[2],
			totalForecasted: 91000.41,
		},
		{
			period: months[3],
			totalForecasted: 91000.48,
		},
		{
			period: months[4],
			totalForecasted: 91000.88,
		},
		{
			period: months[5],
			totalForecasted: 91000.96,
		},
		{
			period: months[6],
			totalForecasted: 91000.68,
		},
		{
			period: months[7],
			totalForecasted: 95764.27999999998,
		},
		{
			period: months[8],
			totalForecasted: 91000.344137931035,
		},
		{
			period: months[9],
			totalForecasted: 14238.632000000001,
		},
		{
			period: months[10],
			totalForecasted: 13955.960000000001,
		},
		{
			period: months[11],
			totalForecasted: 13955.960000000001,
		},
	],
	consultancy: [
		{
			period: months[0],
			monthly_BilledCost: {
				exclVat: 32601,
				inclVat: 43289.75,
			},
		},
		{
			period: months[1],
			monthly_BilledCost: {
				exclVat: 9859,
				inclVat: 12323.75,
			},
		},
		{
			period: months[2],
			monthly_BilledCost: {
				exclVat: 0,
				inclVat: 0,
			},
		},
		{
			period: months[3],
			monthly_BilledCost: {
				exclVat: 7342,
				inclVat: 9177.5,
			},
		},
		{
			period: months[4],
			monthly_BilledCost: {
				exclVat: 16115,
				inclVat: 20143.75,
			},
		},
		{
			period: months[5],
			monthly_BilledCost: {
				exclVat: 9226,
				inclVat: 11532.5,
			},
		},
		{
			period: months[6],
			monthly_BilledCost: {
				exclVat: 344,
				inclVat: 430,
			},
		},
		{
			period: months[7],
			monthly_BilledCost: {
				exclVat: 32056,
				inclVat: 40070,
			},
		},
		{
			period: months[8],
			monthly_BilledCost: {
				exclVat: 55975,
				inclVat: 69968.75,
			},
		},
		{
			period: months[9],
			monthly_BilledCost: {
				exclVat: 76825,
				inclVat: 96031.25,
			},
		},
		{
			period: months[10],
			monthly_BilledCost: {
				exclVat: 5000,
				inclVat: 6250,
			},
		},
		{
			period: months[11],
			monthly_BilledCost: {
				exclVat: 5000,
				inclVat: 6250,
			},
		},
		{
			period: months[12],
			monthly_BilledCost: {
				exclVat: 5000,
				inclVat: 6250,
			},
		},
	],
	yourEmployees: [
		{
			period: months[0],
			totalPriceSum: 0,
		},
		{
			period: months[1],
			totalPriceSum: 0,
		},
		{
			period: months[2],
			totalPriceSum: 0,
		},
		{
			period: months[3],
			totalPriceSum: 253751,
		},
		{
			period: months[4],
			totalPriceSum: 253751,
		},
		{
			period: months[5],
			totalPriceSum: 0,
		},
		{
			period: months[6],
			totalPriceSum: 0,
		},
		{
			period: months[7],
			totalPriceSum: 0,
		},
		{
			period: months[8],
			totalPriceSum: 0,
		},
		{
			period: months[9],
			totalPriceSum: 0,
		},
		{
			period: months[10],
			totalPriceSum: 7234,
		},
		{
			period: months[11],
			totalPriceSum: 7234,
		},
		{
			period: months[12],
			totalPriceSum: 7234,
		},
	],
	yourITSystems: [
		{
			period: months[0],
			totalPriceSum: 0,
		},
		{
			period: months[1],
			totalPriceSum: 0,
		},
		{
			period: months[2],
			totalPriceSum: 32299,
		},
		{
			period: months[3],
			totalPriceSum: 32299,
		},
		{
			period: months[4],
			totalPriceSum: 20299,
		},
		{
			period: months[5],
			totalPriceSum: 20299,
		},
		{
			period: months[6],
			totalPriceSum: 0,
		},
		{
			period: months[7],
			totalPriceSum: 0,
		},
		{
			period: months[8],
			totalPriceSum: 0,
		},
		{
			period: months[9],
			totalPriceSum: 0,
		},
		{
			period: months[10],
			totalPriceSum: 9812,
		},
		{
			period: months[11],
			totalPriceSum: 9812,
		},
		{
			period: months[12],
			totalPriceSum: 9812,
		},
	],
	hardware: [
		{
			period: months[0],
			totalPriceSum: 5000,
		},
		{
			period: months[1],
			totalPriceSum: 7500,
		},
		{
			period: months[2],
			totalPriceSum: 12341,
		},
		{
			period: months[3],
			totalPriceSum: 77136.86846923828,
		},
		{
			period: months[4],
			totalPriceSum: 13481.280029296875,
		},
		{
			period: months[5],
			totalPriceSum: 0,
		},
		{
			period: months[6],
			totalPriceSum: 9936,
		},
		{
			period: months[7],
			totalPriceSum: 49420.68960571289,
		},
		{
			period: months[8],
			totalPriceSum: 10692.270233154297,
		},
		{
			period: months[9],
			totalPriceSum: 507321.33978271484,
		},
		{
			period: months[10],
			totalPriceSum: 89411.12963867188,
		},
		{
			period: months[11],
			totalPriceSum: 89411.12963867188,
		},
		{
			period: months[12],
			totalPriceSum: 89411.12963867188,
		},
	],
};

export const AZURE_BILLING_DATA = {
	tenantId: "",
	fromDate: dayjs().startOf(dayjs().month()).format("YYYY-MM-DD"),
	toDate: dayjs().format("YYYY-MM-DD"),
	total: 302941,
	totalPreTax: 242353,
	totalForecasted: 805594,
	currentDailyCost: 27925,
	lastMonthCompleteMonthTotal: 720128,
	lastMonthFromDate: dayjs().startOf(dayjs().subtract(1, "months")).format("YYYY-MM-DD"),
	lastMonthToDate: dayjs().subtract(1, "months").format("YYYY-MM-DD"),
	lastMonthTotal: 342724,
	lastMonthTotalPreTax: 274179,
	changeFromLastMonth: -39783,
	percentualChangeFromLastMonth: -11,
	preTaxChangeFromLastMonth: -31826,
	preTaxPercentualChangeFromLastMonth: -11,
	currency: "NOK",
	anomalies: {
		subscriptions: [],
		resources: [],
	},
	subscriptions: [
		{
			subscriptionName: "2",
			total: 2474,
			lastMonthTotal: 2452,
			lastMonthCompleteMonthTotal: 3180,
		},
		{
			subscriptionName: "4",
			total: 3312,
			lastMonthTotal: 3849,
			lastMonthCompleteMonthTotal: 7555,
		},
		{
			subscriptionName: "6",
			total: 5191,
			totalPreTax: 4153,
			lastMonthTotal: 5602,
			lastMonthCompleteMonthTotal: 11919,
		},
		{
			subscriptionName: "8",
			total: 7750,
			totalPreTax: 6200,
			lastMonthTotal: 7522,
			lastMonthCompleteMonthTotal: 8962,
		},
		{
			subscriptionName: "10",
			total: 472,
			lastMonthTotal: 577,
			lastMonthCompleteMonthTotal: 1183,
		},
	],
	resourceGroups: [
		{
			resourceGroupName: "2",
			total: 3000,
			lastMonthTotal: 2900,
			lastMonthCompleteMonthTotal: 4000,
		},
		{
			resourceGroupName: "4",
			total: 3500,
			lastMonthTotal: 3000,
			lastMonthCompleteMonthTotal: 4000,
		},
		{
			resourceGroupName: "6",
			total: 5000,
			lastMonthTotal: 1698,
			lastMonthCompleteMonthTotal: 3621,
		},
		{
			resourceGroupName: "10",
			total: 4500,
			lastMonthTotal: 3400,
			lastMonthCompleteMonthTotal: 26235.368200945824,
		},
	],
	resources: [
		{
			resourceName: "2",
			total: 629,
			lastMonthTotal: 673,
			lastMonthCompleteMonthTotal: 1435,
		},
		{
			resourceName: "4",
			total: 3264,
			lastMonthTotal: 3537,
			lastMonthCompleteMonthTotal: 7284,
		},
		{
			resourceName: "6",
			total: 300,
			lastMonthTotal: 356,
			lastMonthCompleteMonthTotal: 757,
		},
		{
			resourceName: "8",
			total: 4625,
			lastMonthTotal: 4868,
			lastMonthCompleteMonthTotal: 10551,
		},
	],
	tags: [
		{
			tagCombination: "2",
			total: 1000,
			lastMonthTotal: 600,
			lastMonthCompleteMonthTotal: 2000,
		},
		{
			tagCombination: "4",
			total: 7499,
			lastMonthTotal: 8578,
			lastMonthCompleteMonthTotal: 17420,
		},
		{
			tagCombination: "6",
			total: 3000,
			lastMonthTotal: 2811,
			lastMonthCompleteMonthTotal: 7037,
		},
		{
			tagCombination: "8",
			total: 5000,
			lastMonthTotal: 4000,
			lastMonthCompleteMonthTotal: 4200,
		},
		{
			tagCombination: "10",
			total: 3264,
			lastMonthTotal: 3537,
			lastMonthCompleteMonthTotal: 7284,
		},
	],
};

export { SUPPORT_CREATE_TICKET, PURCHASEABLE_PRODUCTS, SIGN_INS, DEVICES_STATISTICS };
