import { useEffect, useMemo, useRef } from "react";
import { Divider, Grid, Skeleton, Typography } from "@mui/material";
import Chart from "chart.js/auto";
import dayjs from "dayjs";

import styles from "./MonthlyCostChart.module.scss";
import colors from "styles/utilities/_colors.scss";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { selectAllCostsPreviousMonths, selectTotalCost } from "features/costs";

import _ from "lodash";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { getAfterBody, getLabel } from "utilities/chartJsCustomPlugins";
import { fetchAllCosts } from "actions/costActions";
import { LegendItem } from "components/Common/LegendItem";
import { TOTAL_COST } from "data/mockResponses";
import { IRONSTONE_PRODUCT_DISPLAY_NAMES } from "utilities/constants/constants";
import { ExportCostDropdown } from "./ExportCostDropdown";

const MonthlyCostChart = (): JSX.Element => {
	const numberOfMonths = 12;
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const totalCostSlice = useAppSelector(selectTotalCost);
	const costs = useAppSelector(selectAllCostsPreviousMonths);
	const isLoading = totalCostSlice.isLoading;

	// Fetch all costs previous 12 months for monthly cost chart
	useApiOnce(fetchAllCosts, totalCostSlice);

	const { azure, yourEmployees, yourITSystems, office365, consultancy, hardware } = isLoading
		? TOTAL_COST
		: costs;

	const totalCostProperties = {
		azure: "totalForecasted",
		office365: "totalForecasted",
		yourEmployees: "totalPriceSum",
		yourITSystems: "totalPriceSum",
		hardware: "totalPriceSum",
		consultancy: "monthly_BilledCost.exclVat",
	};

	const chartData = _.range(numberOfMonths).map((i) => ({
		x: dayjs()
			.subtract(numberOfMonths - i, "months")
			.format("MMMM YY"),
		azure: 0,
		license: 0,
		yourEmployees: 0,
		yourItSystems: 0,
		hardware: 0,
		consultancy: 0,
	}));

	const findPeriodIndex = (obj: Record<string, any>) => ({
		...obj,
		index: chartData.findIndex(({ x }) => {
			const [month, year] = x.split(" ");
			return obj.period.includes(month) && obj.period.endsWith(year);
		}),
	});

	const periodInChart = ({ index }: Record<string, any>) => index !== -1;

	const setChartBarName = (name: string) => (obj: Record<string, any>) => ({ ...obj, name });

	const renameTotal = (oldName: string) => (obj: Record<string, any>) => ({
		...obj,
		total: _.get(obj, oldName, 0), // _.get ensures we can access nested properties, e.g., "totalForecasted.exclVat"
	});

	const setTotal = ({ index, name, total }: Record<string, any>) =>
		_.set(chartData[index], name, total); // _.set handles TypeScript typing issues

	/* *
	 * Prepare chart data by adding index field determining correct period.
	 * Removes data that are more than `numberOfMonths` months old.
	 * */
	function addChartBarData(data: Record<string, any>[], name: string, totalProperty: string) {
		return data
			?.map(findPeriodIndex)
			.filter(periodInChart)
			.map(setChartBarName(name))
			.map(renameTotal(totalProperty))
			.forEach(setTotal);
	}

	addChartBarData(azure, "azure", totalCostProperties.azure);
	addChartBarData(office365, "license", totalCostProperties.office365);
	addChartBarData(yourEmployees, "yourEmployees", totalCostProperties.yourEmployees);
	addChartBarData(yourITSystems, "yourItSystems", totalCostProperties.yourITSystems);
	addChartBarData(hardware, "hardware", totalCostProperties.hardware);
	addChartBarData(consultancy, "consultancy", totalCostProperties.consultancy);

	const datasets = useMemo(() => {
		const items = [
			{
				key: "azure",
				label: "Azure",
				legendLabel: "Azure",
				colorKey: "azure",
				cost: totalCostSlice.totalAzureCostPreviousTwelveMonths,
			},
			{
				key: "license",
				label: "License",
				legendLabel: "License",
				colorKey: "license",
				cost: totalCostSlice.totalOffice365CostPreviousTwelveMonths,
			},
			{
				key: "yourItSystems",
				label: IRONSTONE_PRODUCT_DISPLAY_NAMES.YOUR_IT_SYSTEMS,
				legendLabel: "Your IT Systems",
				colorKey: "yourItSystems",
				cost: totalCostSlice.totalYourITSystemsCostPreviousTwelveMonths,
			},
			{
				key: "yourEmployees",
				label: "Your Employees", // Custom legendLabel needed for this chart as YourEmployees changed name to IT for Your Employees
				legendLabel: "IT for Your Employees",
				colorKey: "yourEmployees",
				cost: totalCostSlice.totalYourEmployeesCostPreviousTwelveMonths,
			},
			{
				key: "consultancy",
				label: "Consultancy",
				legendLabel: "Consultancy",
				colorKey: "consultancy",
				cost: totalCostSlice.totalConsultancyCostPreviousTwelveMonths,
			},
			{
				key: "hardware",
				label: "Hardware",
				legendLabel: "Hardware",
				colorKey: "hardware",
				cost: totalCostSlice.totalHardwareCostPreviousTwelveMonths,
			},
		];

		return items.map(({ key, label, legendLabel, colorKey, cost }) => ({
			label,
			legendLabel,
			backgroundColor: colors[colorKey],
			yAxisKey: key,
			total: cost,
		}));
	}, [totalCostSlice]);

	useEffect(() => {
		const chartReadyData = {
			datasets: _(datasets)
				.filter("total") // Only show bars with total > 0
				.map(({ label, yAxisKey, backgroundColor }: Record<string, any>) => ({
					label,
					parsing: {
						yAxisKey,
					},
					barPercentage: 0.55,
					data: chartData,
					backgroundColor: isLoading ? colors.isLoading : backgroundColor,
					borderWidth: 1.3,
					borderColor: "#ffffff",
				}))
				.value(),
		};

		const chart = new Chart(canvasRef?.current?.getContext("2d") as CanvasRenderingContext2D, {
			type: "bar",
			data: chartReadyData,
			options: {
				animation: {
					duration: isLoading ? 0 : 1000,
				},
				indexAxis: "x",
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						stacked: true,
						grid: {
							display: false,
						},
					},
					y: {
						stacked: true,
						ticks: {
							maxTicksLimit: 8,
							display: !isLoading,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: true,
						mode: "index",
						intersect: false,
						padding: 8,
						callbacks: {
							label: (context) => getLabel(context),
							afterBody: (context) => getAfterBody(context),
						},
					},
				},
			},
		});

		chart.canvas.id = "costOverview";

		return () => chart.destroy();
	}, [chartData, isLoading, datasets]);

	return (
		<>
			<Typography variant="body1" mb={2}>
				{`COST PREVIOUS ${numberOfMonths} MONTHS`}
			</Typography>
			<Grid container className={styles.chartAndLegendContainer}>
				<Grid
					container
					item
					xs={12}
					p={1}
					pb={2}
					alignItems="center"
					justifyContent="space-between"
					className={styles.legendContainer}
				>
					<Grid container item xs={10}>
						<Grid className={styles.total} py={2} px={4}>
							<Grid container direction="column">
								{isLoading ? (
									<>
										<Skeleton variant="text" width={80} height={30} />
										<Skeleton variant="text" width={80} height={30} />
									</>
								) : (
									<>
										<Grid container alignItems={"center"} gap={0.5}>
											<Typography
												variant="body1"
												fontWeight={600}
												color="primary"
											>
												Total
											</Typography>
											<Typography variant="caption" color="primary">
												(ex. VAT)
											</Typography>
										</Grid>
										<Typography variant="body1" fontWeight={600}>
											{formatCostString(
												"",
												totalCostSlice.totalCostPreviousTwelveMonths,
												" ,-",
											)}
										</Typography>
									</>
								)}
							</Grid>
						</Grid>
						{datasets.map(
							({
								label,
								legendLabel,
								backgroundColor,
								total,
							}: Record<string, any>) => (
								<LegendItem
									mainLabel={legendLabel}
									key={label}
									subLabel={formatCostString("", total, " ,-")}
									color={backgroundColor}
									isLoading={isLoading}
									onClick={(event) => {
										const chart = Chart.getChart("costOverview");
										const index = datasets
											.filter(({ total }) => total > 0)
											.findIndex((bar) => bar.label === label);
										const meta = chart?.getDatasetMeta(index);
										event.currentTarget.classList.toggle(styles.strikethrough);
										if (!chart || !meta || meta.data.length === 0) return;
										chart.getDatasetMeta(index).hidden
											? chart.show(index)
											: chart.hide(index);
									}}
								/>
							),
						)}
					</Grid>
					<Grid item xs={2} className={styles.dowloadableDropdown}>
						<ExportCostDropdown />
					</Grid>
				</Grid>
				<Grid item xs={12} className={styles.divider}>
					<Divider color={colors.gray} />
				</Grid>
				<Grid mt={2} className={styles.chartStyles}>
					<canvas ref={canvasRef} />
				</Grid>
			</Grid>
		</>
	);
};

export { MonthlyCostChart };
