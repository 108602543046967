import { useCallback, useEffect, useMemo, useState } from "react";
import {
	HardwareGroup,
	HardwareGroups,
	HardwareProduct,
	HardwareProductExtended,
	Manufacturer,
	Manufacturers,
} from "types";
import { SortBy } from "utilities/constants/enums";
import { LCSubStr } from "utilities/longestCommonSubstring";
import _ from "lodash";

interface FilteredProductsHookInputProps {
	products: HardwareProductExtended[];
	selectedManufacturers: Manufacturers;
	showInStockOnly: boolean;
	renderCount: number;
	searchQuery: string;
	sortBy: string;
	setSortBy: React.Dispatch<React.SetStateAction<string>>;
	pricerange: number[];
	selectedGroups: HardwareGroups;
	setSelectedGroups: React.Dispatch<React.SetStateAction<HardwareGroups>>;
	setSelectedManufacturers: React.Dispatch<React.SetStateAction<Manufacturers>>;
}

const isSelected = (entities: HardwareGroups | Manufacturers) =>
	Object.values(entities ?? {}).some((v) => v);

const useFilteredProducts = ({
	sortBy,
	setSortBy,
	products,
	selectedGroups,
	selectedManufacturers,
	showInStockOnly,
	renderCount,
	searchQuery,
	pricerange,
	setSelectedGroups,
	setSelectedManufacturers,
}: FilteredProductsHookInputProps) => {
	const [searchQueryDebounced, setSearchQueryDebounced] = useState<string>("");

	useEffect(() => {
		const timeout = setTimeout(() => {
			setSearchQueryDebounced(searchQuery);
		}, 300);

		return () => clearTimeout(timeout);
	}, [searchQuery]);

	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
	const [totalProductsInSearch, setTotalProductsInSearch] = useState<number>(products.length);

	const searchQueryTerms = useMemo(
		() => searchQueryDebounced.toLowerCase().split(" "),
		[searchQueryDebounced],
	);

	const lcs = useCallback(
		(term) => _.sum(searchQueryTerms.map((query) => LCSubStr(query, term))),
		[searchQueryTerms],
	);

	useEffect(() => {
		const sorted = (entities: HardwareProduct[] = []) =>
			entities.sort((a, b) => {
				if (searchQueryDebounced.length > 0) {
					// Compare display names first
					let comparator =
						lcs(b.displayName.toLowerCase()) - lcs(a.displayName.toLowerCase());
					if (comparator !== 0) return comparator;
					// Compare descriptions if comparing display names give equal search score
					comparator =
						lcs(b.description.toLowerCase()) - lcs(a.description.toLowerCase());
					if (comparator !== 0) return comparator;
					// Compare availability if comparing descriptions give equal search score
					return Number(b.availability.quantity) - Number(a.availability.quantity);
				}
				let comparator;
				switch (sortBy) {
					case SortBy.PriceHighToLow:
						comparator = b.priceInfo.priceNet - a.priceInfo.priceNet;
						break;
					case SortBy.PriceLowToHigh:
						comparator = a.priceInfo.priceNet - b.priceInfo.priceNet;
						break;
					case SortBy.NameDescending:
						comparator = b.displayName.localeCompare(a.displayName);
						break;
					case SortBy.NameAscending:
						comparator = a.displayName.localeCompare(b.displayName);
						break;
					case SortBy.Availability:
						comparator =
							Number(b.availability.quantity) - Number(a.availability.quantity);
						break;
					case SortBy.DiscountAmount:
						comparator = b.priceInfo.discount - a.priceInfo.discount;
						break;
					case SortBy.DiscountPercentage:
						comparator =
							b.priceInfo.discountPercentage - a.priceInfo.discountPercentage;
						break;
					default: // Default: Prefer notebooks
						if (a.group === b.group && a.group === "PC_NOTEBOOK_BUSINESS") {
							comparator = a.displayName.localeCompare(b.displayName);
							break;
						}
						comparator = a.group === "PC_NOTEBOOK_BUSINESS" ? -1 : 1;
						break;
				}
				return comparator;
			});
		const isGroupsSelected = isSelected(selectedGroups);
		const isManufacturersSelected = isSelected(selectedManufacturers);

		const filterOnFiltersAndSearch = ({
			searchTerms,
			availability,
			group,
			manufacturer,
			priceInfo: { priceNet },
		}: HardwareProduct & { searchTerms: string[] }) => {
			if (isManufacturersSelected && !selectedManufacturers[manufacturer as Manufacturer]) {
				return false;
			}

			if (isGroupsSelected && !selectedGroups[group as HardwareGroup]) {
				return false;
			}

			if (showInStockOnly && Number(availability.quantity) <= 0) {
				return false;
			}

			if (priceNet < pricerange[0] || priceNet > pricerange[1]) {
				return false;
			}

			return searchQueryTerms.some((query) =>
				searchTerms.some((term) => term.includes(query)),
			);
		};

		const filteredProducts = products.filter(filterOnFiltersAndSearch);
		setTotalProductsInSearch(filteredProducts.length);
		setSelectedProducts(
			sorted(filteredProducts)
				.slice(0, renderCount)
				.map(({ sku }) => sku),
		);
	}, [
		products,
		selectedGroups,
		selectedManufacturers,
		showInStockOnly,
		renderCount,
		searchQueryDebounced,
		sortBy,
		lcs,
		searchQueryTerms,
		pricerange,
		setSelectedGroups,
		setSelectedManufacturers,
		setSortBy,
	]);

	return {
		filteredProducts: selectedProducts,
		filteredTotalProducts: totalProductsInSearch,
	};
};

export { useFilteredProducts };
