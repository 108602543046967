import { SecondaryButton } from "components/Common/Buttons/Button";
import styles from "./Baguette.module.scss";
import HomeIcon from "@mui/icons-material/Home";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";

interface BaguetteProps {
	onClick: () => void;
	navIsOpen: boolean;
}

export const Baguette = ({ onClick, navIsOpen }: BaguetteProps) => {
	const { navigateToPage } = useAppNavigate();
	return (
		<div className={styles.menuWrapper}>
			<div className={styles.menuButtonsWrapper}>
				<SecondaryButton
					className={styles.homeButton}
					text={<HomeIcon fontSize="large" />}
					size="medium"
					action={() => {
						navigateToPage(NAVIGATION_NAMES.ROOT.path);
						navIsOpen && onClick();
					}}
					variantStyle="contained"
				/>

				<SecondaryButton
					className={styles.menuButton}
					text={!navIsOpen ? "Menu" : "Close"}
					size="medium"
					action={onClick}
					variantStyle="contained"
				/>
			</div>
		</div>
	);
};
