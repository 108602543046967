import { Grid, Typography } from "@mui/material";
import { QuantityAdjuster } from "components/Common/QuantityAdjuster";
import { BuyLicensesSummaryTable } from "./BuyLicensesSummaryTable";
import { AgreeToTerms } from "components/Common/AgreeToTerms";
import { HoverTooltip } from "components/Common/Tooltips";
import { PrimaryButton } from "components/Common/Buttons/Button";
import { useMemo, useState } from "react";
import { License, LicensePurchase, SubscriptionVariant } from "types";

import styles from "./BuyLicenses.module.scss";
import { TERMS_AND_CONDITIONS_TYPES } from "components/Common/Dialogs/TermsAndConditionsDialog/TermsAndConditions";
import { LicenseActionOperationType, TermDuration } from "utilities/constants/enums";
import { useAppSelector } from "hooks/hooks";
import { selectActiveLicenseCompleteDetails } from "features/licenses/licenses";
import { TermDurationSelectorCard } from "components/LicenseManagement/LicenseAssignmentView/PurchaseLicenses/PurchaseLicenseCard";
import dayjs from "dayjs";
import { NEW_SUBSCRIPTION_INDICATOR } from "utilities/constants/constants";
import EmailInput from "components/Common/EmailInput/EmailInput";
import { selectPurchaseActionInfo, selectRecipientMailState } from "features/scheduledActions";

interface BuyLicensesProps {
	handlePrimaryAction: (
		variant: SubscriptionVariant[],
		operationType: LicenseActionOperationType,
	) => void;
}

const createVariant = (
	costIdentifier: string,
	termDuration: string,
	preferredVariant: SubscriptionVariant,
) => {
	const newCommitmentEndDate =
		termDuration === TermDuration.P1M
			? dayjs().add(1, "month").toDate()
			: dayjs().add(1, "year").toDate();
	const {
		termDuration: _,
		creationDate: __,
		orderId: ____,
		refundableQuantity: _____,
		...rest
	} = preferredVariant;

	return {
		...rest,
		costIdentifier,
		termDuration,
		creationDate: new Date(),
		commitmentEndDate: newCommitmentEndDate,
		subscriptionId: `${NEW_SUBSCRIPTION_INDICATOR}_${termDuration}`,
		autoRenewEnabled: true,
	} as SubscriptionVariant;
};

const BuyLicenses = ({ handlePrimaryAction }: BuyLicensesProps) => {
	const [constructedTermDuration, setConstructedTermDuration] = useState<TermDuration | null>();
	const completeLicenseDetails = useAppSelector(selectActiveLicenseCompleteDetails);
	const { friendlyName, totalQuantity, variants, preferredVariantId, costIdentifiers } =
		completeLicenseDetails;

	// Some licenses might not have both term durations available
	// so we need to construct a new variant with the missing term duration
	const variantsWithConstructedNewPurchase = useMemo(() => {
		const preferredVariant = variants.find(
			(variant) => variant.subscriptionId === preferredVariantId,
		) as SubscriptionVariant;

		const termDurations = new Set(variants.map((variant) => variant.termDuration));

		// Check if both P1M and P1Y are present
		const hasP1M = termDurations.has("P1M");
		const hasP1Y = termDurations.has("P1Y");

		// If both term durations are present, we don't need to construct a new variant
		if (hasP1M && hasP1Y) {
			return variants;
		}

		// Construct a new variant with the missing term duration
		const missingTermDuration = hasP1M ? TermDuration.P1Y : TermDuration.P1M;
		setConstructedTermDuration(missingTermDuration);
		const costIdentifier = costIdentifiers[missingTermDuration];
		const newVariant = createVariant(costIdentifier, missingTermDuration, preferredVariant);

		return [...variants, newVariant];
	}, [variants, preferredVariantId, costIdentifiers]);

	const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
	const [quantity, setQuantity] = useState<number>(1);
	const [error, setError] = useState<JSX.Element>(<></>);
	const [blockPurchase, setBlockPurchase] = useState<boolean>(false);

	const { isValid: isEnteredMailValid } = useAppSelector(selectRecipientMailState);

	const handleSetQuantity = (quantity: number) => {
		const { error, blockPurchase } = getError(quantity, totalQuantity, isEnteredMailValid);
		setQuantity(quantity);
		setError(error);
		setBlockPurchase(blockPurchase);
	};

	const license = {
		chosenVariant: variants.find((variant) => variant.subscriptionId === preferredVariantId),
		subscriptionVariants: variantsWithConstructedNewPurchase,
	} as License;

	const [licenseDetails, setLicenseDetails] = useState<License>(license);

	const handleChangeChosenVariant = (variant: SubscriptionVariant) => {
		setLicenseDetails({ ...licenseDetails, chosenVariant: variant });
	};

	const onHandlePrimaryAction = () => {
		const updatedVariant = {
			...licenseDetails.chosenVariant,
			purchaseQuantity: quantity,
		};
		const actionType = updatedVariant.subscriptionId.includes(NEW_SUBSCRIPTION_INDICATOR)
			? LicenseActionOperationType.CreateNewSubscription
			: LicenseActionOperationType.AdjustLicenseCount;
		handlePrimaryAction([updatedVariant], actionType);
	};

	const { isPurchasing } = useAppSelector(selectPurchaseActionInfo);

	const { numP1M, numP1Y } = useMemo(() => {
		const numP1M = completeLicenseDetails.variants.filter(
			(variant) => variant.termDuration === TermDuration.P1M,
		).length;
		const numP1Y = completeLicenseDetails.variants.filter(
			(variant) => variant.termDuration === TermDuration.P1Y,
		).length;
		return { numP1M, numP1Y };
	}, [completeLicenseDetails.variants]);

	return (
		<>
			<Grid container direction={"column"} mt={2}>
				<Grid item>
					<Typography variant="body1">Current available quantity</Typography>
				</Grid>
				<Grid container item className={styles.quantityContainer}>
					<Typography variant="h3">{totalQuantity}</Typography>
					<Typography variant="body1" fontWeight={400} color="text.secondary" ml={1}>
						licenses
					</Typography>
				</Grid>
			</Grid>
			<Grid container direction={"column"}>
				<Grid item mt={3}>
					<Typography variant="body1" fontWeight={500}>
						Buy licenses
					</Typography>
				</Grid>
				<Grid container item mt={2}>
					<Grid container className={styles.subHeaderContainer}>
						<div className={styles.numberContainer}>
							<Typography>1</Typography>
						</div>
						<Typography variant="body1"> Buy quantity</Typography>
					</Grid>
					<Grid item mt={1} className={styles.quantityAdjusterContainer}>
						<QuantityAdjuster
							quantity={quantity}
							up={(quantity: number) => handleSetQuantity(quantity + 1)}
							down={(quantity: number) =>
								quantity - 1 > 0 && handleSetQuantity(quantity - 1)
							}
							setQuantity={handleSetQuantity}
						/>
						<Grid container className={styles.errorContainer}>
							{error}
						</Grid>
					</Grid>
				</Grid>
				<Grid container item mt={2} mb={6}>
					<Grid container className={styles.subHeaderContainer}>
						<div className={styles.numberContainer}>
							<Typography>2</Typography>
						</div>
						<Typography variant="body1">Select subscription duration</Typography>
					</Grid>
					<Grid container className={styles.commitmentDurationSelector}>
						<Grid
							item
							onClick={() =>
								handleChangeChosenVariant(
									variantsWithConstructedNewPurchase.find(
										(variant) => variant.termDuration === TermDuration.P1M,
									) as SubscriptionVariant,
								)
							}
						>
							<TermDurationSelectorCard
								termDuration={TermDuration.P1M}
								newSubscription={constructedTermDuration === TermDuration.P1M}
								licenseName={friendlyName}
								costIdentifier={costIdentifiers.P1M}
								isSelectedTermDuration={
									licenseDetails.chosenVariant.termDuration === TermDuration.P1M
								}
								hidePurchaseOption={false}
							/>
						</Grid>
						<Grid
							item
							onClick={() =>
								handleChangeChosenVariant(
									variantsWithConstructedNewPurchase.find(
										(variant) => variant.termDuration === TermDuration.P1Y,
									) as SubscriptionVariant,
								)
							}
						>
							<TermDurationSelectorCard
								termDuration={TermDuration.P1Y}
								newSubscription={constructedTermDuration === TermDuration.P1Y}
								licenseName={licenseDetails.chosenVariant.friendlyName}
								costIdentifier={costIdentifiers.P1Y}
								isSelectedTermDuration={
									licenseDetails.chosenVariant.termDuration === TermDuration.P1Y
								}
								hidePurchaseOption={false}
							/>
						</Grid>
					</Grid>
					<Grid item className={styles.severalSubsWarning}>
						{numP1M > 1 &&
							licenseDetails.chosenVariant.termDuration === TermDuration.P1M && (
								<Typography variant="description">
									{numP1M} active monthly subscriptions. Priority given to newer
									ones with auto-renewal.
								</Typography>
							)}
						{numP1Y > 1 &&
							licenseDetails.chosenVariant.termDuration === TermDuration.P1Y && (
								<Typography variant="description">
									{numP1Y} active annual subscriptions. Priority given to newer
									ones with auto-renewal.
								</Typography>
							)}
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="body1" fontWeight={500}>
							Summary
						</Typography>
					</Grid>
					<Grid item xs={12} mt={1}>
						<BuyLicensesSummaryTable
							licenses={[
								{
									friendlyName,
									costIdentifier: licenseDetails.chosenVariant.costIdentifier,
									quantity: quantity,
									termDuration: licenseDetails.chosenVariant.termDuration,
								} as LicensePurchase,
							]}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid container item mt={2} className={styles.emailInputContainer}>
				<EmailInput explanationText={"Purchase receipt"} required={false} />
			</Grid>
			<Grid container mt={2}>
				<AgreeToTerms
					type={TERMS_AND_CONDITIONS_TYPES.LICENSE}
					agreedToTerms={agreedToTerms}
					setAgreedToTerms={setAgreedToTerms}
					position="flex-start"
					isDisabled={blockPurchase}
				/>
				<HoverTooltip
					title={"Purchase blocked"}
					description={"Your purchase has been blocked: " + error}
					hide={!blockPurchase}
				>
					<div>
						<PrimaryButton
							text={"Buy licenses"}
							size="medium"
							variantStyle="contained"
							action={onHandlePrimaryAction}
							isLoading={isPurchasing}
							isDisabled={blockPurchase || !agreedToTerms || !isEnteredMailValid}
						/>
					</div>
				</HoverTooltip>
			</Grid>
		</>
	);
};

export { BuyLicenses };

const getError = (quantity: number, totalQuantity: number, isEnteredMailValid: boolean) => {
	const numPercentageIncrease = Math.round((quantity / totalQuantity) * 100) - 100;
	const quantityIsVeryHigh = quantity > 20;
	let error = <></>;
	let blockPurchase = false;

	if (!isEnteredMailValid) {
		blockPurchase = true;
	}

	if (quantity < 1) {
		error = (
			<Typography variant="body1" color="error">
				Quantity cannot be less than 1
			</Typography>
		);
		blockPurchase = true;
	} else if (numPercentageIncrease > 100 && quantityIsVeryHigh) {
		error = (
			<>
				<Typography variant="body1" display="inline">
					Are you sure you want to buy {quantity} licenses?
				</Typography>
				<Typography variant="body1" color="error" display="inline" ml={1}>
					This is a {numPercentageIncrease}% increase
				</Typography>
			</>
		);
		blockPurchase = false;
	}

	return {
		error,
		blockPurchase,
	};
};
