import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchGroupLifecyclePolicies } from "actions/customerActions";
import { RootState } from "store";
import { ArchivalSettings } from "types";

const archivalSettingsAdapter = createEntityAdapter<ArchivalSettings>();

const archivalSettingsSlice = createSlice({
	name: "archivalSettings",
	initialState: archivalSettingsAdapter.getInitialState({ isLoading: true, isFetching: false }),
	reducers: {
		setArchivalSettingsData(state, { payload }) {
			archivalSettingsAdapter.setOne(state, payload);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGroupLifecyclePolicies.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchGroupLifecyclePolicies.fulfilled, (state, { payload }) => {
				archivalSettingsAdapter.setAll(state, payload);
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

export const { setArchivalSettingsData } = archivalSettingsSlice.actions;

export const selectArchivalSettings = (state: RootState) => state.archivalSettings;
export const archivalSettingsSelectors =
	archivalSettingsAdapter.getSelectors(selectArchivalSettings);

export default archivalSettingsSlice.reducer;
