import { Grid, TableCell, TableFooter, TableRow, Typography } from "@mui/material";
import { SecondaryButton } from "components/Common/Buttons/Button";

import styles from "./SummaryTableFooter.module.scss";
import clsx from "clsx";
import { Download } from "@mui/icons-material";

interface SummaryTableFooterProps {
	padding?: boolean;
	totalNumberOfItems?: number;
	summaryCells: { label: string; value: string | number }[];
	onResetFilters: () => void;
	onDownloadExcel?: () => void;
	visible: boolean;
}

const SummaryTableFooter = ({
	padding = true,
	totalNumberOfItems,
	summaryCells,
	onResetFilters,
	onDownloadExcel,
	visible,
}: SummaryTableFooterProps) => {
	return (
		<TableFooter
			className={clsx({
				[styles.padding]: padding,
				[styles.tableFooter]: true,
				[styles.visible]: visible,
			})}
		>
			<TableRow className={styles.rowStyle}>
				<TableCell className={styles.filteredResultsTitle}>
					<Typography variant="body1" fontWeight={500}>
						Filtered results{" "}
						{Number(totalNumberOfItems ?? 0) > 0 && `(${totalNumberOfItems})`}
					</Typography>
				</TableCell>
				<TableCell>
					<div className={styles.summaryContainer}>
						{summaryCells.map((cell) => (
							<Grid item className={styles.footerCell} key={cell.label}>
								<Typography variant="description" fontWeight={500}>
									{cell.label}
								</Typography>
								<Typography variant="body1">{cell.value}</Typography>
							</Grid>
						))}
					</div>
				</TableCell>
				<TableCell className={styles.buttonCell}>
					{onDownloadExcel && (
						<SecondaryButton
							text="Export filtered list"
							size="small"
							variantStyle="outlined"
							action={onDownloadExcel}
							icon={<Download className={styles.downloadIcon} />}
						/>
					)}
					<SecondaryButton
						text="Reset filters"
						size="small"
						variantStyle="outlined"
						action={onResetFilters}
					/>
				</TableCell>
			</TableRow>
		</TableFooter>
	);
};

export default SummaryTableFooter;
