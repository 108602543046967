import { Divider, Grid, Skeleton, Typography } from "@mui/material";
import {
	selectLicenseActions,
	selectLicenseActionsWithSkuGUIDKeys,
} from "features/scheduledActions";
import { useAppSelector } from "hooks/hooks";
import { LicenseAction, StatusInfo, SubscriptionVariant, SubscriptionVariantGroup } from "types";

import styles from "./LicenseHistoryCard.module.scss";

import InfoIconOutlined from "@mui/icons-material/InfoOutlined";

import { LicenseActionStatus, LicenseActionTargetType } from "utilities/constants/enums";
import dayjs from "dayjs";
import { licenseSubscriptionsSelectors } from "features/licenses/licenses";
import clsx from "clsx";
import { HoverTooltip } from "components/Common/Tooltips";
import { getIconAndExplanationText } from "utilities/licenseUtils/licenseActionUtils";
import { FIVE_MINUTES } from "utilities/constants/constants";
import { TruncatableTypography } from "components/Common/TruncateableTypography";

interface LicenseHistoryCardProps {
	skuGuid: string;
}

const LicenseHistoryCard = ({ skuGuid }: LicenseHistoryCardProps) => {
	const { isLoading } = useAppSelector(selectLicenseActions);
	const allLicenseActions = useAppSelector(selectLicenseActionsWithSkuGUIDKeys);
	const licenseActions = allLicenseActions[skuGuid] ?? [];
	const { executed, toBeExecuted } = licenseActions.reduce(
		(acc, curr) => {
			if (curr.TargetType !== LicenseActionTargetType.Subscription) return acc;
			if (
				curr.Status === LicenseActionStatus.Error ||
				curr.Status === LicenseActionStatus.Cancelled ||
				curr.Status === LicenseActionStatus.Finished ||
				curr.Status === LicenseActionStatus.PartiallyFinished
			) {
				acc.executed.push(curr);
			} else {
				acc.toBeExecuted.push(curr);
			}
			return acc;
		},
		{
			executed: [] as LicenseAction[],
			toBeExecuted: [] as LicenseAction[],
		},
	);

	const licenseSubscriptions = useAppSelector(licenseSubscriptionsSelectors.selectAll);
	const relevantSubscriptions =
		licenseSubscriptions.find((subscription) => {
			return subscription.variants[0].provisioningId === skuGuid;
		}) ?? ({} as SubscriptionVariantGroup);

	const content = (actions: LicenseAction[], type: "toBeExecuted" | "executed") => {
		if (isLoading) {
			return (
				<>
					<Grid container direction="column" gap={2}>
						<Skeleton variant="rectangular" height={150} />
						<Skeleton variant="rectangular" height={150} />
						<Skeleton variant="rectangular" height={150} />
					</Grid>
				</>
			);
		}

		if (actions.length === 0) {
			return (
				<Grid container className={styles.noHistoryContainer}>
					<Typography variant="body1" color="text.secondary" align="center">
						{type === "executed" ? "No history available" : "No scheduled actions"}
					</Typography>
				</Grid>
			);
		}

		return (
			<>
				{actions.length > 0 && (
					<Grid container direction="column" gap={2}>
						{actions.map((action) => (
							<LicenseHistoryEntry
								key={action.ActionId}
								licenseAction={action}
								variantGroup={relevantSubscriptions}
							/>
						))}
					</Grid>
				)}
			</>
		);
	};

	return (
		<Grid container direction="column">
			<Typography variant="body1" fontWeight={500}>
				Scheduled actions
			</Typography>
			<Divider className={styles.divider} />
			{content(toBeExecuted, "toBeExecuted")}
			<Typography variant="body1" fontWeight={500} mt={5}>
				History
			</Typography>
			<Divider className={styles.divider} />
			{content(executed, "executed")}
		</Grid>
	);
};

export { LicenseHistoryCard };

interface LicenseHistoryEntryProps {
	key: string;
	licenseAction: LicenseAction;
	variantGroup: SubscriptionVariantGroup;
}

const LicenseHistoryEntry = ({ licenseAction, variantGroup }: LicenseHistoryEntryProps) => {
	const subscription =
		variantGroup.variants.find(
			(variant) => variant.subscriptionId === licenseAction.TargetGUID,
		) ?? ({} as SubscriptionVariant);

	const subscriptionEndDate = dayjs(subscription.commitmentEndDate).format("DD.MM.YYYY");
	const { icon, actionText, descriptionText } = getIconAndExplanationText(
		licenseAction,
		subscription.termDuration,
		subscriptionEndDate,
	);

	const { OrderedByDisplayName, OrderedBy, Status, StatusInfo, ExecutionDate, TableTimestamp } =
		licenseAction;

	const isFutureAction =
		Status === LicenseActionStatus.Scheduled || Status === LicenseActionStatus.InProgress;

	const shownDate = Status === LicenseActionStatus.Scheduled ? ExecutionDate : TableTimestamp;
	const shownState = isFutureAction ? `Scheduled for` : `${Status} on`;

	return (
		<Grid container className={styles.entryContainer}>
			<Grid item className={styles.iconAndDottedLineContainer}>
				{icon}
				<Divider className={styles.dottedLine} />
			</Grid>
			<Grid
				item
				className={clsx({
					[styles.textContainer]: true,
					[styles.inProgressOpacity]: Status === LicenseActionStatus.InProgress,
					[styles.cancelledOpacity]: Status === LicenseActionStatus.Cancelled,
				})}
			>
				<Typography variant="body1" className={styles.actionText}>
					{actionText}
				</Typography>
				<Typography variant="description" mt={-0.5}>
					{descriptionText}
				</Typography>
				<TruncatableTypography
					variant="description"
					color="text.secondary"
					mt={0.5}
					maxCharLength={30}
					tooltipPlacement="left-start"
				>
					{OrderedByDisplayName ?? OrderedBy}
				</TruncatableTypography>
				<Typography variant="description" color="text.secondary">
					{`${shownState}: ${dayjs(shownDate).format("DD.MM.YYYY")}`}
				</Typography>
			</Grid>
			<Label label={Status} statusInfo={StatusInfo} />
		</Grid>
	);
};

const Label = ({ label, statusInfo }: { label: LicenseActionStatus; statusInfo: StatusInfo[] }) => {
	let cssClass = "hidden";
	let text = label as string;
	let description = "";
	let title = "";
	const showTooltip =
		label === LicenseActionStatus.Error ||
		label === LicenseActionStatus.InProgress ||
		label === LicenseActionStatus.Cancelled;

	switch (label) {
		case LicenseActionStatus.InProgress:
			cssClass = "inProgress";
			text = "Processing";
			description = `The order is being processed, changes will be active within ${FIVE_MINUTES} minutes`;
			title = "Order being processed";
			break;
		case LicenseActionStatus.Error:
			cssClass = "error";
			text = "Error";
			description = "There was an error executing the order, please try again.";
			title = "Order error";
			break;
		case LicenseActionStatus.Cancelled:
			cssClass = "actionCancelled";
			text = "Cancelled";
			title = "Order has been cancelled";
			const info = statusInfo.find((info) => info.Status === label) ?? ({} as StatusInfo);
			description = `${info.Message} on ${
				info.ActivityDate
					? dayjs(info.ActivityDate).format("DD.MM.YYYY HH:mm")
					: "unknown date"
			}`;
			break;
		default:
			break;
	}

	return (
		<HoverTooltip hide={!showTooltip} title={title} description={description}>
			<Typography
				variant="caption"
				className={clsx({
					[styles[cssClass]]: true,
				})}
			>
				{text}
				<InfoIconOutlined className={styles.infoIcon} />
			</Typography>
		</HoverTooltip>
	);
};
