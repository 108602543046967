import PropTypes from "prop-types";
import { Divider, Grid } from "@mui/material";

import { UserDetails } from "../UserDetails";
import { SummaryCart } from "./SummaryCart";

import styles from "./Summary.module.scss";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { selectCartCount, selectProducts } from "features/hardware/products";
import { fetchProducts } from "actions/productActions";

const Summary = ({ saveForm, newEmployeeData }) => {
	const cartItemsCount = useAppSelector(selectCartCount);

	useApiOnce(fetchProducts, useAppSelector(selectProducts));

	return (
		<Grid container classes={{ root: styles.summaryWrapper }}>
			<Grid item xs={12} className={styles.userDetails}>
				<UserDetails
					employeeData={newEmployeeData}
					setIsFormDataDirty={() => {}} // Prevents isDirty reset. Data already dirty from previous step.
					showShippingDetails={cartItemsCount > 0}
					saveForm={saveForm}
				/>
			</Grid>
			<Divider orientation="vertical" flexItem />
			<Grid
				container
				direction="column"
				item
				xs={5}
				classes={{ root: styles.summaryCartWrapper }}
			>
				<Grid item xs={5} classes={{ root: styles.summaryCart }}>
					<SummaryCart
						chosenLicenses={newEmployeeData.chosenLicenses ?? []}
						shouldShowLicenses={newEmployeeData.chosenLicenses?.length > 0}
						shouldShowProducts={cartItemsCount > 0}
					/>
				</Grid>
				<Divider />
			</Grid>
		</Grid>
	);
};

Summary.propTypes = {
	newEmployeeData: PropTypes.shape({
		givenName: PropTypes.string,
		surname: PropTypes.string,
		mail: PropTypes.string,
		password: PropTypes.string,
		department: PropTypes.string,
		manager: PropTypes.string,
		officeLocation: PropTypes.string,
		jobTitle: PropTypes.string,
		companyName: PropTypes.string,
		licenseGroups: PropTypes.arrayOf(
			PropTypes.shape({
				groupID: PropTypes.string,
				groupName: PropTypes.string,
				licenses: PropTypes.arrayOf(
					PropTypes.shape({
						name: PropTypes.string,
						usedLicenses: PropTypes.number,
						unusedLicenses: PropTypes.number,
						skuId: PropTypes.string,
					}),
				),
			}),
		),
		addUserToGroups: PropTypes.arrayOf(PropTypes.string),
	}),
};

export { Summary };
