import { Grid, Skeleton, Typography } from "@mui/material";
import { getIcon } from "utilities/microsoftIconsUtils";

import styles from "./LicenseRecommendationHeader.module.scss";
import { LicenseSubscriptionRecommendation } from "types";
import { caculateTotalCostSavings } from "utilities/licenseUtils/costSavingsUtils";
import { TotalSavingsChartCard } from "../LicenseRecommendationContent/TotalSavingsChartCard";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";

interface LicenseRecommendationHeaderInputProps {
	title: string | JSX.Element;
	isLoading: boolean;
	recommendation: LicenseSubscriptionRecommendation;
}

export const LicenseRecommendationHeader = ({
	title,
	isLoading,
	recommendation,
}: LicenseRecommendationHeaderInputProps) => {
	const icon = getIcon({ iconName: title as string });
	const { totalCostSavings } = caculateTotalCostSavings(recommendation);
	return (
		<Grid container className={styles.titleContainer}>
			<Grid item xs={9} px={3} py={2}>
				<Typography variant="h2">
					{!isLoading ? (
						<>
							{icon ? (
								<>
									<Grid container alignItems="center" gap={2}>
										<svg width="30" height="30" viewBox="0 0 60 60">
											{getIcon({ iconName: title as string })}
										</svg>
										{tripleDotTruncateString(title as string, 37)}
									</Grid>
								</>
							) : (
								title
							)}
						</>
					) : (
						<Skeleton variant="text" height={30} width={300} animation="wave" />
					)}
				</Typography>
			</Grid>
			<Grid item xs={2.2} md={2}>
				{!isLoading && (
					<TotalSavingsChartCard
						recommendation={recommendation}
						totalCostSavings={totalCostSavings}
						allSavingTypesOptimized={totalCostSavings === 0}
					/>
				)}
			</Grid>
		</Grid>
	);
};
