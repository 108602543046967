import { useEffect, useMemo, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { tableDataWithOtherCollector } from "utilities/tableDataWithOtherCollector";
import colors from "styles/utilities/_colors.scss";
import _ from "lodash";
import styles from "./CompliancyChart.module.scss";
import securityDashboardSettings from "settings/securityDashboard.json";
import { LegendItem } from "components/Common/LegendItem";
import { LegendItemContainer } from "components/Common/LegendItemContainer";

const CompliancyChart = ({ isLoading, checkDevicesValues, chartStyles }) => {
	const canvasRef = useRef(null);
	const numDeviceOSToShow = 4;

	const settings = securityDashboardSettings.deviceCompliancy;

	const [datasets, setDatasets] = useState([]);
	const [overallStats, setOverallStats] = useState([]);

	const compliancyColors = useMemo(
		() =>
			_.mapValues(
				{
					compliant: colors.blueTint2,
					noStatus: colors.brownTint1,
					nonCompliant: colors.yellowTint2,
				},
				(color) => (isLoading ? colors.loading : color),
			),
		[isLoading],
	);

	useEffect(() => {
		let data = {};
		const labels = new Set();
		const devicesList = checkDevicesValues?.AllDevicesDetails ?? [];

		devicesList.forEach((device) => {
			labels.add(device.operatingSystem);

			if (!data[device.operatingSystem]) {
				data[device.operatingSystem] = {
					nonCompliant: 0,
					compliant: 0,
					notAvailable: 0,
				};
			}
			if (device.isCompliant === false) {
				data[device.operatingSystem].nonCompliant++;
			}

			if (device.isCompliant === true) {
				data[device.operatingSystem].compliant++;
			}

			if (device.isCompliant === null) {
				data[device.operatingSystem].notAvailable++;
			}
		});

		const { topMostDevices, labelsArray } = tableDataWithOtherCollector(
			data,
			numDeviceOSToShow,
		);

		const compliantValues = [];
		const nonCompliantValues = [];
		const notAvailableValues = [];

		labelsArray.forEach((label) => {
			compliantValues.push(topMostDevices[label].compliant);
			nonCompliantValues.push(topMostDevices[label].nonCompliant);
			notAvailableValues.push(topMostDevices[label].notAvailable);
		});

		const datasets = [
			{
				label: settings.compliantLabelText,
				data: compliantValues,
				backgroundColor: compliancyColors.compliant,
			},
			{
				label: settings.noStatusLabelText,
				data: notAvailableValues,
				backgroundColor: compliancyColors.noStatus,
			},
			{
				label: settings.nonCompliantLabelText,
				data: nonCompliantValues,
				backgroundColor: compliancyColors.nonCompliant,
			},
		];

		setDatasets(datasets);

		setOverallStats([
			{
				// compliant
				value: checkDevicesValues?.NumberOfCompliantDevices.True,
				labelText: settings.compliantLabelText,
				color: compliancyColors.compliant,
			},
			{
				// not available
				value: checkDevicesValues?.NumberOfCompliantDevices.NotAvailable,
				labelText: settings.noStatusLabelText,
				color: compliancyColors.noStatus,
			},
			{
				// non-compliant
				value: checkDevicesValues?.NumberOfCompliantDevices.False,
				labelText: settings.nonCompliantLabelText,
				color: compliancyColors.nonCompliant,
			},
		]);

		const chartReadyData = {
			labels: labelsArray,
			datasets,
		};

		const chart = new Chart(canvasRef.current.getContext("2d"), {
			type: "bar",
			data: chartReadyData,
			options: {
				animation: {
					duration: isLoading ? 0 : 1000,
				},
				plugins: {
					legend: {
						display: false,
					},
				},
				indexAxis: "y",
				responsive: true,
				maintainAspectRatio: false,
				barThickness: 20,
				scales: {
					x: {
						stacked: true,
						ticks: {
							display: !isLoading,
							font: {
								family: "Barlow",
								size: 14,
								weight: 400,
							},
							color: "#000000",
						},
						border: {
							display: false,
						},
					},
					y: {
						grid: {
							display: false,
						},
						ticks: {
							display: !isLoading,
							crossAlign: "start",
							font: {
								family: "Barlow",
								size: 14,
								weight: 400,
							},
						},
						stacked: true,
					},
				},
			},
		});

		chart.canvas.id = "compliancy";

		return () => chart.destroy();
	}, [checkDevicesValues, isLoading, compliancyColors, settings]);

	return (
		<>
			<LegendItemContainer alignWithMinWidth>
				{overallStats.map(({ value, labelText, color }, key) => (
					<LegendItem
						mainLabel={labelText}
						color={color}
						isLoading={isLoading}
						subLabel={value}
						key={key}
						onClick={(event) => {
							const chart = Chart.getChart("compliancy");
							const index = datasets
								.filter(({ data }) => data.length > 0)
								.findIndex((bar) => bar.backgroundColor === color);
							const meta = chart?.getDatasetMeta(index);
							event.currentTarget.classList.toggle(styles.strikethrough);
							if (!chart || !meta || meta.data.length === 0) return;
							chart.getDatasetMeta(index).hidden
								? chart.show(index)
								: chart.hide(index);
						}}
					/>
				))}
			</LegendItemContainer>

			<div className={chartStyles}>
				<canvas ref={canvasRef} />
			</div>
		</>
	);
};

export { CompliancyChart };
