import { createAsyncThunk } from "@reduxjs/toolkit";
import { DeviceShort, User } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet, apiDelete, apiPatch, apiPut, apiPost } from "utilities/api";

export const fetchUsers = createAsyncThunk(
	"users/fetchAll",
	apiGet<User[]>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_USERS(),
		[SCOPES.GRAPH_READ],
	),
);

export const fetchUser = createAsyncThunk(
	"users/fetch",
	apiGet<User>(
		(authConfig, { id }) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_USER(id),
		[SCOPES.GRAPH_READ],
	),
);

export const createUser = createAsyncThunk(
	"users/create",
	apiPost<User>(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.POST_USER(),
		[SCOPES.GRAPH_WRITE],
	),
);

export const updateUser = createAsyncThunk(
	"users/update",
	apiPatch<User>(
		(authConfig, { id }) => authConfig.MS_GRAPH_API_ENDPOINTS.UPDATE_USER(id),
		[SCOPES.GRAPH_WRITE],
	),
);

export const deleteUser = createAsyncThunk(
	"users/delete",
	apiDelete<{ deletedUserId: string }>(
		(authConfig, { id }) => authConfig.PARTNER_CENTER_API_ENDPOINTS.DELETE_USER(id),
		[SCOPES.PARTNERCENTER_WRITE],
	),
);

export const fetchUserDevices = createAsyncThunk(
	"users/fetchUserDevices",
	apiGet<DeviceShort[]>(
		(authConfig, { id }) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_USER_DEVICES(id),
		[SCOPES.GRAPH_READ],
	),
);

export const fetchManager = createAsyncThunk(
	"users/fetchManager",
	apiGet<User>(
		(authConfig, { id }) => authConfig.MS_GRAPH_API_ENDPOINTS.MANAGER(id),
		[SCOPES.GRAPH_READ],
	),
);

export const setManager = createAsyncThunk(
	"users/setManager",
	apiPut<{ managerId: string }>(
		(authConfig, { id }) => authConfig.MS_GRAPH_API_ENDPOINTS.MANAGER(id),
		[SCOPES.GRAPH_WRITE],
	),
);

export const createGuestInvitation = createAsyncThunk(
	"users/createGuestInvitation",
	apiPost<{ userId: string; companyName: string }>(
		(authConfig) =>
			authConfig.MS_GRAPH_API_ENDPOINTS.POST_GUEST_INVITATION_IRONSTONE_B2B_TENANT(),
		[SCOPES.GRAPH_WRITE],
	),
);

export const sendSms = createAsyncThunk(
	"users/sendSms",
	apiPost((authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.SEND_SMS(), [SCOPES.GRAPH_WRITE]),
);

export const sendMail = createAsyncThunk(
	"users/sendMail",
	apiPost((authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.SEND_MAIL(), [SCOPES.GRAPH_WRITE]),
);

export const getAppRoleAssignments = createAsyncThunk(
	"users/getAppRoleAssignments",
	apiGet(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_APP_ROLES_ASSIGNMENTS(),
		[SCOPES.GRAPH_READ],
	),
);

export const getOneTimePassword = createAsyncThunk(
	"users/getOneTimePassword",
	apiGet(
		(authConfig) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_ONE_TIME_PASSWORD(),
		[SCOPES.GRAPH_READ],
	),
);
