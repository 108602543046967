import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServiceStatusGroup } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

export const fetchServiceStatuses = createAsyncThunk(
	"serviceStatuses/fetch",
	apiGet<ServiceStatusGroup[]>(
		(authConfig) => authConfig.FRESHSERVICE_API_ENDPOINTS.GET_SERVICE_STATUSES(),
		[SCOPES.FRESHSERVICE_READ],
	),
);
