import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { PrimaryFormButton } from "components/Common/Buttons/Button";
import { InputTextField } from "components/Common/InputTextField";
import { userFormValidation } from "utilities/userFormValidation";
import { useAppSelector, useAuth } from "hooks/hooks";
import { usersSelectors } from "features/users";
import styles from "./EditUserForm.module.scss";

const EditUserForm = ({ employeeData, saveForm, isFormDisabled }) => {
	const { mail, givenName, surname, officeLocation, department } = employeeData;
	const mailPrefix = mail?.split("@")[0];
	const mailSuffix = mail?.split("@")[1];

	const defaultValues = {
		mailPrefix,
		mailSuffix,
		givenName,
		surname,
		officeLocation,
		department,
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm({
		mode: "onSubmit",
		defaultValues,
	});

	const formId = "edit-user-form";

	const {
		auth: {
			account: { localAccountId },
		},
		isAdmin,
	} = useAuth();

	const { userPrincipalName: loggedInUserPrincipalName } =
		useAppSelector((state) => usersSelectors.selectById(state, localAccountId)) ?? {};

	const users = useAppSelector(usersSelectors.selectAll);

	return (
		<form onSubmit={handleSubmit(saveForm)} id={formId}>
			<Grid container spacing={3}>
				<Grid item className={styles.textFieldWrapper}>
					<InputTextField
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						label="Email (user name)"
						name="mailPrefix"
						fullWidth
						error={!!errors.mailPrefix}
						helperText={userFormValidation.mailPrefix.getHelperText(errors)}
						{...register("mailPrefix", {
							...userFormValidation.mailPrefix.getValidationProps(
								users,
								mailSuffix,
								loggedInUserPrincipalName,
							),
							disabled: !isAdmin,
						})}
					/>
				</Grid>
				<Grid item className={styles.textFieldWrapper}>
					<InputTextField
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						label="Domain"
						name="mailSuffix"
						fullWidth
						{...register("mailSuffix", {
							disabled: true,
						})}
					/>
				</Grid>

				<Grid item className={styles.textFieldWrapper}>
					<InputTextField
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						label="First name"
						name="givenName"
						fullWidth
						error={!!errors.givenName}
						helperText={userFormValidation.givenName.getHelperText(errors)}
						{...register("givenName", {
							...userFormValidation.givenName.validationProps,
							disabled: isFormDisabled || !isAdmin,
						})}
					/>
				</Grid>
				<Grid item className={styles.textFieldWrapper}>
					<InputTextField
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						label="Last name"
						name="surname"
						fullWidth
						error={!!errors.surname}
						helperText={userFormValidation.surname.getHelperText(errors)}
						{...register("surname", {
							...userFormValidation.surname.validationProps,
							disabled: isFormDisabled || !isAdmin,
						})}
					/>
				</Grid>

				<Grid item className={styles.textFieldWrapper}>
					<InputTextField
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						label="Department"
						name="department"
						fullWidth
						{...register("department", {
							disabled: !isAdmin,
						})}
					/>
				</Grid>
				<Grid item className={styles.textFieldWrapper}>
					<InputTextField
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						label="Office Location"
						name="officeLocation"
						fullWidth
						{...register("officeLocation", { disabled: !isAdmin })}
					/>
				</Grid>
				<Grid item className={styles.textFieldWrapper}>
					{isAdmin && (
						<Grid container spacing={3}>
							<Grid item>
								<PrimaryFormButton
									text="Save changes"
									size="medium"
									variantStyle="contained"
									isDisabled={!isDirty || isFormDisabled}
									form={formId}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		</form>
	);
};

export { EditUserForm };
