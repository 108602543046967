import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

import ChartDataLabels from "chartjs-plugin-datalabels";
import styles from "./CostChart.module.scss";
import colors from "styles/utilities/_colors.scss";
import clsx from "clsx";
import { Grid } from "@mui/material";
import _ from "lodash";
import {
	ChartBar,
	ChartBarCount,
	ChartBarLabelByProperty,
	ChartBarLabelProp,
	ChartBarProp,
	ChartData,
} from "types";
import { formatScandinavianCostString } from "utilities/currency/numberFormattingUtility";
import { tripleDotTruncateString } from "utilities/stringFormattingUtility";
import { LegendItem } from "components/Common/LegendItem";

// Truncates a string the the n-length of characters and adds 3 dots to the end

// Rounds all the properites in the array to closest int
const RoundNumbersInArrayOfObjects = (array: ChartBar[], arrayOfProperties: ChartBarProp[]) => {
	return array.map((item) => {
		arrayOfProperties.forEach((property) => _.update(item, property, Math.round));
		return item;
	});
};

/**
 * @param {chartData}:
 * Data you want to use on when creating the chart
 *
 * @param {chartMaxLegends}:
 * Max number of legends you want on the Bar Chart.
 * If you go over the max number, all the other will
 * be put into a legend called "Other"
 *
 * @param {chartLegendPropertyName}:
 * The property from the chartData variable that will
 * be used to create the legends for the chart.
 * By sending "subscriptionName" to this property you
 * will bet all the subscriptionName data as legends in
 * the chart
 *
 * @param {chartBarLabelByProperty}:
 * Object with descriptive texts that will tell you what
 * the level bar numbers is showing.
 *
 */
interface CostChartProps {
	header?: JSX.Element;
	chartData: ChartData;
	chartBarLabelByProperty: ChartBarLabelByProperty;
	chartMaxLegends?: number;
	chartBarPropertyName: ChartBarProp; // = "subscriptionName",
	isLoading: boolean;
}

const CostChart = ({
	header,
	chartData = [],
	chartBarLabelByProperty = {} as ChartBarLabelByProperty,
	chartMaxLegends = 5,
	chartBarPropertyName, // = "subscriptionName",
	isLoading,
}: CostChartProps) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const chartProps = Object.keys(chartBarLabelByProperty) as ChartBarProp[];

	const sortedChartData = _.orderBy(chartData, _.head(chartProps), "desc");

	const currentMonthColor = colors.yellowPrimary;
	const currentMotnhPredictedColor = colors.yellowTint4;
	const lastMonthColor = colors.bluePrimary;
	const lastMonthPredictedColor = colors.blueTint6;

	useEffect(() => {
		let chartDataArray = [] as ChartBar[];

		sortedChartData.forEach((chartBar, index) => {
			const barCounts = chartProps.reduce(
				(acc, name) => ({ ...acc, [name]: chartBar[name] }),
				{},
			) as ChartBarCount;

			let currentNumberOfLegendsInChart = index + 1;

			// Add new data if we are not at the max number of legends already
			if (currentNumberOfLegendsInChart < chartMaxLegends) {
				// Create a new bar for this item
				// We don't need to show the second bar if the value of it is zero
				let formattedChartBarName = tripleDotTruncateString(
					chartBar[chartBarPropertyName] ?? "",
					40,
				);
				const chartDataObject = {
					y: formattedChartBarName,
					...barCounts,
				};
				chartDataArray.push(chartDataObject);
			} else {
				// Handle "Other" category
				const otherChartDataObject = _.last(chartDataArray);
				const otherExists = otherChartDataObject?.y === "Other";
				if (!otherExists)
					// Create a new bar with all other data, called "Other"
					chartDataArray.push({
						y: "Other",
						...barCounts,
					});
				else _.mergeWith(otherChartDataObject, barCounts, _.add);
			}
		});

		// Round numbers before displaying
		const finalArray = RoundNumbersInArrayOfObjects(chartDataArray, chartProps);

		const backgroundColors = [
			currentMonthColor,
			currentMotnhPredictedColor,
			lastMonthColor,
			lastMonthPredictedColor,
		];

		const datasets = Object.entries(chartBarLabelByProperty).map(([xAxisKey, label], i) => ({
			label: [label],
			data: finalArray,
			parsing: {
				xAxisKey: [xAxisKey],
			},
			backgroundColor: isLoading ? colors.loading : backgroundColors[i % 4],
			datalabels: {
				anchor: i % 2 === 0 ? "center" : "end",
				offset: 5,
				align: i % 2 === 0 ? "center" : "end",
				font: {
					weight: i % 2 === 0 ? "400" : "500",
				},
			},
			stack: label.includes("this month") ? "0" : "1",
			barThickness: 35,
		})) as any;

		const context = canvasRef.current?.getContext("2d");

		if (!context) return;

		const chart = new Chart(context, {
			plugins: [ChartDataLabels],
			type: "bar",
			data: {
				datasets,
			},
			options: {
				animation: {
					duration: isLoading ? 0 : 1000,
				},
				indexAxis: "y",
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					y: {
						display: !isLoading,
						ticks: {
							crossAlign: "far",
						},
					},
					x: { display: !isLoading },
				},
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: !isLoading, // Hide hover effect when loading data
						callbacks: {
							label: function (context) {
								let value = Number(context.parsed.x);

								const { totalPreTax, lastMonthTotalPreTax } =
									context.raw as ChartBar;

								const isRestOfMonthForecast = context.datasetIndex === 1;
								const isRestOfLastMonthTotal = context.datasetIndex === 3;

								// Labels should show total cost (current + predicted)
								if (isRestOfMonthForecast) value += totalPreTax;
								if (isRestOfLastMonthTotal) value += lastMonthTotalPreTax;

								return `${context.dataset.label}: ${formatScandinavianCostString(
									value,
								)}`;
							},
						},
					},
					datalabels: {
						display: function (context) {
							if (isLoading || !context.dataset.parsing) return false;

							let barCountPropertyName = _.head(
								context.dataset.parsing?.xAxisKey,
							) as ChartBarLabelProp;

							const chartBar = context.dataset.data[
								context.dataIndex
							] as unknown as ChartBar;

							return chartBar[barCountPropertyName] > 0;
						},
						formatter: (value, context) => {
							// Pick out the correct value since we have 2 values on each legend
							if (isLoading || !context.dataset.parsing) return "";
							let barCountPropertyName = _.head(context.dataset.parsing.xAxisKey);
							if (!barCountPropertyName) return "";
							let thisValue = value[barCountPropertyName];

							if (!thisValue) return "";

							const isForecast = barCountPropertyName === "restOfMonthForecast";
							const isCompleteTotalLastMonth =
								barCountPropertyName === "restOfLastMonthTotal";

							if (isForecast) {
								thisValue += value.totalPreTax;
								return formatScandinavianCostString(thisValue);
							}

							if (isCompleteTotalLastMonth) {
								thisValue += value.lastMonthTotalPreTax;
								return formatScandinavianCostString(thisValue);
							}

							const max = _(context.dataset.data)
								.flatMap(_.values)
								.filter(Number)
								.max();

							/*
							 * barRatio < 0.02:         Remove labels
							 * 0.02 < barRatio < 0.075: Displays compact labels
							 * barRatio > 0.075:        Displays normal labels
							 * */
							const barRatio = thisValue / max;

							const minusculeThreshold = 0.02;

							const shouldHide = barRatio < minusculeThreshold;

							if (shouldHide) return "";

							const shortThreshold = 0.075; // Short bars should have compact labels

							const hasSpace = barRatio > shortThreshold;

							if (hasSpace) return formatScandinavianCostString(thisValue);

							// Format larger numbers (e.g., 3600 -> 3,6k)
							return Intl.NumberFormat("no", {
								notation: "compact",
							}).format(thisValue);
						},
						color: colors.textPrimary,
					},
				},
			},
		});

		return () => chart.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		sortedChartData,
		chartProps,
		chartBarLabelByProperty,
		chartBarPropertyName,
		chartMaxLegends,
		isLoading,
	]);

	return (
		<Grid className={clsx([styles.chartStyles, styles.tall])}>
			<Grid container item xs={12} mb={1} wrap="nowrap" flexWrap="nowrap">
				<Grid item xs>
					{header}
				</Grid>
				<Grid item xs={9} md={7} container className={styles.legends} rowSpacing={4}>
					<Grid container item xs={9} xxl={7} pr={2}>
						<Grid
							item
							xs={6}
							container
							alignItems="center"
							wrap="nowrap"
							flexWrap="nowrap"
						>
							<LegendItem
								mainLabel={chartBarLabelByProperty.totalPreTax}
								color={currentMonthColor}
								noPadding
								isLoading={isLoading}
							/>
						</Grid>

						<Grid
							item
							xs={6}
							container
							alignItems="center"
							wrap="nowrap"
							flexWrap="nowrap"
							whiteSpace="nowrap"
						>
							{_.head(sortedChartData)?.restOfMonthForecast && (
								<LegendItem
									mainLabel={chartBarLabelByProperty.restOfMonthForecast}
									color={currentMotnhPredictedColor}
									noPadding
									isLoading={isLoading}
								/>
							)}
						</Grid>
					</Grid>
					<Grid container item xs={9} xxl={7} pr={2}>
						<Grid item xs={6} container alignItems="center">
							<LegendItem
								mainLabel={chartBarLabelByProperty.lastMonthTotalPreTax}
								color={lastMonthColor}
								noPadding
								isLoading={isLoading}
							/>
						</Grid>

						<Grid item xs={6} container alignItems="center">
							<LegendItem
								mainLabel={chartBarLabelByProperty.restOfLastMonthTotal}
								color={lastMonthPredictedColor}
								noPadding
								isLoading={isLoading}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<canvas ref={canvasRef} />
		</Grid>
	);
};

export { CostChart };
