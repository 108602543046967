import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export interface IWorkspaceProps {
	title: string;
	description: string;
	backgroundPic?: string;
	onClickCallback: Function;
}

export const WorkspaceCard = (props: IWorkspaceProps) => {
	return (
		<Card
			sx={{
				maxWidth: 340,
				boxShadow:
					"0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
			}}
		>
			<CardActionArea
				onClick={() => {
					props.onClickCallback();
				}}
			>
				<CardMedia
					component="img"
					src={props.backgroundPic ? props.backgroundPic : ""}
					height="243"
					alt="green iguana"
					sx={{
						padding: "20px",
						backgroundColor:
							props.title === "Internal Collaboration" ||
							props.title === "Internal Project"
								? "#cbd8ee"
								: "#464775",
					}}
				/>
				<CardContent
					sx={{
						minHeight: "98px",
					}}
				>
					<Typography gutterBottom variant="body2" component="div">
						{props.title}
					</Typography>
					<Typography variant="caption" color="text.secondary">
						{props.description}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};
