import { Grid } from "@mui/material";
import { LicenseGroups } from "../LicenseGroups";
import { AddUserLicenseDetails } from "../AddUserLicenseDetails";

import { useAppSelector } from "hooks/hooks";
import { selectAvailableLicenses, selectEnrichedLicenseGroups } from "features/licenses/licenses";

const AddToLicenseGroup = ({ newEmployeeData, updateEmployeeData }) => {
	const availableLicenseGroups = useAppSelector(selectEnrichedLicenseGroups);
	const availableLicenses = useAppSelector(selectAvailableLicenses);

	const handleTermDurationChange = (chosenVariant) => {
		const updatedChosenLicenses = newEmployeeData.chosenLicenses.map((license) => {
			if (license.skuId === chosenVariant.provisioningId) {
				return {
					...license,
					chosenVariant,
				};
			}

			return license;
		});

		updateEmployeeData({
			...newEmployeeData,
			chosenLicenses: updatedChosenLicenses,
		});
	};

	const toggleGroupClick = (group) => {
		let updatedLicenseGroups = newEmployeeData.licenseGroups;
		let updatedAddUserToGroups = newEmployeeData.addUserToGroups;

		const index = updatedLicenseGroups.findIndex(
			(pickedLicense) => pickedLicense.groupID === group.groupID,
		);

		if (index === -1) {
			updatedLicenseGroups.push(group);
			updatedAddUserToGroups.push(group.groupID);
		} else {
			updatedLicenseGroups = updatedLicenseGroups.filter(
				(_, currentElementIndex) => currentElementIndex !== index,
			);
			updatedAddUserToGroups = updatedAddUserToGroups.filter(
				(groupID) => groupID !== group.groupID,
			);
		}

		const chosenLicenses = updatedLicenseGroups
			.flatMap((group) => group.licenses)
			.map((license) => {
				// We use the "availableLicenses" enriched with the chosen variant
				const licenseMatch = availableLicenses.find(({ skuId }) => skuId === license.skuId);

				return {
					...licenseMatch,
					needsProvisioning: license.unusedLicenses <= 0,
				};
			});

		updateEmployeeData({
			licenseGroups: updatedLicenseGroups,
			addUserToGroups: updatedAddUserToGroups,
			chosenLicenses,
		});
	};

	return (
		<Grid container justifyContent="center">
			<Grid container item xs={12} spacing={3}>
				<LicenseGroups
					availableLicenseGroups={Object.values(availableLicenseGroups)}
					initialLicenseGroups={[]} // We don't have any initial license groups during user onboarding
					chosenLicenseGroups={newEmployeeData.licenseGroups}
					onClick={toggleGroupClick}
				/>
				<AddUserLicenseDetails
					availableLicenses={availableLicenses}
					chosenLicenses={newEmployeeData.chosenLicenses}
					handleTermDurationChange={handleTermDurationChange}
				/>
			</Grid>
		</Grid>
	);
};

export { AddToLicenseGroup };
