import { SelectHardware } from "components/Hardware/SelectHardware";
import { HardwareCheckout } from "./HardwareCheckout";
import {
	HARDWARE_PAGES,
	INNER_WIDTH_LARGE_ENOUGH_TO_PUSH_CONTENT,
} from "utilities/constants/constants";
import { useAppNavigate } from "hooks/useAppNavigate";
import { EditBundle } from "./SelectHardware/HardwareBundles/EditBundle";
import { Grid } from "@mui/material";

import styles from "./HardwareView.module.scss";
import { HardwareNavigation } from "./HardwareNavigation";
import { useAppSelector } from "hooks/hooks";
import { selectHardwareDrawerState } from "features/hardware/hardwarePage";
import clsx from "clsx";
import { useEffect } from "react";
import { HardwareBundlesView } from "./SelectHardware/HardwareBundlesView";
import { SwapProductDialog } from "./SwapProductDialog";

const HardwareView = ({ page }: { page: string }) => {
	const { navigateToPage } = useAppNavigate();

	let content = <></>;
	switch (page.toLowerCase()) {
		case HARDWARE_PAGES.SELECT_HADRWARE.path:
			content = <SelectHardware isOnboarding={false} />;
			break;
		case HARDWARE_PAGES.CHECKOUT.path:
			content = (
				<HardwareCheckout
					onFinishCheckout={() =>
						navigateToPage(`/${HARDWARE_PAGES.SELECT_HADRWARE.path}`)
					}
				/>
			);
			break;
		case HARDWARE_PAGES.HARDWARE_BUNDLES.path:
			content = <HardwareBundlesView />;
			break;
		case HARDWARE_PAGES.EDIT_BUNDLE.path:
		case HARDWARE_PAGES.CREATE_BUNDLE.path:
			content = <EditBundle />;
			break;
		default:
			content = <SelectHardware isOnboarding={false} />;
			break;
	}

	const { isDrawerOpen } = useAppSelector(selectHardwareDrawerState);

	useEffect(() => {
		// Hide Userback widget when drawer is open
		try {
			isDrawerOpen ? window?.Userback?.hide() : window?.Userback?.show();
		} catch (error) {}
	}, [isDrawerOpen]);

	const isPushablePage =
		page !== HARDWARE_PAGES.HARDWARE_BUNDLES.path && page !== HARDWARE_PAGES.CHECKOUT.path;
	const shouldPushContentLeft =
		isDrawerOpen &&
		window.innerWidth >= INNER_WIDTH_LARGE_ENOUGH_TO_PUSH_CONTENT &&
		isPushablePage;

	return (
		<Grid container className={styles.wrapper}>
			<Grid
				container
				className={clsx(styles.hardwarePageContainer, {
					[styles.pushContentLeft]: shouldPushContentLeft,
				})}
			>
				<HardwareNavigation />
				{content}
			</Grid>
			<SwapProductDialog />
		</Grid>
	);
};

export { HardwareView };
