import { createAsyncThunk } from "@reduxjs/toolkit";
import { LicensePriceDTO } from "types";
import { LICENSE_PRICES_FOLDER_PATH, SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";

export const fetchLicensePrices = createAsyncThunk(
	"licenses/fetchLicensePrices",
	apiGet<LicensePriceDTO>(
		(authConfig) =>
			authConfig.DATA_LAKE_API_ENDPOINTS.GET_CUSTOMER_DATA(LICENSE_PRICES_FOLDER_PATH),
		[SCOPES.DATA_LAKE_READ],
	),
);
