import { Grid, Typography } from "@mui/material";
import clsx from "clsx";
import { LicenseAssignmentAndPurchase, SubscriptionVariantGroup } from "types";
import { useMemo } from "react";
import { PurchaseLicenseCard } from "./PurchaseLicenseCard/PurchaseLicenseCard";
import { useAppSelector } from "hooks/hooks";
import { licenseSubscriptionsSelectors } from "features/licenses/licenses";

import styles from "./PurchaseLicenses.module.scss";

interface PurchaseLicensesProps {
	allSelectedLicenses: {
		id: string;
		assignedBy: string;
	}[];
	userIdsSelectedForAssignment: string[];
	purchaseAndAssignments: LicenseAssignmentAndPurchase;
	chosenVariants: { [key: string]: string };
	handleSelectVariant: (skuId: string, chosenSubscriptionId: string) => void;
}

export const PurchaseLicenses = ({
	allSelectedLicenses,
	userIdsSelectedForAssignment,
	purchaseAndAssignments,
	chosenVariants,
	handleSelectVariant,
}: PurchaseLicensesProps) => {
	const licenses = useAppSelector(licenseSubscriptionsSelectors.selectEntities);

	const totalPurchaseQuantity = useMemo(() => {
		return Object.values(purchaseAndAssignments.purchases ?? {}).reduce(
			(total, purchase) => total + purchase.quantity,
			0,
		);
	}, [purchaseAndAssignments]);

	const content = useMemo(() => {
		const anyUsersSelected = userIdsSelectedForAssignment.length > 0;
		const anyLicensesSelected = allSelectedLicenses.length > 0;
		const anyLicensesForPurchase = Object.values(purchaseAndAssignments.purchases).length > 0;

		if (!anyUsersSelected || !anyLicensesSelected) {
			let text = "";
			if (!anyUsersSelected && !anyLicensesSelected) {
				text += "users and licenses";
			} else if (!anyLicensesSelected) {
				text += "licenses";
			} else {
				text += "users";
			}
			return (
				<Grid container className={styles.noneSelected}>
					<Typography>
						Purchase options will be shown here when you have selected {text}.
					</Typography>
				</Grid>
			);
		} else if (!anyLicensesForPurchase) {
			return (
				<Grid container className={styles.noneSelected}>
					<Typography>
						No licenses need to be purchased for the selected values.
					</Typography>
				</Grid>
			);
		} else {
			return (
				<Grid container item gap={2} mt={2}>
					{allSelectedLicenses.map((license, index) => (
						<PurchaseLicenseCard
							key={license.id}
							chosenVariants={chosenVariants}
							purchaseQuantity={
								purchaseAndAssignments.purchases[license.id]?.quantity ?? 0
							}
							handleSelectVariant={handleSelectVariant}
							license={licenses[license.id] ?? ({} as SubscriptionVariantGroup)}
							skuInformation={
								purchaseAndAssignments.skuInformation[license.id] ?? ({} as any)
							}
						/>
					))}
				</Grid>
			);
		}
	}, [
		userIdsSelectedForAssignment,
		allSelectedLicenses,
		purchaseAndAssignments.purchases,
		licenses,
		chosenVariants,
		handleSelectVariant,
		purchaseAndAssignments.skuInformation,
	]);

	return (
		<>
			<Grid item xs={11} className={styles.marginLeft}>
				<Typography
					variant="caption"
					color="text.secondary"
					className={clsx({
						[styles.hidden]: totalPurchaseQuantity === 0,
					})}
				>
					To be able to assign selected licenses you need to purchase{" "}
					{totalPurchaseQuantity} additional licenses.
				</Typography>
			</Grid>
			{content}
		</>
	);
};
