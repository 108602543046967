import { ApproverType } from "utilities/constants/enums";

export const hardwareApproverRoleDescription = (roleType: ApproverType) => {
	const globalApproverDescription =
		"This role empowers you to authorize hardware requests created with the Ironstone Portal. This ensures that you can oversee and approve necessary equipment for your entire organization efficiently.";
	const managerApproverDescription =
		"This role enables you to authorize hardware requests created with the Ironstone Portal. This ensures that you can oversee and approve necessary equipment for your indirect reports efficiently.";
	switch (roleType) {
		case ApproverType.Global:
			return globalApproverDescription;
		case ApproverType.Manager:
			return managerApproverDescription;
		default:
			return globalApproverDescription;
	}
};
