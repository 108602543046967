import { get, post } from "utilities/rest.ts";
import { SCOPES } from "utilities/constants/constants.ts";

const updateRoles = async (
	instance,
	account,
	authConfig,
	roleId,
	addUsersToRole,
	removeUsersFromRole,
) => {
	const token = await instance.acquireTokenSilent({
		scopes: [SCOPES.GRAPH_WRITE],
		account,
	});

	const endpoints = authConfig.MS_GRAPH_API_ENDPOINTS;

	const roleAssignmentResponse = await post({
		uri: endpoints.UPDATE_APP_ROLES_ASSIGNMENTS(),
		tokenType: token.tokenType,
		accessToken: token.accessToken,
		body: JSON.stringify({
			roleId: roleId,
			addUsersToRole: addUsersToRole,
			removeUsersFromRole: removeUsersFromRole,
		}),
		contentType: "application/json",
		extraHeaders: {
			"Ocp-Apim-Subscription-Key": authConfig.OCP_APIM_SUBSCRIPTION_KEY,
		},
	});

	if (roleAssignmentResponse?.status === 201) {
		return roleAssignmentResponse.data;
	}

	return null;
};

const getAppRoleAssignments = async (instance, account, authConfig, roleId = "") => {
	const token = await instance.acquireTokenSilent({
		scopes: [SCOPES.GRAPH_READ],
		account,
	});

	const endpoints = authConfig.MS_GRAPH_API_ENDPOINTS;

	const { data, status } = await get(
		endpoints.GET_APP_ROLES_ASSIGNMENTS(),
		token.tokenType,
		token.accessToken,
		{
			"Ocp-Apim-Subscription-Key": authConfig.OCP_APIM_SUBSCRIPTION_KEY,
			"Cache-Control": "no-cache",
		},
	);

	if (status !== 200) {
		return {
			appRoleAssignments: [],
			status: 404,
		};
	}

	return {
		appRoleAssignments: data,
		status: status,
	};
};

const ensureAnyPortalAdmins = async (instance, account, authConfig) => {
	const adminValue = "Admin";
	try {
		if (account?.idTokenClaims?.roles?.includes(adminValue)) {
			return true;
		}

		const { appRoleAssignments, status } = await getAppRoleAssignments(
			instance,
			account,
			authConfig,
			"",
		);

		// In the case of a 404, it means we've not yet found the app role assignments.
		// Any admins should thus be considered false
		// This helps us handle cases where the app role assignments have not yet been created,
		// due to lag in azure after the enterprise app has been created
		if (status === 404) {
			return false;
		}

		const anyAdmins =
			appRoleAssignments.find((role) => role.roleValue === adminValue)?.roleAssignedUsers
				?.length > 0;

		// Most likely this will be true, and the method will return here
		if (anyAdmins) {
			return true;
		}

		// In the odd case where this is the first admin, we need to add the user to the admin role
		const update = await updateRoles(
			instance,
			account,
			authConfig,
			appRoleAssignments.find((role) => role.roleValue === adminValue).roleId,
			[account.idTokenClaims.oid],
			[],
		);

		if (update) {
			return true;
		}

		return false;
	} catch (error) {
		return false;
	}
};

export { updateRoles, getAppRoleAssignments, ensureAnyPortalAdmins };
