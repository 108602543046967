import { Typography } from "@mui/material";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { useAuth } from "hooks/hooks";
import { handleLogout } from "utilities/handleLogout";

export const DemoSetupDialog = () => {
	const {
		auth: { account, instance },
	} = useAuth();

	const onLogout = () => {
		handleLogout({ account, instance });
	};

	const accountIsFromIronstoneTenant = account?.username?.includes("ironstone");

	const showDialog = !accountIsFromIronstoneTenant;

	return (
		showDialog && (
			<PrimaryDialog
				title="Demo Account Required"
				primaryAction={onLogout}
				primaryButtonText="Logout"
				size="medium"
			>
				<Typography display="inline">You are logged in with the account </Typography>
				<Typography display="inline" color="text.secondary">
					({account.username})
				</Typography>
				<Typography display="inline">
					, which is not an Ironstone demo account. Please log out and sign in with the
					designated demo account received in the email.
				</Typography>
				<br />
				<br />
				<Typography>
					If login issues persist, try using a different browser or an incognito window.
				</Typography>
			</PrimaryDialog>
		)
	);
};
