import { Grid, Skeleton, Typography } from "@mui/material";
import { useAppSelector } from "hooks/hooks";
import { selectLicensePrices } from "features/licenses/licensePrices";
import { License, LicensePrice } from "types";

const LicensePriceDetails = ({ license }: { license: License }): JSX.Element => {
	const { entities: licensePrices, isLoading } = useAppSelector(selectLicensePrices);

	const price = licensePrices[license.chosenVariant?.costIdentifier] ?? ({} as LicensePrice);

	const getLicensePriceDetails = () => {
		if (isLoading) {
			return (
				<Grid item>
					<Skeleton variant="text" width={80} height={30} />
				</Grid>
			);
		}

		if (license.unusedLicenses <= 0)
			return (
				<Grid item>{`${Math.round(price.UnitPriceAfterDiscount ?? 0)} / ${
					price.CostPeriod
				}`}</Grid>
			);

		return (
			<Grid container direction="row">
				<Typography variant="body1" fontWeight={500}>
					Available
				</Typography>
			</Grid>
		);
	};

	return <>{getLicensePriceDetails()}</>;
};

export { LicensePriceDetails };
