import { GA_TEMPLATE_ROLE_ID } from "utilities/constants/constants.ts";

export const isGa = (token) => {
	const { wids } = getValuesFromToken(token) ?? [];

	return wids.includes(GA_TEMPLATE_ROLE_ID);
};

const getValuesFromToken = (token) => {
	if (!token) {
		return null;
	}

	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(""),
	);

	return JSON.parse(jsonPayload);
};

export default getValuesFromToken;
