import { Grid, Skeleton } from "@mui/material";
import type { ReactElement } from "react";

export function ServiceStatusSkeleton(): ReactElement {
	return (
		<Grid container direction="column">
			<Skeleton variant="text" width={100} height={30} />
			<Skeleton variant="text" width={200} height={30} />
		</Grid>
	);
}
