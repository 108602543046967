import { Grid, Typography } from "@mui/material";

import styles from "./TableCellGrid.module.scss";

interface TableCellGridProps {
	title: string;
	children: React.ReactNode;
	width: string;
	windowWidth: number;
}

export const TableCellGrid = ({ title, children, width, windowWidth }: TableCellGridProps) => {
	const MEDIUM_SIZE_WINDOW = 900;
	const showTitleOfCell = windowWidth <= MEDIUM_SIZE_WINDOW;
	return (
		<Grid container item width={width} className={styles.tableCell}>
			{showTitleOfCell && (
				<Typography variant="description" fontWeight={400} mb={1} color={"text.secondary"}>
					{title}
				</Typography>
			)}
			{children}
		</Grid>
	);
};
