import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { HardwareDrawerType, HardwarePurchaseType, SwapType } from "utilities/constants/enums";

const initialState = {
	hardwareDrawerState: {
		activeDrawerType: HardwareDrawerType.Cart,
		isDrawerOpen: false,
		headerText: "",
		primaryButtonText: "",
		secondaryButtonText: "",
		ariaLabel: "",
	},
	swapProductState: {
		isOpen: false,
		productId: "",
		count: 1,
		activeSwapType: SwapType.SwapWithBundleProduct,
	},
	bundleIdToDelete: "",
	hardwarePurhcaseType: HardwarePurchaseType.Buy,
};

const hardwarePageSlice = createSlice({
	name: "hardwarePage",
	initialState: initialState,
	reducers: {
		setHardwareDrawerOpen: (
			state,
			{ payload }: { payload: { drawerType: HardwareDrawerType; forceOpen?: boolean } },
		) => {
			state.hardwareDrawerState.activeDrawerType = payload.drawerType;
			if (window.innerWidth > 2250 || payload.forceOpen) {
				state.hardwareDrawerState.isDrawerOpen = true;
			}
		},
		setHardwareDrawerClosed: (state) => {
			state.hardwareDrawerState.isDrawerOpen = false;
		},
		setHardwareDrawerState: (state, { payload }) => {
			state.hardwareDrawerState = {
				...state.hardwareDrawerState,
				...payload,
			};
		},
		setBundleIdToDelete: (state, { payload }) => {
			state.bundleIdToDelete = payload;
		},
		setProductIdToSwap: (state, { payload }) => {
			state.swapProductState.productId = payload;
		},
		setCountToSwap: (state, { payload }) => {
			state.swapProductState.count = payload;
		},
		setProductSwapDialogState: (state, { payload }) => {
			const { isOpen } = payload || { isOpen: false };
			state.swapProductState.isOpen = isOpen;
		},
		setSwapType: (state, { payload }) => {
			state.swapProductState.activeSwapType = payload;
		},
		setHardwarePurchaseType: (state, { payload }) => {
			state.hardwarePurhcaseType = payload;
		},
	},
});

export const selectBundleIdToDelete = (state: RootState) => state.hardwarePage.bundleIdToDelete;

export const selectHardwareDrawerState = (state: RootState) =>
	state.hardwarePage.hardwareDrawerState;

export const selectProductSwapDialogState = (state: RootState) =>
	state.hardwarePage.swapProductState;

export const selectAddToSwapType = (state: RootState) => {
	const currentSwapType = state.hardwarePage.swapProductState.activeSwapType;
	if (currentSwapType === SwapType.SwapWithBundleProduct) {
		return SwapType.AddToCart;
	} else {
		return SwapType.AddToBundle;
	}
};

export const selectHardwarePurchaseType = (state: RootState) =>
	state.hardwarePage.hardwarePurhcaseType;

export const {
	setHardwareDrawerOpen,
	setHardwareDrawerClosed,
	setHardwareDrawerState,
	setBundleIdToDelete,
	setProductIdToSwap,
	setProductSwapDialogState,
	setSwapType,
	setCountToSwap,
	setHardwarePurchaseType,
} = hardwarePageSlice.actions;

export default hardwarePageSlice.reducer;
