import { CircularProgress, Grid, Typography } from "@mui/material";
import {
	selectActiveEntitlement,
	selectGuestRedeemInvitationUrl,
	setActiveStep,
} from "features/entitlements";
import { useAppSelector, useAuth } from "hooks/hooks";
import { PrimaryButton } from "components/Common/Buttons/Button";

import styles from "./GuestInvitation.module.scss";

interface GuestInvitationProps {
	isCreatingEntitlement: boolean;
}

export const GuestInvitation = ({ isCreatingEntitlement }: GuestInvitationProps) => {
	const {
		dispatch,
		auth: { account },
	} = useAuth();

	const redeemUrl = useAppSelector(selectGuestRedeemInvitationUrl);

	const handleAcceptInvitation = () => {
		dispatch(setActiveStep(2));
		redeemUrl && window.open(redeemUrl as string, "_blank");
	};

	const activeEntitlement = useAppSelector(selectActiveEntitlement);

	return (
		<Grid container className={styles.switchingContainer}>
			<Typography variant="h2" fontWeight={500}>
				Creating entitlement for {activeEntitlement?.departmentName}
			</Typography>
			{isCreatingEntitlement ? (
				<CircularProgress />
			) : (
				<Typography variant="body1">
					Accept invitation to get access to {activeEntitlement?.departmentName} for user{" "}
					{account.name}
				</Typography>
			)}
			<PrimaryButton
				variantStyle="contained"
				text="Accept invitation"
				action={handleAcceptInvitation}
				isDisabled={redeemUrl === null}
				size="small"
			/>
		</Grid>
	);
};
