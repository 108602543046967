import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomerInfo } from "types";
import { SCOPES } from "utilities/constants/constants";
import { apiGet, apiPost } from "utilities/api";

export const fetchPossibleEntitlements = createAsyncThunk(
	"entitlements/fetch",
	apiGet<CustomerInfo[]>(
		(authConfig) => authConfig.FRESHSERVICE_API_ENDPOINTS.GET_ENTITLEMENTS(),
		[SCOPES.FRESHSERVICE_READ],
	),
);

export const fetchAvailableEntitlements = createAsyncThunk(
	"entitlements/fetchAvailable",
	apiGet<string[]>(
		(authConfig, { params: { id } }: any) =>
			authConfig.MS_GRAPH_API_ENDPOINTS.GET_AVAILABLE_ENTITLEMENTS(id),
		[SCOPES.GRAPH_READ],
	),
);

export const createEntitlement = createAsyncThunk(
	"entitlements/create",
	apiPost(
		(authConfig, { id }: any) => {
			return authConfig.MS_GRAPH_API_ENDPOINTS.CREATE_ENTITLEMENT(id);
		},
		[SCOPES.GRAPH_WRITE],
	),
);
