import { Grid, Typography, Skeleton } from "@mui/material";

import styles from "./MFAChart.module.scss";
import { LegendItem } from "components/Common/LegendItem";

interface MFALegendInfoInputProps {
	isLoading: boolean;
	title: string;
	value: string;
	color: string;
	onClick: () => void;
}

const MFALegendInfo = ({ isLoading, title, value, color, onClick }: MFALegendInfoInputProps) => {
	return (
		<div className={styles.underline}>
			<LegendItem
				mainLabel={
					<Grid container item xs={12}>
						<Grid item xs={12}>
							<span className={styles.inlineAlign}>
								<Typography variant="body1" display="inline">
									{title}
								</Typography>
								{isLoading ? (
									<Skeleton variant="text" width={50} height={20} />
								) : (
									<Typography variant="body1">{value} Users</Typography>
								)}
							</span>
						</Grid>
					</Grid>
				}
				noPadding
				color={color}
				onClick={onClick}
			/>
		</div>
	);
};

export { MFALegendInfo };
