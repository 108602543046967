import { Dictionary } from "@reduxjs/toolkit";
import {
	LicenseAssignmentAndPurchase,
	LicenseAssignmentConflict,
	SubscribedSku,
	SubscriptionVariant,
	SubscriptionVariantGroup,
	User,
} from "types";
import { MUTEX_LICENSES } from "utilities/constants/constants";
import { LicenseActionOperationType, TermDuration } from "utilities/constants/enums";

// Looping through all users and license info to check if we need to:
// 1. Purchase new licenses
// 2. Directly assign licenses to users
// 3. Assign user to groups
export const getPurchasesAndAssignments = (
	allVariantsBySubId: { [subscriptionId: string]: SubscriptionVariant }, // All variants with subscriptionId as key
	subscriptionVariantGroups: Dictionary<SubscriptionVariantGroup>,
	subscribedSkus: Dictionary<SubscribedSku>,
	selectedUsers: User[],
	chosenVariants: { [skuId: string]: string }, // Chosen variant with skuId as key and sub Id as value
	chosenGroups: { [groupId: string]: string[] },
) => {
	const licensesInChosenGroups = Object.values(chosenGroups).flatMap((licenses) => licenses);
	const skusForDirectAssignment = Object.keys(chosenVariants).filter(
		(sku) => !licensesInChosenGroups.includes(sku),
	);

	let allAssignmentsBySkuId = {} as { [skuId: string]: number };
	let directAssignments = {} as { [userId: string]: string[] };
	let groupAssignments = {} as { [userId: string]: string[] };
	let calculatedBySkuId = {} as {
		[skuId: string]: { assignmentQuantity: number; availableQuantity: number };
	};

	selectedUsers.forEach((user: User) => {
		const userSkus = user.licenses.map((license) => license.skuId);
		const userGroups = user.groups.map((group) => group.id);

		const skusForAssignmentOnUser = skusForDirectAssignment.reduce((acc, sku) => {
			const skuMissingOnUser = !userSkus.includes(sku);
			if (!skuMissingOnUser) {
				return acc;
			}

			acc[sku] = (acc[sku] ?? 0) + 1;

			return acc;
		}, {} as { [skuId: string]: number });

		Object.keys(skusForAssignmentOnUser).forEach((sku) => {
			const skus = directAssignments[user.id] ?? [];
			directAssignments[user.id] = [...skus, sku];
			allAssignmentsBySkuId[sku] = (allAssignmentsBySkuId[sku] ?? 0) + 1;
		});

		const groupsForAssigment = Object.keys(chosenGroups).filter(
			(groupId) => !userGroups.includes(groupId),
		);

		groupsForAssigment.forEach((groupId) => {
			const groupIds = groupAssignments[user.id] ?? [];
			groupAssignments[user.id] = [...groupIds, groupId];
			const licenses = chosenGroups[groupId];
			licenses.forEach((license) => {
				allAssignmentsBySkuId[license] = (allAssignmentsBySkuId[license] ?? 0) + 1;
			});
		});
	});

	let assignmentsRequiringAdjustment = {
		purchases: {},
		directAssignments: directAssignments,
		groupAssignments: groupAssignments,
		skuInformation: calculatedBySkuId,
	} as LicenseAssignmentAndPurchase;

	// Check which of the assignments that require adjustment => licenses that need to be purchased
	Object.entries(allAssignmentsBySkuId).forEach(([sku, assignmentQuantity]) => {
		const availableUnits =
			subscribedSkus[sku]!.prepaidUnits.enabled - subscribedSkus[sku]!.consumedUnits;
		assignmentsRequiringAdjustment.skuInformation = {
			...assignmentsRequiringAdjustment.skuInformation,
			[sku]: {
				assignmentQuantity,
				availableQuantity: availableUnits,
			},
		};
		const purchaseQuantity = getNumPurchasesRequired(assignmentQuantity, subscribedSkus[sku]);

		// The subscription identifier might either be the guid of the subscription, or the term duration
		// If the identifier is the term duration, it means that the customer has yet to buy this exact subscription term for this license
		const subscriptionIdentifier = chosenVariants[sku];
		const subscriptionVariant = allVariantsBySubId[subscriptionIdentifier];
		if (purchaseQuantity > 0) {
			const variantGroup = subscriptionVariantGroups[sku] ?? ({} as SubscriptionVariantGroup);
			const costIdentifier = subscriptionVariant
				? subscriptionVariant.costIdentifier
				: variantGroup.costIdentifiers[subscriptionIdentifier as TermDuration];
			assignmentsRequiringAdjustment.purchases[sku] = {
				purchaseType: subscriptionVariant
					? LicenseActionOperationType.AdjustLicenseCount
					: LicenseActionOperationType.CreateNewSubscription,
				skuId: sku,
				variant: subscriptionVariant
					? subscriptionVariant
					: ({
							friendlyName:
								subscriptionVariantGroups[sku]?.friendlyName ?? "New subscription",
							provisioningId: sku,
							commitmentEndDate: null,
					  } as unknown as SubscriptionVariant),
				termDuration: (subscriptionVariant
					? subscriptionVariant.termDuration
					: subscriptionIdentifier) as TermDuration,
				costIdentifier,
				quantity: purchaseQuantity,
				friendlyName: variantGroup.friendlyName,
			};
		}
	});

	return assignmentsRequiringAdjustment;
};

const getNumPurchasesRequired = (numAssignments: number, subscribedSku?: SubscribedSku) => {
	// We should not really end up here if the sku is not in the subscribedSkus -> Should really log an error
	if (!subscribedSku) return 0;

	// Check if the subscribed SKU has enough licenses to cover the number of assignments
	const availableLicenses = Math.max(
		subscribedSku.prepaidUnits.enabled - subscribedSku.consumedUnits,
		0,
	);
	const numLicensesForPurchase = availableLicenses - numAssignments;

	// If there are enough licenses, we don't need to purchase any
	return numLicensesForPurchase >= 0 ? 0 : Math.abs(numLicensesForPurchase);
};

export const getPotentialConflicts = (
	purchaseAndAssignments: LicenseAssignmentAndPurchase,
	users: Dictionary<User>,
	licenses: Dictionary<SubscriptionVariantGroup>,
	allLicensesByGroup: Dictionary<string[]>,
) => {
	const potentialConflicts: LicenseAssignmentConflict[] = [];

	const allUniqueUserIds = Array.from(
		new Set([
			...Object.keys(purchaseAndAssignments.directAssignments),
			...Object.keys(purchaseAndAssignments.groupAssignments),
		]),
	);

	allUniqueUserIds.forEach((userId) => {
		const alreadyAssignedLicenses = users[userId]?.licenses.map(({ skuId }) => skuId) ?? [];

		const directAssignments = purchaseAndAssignments.directAssignments[userId] ?? [];
		const licensesAssignedByGroups =
			purchaseAndAssignments.groupAssignments[userId]?.flatMap(
				(groupId) => allLicensesByGroup[groupId] ?? [],
			) ?? [];

		const allLicensesForAssignments = [
			...directAssignments,
			...licensesAssignedByGroups,
		] as string[];

		const assignedMutexLicenses = alreadyAssignedLicenses.filter((skuId) =>
			MUTEX_LICENSES.some((mutexLicense) =>
				licenses[skuId]?.friendlyName.toUpperCase().includes(mutexLicense.toUpperCase()),
			),
		);

		const licensesForAssignmentInMutex = allLicensesForAssignments.filter((skuId) =>
			MUTEX_LICENSES.some((mutexLicense) =>
				licenses[skuId]?.friendlyName.toUpperCase().includes(mutexLicense.toUpperCase()),
			),
		);
		let anyConflicts = false;

		if (assignedMutexLicenses.length > 0 && licensesForAssignmentInMutex.length > 0) {
			// If there are any previously assigned license in the mutex group
			// AND any license to be assigned also in the mutex group, we have a potential conflict
			anyConflicts = true;
		}

		if (licensesForAssignmentInMutex.length > 1) {
			// If there are more than one license in the mutex group of the licenses that
			// are to be assigned, we have a potential conflict
			anyConflicts = true;
		}

		if (anyConflicts) {
			const uniqueConflictingIds = Array.from(
				new Set([...assignedMutexLicenses, ...licensesForAssignmentInMutex]),
			);
			// If there are more than one license in the mutex group, we have a potential conflict
			potentialConflicts.push({
				userId,
				skuIds: uniqueConflictingIds,
			});
		}
	});

	return potentialConflicts;
};
