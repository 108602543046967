import { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import { UserConfigurator } from "../UserConfigurator";
import { ToggleableTitle } from "../ToggleableTitle";
import { ConfiguratorUserTypes } from "utilities/constants/enums";

import styles from "./WorkspaceConfigurator.module.scss";

const WorkspaceConfigurator = ({
	title,
	description,
	dataTypeName,
	creators = [],
	approvers = [],
	handleChange,
	enabled,
	isDataLoaded,
	data,
}) => {
	useEffect(() => {
		if (isDataLoaded) {
			setLoading(false);
		}
	}, [isDataLoaded]);

	const [loading, setLoading] = useState(true);

	return (
		<>
			{loading ? (
				<Grid container className={styles.paper}>
					<Grid item xs={12}>
						<Skeleton variant="wave" height={100} />
					</Grid>
				</Grid>
			) : (
				<Grid container className={styles.paper}>
					<Grid item xs={4} className={styles.workspaceTitle}>
						<ToggleableTitle
							title={title}
							description={description}
							propertyName={`${dataTypeName}Enabled`}
							enabled={enabled}
							handleChange={handleChange}
							data={data}
						/>
					</Grid>
					<Grid item className={styles.container} xs={4}>
						<UserConfigurator
							title={title}
							dataTypeName={`${dataTypeName}Data`}
							propertyName={ConfiguratorUserTypes.Creators}
							selectedUsers={creators}
							handleChange={handleChange}
							enabled={enabled}
							data={data}
						/>
					</Grid>
					<Grid item className={styles.container} xs={4}>
						<UserConfigurator
							title="Internal Collaboration Approvers"
							dataTypeName={`${dataTypeName}Data`}
							propertyName={ConfiguratorUserTypes.Approvers}
							selectedUsers={approvers}
							handleChange={handleChange}
							enabled={enabled}
							data={data}
							customOptions={[
								{ displayName: "Line Manager", type: "ROLE", id: "LINE_MANAGER" },
								{
									displayName: "Department Manager",
									type: "ROLE",
									id: "DEPARTMENT_MANAGER",
								},
							]}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export { WorkspaceConfigurator };
