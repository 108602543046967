import { Grid, Skeleton } from "@mui/material";
import type { ReactElement } from "react";

export function RiskNotificationSkeleton(): ReactElement {
	return (
		<Grid container direction="column" justifyContent="center" alignItems="center" pt={4}>
			<Skeleton variant="text" width={50} height={25} />
			<Skeleton variant="text" width={100} height={25} />
		</Grid>
	);
}
