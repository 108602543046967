import { Grid } from "@mui/material";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import styles from "./FilterDialog.module.scss";
import { FilterGroupProps, FilterGroup } from "../FilterGroup";

interface Props extends FilterGroupProps {
	onClose: () => void;
}

const FilterDialog = ({ onClose, ...restFilterGroupProps }: Props) => {
	return (
		<PrimaryDialog
			title="Filters"
			primaryButtonText={"Close"}
			size="large"
			primaryAction={onClose}
			onLeaveAction={onClose}
		>
			<Grid container className={styles.wrapper}>
				<FilterGroup {...restFilterGroupProps} />
			</Grid>
		</PrimaryDialog>
	);
};

export { FilterDialog };
