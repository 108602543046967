import {
	LicenseActionOperationType,
	LicenseActionStatus,
	TermDuration,
} from "utilities/constants/enums";
import {
	LicenseAction,
	LicenseSubscriptionRecommendation,
	StatusInfo,
	PurchaseInfo,
	RefundableQuantity,
} from "types";
import dayjs from "dayjs";
import { AccountInfo } from "@azure/msal-browser";
import { LICENSE_ACTION_DATE_FORMAT } from "utilities/constants/constants";

import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CartIcon from "@mui/icons-material/ShoppingCart";
import UpdateIcon from "@mui/icons-material/Update";
import RestoreIcon from "@mui/icons-material/Restore";
import PauseIcon from "@mui/icons-material/Pause";
import CancelIcon from "@mui/icons-material/Cancel";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import clsx from "clsx";

import styles from "./licenseIconStyles.module.scss";

interface LicenseActionBuilderProps {
	confirmationMailRecipient: string;
	recommendation: LicenseSubscriptionRecommendation;
	changes: any;
	action: any;
}

interface RecommendedActionCreationProps {
	customerName: string;
	account: AccountInfo;
	recommendation: LicenseSubscriptionRecommendation;
	changes: any;
	confirmationMailRecipient: string;
}

interface CancelLicenseActionsProps {
	licenseAction: LicenseAction;
	username: string;
}

enum TargetType {
	Subscription = "Subscription",
	User = "User",
}

export const buildCancelActionBody = ({ username, licenseAction }: CancelLicenseActionsProps) => {
	return {
		...licenseAction,
		Status: LicenseActionStatus.Cancelled,
		StatusInfo: [
			...licenseAction.StatusInfo,
			{
				Status: LicenseActionStatus.Cancelled,
				Message: `Cancelled by user ${username}`,
				ActivityDate: dayjs().format(LICENSE_ACTION_DATE_FORMAT),
				OrderedBy: username ?? "Could not find username",
			},
		],
	} as LicenseAction;
};

export const buildRecommendationActionBody = ({
	customerName,
	account,
	recommendation,
	changes,
	confirmationMailRecipient,
}: RecommendedActionCreationProps) => {
	const action = {
		GroupActionId: crypto.randomUUID(),
		TenantId: account?.idTokenClaims?.tid,
		CustomerName: customerName,
		OrderedBy: account.username,
		OrderedByDisplayName: account.name,
	};

	return removeUnusedLicenses({ action, recommendation, changes, confirmationMailRecipient });
};

const removeUnusedLicenses = ({
	action,
	recommendation,
	changes,
	confirmationMailRecipient,
}: LicenseActionBuilderProps) => {
	const bothActionTypesPresent =
		changes.softDeleteUsers.length > 0 && changes.removeLicenses.length > 0;
	// Removes idle licenses from a subscription.
	// Scheduled for the day after the subscription end date
	// Soft deletes users specified in the recommendation
	const softDeleteUsersRecommendationBodies =
		changes.softDeleteUsers.map((change: any) => {
			const { id, userPrincipalName } = change;
			const softDeleteMessage = `Soft deleting user ${userPrincipalName}`;
			const orderDate = dayjs().format(LICENSE_ACTION_DATE_FORMAT);
			// Execution date is set to two days after commitment end date to be sure that the license removal happens after renewal has been
			const executionDate = dayjs()
				.add(1, "day")
				.startOf("day")
				.format(LICENSE_ACTION_DATE_FORMAT);

			return {
				PartitionKey: action.TenantId,
				GroupActionId: action.GroupActionId,
				ActionId: Math.random().toString(36).substring(2, 11),
				ConfirmationMailRecipient: confirmationMailRecipient,
				TenantId: action.TenantId,
				CustomerName: action.CustomerName,
				Operation: LicenseActionOperationType.SoftDeleteUser,
				TargetType: TargetType.User,
				TargetGUID: id,
				SkuGUID: recommendation.subscriptionSkuID,
				TargetFriendlyName: userPrincipalName,
				QuantityChange: -1,
				Status: LicenseActionStatus.Scheduled,
				StatusInfo: [
					{
						Status: LicenseActionStatus.Scheduled,
						Message: softDeleteMessage,
						OrderedBy: action.OrderedBy,
						ActivityDate: orderDate,
					},
				] as StatusInfo[],
				RecommendedAction: true, // Indicates whether the action was recommended by the system
				ExecutionDate: executionDate,
				Description: softDeleteMessage,
				OrderedBy: action.OrderedBy,
				OrderedByDisplayName: action.OrderedByDisplayName,
				OrderDate: orderDate,
				TotalSavings: 0,
			} as LicenseAction;
		}) ?? [];

	const removeUnusedLicensesRecommendationBodies =
		changes.removeLicenses.map((change: any) => {
			const { removalQuantity, subscription } = change;
			const absRemovalQuantity = Math.abs(removalQuantity);
			const { subscriptionOfferName } = recommendation;
			const message = `Adjusting license count for ${subscriptionOfferName} downwards by ${Math.abs(
				absRemovalQuantity,
			)}`;
			const orderDate = dayjs().format(LICENSE_ACTION_DATE_FORMAT);
			const executionDate = calculateExecutionDateForRecommendedAction(
				subscription.commitmentEndDate,
				removalQuantity,
				subscription.refundableQuantity,
			);

			return {
				PartitionKey: action.TenantId,
				GroupActionId: bothActionTypesPresent ? action.GroupActionId : "",
				ActionId: Math.random().toString(36).substring(2, 11),
				ConfirmationMailRecipient: confirmationMailRecipient,
				TenantId: action.TenantId,
				CustomerName: action.CustomerName,
				Operation: LicenseActionOperationType.AdjustLicenseCount,
				TargetType: TargetType.Subscription,
				TargetGUID: subscription.id,
				SkuGUID: recommendation.subscriptionSkuID,
				TargetFriendlyName: subscriptionOfferName,
				QuantityChange: -absRemovalQuantity,
				Status: LicenseActionStatus.Scheduled,
				StatusInfo: [
					{
						Status: LicenseActionStatus.Scheduled,
						Message: message,
						OrderedBy: action.OrderedBy,
						ActivityDate: orderDate,
					},
				] as StatusInfo[],
				RecommendedAction: true, // Indicates whether the action was recommended by the system
				ExecutionDate: executionDate,
				Description: message,
				OrderedBy: action.OrderedBy,
				OrderedByDisplayName: action.OrderedByDisplayName,
				OrderDate: orderDate,
				TotalSavings: absRemovalQuantity * Number(subscription.unitPrice),
			} as LicenseAction;
		}) ?? [];

	return [...softDeleteUsersRecommendationBodies, ...removeUnusedLicensesRecommendationBodies];
};

export const calculateExecutionDateForRecommendedAction = (
	commitmentEndDate: Date,
	removalQuantity: number,
	refundableQuantities: RefundableQuantity[],
) => {
	let executionDate = dayjs(commitmentEndDate).add(1, "day").format(LICENSE_ACTION_DATE_FORMAT);

	// If refundable quantities are available, we try to remove licenses that are refundable immediately
	const dateTimeOfWantedRefundableRemoval = dayjs().startOf("day");
	const numRefundableLicenses = refundableQuantities?.reduce((acc, curr) => {
		if (dayjs(dateTimeOfWantedRefundableRemoval).isBefore(dayjs(curr.allowedUntilDateTime))) {
			return acc + curr.quantity;
		}

		return acc;
	}, 0);

	if (Math.abs(removalQuantity) <= numRefundableLicenses) {
		executionDate = dateTimeOfWantedRefundableRemoval.format(LICENSE_ACTION_DATE_FORMAT);
	}

	return executionDate;
};

interface CreateLicenseActionProps {
	customerName: string;
	account: AccountInfo;
	operationType: LicenseActionOperationType;
	targetFriendlyName: string;
	variant: {
		subscriptionId: string;
		provisioningId: string;
		commitmentEndDate: Date;
		refundableQuantity?: RefundableQuantity[];
	};
	confirmationMailRecipient: string;
	quantityChange?: number;
	purchaseInfo?: PurchaseInfo;
}

export const createLicenseActionBody = ({
	customerName,
	account,
	operationType,
	targetFriendlyName,
	variant,
	confirmationMailRecipient,
	quantityChange = 0,
	purchaseInfo,
}: CreateLicenseActionProps) => {
	const action = {
		GroupActionId: crypto.randomUUID(),
		TenantId: account?.idTokenClaims?.tid,
		CustomerName: customerName,
		OrderedBy: account.username,
		OrderedByDisplayName: account.name,
	};

	const description = `Executing ${operationType} for ${targetFriendlyName}`;
	const calculatedQuantityChange = LicenseActionOperationType.AdjustLicenseCount
		? quantityChange
		: 0;

	const executionDate = calculateExecutionDateForAdjustment(
		variant,
		quantityChange,
		variant.refundableQuantity,
	);
	const now = dayjs().format(LICENSE_ACTION_DATE_FORMAT);

	return {
		PartitionKey: action.TenantId,
		GroupActionId: "",
		ActionId: Math.random().toString(36).substring(2, 11),
		TenantId: action.TenantId,
		CustomerName: action.CustomerName,
		Operation: operationType,
		TargetType: TargetType.Subscription,
		TargetGUID: variant.subscriptionId,
		SkuGUID: variant.provisioningId,
		TargetFriendlyName: targetFriendlyName,
		QuantityChange: calculatedQuantityChange,
		Status: LicenseActionStatus.Scheduled,
		StatusInfo: [
			{
				Status: LicenseActionStatus.Scheduled,
				Message: `Executing ${operationType} for ${targetFriendlyName}`,
				OrderedBy: action.OrderedBy,
				ActivityDate: now,
			},
		] as StatusInfo[],
		RecommendedAction: false,
		ExecutionDate: executionDate,
		Description: description,
		OrderedByDisplayName: action.OrderedByDisplayName,
		OrderedBy: action.OrderedBy,
		OrderDate: now,
		ConfirmationMailRecipient: confirmationMailRecipient,
		TotalSavings: 0, // No "savings to be made" for actions that are not recommended
		...(purchaseInfo && { PurchaseInfo: purchaseInfo }),
	} as LicenseAction;
};

const calculateExecutionDateForAdjustment = (
	variant: { commitmentEndDate: Date },
	quantityChange: number,
	refundableQuantity?: RefundableQuantity[],
) => {
	// Execution date is always today at 00.00, since we want to execute all actions immediately
	// except for removals, which are executed the day after the subscription end date
	if (quantityChange < 0) {
		return calculateExecutionDateForRecommendedAction(
			variant.commitmentEndDate,
			quantityChange,
			refundableQuantity ?? [],
		);
	}

	return dayjs().startOf("day").format(LICENSE_ACTION_DATE_FORMAT);
};

export const getIconAndExplanationText = (
	licenseAction: LicenseAction,
	termDuration?: string,
	subscriptionEndDate?: string,
) => {
	let icon = <></>;
	let actionText = "";
	let descriptionText = "";

	switch (licenseAction.Operation) {
		case LicenseActionOperationType.AdjustLicenseCount:
			let termDurationPretty = "";
			if (termDuration) {
				termDurationPretty = termDuration === TermDuration.P1M ? "monthly" : "annual";
			}
			actionText = `Licenses ${licenseAction.QuantityChange > 0 ? "purchased" : "removed"}`;
			descriptionText = `${Math.abs(
				licenseAction.QuantityChange,
			)} x ${termDurationPretty} licenses`;
			if (licenseAction.Status === LicenseActionStatus.Cancelled) {
				icon = <HighlightOffIcon className={styles.highlightOff} />;
				break;
			}
			icon =
				licenseAction.QuantityChange > 0 ? (
					<CartIcon className={styles.cartIcon} />
				) : (
					<CalendarMonthIcon
						className={clsx({
							[styles.calendarIcon]: true,
						})}
					/>
				);
			break;
		case LicenseActionOperationType.AutorenewOff:
			actionText = `Autorenewal turned off`;
			descriptionText = `Subscription end date ${subscriptionEndDate}`;
			icon = <RestoreIcon className={styles.updateIcon} />;
			break;
		case LicenseActionOperationType.AutorenewOn:
			actionText = `Autorenewal turned on`;
			descriptionText = `Subscription end date ${subscriptionEndDate}`;
			icon = <UpdateIcon className={styles.updateIconGreen} />;
			break;
		case LicenseActionOperationType.SuspendSubscription:
			actionText = `Subscription suspended`;
			descriptionText = `Subscription end date ${subscriptionEndDate}`;
			icon = <PauseIcon className={styles.pauseIcon} />;
			break;
		case LicenseActionOperationType.CancelSubscription:
			actionText = `Subscription cancelled`;
			descriptionText = `Subscription end date ${subscriptionEndDate}`;
			icon = <CancelIcon className={styles.cancelIcon} />;
			break;
		case LicenseActionOperationType.SoftDeleteUser:
			actionText = `Deleted user`;
			descriptionText = `${licenseAction.TargetFriendlyName}`;
			icon = <PersonRemoveIcon className={styles.cancelIcon} />;
			break;
		case LicenseActionOperationType.CreateNewSubscription:
			let shownTermDuration = "";
			if (licenseAction.PurchaseInfo?.TermDuration) {
				shownTermDuration =
					licenseAction.PurchaseInfo.TermDuration === TermDuration.P1M
						? "monthly"
						: "annual";
			}
			actionText = `Licenses purchased`;
			descriptionText = `New subscription: ${licenseAction.QuantityChange} x ${shownTermDuration} licenses`;
			icon = <CartIcon className={styles.cartIcon} />;
			break;
		default:
			break;
	}

	return { icon, actionText, descriptionText };
};
