import { MouseEventHandler } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Grid,
	Typography,
} from "@mui/material";
import styles from "./Dialog.module.scss";

import CloseIcon from "@mui/icons-material/Close";
import {
	PrimaryButton,
	SecondaryButton,
	PrimaryDeleteButton,
} from "components/Common/Buttons/Button";
import clsx from "clsx";
import { LoadingDialogStep } from "types";
import { getLoadingStatusIcon } from "utilities/loadingStatus";

interface PrimaryDialogProps {
	title?: string;
	//TODO: Enum
	size: "small" | "medium" | "large";
	primaryAction?: MouseEventHandler;
	onLeaveAction?: MouseEventHandler;
	disabledActions?: boolean;
	isLoading?: boolean;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	isDestructiveAction?: boolean;
	removeContentMarginAndPadding?: boolean;
	expandedHeight?: boolean;
	customButtonComponent?: React.ReactNode;
	zIndex?: number;
	children?: React.ReactNode;
}

const PrimaryDialog = ({
	title,
	size,
	primaryAction,
	onLeaveAction,
	disabledActions,
	isLoading,
	primaryButtonText,
	secondaryButtonText,
	isDestructiveAction,
	removeContentMarginAndPadding,
	expandedHeight,
	customButtonComponent,
	zIndex,
	children,
}: PrimaryDialogProps): JSX.Element => {
	const usedPaperStyle = styles[`${size}DialogPaper`];
	const usedDialogActionsStyle = styles[`${size}DialogActions`];
	const usedContentStyle = styles[`${size}DialogContent`];
	const usedSpacingStyle = removeContentMarginAndPadding
		? styles[`noDialogContentSpacing`]
		: styles[`${size}DialogContentSpacing`];

	return (
		<Dialog
			open={true}
			onClose={onLeaveAction}
			className={clsx(styles.baseDialogPaper, {
				[styles.fullscreenDialogForSmallerScreens]: size !== "small",
			})}
			style={{ zIndex: zIndex }}
		>
			<div
				className={clsx(usedPaperStyle, { [styles.expandedHeight]: expandedHeight })}
				data-testid="dialog.primary"
			>
				{title && (
					<DialogTitle className={`${styles.dialogTitle} ${styles.usedPaperStyle}`}>
						{title}
						{onLeaveAction && (
							<IconButton
								data-testid="closeicon"
								aria-label="close"
								onClick={onLeaveAction}
								className={styles.closeButton}
							>
								<CloseIcon />
							</IconButton>
						)}
					</DialogTitle>
				)}
				{children && (
					<DialogContent className={`${usedContentStyle} ${usedSpacingStyle}`}>
						{children}
					</DialogContent>
				)}

				{(primaryButtonText || secondaryButtonText || customButtonComponent) && (
					<DialogActions className={usedDialogActionsStyle}>
						{primaryButtonText && primaryAction && (
							<>
								{isDestructiveAction ? (
									<PrimaryDeleteButton
										text={primaryButtonText}
										size="medium"
										isLoading={isLoading}
										action={primaryAction}
										variantStyle="contained"
										marginRight={2}
										isDisabled={disabledActions}
									/>
								) : (
									<PrimaryButton
										data-testid="button.primary"
										text={primaryButtonText}
										size="medium"
										isLoading={isLoading}
										isDisabled={disabledActions}
										action={primaryAction}
										variantStyle="contained"
										marginRight={2}
									/>
								)}
							</>
						)}
						{customButtonComponent}
						{secondaryButtonText && (
							<SecondaryButton
								text={secondaryButtonText ? secondaryButtonText : ""}
								size="medium"
								isDisabled={isLoading}
								action={onLeaveAction ? onLeaveAction : () => {}}
								variantStyle="outlined"
							/>
						)}
					</DialogActions>
				)}
			</div>
		</Dialog>
	);
};

interface LoadingDialogProps {
	open: boolean;
	title: string;
	steps: LoadingDialogStep[];
	primaryAction: MouseEventHandler;
	primaryButtonText: string;
	disableActions: boolean;
	onLeaveAction?: MouseEventHandler;
}

const LoadingDialog = ({
	open,
	title,
	steps,
	primaryAction,
	primaryButtonText,
	onLeaveAction,
	disableActions,
}: LoadingDialogProps): JSX.Element => {
	return (
		<Dialog open={open} onClose={onLeaveAction} className={styles.loadingDialog}>
			<Grid className={styles.loadingDialogContent}>
				<Typography variant="h2" className={styles.loadingDialogTitle}>
					{title}
				</Typography>
				<Grid className={styles.loadingStepContainer}>
					{steps.map((step, index) => {
						const icon = getLoadingStatusIcon(step.status);
						const text = step.title;
						return (
							<Grid className={styles.loadingStep} key={index}>
								{icon}
								{text}
							</Grid>
						);
					})}
				</Grid>
			</Grid>
			<Grid className={styles.loadingDialogActions}>
				<PrimaryButton
					text={primaryButtonText}
					size="medium"
					variantStyle="contained"
					action={primaryAction}
					isDisabled={disableActions}
				/>
			</Grid>
		</Dialog>
	);
};

export { PrimaryDialog, LoadingDialog };
