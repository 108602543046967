import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchMailDomains } from "actions/mailDomainActions";
import { RootState } from "store";

interface MailDomain {
	id: number;
	name: string;
}

const mailDomainsAdapter = createEntityAdapter<MailDomain>();

const mailDomainsSlice = createSlice({
	name: "mailDomains",
	initialState: mailDomainsAdapter.getInitialState({
		isLoading: true,
		isFetching: false,
		default: -1,
	}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchMailDomains.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchMailDomains.fulfilled, (state, { payload }) => {
				let id = 0;
				const mailDomains = [];
				for (const { name, isDefault } of payload) {
					if (isDefault) state.default = id;
					mailDomains.push({ id: id++, name });
				}
				mailDomainsAdapter.setAll(state, mailDomains);
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

export const selectMailDomains = (state: RootState) => state.mailDomains;

export const mailDomainsSelectors = mailDomainsAdapter.getSelectors(selectMailDomains);

export const selectDefaultMailDomain = (state: RootState) =>
	mailDomainsSelectors.selectById(state, state.mailDomains.default);

export default mailDomainsSlice.reducer;
