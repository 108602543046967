import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { InputWrapper } from "components/Common/InputWrapper";
import { AutoCompleteWithFetch } from "components/Common/AutocompleteWithFetch";
import { useAppSelector, useAuth } from "hooks/hooks";
import { requestersSelectors } from "features/requesters";
import { CompanyApprover, Requester } from "types";
import useRole from "utilities/roleUtils/roleCheck";
import { usersSelectors } from "features/users";
import { selectCustomerDetails } from "features/customer";
import { setGlobalSnackbarState } from "features/snackbars";

import styles from "./CompanyRoles.module.scss";

interface CompanyRolesProps {
	isSaving: boolean;
	selectedDepartmentHead: Requester;
	selectedPrimeUser: Requester;
	selectedCompanyApprovers: CompanyApprover[];
	setSelectedDepartmentHead: (value: Requester) => void;
	setSelectedPrimeUser: (value: Requester) => void;
	setSelectedCompanyApprovers: (value: CompanyApprover[]) => void;
	setCompanyRolesEqualOriginal: (value: boolean) => void;
}

const CompanyRoles = ({
	isSaving,
	selectedDepartmentHead,
	selectedPrimeUser,
	selectedCompanyApprovers,
	setSelectedDepartmentHead,
	setSelectedPrimeUser,
	setSelectedCompanyApprovers,
	setCompanyRolesEqualOriginal,
}: CompanyRolesProps) => {
	const { dispatch } = useAuth();
	const { isAdmin } = useRole();
	const customerInfo = useAppSelector(selectCustomerDetails);
	const freshRequesters = useAppSelector(requestersSelectors.selectAll);
	const users = useAppSelector(usersSelectors.selectEntities);

	useEffect(() => {
		const originalDepartmentHead = customerInfo?.headUserEmail;
		const originalPrimeUser = customerInfo?.primeUserEmail;
		const originalApprovers = customerInfo?.approvers || [];

		const departmentHeadEqual = originalDepartmentHead === selectedDepartmentHead.mail;
		const primeUserEqual = originalPrimeUser === selectedPrimeUser.mail;
		const approversEqual =
			originalApprovers.length === selectedCompanyApprovers.length &&
			originalApprovers.every((approver) =>
				selectedCompanyApprovers.some(
					(selectedApprover) => selectedApprover.mail === approver.mail,
				),
			);

		setCompanyRolesEqualOriginal(departmentHeadEqual && primeUserEqual && approversEqual);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDepartmentHead, selectedPrimeUser, selectedCompanyApprovers, customerInfo]);

	// const handleSetValues = (selectedValues, newValue, identifier, setter) => {
	const handleSetValues = (
		selectedValues: any[],
		newValue: any,
		identifier: string,
		setter: any,
	) => {
		// Clear values if an empty array is passed as "value"
		if (!newValue?.id) {
			setter([]);
			return;
		}

		const itemAlreadyAdded = selectedValues.some(
			(item) => item[identifier] === newValue[identifier],
		);
		let newValues = [];

		// add item
		if (!itemAlreadyAdded) {
			newValues = [...selectedValues, newValue];
		}

		// remove item
		if (itemAlreadyAdded) {
			newValues = selectedValues.filter((item) => item[identifier] !== newValue[identifier]);
		}

		setter(newValues);
		return newValues;
	};

	const handleFreshUserChange = (newValue: Requester, setter: any) => {
		if (!newValue?.mail) {
			setter({ mail: "" });
			return;
		}

		setter(newValue);
	};

	const getData = (searchValue: string) =>
		Object.values(users).filter(
			(user) =>
				user?.mail &&
				user?.displayName.toLowerCase().includes(searchValue.toLowerCase()) &&
				!user?.mail?.toLowerCase().includes("demo-"),
		);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h3" fontWeight={500}>
					Primary users
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Grid container item className={styles.field}>
					<InputWrapper
						label="Company head"
						tooltipText="This is the main technical contact at your company. A company head user can view and approve all the incidents and service requests of their company."
						htmlFor="companyHead"
					>
						<AutoCompleteWithFetch
							multiple={false}
							getData={() => freshRequesters}
							selectedValue={isSaving ? { mail: "" } : selectedDepartmentHead}
							propertyToDisplay="mail"
							propertyToIdentify="mail"
							propertyToGroupBy=""
							handleSetValue={(value: any) =>
								handleFreshUserChange(value, setSelectedDepartmentHead)
							}
							placeholder="Search for users"
							id="companyHead"
							viewMode={!isAdmin}
						/>
					</InputWrapper>
				</Grid>
			</Grid>
			<Grid item xs={6}>
				<Grid container item className={styles.field}>
					<InputWrapper label="Primary user" htmlFor="primeUser">
						<AutoCompleteWithFetch
							multiple={false}
							getData={() => freshRequesters}
							selectedValue={isSaving ? { mail: "" } : selectedPrimeUser}
							propertyToDisplay="mail"
							propertyToIdentify="mail"
							propertyToGroupBy=""
							handleSetValue={(value: any) =>
								handleFreshUserChange(value, setSelectedPrimeUser)
							}
							placeholder="Search for users"
							id="primeUser"
							viewMode={!isAdmin}
						/>
					</InputWrapper>
				</Grid>
			</Grid>
			<Grid item xs={6}>
				<Grid container item className={styles.field}>
					<InputWrapper
						label="Approvers"
						tooltipText="Approvers are users that can approve incidents and service requests of their company. Only users with enabled mail service can be approvers."
						htmlFor="approvers"
					>
						<AutoCompleteWithFetch
							multiple={true}
							getData={getData}
							selectedValues={selectedCompanyApprovers ?? []}
							propertyToDisplay="displayName"
							secondaryPropertyToDisplay="mail"
							propertyToIdentify="mail"
							propertyToGroupBy="department"
							handleSetValues={(value: any) => {
								const newSelectedValues = handleSetValues(
									selectedCompanyApprovers,
									value,
									"mail",
									setSelectedCompanyApprovers,
								) as CompanyApprover[];

								const lengthOfNewApprovers = newSelectedValues
									?.map((approver) => approver.mail)
									.join(",").length;

								if (lengthOfNewApprovers > 256) {
									// 256 is the max length of the approvers field in the database
									// TODO:
									dispatch(
										setGlobalSnackbarState({
											snackbarText: "Max number of approvers reached.",
											snackbarSeverity: "error",
										}),
									);
									handleSetValues(
										selectedCompanyApprovers,
										value,
										"mail",
										setSelectedCompanyApprovers,
									);
									return;
								}
							}}
							placeholder="Search for users"
							id="approvers"
							viewMode={!isAdmin}
						/>
					</InputWrapper>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default CompanyRoles;
