import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { EditUserForm } from "./EditUserForm";
import { MyLicenses } from "./MyLicenses";
import {
	mapObjectStringValues,
	trimAndRemoveDuplicateSpacesBetweenWords,
} from "utilities/stringFormattingUtility";
import { fetchUserDevices, updateUser } from "actions/userActions";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { usersSelectors } from "features/users";
import _ from "lodash";
import styles from "./UserMyProfile.module.scss";
import { fetchLicenseNames } from "actions/licenseNameActions";
import { selectlicenseNames } from "features/licenses/licenseNames";
import { User } from "types";
import { DevicesInfo } from "./DevicesInfo/DevicesInfo";

const UserMyProfile = () => {
	const {
		dispatch,
		auth,
		auth: {
			account: { localAccountId },
		},
	} = useAuth();

	const employeeData =
		useAppSelector((state) => usersSelectors.selectById(state, localAccountId)) ?? ({} as User);
	useApiOnce(fetchLicenseNames, useAppSelector(selectlicenseNames));

	const [isFormDisabled, setIsFormDisabled] = useState(false);

	const onSave = async (updatedData: Partial<User>) => {
		setIsFormDisabled(true);

		const newData = {
			...employeeData,
			...updatedData,
		} as User & { mailPrefix: string };

		mapObjectStringValues(newData, trimAndRemoveDuplicateSpacesBetweenWords);

		// Use the default mail value for extracting mail suffix as editing is disabled
		const { mail, userPrincipalName } = newData;
		const mailSuffix = (mail || userPrincipalName)?.split("@")[1];
		newData.mail = `${newData.mailPrefix.toLowerCase()}@${mailSuffix}`;

		await dispatch(updateUser({ auth, id: employeeData.id, body: newData }));

		setIsFormDisabled(false);
	};

	useEffect(() => {
		if (employeeData?.devices) return;

		dispatch(fetchUserDevices({ auth, id: localAccountId }));
	}, [dispatch, localAccountId, auth, employeeData?.devices]);

	return (
		<Grid container className={styles.wrapper}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{!_.isEmpty(employeeData) && (
							<EditUserForm
								employeeData={employeeData}
								saveForm={onSave}
								isFormDisabled={isFormDisabled}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item className={styles.tableWrapper}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h2">My licenses</Typography>
						</Grid>
						<Grid item xs={12}>
							<MyLicenses licenses={employeeData.licenses ?? []} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item className={styles.tableWrapper}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h2">My devices</Typography>
						</Grid>
						<Grid item xs={12}>
							<DevicesInfo userId={localAccountId} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export { UserMyProfile };
