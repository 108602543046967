import { Grid, Typography } from "@mui/material";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectUserDrawerState, setUserDrawerClosed } from "features/user";
import { usersSelectors } from "features/users";
import { PrimaryDeleteButton } from "components/Common/Buttons/Button";
import { deleteUser } from "actions/userActions";
import { LicenseList } from "components/Common/LicenseList";
import { useState } from "react";
import useRole from "utilities/roleUtils/roleCheck";

const DeleteUser = () => {
	const { activeUserId } = useAppSelector(selectUserDrawerState);
	const user = useAppSelector((state) => usersSelectors.selectById(state, activeUserId));
	const assignedLicenses = user?.licenses ?? [];
	const [isDeleting, setIsDeleting] = useState(false);
	const { isActionRole } = useRole();
	const [error, setError] = useState("");

	const { auth, dispatch } = useAuth();

	const handleDeleteUser = async () => {
		setIsDeleting(true);
		const response = await dispatch(deleteUser({ auth, id: activeUserId }));
		setIsDeleting(false);

		if (response.meta.requestStatus === "fulfilled") {
			dispatch(setUserDrawerClosed());
		} else {
			setError((response as any).error?.message);
		}
	};

	return (
		<Grid item xs={12}>
			<Typography variant="body1" my={4}>
				The user account can be recovered within 30 days after being deleted. After 30 days,
				the user account and mailbox are permanently deleted and not recoverable.
			</Typography>
			{assignedLicenses.length > 0 && (
				<>
					<Typography variant="body1">
						All licenses will be made available 5 - 10 minutes after deletion.
					</Typography>
					<Grid my={4}>
						<Typography variant="h6">Licenses to be removed from user</Typography>
						<LicenseList licenses={assignedLicenses} readView />
					</Grid>
				</>
			)}
			<PrimaryDeleteButton
				text="Delete user"
				size="medium"
				action={handleDeleteUser}
				variantStyle="contained"
				isLoading={isDeleting}
				isDisabled={!isActionRole}
				disabledDescription="You do not have permission to delete users, this requires an admin role."
			/>
			{error && <Typography color="error">{error}</Typography>}
		</Grid>
	);
};

export { DeleteUser };
