import { Typography, TooltipProps, TypographyProps } from "@mui/material";
import { HoverTooltip } from "../Tooltips";

import styles from "./TruncatableTypography.module.scss";

interface TruncatableTypographyProps extends TypographyProps {
	children?: string;
	maxCharLength: number;
	tooltipTitle?: string;
	tooltipPlacement?: TooltipProps["placement"];
}

const TruncatableTypography = ({
	children,
	maxCharLength,
	tooltipTitle = "",
	tooltipPlacement = "top",
	...typographyProps
}: TruncatableTypographyProps) => {
	if (!children) return null;
	const isTruncated = children.length > maxCharLength;

	const displayText = isTruncated ? `${children.substring(0, maxCharLength)}...` : children;

	return (
		<HoverTooltip
			title={tooltipTitle}
			hide={!isTruncated}
			description={children}
			placement={tooltipPlacement}
		>
			<Typography
				{...typographyProps}
				className={`${styles.defaultStyle} ${typographyProps.className || ""}`}
			>
				{displayText}
			</Typography>
		</HoverTooltip>
	);
};

export { TruncatableTypography };
