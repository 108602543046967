import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	TableContainer,
	Skeleton,
} from "@mui/material";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

import styles from "./InvoiceTable.module.scss";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

import { Invoice } from "types";
import { InvoicePaymentStatus } from "../InvoicePaymentStatus/InvoicePaymentStatus";
import _ from "lodash";
import SummaryTableFooter from "components/Common/SummaryTableFooter/SummaryTableFooter";
import { selectInvoiceDownloadStatus } from "features/invoices";
import { DownloadableDocument } from "components/Common/DownloadableDocument";
import { DocumentType } from "utilities/constants/enums";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface InvoiceTableProps {
	isLoading: boolean;
	invoices: Invoice[];
	selectedRangeDisplay: string;
	shouldShowTableFooter: boolean;
	onResetFilters: () => void;
}

const InvoiceTable = ({
	isLoading,
	invoices,
	selectedRangeDisplay,
	shouldShowTableFooter,
	onResetFilters,
}: InvoiceTableProps) => {
	const selectedInvoicesCost = invoices.reduce((acc, invoice) => acc + invoice?.amount, 0);

	const tableHeadWidths = {
		referenceNumber: "10%",
		date: "10%",
		description: "20%",
		status: "10%",
		amount: "10%",
		exportInvoiceLink: "10%",
	};

	const getAmount = ({ documentType, amount }: Invoice) =>
		documentType?.toUpperCase() === "CREDITNOTE" ? -amount : amount;

	return (
		<TableContainer>
			<Table>
				<TableHead className={styles.tableHeader}>
					<TableRow>
						<TableCell width={tableHeadWidths.referenceNumber}>
							<Typography variant="body1" fontWeight={500}>
								Reference number
							</Typography>
						</TableCell>
						<TableCell width={tableHeadWidths.date}>
							<Typography variant="body1" fontWeight={500}>
								Due date
							</Typography>
						</TableCell>
						<TableCell width={tableHeadWidths.description}>
							<Typography variant="body1" fontWeight={500}>
								Description
							</Typography>
						</TableCell>
						<TableCell width={tableHeadWidths.status}>
							<Typography variant="body1" fontWeight={500} noWrap>
								Status
							</Typography>
						</TableCell>
						<TableCell className={styles.costTypeCell} width={tableHeadWidths.amount}>
							<Typography
								variant="body1"
								fontWeight={500}
								noWrap
								className={styles.costTypeCellText}
							>
								Amount (inc. VAT)
							</Typography>
						</TableCell>
						<TableCell width={tableHeadWidths.exportInvoiceLink}>
							<Typography variant="body1" fontWeight={500}>
								Export invoice
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{!isLoading && invoices?.length === 0 && (
						<TableRow classes={{ root: styles.rowStyle }}>
							<TableCell colSpan={8}>
								<Typography variant="body1" align="center">
									No invoices found with current filters
								</Typography>
							</TableCell>
						</TableRow>
					)}
					{!isLoading
						? invoices.map((invoice: Invoice) => {
								return (
									<TableRow
										key={invoice.referenceNumber}
										classes={{ root: styles.rowStyle }}
									>
										<TableCell className={styles.ticketCell}>
											<Typography variant="body1" noWrap>
												{invoice.referenceNumber}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1">
												{invoice.documentDueDate
													? dayjs(invoice.documentDueDate).format(
															"DD.MM.YYYY",
													  )
													: "No due date"}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1" noWrap>
												{invoice.invoiceText}
											</Typography>
										</TableCell>
										<TableCell>
											<InvoicePaymentStatus
												status={invoice.status}
												documentType={invoice.documentType}
											/>
										</TableCell>
										<TableCell className={styles.costTypeCell}>
											<Typography variant="body1">
												{formatCostString("", getAmount(invoice), " ,-")}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1" noWrap>
												<DownloadableDocument
													documentType={DocumentType.Invoice}
													id={invoice.referenceNumber}
													documentTitle={`Download ${
														invoice.documentType ?? "invoice"
													}`}
													selector={selectInvoiceDownloadStatus}
													documentDescription={invoice.invoiceText}
													fileType="pdf"
												/>
											</Typography>
											<Typography
												variant="description"
												color="text.secondary"
												ml={3}
											>
												{invoice.attachments?.length > 0
													? `+ ${invoice.attachments.length} attachment${
															invoice.attachments.length > 1
																? "s"
																: ""
													  }`
													: ""}
											</Typography>
										</TableCell>
									</TableRow>
								);
						  })
						: _.range(20).map((num: number) => (
								<TableRow key={num} classes={{ root: styles.rowStyle }}>
									{Object.entries(tableHeadWidths).map(([key, value]) => (
										<TableCell key={key}>
											<Skeleton variant="text" height={50} />
										</TableCell>
									))}
								</TableRow>
						  ))}
				</TableBody>
				<SummaryTableFooter
					padding={false}
					onResetFilters={onResetFilters}
					summaryCells={[
						{
							label: "Date interval",
							value: selectedRangeDisplay,
						},
						{
							label: "Total invoices",
							value: invoices.length,
						},
						{
							label: "Total cost",
							value: formatCostString("", selectedInvoicesCost, " ,-"),
						},
					]}
					visible={shouldShowTableFooter}
				/>
			</Table>
		</TableContainer>
	);
};

export { InvoiceTable };
