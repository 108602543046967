import { createAsyncThunk } from "@reduxjs/toolkit";
import { SCOPES } from "utilities/constants/constants";
import { apiGet } from "utilities/api";
import { Branding } from "types";

export const fetchCustomerBranding = createAsyncThunk(
	"groups/fetchCustomerBranding",
	apiGet<Branding>(
		(authConfig: any) => authConfig.MS_GRAPH_API_ENDPOINTS.GET_BRANDING(),
		[SCOPES.GRAPH_READ],
	),
);
