import { useEffect } from "react";
import { useAppSelector, useAuth } from "hooks/hooks";
import { selectRoles } from "features/roles";
import { selectCustomerDetails } from "features/customer";
import { useLocation } from "react-router-dom";

const useHotjar = () => {
	const roles = useAppSelector(selectRoles);
	const customerInfo = useAppSelector(selectCustomerDetails);
	const {
		auth: { account },
	} = useAuth();
	const { pathname } = useLocation();

	useEffect(() => {
		if (!account) return;
		const userId = account.idTokenClaims?.oid ?? "NO_USER_ID_FOUND";
		const tid = account.idTokenClaims?.tid ?? "NO_TENANT_ID_FOUND";
		const window = globalThis as any;
		if (window?.hj && typeof window.hj === "function" && customerInfo.isLoading === false) {
			window.hj("identify", userId, {
				role: JSON.stringify(roles),
				companyName: customerInfo?.departmentName ?? "",
				tenantId: tid,
				userName: account.name ?? "",
			});
		}
		// Pathname as a dependency, as per hotjars best practices, we identify on every page change
	}, [account, customerInfo, roles, pathname]);
};

export { useHotjar };
