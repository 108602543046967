import { ReactComponent as WindowsIcon } from "assets/operatingSystemIcons/Windows.svg";
import { ReactComponent as AppleIcon } from "assets/operatingSystemIcons/Apple.svg";
import { ReactComponent as AndroidIcon } from "assets/operatingSystemIcons/Android.svg";

export const getOperatingSystemIcon = (os: string) => {
	if (os.toUpperCase().includes("WINDOWS")) {
		return <WindowsIcon />;
	} else if (
		os.toUpperCase().includes("MAC") ||
		os.toUpperCase().includes("IOS") ||
		os.toUpperCase().includes("IPHONE")
	) {
		return <AppleIcon />;
	} else if (os.toUpperCase().includes("ANDROID")) {
		return <AndroidIcon />;
	}
};
