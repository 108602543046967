import { useState } from "react";
import {
	FormControl,
	RadioGroup,
	Radio,
	FormControlLabel,
	TextField,
	Box,
	Grid,
	Skeleton,
} from "@mui/material";
import { REGEX } from "utilities/constants/constants";

import styles from "./ArchivalSettingsConfigurator.module.scss";

const ArchivalSettingsConfigurator = ({ isLoading, handleChange, archivalSettings = {} }) => {
	const [helperText, setHelperText] = useState("");
	const [mailHelperText, setMailHelperText] = useState("");
	const radioValues = [31, 60, 90];
	const showLifecycleDaysInTextField = !radioValues.includes(
		archivalSettings?.groupLifetimeInDays,
	);

	const onhandleLifecycleDaysChange = (value) => {
		const propertyName = "groupLifetimeInDays";
		if (radioValues.includes(value)) {
			setHelperText("");
			handleChange(propertyName, value);
			return;
		}

		const asInt = parseInt(value, 10) || "";
		if (asInt < 31 || asInt > 365) {
			setHelperText("31 - 365");
		}

		if (30 < asInt && asInt <= 365) {
			setHelperText("");
		}

		handleChange(propertyName, asInt);
	};

	const onHandleAlternateMailChange = (propertyName, value) => {
		const valid = REGEX.MAIL_COMPLETE.test(value);
		const showHelperText = value.length > 10;

		if (showHelperText && !valid) {
			setMailHelperText("Invalid email");
		} else {
			setMailHelperText("");
		}

		handleChange(propertyName, value);
	};

	return (
		<>
			{isLoading ? (
				<Grid container>
					<Grid item xs={11}>
						<Skeleton variant="wave" height={60} width={400} />
					</Grid>
					<Grid item xs={1}>
						<Skeleton variant="wave" height={60} width={400} />
					</Grid>
				</Grid>
			) : (
				<FormControl>
					<Grid container className={styles.container}>
						<Grid item xs={11}>
							<RadioGroup
								row
								aria-label="archive-after-days-radio"
								className={styles.radioContainer}
							>
								{radioValues.map((value, i) => (
									<FormControlLabel
										key={i}
										labelPlacement="start"
										control={
											<Radio
												checked={
													archivalSettings.groupLifetimeInDays === value
												}
												onChange={() => onhandleLifecycleDaysChange(value)}
												size="small"
											/>
										}
										label={<Box fontSize={16}>{value}</Box>}
										className={styles.radioBtn}
									/>
								))}
								<TextField
									className={styles.textField}
									variant="outlined"
									size="medium"
									inputProps={{
										className: styles.inputText,
									}}
									InputLabelProps={{
										shrink: true,
										className: styles.inputLabel,
									}}
									onChange={(e) => {
										onhandleLifecycleDaysChange(e.target.value);
									}}
									value={
										showLifecycleDaysInTextField
											? archivalSettings.groupLifetimeInDays
											: ""
									}
									error={helperText !== ""}
									helperText={helperText}
									label="Custom"
								/>
							</RadioGroup>
						</Grid>
						<Grid item xs={1}>
							<TextField
								className={styles.mailField}
								variant="outlined"
								size="medium"
								inputProps={{
									className: styles.inputText,
								}}
								InputLabelProps={{
									shrink: true,
									className: styles.inputLabel,
								}}
								onChange={(e) => {
									onHandleAlternateMailChange(
										"alternateNotificationEmails",
										e.target.value,
									);
								}}
								value={
									archivalSettings
										? archivalSettings.alternateNotificationEmails
										: ""
								}
								error={mailHelperText !== ""}
								helperText={mailHelperText}
								label="Contact mail"
								placeholder="Contact mail for groups without owner"
							/>
						</Grid>
					</Grid>
				</FormControl>
			)}
		</>
	);
};

export { ArchivalSettingsConfigurator };
