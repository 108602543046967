import { selectLicensePrices } from "features/licenses/licensePrices";
import { licensePricesSelectors } from "features/licenses/licensePrices";
import { useAppSelector } from "hooks/hooks";
import { LicensePrice, SubscriptionVariantGroup } from "types";
import { TermDuration } from "utilities/constants/enums";
import { FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import { getIcon } from "utilities/microsoftIconsUtils";
import clsx from "clsx";
import { HoverTooltip } from "components/Common/Tooltips";
import { formatCostString } from "utilities/currency/numberFormattingUtility";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import InfoIconOutlined from "@mui/icons-material/InfoOutlined";

import commonStyles from "../PurchaseLicenses.module.scss";
import styles from "./PurchaseLicenseCard.module.scss";

interface PurchaseLicenseCardProps {
	license: SubscriptionVariantGroup;
	chosenVariants: { [key: string]: string };
	purchaseQuantity: number;
	handleSelectVariant: (skuId: string, chosenSubscriptionId: string) => void;
	skuInformation: { assignmentQuantity: number; availableQuantity: number };
}

export const PurchaseLicenseCard = ({
	license,
	chosenVariants,
	purchaseQuantity,
	handleSelectVariant,
	skuInformation,
}: PurchaseLicenseCardProps) => {
	const monthlyLicense = license.variants.find(
		(variant) => variant.termDuration === TermDuration.P1M,
	);
	const annualLicense = license.variants.find(
		(variant) => variant.termDuration === TermDuration.P1Y,
	);

	if (purchaseQuantity === 0) return null;

	// Monthly option NOT be shown if
	// Customer does not have this term already, and the assignment quantity is <= available quantity
	const hideMonthlyOption =
		!monthlyLicense && skuInformation.assignmentQuantity <= skuInformation.availableQuantity;
	const hideAnnualOption =
		!annualLicense && skuInformation.assignmentQuantity <= skuInformation.availableQuantity;

	// Default to term duration if subscription id is not available
	// => customer does NOT have this term duration for this license, it needs to be purchased
	// during the assignment process
	let monthlySubscriptionIdentifier = monthlyLicense?.subscriptionId ?? TermDuration.P1M;
	let annualSubscriptionIdentifier = annualLicense?.subscriptionId ?? TermDuration.P1Y;

	let monthlyCostIdentifier = monthlyLicense?.costIdentifier ?? "";
	let annualCostIdentifier = annualLicense?.costIdentifier ?? "";
	if (!monthlyLicense?.costIdentifier) {
		monthlyCostIdentifier = annualCostIdentifier.replace(TermDuration.P1Y, TermDuration.P1M);
		// Swap defualt checked if customer does not have a monthly subscription on this license
	} else if (!annualLicense?.costIdentifier) {
		annualCostIdentifier = monthlyCostIdentifier?.replace(TermDuration.P1M, TermDuration.P1Y);
	}

	let monthlySelected = false;
	let annualSelected = false;

	if (chosenVariants[license.provisioningId] === monthlySubscriptionIdentifier) {
		monthlySelected = true;
	} else if (chosenVariants[license.provisioningId] === annualSubscriptionIdentifier) {
		annualSelected = true;
	} else {
		// Prefer monthly as default selected if customer has monthly subscription on this license
		// TODO: Move this into "license settings" in the future, so that the customer can choose
		// which term duration they prefer as default
		monthlyLicense?.costIdentifier ? (monthlySelected = true) : (annualSelected = true);
	}

	const icon = getIcon({ iconName: license.friendlyName });

	return (
		<Grid container className={styles.purchaseLicenseCard}>
			<Grid container item gap={1} alignItems={"center"}>
				{icon && (
					<svg width="20" height="20" viewBox="0 0 100 100">
						{icon}
					</svg>
				)}
				<Typography variant="description">{license.friendlyName}</Typography>
			</Grid>
			<Grid
				item
				className={clsx({
					[styles.hidden]: purchaseQuantity === 0,
					[styles.licensesToBePurchasedInfo]: true,
				})}
			>
				<ShoppingCartOutlinedIcon className={commonStyles.shoppingCartIcon} />
				<Typography variant="caption">
					{purchaseQuantity} licenses to be purchased
				</Typography>
			</Grid>
			<Grid container className={commonStyles.commitmentDurationSelector}>
				<Grid
					item
					onClick={() =>
						handleSelectVariant(license.provisioningId, monthlySubscriptionIdentifier)
					}
				>
					<TermDurationSelectorCard
						termDuration={TermDuration.P1M}
						newSubscription={!monthlyLicense}
						licenseName={license.friendlyName}
						costIdentifier={monthlyCostIdentifier}
						isSelectedTermDuration={monthlySelected}
						hidePurchaseOption={hideMonthlyOption}
					/>
				</Grid>
				<Grid
					item
					onClick={() =>
						handleSelectVariant(license.provisioningId, annualSubscriptionIdentifier)
					}
				>
					<TermDurationSelectorCard
						termDuration={TermDuration.P1Y}
						newSubscription={!annualLicense}
						licenseName={license.friendlyName}
						costIdentifier={annualCostIdentifier}
						isSelectedTermDuration={annualSelected}
						hidePurchaseOption={hideAnnualOption}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

interface TermDurationSelectorCardProps {
	licenseName: string;
	termDuration: TermDuration;
	newSubscription: boolean;
	costIdentifier: string;
	isSelectedTermDuration: boolean;
	hidePurchaseOption: boolean;
}

export const TermDurationSelectorCard = ({
	licenseName,
	termDuration,
	newSubscription,
	costIdentifier,
	isSelectedTermDuration,
	hidePurchaseOption,
}: TermDurationSelectorCardProps) => {
	const { isLoading: isLoadingPrices } = useAppSelector(selectLicensePrices);
	const licensePrices = useAppSelector(licensePricesSelectors.selectEntities);
	const hidePrice = !isLoadingPrices && !licensePrices[costIdentifier];
	const termDurationpretty = termDuration === TermDuration.P1M ? "Monthly" : "Annual";
	const hideOption = hidePurchaseOption || (hidePrice && newSubscription);
	const priceObject = licensePrices[costIdentifier] ?? ({} as LicensePrice);
	return (
		<Grid
			item
			className={clsx([styles.commitmentItem], {
				[styles.selectedItem]: isSelectedTermDuration,
				[styles.notAvailable]: hideOption,
				[styles.marginRight]: termDuration === TermDuration.P1M,
			})}
		>
			{newSubscription && (
				<HoverTooltip
					title="Creating new subscription"
					description={`You currently have no ${licenseName} licenses on a ${termDurationpretty.toLowerCase()} subscription. Selecting this subscription duration will create a new subscription upon purchase`}
				>
					<Grid
						item
						className={clsx({
							[styles.newSubscriptionLabel]: true,
							[styles.selectedLabel]: isSelectedTermDuration,
						})}
					>
						<Typography variant="caption" className={commonStyles.newSubscriptionText}>
							New subscription
						</Typography>
						<InfoIconOutlined className={commonStyles.infoIcon} />
					</Grid>
				</HoverTooltip>
			)}
			<FormControlLabel
				value="1"
				control={<Radio size="small" />}
				label={<Typography variant="description">{termDurationpretty}</Typography>}
				checked={isSelectedTermDuration}
				className={styles.labelStyle}
			/>
			<Typography variant="caption" color="text.secondary">
				{hidePrice ? "No price available" : "Price per license"}
			</Typography>
			<Typography variant="description">
				{hidePrice
					? "-"
					: formatCostString("", priceObject?.UnitPriceAfterDiscount ?? 0, " ,-")}
				{` / ${priceObject?.CostPeriod ?? ""}`}
			</Typography>
		</Grid>
	);
};
