import { useState } from "react";
import {
	Autocomplete,
	Checkbox,
	TextField,
	CircularProgress,
	Grid,
	Typography,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import _ from "lodash";
import clsx from "clsx";
import { HoverTooltip } from "../Tooltips/Tooltips";

import styles from "./AutoCompleteWithFetch.module.scss";
import { TruncatableTypography } from "../TruncateableTypography";

const AutoCompleteWithFetch = (props) => {
	if (!props.multiple) {
		return <AutoCompleteWithFetchSingle {...props} />;
	}

	return <AutoCompleteWithFetchMultiple {...props} />;
};

/*
 * This component is a wrapper around the Material UI Autocomplete component.
 * It is used to fetch data from the backend and display it in a dropdown.
 * It also allows for multiple selections.
 *
 * Let's try to keep this component as generic as possible, and reuse it as much as possible.
 */
const AutoCompleteWithFetchMultiple = ({
	getData, // Function that retrieves data to be displayed in the dropdown
	selectedValues, // Selected values, is always an array
	propertyToDisplay, // Typically "name" | "displayName" | "prettyName" | "mail" etc.
	secondaryPropertyToDisplay, // Typically "mail", "id", "upn" etc.
	propertyToDisplayEnd, // Secondary property to display, typically "memberCount" | "licenseCount" etc.
	propertyToIdentify, // Typically "id" | "uuid" | "oid" | "tenantId" etc.
	propertyToGroupBy, // Typically "user type" | "group type" | "role type" etc.
	handleSetValues, // Function that sets the selected values, this can be tailored in the parent component
	endPropertyExplanation = "", // Used to explain the secondary property to display, typically "members" | "licenses" etc.
	placeholder = "",
	displaySelectedValues = true,
	propertyShownOnHover,
	viewMode = false,
}) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState([]);

	const onHandleSearch = ({ currentTarget: { value } }) => {
		initializeSearch(value);
	};

	const initializeSearch = (searchValue) => {
		setLoading(true);
		const fetchedOptions = getData(searchValue);
		const withSelectedValues = [...selectedValues, ...fetchedOptions];
		// add the fetched options to the list of options if it doesn't exist in the options list already
		const newOptions = withSelectedValues?.filter(
			(option) => !options.some((o) => o[propertyToIdentify] === option[propertyToIdentify]),
		);
		const sorted = _.sortBy(newOptions, propertyToGroupBy);
		const unique = _.uniqBy(sorted, propertyToIdentify);
		setOptions([...options, ...unique]);
		setLoading(false);
	};

	const limitNumberOfTagsBasedOnSize = () => {
		let limitTags = 0;
		let space = 48; // Heuristic value that fits most component widths
		if (!selectedValues) return limitTags;
		for (const value of selectedValues.map(({ displayName }) => displayName)) {
			space -= value?.length;
			if (space <= 0) break;
			limitTags++;
		}
		return limitTags;
	};

	return (
		<Autocomplete
			data-testid="autocomplete.with.fetch"
			id="autocomplete-with-fetch"
			multiple
			open={open}
			limitTags={limitNumberOfTagsBasedOnSize()}
			onOpen={() => {
				initializeSearch("");
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			classes={{ paper: styles.dropdownStyle }}
			groupBy={(option) => option[propertyToGroupBy]}
			value={selectedValues}
			options={options}
			disableCloseOnSelect
			isOptionEqualToValue={(option, value) =>
				option[propertyToIdentify] === value[propertyToIdentify]
			}
			fullWidth
			renderTags={(value, getTagProps) =>
				displaySelectedValues ? (
					<div className={styles.tagContainer}>
						<PeopleIcon />
						<Typography variant="body1" fontWeight={500}>
							{value.length}
						</Typography>
					</div>
				) : null
			}
			onChange={handleSetValues}
			disableClearable={viewMode}
			getOptionLabel={(option) => option[propertyToDisplay] ?? ""}
			renderOption={(props, option, { selected }) => (
				<HoverTooltip
					title={option[propertyToDisplay]}
					description={option[propertyShownOnHover] ?? "No description available"}
					hide={!propertyShownOnHover}
					placement="left-start"
				>
					<Grid
						container
						data-testid="dropdown.option"
						onClick={() => handleSetValues(option)}
						className={clsx(styles.dropDownContainer, {
							[styles.viewMode]: viewMode,
						})}
						key={option[propertyToIdentify]}
					>
						<Checkbox
							icon={
								<CheckBoxOutlineBlank
									className={styles.unchecked}
									fontSize="small"
								/>
							}
							checkedIcon={<CheckBox className={styles.checked} fontSize="small" />}
							checked={selected}
						/>
						<Grid item className={styles.userNameContainer}>
							<Typography variant="description" display="block">
								{option[propertyToDisplay]}
							</Typography>
							{option[secondaryPropertyToDisplay] && (
								<TruncatableTypography
									variant="caption"
									maxCharLength={37}
									color={"text.secondary"}
									tooltipTitle={option[secondaryPropertyToDisplay]}
									tooltipPlacement="left-start"
								>
									{option[secondaryPropertyToDisplay]}
								</TruncatableTypography>
							)}
						</Grid>
						<div className={styles.dropDownItemEnd}>
							{endPropertyExplanation} {option[propertyToDisplayEnd]}
						</div>
					</Grid>
				</HoverTooltip>
			)}
			renderInput={(params) => (
				<TextField
					data-testid="autocomplete.input"
					{...params}
					variant="outlined"
					size="small"
					placeholder={selectedValues.length > 0 ? "" : placeholder}
					onChange={onHandleSearch}
					className={styles.textField}
					InputProps={{
						style: {
							fontSize: 15,
							minHeight: 50,
						},
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color="inherit" size={20} />
								) : (
									params.InputProps.endAdornment
								)}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

const AutoCompleteWithFetchSingle = ({
	getData, // Function that fetches data to be displayed in the dropdown
	selectedValue, // Selected value, is always an object
	propertyToDisplay, // Typically "name" | "displayName" | "prettyName" | "mail" etc.
	propertyToDisplayEnd, // Secondary property to display, typically "memberCount" | "licenseCount" etc.
	propertyToIdentify, // Typically "id" | "uuid" | "oid" | "tenantId" etc.
	propertyToGroupBy, // Typically "user type" | "group type" | "role type" etc.
	handleSetValue, // Function that sets the selected values, this can be tailored in the parent component
	endPropertyExplanation = "", // Used to explain the secondary property to display, typically "members" | "licenses" etc.
	placeholder = "",
	propertyShownOnHover,
	viewMode = false,
}) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState([]);

	const onHandleSearch = ({ currentTarget: { value } }) => {
		initializeSearch(value);
	};

	const initializeSearch = (searchValue) => {
		setLoading(true);
		const fetchedOptions = getData(searchValue);
		const withSelectedValue = [selectedValue, ...fetchedOptions];
		// add the fetched options to the list of options if it doesn't exist in the options list already
		const newOptions = withSelectedValue?.filter(
			(option) => !options.some((o) => o[propertyToIdentify] === option[propertyToIdentify]),
		);
		const sorted = _.sortBy(newOptions, propertyToGroupBy);
		const unique = _.uniqBy(sorted, propertyToIdentify);
		setOptions([...options, ...unique]);
		setLoading(false);
	};

	return (
		<Autocomplete
			data-testid="autocomplete.with.fetch.single"
			id="autocomplete-with-fetch"
			multiple={false}
			open={open}
			onOpen={() => {
				initializeSearch("");
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			groupBy={(option) => option[propertyToGroupBy]}
			value={selectedValue}
			options={options}
			isOptionEqualToValue={(option, value) =>
				option[propertyToIdentify] === value[propertyToIdentify]
			}
			fullWidth
			onChange={handleSetValue}
			disableClearable={viewMode}
			getOptionLabel={(option) => option[propertyToDisplay] ?? ""}
			renderOption={(props, option, { selected }) => (
				<div
					onClick={(e) => {
						if (viewMode) return;
						handleSetValue(option);
						setOpen(false);
					}}
					className={clsx({
						[styles.selectedRow]: selected,
						[styles.singleDropDown]: true,
						[styles.viewMode]: viewMode,
					})}
					key={option[propertyToIdentify]}
				>
					{option[propertyToDisplay]} {option[propertyToDisplayEnd] ?? ""}
				</div>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					size="small"
					placeholder={placeholder}
					onChange={onHandleSearch}
					className={styles.textField}
					InputProps={{
						style: {
							fontSize: 15,
							minHeight: 50,
						},
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color="inherit" size={20} />
								) : (
									params.InputProps.endAdornment
								)}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

export { AutoCompleteWithFetch };
