import styles from "./InvoiceView.module.scss";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { fetchInvoices } from "actions/invoiceActions";
import { invoiceSelectors, selectInvoices } from "features/invoices";
import dayjs from "dayjs";
import { Grid, IconButton, InputBase, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { InvoiceTable } from "./InvoiceTable";
import { DateRangePicker } from "components/Common/DateRangePicker";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const InvoiceView = (): JSX.Element => {
	const { dateRanges, isLoading, isFetching } = useAppSelector(selectInvoices);

	const invoices = useAppSelector(invoiceSelectors.selectAll);

	useApiOnce(fetchInvoices, { isLoading, isFetching });

	const allStatuses = ["All", "Paid", "Open"];

	const defaultStartRange = {
		startDate: dayjs("2016").startOf("year").toDate(),
		endDate: dayjs().endOf("month").toDate(),
		key: "selection",
	};

	const [ranges, setRanges] = useState([defaultStartRange]);
	const [searchValue, setSearchValue] = useState("");

	const [selectedStatus, setSelectedStatus] = useState<string>("All");

	const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleStatusChange = (e: SelectChangeEvent<string>) => {
		setSelectedStatus(e.target.value as string);
	};

	const handleResetFilters = () => {
		setRanges([defaultStartRange]);
		setSearchValue("");
		setSelectedStatus("All");
	};

	const selectedInvoices = invoices.filter(
		({ referenceNumber, invoiceText, status, documentDate }) => {
			if (
				!dayjs(documentDate).isBetween(
					dayjs(ranges[0].startDate),
					dayjs(ranges[0].endDate),
					"day",
					"[]",
				)
			) {
				return false;
			}

			const { lowerCasedReferenceNumber, lowerCasedInvoiceText, lowerCasedSearchValue } = {
				lowerCasedReferenceNumber: referenceNumber?.toLowerCase(),
				lowerCasedInvoiceText: invoiceText?.toLowerCase(),
				lowerCasedSearchValue: searchValue.toLowerCase(),
			};
			const isSearchValueIncluded = searchValue
				? lowerCasedReferenceNumber.includes(lowerCasedSearchValue) ||
				  lowerCasedInvoiceText?.includes(lowerCasedSearchValue)
				: true;
			const exactStatusOrAllSelected = selectedStatus === "All" || status === selectedStatus;

			return exactStatusOrAllSelected && isSearchValueIncluded;
		},
	);

	const [showDateRangePicker, setShowDateRangePicker] = useState(false);

	const filtersAreDefault =
		dayjs(ranges[0].startDate).isSame(defaultStartRange.startDate, "day") &&
		dayjs(ranges[0].endDate).isSame(defaultStartRange.endDate, "day") &&
		searchValue === "";

	const selectedRangeDisplay = filtersAreDefault
		? ""
		: `${
				dayjs(ranges[0].startDate).format("DD.MM.YY") +
				" - " +
				dayjs(ranges[0].endDate).format("DD.MM.YY")
		  }`;

	return (
		<Grid container className={styles.container}>
			<Grid container mb={3} spacing={3}>
				<Grid item className={styles.searchField}>
					<div className={styles.inputRoot}>
						<IconButton disabled className={styles.iconButton}>
							<SearchIcon />
						</IconButton>
						<InputBase
							value={searchValue}
							className={styles.input}
							placeholder="Search for reference number or description"
							onChange={handleSearchValueChange}
						/>
					</div>
				</Grid>
				<Grid item className={styles.datePickerField}>
					<DateRangePicker
						showDateRangePicker={showDateRangePicker}
						setShowDateRangePicker={setShowDateRangePicker}
						ranges={ranges}
						setRanges={setRanges}
						selectedRangeDisplay={selectedRangeDisplay}
						selectablePeriods={dateRanges}
					/>
				</Grid>

				<Grid item className={styles.statusSelectorField}>
					<Select
						id="invoice-status-selector"
						value={selectedStatus}
						defaultValue={"All"}
						size="small"
						className={styles.statusSelector}
						onChange={handleStatusChange}
						placeholder="Status"
					>
						{allStatuses.map((status: string, key) => {
							return (
								<MenuItem key={key} value={status}>
									{status}
								</MenuItem>
							);
						})}
					</Select>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<InvoiceTable
					isLoading={isLoading}
					invoices={selectedInvoices}
					selectedRangeDisplay={selectedRangeDisplay}
					shouldShowTableFooter={!filtersAreDefault}
					onResetFilters={handleResetFilters}
				/>
			</Grid>
		</Grid>
	);
};

export { InvoiceView };
