import { Grid, IconButton, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import styles from "./QuantityAdjuster.module.scss";

interface QuantityAdjusterProps {
	quantity: number;
	up: (quantity: number) => void;
	down: (quantity: number) => void;
	setQuantity?: (quantity: number) => void;
}

export const QuantityAdjuster = ({ quantity, up, down, setQuantity }: QuantityAdjusterProps) => {
	const canAdjustDirectly = setQuantity !== undefined;
	return (
		<Grid container className={styles.container}>
			<IconButton onClick={() => down(quantity)} className={styles.iconContainer}>
				<RemoveIcon className={styles.removeCircleIcon} />
			</IconButton>
			{canAdjustDirectly ? (
				<TextField
					className={styles.quantityAdjustmentInputField}
					onFocus={(e) => {
						e.target.select();
					}}
					inputProps={{
						className: styles.text,
					}}
					onChange={(e) =>
						setQuantity(isNaN(parseInt(e.target.value)) ? 1 : parseInt(e.target.value))
					}
					value={quantity}
				/>
			) : (
				<Typography variant="caption" className={styles.skuQuantityNumber}>
					{quantity}
				</Typography>
			)}

			<IconButton onClick={() => up(quantity)} className={styles.iconContainer}>
				<AddIcon className={styles.addCircleIcon} />
			</IconButton>
		</Grid>
	);
};
