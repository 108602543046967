import { Grid, Skeleton, Typography } from "@mui/material";
import { licenseNameSelectors, selectlicenseNames } from "features/licenses/licenseNames";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";

import styles from "./LicenseList.module.scss";
import { getIcon } from "utilities/microsoftIconsUtils";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { licenseGroupsSelectors } from "features/licenses/licenses";
import { useState } from "react";
import clsx from "clsx";
import { PrimaryButton } from "../Buttons/Button";
import { LicenseAssignedToUser } from "types";
import { removeLicenses } from "actions/licenseActions";
import { TruncatableTypography } from "../TruncateableTypography";
import { fetchLicenseNames } from "actions/licenseNameActions";
import useRole from "utilities/roleUtils/roleCheck";

interface LicenseListProps {
	licenses: LicenseAssignedToUser[];
	readView: boolean;
	userId?: string;
}

/* This is a component used to list licenses, and allow the user to remove them. 
   removals are only allowed if the user is not in readView mode, which is determined by where the user is in the application.
*/
export const LicenseList = ({ userId, licenses, readView }: LicenseListProps) => {
	const { dispatch, auth } = useAuth();
	const [licenseSkusForRemoval, setLicenseSkusForRemoval] = useState<string[]>([]);
	const [isRemoving, setIsRemoving] = useState(false);
	const licenseGroups = useAppSelector(licenseGroupsSelectors.selectAll);

	useApiOnce(fetchLicenseNames, useAppSelector(selectlicenseNames));

	const keyOfLicense = (license: LicenseAssignedToUser) =>
		license.assignedByGroup ? `${license.skuId}-${license.assignedByGroup}` : license.skuId;

	const handleSwapRemoval = (skuId: string, assignedByGroup?: string | null) => {
		const removalId = keyOfLicense({ skuId, assignedByGroup } as LicenseAssignedToUser);
		const isGroupAssigned = !!assignedByGroup;
		const allSwappedLicenses = isGroupAssigned
			? licenses
					.filter((license) => license.assignedByGroup === assignedByGroup)
					.map((license) => keyOfLicense(license))
			: [removalId];

		let allRemovals = [...licenseSkusForRemoval];
		if (licenseSkusForRemoval.includes(removalId)) {
			allRemovals = allRemovals.filter((id) => !allSwappedLicenses.includes(id));
		} else {
			allRemovals = [...allRemovals, ...allSwappedLicenses];
		}

		setLicenseSkusForRemoval(allRemovals);
	};

	const licensesOnUser = licenses.filter(
		(license) => !licenseSkusForRemoval.includes(keyOfLicense(license)),
	);

	const anyRemoved = licenseSkusForRemoval.length > 0;
	const anyLicenses = licenses.length > 0;

	const licensesForRemoval = licenses.filter((license) =>
		licenseSkusForRemoval.includes(keyOfLicense(license)),
	);

	const handleRemovalConfirmed = async () => {
		if (!userId) return; // Will happen if the list is in readView mode
		setIsRemoving(true);
		const { groupIds, skuIds } = licensesForRemoval.reduce(
			(acc, curr) => {
				if (!!curr.assignedByGroup && !acc.groupIds.includes(curr.assignedByGroup)) {
					acc.groupIds.push(curr.assignedByGroup);
				} else {
					acc.skuIds.push(curr.skuId);
				}

				return acc;
			},
			{ groupIds: [] as string[], skuIds: [] as string[] },
		);

		const body = [
			{
				groupRemovals: groupIds.length > 0 ? [{ userId, groupIds }] : [],
				directRemovals: skuIds.length > 0 ? [{ userId, skuIds }] : [],
			},
		];

		await dispatch(removeLicenses({ auth, body, licenseGroups }));
		setIsRemoving(false);
	};

	if (!anyLicenses) {
		return (
			<Grid item xs={12}>
				<Typography variant="body1" className={styles.noLicensesLabel}>
					No licenses assigned
				</Typography>
			</Grid>
		);
	}

	return (
		<Grid container className={styles.container}>
			<Grid container className={styles.licenseListContainer}>
				{licensesOnUser.map((license) => {
					return (
						<LicenseItem
							license={license}
							handleSwapRemoval={() =>
								handleSwapRemoval(license.skuId, license?.assignedByGroup)
							}
							hasBeenRemoved={false}
							key={keyOfLicense(license)}
							readView={readView}
						/>
					);
				})}
			</Grid>
			{!readView && (
				<Typography
					variant="body1"
					className={clsx({
						[styles.noRemovalsSelected]: !anyRemoved,
						[styles.removedLicensesLabel]: true,
					})}
				>
					Licenses for removal
				</Typography>
			)}
			{anyRemoved && !readView && (
				<Grid container className={styles.removedLicensesContainer}>
					{licensesForRemoval.map((license) => {
						return (
							<LicenseItem
								license={license}
								handleSwapRemoval={() =>
									handleSwapRemoval(license.skuId, license.assignedByGroup)
								}
								hasBeenRemoved={true}
								key={keyOfLicense(license)}
								readView={readView}
							/>
						);
					})}
				</Grid>
			)}
			<PrimaryButton
				action={handleRemovalConfirmed}
				text={"Remove licenses"}
				size="small"
				variantStyle="contained"
				className={clsx({ [styles.hidden]: !anyRemoved })}
				marginTop={3}
				isLoading={isRemoving}
			/>
		</Grid>
	);
};

interface LicenseItemProps {
	license: LicenseAssignedToUser;
	handleSwapRemoval: (skuId: string) => void;
	hasBeenRemoved: boolean;
	readView: boolean;
}

const LicenseItem = ({
	license,
	handleSwapRemoval,
	hasBeenRemoved,
	readView,
}: LicenseItemProps) => {
	const { isLoading: isLoadingLicenseNames } = useAppSelector(selectlicenseNames);
	const licenseNames = useAppSelector(licenseNameSelectors.selectEntities);
	const name = licenseNames[license.skuId]?.licenseDisplayName as string;
	const groups = useAppSelector(licenseGroupsSelectors.selectEntities);
	const groupName = groups[license.assignedByGroup ?? ""]?.groupName as string;

	const icon = getIcon({
		iconName: name,
	});

	const { isActionRole } = useRole();

	return (
		<Grid
			container
			item
			className={clsx(styles.licenseItem, { [styles.removedLicenseItem]: hasBeenRemoved })}
		>
			{!isLoadingLicenseNames ? (
				<>
					<Grid className={styles.itemAndDisplayName}>
						{icon && (
							<svg width="20" height="20" viewBox="0 0 60 60">
								{icon}
							</svg>
						)}
						<TruncatableTypography variant="description" maxCharLength={35}>
							{name ?? license.skuId}
						</TruncatableTypography>
					</Grid>
					<Grid
						className={clsx(styles.groupLabelContainer, {
							[styles.hidden]: !license.assignedByGroup,
						})}
					>
						<TruncatableTypography
							variant="description"
							className={styles.groupLabel}
							maxCharLength={20}
						>
							{groupName ? groupName : "Unknown group"}
						</TruncatableTypography>
					</Grid>
					{!readView &&
						(!hasBeenRemoved ? (
							<Grid
								container
								onClick={() => handleSwapRemoval(license.skuId)}
								className={clsx(styles.removeLicenseButtonContainer, {
									[styles.disabled]: !isActionRole,
								})}
							>
								<RemoveCircleOutlineIcon />
								<Typography variant="description" className={styles.removeLabel}>
									Remove license
								</Typography>
							</Grid>
						) : (
							<Typography
								variant="description"
								className={styles.removedLabel}
								onClick={() => handleSwapRemoval(license.skuId)}
							>
								Cancel removal
							</Typography>
						))}
				</>
			) : (
				<>
					<Skeleton variant="rectangular" width={"100%"} height={20} />
				</>
			)}
		</Grid>
	);
};
