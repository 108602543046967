import { Card, Typography } from "@mui/material";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { useAppNavigate } from "hooks/useAppNavigate";
import { useState } from "react";
import clsx from "clsx";
import { useAppDispatch } from "hooks/hooks";
import { setHardwareDrawerState } from "features/hardware/hardwarePage";
import { HardwareDrawerType } from "utilities/constants/enums";
import { clearBundleItems } from "features/hardware/hardwareBundles";
import styles from "./BundleCard.module.scss";

interface AddNewBundleCardProps {
	hidden: boolean;
	disabled: boolean;
}

const AddNewBundleCard = ({ hidden, disabled }: AddNewBundleCardProps) => {
	const { navigateToPage } = useAppNavigate();
	const dispatch = useAppDispatch();
	const [isHovered, setHovered] = useState(false);

	const handleNavigateToCreateBundle = () => {
		dispatch(clearBundleItems());
		dispatch(
			setHardwareDrawerState({
				isDrawerOpen: true,
				activeDrawerType: HardwareDrawerType.Bundle,
				headerText: "Create new bundle",
				primaryButtonText: "Create bundle",
				secondaryButtonText: "Cancel",
			}),
		);
		navigateToPage(NAVIGATION_NAMES.HARDWARE_CREATE_BUNDLE.path);
	};

	return (
		<Card
			className={clsx(styles.addNewCard, {
				[styles.disabled]: disabled,
				[styles.hidden]: hidden,
			})}
			key={0}
			onClick={handleNavigateToCreateBundle}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{!disabled && (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="70"
					height="70"
					viewBox="0 0 70 70"
					fill="none"
					className={clsx({
						[styles.makeBiggerOnHover]: isHovered,
					})}
				>
					<circle cx="35" cy="35" r="34.5" stroke="#00608A" strokeDasharray="7 7" />
					<rect x="32" y="17" width="6" height="32" rx="3" fill="#D9D9D9" />
					<rect
						x="19"
						y="36"
						width="6"
						height="32"
						rx="3"
						transform="rotate(-90 19 36)"
						fill="#D9D9D9"
					/>
				</svg>
			)}
			<Typography variant="body1" className={styles.addNewText}>
				{disabled ? "No active bundles" : "Create new bundle"}
			</Typography>
		</Card>
	);
};

export { AddNewBundleCard };
