import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

const initialState = {
	isLockedDialogOpen: false,
};

const lockedDialogSlice = createSlice({
	name: "lockedDialog",
	initialState: initialState,
	reducers: {
		setIsLockedDialogOpen: (state, { payload }) => {
			state.isLockedDialogOpen = payload;
		},
	},
});

export const selectIsLockedDialogOpen = (state: RootState) => state.lockedDialog.isLockedDialogOpen;

export const { setIsLockedDialogOpen } = lockedDialogSlice.actions;

export default lockedDialogSlice.reducer;
