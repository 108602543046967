import { Typography } from "@mui/material";
import { selectLicenseActionEffectsOnSubscriptions } from "features/scheduledActions";
import { useAppSelector } from "hooks/hooks";
import Alert from "@mui/material/Alert";
import dayjs from "dayjs";

import styles from "./RecentlyModifiedSubscriptionHeader.module.scss";
import { useMemo } from "react";
import { FIVE_MINUTES } from "utilities/constants/constants";

export const RecentlyModifiedSubscriptionsHeader = ({
	provisioningId,
}: {
	provisioningId: string;
}) => {
	const licenseActionsEffects = useAppSelector(selectLicenseActionEffectsOnSubscriptions);
	const recentlyModifiedSubscriptionsForSku = licenseActionsEffects[provisioningId];
	const lastModifiedAt = useMemo(() => {
		if (!recentlyModifiedSubscriptionsForSku) return new Date(0);
		const effectsOnSubs = Object.values(recentlyModifiedSubscriptionsForSku) ?? [];
		const largestModifiedAt = effectsOnSubs.reduce((prev, curr) => {
			return prev > curr.lastModifiedAt ? prev : curr.lastModifiedAt;
		}, new Date(0));
		return largestModifiedAt;
	}, [recentlyModifiedSubscriptionsForSku]);

	return recentlyModifiedSubscriptionsForSku ? (
		<Alert severity="info" className={styles.recentlyModifiedHeaderContainer}>
			<Typography variant="body1">
				Last modifications completed at {dayjs(lastModifiedAt).format("HH:mm:ss")}, please
				allow up to {FIVE_MINUTES} minutes for changes to take effect.
			</Typography>
		</Alert>
	) : (
		<></>
	);
};
