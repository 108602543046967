import { Breadcrumbs, Grid, Typography } from "@mui/material";
import {
	selectActiveLicenseCompleteDetails,
	setActiveLicenseManagementId,
} from "features/licenses/licenses";
import { useAppSelector, useAuth, useFetchAllSubscriptionsOnLicense } from "hooks/hooks";
import SavingsIcon from "@mui/icons-material/Savings";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { InternalLink } from "components/Common/Links";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";

import styles from "./LicenseDetailsView.module.scss";
import {
	selectCostSavingRecommendations,
	selectIsScheduledActionsDialogOpen,
	setIsScheduledActionsDialogOpen,
} from "features/recommendations";
import { LicenseAdjustmentCard } from "./LicenseAdjustmentCard";
import { LicenseAdjustmentDrawer } from "../LicenseAdjustmentDrawer";
import { LicenseHistoryCard } from "./LicenseHistoryCard";
import { LicenseSubscriptionTable } from "./LicenseSubscriptionTable/LicenseSubscriptionTable";
import {
	GroupedActions,
	LicenseAction,
	LicenseSubscriptionRecommendation,
	RemovalQuantity,
	SubscriptionVariant,
} from "types";
import { LicenseActionOperationType, LicenseActionStatus } from "utilities/constants/enums";
import { selectCustomerDetails } from "features/customer";
import { createLicenseActionBody } from "utilities/licenseUtils/licenseActionUtils";
import { SmallCard } from "components/Common/Cards";
import ScheduledChangesDialog from "components/Recommendations/ScheduledActionsDialog/ScheduledActionsDialog";
import {
	selectGroupedLicenseActions,
	selectLicenseActions,
	updateLicenseActionStatus,
	selectRecipientMailState,
	setLicenseActionSkuFilter,
	licenseActionsSelector,
} from "features/scheduledActions";
import { caculateTotalCostSavings } from "utilities/licenseUtils/costSavingsUtils";
import { fetchLicenseSubscription } from "actions/licenseActions";
import { createLicenseActions, executeLicenseActions } from "actions/scheduledActionActions";
import dayjs from "dayjs";
import { RecentlyModifiedSubscriptionsHeader } from "./RecentlyModifiedSubscriptionsHeader/RecentlyModifiedSubscriptionHeader";
import { licenseNameSelectors } from "features/licenses/licenseNames";

const LicenseDetailsView = () => {
	const { auth, dispatch } = useAuth();
	const completeLicenseDetails = useAppSelector(selectActiveLicenseCompleteDetails);
	const provisioningIdOfLicense = completeLicenseDetails?.provisioningId ?? "";
	useFetchAllSubscriptionsOnLicense(provisioningIdOfLicense);
	const costSavingRecommendations = useAppSelector(selectCostSavingRecommendations);
	const relevantCostSavingRecommendation = costSavingRecommendations.find(
		(recommendation) =>
			recommendation.subscriptionSkuID === completeLicenseDetails?.variants[0].provisioningId,
	);
	const licenseNames = useAppSelector(licenseNameSelectors.selectEntities);
	const { isLoading: licenseActionsLoading } = useAppSelector(selectLicenseActions);
	const licenseActions = useAppSelector(licenseActionsSelector.selectEntities);
	const licenseActionGroups =
		useAppSelector(selectGroupedLicenseActions) ?? ({} as GroupedActions);

	const numScheduledActions = Object.entries(licenseActionGroups).reduce((acc, [key, value]) => {
		if (!key.includes(completeLicenseDetails?.provisioningId ?? "")) {
			return acc;
		}
		return acc + value.numScheduledActions;
	}, 0);

	const isScheduledActionsDialogOpen = useAppSelector(selectIsScheduledActionsDialogOpen);

	const { unused: costSavingsUnused = 0 } = caculateTotalCostSavings(
		relevantCostSavingRecommendation as LicenseSubscriptionRecommendation,
	);

	const customerInfo = useAppSelector(selectCustomerDetails);

	const handleCloseLicenseDetails = () => {
		dispatch(setActiveLicenseManagementId(""));
	};

	const handleOpenScheduledActionsDialog = () => {
		dispatch(setLicenseActionSkuFilter(completeLicenseDetails?.provisioningId ?? ""));
		dispatch(setIsScheduledActionsDialogOpen(true));
	};

	const { navigateToPage } = useAppNavigate();

	const { mail: recipientMail } = useAppSelector(selectRecipientMailState);

	const handleCreateLicenseAction = async (
		variants: SubscriptionVariant[] | RemovalQuantity[], // Either a SubscriptionVariant or a RemovalQuantity, depending on the operation type
		operationType: LicenseActionOperationType,
	) => {
		const bodies = variants.map((variant) => {
			const quantityChange = calculateQuantityChange(variant, operationType);
			const purchaseInfo =
				operationType === LicenseActionOperationType.CreateNewSubscription
					? {
							TermDuration: variant.termDuration,
							ProductId: (variant as SubscriptionVariant).costIdentifier.split(
								":",
							)[0],
							SkuId: (variant as SubscriptionVariant).costIdentifier.split(":")[1],
					  }
					: undefined;

			return createLicenseActionBody({
				customerName: customerInfo?.departmentName ?? "",
				account: auth.account,
				operationType,
				targetFriendlyName: completeLicenseDetails?.friendlyName ?? "",
				variant,
				quantityChange: quantityChange,
				purchaseInfo: purchaseInfo,
				confirmationMailRecipient: recipientMail,
			});
		});

		const createActionResults = await dispatch(
			createLicenseActions({
				auth,
				body: bodies,
			}),
		);

		const createdActions = (createActionResults?.payload as any)?.data ?? [];

		// Only execute license action if execution date is before current date
		createdActions.forEach(async (createdActionBody: LicenseAction) => {
			const actionWillBeDirectlyExecuted = dayjs(createdActionBody.ExecutionDate).isBefore(
				dayjs(),
			);

			let subscriptionIdToFetch = createdActionBody.TargetGUID;
			if (actionWillBeDirectlyExecuted) {
				const executeActionResult = await dispatch(
					executeLicenseActions({
						auth,
						body: [createdActionBody],
					}),
				);
				if (operationType === LicenseActionOperationType.CreateNewSubscription)
					subscriptionIdToFetch = (executeActionResult?.payload as any)?.data?.[0]
						?.TargetGUID;
			}

			// Wait here for 60 seconds (if action happens now, and its a quantity adjustment)
			// to allow Microsoft to update the subscription quantities in their backend
			// if we DON'T do this, the subscription quantities will be wrong when we re-fetch the "fresh" subscription-data
			const isDirectQuantityAdjustment =
				createdActionBody.Operation === LicenseActionOperationType.AdjustLicenseCount &&
				actionWillBeDirectlyExecuted;

			if (isDirectQuantityAdjustment) {
				await new Promise((resolve) => setTimeout(resolve, 60000));
			}

			await dispatch(
				fetchLicenseSubscription({
					auth,
					subscriptionId: subscriptionIdToFetch,
					skuId: createdActionBody.SkuGUID,
					headers: { "Cache-Control": "no-cache" },
				}),
			);

			const currentActionStatus = licenseActions[createdActionBody.ActionId]?.Status;
			if (actionWillBeDirectlyExecuted && currentActionStatus !== LicenseActionStatus.Error) {
				// Only update status if action was executed directly (i.e not scheduled for later)
				// and if the action is not in an error state
				dispatch(
					updateLicenseActionStatus({
						ids: [createdActionBody.ActionId],
						status: LicenseActionStatus.Finished,
					}),
				);
			}
		});
	};

	return (
		<>
			<Grid container item className={styles.wrapper}>
				<Grid item xs={12}>
					<Breadcrumbs
						className={styles.breadcrumbs}
						separator="›"
						aria-label="breadcrumb"
					>
						<Typography
							variant="body1"
							onClick={handleCloseLicenseDetails}
							className={styles.firstBreadcrumb}
						>
							Manage licenses
						</Typography>
						<Typography variant="body1" className={styles.activeBreadcrumb}>
							{licenseNames[provisioningIdOfLicense]?.licenseDisplayName ??
								completeLicenseDetails?.friendlyName}
						</Typography>
					</Breadcrumbs>
				</Grid>
				<RecentlyModifiedSubscriptionsHeader provisioningId={provisioningIdOfLicense} />
				<Grid container className={styles.container}>
					<Grid container className={styles.cardsAndAdjustmentsContainer}>
						<Grid item className={styles.adjustmentsContainer}>
							<LicenseAdjustmentCard />
						</Grid>
						<Grid container className={styles.cardsContainer}>
							<SmallCard
								locked={!customerInfo?.yourEmployeesEnabled}
								title="Potential savings"
								icon={<SavingsIcon className={styles.savingsIcon} />}
								leftText={JSON.stringify(Math.round(costSavingsUnused * 12))}
								rightText=",- / year"
								onClick={() =>
									navigateToPage(NAVIGATION_NAMES.RECOMMENDATIONS.path)
								}
								link={<InternalLink>Go to recommendations</InternalLink>}
							/>
							<SmallCard
								title="Scheduled actions"
								icon={<CalendarMonthIcon className={styles.calendarIcon} />}
								leftText={JSON.stringify(numScheduledActions)}
								rightText="scheduled actions"
								onClick={handleOpenScheduledActionsDialog}
								link={<InternalLink>View scheduled actions</InternalLink>}
							/>
						</Grid>
					</Grid>
					<Grid container className={styles.licenseHistoryContainer}>
						<LicenseHistoryCard
							skuGuid={completeLicenseDetails?.provisioningId ?? ""}
						/>
					</Grid>
					<Grid container>
						<LicenseSubscriptionTable
							handleCreateLicenseAction={handleCreateLicenseAction}
						/>
					</Grid>
				</Grid>
			</Grid>
			<LicenseAdjustmentDrawer handleCreateLicenseAction={handleCreateLicenseAction} />
			{isScheduledActionsDialogOpen && (
				<ScheduledChangesDialog isScheduledActionsLoading={licenseActionsLoading} />
			)}
		</>
	);
};

export { LicenseDetailsView };

const calculateQuantityChange = (
	variant: SubscriptionVariant | RemovalQuantity,
	operationType: LicenseActionOperationType,
) => {
	let quantityChange = 0;
	switch (operationType) {
		case LicenseActionOperationType.CreateNewSubscription:
		case LicenseActionOperationType.AdjustLicenseCount:
			quantityChange =
				"removalQuantity" in variant ? variant.removalQuantity : variant.purchaseQuantity;
			break;
		case LicenseActionOperationType.AutorenewOff:
			quantityChange = 0;
			break;
		case LicenseActionOperationType.AutorenewOn:
			quantityChange = 0;
			break;
		case LicenseActionOperationType.SuspendSubscription:
			quantityChange = 0;
			break;
		case LicenseActionOperationType.CancelSubscription:
			quantityChange = 0;
			break;
		default:
			quantityChange = 0;
			break;
	}

	return quantityChange;
};
