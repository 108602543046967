import {
	AzureCost,
	ConsultancyCost,
	MarketplaceItemsCost,
	Office365Cost,
	ReservedInstancesCost,
	TotalCost,
	YourEmployeesCost,
	YourITSystemsCost,
} from "types";

export const mergeCostData = (responses: TotalCost[]) => {
	// We then need to create a complete array of data per cost type for each month
	let completeAzureData = [] as AzureCost[];
	let completeOffice365Data = [] as Office365Cost[];
	let completeYourEmployeesData = [] as YourEmployeesCost[];
	let completeYourItSystemsData = [] as YourITSystemsCost[];
	let completeConsultancyData = [] as ConsultancyCost[];
	let reservedInstancesData = [] as ReservedInstancesCost[];
	let marketPlaceItemsData = [] as MarketplaceItemsCost[];

	responses.forEach((response) => {
		const {
			azure,
			office365,
			yourEmployees,
			yourItSystems,
			consultancy,
			reservedInstances,
			marketplaceItems,
		} = response;

		if (azure) {
			completeAzureData = completeAzureData.concat(azure);
		}
		if (office365) {
			completeOffice365Data = completeOffice365Data.concat(office365);
		}
		if (yourEmployees) {
			completeYourEmployeesData = completeYourEmployeesData.concat(yourEmployees);
		}
		if (yourItSystems) {
			completeYourItSystemsData = completeYourItSystemsData.concat(yourItSystems);
		}
		if (consultancy) {
			completeConsultancyData = completeConsultancyData.concat(consultancy);
		}
		if (reservedInstancesData) {
			reservedInstancesData = reservedInstancesData.concat(reservedInstances);
		}
		if (marketPlaceItemsData) {
			marketPlaceItemsData = marketPlaceItemsData.concat(marketplaceItems);
		}
	});

	return {
		azure: completeAzureData,
		office365: completeOffice365Data,
		yourEmployees: completeYourEmployeesData,
		yourItSystems: completeYourItSystemsData,
		consultancy: completeConsultancyData,
		reservedInstances: reservedInstancesData,
		marketPlaceItems: marketPlaceItemsData,
	};
};
