import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchInvoices, fetchInvoice } from "actions/invoiceActions";
import { RootState } from "store";
import { Invoice, InvoiceDownloadStatus } from "types";
import { downloadAndUnzip } from "utilities/pdfUtils";
import dayjs from "dayjs";
import { DownloadFileStatusEnum } from "utilities/constants/enums";

const invoiceAdapter = createEntityAdapter<Invoice>({
	selectId: (invoice) => invoice.referenceNumber,
});

const invoiceSlice = createSlice({
	name: "invoices",
	initialState: invoiceAdapter.getInitialState({
		isLoading: true,
		isFetching: false,
		invoiceDownloadStatus: {} as InvoiceDownloadStatus,
		dateRanges: [] as string[],
	}),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchInvoices.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchInvoices.fulfilled, (state, { payload }) => {
				const mapped = payload.map((invoice) => {
					let status = invoice?.status;
					if (status.toUpperCase() === "CLOSED") {
						status = "Paid";
					}
					if (status.toUpperCase() === "SCHEDULED") {
						status = "Open";
					}
					return {
						...invoice,
						status,
					};
				});
				invoiceAdapter.setAll(state, mapped);
				state.dateRanges = Array.from(
					new Set(
						mapped.map(({ documentDate }) => dayjs(documentDate).format("MMMM YYYY")),
					),
				);
				state.isFetching = false;
				state.isLoading = false;
			})
			.addCase(fetchInvoices.rejected, (state) => {
				state.isLoading = false;
				state.isFetching = false;

				invoiceAdapter.setAll(state, []);
			});
		builder
			.addCase(fetchInvoice.pending, (state, meta) => {
				const id = meta.meta.arg.id;
				state.isFetching = true;
				state.invoiceDownloadStatus[id] = DownloadFileStatusEnum.Downloading;
			})
			.addCase(fetchInvoice.fulfilled, (state, { payload, meta }) => {
				const { id, customerName, invoiceText } = meta.arg;
				downloadAndUnzip(payload, `${customerName}__${invoiceText}`);
				state.isFetching = false;
				state.isLoading = false;
				state.invoiceDownloadStatus[id] = DownloadFileStatusEnum.Downloaded;
			})
			.addCase(fetchInvoice.rejected, (state, meta) => {
				const id = meta.meta.arg.id;
				state.isLoading = false;
				state.isFetching = false;
				state.invoiceDownloadStatus[id] = DownloadFileStatusEnum.Error;
			});
	},
});

export const selectInvoices = (state: RootState) => state.invoices;
export const selectInvoiceDownloadStatus = (state: RootState) =>
	state.invoices.invoiceDownloadStatus;

export const invoiceSelectors = invoiceAdapter.getSelectors(selectInvoices);

export default invoiceSlice.reducer;
