import { useForm, Controller } from "react-hook-form";
import { CircularProgress, Grid, InputLabel, Typography } from "@mui/material";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";

import styles from "./RequestAccessDialog.module.scss";
import { InputTextField } from "components/Common/InputTextField";
import axios from "axios";
import { DEMO_REQUEST_URL } from "utilities/constants/constants";
import { useEffect, useState } from "react";

interface RequestAccessDialogProps {
	open: boolean;
	onClose: () => void;
}

export interface FormData {
	name: string;
	phone: string;
	email: string;
	company: string;
}

export const RequestAccessDialog = ({ open, onClose }: RequestAccessDialogProps) => {
	const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
	const [isSendingRequest, setIsSendingRequest] = useState(false);

	useEffect(() => {
		return () => {
			setFormHasBeenSubmitted(false);
			setIsSendingRequest(false);
		};
	}, [open]);

	const { control, handleSubmit, getValues, reset } = useForm<FormData>({
		defaultValues: {
			name: "",
			phone: "+47",
			email: "",
			company: "",
		},
	});

	const onSubmit = async (data: FormData) => {
		if (formHasBeenSubmitted) {
			reset();
			onClose();
			return;
		}
		data.name = data.name.trim();
		setIsSendingRequest(true);
		await axios.post(DEMO_REQUEST_URL, data);
		setIsSendingRequest(false);
		setFormHasBeenSubmitted(true);
	};

	const showForm = !formHasBeenSubmitted && !isSendingRequest;
	const showSuccess = formHasBeenSubmitted;

	return open ? (
		<PrimaryDialog
			title="Request Demo Access"
			size="medium"
			primaryAction={handleSubmit(onSubmit)}
			onLeaveAction={onClose}
			primaryButtonText={showForm ? "Submit" : "Close"}
			secondaryButtonText={showForm ? "Cancel" : ""}
			disabledActions={isSendingRequest}
		>
			<Grid container className={styles.dialogContent}>
				{showForm && (
					<form noValidate>
						<Controller
							name="name"
							control={control}
							rules={{ required: "Name is required" }}
							render={({ field, fieldState }) => (
								<InputTextField
									{...field}
									label="Full name"
									placeholder="Enter your name"
									error={!!fieldState.error}
									helperText={fieldState.error?.message}
									className={styles.input}
									size="small"
									InputLabelProps={{ shrink: true }}
								/>
							)}
						/>
						<Controller
							name="phone"
							control={control}
							rules={{
								required: "Phone number is required",
								pattern: {
									value: /^\+\d{1,3}\d{8,14}$/,
									message: "Invalid phone number",
								},
							}}
							render={({ field, fieldState }) => (
								<>
									<InputLabel className={styles.inputLabel}>
										<Typography variant="body1">Phone Number</Typography>
										<Typography variant="description" color="text.secondary">
											(For password)
										</Typography>
									</InputLabel>
									<InputTextField
										{...field}
										noLabel
										placeholder="Enter your phone number"
										error={!!fieldState.error}
										helperText={fieldState.error?.message}
										className={styles.input}
										size="small"
										InputLabelProps={{ shrink: true }}
									/>
								</>
							)}
						/>

						<Controller
							name="email"
							control={control}
							rules={{
								required: "Email is required",
								pattern: {
									value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
									message: "Invalid email address",
								},
							}}
							render={({ field, fieldState }) => (
								<>
									<InputLabel className={styles.inputLabel}>
										<Typography variant="body1">Business email</Typography>
										<Typography variant="description" color="text.secondary">
											(For account details)
										</Typography>
									</InputLabel>
									<InputTextField
										{...field}
										noLabel
										placeholder="Enter your email"
										error={!!fieldState.error}
										helperText={fieldState.error?.message}
										className={styles.input}
										size="small"
										InputLabelProps={{ shrink: true }}
									/>
								</>
							)}
						/>
						<Controller
							name="company"
							control={control}
							rules={{ required: "Company name is required" }}
							render={({ field, fieldState }) => (
								<InputTextField
									{...field}
									label="Company"
									placeholder="Enter your company name"
									error={!!fieldState.error}
									helperText={fieldState.error?.message}
									className={styles.input}
									size="small"
									InputLabelProps={{ shrink: true }}
								/>
							)}
						/>
					</form>
				)}
				{isSendingRequest && (
					<CircularProgress size={40} className={styles.loadingSpinner} />
				)}
				{showSuccess && (
					<>
						<Typography variant="h2" textAlign="center" mb={2}>
							Your request has been submitted successfully!
						</Typography>
						<Typography variant="body1" textAlign="center">
							One of our representatives will handle your request shortly. <br />
							You will receive an email at {getValues("email")} with further details.
						</Typography>
					</>
				)}
			</Grid>
		</PrimaryDialog>
	) : null;
};
