import config from "authConfig";

const clearPreviouslySetAccessTokens = () => {
	const portalAccessTokensInStorage = Object.entries(sessionStorage)
		.filter(
			([key]) =>
				key.includes(`accesstoken-${config.APP_ID}`) && key.includes(config.AUTHORITY), // For our own accessTokens
		)
		.map(([key, value]) => {
			return {
				key,
				value: JSON.parse(value),
			};
		});

	portalAccessTokensInStorage.forEach(({ key }) => sessionStorage.removeItem(key));
};

export { clearPreviouslySetAccessTokens };
