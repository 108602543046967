import { Grid, Typography } from "@mui/material";
import { AutoCompleteWithFetch } from "components/Common/AutocompleteWithFetch";
import { usersSelectors } from "features/users";
import { useAppSelector } from "hooks/hooks";
import { User } from "types";
import { SelectedItemChip } from "../SelectedItemChip";

import styles from "./SelectUsers.module.scss";

interface SelectUsersProps {
	userIdsSelectedForAssignment: string[];
	setUserIdsSelectedForAssignment: (userIds: string[]) => void;
}

export const SelectUsers = ({
	userIdsSelectedForAssignment,
	setUserIdsSelectedForAssignment,
}: SelectUsersProps) => {
	const users = useAppSelector(usersSelectors.selectEntities);

	const handleToggleSelectUser = (id: string) => {
		if (!id) {
			setUserIdsSelectedForAssignment([]);
			return;
		}
		if (userIdsSelectedForAssignment.includes(id)) {
			setUserIdsSelectedForAssignment(
				userIdsSelectedForAssignment.filter((userId) => userId !== id),
			);
			return;
		}
		setUserIdsSelectedForAssignment([...userIdsSelectedForAssignment, id]);
	};

	const getUsers = (searchTerm: string) =>
		Object.values(users)?.filter(
			(user) =>
				user?.displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
				user?.mail?.toLowerCase().includes(searchTerm.toLowerCase()),
		) ?? [];

	return (
		<Grid container item xs={11} className={styles.assignToUserContainer}>
			<Typography variant="body1">Assign to users</Typography>
			<Typography variant="caption" color="text.secondary" mb={1}>
				Search for users in your organization by name or email to assign them licenses /
				license groups. You can select up to 20 users at once.
			</Typography>
			<AutoCompleteWithFetch
				displaySelectedValues={false}
				multiple={true}
				getData={getUsers}
				selectedValues={userIdsSelectedForAssignment.map((id: string) => users[id]) ?? []}
				propertyToDisplay="displayName"
				secondaryPropertyToDisplay="mail"
				propertyToIdentify="id"
				propertyToGroupBy="department"
				handleSetValues={(e: User) => handleToggleSelectUser(e.id)}
				placeholder="Search for users"
				id="assign-license-to-user"
			/>
			<Typography variant="caption" mt={1}>
				Selected users
			</Typography>

			<Grid container className={styles.selectedSection}>
				{userIdsSelectedForAssignment.length > 0 ? (
					<Grid container className={styles.selected}>
						{userIdsSelectedForAssignment.map((id) => (
							<SelectedItemChip
								key={id}
								text={users[id]?.displayName ?? ""}
								id={id}
								deselect={handleToggleSelectUser}
								type="user"
							/>
						))}
					</Grid>
				) : (
					<Grid container className={styles.noneSelected}>
						<Typography>No selected users</Typography>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};
