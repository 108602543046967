import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
	selectCustomerLocations,
	selectIsCustomerLocationDialogOpen,
	setIsCustomerLocationDialogOpen,
} from "features/customer";
import { useAppSelector, useAuth } from "hooks/hooks";
import { PrimaryDialog } from "components/Common/Dialogs/Dialog";
import { updateCustomerLocations } from "actions/customerActions";
import { CustomerLocation } from "types";
import { CircularProgress, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { CountryFlag, CountrySelector } from "components/Common/CountrySelector";
import { getCode } from "country-list";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { PrimaryFormButton, SecondaryButton } from "components/Common/Buttons/Button";

import styles from "./NewLocationDialog.module.scss";
import clsx from "clsx";
import DeleteIcon from "@mui/icons-material/Delete";

export const EditLocationsDialog = () => {
	const { auth, dispatch } = useAuth();
	const isOpen = useAppSelector(selectIsCustomerLocationDialogOpen);
	const customerLocations = useAppSelector(selectCustomerLocations);

	const [selectedLocationIndex, setSelectedLocationIndex] = React.useState<number | null>(null);

	const emptyLocation: CustomerLocation = {
		city: "",
		address: "",
		postalCode: "",
		countryCode: "",
		country: "",
		name: "",
		title: "",
		email: "",
		phone: "",
	};

	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm<CustomerLocation>({
		defaultValues: emptyLocation,
	});

	const handleClose = () => dispatch(setIsCustomerLocationDialogOpen(false));

	const validateLocation = (data: CustomerLocation) => {
		return data.address && data.postalCode && data.city && data.country;
	};

	const [isSaving, setIsSaving] = useState(false);
	const saveLocation = async (data: CustomerLocation) => {
		setIsSaving(true);
		const isValid = validateLocation(data);
		if (!isValid) {
			setIsSaving(false);
			return;
		}
		const countryCode = getCode(data.country) ?? "";
		let locations = [...customerLocations.locations];

		if (selectedLocationIndex !== null) {
			// Update existing location
			locations[selectedLocationIndex] = { ...data, countryCode };
		} else {
			// Add new location
			locations.push({ ...data, countryCode });
		}

		await dispatch(updateCustomerLocations({ auth, body: locations }));
		reset({ ...emptyLocation });
		setIsSaving(false);
		setSelectedLocationIndex(null);
	};

	const [isDeleting, setIsDeleting] = useState(false);
	const deleteLocation = async () => {
		if (selectedLocationIndex !== null) {
			setIsDeleting(true);
			let locations = [...customerLocations.locations];
			locations.splice(selectedLocationIndex, 1);
			await dispatch(updateCustomerLocations({ auth, body: locations }));
			reset({ ...emptyLocation });
			setSelectedLocationIndex(null);
			setIsDeleting(false);
		}
	};

	const startEditing = (index: number) => {
		setSelectedLocationIndex(index);
		const location = customerLocations.locations[index];
		reset(location);
	};

	const startAdding = () => {
		setSelectedLocationIndex(null);
		reset({ ...emptyLocation });
	};

	const isAdding = selectedLocationIndex === null;
	const isSaveButtonDisabled =
		!watch("address") || !watch("postalCode") || !watch("city") || isSaving;

	return isOpen ? (
		<PrimaryDialog
			size="large"
			title="Locations"
			onLeaveAction={handleClose}
			removeContentMarginAndPadding
			isDestructiveAction={true}
		>
			<Grid className={styles.container}>
				<Grid className={styles.locationListContainer}>
					<Grid container className={styles.locationList}>
						{customerLocations.locations.map((location) => (
							<Grid
								container
								className={clsx(styles.locationItem, {
									[styles.selected]:
										customerLocations.locations.indexOf(location) ===
										selectedLocationIndex,
								})}
								key={`${location.city}-${location.address}-${location.countryCode}`}
								onClick={() =>
									startEditing(customerLocations.locations.indexOf(location))
								}
							>
								<Grid item xs={1} mr={2}>
									<CountryFlag
										countryCode={location.countryCode}
										coloredBackground={false}
									/>
								</Grid>
								<Grid item xs={10}>
									<Grid container>
										<Typography variant="body1">{location.city}</Typography>
									</Grid>
									<Grid item mt={-0.5}>
										<Typography
											variant="description"
											className={styles.address}
										>
											{location.address}, {location.postalCode}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						))}
					</Grid>
					<SecondaryButton
						action={startAdding}
						text="New Location"
						size="small"
						variantStyle="contained"
						icon={<AddBusinessIcon className={styles.addIcon} />}
						className={styles.addLocationButton}
					/>
				</Grid>
				<form
					onSubmit={handleSubmit(saveLocation)}
					className={styles.form}
					id="location-form"
				>
					<Typography variant="h3" className={styles.formTitle}>
						{isAdding ? "Add new location" : "Edit location"}
					</Typography>
					<Grid item className={styles.formItemSingle}>
						<InputLabel>Street address</InputLabel>
						<TextField
							{...register("address", { required: "Address is required" })}
							error={!!errors.address}
							helperText={errors.address?.message}
							fullWidth
							size="small"
						/>
					</Grid>
					<Grid container className={styles.formRow}>
						<Grid item className={styles.formItem}>
							<InputLabel>Postal code</InputLabel>
							<TextField
								{...register("postalCode", {
									required: "Postal Code is required",
								})}
								error={!!errors.postalCode}
								helperText={errors.postalCode?.message}
								fullWidth
								size="small"
							/>
						</Grid>
						<Grid item className={styles.formItem}>
							<InputLabel>City</InputLabel>
							<TextField
								{...register("city", { required: "City is required" })}
								error={!!errors.city}
								helperText={errors.city?.message}
								size="small"
							/>
						</Grid>
					</Grid>
					<Grid item className={styles.formItemSingle}>
						<InputLabel>Country</InputLabel>
						<CountrySelector value={watch("countryCode")} register={register} />
					</Grid>
					<Grid className={styles.buttonRow}>
						<PrimaryFormButton
							text={isAdding ? "Add location" : "Update location"}
							size="small"
							variantStyle="contained"
							form="location-form"
							marginRight={1}
							action={() => handleSubmit(saveLocation)}
							isDisabled={isSaveButtonDisabled}
						/>
						{selectedLocationIndex !== null && (
							<Typography
								onClick={() => deleteLocation()}
								className={styles.deleteButton}
							>
								{!isDeleting ? (
									<>
										<DeleteIcon />
										Delete location
									</>
								) : (
									<CircularProgress
										className={styles.deleteButtonLoader}
										size={24}
									/>
								)}
							</Typography>
						)}
					</Grid>
				</form>
			</Grid>
		</PrimaryDialog>
	) : null;
};
