import { SCOPES } from "utilities/constants/constants.ts";
import { get, put } from "utilities/rest.ts";

const ensureAzureApiAccess = async (instance, account, authConfig) => {
	const { tokenType, accessToken } = await instance.acquireTokenSilent({
		scopes: [SCOPES.GRAPH_READ, SCOPES.GRAPH_WRITE],
		account,
	});

	const portalHasAccessResponse = await get(
		authConfig.MS_GRAPH_API_ENDPOINTS.CHECK_MANAGEMENT_ROOT_GROUP_ACCESS(),
		tokenType,
		accessToken,
		"application/json",
		{
			"Ocp-Apim-Subscription-Key": authConfig.OCP_APIM_SUBSCRIPTION_KEY,
		},
	);

	if (portalHasAccessResponse?.data?.hasAccess) return portalHasAccessResponse;

	const azureToken = await instance.acquireTokenSilent({
		scopes: ["https://management.azure.com/user_impersonation"],
		account,
	});

	const accessEnsuredResponse = await put({
		uri: authConfig.MS_GRAPH_API_ENDPOINTS.SET_MANAGEMENT_ROOT_GROUP_ACCESS(
			azureToken.account.idTokenClaims?.oid,
		),
		tokenType,
		accessToken,
		body: JSON.stringify({
			appId: instance.config.auth.clientId,
			userAzureToken: `${azureToken.tokenType} ${azureToken.accessToken}`, // Backend needs the users access token to create the role assignment
		}),
		contentType: "application/json",
		extraHeaders: {
			"Ocp-Apim-Subscription-Key": authConfig.OCP_APIM_SUBSCRIPTION_KEY,
		},
	});

	return accessEnsuredResponse;
};

export { ensureAzureApiAccess };
