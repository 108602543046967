import { Divider, Grid, Skeleton } from "@mui/material";
import { useApi, useApiOnce, useAppSelector, useAuth } from "hooks/hooks";

import { fetchLicenseSubscriptionRecommendations } from "actions/recommendationActions";
import {
	selectRecommendations,
	selectCostSavingRecommendations,
	selectIsScheduledActionsDialogOpen,
	setIsScheduledActionsDialogOpen,
	selectTotalyearlyCostSavings,
} from "features/recommendations";
import { LicenseRecommendation } from "./LicenseRecommendation";

import styles from "./RecommendationsView.module.scss";
import { selectCustomerDetails } from "features/customer";
import { RecommendationsPreview } from "./RecommendtionsPreview";
import { fetchScheduledActions } from "actions/scheduledActionActions";
import {
	selectLicenseActions,
	selectGroupedLicenseActions,
	selectLicenseActionSavingsLastMonth,
	setLicenseActionSkuFilter,
} from "features/scheduledActions";
import ScheduledChangesDialog from "./ScheduledActionsDialog/ScheduledActionsDialog";
import { LicenseActionStatus } from "utilities/constants/enums";

import OptimizedLicensesChart from "./OptimizedLicensesChart/OptimizedLicensesChart";
import { TotalSavingsCard } from "./TotalSavingsCard";
import { ScheduledActionsCard } from "./ScheduledActionsCard";

const RecommendationsView = () => {
	const { dispatch } = useAuth();
	const isScheduledActionsDialogOpen = useAppSelector(selectIsScheduledActionsDialogOpen);
	const { isLoading, isFetching } = useAppSelector(selectRecommendations);
	const { isLoading: isScheduledActionsLoading } = useAppSelector(selectLicenseActions);
	const licenseActionsSavingsLastMonth = useAppSelector(selectLicenseActionSavingsLastMonth);
	const licenseActionGroups = useAppSelector(selectGroupedLicenseActions);
	const { isLoading: isCustomerDetailsLoading, yourEmployeesEnabled = false } =
		useAppSelector(selectCustomerDetails) ?? {};
	const recommendations = useAppSelector(selectCostSavingRecommendations);

	useApiOnce(fetchLicenseSubscriptionRecommendations, { isLoading, isFetching });
	useApi(fetchScheduledActions);

	const totalPossibleSavings = useAppSelector(selectTotalyearlyCostSavings) ?? 0;

	const getRecommendationsContent = () => {
		if (isLoading) {
			return Array.from(Array(10).keys()).map((_, index) => (
				<LicenseRecommendation isLoading={isLoading} key={index} recommendation={null} />
			));
		} else {
			return recommendations.map((recommendation) => (
				<LicenseRecommendation
					key={recommendation.subscriptionOfferName}
					isLoading={isLoading}
					recommendation={recommendation}
				/>
			));
		}
	};

	const handleOpenScheduledActionsDialog = () => {
		dispatch(setLicenseActionSkuFilter("All"));
		dispatch(setIsScheduledActionsDialogOpen(true));
	};

	const numScheduledLicenseActionGroups = Object.values(licenseActionGroups).reduce(
		(acc, curr) => (curr.groupStatus === LicenseActionStatus.Scheduled ? acc + 1 : acc),
		0,
	);

	const maxSavings = totalPossibleSavings;
	const filledSavings = licenseActionsSavingsLastMonth ?? 0;

	if (!isCustomerDetailsLoading && !yourEmployeesEnabled) {
		return <RecommendationsPreview />;
	}

	return (
		<Grid container justifyContent={"center"}>
			<Grid container pb={3} maxWidth={1600}>
				<Grid container item gap={2} justifyContent={"space-between"}>
					<Grid item className={styles.totalCard}>
						<Grid item>
							<TotalSavingsCard
								totalPossibleSavings={totalPossibleSavings}
								yourEmployeesEnabled={yourEmployeesEnabled}
								isLoading={isLoading}
							/>
						</Grid>
					</Grid>
					{yourEmployeesEnabled && (
						<>
							<Grid
								item
								className={styles.scheduledActionsCard}
								onClick={handleOpenScheduledActionsDialog}
							>
								<ScheduledActionsCard
									isScheduledActionsLoading={isScheduledActionsLoading}
									numScheduledLicenseActionGroups={
										numScheduledLicenseActionGroups
									}
								/>
							</Grid>
							<Grid container className={styles.chartContainer}>
								{!isLoading && !isScheduledActionsLoading ? (
									<Grid item className={styles.chart}>
										<OptimizedLicensesChart
											type="linear"
											max={maxSavings}
											filled={filledSavings}
										/>
									</Grid>
								) : (
									<Skeleton
										variant="rectangular"
										width={"100%"}
										height={"100%"}
									/>
								)}
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12} pb={3}>
				<Divider className={styles.divider} />
			</Grid>
			<Grid container className={styles.recommendationsContainer}>
				{getRecommendationsContent()}
			</Grid>
			{isScheduledActionsDialogOpen && (
				<ScheduledChangesDialog isScheduledActionsLoading={isScheduledActionsLoading} />
			)}
		</Grid>
	);
};

export default RecommendationsView;
