import { useState, useEffect } from "react";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { LeftNavigation } from "../LeftNavigation";
import { useSearchParams } from "react-router-dom";
import { SetupDialogs } from "components/SetupDialogs";
import { UserbackProvider } from "@userback/react";
import breakpoints from "styles/utilities/_breakpoints.scss";
import styles from "./AuthenticatedContent.module.scss";
import {
	useAppSelector,
	useAuth,
	useActionOnScreenTreshold,
	useEnvironment,
	useEntitlement,
	useTenantIsAllowed,
} from "hooks/hooks";
import { fetchUser } from "actions/userActions";
import { setRoles, selectRoles } from "features/roles";
import { usersSelectors } from "features/users";
import { RoutedContent } from "./RoutedContent";
import { Baguette } from "./Baguette";
import { useHotjar } from "hooks/useHotjar";
import { ErrorBoundary } from "components/Common/ErrorBoundary";
import { PageLogger } from "components/PageLogger";
import { UserDrawer } from "../MyPeople/UserDrawer";
import { DemoSetupDialog } from "components/SetupDialogs/DemoSetupDialog";
import { EnvironmentBanner } from "./EnvironmentBanner/EnvironmentBanner";
import { TenantNotAllowedDialog } from "components/SetupDialogs/TenantNotAllowedDialog";
import { GlobalSnackbar } from "components/Common/GlobalSnackbar";
import { EntitlementSystem } from "components/Entitlement";
import { calculateRoles } from "utilities/roleUtils/calculateRoles";

const AuthenticatedContent = () => {
	useHotjar();
	const {
		dispatch,
		auth,
		auth: {
			instance,
			account,
			account: { idTokenClaims, localAccountId, tenantId, username, name } = {},
			authConfig,
		},
	} = useAuth();
	const { isHomeTenant } = useEntitlement();

	useEffect(() => {
		const setRolesForUser = async () => {
			const calculatedRoles = await calculateRoles({
				authConfig,
				account,
				isHomeTenant,
				instance,
			});
			dispatch(setRoles(calculatedRoles));
		};
		dispatch(fetchUser({ auth, id: localAccountId }));
		setRolesForUser();
	}, [dispatch, auth, localAccountId, account, authConfig, isHomeTenant, instance]);

	const userData = useAppSelector((state) => usersSelectors.selectById(state, localAccountId));

	useActionOnScreenTreshold(
		(isAboveTreshold) => isAboveTreshold && setIsNavOpen(false),
		breakpoints.lg,
	);

	const [isNavOpen, setIsNavOpen] = useState(false);
	const [searchParams] = useSearchParams();
	const iframeMode = searchParams.get("iframeMode") === "true" ? true : false;

	const roles = useAppSelector(selectRoles);
	const { isDemo } = useEnvironment();

	const togglLeftNavigation = () => setIsNavOpen(!isNavOpen);
	const { tenantHasAccess } = useTenantIsAllowed();

	return (
		<>
			<PageLogger />
			<UserbackProvider
				token="39082|77499|8eVWrmAYGwYYzfcpi79dmtY1M"
				options={{
					email: idTokenClaims?.email ?? "",
					user_data: {
						id: `${username}@${tenantId}`,
						info: {
							name,
							email: idTokenClaims?.email ?? "",
							roles: roles?.toString() ?? "",
							groups: idTokenClaims?.groups?.toString() ?? "",
							objectId: localAccountId,
							tenantId,
						},
					},
				}}
			>
				{isDemo && <DemoSetupDialog />}
				<EnvironmentBanner />
				<TenantNotAllowedDialog />
				{tenantHasAccess && (
					<div className={styles.root}>
						<EntitlementSystem />
						{!iframeMode && (
							<>
								<LeftNavigation
									togglLeftNavigation={setIsNavOpen}
									isNavOpen={isNavOpen}
								/>
							</>
						)}
						<ErrorBoundary>
							<RoutedContent
								navigationNames={NAVIGATION_NAMES}
								department={userData?.department}
								username={username}
							/>
							{/* User drawer should be available on several pages */}
							<UserDrawer />
							<GlobalSnackbar />
						</ErrorBoundary>
						{/* Setup dialogs are not interesting in demo mode */}
						{!isDemo && <SetupDialogs />}
						<Baguette onClick={togglLeftNavigation} navIsOpen={isNavOpen} />
					</div>
				)}
			</UserbackProvider>
		</>
	);
};

export { AuthenticatedContent };
