import { Grid } from "@mui/material";
import { ReactElement } from "react";
import { HardwareOrdersTable } from "./HardwareOrdersTable/HardwareOrdersTable";
import { Search } from "./Search";
import { ReactComponent as HardwareIcon } from "assets/cost/hardware.svg";
import { CostTypeCard } from "components/CostManagement/TotalsCards/CostTypeCard";

import { selectHardwareCostCurrentAndLastMonth, selectOrders } from "features/orders";
import { useAppSelector } from "hooks/hooks";

import styles from "./HardwareOrders.module.scss";
import colors from "styles/utilities/_colors.scss";
import dayjs from "dayjs";

export function HardwareOrders(): ReactElement {
	const { isLoading } = useAppSelector(selectOrders);
	const [hardwareCurrentMonth, hardwareLastMonth] = useAppSelector(
		selectHardwareCostCurrentAndLastMonth,
	);

	return (
		<Grid container className={styles.container}>
			<Grid container spacing={3} alignItems="center">
				<Grid item className={styles.cardAndInputContainer}>
					<CostTypeCard
						isLoading={isLoading}
						title={"Current expense this month"}
						totalCurrentPeriod={hardwareCurrentMonth?.totalCost ?? 0}
						totalPreviousPeriod={hardwareLastMonth?.totalCost ?? 0}
						icon={
							<div className={styles.hardwareIcon}>
								<HardwareIcon />
							</div>
						}
						backgroundColor={colors.hardware}
						size="medium"
						periodLabel={`${dayjs()
							.startOf("month")
							.format("DD.MM")} - ${dayjs().format("DD.MM")}`}
					/>
					<Grid container>
						<Search />
					</Grid>
				</Grid>

				<Grid container className={styles.tableWrapper}>
					<HardwareOrdersTable />
				</Grid>
			</Grid>
		</Grid>
	);
}
