import { Grid, Typography } from "@mui/material";
import {
	TERMS_AND_CONDITIONS_TYPES,
	TermsAndConditionsDialog,
} from "../Dialogs/TermsAndConditionsDialog/TermsAndConditions";
import { PrimaryCheckbox } from "../Checkboxes";

import styles from "./AgreeToTerms.module.scss";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { InternalLink } from "../Links";

type positioning = "flex-start" | "flex-end";

interface AgreeToTermsProps {
	type: TERMS_AND_CONDITIONS_TYPES;
	agreedToTerms: boolean;
	setAgreedToTerms: (value: boolean) => void;
	position: positioning;
	isDisabled?: boolean;
	required?: boolean;
}

/**
 * This component is used to display a checkbox and a link to the terms and conditions dialog.
 * @param type - The type of terms and conditions to display, @see {@link TERMS_AND_CONDITIONS_TYPES}.
 * @param agreedToTerms - Whether or not the user has agreed to the terms and conditions.
 * @param setAgreedToTerms - Function to set the agreedToTerms value.
 * @param position - The position of the checkbox and link - @see {@link positioning}.
 * @param isDisabled - Whether or not the checkbox and link should be disabled, defaults to false.
 */
const AgreeToTerms = ({
	type,
	agreedToTerms,
	setAgreedToTerms,
	position,
	isDisabled = false,
	required = true,
}: AgreeToTermsProps) => {
	const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
	const [currentlyAgreesToTerm, setCurrentlyAgreesToTerm] = useState(false);

	useEffect(() => {
		if (isDisabled) {
			setAgreedToTerms(false);
		}
		setCurrentlyAgreesToTerm(agreedToTerms);
	}, [agreedToTerms, isDisabled, setAgreedToTerms]);

	return (
		<Grid
			item
			xs={12}
			justifyContent={position}
			className={clsx(styles.agreeToTermsContainer, {
				[styles.disabled]: isDisabled,
				[styles.hidden]: !required,
			})}
		>
			<span className={styles.checkboxContainer}>
				<PrimaryCheckbox
					label={""}
					checked={currentlyAgreesToTerm}
					onChange={() => setAgreedToTerms(!agreedToTerms)}
					isDisabled={isDisabled}
				/>
			</span>
			<Typography component="span" className={styles.termsAndConditionsText}>
				I have read and agree to the{" "}
				<InternalLink onClick={() => setShowTermsAndConditions(true)}>
					Terms and Conditions
				</InternalLink>
			</Typography>
			{showTermsAndConditions && (
				<TermsAndConditionsDialog
					open={showTermsAndConditions}
					onClose={() => setShowTermsAndConditions(false)}
					title="Terms and Conditions"
					type={type}
				/>
			)}
		</Grid>
	);
};

export { AgreeToTerms };
