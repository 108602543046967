import { AuthConfig } from "types";
import { SCOPES } from "utilities/constants/constants";
import { post } from "utilities/rest";

interface CreateAllReportsProps {
	instance: any;
	account: any;
	authConfig: AuthConfig;
}

interface CreateReportProps {
	instance: any;
	account: any;
	authConfig: AuthConfig;
	url: string;
}

const createReport = async ({ instance, account, authConfig, url }: CreateReportProps) => {
	const { tokenType, accessToken } = await instance.acquireTokenSilent({
		scopes: [SCOPES.REPORTS_WRITE],
		account,
	});

	return post({
		uri: url,
		tokenType,
		accessToken,
		contentType: "application/json",
		extraHeaders: {
			"Ocp-Apim-Subscription-Key": authConfig.OCP_APIM_SUBSCRIPTION_KEY,
		},
	});
};

const createAllReports = async (auth: CreateAllReportsProps) => {
	const endpoints = auth.authConfig.REPORTS_API_ENDPOINTS;
	createReport({
		...auth,
		url: endpoints.CREATE_AGGREGATED_DATA(),
	});
	createReport({
		...auth,
		url: endpoints.CREATE_AZURE_BILLINGDATA(),
	});
	createReport({
		...auth,
		url: endpoints.CREATE_OFFICE365_BILLINGDATA(),
	});
	createReport({
		...auth,
		url: endpoints.CREATE_TIMELY_HOURS_REPORT(),
	});
	createReport({
		...auth,
		url: endpoints.CREATE_MARKETPLACE_ITEMS_BILLINGDATA(),
	});
	createReport({
		...auth,
		url: endpoints.CREATE_RESERVED_INSTANCES_BILLINGDATA(),
	});
	createReport({
		...auth,
		url: endpoints.CREATE_LICENSE_COST_RECOMMENDATIONS(),
	});
};

export { createReport, createAllReports };
