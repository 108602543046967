import {
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Grid,
} from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import clsx from "clsx";
import dayjs from "dayjs";
import { HoverTooltip } from "components/Common/Tooltips";

import styles from "./DevicesInfo.module.scss";
import { useAppSelector } from "hooks/hooks";
import { usersSelectors } from "features/users";
import { DATE_FORMATS } from "utilities/constants/enums";

interface DevicesInfoProps {
	userId: string;
}

const DevicesInfo = ({ userId }: DevicesInfoProps): JSX.Element => {
	const { devices = [] } =
		useAppSelector((state) => usersSelectors.selectById(state, userId)) ?? {};

	const anyDevices = devices.length !== 0;

	return (
		<>
			<Grid item xs={12}>
				<TableContainer className={styles.tableContainer}>
					<Table aria-label="simple table">
						<TableHead className={styles.tableHead}>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>OS</TableCell>
								<TableCell>Version</TableCell>
								<TableCell>Compliant</TableCell>
								<TableCell>Registered</TableCell>
								<TableCell>
									<HoverTooltip title="This is an approximate date">
										<div className={styles.activityInfo}>
											<InfoOutlinedIcon />
											<Typography className={styles.activityText}>
												Activity
											</Typography>
										</div>
									</HoverTooltip>
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{anyDevices ? (
								devices.map((device) => (
									<TableRow key={device.id}>
										<TableCell>{device.displayName}</TableCell>
										<TableCell>{device.operatingSystem}</TableCell>
										<TableCell>{device.operatingSystemVersion}</TableCell>
										<TableCell
											className={clsx(!device.isCompliant && styles.red)}
										>
											{device.isCompliant ? "Yes" : "No"}
										</TableCell>
										<TableCell>
											{device.registeredDate &&
												dayjs(device.registeredDate).format(
													DATE_FORMATS.NO_CLOCK,
												)}
										</TableCell>
										<TableCell>
											{device.lastActivityDate &&
												dayjs(device.lastActivityDate).format(
													DATE_FORMATS.NO_CLOCK,
												)}
										</TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell
										colSpan={6}
										align="center"
										className={styles.noDevicesCell}
									>
										<Typography variant="body2">
											No devices registered for this user
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</>
	);
};

export { DevicesInfo };
