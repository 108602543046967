import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import {
	createEntitlement,
	fetchPossibleEntitlements,
	fetchAvailableEntitlements,
} from "actions/entitlementActions";
import { RootState } from "store";
import { CustomerInfo } from "types";

// Extend the CustomerInfo type to include the entitlement ID
interface EntitlementEntry extends CustomerInfo {
	id: string; // String identifier for the tenant
}

const entitlementsAdapter = createEntityAdapter<EntitlementEntry>({
	selectId: (entitlement) => entitlement.id,
});

const availableEntitlementsAdapter = createEntityAdapter<string>({
	selectId: (entitlement) => entitlement,
});

const entitlementSlice = createSlice({
	name: "entitlements",
	initialState: {
		allEntitlements: entitlementsAdapter.getInitialState({
			isLoading: true,
			isFetching: false,
		}),
		availableEntitlements: availableEntitlementsAdapter.getInitialState({
			isLoading: true,
			isFetching: false,
		}),
		errorCreatingEntitlement: false,
		activeEntitlement: null as EntitlementEntry | null,
		activeStep: 0,
		redeemUrl: null,
	},
	reducers: {
		setActiveEntitlement: (state, { payload }) => {
			state.activeEntitlement = payload;
		},
		setActiveStep: (state, { payload }) => {
			state.activeStep = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPossibleEntitlements.pending, (state) => {
				state.allEntitlements.isFetching = true;
			})
			.addCase(fetchPossibleEntitlements.fulfilled, (state, { payload }) => {
				entitlementsAdapter.setAll(
					state.allEntitlements,
					payload.map((entitlement) => ({
						...entitlement,
						id: entitlement.primaryTenantId?.split(",")[0],
					})),
				);
				state.allEntitlements.isFetching = false;
				state.allEntitlements.isLoading = false;
			});

		builder
			.addCase(fetchAvailableEntitlements.pending, (state) => {
				state.availableEntitlements.isFetching = true;
			})
			.addCase(fetchAvailableEntitlements.fulfilled, (state, { payload }) => {
				availableEntitlementsAdapter.setAll(state.availableEntitlements, payload);
				state.availableEntitlements.isFetching = false;
				state.availableEntitlements.isLoading = false;
			});

		builder
			.addCase(createEntitlement.pending, (state) => {
				state.errorCreatingEntitlement = false;
			})
			.addCase(createEntitlement.fulfilled, (state, { payload }) => {
				state.redeemUrl = (payload as any).redeemUrl;
			})
			.addCase(createEntitlement.rejected, (state, { error }) => {
				state.errorCreatingEntitlement = true;
			});
	},
});

export const { setActiveEntitlement, setActiveStep } = entitlementSlice.actions;
export const allEntitlementsSelectors = entitlementsAdapter.getSelectors<RootState>(
	(state) => state.entitlements.allEntitlements,
);
export const availableEntitlementsSelectors = availableEntitlementsAdapter.getSelectors<RootState>(
	(state) => state.entitlements.availableEntitlements,
);
export const selectEntitlementState = (state: RootState) => state.entitlements.allEntitlements;
export const selectAvailableEntitlementState = (state: RootState) =>
	state.entitlements.availableEntitlements;
export const selectActiveEntitlement = (state: RootState) => state.entitlements.activeEntitlement;
export const selectActiveStep = (state: RootState) => state.entitlements.activeStep;
export const selectErrorCreatingEntitlement = (state: RootState) =>
	state.entitlements.errorCreatingEntitlement;

// Returns raw list of tid's the user can access
export const selectAllAvailableEntitlementsTenantIds = (state: RootState) =>
	availableEntitlementsSelectors.selectAll(state);
// Returns list of all the same tenants as the raw list above, but with the full entitlement object
export const selectAllAvailableEntitlements = createSelector(
	selectEntitlementState,
	selectAvailableEntitlementState,
	(allEntitlements, availableEntitlements) => {
		const allEntitlementsArray = entitlementsAdapter.getSelectors().selectAll(allEntitlements);
		const availableEntitlementsArray = availableEntitlementsAdapter
			.getSelectors()
			.selectAll(availableEntitlements);

		return allEntitlementsArray.filter((entitlement) =>
			availableEntitlementsArray.includes(entitlement.id),
		);
	},
);

export const selectGuestRedeemInvitationUrl = (state: RootState) => state.entitlements.redeemUrl;

export default entitlementSlice.reducer;
