import { Chart } from "chart.js";
import { useEffect, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";

import styles from "./OptimizedLicensesChart.module.scss";

interface OptimizedLicensesChartProps {
	type: "linear" | "circular";
	filled: number;
	max: number;
}

const OptimizedLicensesChart = ({ type, filled, max }: OptimizedLicensesChartProps) => {
	const chartRef = useRef<Chart | null>(null);
	useEffect(() => {
		const currentChartRef = chartRef.current;
		const afterDrawPlugin = () => ({
			id: `afterDrawPlugin`,
			afterDraw: function (chart: Chart<"doughnut", number[], unknown>) {
				const ctx = chart.ctx;
				const { width, height } = chart;
				const fontSizeLarge = Math.round((height / 100) * 15);
				const fontSizeSmall = Math.round((height / 100) * 12);
				const firstDataValue = chart.data.datasets[0]?.data[0] ?? 0;
				const secondDataValue = chart.data.datasets[0]?.data[1] ?? 0;
				let percentage = firstDataValue / (secondDataValue + firstDataValue);
				if (percentage > 1) {
					percentage = 1;
				}
				let theCenterText = `${Math.round(percentage * 100)}%`;

				ctx.save();
				ctx.textBaseline = "middle";
				ctx.textAlign = "center";
				ctx.font = `${fontSizeLarge}px Inter`;
				ctx.fillStyle = "black";
				ctx.fillText(theCenterText, width / 2, height / 2 - fontSizeSmall);
				ctx.font = `${fontSizeSmall}px Inter`;
				ctx.fillText("optimized", width / 2, height / 2 + fontSizeSmall);
				ctx.restore();
			},
		});

		Chart.register(afterDrawPlugin());
		return () => {
			Chart.unregister(afterDrawPlugin());
			if (currentChartRef) {
				currentChartRef.destroy();
			}
		};
	}, []);

	const options = {
		cutout: "80%",
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
		},
		responsive: true,
	};

	// Max savings - what savings that has actually been done might be negative.
	// This happens when the user has made savings "yesterday". The savings are then removed from the "potential savings/max",
	// but not from the "completed savings/filled".
	const savingsLeft = Math.max(max - filled, 0);

	const data = {
		labels: ["Completed", "Potential"],
		datasets: [
			{
				data: [filled, savingsLeft],
				backgroundColor: ["#47AE8187", "#3575A4B2"],
			},
		],
	};

	return (
		<>
			{type === "linear" ? (
				<Grid container className={styles.labelsContainer}>
					<Grid item xs={12} pb={3}>
						<Typography variant="h3" fontWeight={600}>
							{"Saved cost last 30 days"}
						</Typography>
					</Grid>
					<Grid item className={styles.labelContainer} mr={3}>
						<Grid>
							<SquareRoundedIcon className={styles.greenIcon} />
						</Grid>
						<Grid item container direction="column">
							<Typography variant="caption">Saved</Typography>
							<Typography variant="subtitle1">
								{formatCostString("", `${filled}`, " kr / year")}
							</Typography>
						</Grid>
					</Grid>
					<Grid item className={styles.labelContainer}>
						<Grid>
							<SquareRoundedIcon className={styles.blueIcon} />
						</Grid>
						<Grid item container direction="column">
							<Typography variant="caption">Potential savings</Typography>
							<Typography variant="subtitle1">
								{formatCostString("", `${max}`, " kr / year")}
							</Typography>
						</Grid>
					</Grid>
					<LinearProgress
						variant="determinate"
						value={(filled / (max + filled)) * 100}
						className={styles.linearProgress}
						sx={{
							"& .MuiLinearProgress-bar": {
								backgroundColor: "rgba(158, 212, 188, 1)",
							},
							"& .MuiLinearProgress-bar1Determinate": {
								borderRight: "1px solid #fff",
							},
						}}
					/>
				</Grid>
			) : (
				<div className={styles.doughnut}>
					<Doughnut data={data} options={options} />
				</div>
			)}
		</>
	);
};

export default OptimizedLicensesChart;
