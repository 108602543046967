import { Typography, Grid, CircularProgress } from "@mui/material";

import clsx from "clsx";
import dayjs from "dayjs";

import styles from "./UserDeviceDetails.module.scss";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { selectIsUserDevicesLoading, usersSelectors } from "features/users";
import { DATE_FORMATS, DeviceComplianceStatus } from "utilities/constants/enums";
import { selectUserDrawerState } from "features/user";
import { fetchUserDevices } from "actions/userActions";
import { useEffect } from "react";
import { getOperatingSystemIcon } from "utilities/iconUtils/operatingSystemIconUtils";
import { getHardwareIconBasedOnOperatingSystem } from "utilities/iconUtils/hardwareIconUtils";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import { selectDevices, selectDevicesKeyedByGraphDeviceId } from "features/devices";
import { Device } from "types";
import { fetchAllDevices } from "actions/deviceActions";

const UserDeviceDetails = (): JSX.Element => {
	const { auth, dispatch } = useAuth();
	const { activeUserId } = useAppSelector(selectUserDrawerState);
	const isDevicesLoading = useAppSelector(selectIsUserDevicesLoading);
	const user = useAppSelector((state) => usersSelectors.selectById(state, activeUserId));
	const { devices = [] } =
		useAppSelector((state) => usersSelectors.selectById(state, activeUserId)) ?? {};
	const allDevices = useAppSelector(selectDevicesKeyedByGraphDeviceId);

	// Full compliance / device status info only found by getting the full management info
	useApiOnce(fetchAllDevices, useAppSelector(selectDevices));
	const { isLoading: isManagedDevicesLoading } = useAppSelector(selectDevices);

	const anyDevices = devices.length !== 0;

	useEffect(() => {
		if (user?.devices instanceof Array) return;
		dispatch(fetchUserDevices({ auth, id: activeUserId }));
	}, [dispatch, auth, activeUserId, user?.devices]);

	const getActivityStatus = (lastActivityDate: string | null, fullDevice: Device) => {
		if (fullDevice)
			return fullDevice.deviceStatuses.includes(DeviceComplianceStatus.Inactive)
				? "Inactive"
				: "Active";
		const thirtyDaysAgo = dayjs().subtract(30, "days").format();
		const isLastActivityAbove30Days = dayjs(lastActivityDate).isBefore(thirtyDaysAgo);
		return isLastActivityAbove30Days ? "Inactive" : "Active";
	};

	return (
		<Grid container spacing={2} mt={2}>
			{anyDevices ? (
				devices.map((device) => {
					const fullDevice = allDevices[device.id];
					return (
						<Grid container key={device.id}>
							<Grid className={styles.deviceCard}>
								<Grid container className={styles.summary}>
									<Grid item className={styles.deviceNameContainer}>
										<svg width="20" height="20" viewBox="0 0 60 60">
											{getHardwareIconBasedOnOperatingSystem(
												device.operatingSystem,
											)}
										</svg>
										<TruncatableTypography variant="body1" maxCharLength={25}>
											{device.displayName}
										</TruncatableTypography>
										<svg width="100" height="20" className={styles.osIcon}>
											{getOperatingSystemIcon(device.operatingSystem)}
										</svg>
									</Grid>
									<Grid item className={styles.statusContainer}>
										<Typography
											variant="caption"
											className={clsx(
												getActivityStatus(
													device.lastActivityDate,
													fullDevice,
												) === "Active"
													? styles.greenLabel
													: styles.yellowLabel,
											)}
										>
											{getActivityStatus(device.lastActivityDate, fullDevice)}
										</Typography>
										<Typography
											variant="caption"
											className={clsx({
												[styles.hidden]:
													fullDevice?.deviceStatuses.length === 1 &&
													fullDevice?.deviceStatuses.includes(
														DeviceComplianceStatus.Inactive,
													), // Inactive status not currently interesting in this context
												[styles.greenLabel]:
													fullDevice?.deviceStatuses.includes(
														DeviceComplianceStatus.Compliant,
													),
												[styles.redLabel]:
													fullDevice?.deviceStatuses.includes(
														DeviceComplianceStatus.NonCompliant,
													),
											})}
										>
											{fullDevice?.deviceStatuses.find(
												(status) =>
													status !== DeviceComplianceStatus.Inactive,
											)}
										</Typography>
									</Grid>
								</Grid>
								<Grid container className={styles.deviceInfo}>
									<Grid container className={styles.deviceInfoRow}>
										<DeviceInfoItem
											label="OS"
											value={device.operatingSystem}
											width={1 / 3}
										/>
										<DeviceInfoItem
											label="Version"
											value={device.operatingSystemVersion}
											width={1 / 3}
										/>
										<DeviceInfoItem
											label="Serial number"
											value={
												isManagedDevicesLoading
													? "Loading..."
													: fullDevice?.serialNumber ?? "Not enrolled"
											}
											width={1 / 3}
										/>
									</Grid>
									<Grid container className={styles.deviceInfoRow}>
										<DeviceInfoItem
											label="Registered"
											value={
												device.registeredDate &&
												dayjs(device.registeredDate).format(
													DATE_FORMATS.NO_CLOCK,
												)
											}
											width={1 / 3}
										/>
										<DeviceInfoItem
											label="Last active"
											value={
												device.lastActivityDate &&
												dayjs(device.lastActivityDate).format(
													DATE_FORMATS.NO_CLOCK,
												)
											}
											width={2 / 3}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					);
				})
			) : isDevicesLoading ? (
				<Grid container item xs={12} mt={3} justifyContent="center">
					<CircularProgress />
				</Grid>
			) : (
				<Grid item xs={12} mt={5}>
					<Typography variant="body1" align="center">
						No devices registered for this user
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

interface DeviceInfoItemProps {
	label: string;
	value: string;
	width: number;
}

const DeviceInfoItem = ({ label, value, width }: DeviceInfoItemProps) => {
	return (
		<Grid item width={width} className={styles.deviceInfoItem}>
			<Grid item>
				<Typography variant="description">{label}</Typography>
			</Grid>
			<Grid item>
				<Typography variant="description" color="text.secondary">
					{value}
				</Typography>
			</Grid>
		</Grid>
	);
};

export { UserDeviceDetails };
