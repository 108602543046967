import { Grid, IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import styles from "./ConsultancyCostView.module.scss";
import { useApiOnce, useAppSelector } from "hooks/hooks";
import { fetchConsultancyCost } from "actions/costActions";
import { consultancySelectors, selectConsultancyCost } from "features/costs";
import { ConsultancyCost, ConsultancyCostEntries } from "types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
import { DateRangePicker } from "components/Common/DateRangePicker/DateRangePicker";
import { ConsultancyCostTable } from "./ConsultancyCostTable";
import { DataUpdateInterval } from "components/Common/DataUpdateInterval";
import _ from "lodash";
import { CostTypeCard } from "../TotalsCards/CostTypeCard";
import { ReactComponent as TeleSales } from "assets/cost/TeleSales.svg";
import colors from "styles/utilities/_colors.scss";
import { filterEntriesOnSelectedRange } from "utilities/dates/dateRangePicker";

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);

const ConsultancyCostView = (): JSX.Element => {
	const consultancyCost = useAppSelector(consultancySelectors.selectAll);
	const { isLoading, isFetching } = useAppSelector(selectConsultancyCost);
	useApiOnce(fetchConsultancyCost, { isLoading, isFetching });

	const cost =
		consultancyCost.find(({ period }) => dayjs().isSame(dayjs(period, "MMMM YYYY"), "month")) ??
		({} as ConsultancyCost);
	const costLastPeriod =
		consultancyCost.find(({ period }) =>
			dayjs().subtract(1, "month").isSame(dayjs(period, "MMMM YYYY"), "month"),
		) ?? ({} as ConsultancyCost);

	const rangesBasedOnFetchedData = consultancyCost?.map(({ period }) => period);

	// Billed aka billable cost, what the customer actually needs to pay
	const totalCostCurrentPeriod = cost.totalForecasted?.exclVat || 0;
	const totalCostLastPeriod = costLastPeriod.totalForecasted?.exclVat || 0;

	const allEntries = _.flatMap(consultancyCost, "allEntries");

	const defaultStartRange = {
		startDate: dayjs().subtract(12, "month").startOf("month").toDate(),
		endDate: dayjs().endOf("day").toDate(),
		key: "selection",
	};

	const [ranges, setRanges] = useState([defaultStartRange]);
	const [searchValue, setSearchValue] = useState("");

	const filtersAreDefault =
		dayjs(ranges[0].startDate).isSame(defaultStartRange.startDate, "day") &&
		dayjs(ranges[0].endDate).isSame(defaultStartRange.endDate, "day") &&
		searchValue === "";

	const [showDateRangePicker, setShowDateRangePicker] = useState(false);

	const filterEntries = (entries: ConsultancyCostEntries[], filter: any) => {
		const { searchValue, ranges } = filter;

		const duringPeriodEntries = filtersAreDefault
			? entries
			: filterEntriesOnSelectedRange(ranges[0].startDate, ranges[0].endDate, entries, "day");

		const matchesSearchInput = (property: string) => {
			const matches = property?.toLowerCase().includes(searchValue.toLowerCase());
			return matches;
		};

		if (searchValue === "") return duringPeriodEntries;

		const filteredEntries = duringPeriodEntries.filter(
			({
				consultantEmail,
				consultantName,
				ticketSubject,
				projectName,
				labels,
			}: ConsultancyCostEntries) =>
				[
					consultantEmail,
					consultantName,
					ticketSubject,
					projectName,
					JSON.stringify(labels),
				].some(matchesSearchInput),
		);

		return filteredEntries;
	};

	const handleResetFilters = () => {
		setRanges([defaultStartRange]);
		setSearchValue("");
	};

	const selectedEntries = filterEntries(allEntries, {
		searchValue,
		ranges,
	});
	const selectedRangeDisplay = filtersAreDefault
		? ""
		: `${
				dayjs(ranges[0].startDate).format("DD.MM.YY") +
				" - " +
				dayjs(ranges[0].endDate).format("DD.MM.YY")
		  }`;

	const labelThisPeriod = `${dayjs().startOf("month").format("DD.MM")} -
		${dayjs().format("DD.MM")}`;

	return (
		<Grid container className={styles.wrapper}>
			<Grid
				container
				className={styles.topSectionContainer}
				justifyContent="space-between"
				mb={3}
			>
				<Grid container>
					<Grid container className={styles.cardAndInputContainer}>
						<Grid container mb={1}>
							<CostTypeCard
								isLoading={isLoading}
								title="Consultancy"
								totalCurrentPeriod={totalCostCurrentPeriod}
								totalPreviousPeriod={totalCostLastPeriod}
								icon={<TeleSales />}
								backgroundColor={colors.yellowTint4}
								size="medium"
								navigationPath="/cost/consultancy"
								periodLabel={labelThisPeriod}
							/>
						</Grid>
						<Grid container className={styles.inputContainer}>
							<Grid item xs={12}>
								<div className={styles.inputRoot}>
									<IconButton
										disabled
										className={styles.iconButton}
										aria-label="search"
									>
										<SearchIcon />
									</IconButton>
									<InputBase
										value={searchValue}
										className={styles.input}
										placeholder="Project, ticket, consultant"
										onChange={(e) => setSearchValue(e.target.value)}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<DateRangePicker
									showDateRangePicker={showDateRangePicker}
									setShowDateRangePicker={setShowDateRangePicker}
									ranges={ranges}
									setRanges={setRanges}
									selectablePeriods={rangesBasedOnFetchedData}
									selectedRangeDisplay={selectedRangeDisplay}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item mt={3}>
					<DataUpdateInterval updateIntervalText={"Cost data updates every Monday"} />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<ConsultancyCostTable
					isLoading={isLoading}
					filtersAreDefault={filtersAreDefault}
					consultancyCostEntries={selectedEntries}
					selectedRangeDisplay={selectedRangeDisplay}
					onResetFilters={handleResetFilters}
				/>
			</Grid>
		</Grid>
	);
};

export { ConsultancyCostView };
