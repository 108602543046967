import {
	createDeviceCompliancyReport,
	fetchAllDevices,
	fetchDeviceComplianceReportStatus,
} from "actions/deviceActions";
import {
	devicesSelectors,
	resetFilters,
	selectChosenDeviceFilters,
	selectDeviceComplianceReport,
	selectDeviceFilterOptions,
	selectDevices,
	setChosenDeviceFilters,
	setFilterIds,
} from "features/devices";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { useEffect, useState } from "react";
import { ManageDevicesTable } from "./ManageDevicesTable";
import { fetchUsers } from "actions/userActions";
import { selectUsers } from "features/users";
import { Grid, IconButton, InputBase } from "@mui/material";

import styles from "./ManageDevices.module.scss";
import { DeviceCardsSection } from "./DeviceCardsSection";
import SearchIcon from "@mui/icons-material/Search";
import { ListFilter } from "components/Common/ListFilter";
import { IntuneManagementType } from "utilities/constants/enums";
import { filterBasedOnChosenFilters } from "utilities/filterDeviceData";
import { DeviceFilters } from "types";
import { useDynamicCSSVar } from "hooks/cssHooks";
import { SecondaryButton } from "components/Common/Buttons/Button";
import { Download } from "@mui/icons-material";
import { csvDownload } from "components/Common/DownloadableLink";
import dayjs from "dayjs";

const DeviceOverview = () => {
	const { auth, dispatch } = useAuth();
	const devices = useAppSelector(selectDevices);
	const complianceReportState = useAppSelector(selectDeviceComplianceReport);
	const allDeviceIds = useAppSelector(devicesSelectors.selectIds);
	const allDevices = useAppSelector(devicesSelectors.selectAll);

	const chosenFilters = useAppSelector(selectChosenDeviceFilters);
	const deviceFilters = useAppSelector(selectDeviceFilterOptions);
	const [searchValue, setSearchValue] = useState("");

	useApiOnce(fetchAllDevices, devices);
	useApiOnce(fetchDeviceComplianceReportStatus, complianceReportState);
	useApiOnce(fetchUsers, useAppSelector(selectUsers));

	useEffect(() => {
		const createAndFetch = async () => {
			await dispatch(
				createDeviceCompliancyReport({
					auth,
					body: {},
				}),
			);
			setTimeout(async () => {
				await dispatch(fetchAllDevices({ auth }));
			}, 15000); // We give the report some seconds to be created
		};

		if (complianceReportState.isError) {
			// If the intune device compliance report is not created, we create it and fetch the devices
			createAndFetch();
		}
	}, [complianceReportState.isError, dispatch, auth]);

	const handleSetChosenDeviceFilters = (newFilters: DeviceFilters) => {
		dispatch(setChosenDeviceFilters(newFilters));
		const filterIds = filterBasedOnChosenFilters(allDevices, newFilters);
		dispatch(setFilterIds(filterIds));
	};

	const onResetFilters = () => {
		dispatch(resetFilters());
		dispatch(setFilterIds(allDeviceIds));
		setSearchValue("");
	};

	const dynamicRef = useDynamicCSSVar("top-search-bar-height");

	const downloadAllDevices = () => {
		csvDownload(
			dispatch,
			allDevices,
			`Devices: ${dayjs().format("YYYY-MM-DD")}.csv`,
			";",
			true,
		);
	};

	return (
		<Grid container className={styles.container}>
			<Grid container className={styles.topSectionContainer}>
				<DeviceCardsSection />
			</Grid>
			<Grid container item className={styles.topSearchBar} ref={dynamicRef}>
				<Grid container gap={2}>
					<Grid item className={styles.searchFieldSection}>
						<div className={styles.inputRoot}>
							<IconButton disabled className={styles.iconButton} aria-label="search">
								<SearchIcon />
							</IconButton>
							<InputBase
								value={searchValue}
								className={styles.input}
								placeholder="Device name, user name, serial number"
								inputProps={{ "aria-label": "search for devices or users" }}
								onChange={(e) => setSearchValue(e.target.value)}
							/>
						</div>
						<SecondaryButton
							text="Export all"
							size="small"
							variantStyle="outlined"
							action={downloadAllDevices}
							icon={<Download className={styles.downloadIcon} />}
							className={styles.exportButtonSmallScreen}
						/>
					</Grid>
					<Grid item className={styles.filtersAndButtonContainer}>
						<Grid item className={styles.filters}>
							<ListFilter
								id="device-os-filter"
								filterValues={chosenFilters.osFilters || []}
								filterOptions={deviceFilters.osFilters || []}
								updateFilter={(value) =>
									handleSetChosenDeviceFilters({
										...chosenFilters,
										osFilters: value as string[],
									})
								}
								noneSelectedLabel="Operating systems"
								disabled={devices.isLoading}
							/>
							<ListFilter
								id="device-intune-filter"
								filterValues={chosenFilters.intuneManagementFilters || []}
								filterOptions={deviceFilters.intuneManagementFilters || []}
								updateFilter={(value) =>
									handleSetChosenDeviceFilters({
										...chosenFilters,
										intuneManagementFilters: value as IntuneManagementType[],
									})
								}
								noneSelectedLabel="Intune status"
								disabled={devices.isLoading}
							/>
							<ListFilter
								id="device-status-filter"
								filterValues={chosenFilters.complianceFilters || []}
								filterOptions={deviceFilters.complianceFilters || []}
								updateFilter={(value) =>
									handleSetChosenDeviceFilters({
										...chosenFilters,
										complianceFilters: value as string[],
									})
								}
								noneSelectedLabel="Device status"
								disabled={devices.isLoading}
							/>
						</Grid>
						<SecondaryButton
							text="Export all"
							size="small"
							variantStyle="outlined"
							action={downloadAllDevices}
							icon={<Download className={styles.downloadIcon} />}
							className={styles.exportButton}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid container>
				<ManageDevicesTable searchValue={searchValue} onResetFilters={onResetFilters} />
			</Grid>
		</Grid>
	);
};

export { DeviceOverview };
