import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";

import styles from "./LicenseRemovalRow.module.scss";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { LicenseSubscription, LicenseSubscriptionRecommendation } from "types";
import { TermDurationLabel } from "components/CostManagement/LicenseCostView/LicenseCostTable/TermDurationLabel";

interface LicenseRemovalRowInputProps {
	recommendation: LicenseSubscriptionRecommendation;
	removalQuantity: number;
	subscriptionId: string;
}

const LicenseRemovalRow = ({
	recommendation,
	removalQuantity,
	subscriptionId,
}: LicenseRemovalRowInputProps) => {
	const subscription =
		recommendation.subscriptions.find((subscription) => subscription.id === subscriptionId) ??
		({} as LicenseSubscription);
	const savings = subscription.unitPriceAfterDiscount * removalQuantity;
	return (
		<TableRow key={subscriptionId} className={styles.noBorderTableRow}>
			<TableCell>
				{removalQuantity} x {recommendation.subscriptionOfferName}
			</TableCell>
			<TableCell>{dayjs(subscription.commitmentEndDate).format("DD.MM.YYYY")}</TableCell>
			<TableCell>
				<TermDurationLabel label={subscription.termDuration} />
			</TableCell>
			<TableCell align={"right"}>
				{formatCostString(" ", savings * 12, " ,- / year")}
			</TableCell>
		</TableRow>
	);
};

export { LicenseRemovalRow };
