import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect, useMemo, RefObject } from "react";
import config from "authConfig";
import { allowedPageForRole } from "utilities/roleUtils/allowedPageForRole";
import { getAllowedPagesForCustomer } from "utilities/accessUtils/allowedPagesForCustomer";
import { selectCustomerDetails } from "features/customer";
import { selectRoles } from "features/roles";
import { ROLES_ACCESS } from "utilities/constants/pages";
import { NavigationName } from "types";
import { fetchLicenseSubscription } from "actions/licenseActions";
import { fetchLicensePrices } from "actions/licensePriceActions";
import { licenseSubscriptionsSelectors } from "features/licenses/licenses";
import { selectLicensePrices } from "features/licenses/licensePrices";
import { selectAccess } from "features/access";
import { checkAccess } from "actions/accessActions";
import {
	selectAllAvailableEntitlementsTenantIds,
	selectAvailableEntitlementState,
} from "features/entitlements";
import { fetchAvailableEntitlements } from "actions/entitlementActions";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAuth = () => {
	const {
		instance,
		accounts: [account],
	} = useMsal();

	const entitlement = sessionStorage.getItem("entitlement");

	const { idTokenClaims = { wids: [] }, tenantId } = account ?? {};

	const isAdmin = idTokenClaims.roles?.includes("Admin") ?? false;
	const tenantIdAuth = entitlement ?? tenantId;

	const authConfig = useMemo(() => new config.AuthConfig(tenantIdAuth), [tenantIdAuth]);

	const dispatch = useAppDispatch();

	return useMemo(
		() => ({ auth: { instance, account, authConfig }, dispatch, isAdmin }),
		[account, instance, authConfig, dispatch, isAdmin],
	);
};

/*
 * Use this function if rerendering a component should trigger API refetch
 * DO NOT USE unless fresh data on each render is required
 * */
export const useApi = (action: any) => {
	const { auth, dispatch } = useAuth();

	return useEffect(() => {
		dispatch(action({ auth }));
	}, [dispatch, action, auth]);
};

/*
 * Use this function if rerendering a component should trigger API refetch and skip cache
 * DO NOT USE unless fresh data on each render is required
 * */
export const useApiNoCache = (action: any) => {
	const { auth, dispatch } = useAuth();

	return useEffect(() => {
		dispatch(action({ auth, headers: { "Cache-Control": "no-cache" } }));
	}, [dispatch, action, auth]);
};

interface LoadingProps {
	isLoading: boolean;
	isFetching: boolean;
}

// Use this function if rerendering a component should NOT trigger API refetch (i.e., on initial render only)
export const useApiOnce = (action: any, { isLoading, isFetching }: LoadingProps) => {
	const { auth, dispatch } = useAuth();

	return useEffect(() => {
		if (!isLoading || isFetching) return;

		dispatch(action({ auth }));
	}, [dispatch, action, auth, isLoading, isFetching]);
};

export const useApiOnceWithParams = (
	action: any,
	{ isLoading, isFetching }: LoadingProps,
	params: any,
) => {
	const { auth, dispatch } = useAuth();

	return useEffect(() => {
		if (!isLoading || isFetching) return;

		dispatch(action({ auth, params }));
	}, [dispatch, action, auth, isLoading, isFetching, params]);
};

// Use this function if rerendering a component should NOT trigger API refetch (i.e., on initial render only and skip cache)
export const useApiOnceNoCache = (
	action: any,
	{ isLoading, isFetching }: LoadingProps,
	cache: string,
) => {
	const { auth, dispatch } = useAuth();

	return useEffect(() => {
		if (!isLoading || isFetching) return;

		dispatch(action({ auth, headers: { "Cache-Control": cache } }));
	}, [dispatch, action, auth, isLoading, isFetching, cache]);
};

// Use this function when you want to fetch data once on initial render and then again without cache
export const useApiOnceWithAndWithoutCache = (
	action: any,
	{ isLoading, isFetching }: LoadingProps,
) => {
	const { auth, dispatch } = useAuth();

	return useEffect(() => {
		if (!isLoading || isFetching) return;

		dispatch(action({ auth })).then(() => {
			dispatch(action({ auth, headers: { "Cache-Control": "no-cache" } }));
		});
	}, [dispatch, action, auth, isLoading, isFetching]);
};

const debounce = (func: any, wait: number) => {
	let timeout: any;
	return (...args: any[]) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(this, args), wait);
	};
};

export const useResize = (ref: any, debounceTime = 200) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const handleResize = debounce(() => {
			if (ref.current) {
				setDimensions({
					width: ref.current.offsetWidth,
					height: ref.current.offsetHeight,
				});
			}
		}, debounceTime);

		window.addEventListener("resize", handleResize);
		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [ref, debounceTime]);

	return dimensions;
};

export const useWindowSize = (debounceTime = 200) => {
	const [windowSize, setWindowSize] = useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	useEffect(() => {
		const handleResize = debounce(() => {
			setWindowSize({ height: window.innerHeight, width: window.innerWidth });
		}, debounceTime);

		window.addEventListener("resize", handleResize);
		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [debounceTime]);

	return windowSize;
};

export const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void): void => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				callback();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, callback]);
};

export const useAllowedPagesForUser = () => {
	const customerInfo = useAppSelector(selectCustomerDetails);
	const roles = useAppSelector(selectRoles);

	const [allowedPagesForRole, setAllowedPagesForRole] = useState<NavigationName[]>([]);
	const [allowedPagesForCustomer, setAllowedPagesForCustomer] = useState<NavigationName[]>([]);

	useEffect(() => {
		const roleHasAccessToPages = allowedPageForRole(roles, ROLES_ACCESS);
		const customerHasAccessToPages = getAllowedPagesForCustomer({
			yourEmployeesEnabled: customerInfo?.yourEmployeesEnabled,
			yourItSystemsEnabled: customerInfo?.yourItSystemsEnabled,
			deviceManagementEnabled: customerInfo?.deviceManagementEnabled,
			hasKomplettRelationship: customerInfo?.hasKomplettRelationship,
			deprecatedPeopleServiceEnabled: customerInfo?.deprecatedPeopleServiceEnabled,
		});

		setAllowedPagesForCustomer(customerHasAccessToPages);
		setAllowedPagesForRole(roleHasAccessToPages);
	}, [
		roles,
		customerInfo?.deprecatedPeopleServiceEnabled,
		customerInfo?.yourEmployeesEnabled,
		customerInfo?.yourItSystemsEnabled,
		customerInfo?.hasKomplettRelationship,
		customerInfo?.deviceManagementEnabled,
	]);

	return { allowedPagesForRole, allowedPagesForCustomer };
};

export const useActionOnScreenTreshold = (
	callback: (aboveTreshold: boolean) => void,
	threshold: string | number,
) => {
	useEffect(() => {
		const thresholdNumber =
			typeof threshold === "string" ? Number(threshold.replace(/\D/g, "")) : threshold;
		const isScreenBelowTreshold = () => window.innerWidth > thresholdNumber;
		const handleResize = () => callback(isScreenBelowTreshold());
		callback(isScreenBelowTreshold());
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [callback, threshold]);
};

// Reuse this when fetching license prices. It's a hook due to the fact that there's some setup cost in the form of code overhead (as seen below)
export const useFetchLicensePrices = () => {
	const { auth, dispatch } = useAuth();
	const { ...customer } = useAppSelector(selectCustomerDetails);
	const { isLoading, isFetching } = useAppSelector(selectLicensePrices);

	useEffect(() => {
		if (!isLoading || isFetching) return;
		dispatch(fetchLicensePrices({ auth }));
	}, [dispatch, auth, customer.location, isFetching, isLoading]);
};

export const useFetchAllSubscriptionsOnLicense = (skuId: string) => {
	const { auth, dispatch } = useAuth();
	const licenseSubscriptions = useAppSelector(licenseSubscriptionsSelectors.selectEntities);
	const ids = licenseSubscriptions[skuId]?.variants.map((variant) => variant.subscriptionId);

	useEffect(() => {
		ids?.forEach((subscriptionId) => {
			if (!subscriptionId) return;
			dispatch(
				fetchLicenseSubscription({
					auth,
					subscriptionId,
					skuId,
					headers: { "Cache-Control": "no-cache" },
				}),
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, auth, skuId]);
};

export const useApiWithAndWithoutCache = (action: any, { isLoading, isFetching }: LoadingProps) => {
	const { auth, dispatch } = useAuth();

	return useEffect(() => {
		if (!isLoading || isFetching) return;

		dispatch(action({ auth })).then(() =>
			dispatch(action({ auth, headers: { "Cache-Control": "no-cache" } })),
		);
	}, [dispatch, action, auth, isLoading, isFetching]);
};

export const useEnvironment = () => {
	const isDemo = window.location.origin.includes("demo.ironstoneit");
	const isTest = window.location.origin.includes("test.ironstoneit");
	return { isDemo, isTest };
};

export const useEntitlement = () => {
	const customerInfo = useAppSelector(selectCustomerDetails);
	const {
		auth: { account: { homeAccountId } = {}, authConfig },
	} = useAuth();
	const homeAccountUserId = homeAccountId?.split(".")[0];

	useApiOnceWithParams(
		fetchAvailableEntitlements,
		useAppSelector(selectAvailableEntitlementState),
		{
			id: homeAccountUserId,
		},
	);

	const availableEntitlementsTenantIds = useAppSelector(selectAllAvailableEntitlementsTenantIds);

	// Currently, only tenants with Ironstone in the department name should see the entitlements
	const isIronstoneTenant = customerInfo.departmentName?.includes("Ironstone");

	// If currently entitled to another view, this would be false
	const accountIsCurrentlyInHomeTenant = homeAccountId?.split(".")[1] === authConfig.tenantId;

	return {
		showEntitlementSystem: isIronstoneTenant && availableEntitlementsTenantIds.length > 0,
		isHomeTenant: accountIsCurrentlyInHomeTenant,
	};
};

// Checks if tenant is allowed to access our API's
export const useTenantIsAllowed = () => {
	const accessState = useAppSelector(selectAccess);
	useApiOnce(checkAccess, accessState);
	const tenantHasAccess = accessState.isLoading || accessState.hasAccess;

	return { tenantHasAccess };
};
