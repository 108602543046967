import {
	Grid,
	Typography,
	Table,
	TableContainer,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
} from "@mui/material";
import { AutoCompleteWithFetch } from "components/Common/AutocompleteWithFetch";

import styles from "./AddToAccessGroup.module.scss";
import { useAppSelector } from "hooks/hooks";
import { AccessGroup } from "types";
import { groupSelectors } from "features/groups";

type EmployeeData = {
	addUserToAccessGroups: AccessGroup[];
};

interface AddToAccessGroupInputProps {
	newEmployeeData: EmployeeData;
	updateEmployeeData: Function;
}

const AddToAccessGroup = ({ newEmployeeData, updateEmployeeData }: AddToAccessGroupInputProps) => {
	const groups = useAppSelector(groupSelectors.selectAll);

	const getData = (searchValue: string) => {
		let withoutLicenseGroups = groups.filter((group) => group.groupType !== "License");

		if (searchValue) {
			withoutLicenseGroups = withoutLicenseGroups.filter((group) => {
				return group.displayName.toLowerCase().includes(searchValue.toLowerCase());
			});
		}

		return withoutLicenseGroups;
	};
	const handleUpdateValues = (value: AccessGroup) => {
		// Clear values if an empty array is passed as "value"
		if (!value?.id) {
			updateEmployeeData({
				addUserToAccessGroups: [],
			});
			return;
		}
		const selectedValues = newEmployeeData.addUserToAccessGroups;
		const itemAlreadyAdded = selectedValues.some((item) => item.id === value.id);
		let updatedAccessGroups: AccessGroup[] = [];

		// add item
		if (!itemAlreadyAdded) {
			updatedAccessGroups = [...selectedValues, value];
		}

		// remove item
		if (itemAlreadyAdded) {
			updatedAccessGroups = selectedValues.filter((item) => item.id !== value.id);
		}

		updateEmployeeData({
			addUserToAccessGroups: updatedAccessGroups,
		});
	};

	return (
		<Grid container spacing={3}>
			<Grid container item xs={12} spacing={3}>
				<Grid item xs={12}>
					<Typography variant="body2">
						<b>Add access group</b>
						<Typography ml={1} variant="caption">
							(Optional)
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<AutoCompleteWithFetch
						multiple={true}
						getData={getData}
						selectedValues={newEmployeeData.addUserToAccessGroups}
						propertyToDisplay="prettyDisplayName"
						propertyToDisplayEnd="memberCount"
						endPropertyExplanation="Users: "
						propertyToIdentify="id"
						propertyToGroupBy="groupType"
						handleSetValues={handleUpdateValues}
						placeholder="Select one or more access groups"
						propertyShownOnHover="description"
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TableContainer className={styles.tableContainer}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell className={styles.otherCell}>
									Access groups added to user
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{newEmployeeData.addUserToAccessGroups.map((group, key) => (
								<TableRow key={key}>
									<TableCell>{group.prettyDisplayName}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

export { AddToAccessGroup };
