import {
	reprocessLicenseAssignment,
	adjustLicenseSubscriptionQuantities,
} from "actions/licenseActions";
import _ from "lodash";
import { Auth, SubscriptionVariant } from "types";

interface adjustLicenseSubscriptionQuantitiesProps {
	auth: Auth;
	user: { id: string };
	dispatch: any;
	subscriptionVariants: SubscriptionVariant[];
}

export const adjustLicenseQuantity = async ({
	dispatch,
	user: { id },
	subscriptionVariants,
	auth,
}: adjustLicenseSubscriptionQuantitiesProps) => {
	const uniqueSubscriptionsRequiringProvisioning = _.uniqBy(
		subscriptionVariants,
		"subscriptionId",
	);
	const mapped = uniqueSubscriptionsRequiringProvisioning.map((subscriptionVariant) => {
		return {
			quantity: subscriptionVariant.quantity + 1, // Add one extra quantity
			subscriptionId: subscriptionVariant.subscriptionId,
		};
	});

	const adjustQuantityResponse = await dispatch(
		adjustLicenseSubscriptionQuantities({
			auth,
			body: mapped,
			subscriptionVariants: uniqueSubscriptionsRequiringProvisioning,
		}),
	);

	if (adjustQuantityResponse?.error) {
		return { success: false, groups: [] };
	}

	const reprocessResponse = await dispatch(reprocessLicenseAssignment({ auth, id, body: {} }));

	if (!reprocessResponse?.payload?.reprocessedUserName) {
		return { success: false, groups: [] };
	}

	return { success: true, groups: adjustQuantityResponse.payload };
};
