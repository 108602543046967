import { useAppSelector, useAuth } from "./hooks";
import { usersSelectors } from "features/users";
import { User } from "types";

/* This hook is used to get data from the current active user 
// @param string: keyof User
// @returns: any
*/
export const useUser = (): User => {
	const {
		auth: { account },
	} = useAuth();

	const user = useAppSelector((state) =>
		usersSelectors.selectById(state, account?.idTokenClaims?.oid ?? ""),
	);

	return user ?? ({} as User);
};
