import clsx from "clsx";
import { useAuth, useEnvironment } from "hooks/hooks";

import styles from "./EnvironmentBanner.module.scss";

export const EnvironmentBanner = () => {
	const {
		auth: { account },
	} = useAuth();
	const { isDemo, isTest } = useEnvironment();
	const isDemoAccount = account?.username.includes("ironstonedemo") || true;

	const showDemoBanner = isDemo && isDemoAccount;
	const showTestBanner = isTest;

	return (
		<div
			className={clsx({
				[styles.demoBanner]: showDemoBanner,
				[styles.testBanner]: showTestBanner,
				[styles.hide]: !showDemoBanner && !showTestBanner,
			})}
		>
			<div className={styles.text}>
				{showDemoBanner ? "DEMO" : showTestBanner ? "TEST - NO EXTERNAL USE" : ""}
			</div>
		</div>
	);
};
