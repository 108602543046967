

// Will be rewritten on build
const GLOBAL_ENV_TYPE = "t";
const SCOPE_ENVIRONMENT = "test";
const APP_ID = "d03141fc-8be8-4a41-9357-4a96de5912fb";
const ADMIN_CONSENT_URL = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=d03141fc-8be8-4a41-9357-4a96de5912fb&scope=.default openid profile offline_access&redirect_uri=https%3A%2F%2Ftest.ironstoneit.com%2Fdashboard&response_mode=fragment&response_type=code&prompt=consent";

class AuthConfig {
	#ADMIN_CONSENT_URL;
	#ENVIRONMENT_TYPE;
	#DOMAIN;
	#DATA_LAKE_API_ENDPOINTS_TOP_URL;
	#BILLING_API_ENDPOINTS_TOP_URL;
	#MS_GRAPH_API_ENDPOINTS_TOP_URL;
	#PARTNER_CENTER_API_ENDPOINTS_TOP_URL;
	#PAGE_API_ENDPOINTS_TOP_URL;
	#FRESHSERVICE_API_ENDPOINTS_TOP_URL;
	#HARDWARE_API_ENDPOINTS_TOP_URL;
	#FUNCTION_API_ENDPOINTS_TOP_URL;
	#REPORTS_API_ENDPOINTS_TOP_URL;
	#SCHEDULER_API_ENDPOINTS_TOP_URL;
	#ACCESS_CONTROL_API_ENDPOINTS_TOP_URL;
	#OCP_SUBSCRIPTION_KEY = "09b6f55414f84e5ea524260b927b478f";

	constructor(tenantId) {
		// If you want to run locally, set URL to `http:localhost:{port number}` for the given service
		this.tenantId = tenantId;
		this.#ADMIN_CONSENT_URL = ADMIN_CONSENT_URL;
		this.#ENVIRONMENT_TYPE = GLOBAL_ENV_TYPE;
		this.#DOMAIN = `https://ist-${this.#ENVIRONMENT_TYPE}-euw-portal-apim.azure-api.net`;
		this.#DATA_LAKE_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/datalake/v1`;
		this.#BILLING_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/billing/v1`;
		this.#MS_GRAPH_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/msgraph/v1`;
		this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/freshservice/v1`;
		this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/partnercenter/v1`;
		this.#HARDWARE_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/hardware/v1`;
		this.#SCHEDULER_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/scheduler/v1`;
		this.#FUNCTION_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/function`;
		this.#REPORTS_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/reports`;
		this.#PAGE_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/page`;
		this.#ACCESS_CONTROL_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/accesscontrol`;
	}

	get ADMIN_CONSENT_URL() {
		return this.#ADMIN_CONSENT_URL;
	}

	// Add here the endpoints for MS Graph API services you would like to use.
	get GRAPH_CONFIG() {
		return {
			GRAPH_ME_PHOTO: () => "https://graph.microsoft.com/beta/me/photos/240x240/$value",
		};
	}

	get SCHEDULER_API_ENDPOINTS() {
		return {
			LICENSE_ACTIONS: () => `${this.#SCHEDULER_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/licenseActions`,
			EXECUTE_LICENSE_ACTION: (licenseActionId) => `${this.#SCHEDULER_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/execute-scheduledlicenseaction/${licenseActionId}/execute`,
			EXECUTE_ALL_LICENSE_ACTIONS: () => `${this.#SCHEDULER_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/execute-scheduledlicenseactions`,
		};
	}



	get DATA_LAKE_API_ENDPOINTS() {
		return {
			GET_AGGREGATED_DATA: () =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/aggregateddata`,
			GET_CUSTOMER_DATA: (folderPath) =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/customerData/${folderPath}`,
			POST_CUSTOMER_DATA: (folderPath) =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/customerData/${folderPath}?Overwrite=True`,
			GET_FOLDER_CONTENT: (folderPath) =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/folderContent/${folderPath}`,
			GET_LICENSE_NAMES: () =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/customerData/Common__MicrosoftFiles__licenseNames.json`,
		};
	}

	get BILLING_API_ENDPOINTS() {
		return {
			GET_AZURE_BILLING: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/azure`,
			GET_OFFICE365_BILLING: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/office365`,
			GET_CONSULTANCY_COSTS: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/consultancy`,
			GET_YOUR_EMPLOYEES_COSTS: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/yourEmployees`,
			GET_YOUR_IT_SYSTEMS_COSTS: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/yourItSystems`,
			GET_ALL_COSTS: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/totalCost`,
			GET_SPECIFIC_PERIOD_ALL_COSTS: () =>
				`${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/totalCost/download`,
			GET_ALL_INVOICES: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/invoices`,
			GET_MARKETPLACE_ITEMS_COST: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/marketplaceItems`,
			GET_RESERVED_INSTANCES_COST: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/reservedInstances`,
			GET_INVOICE: (invoiceId) => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/invoices/${invoiceId}`,
		};
	}

	get MS_GRAPH_API_ENDPOINTS() {
		return {
			CHECK_MANAGEMENT_ROOT_GROUP_ACCESS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId
				}/rootManagementGroupAccess`,
			SET_MANAGEMENT_ROOT_GROUP_ACCESS: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId
				}/${userId}/rootManagementGroupAccess`,
			GET_USERS: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users`,
			GET_USER: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}/info`,
			GET_USER_DEVICES: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}/devices`,
			MANAGER: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}/manager`,
			GET_MANAGER_STRUCTURE: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/managerStructure`,
			POST_USER: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/user`,
			POST_COLLABORATION_REQUEST: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/collaborationRequest`,
			POST_GUEST_INVITATION_IRONSTONE_B2B_TENANT: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/ironstoneB2B/invite`,
			UPDATE_USER: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}`,
			GET_SUBSCRIBED_SKUS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/subscribedSkus`,
			GET_GROUPS: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groups`,
			GET_LICENSE_GROUPS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/licenseGroups`,
			ADD_GROUP_MEMBER: (groupId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groups/${groupId}/members`,
			REMOVE_GROUP_MEMBER: (groupId, userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groups/${groupId}/members/${userId}/`,
			REMOVE_LICENSES_FROM_USERS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/licenses`,
			ASSIGN_LICENSES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/licenseAssignment`,
			GET_LICENSE_ASSIGNMENT_ERRORS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/licenseAssignmentErrors`,
			GET_LICENSE_ASSIGNMENT_ERRORS_FOR_USER: (id) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${id}/licenseAssignmentErrors`,
			GET_GROUP_LIFECYCLE_POLICIES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groupLifecyclePolicies`,
			SET_GROUP_LIFECYCLE_POLICIES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groupLifecyclePolicies`,
			REPROCESS_LICENSE_ASSIGNMENT: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}/reprocessLicenseAssignment`,
			GET_MAIL_DOMAINS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/mailDomains`,
			GET_DEPARTMENTS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/departments`,
			GET_COUNTRIES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/countries`,
			SEND_MAIL: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/mail`,
			SEND_SMS: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/sms`,
			GET_ONE_TIME_PASSWORD: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/otp`,
			GET_APP_ROLES_ASSIGNMENTS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/apps/${APP_ID}/appRolesAssignedTo`,
			UPDATE_APP_ROLES_ASSIGNMENTS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/apps/${APP_ID}/appRolesAssignedTo`,
			GET_SERVICE_PRINCIPAL: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/servicePrincipals`,
			DEVICES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/devices`,
			DEVICE_COMPLIANCE_REPORT: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/deviceReport`,
			DEVICE_COMPLIANCE_REPORT_STATUS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/deviceReportStatus`,
			GET_BRANDING: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/branding`,
			CREATE_ENTITLEMENT: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${userId}/entitlement`,
			GET_AVAILABLE_ENTITLEMENTS: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${userId}/entitlements`,
		};
	}

	get FRESHSERVICE_API_ENDPOINTS() {
		return {
			GET_CUSTOMER_INFO: () =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/customerInfo`,
			UPDATE_CUSTOMER_INFO: (departmentId) =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${departmentId}/customerInfo`,
			TICKETS: () => `${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/tickets`,
			POST_REQUESTER: () =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/requester`,
			UPDATE_TICKET: (ticketId) =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/tickets/${ticketId}`,
			REPLY_TO_TICKET: (ticketId) =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId
				}/tickets/${ticketId}/replies`,
			GET_SECURITY_INCIDENTS: (source) =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${source}/incidents`,
			GET_SERVICE_STATUSES: () =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/serviceStatuses`,
			GET_REQUESTERS: () =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/requesters`,
			GET_ENTITLEMENTS: () =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/entitlements`,
		};
	}

	get HARDWARE_API_ENDPOINTS() {
		return {
			GET_ORDERINFO: () =>
				`${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/orderInfo`,
			CREATE_ORDER: (userId) =>
				`${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${userId}/orders`,
			CREATE_ORDER_REQUEST: (userId) =>
				`${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${userId}/orderRequests`,
			GET_PRODUCTS: () => `${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/products`,
			GET_PRODUCTS_V2: () =>
				`${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/v2/${this.tenantId}/products`,
			CUSTOMER_LOCATIONS: () => 
				`${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/locations`,
		};
	}

	get FUNCTION_API_ENDPOINTS() {
		return {
			GET_COMPARE_APP_PERMISSIONS: () =>
				`${this.#FUNCTION_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/compareAppPermissions `,
			GET_LICENSE_RECOMMENDATIONS: () =>
				`${this.#FUNCTION_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/recommendationsFile `,
			LIST_CUSTOMER_DOCUMENTS: () => 
				`${this.#FUNCTION_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/ListConfluenceCustomerAttachments`,
			GET_CUSTOMER_DOCUMENT: (attachmentId) =>
				`${this.#FUNCTION_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/GetConfluenceCustomerAttachments/${attachmentId}`,
		}
	}

	get REPORTS_API_ENDPOINTS() {
		return {
			CREATE_AGGREGATED_DATA: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/aggregatedData/generate`,
			CREATE_AZURE_BILLINGDATA: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/azurebilling/generate`,
			CREATE_OFFICE365_BILLINGDATA: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/officebilling/generate`,
			CREATE_TIMELY_HOURS_REPORT: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/timelyhours/generate`,
			CREATE_MARKETPLACE_ITEMS_BILLINGDATA: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/marketplaceitems/generate`,
			CREATE_RESERVED_INSTANCES_BILLINGDATA: () => 
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/reservedinstances/generate`,
			CREATE_LICENSE_COST_RECOMMENDATIONS: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/licenceCostRecommendation/generate`,
		}
	}


	get PARTNER_CENTER_API_ENDPOINTS() {
		return {
			DELETE_USER: (userId) =>
				`${this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}`,
			GET_SUBSCRIPTIONS: () =>
				`${this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL}/${this.tenantId}/subscriptions`,
			GET_SUBSCRIPTION: (subscriptionId) =>
				`${this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL}/${this.tenantId}/subscriptions/${subscriptionId}`,
			ADJUST_LICENSE_SUBSCRIPTION_QUANTITY: () =>
				`${this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL}/${this.tenantId
				}/subscriptions/quantity`,
		};
	}

	get PAGE_API_ENDPOINTS_TOP_URL() {
		return {
			PAGE: () => `${this.#PAGE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/hit`,
		};
	}

	get ACCESS_CONTROL_API_ENDPOINTS() {
		return {
			CHECK_ACCESS: () =>
				`${this.#ACCESS_CONTROL_API_ENDPOINTS_TOP_URL}/${this.tenantId}/access`,
		};
	}

	get OCP_APIM_SUBSCRIPTION_KEY() {
		return this.#OCP_SUBSCRIPTION_KEY;
	}

	getMSALConfig() {
		return {
			auth: {
				authority: `https://login.microsoftonline.com/${this.tenantId ?? "common"}`,
				tenantId: this.tenantId ?? "common",
				clientId: APP_ID,
				redirectUri: "/",
			},
			cache: {
				cacheLocation: "sessionStorage", // This configures where your cache will be stored
				storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
			},
		};
	}
}

const config = {
	CLIENT_ID: APP_ID,
	APP_ID,
	AUTHORITY: `api://${SCOPE_ENVIRONMENT}.ironstoneit.com`,
	AuthConfig,
};

export default config;
