import { Grid, List } from "@mui/material";
import styles from "./HardwareList.module.scss";
import { useAppSelector } from "hooks/hooks";
import { productsSelectors } from "features/hardware/products";
import { Dispatch, SetStateAction } from "react";
import { HardwareProduct } from "types";
import { nonNullable } from "utilities/filters";
import Product from "./Product/Product";
import { AddToCartButtonProps } from "./Product/AddToCartButton";

interface Props {
	selectedProducts: string[];
	setShowCheckoutDialog: Dispatch<SetStateAction<boolean>>;
	isLoading?: boolean;
	addToCartComponent?: (props: AddToCartButtonProps) => React.ReactNode;
}

const HardwareList = ({
	selectedProducts,
	setShowCheckoutDialog,
	isLoading,
	addToCartComponent,
}: Props) => {
	const productsById = useAppSelector(productsSelectors.selectEntities);
	const cartFuntion = (sku: string) => () =>
		addToCartComponent && addToCartComponent({ sku: sku });
	return (
		<Grid container item classes={{ root: styles.allProducts }} direction="column">
			<List>
				{!isLoading ? (
					selectedProducts
						.map((sku) => productsById[sku])
						.filter(nonNullable)
						.map((product: HardwareProduct) => (
							<Product
								key={product.sku}
								{...{ product, setShowCheckoutDialog }}
								isLoading={isLoading}
								addToCartComponent={cartFuntion(product.sku)}
							/>
						))
				) : (
					<>
						<Product key="loading object 1" isLoading={isLoading} />
						<Product key="loading object 2" isLoading={isLoading} />
						<Product key="loading object 3" isLoading={isLoading} />
					</>
				)}
			</List>
		</Grid>
	);
};

export { HardwareList };
