import { UserAuthenticationMethod } from "utilities/constants/enums";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { LockClock } from "@mui/icons-material";
import { ReactComponent as MicrosoftAuthenticator } from "assets/microsoftIcons/MicrosoftAuthenticator.svg";
import { ReactComponent as WindowsHello } from "assets/microsoftIcons/WindowsHello.svg";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";

export const UserAuthenticationMethodConstants: {
	[key in UserAuthenticationMethod]: {
		icon: React.ReactElement;
		displayName: string;
		description: string;
	};
} = {
	[UserAuthenticationMethod.Email]: {
		icon: <EmailIcon color="primary" />,
		displayName: "Email",
		description: "Authenticate via email by receiving a verification code or link.",
	},
	[UserAuthenticationMethod.MobilePhone]: {
		icon: <PhoneAndroidIcon color="primary" />,
		displayName: "Mobile Phone",
		description: "Authenticate using SMS codes or calls to your mobile phone.",
	},
	[UserAuthenticationMethod.OfficePhone]: {
		icon: <PhoneAndroidIcon color="primary" />,
		displayName: "Office Phone",
		description: "Authenticate via your office phone, typically through a call.",
	},
	[UserAuthenticationMethod.AppNotification]: {
		icon: <MicrosoftAuthenticator />,
		displayName: "App Notification",
		description: "Authenticate by approving notifications sent to your app.",
	},
	[UserAuthenticationMethod.AppCode]: {
		icon: <MicrosoftAuthenticator />,
		displayName: "App Code",
		description: "Authenticate using codes generated by an authenticator app.",
	},
	[UserAuthenticationMethod.Fido]: {
		icon: <FingerprintIcon color="primary" />,
		displayName: "Fido",
		description: "Authenticate using FIDO-compliant hardware or biometric devices.",
	},
	[UserAuthenticationMethod.AppPassword]: {
		icon: <VpnKeyIcon color="primary" />,
		displayName: "App Password",
		description: "Use an app-specific password for older applications.",
	},
	[UserAuthenticationMethod.MicrosoftAuthenticatorPush]: {
		icon: <MicrosoftAuthenticator />,
		displayName: "Microsoft Authenticator Push",
		description:
			"Approve sign-in requests via notifications from the Microsoft Authenticator app.",
	},
	[UserAuthenticationMethod.WindowsHelloForBusiness]: {
		icon: <WindowsHello />,
		displayName: "Windows Hello For Business",
		description: "Authenticate using Windows Hello biometric data or PIN.",
	},
	[UserAuthenticationMethod.SoftwareOneTimePasscode]: {
		icon: <LockClock color="primary" />,
		displayName: "Software One Time Passcode",
		description: "Use a one-time passcode generated by a software authenticator app.",
	},
	[UserAuthenticationMethod.MicrosoftAuthenticatorPasswordless]: {
		icon: <MicrosoftAuthenticator />,
		displayName: "Microsoft Authenticator Passwordless",
		description: "Sign in without a password using Microsoft Authenticator.",
	},
	[UserAuthenticationMethod.AlternateMobilePhone]: {
		icon: <PhoneAndroidIcon color="primary" />,
		displayName: "Alternate Mobile Phone",
		description: "Authenticate using an alternate mobile phone number.",
	},
	[UserAuthenticationMethod.None]: {
		icon: <NoEncryptionGmailerrorredIcon color="error" />,
		displayName: "None",
		description:
			"No authentication method is set for this user. Inform user to set up an authentication method.",
	},
};
