import { LicenseList } from "components/Common/LicenseList";
import { selectUserDrawerState, setUserDrawerClosed } from "features/user";
import { usersSelectors } from "features/users";
import { useAppSelector, useAuth } from "hooks/hooks";

import { Grid } from "@mui/material";
import { User } from "types";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { setPreselectedUserIdForAssignment } from "features/licenses/licenses";
import { InternalLink } from "components/Common/Links";
import useRole from "utilities/roleUtils/roleCheck";

export const UserLicenseDetails = () => {
	const { activeUserId } = useAppSelector(selectUserDrawerState);
	const user =
		useAppSelector((state) => usersSelectors.selectById(state, activeUserId)) ?? ({} as User);
	const { navigateToPage } = useAppNavigate();
	const { dispatch } = useAuth();

	const handleNavigateToPage = () => {
		dispatch(setPreselectedUserIdForAssignment(activeUserId));
		navigateToPage(NAVIGATION_NAMES.ASSIGN_LICENSES.path);
		dispatch(setUserDrawerClosed());
	};

	const { isActionRole } = useRole();

	return (
		<>
			<Grid item xs={12} mt={4}>
				{isActionRole && (
					<InternalLink onClick={handleNavigateToPage} textDecoration="underline">
						Go to license assignment
					</InternalLink>
				)}
				<LicenseList
					userId={activeUserId}
					licenses={user?.licenses || []}
					readView={false}
				/>
			</Grid>
		</>
	);
};
