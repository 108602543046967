import { Grid, Typography } from "@mui/material";
import styles from "./StockIndicator.module.scss";
import CircleIcon from "@mui/icons-material/Circle";
import dayjs from "dayjs";
import { DATE_FORMATS } from "utilities/constants/enums";
import { Availability } from "types";
import clsx from "clsx";
import { SwapProduct } from "../../HardwareList/Product/SwapProduct";
interface StockIndicatorInputProps {
	availability?: Availability;
	requestedQuantity?: number;
	variant: "horizontal" | "vertical" | "absolute";
	idToSwap?: string;
}

export const StockIndicator = ({
	availability,
	requestedQuantity = 1,
	variant,
	idToSwap,
}: StockIndicatorInputProps) => {
	if (!availability) {
		// Show product as end of life if no availability
		return <EndOfLifeComponent variant={variant} />;
	}
	const stock = parseInt(availability?.quantity ?? "0");
	const enoughInStock = stock >= requestedQuantity;
	const isExpectedInStock = parseInt(availability?.incomingStock ?? "0") > 0;

	const OutOfStockComponent = (fontSize: number, marginRight: number) => (
		<Grid container alignItems={"center"} gap={1}>
			<CircleIcon
				className={clsx(styles.availabilityIcon, {
					[styles.inStock]: enoughInStock,
					[styles.orderedOnRequest]: !availability.inStock && !isExpectedInStock,
					[styles.outOfStock]: !enoughInStock,
				})}
			/>
			<Typography fontSize={fontSize} marginRight={marginRight}>
				{availability.inStock
					? `${stock} In stock`
					: isExpectedInStock
					? "Out of stock"
					: "Ordered on request"}
			</Typography>
			{idToSwap && (
				<SwapProduct
					idToSwap={idToSwap}
					count={requestedQuantity}
					availability={availability}
				/>
			)}
		</Grid>
	);

	const availableDateInFuture = dayjs(availability.availableDate).isAfter(dayjs());
	const shouldShowOutOfStock = stock === 0 && availability.availableDate && availableDateInFuture;
	const ExpectedInStockComponent = (marginLeft: number, marginTop: number, fontSize: number) => (
		<>
			<Typography fontSize={fontSize} className={styles.expectedDateText}>
				{isExpectedInStock
					? "Expected in stock"
					: `${dayjs(availability.availableDate).format("DD.MM.YYYY")}`}
			</Typography>
			<Typography
				fontSize={fontSize}
				className={styles.expectedDateText}
				marginLeft={marginLeft}
				marginTop={marginTop}
			>
				{isExpectedInStock
					? dayjs(availability.availableDate).format(DATE_FORMATS.NO_CLOCK)
					: ""}
			</Typography>
		</>
	);

	const getVariant = () => {
		if (variant === "horizontal") {
			return (
				<Grid item>
					<Grid container className={styles.stockDescriptionContainer}>
						{OutOfStockComponent(14, 0)}
					</Grid>
					<Grid container>
						{shouldShowOutOfStock && ExpectedInStockComponent(0.5, 0, 14)}
					</Grid>
				</Grid>
			);
		} else if (variant === "vertical") {
			return (
				<Grid container item>
					<Grid container className={styles.stockDescriptionContainer}>
						{OutOfStockComponent(16, 0)}
					</Grid>
					<Grid container>
						{shouldShowOutOfStock && ExpectedInStockComponent(3, 0, 16)}
					</Grid>
				</Grid>
			);
		} else {
			// "absolute"
			return (
				<Grid className={styles.absoluteContainer}>
					{OutOfStockComponent(11, 1)}
					{shouldShowOutOfStock && ExpectedInStockComponent(0.5, 0, 11)}
				</Grid>
			);
		}
	};

	return getVariant();
};

const EndOfLifeComponent = ({ variant }: { variant: string }) => {
	if (variant === "vertical") {
		return (
			<Grid container alignItems={"center"} gap={1}>
				<CircleIcon className={clsx(styles.availabilityIcon, styles.endOfLife)} />
				<Typography fontSize={16} marginRight={0}>
					No longer available
				</Typography>
			</Grid>
		);
	} else {
		return (
			<Grid container alignItems={"center"} gap={1}>
				<CircleIcon className={clsx(styles.availabilityIcon, styles.endOfLife)} />
				<Typography fontSize={11} marginRight={1}>
					Product EOL - no longer available
				</Typography>
			</Grid>
		);
	}
};
