import { Grid } from "@mui/material";
import styles from "./InfoBanner.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import clsx from "clsx";
import { useAppSelector } from "hooks/hooks";
import { selectAggregatedData } from "features/aggregatedData";

interface InfoBannerProps {
	infoText: string;
	styleBanner: string;
	styleText: string;
}

const InfoBanner = ({ infoText, styleBanner, styleText }: InfoBannerProps) => {
	return (
		<Grid container className={clsx(styles[styleBanner], styles.infoBanner)}>
			<Grid item xs={12} className={styles[styleText]}>
				<InfoIcon className={styles.icon} />
				{infoText}
			</Grid>
		</Grid>
	);
};

/* This is a banner for the security dashboard. It will only be shown if the user has not
 * purchased an Azure AD Premium license.
 * It has been placed inside this component to avoid using app selectors etc. inside the pages.tsx file.
 * */
const SecurityDashboardInfoBanner = ({ infoText, styleBanner, styleText }: InfoBannerProps) => {
	const { isLoading, isFetching, ...aggregatedData } = useAppSelector(
		selectAggregatedData,
	) as any;

	const isMissingAzureAdPremiumLicense =
		aggregatedData &&
		aggregatedData?.Check_Licenses?.CheckValues?.Licenses_AADPremiumLicenses === 0;

	return (
		<>
			{isMissingAzureAdPremiumLicense && (
				<InfoBanner infoText={infoText} styleBanner={styleBanner} styleText={styleText} />
			)}
		</>
	);
};

export { InfoBanner, SecurityDashboardInfoBanner };
