import {
	Autocomplete,
	Box,
	Divider,
	FormControl,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { dropDownClasses, TabPanel } from "./Shared";
import styles from "./RequestForm.module.scss";
import { get } from "utilities/rest";
import { useEffect, useState } from "react";
import _ from "lodash";
import { IDropDownOption } from "./RequestForm";
import { UsageLocation } from "types";

export interface IOrganisationProps {
	activeTabIndex: number;
	selectedRegion?: IDropDownOption | null;
	setSelectedRegion: Function;
	selectedDepartment?: string | null;
	setSelectedDepartment: Function;
	authToken: string;
	apiConfig: any;
}

export const Organisation = ({
	activeTabIndex,
	selectedRegion,
	setSelectedRegion,
	selectedDepartment,
	setSelectedDepartment,
	authToken,
	apiConfig,
}: IOrganisationProps) => {
	const [departments, setDepartments] = useState<Array<string>>([]);
	const [countries, setCountries] = useState<UsageLocation[]>([]);

	const getDepartments = async (apiConfig: any) => {
		let departments = ["HR", "IT", "Sales", "Marketing", "Finance"];
		let sharedDepartment = "Company Group / Global";
		const response = await get(
			apiConfig.MS_GRAPH_API_ENDPOINTS.GET_DEPARTMENTS(),
			"Bearer",
			authToken,
			true,
			"application/json",
			undefined,
			{
				"Ocp-Apim-Subscription-Key": apiConfig.OCP_APIM_SUBSCRIPTION_KEY,
			},
			null,
		);
		if (!_.isEmpty(response?.data)) {
			const departmentsFromResponse = response?.data?.map(
				(department: any) => department.name,
			);
			departments = [...departmentsFromResponse];
		} else {
			departments = [sharedDepartment, ...departments];
		}
		const sorted = departments.sort(
			(a: any, b: any) => b.numberOfEmployees - a.numberOfEmployees,
		);
		setDepartments(sorted);
	};

	const getCountries = async (apiConfig: any) => {
		// Arbitrary number of employees to put Norway and Sweden at the top of the list
		let countriesList = [
			{ id: "NO", label: "Norway", numberOfEmployees: 10000 },
			{ id: "SE", label: "Sweden", numberOfEmployees: 10000 },
		] as UsageLocation[];
		const response = await get(
			apiConfig.MS_GRAPH_API_ENDPOINTS.GET_COUNTRIES(),
			"Bearer",
			authToken,
			true,
			"application/json",
			undefined,
			{
				"Ocp-Apim-Subscription-Key": apiConfig.OCP_APIM_SUBSCRIPTION_KEY,
			},
			null,
		);
		if (!_.isEmpty(response?.data)) {
			const countriesFromResponse = response?.data?.map((country: any) => ({
				id: country.countryCode,
				label: country.countryName,
				numberOfEmployees: country.numberOfEmployees,
			})) as UsageLocation[];
			// Get unique countries, prioritise countries from response
			countriesList = [...countriesFromResponse, ...countriesList];
			countriesList = _.uniqBy(countriesList, "id");
		}
		const sorted = countriesList.sort(
			(a: any, b: any) => b.numberOfEmployees - a.numberOfEmployees,
		);
		setCountries(sorted);
	};

	useEffect(() => {
		if (apiConfig) {
			getDepartments(apiConfig);
			getCountries(apiConfig);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<TabPanel value={activeTabIndex} index={1}>
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="body2" className={styles.formRow}>
					Select which parts of the organisation this collaboration relates to...
				</Typography>
				<Typography className={styles.steps}>(step 2/4)</Typography>
			</Stack>
			<Divider variant="middle" className={styles.devider} />
			<Box>
				<FormControl>
					<Stack direction="column" className={styles.sectionRow}>
						<Typography className={styles.formLabelWrapperWide}>
							Which region will this workspace be associated to?
							<span className={styles.asterisk}>*</span>
						</Typography>
						<Autocomplete
							value={selectedRegion || null}
							onChange={(event: any, newValue: any) => {
								setSelectedRegion(newValue);
							}}
							options={countries}
							// isOptionEqualToValue={(option, value) => option.id === value.id}
							className={styles.dropDown}
							classes={dropDownClasses}
							renderInput={(params) => (
								<TextField {...params} value={selectedRegion?.label || null} />
							)}
						/>
					</Stack>
					<Stack direction="column" className={styles.sectionRow}>
						<Typography className={styles.formLabelWrapperWide}>
							Which department will own this workspace?
							<span className={styles.asterisk}>*</span>
						</Typography>
						<Autocomplete
							disablePortal
							value={selectedDepartment || null}
							onChange={(event: any, newValue: any) => {
								setSelectedDepartment(newValue);
							}}
							options={departments}
							classes={dropDownClasses}
							renderInput={(params) => (
								<TextField {...params} value={selectedDepartment || null} />
							)}
						/>
					</Stack>
				</FormControl>
			</Box>
		</TabPanel>
	);
};
