import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
	closeSecurityIncident,
	escalateSecurityIncident,
	fetchSecurityIncidents,
} from "actions/securityIncidentActions";
import { RootState } from "store";
import { SecurityIncident } from "types";
import { CUSTOMER_FACING_STATUS } from "utilities/constants/constants";

const securityIncidensAdapter = createEntityAdapter<SecurityIncident>();

const securityIncidentsSlice = createSlice({
	name: "securityIncidents",
	initialState: securityIncidensAdapter.getInitialState({ isLoading: true, isFetching: false }),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSecurityIncidents.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchSecurityIncidents.fulfilled, (state, { payload }) => {
				securityIncidensAdapter.setAll(state, payload);
				state.isFetching = false;
				state.isLoading = false;
			});
		builder.addCase(
			escalateSecurityIncident.fulfilled,
			(
				state,
				{
					meta: {
						arg: { id },
					},
				},
			) => {
				securityIncidensAdapter.updateOne(state, {
					id,
					changes: {
						customer_facing_status: CUSTOMER_FACING_STATUS.BEING_PROCESSED.id,
						status: CUSTOMER_FACING_STATUS.BEING_PROCESSED.id,
					},
				});
			},
		);
		builder.addCase(
			closeSecurityIncident.fulfilled,
			(
				state,
				{
					meta: {
						arg: { id },
					},
				},
			) => {
				securityIncidensAdapter.updateOne(state, {
					id,
					changes: {
						status: CUSTOMER_FACING_STATUS.RESOLVED.id,
						customer_facing_status: CUSTOMER_FACING_STATUS.RESOLVED.id,
					},
				});
			},
		);
	},
});

export const selectSecurityIncidents = (state: RootState) => state.securityIncidents;

export const securityIncidentSelectors =
	securityIncidensAdapter.getSelectors<RootState>(selectSecurityIncidents);

export default securityIncidentsSlice.reducer;
