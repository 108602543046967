import { Grid, Skeleton } from "@mui/material";
import { LicenseSubscriptionRecommendation } from "types";

import styles from "./LicenseRecommendationContent.module.scss";
import { LicenseRemovalCard } from "./LicenseRemovalCard";
import { CostSavingsType } from "utilities/constants/enums";
import { caculateTotalCostSavings } from "utilities/licenseUtils/costSavingsUtils";
import { TermDurationChangeCard } from "./TermDurationChangeCard";

interface LicenseRecommendationContentInputProps {
	isLoading: boolean;
	recommendation: LicenseSubscriptionRecommendation;
	handleDrawerStateChange: (open: boolean, type: CostSavingsType) => void;
}

export const LicenseRecommendationContent = ({
	isLoading,
	recommendation,
	handleDrawerStateChange,
}: LicenseRecommendationContentInputProps) => {
	const { unused: costSavingsUnused, termDuration: costSavingsTermDuration } =
		caculateTotalCostSavings(recommendation);

	return (
		<Grid container className={styles.content}>
			<Grid container className={styles.cardsContainer}>
				{!isLoading && (
					<>
						<Grid container>
							<Grid item className={styles.insightCardContainer}>
								<LicenseRemovalCard
									recommendation={recommendation}
									handleDrawerStateChange={handleDrawerStateChange}
									costSavingsUnused={costSavingsUnused}
								/>
							</Grid>
							<Grid item className={styles.insightCardContainer}>
								<TermDurationChangeCard
									recommendation={recommendation}
									handleDrawerStateChange={handleDrawerStateChange}
									costSavingsTermDuration={costSavingsTermDuration}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
			{isLoading && (
				<Grid container className={styles.cardsContainer}>
					{Array.from(Array(2).keys()).map((index) => (
						<Grid
							key={index}
							container
							className={styles.insightCardContainer}
							p={3}
							width={100}
							height={296}
						>
							<Skeleton variant="rectangular" width={"100%"} height={"100%"} />
						</Grid>
					))}
				</Grid>
			)}
		</Grid>
	);
};
