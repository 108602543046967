import { Grid, Typography } from "@mui/material";
import styles from "./ServiceStatusItem.module.scss";
import { CARD_STATUS_COLOR } from "utilities/constants/enums";

interface ServiceStatusItemProps {
	networkStatus: "Online" | "Offline";
	name: string;
}

const ServiceStatusItem = ({ networkStatus, name }: ServiceStatusItemProps): JSX.Element => {
	const colorStatus: CARD_STATUS_COLOR =
		networkStatus === "Online" ? CARD_STATUS_COLOR.GREEN : CARD_STATUS_COLOR.RED;

	return (
		<Grid container spacing={1} className={styles.serviceStatusItem}>
			<Grid item container direction="row" xs={8}>
				<Grid item>
					<Typography variant="body1" className={styles[`${colorStatus}TextStatus`]}>
						{networkStatus}
					</Typography>
					<Typography variant="caption">{name}</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export { ServiceStatusItem };
