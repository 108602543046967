import { Checkbox, FormControlLabel } from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import styles from "./Checkbox.module.scss";

interface CheckboxInputProps {
	label: React.ReactNode;
	checked: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	size?: "small" | "medium";
	name?: string;
	isDisabled?: boolean;
}

export const PrimaryCheckbox = ({
	label,
	checked,
	onChange,
	size,
	name,
	isDisabled = false,
}: CheckboxInputProps) => {
	return (
		<FormControlLabel
			label={label}
			control={
				<Checkbox
					icon={<CheckBoxOutlineBlankIcon className={styles.checkbox} />}
					checkedIcon={<CheckBoxIcon className={styles.checkboxChecked} />}
					checked={checked}
					onChange={onChange}
					size={size || "medium"}
					name={name}
					disabled={isDisabled}
				/>
			}
		/>
	);
};
