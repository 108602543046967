import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";
import { Grid } from "@mui/material";
import { HoverTooltip } from "components/Common/Tooltips";
import { UserAuthenticationMethod } from "utilities/constants/enums";

import styles from "./AuthenticationMethods.module.scss";
import { UserAuthenticationMethodConstants } from "components/MyPeople/MyPeopleTable/AuthenticationMethods/authenticationMethodsHelper";

interface AuthenticationMethodsProps {
	authenticationMethods: UserAuthenticationMethod[];
}

export const AuthenticationMethods = ({ authenticationMethods }: AuthenticationMethodsProps) => {
	if (!authenticationMethods || authenticationMethods.length === 0) {
		return (
			<HoverTooltip
				title={UserAuthenticationMethodConstants.None.displayName}
				description={UserAuthenticationMethodConstants.None.description}
			>
				<NoEncryptionGmailerrorredIcon color="error" />
			</HoverTooltip>
		);
	}

	return (
		<Grid container className={styles.authenticationMethods}>
			{authenticationMethods.map((method, index) => {
				const methodDetail = UserAuthenticationMethodConstants[method];

				if (!methodDetail) {
					return null;
				}

				const { icon, displayName, description } = methodDetail;

				return (
					<HoverTooltip key={index} title={displayName} description={description}>
						{icon}
					</HoverTooltip>
				);
			})}
		</Grid>
	);
};
