interface handleLogoutProps {
	account: any;
	instance: any;
}

const handleLogout = ({ account, instance }: handleLogoutProps) => {
	instance.logoutRedirect();
};

export { handleLogout };
