import { Drawer, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./PortalRolesDrawer.module.scss";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { Role } from "utilities/constants/enums";
import { isActionRole } from "utilities/roleUtils/roleCheck";
import { NavigationName } from "types";

interface PortalRolesDrawerProps {
	roleValue: string;
	roleDisplayName: string;
	activeRoleValue: string | null;
	setActiveRoleValue: (value: string | null) => void;
}

export const PortalRolesDrawer = ({
	roleValue,
	roleDisplayName,
	activeRoleValue,
	setActiveRoleValue,
}: PortalRolesDrawerProps) => {
	const { default: defaultAccess, roleSpecific: roleSpecificAccess } = Object.values(
		NAVIGATION_NAMES,
	).reduce(
		(acc, nav) => {
			if (
				nav.description === undefined // Navigation names with no description => mostly subroutes + root/wildcard/logout etc.
			) {
				return acc;
			}
			if (nav.accessibleByRoles.includes(Role.USER as Role)) {
				acc.default.push(nav);
				return acc;
			}
			if (nav.accessibleByRoles.includes(roleValue.toLowerCase() as Role)) {
				acc.roleSpecific.push(nav);
				return acc;
			}
			return acc;
		},
		{ default: [], roleSpecific: [] } as {
			default: NavigationName[];
			roleSpecific: NavigationName[];
		},
	);

	return (
		<Drawer
			anchor="right"
			onClose={() => setActiveRoleValue(null)}
			classes={{ paper: styles.drawer }}
			open={activeRoleValue === roleValue}
		>
			<Grid className={styles.drawerTitle}>
				<Typography variant="h3" fontWeight={500}>
					{roleDisplayName} access
				</Typography>
				<IconButton className={styles.closeButton} onClick={() => setActiveRoleValue(null)}>
					<CloseIcon />
				</IconButton>
			</Grid>
			<Grid container item className={styles.drawerContent}>
				<Grid item>
					<Typography variant="h3" fontWeight={500} mb={2}>
						{`Access for ${roleDisplayName.toLocaleLowerCase()} role`}
					</Typography>
					{roleSpecificAccess.map((nav) => (
						<PortalAccessItem
							key={nav.value}
							label={nav.label}
							prefixIcon={nav.prefixIcon}
							description={
								nav.description ? nav.description(isActionRole([roleValue])) : ""
							}
						/>
					))}
					{defaultAccess.map((nav) => (
						<PortalAccessItem
							key={nav.value}
							label={nav.label}
							prefixIcon={nav.prefixIcon}
							description={
								nav.description ? nav.description(isActionRole([roleValue])) : ""
							}
						/>
					))}
				</Grid>
			</Grid>
		</Drawer>
	);
};

interface PortalAccessItemProps {
	label: string;
	description: string;
	prefixIcon?: React.ReactNode;
}

const PortalAccessItem = ({ label, description, prefixIcon }: PortalAccessItemProps) => {
	return (
		<Grid container className={styles.accessSection}>
			{prefixIcon}
			<Grid item className={styles.labelContainer}>
				<Typography variant="body1">{label}</Typography>
			</Grid>
			<Grid item className={styles.descriptionContainer}>
				<Typography variant="description" color="text.secondary">
					{description}
				</Typography>
			</Grid>
		</Grid>
	);
};
