import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import {
	fetchAllCosts,
	fetchOffice365Billing,
	fetchConsultancyCost,
	fetchYourEmployeesCost,
	fetchYourITSystemsCost,
	fetchMarketplaceItemsCost,
	fetchReservedInstancesCost,
	fetchAzureBilling,
	fetchAllCostsFullDetails,
} from "actions/costActions";
import { RootState } from "store";
import { AZURE_BILLING_DATA } from "data/mockResponses";
import {
	AzureCost,
	ConsultancyCost,
	MarketplaceItemsCost,
	Office365Cost,
	ReservedInstancesCost,
	CostOverviewTotals,
	YourEmployeesCost,
	YourITSystemsCost,
	AllCostTypesShortApiObject,
	Anomalies,
	TotalCost,
} from "types";
import _ from "lodash";
import dayjs from "dayjs";

const azureCostAdapter = createEntityAdapter<AzureCost>({
	selectId: ({ period }) => period,
});

const office365CostAdapter = createEntityAdapter<Office365Cost>({
	selectId: ({ period }) => period,
});

const yourEmployeesCostAdapter = createEntityAdapter<YourEmployeesCost>({
	selectId: ({ period }) => period,
});

const yourITSystemsCostAdapter = createEntityAdapter<YourITSystemsCost>({
	selectId: ({ period }) => period,
});

const marketplaceItemsAdapter = createEntityAdapter<MarketplaceItemsCost>({
	selectId: ({ period }) => period,
});

const reservedInstancesAdapter = createEntityAdapter<ReservedInstancesCost>({
	selectId: ({ period }) => period,
});

const consultancyAdapter = createEntityAdapter<ConsultancyCost>({
	selectId: ({ period }) => period,
});

interface Loading {
	isLoading: boolean;
	isFetching: boolean;
}

const initialLoading = {
	isLoading: true,
	isFetching: false,
} as Loading;

// The cost slice consists of "totalCost" which has short information about all cost types
// This data is fetched from an aggregated cost file in the data lake.
// The separate cost types are fetched from the billing API, which aggregates cost data on the fly.
// This is done due to the fact that it is extremely inefficient to fetch all cost types for all periods
// every time a user hits the cost overview page. (Especially if the customer has a large azure environment)
const costsSlice = createSlice({
	name: "costs",
	initialState: {
		totalCost: {
			...initialLoading,
		} as CostOverviewTotals & Loading,
		invoice: { invoiceData: {}, ...initialLoading },
		anomalies: { anomaliesData: {} as Anomalies, ...initialLoading },
		azure: azureCostAdapter.getInitialState(initialLoading),
		office365: office365CostAdapter.getInitialState(initialLoading),
		yourEmployees: yourEmployeesCostAdapter.getInitialState(initialLoading),
		yourITSystems: yourITSystemsCostAdapter.getInitialState(initialLoading),
		marketplaceItems: marketplaceItemsAdapter.getInitialState(initialLoading),
		reservedInstances: reservedInstancesAdapter.getInitialState(initialLoading),
		consultancy: consultancyAdapter.getInitialState(initialLoading),
		selectedCostView: "COST OVERVIEW",
	},
	reducers: {
		setSelectedCostView(state, { payload }) {
			state.selectedCostView = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAzureBilling.pending, (state) => {
				state.azure.isFetching = true;
			})
			.addCase(fetchAzureBilling.fulfilled, (state, { payload }) => {
				azureCostAdapter.addMany(state.azure, payload);
				state.azure.isFetching = false;
				state.azure.isLoading = false;
			});
		builder
			.addCase(fetchOffice365Billing.pending, (state) => {
				state.office365.isFetching = true;
			})
			.addCase(fetchOffice365Billing.fulfilled, (state, { payload }) => {
				office365CostAdapter.addMany(state.office365, payload);
				state.office365.isFetching = false;
				state.office365.isLoading = false;
			});
		builder
			.addCase(fetchConsultancyCost.pending, (state) => {
				state.consultancy.isFetching = true;
			})
			.addCase(fetchConsultancyCost.fulfilled, (state, { payload }) => {
				consultancyAdapter.addMany(state.consultancy, payload);
				state.consultancy.isLoading = false;
				state.consultancy.isFetching = false;
			});
		builder
			.addCase(fetchYourEmployeesCost.pending, (state) => {
				state.yourEmployees.isFetching = true;
			})
			.addCase(fetchYourEmployeesCost.fulfilled, (state, { payload }) => {
				yourEmployeesCostAdapter.addMany(state.yourEmployees, payload);
				state.yourEmployees.isLoading = false;
				state.yourEmployees.isFetching = false;
			});
		builder
			.addCase(fetchYourITSystemsCost.pending, (state) => {
				state.yourITSystems.isFetching = true;
			})
			.addCase(fetchYourITSystemsCost.fulfilled, (state, { payload }) => {
				yourITSystemsCostAdapter.addMany(state.yourITSystems, payload);
				state.yourITSystems.isLoading = false;
				state.yourITSystems.isFetching = false;
			});
		builder
			.addCase(fetchMarketplaceItemsCost.pending, (state) => {
				state.marketplaceItems.isFetching = true;
			})
			.addCase(fetchMarketplaceItemsCost.fulfilled, (state, { payload }) => {
				marketplaceItemsAdapter.addMany(state.marketplaceItems, payload);
				state.marketplaceItems.isLoading = false;
				state.marketplaceItems.isFetching = false;
			});
		builder
			.addCase(fetchReservedInstancesCost.pending, (state) => {
				state.reservedInstances.isFetching = true;
			})
			.addCase(fetchReservedInstancesCost.fulfilled, (state, { payload }) => {
				reservedInstancesAdapter.addMany(state.reservedInstances, payload);
				state.reservedInstances.isLoading = false;
				state.reservedInstances.isFetching = false;
			});
		builder
			.addCase(fetchAllCosts.pending, (state) => {
				state.totalCost.isFetching = true;
			})
			.addCase(fetchAllCosts.fulfilled, (state, { payload }) => {
				const mapped = mapToAllCostTypesShort(payload as AllCostTypesShortApiObject);
				const anomalies = payload.Azure.Anomalies;
				state.anomalies = {
					anomaliesData: anomalies,
					isFetching: false,
					isLoading: false,
				};
				state.totalCost = {
					...(mapped as CostOverviewTotals),
					isFetching: false,
					isLoading: false,
				};
			});
		builder
			.addCase(fetchAllCostsFullDetails.pending, (state, { payload }) => {
				state.azure.isLoading = true;
				state.reservedInstances.isLoading = true;
				state.marketplaceItems.isLoading = true;
				state.office365.isLoading = true;
				state.yourEmployees.isLoading = true;
				state.yourITSystems.isLoading = true;
				state.consultancy.isLoading = true;
			})
			.addCase(fetchAllCostsFullDetails.fulfilled, (state, { payload }) => {
				const {
					azure,
					reservedInstances,
					marketplaceItems,
					office365,
					yourEmployees,
					yourItSystems,
					consultancy,
				} = payload as TotalCost;

				azureCostAdapter.addMany(state.azure, azure ?? []);
				reservedInstancesAdapter.addMany(state.reservedInstances, reservedInstances ?? []);
				marketplaceItemsAdapter.addMany(state.marketplaceItems, marketplaceItems ?? []);
				office365CostAdapter.addMany(state.office365, office365 ?? []);
				yourEmployeesCostAdapter.addMany(state.yourEmployees, yourEmployees ?? []);
				yourITSystemsCostAdapter.addMany(state.yourITSystems, yourItSystems ?? []);
				consultancyAdapter.addMany(state.consultancy, consultancy ?? []);

				state.azure.isLoading = false;
				state.reservedInstances.isLoading = false;
				state.marketplaceItems.isLoading = false;
				state.office365.isLoading = false;
				state.yourEmployees.isLoading = false;
				state.yourITSystems.isLoading = false;
				state.consultancy.isLoading = false;
			});
	},
});

const currentMonthWithYear = dayjs().format("MMMM YYYY");
const previousMonthWithyear = dayjs().subtract(1, "months").format("MMMM YYYY");

export const selectCurrentAzureCost = (state: RootState) => {
	const { isLoading, entities } = selectAllAzureCosts(state);
	if (isLoading) return { billingDataCurrent: AZURE_BILLING_DATA, isLoading };

	const billingDataCurrent = entities[currentMonthWithYear];
	const billingDataLastMonth = entities[previousMonthWithyear];

	const addRestOfMonthData = (name: string, id: string) => {
		const previousMonthEntityById = _.keyBy(_.get(billingDataLastMonth, name), id);
		return _.get(billingDataCurrent, name, []).map((item: any) => {
			return {
				...item,
				restOfMonthForecast: item.monthlyForecast
					? Math.max(item.monthlyForecast - item.totalPreTax, 0)
					: undefined,
				restOfLastMonthTotal: Math.max(
					previousMonthEntityById[_.get(item, id, "")]?.totalPreTax -
						item.lastMonthTotalPreTax,
					0,
				),
			};
		});
	};

	return {
		billingDataCurrent: {
			...billingDataCurrent,
			subscriptions: addRestOfMonthData("subscriptions", "subscriptionName"),
			resourceGroups: addRestOfMonthData("resourceGroups", "resourceGroupName"),
			resources: addRestOfMonthData("resources", "resourceName"),
			tags: addRestOfMonthData("tags", "tagCombination"),
		},
		billingDataLastMonth,
		isLoading,
	};
};

export const selectAnomalies = (state: RootState) => state.costs.anomalies;
export const selectAzureCost = (state: RootState) => state.costs.azure;
export const selectOffice365Cost = (state: RootState) => state.costs.office365;
export const selectConsultancyCost = (state: RootState) => state.costs.consultancy;
export const selectYourEmployeesCost = (state: RootState) => state.costs.yourEmployees;
export const selectYourITSystemsCost = (state: RootState) => state.costs.yourITSystems;

export const azureCostSelectors = azureCostAdapter.getSelectors(selectAzureCost);
export const office365Selectors = office365CostAdapter.getSelectors(selectOffice365Cost);

export const selectCurrentOffice365Cost = (state: RootState) =>
	office365Selectors.selectById(state, currentMonthWithYear);

export const selectLastOffice365Cost = (state: RootState) =>
	office365Selectors.selectById(state, previousMonthWithyear);

export const selectShowOffice365Discount = createSelector(
	selectCurrentOffice365Cost,
	(currentCost) => Number(currentCost?.totalDiscountAmount ?? 0) > 0,
);

export const yourEmployeesSelectors =
	yourEmployeesCostAdapter.getSelectors(selectYourEmployeesCost);
export const selectCurrentYourEmployeesCost = (state: RootState) =>
	yourEmployeesSelectors.selectById(state, currentMonthWithYear);
export const selectLastYourEmployeesCost = (state: RootState) =>
	yourEmployeesSelectors.selectById(state, previousMonthWithyear);

export const yourITSystemsSelectors =
	yourITSystemsCostAdapter.getSelectors(selectYourITSystemsCost);
export const selectCurrentYourITSystemsCost = (state: RootState) =>
	yourITSystemsSelectors.selectById(state, currentMonthWithYear);
export const selectLastYourITSystemsCost = (state: RootState) =>
	yourITSystemsSelectors.selectById(state, previousMonthWithyear);

export const selectMarketplaceItemsCost = (state: RootState) => state.costs.marketplaceItems;
const marketplaceItemsCostSelectors = marketplaceItemsAdapter.getSelectors(
	selectMarketplaceItemsCost,
);
export const selectCurrentMarketplaceItemsCost = (state: RootState) =>
	marketplaceItemsCostSelectors.selectById(state, dayjs().format("MMMM YYYY"));

export const selectReservedInstancesCost = (state: RootState) => state.costs.reservedInstances;
const reservedInstancesCostSelectors = reservedInstancesAdapter.getSelectors(
	selectReservedInstancesCost,
);
export const selectCurrentReservedInstancesCost = (state: RootState) =>
	reservedInstancesCostSelectors.selectById(state, dayjs().format("MMMM YYYY"));

export const consultancySelectors = consultancyAdapter.getSelectors(selectConsultancyCost);
export const selectCurrentConsultancyCost = (state: RootState) =>
	consultancySelectors.selectById(state, currentMonthWithYear);
export const selectLastConsultancyCost = (state: RootState) =>
	consultancySelectors.selectById(state, previousMonthWithyear);

export const selectAllAzureCosts = createSelector(
	selectAzureCost,
	selectReservedInstancesCost,
	selectMarketplaceItemsCost,
	azureCostSelectors.selectAll,
	reservedInstancesCostSelectors.selectAll,
	marketplaceItemsCostSelectors.selectAll,
	(
		azureState,
		reservedInstancesState,
		marketplaceItemsState,
		azure,
		reservedInstances,
		marketplaceItems,
	) => {
		const isLoading =
			azureState.isLoading ||
			reservedInstancesState.isLoading ||
			marketplaceItemsState.isLoading;

		if (isLoading) return { isLoading, entities: {} as Record<string, AzureCost> };

		const allAzureCosts = _.cloneDeep(azure);

		// Azure costs need to include costs for reserved instances
		reservedInstances.forEach((cost, i) => {
			const exclVat = cost.totalForecasted.exclVat ?? cost.totalForecasted ?? 0;
			const periodThisIndex = allAzureCosts[i] ?? {};
			periodThisIndex.totalPreTax += exclVat;
			periodThisIndex.totalForecasted += exclVat;
			periodThisIndex.totalPreTaxDiscounted += exclVat;
			periodThisIndex.totalForecastedDiscounted += exclVat;
		});

		// Azure costs need to include costs for marketplace items
		marketplaceItems.forEach((cost, i) => {
			const exclVat = cost.totalForecasted.exclVat ?? cost.totalForecasted ?? 0;
			const periodThisIndex = allAzureCosts[i] ?? {};
			periodThisIndex.totalPreTax += exclVat;
			periodThisIndex.totalForecasted += exclVat;
			periodThisIndex.totalPreTaxDiscounted += exclVat;
			periodThisIndex.totalForecastedDiscounted += exclVat;
		});

		return { isLoading, entities: _.keyBy(allAzureCosts, "period") };
	},
	{
		memoizeOptions: {
			equalityCheck: (a, b) => a.isLoading === b.isLoading, // Do not re-render unless returned `isLoading` changes
		},
	},
);

export const selectTotalCost = (state: RootState) => state.costs.totalCost;
export const selectAllCostTypes = createSelector(
	selectTotalCost,
	({ azure, office365, consultancy, yourEmployees, yourITSystems, hardware }) => ({
		azure,
		office365,
		consultancy,
		yourEmployees,
		yourITSystems,
		hardware,
	}),
);

export const selectAllCostsForDownload = createSelector(
	selectTotalCost,
	selectAllAzureCosts,
	office365Selectors.selectAll,
	yourEmployeesSelectors.selectAll,
	yourITSystemsSelectors.selectAll,
	marketplaceItemsCostSelectors.selectAll,
	reservedInstancesCostSelectors.selectAll,
	consultancySelectors.selectAll,
	(
		totalCost,
		azure,
		office365,
		yourEmployees,
		yourItSystems,
		marketplaceItems,
		reservedInstances,
		consultancy,
	) => ({
		totalCost,
		entities: {
			azure: _.values(azure.entities),
			office365,
			yourEmployees,
			yourItSystems,
			marketplaceItems,
			reservedInstances,
			consultancy,
		} as any,
	}),
);

export const selectTotalCostObject = createSelector(selectTotalCost, (allCostTypes) =>
	getCostForPeriod(allCostTypes, currentMonthWithYear),
);

const getCostForPeriod = (costs: CostOverviewTotals & Loading, period: string) => {
	const {
		isLoading,
		totalCost,
		totalCostPreviousMonth,
		totalCurrentCostThisMonth,
		azure,
		office365,
		consultancy,
		yourEmployees,
		yourITSystems,
		hardware,
	} = costs;

	if (isLoading)
		return {
			totalCost: 0,
			totalCurrentCostThisMonth: 0,
			totalCostPreviousMonth: 0,
			azure: {
				current: 0,
				previous: 0,
			},
			office365: {
				current: 0,
				previous: 0,
			},
			consultancy: {
				current: 0,
				previous: 0,
			},
			yourEmployees: {
				current: 0,
				previous: 0,
			},
			yourITSystems: {
				current: 0,
				previous: 0,
			},
			hardware: {
				current: 0,
				previous: 0,
			},
		} as any;

	const currentAzure = azure.find((cost) => cost.period === period);
	const previousAzure = azure.find((cost) => cost.period === previousMonthWithyear);
	const currentOffice365 = office365.find((cost) => cost.period === period);
	const previousOffice365 = office365.find((cost) => cost.period === previousMonthWithyear);
	const currentConsultancy = consultancy.find((cost) => cost.period === period);
	const previousConsultancy = consultancy.find((cost) => cost.period === previousMonthWithyear);
	const currentYourEmployees = yourEmployees.find((cost) => cost.period === period);
	const previousYourEmployees = yourEmployees.find(
		(cost) => cost.period === previousMonthWithyear,
	);
	const currentYourITSystems = yourITSystems.find((cost) => cost.period === period);
	const previousYourITSystems = yourITSystems.find(
		(cost) => cost.period === previousMonthWithyear,
	);
	const currentHardware = hardware.find((cost) => cost.period === period);
	const previousHardware = hardware.find((cost) => cost.period === previousMonthWithyear);

	const totalAzureCost = currentAzure?.totalForecasted ?? 0;
	const totalAzureCostLastMonth = previousAzure?.totalForecasted ?? 0;
	const totalOffice365Cost = currentOffice365?.totalForecasted ?? 0;
	const totalOffice365CostLastMonth = previousOffice365?.totalForecasted ?? 0;
	const totalConsultancyCost = currentConsultancy?.monthly_BilledCost?.exclVat ?? 0;
	const totalConsultancyCostLastMonth = previousConsultancy?.monthly_BilledCost?.exclVat ?? 0;
	const totalYourEmployeesCost = currentYourEmployees?.totalPriceSum ?? 0;
	const totalYourEmployeesCostLastMonth = previousYourEmployees?.totalPriceSum ?? 0;
	const totalYourITSystemsCost = currentYourITSystems?.totalPriceSum ?? 0;
	const totalYourITSystemsCostLastMonth = previousYourITSystems?.totalPriceSum ?? 0;
	const hardwareCost = currentHardware?.totalPriceSum ?? 0;
	const hardwareCostLastMonth = previousHardware?.totalPriceSum ?? 0;

	return {
		totalCost,
		totalCostPreviousMonth,
		totalCurrentCostThisMonth,
		azure: {
			current: totalAzureCost,
			previous: totalAzureCostLastMonth,
		},
		office365: {
			current: totalOffice365Cost,
			previous: totalOffice365CostLastMonth,
		},
		consultancy: {
			current: totalConsultancyCost,
			previous: totalConsultancyCostLastMonth,
		},
		yourEmployees: {
			current: totalYourEmployeesCost,
			previous: totalYourEmployeesCostLastMonth,
		},
		yourITSystems: {
			current: totalYourITSystemsCost,
			previous: totalYourITSystemsCostLastMonth,
		},
		hardware: {
			current: hardwareCost,
			previous: hardwareCostLastMonth,
		},
	} as any;
};

export const selectAllCostsPreviousMonths = createSelector(selectAllCostTypes, (entities) => {
	// Removing current month from cost arrays
	return {
		azure: entities?.azure?.filter((cost) => cost.period !== currentMonthWithYear) ?? [],
		office365:
			entities?.office365?.filter((cost) => cost.period !== currentMonthWithYear) ?? [],
		consultancy:
			entities?.consultancy?.filter((cost) => cost.period !== currentMonthWithYear) ?? [],
		yourEmployees:
			entities?.yourEmployees?.filter((cost) => cost.period !== currentMonthWithYear) ?? [],
		yourITSystems:
			entities?.yourITSystems?.filter((cost) => cost.period !== currentMonthWithYear) ?? [],
		hardware: entities?.hardware?.filter((cost) => cost.period !== currentMonthWithYear) ?? [],
	};
});

export const selectSelectedCostView = (state: RootState) => state.costs.selectedCostView;
export const { setSelectedCostView } = costsSlice.actions;
export default costsSlice.reducer;

const mapToAllCostTypesShort = (allCostTypesShortApiObject: AllCostTypesShortApiObject) => {
	const {
		Azure,
		Office365: office365,
		Consultancy: consultancy,
		YourEmployees: yourEmployees,
		YourITSystems: yourITSystems,
		Hardware: hardware,
		Period: period,
		TotalCost: totalCost,
		TotalCostPreviousMonth: totalCostPreviousMonth,
		TotalCurrentCostThisMonth: totalCurrentCostThisMonth,
	} = allCostTypesShortApiObject;

	const azure = Azure.Periods;

	const totalCosts = calculateTotalCostPreviousTwelveMonths(allCostTypesShortApiObject);

	return {
		period,
		azure,
		office365,
		consultancy,
		yourEmployees,
		yourITSystems,
		hardware,
		totalCost,
		totalCostPreviousMonth,
		totalCurrentCostThisMonth,
		...totalCosts,
	} as CostOverviewTotals;
};

const calculateTotalCostPreviousTwelveMonths = (
	allCostTypesShortApiObject: AllCostTypesShortApiObject,
) => {
	// The total
	const lastTwelvePeriodsExcludingCurrent = _.range(1, 13).map((i) =>
		dayjs().subtract(i, "months").format("MMMM YYYY"),
	);
	const totalAzureCost = calculateTotalCost(
		allCostTypesShortApiObject.Azure.Periods,
		lastTwelvePeriodsExcludingCurrent,
		getForecastedCost,
	);
	const totalOffice365Cost = calculateTotalCost(
		allCostTypesShortApiObject.Office365,
		lastTwelvePeriodsExcludingCurrent,
		getForecastedCost,
	);
	const totalConsultancyCost = calculateTotalCost(
		allCostTypesShortApiObject.Consultancy,
		lastTwelvePeriodsExcludingCurrent,
		getExclVatCost,
	);
	const totalYourEmployeesCost = calculateTotalCost(
		allCostTypesShortApiObject.YourEmployees,
		lastTwelvePeriodsExcludingCurrent,
		getTotalPriceSumCost,
	);
	const totalYourITSystemsCost = calculateTotalCost(
		allCostTypesShortApiObject.YourITSystems,
		lastTwelvePeriodsExcludingCurrent,
		getTotalPriceSumCost,
	);
	const totalHardwareCost = calculateTotalCost(
		allCostTypesShortApiObject.Hardware,
		lastTwelvePeriodsExcludingCurrent,
		getTotalPriceSumCost,
	);
	return {
		totalCostPreviousTwelveMonths:
			totalAzureCost +
			totalOffice365Cost +
			totalConsultancyCost +
			totalYourEmployeesCost +
			totalYourITSystemsCost +
			totalHardwareCost,
		totalAzureCostPreviousTwelveMonths: totalAzureCost,
		totalOffice365CostPreviousTwelveMonths: totalOffice365Cost,
		totalConsultancyCostPreviousTwelveMonths: totalConsultancyCost,
		totalYourEmployeesCostPreviousTwelveMonths: totalYourEmployeesCost,
		totalYourITSystemsCostPreviousTwelveMonths: totalYourITSystemsCost,
		totalHardwareCostPreviousTwelveMonths: totalHardwareCost,
	};
};

const calculateTotalCost = (
	costArray: any[],
	periodsToCalculateFor: string[],
	costPropertyExtractor: (curr: any) => number,
) =>
	costArray.reduce((acc, curr) => {
		const excludedPeriod = !periodsToCalculateFor.includes(curr.period);
		if (excludedPeriod) return acc;
		return acc + costPropertyExtractor(curr);
	}, 0);

const getForecastedCost = (cost: any) => cost.totalForecasted ?? 0;
const getExclVatCost = (cost: any) => cost.monthly_BilledCost?.exclVat ?? 0;
const getTotalPriceSumCost = (cost: any) => cost.totalPriceSum ?? 0;
