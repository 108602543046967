import { License, SubscriptionVariant } from "types";
import {
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	Typography,
	Skeleton,
} from "@mui/material";
import clsx from "clsx";

import styles from "./TermDurationPicker.module.scss";
import dayjs from "dayjs";

interface TermDurationPickerPickerProps {
	license: License;
	isAlreadyAssigned: boolean;
	handleTermDurationChange: (variant: SubscriptionVariant) => void;
}

const TermDurationPicker = ({
	license,
	handleTermDurationChange,
}: TermDurationPickerPickerProps) => {
	const handleOnChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		variant: SubscriptionVariant,
	) => {
		handleTermDurationChange(variant);
	};

	const termDurationPretty = (termDuration: string) => {
		if (termDuration === "P1M") return "Monthly";
		if (termDuration === "P1Y") return "Annual";
		return termDuration;
	};

	const termDurationExplainer = (termDuration: string) => {
		if (termDuration === "P1M") {
			return (
				<>
					<Typography variant="body1" mt={1} display="inline" component={"span"}>
						A flexible term where you can add or remove licenses monthly. The monthly
						option will cost 20% more than an annual commitment
					</Typography>
				</>
			);
		}
		if (termDuration === "P1Y") {
			return (
				<>
					<Typography variant="body1" display="inline" component={"span"}>
						A 12 month
					</Typography>
					<Typography
						variant="body1"
						fontWeight={500}
						display="inline"
						component={"span"}
					>
						{" commitment. "}
					</Typography>
					<Typography variant="body1" component={"span"}>
						20% reduction in price compared to monthly duration. Billed monthly.
					</Typography>
				</>
			);
		}

		return "";
	};

	const getTermDurationRadioButtonsPicker = () => {
		if (!license.chosenVariant) {
			return <Skeleton variant="text" width={160} height={30} />;
		}

		const variants = filterVariantsToDisplay(license);

		// Add dummy variant to make sure the customer sees two variant options
		// Disable the other variant option
		if (variants.length === 1) {
			const chosenTermDuration = license.chosenVariant.termDuration;
			const otherTermDuration = license.chosenVariant.termDuration === "P1M" ? "P1Y" : "P1M";
			const allVariantTypesIncludingDummy = [
				...variants,
				{
					termDuration: otherTermDuration,
				} as SubscriptionVariant,
			];
			const sortedVariants = allVariantTypesIncludingDummy.sort((a, b) =>
				a.termDuration.localeCompare(b.termDuration),
			);

			return sortedVariants.map((variant: SubscriptionVariant, key) => {
				return (
					<FormControlLabel
						key={key}
						value={variant.termDuration}
						control={
							<Radio
								size="small"
								checked={
									license.chosenVariant.termDuration === variant.termDuration
								}
								disabled={variant.termDuration !== chosenTermDuration}
							/>
						}
						className={clsx({
							[styles.disabled]:
								variant.termDuration !== license.chosenVariant.termDuration,
						})}
						label={termDurationPretty(variant.termDuration)}
					/>
				);
			});
		}

		const sortedVariants = [...variants].sort((a, b) =>
			a.termDuration.localeCompare(b.termDuration),
		);

		// If there are two variants with different term durations, we want to show both of them
		return sortedVariants.map((variant: SubscriptionVariant, key) => {
			return (
				<FormControlLabel
					key={key}
					value={variant.termDuration}
					control={
						<Radio
							onChange={(e) => handleOnChange(e, variant)}
							size="small"
							checked={license.chosenVariant.termDuration === variant.termDuration}
						/>
					}
					label={termDurationPretty(variant.termDuration)}
				/>
			);
		});
	};

	return (
		<>
			<FormControl component="fieldset">
				<RadioGroup aria-label="subscription-duration" row>
					{getTermDurationRadioButtonsPicker()}
				</RadioGroup>
			</FormControl>
			{license.subscriptionVariants?.length > 1 && (
				<>
					<Typography mt={2} height={100}>
						{termDurationExplainer(license.chosenVariant?.termDuration)}
					</Typography>
				</>
			)}
		</>
	);
};

const filterVariantsToDisplay = (license: License) => {
	return license?.subscriptionVariants?.reduce(
		(acc: SubscriptionVariant[], variant: SubscriptionVariant) => {
			const existingVariantWithSameTermDuration = acc.find(
				(v) => v.termDuration === variant.termDuration,
			);
			if (!existingVariantWithSameTermDuration) {
				acc.push(variant);
				return acc;
			}

			const currIsOlderThanAcc = dayjs(variant.creationDate).isBefore(
				dayjs(existingVariantWithSameTermDuration.creationDate),
			);

			if (currIsOlderThanAcc) {
				acc = acc.filter((v) => v.termDuration !== variant.termDuration);
				acc.push(variant);
			}

			return acc;
		},
		[],
	);
};

export { TermDurationPicker };
