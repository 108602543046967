import { ReactComponent as EntraID } from "assets/microsoftIcons/EntraID.svg";
import { ReactComponent as Microsoft365 } from "assets/microsoftIcons/Microsoft365.svg";
import { ReactComponent as MicrosoftExchange } from "assets/microsoftIcons/MicrosoftExchange.svg";
import { ReactComponent as Visio } from "assets/microsoftIcons/Visio.svg";
import { ReactComponent as WindowsDefender } from "assets/microsoftIcons/WindowsDefender.svg";
import { ReactComponent as PowerBI } from "assets/microsoftIcons/PowerBI.svg";
import { ReactComponent as Teams } from "assets/microsoftIcons/Teams.svg";
import { ReactComponent as Sharepoint } from "assets/microsoftIcons/Sharepoint.svg";
import { ReactComponent as ProjectPlan } from "assets/microsoftIcons/ProjectPlan.svg";
import { ReactComponent as Azure } from "assets/microsoftIcons/Azure.svg";
import { ReactComponent as Copilot } from "assets/microsoftIcons/Copilot.svg";
import { ReactComponent as Intune } from "assets/microsoftIcons/Intune.svg";

interface GetIconProps {
	iconName: string;
}

export const getIcon = ({ iconName }: GetIconProps): JSX.Element | null => {
	if (!iconName) return null;
	const uppercased = iconName.toUpperCase();
	if (uppercased.includes("DEFENDER")) {
		return <WindowsDefender />;
	} else if (uppercased.includes("COPILOT")) {
		return <Copilot />;
	} else if (uppercased.includes("INTUNE")) {
		return <Intune />;
	} else if (uppercased.includes("MICROSOFT 365")) {
		return <Microsoft365 />;
	} else if (uppercased.includes("SHAREPOINT")) {
		return <Sharepoint />;
	} else if (uppercased.includes("AZURE AD") || uppercased.includes("ENTRA ID")) {
		return <EntraID />;
	} else if (uppercased.includes("AZURE")) {
		return <Azure />;
	} else if (uppercased.includes("EXCHANGE")) {
		return <MicrosoftExchange />;
	} else if (uppercased.includes("VISIO")) {
		return <Visio />;
	} else if (uppercased.includes("DEFENDER") || uppercased.includes("SECURITY")) {
		return <WindowsDefender />;
	} else if (uppercased.includes("POWER BI")) {
		return <PowerBI />;
	} else if (uppercased.includes("TEAMS")) {
		return <Teams />;
	} else if (uppercased.includes("PROJECT PLAN")) {
		return <ProjectPlan />;
	} else {
		return null;
	}
};
