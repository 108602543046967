import { Grid, Skeleton, Typography } from "@mui/material";

import MuiSlider from "@mui/material/Slider";
import styles from "./Slider.module.scss";
import { SyntheticEvent, useState } from "react";
import { CustomSliderThumb } from "./SliderThumb";

//export interface SliderProps extends OverridableComponent<SliderTypeMap<"span", {}>> {
export interface SliderProps {
	title: string;
	unit?: string;
	commitedValue: number[];
	setValueRange: (
		event: Event | SyntheticEvent<Element, Event>,
		value: number | number[],
	) => void;
	maxValue: number;
	minValue?: number;
	step: number;
	isLoading?: boolean;
}

const Slider = ({
	title,
	unit,
	setValueRange,
	maxValue,
	minValue = 0,
	commitedValue,
	step,
	isLoading,
	...rest
}: SliderProps) => {
	const [internalValueRange, setInternalValueRange] = useState<number[]>(commitedValue);

	const handlePriceChange = (event: Event, newValue: number | number[]) => {
		setInternalValueRange(newValue as number[]);
	};

	const ariaLabelText = (value: number) => `${value} ${unit}`;

	return (
		<Grid container>
			<Typography variant="subtitle2">
				<strong>{title}</strong>
			</Typography>
			{!isLoading ? (
				<>
					<Grid container className={styles.sliderContainer}>
						<Grid container className={styles.sliderTextContainer}>
							<Typography className={styles.sliderTextFrom} variant="description">
								{internalValueRange[0]}
							</Typography>
							<Typography variant="description">-</Typography>
							<Typography className={styles.sliderTextTo} variant="description">
								{internalValueRange[1]}
							</Typography>
						</Grid>
						<MuiSlider
							className={styles.slider}
							getAriaLabel={() => `Range ${unit}`}
							slots={{ thumb: CustomSliderThumb }}
							value={internalValueRange}
							onChange={handlePriceChange}
							valueLabelDisplay="auto"
							getAriaValueText={ariaLabelText}
							step={10}
							max={maxValue}
							min={minValue}
							onChangeCommitted={setValueRange}
							{...rest}
						/>
					</Grid>
				</>
			) : (
				<Skeleton height={60} className={styles.skeleton} />
			)}
		</Grid>
	);
};

export { Slider };
