import {
	Collapse,
	Grid,
	IconButton,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { useAppSelector, useAuth } from "hooks/hooks";
import { GroupedActions, LicenseAction } from "types";

import { LicenseActionStatus, LicenseActionTargetType } from "utilities/constants/enums";
import { Fragment, useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { ReactComponent as LicenseIcon } from "assets/misc/License.svg";

import { formatCostString } from "utilities/currency/numberFormattingUtility";
import clsx from "clsx";
import { SecondaryButton } from "components/Common/Buttons/Button";
import {
	buildCancelActionBody,
	getIconAndExplanationText,
} from "utilities/licenseUtils/licenseActionUtils";
import { updateScheduledAction } from "actions/scheduledActionActions";
import dayjs from "dayjs";
import { HoverTooltip } from "components/Common/Tooltips";

import styles from "./ScheduledActionsTable.module.scss";
import { selectAllLicenseSubscriptionVariants } from "features/licenses/licenses";
import { TruncatableTypography } from "components/Common/TruncateableTypography";
import useRole from "utilities/roleUtils/roleCheck";

interface ScheduledChangesDialogInputProps {
	isOpen: Record<string, boolean>;
	handleSetIsOpen: (groupId: string) => void;
	groupedActions: Record<string, GroupedActions>;
	isScheduledActionsLoading: boolean;
}

export const ScheduledActionsTable = ({
	isOpen,
	handleSetIsOpen,
	groupedActions,
	isScheduledActionsLoading,
}: ScheduledChangesDialogInputProps) => {
	const { auth, dispatch } = useAuth();
	const { isActionRole } = useRole();
	const allVariantsBySubId = useAppSelector(selectAllLicenseSubscriptionVariants);
	const tableHeaders = [
		{ title: "Action", width: "35%" },
		{ title: "Scheduled date", width: "15%" },
		{ title: "Scheduled by", width: "25%" },
		{ title: "Status", width: "10%" },
		{ title: "", width: "20%" },
	];
	const [isCancellingAction, setIsCancellingAction] = useState(
		{} as {
			[key: string]: boolean;
		},
	);

	const handleCancelActions = async (licenseActions: LicenseAction[], cancellationId: string) => {
		// Cancellation ID is either the Action id of the first and ONLY action if its a "Single" action
		// or the GroupActionId of the first action in the group if its a "Group" action, since all actions in a group have the same GroupActionId
		setIsCancellingAction((prev) => ({
			...prev,
			...cancellableActions.reduce(
				(acc, _) => ({
					...acc,
					[cancellationId]: true,
				}),
				{},
			),
		}));

		const username = auth.account.username ?? "Could not find username";
		const cancellableActions = licenseActions.map((action) =>
			buildCancelActionBody({
				username,
				licenseAction: action,
			}),
		) as LicenseAction[];
		await dispatch(
			updateScheduledAction({
				auth,
				body: cancellableActions,
			}),
		);
		setIsCancellingAction((prev) => ({
			...prev,
			...cancellableActions.reduce(
				(acc, _) => ({
					...acc,
					[cancellationId]: false,
				}),
				{},
			),
		}));
	};

	return (
		<TableContainer className={styles.tableContainer}>
			<Table stickyHeader>
				<TableBody>
					{Object.keys(groupedActions).map((groupId, index) => {
						const actionGroup = groupedActions[groupId];

						const totalSavingsForActionGroup = actionGroup.licenseActions.reduce(
							(acc, action) => acc + action.TotalSavings,
							0,
						);
						const { numUsersRemoved, numLicensesRemoved } =
							actionGroup.licenseActions.reduce(
								(acc, action) => {
									if (action.TargetType === LicenseActionTargetType.User) {
										return {
											numUsersRemoved:
												acc.numUsersRemoved +
												Math.abs(action.QuantityChange),
											numLicensesRemoved: acc.numLicensesRemoved,
										};
									} else {
										return {
											numUsersRemoved: acc.numUsersRemoved,
											numLicensesRemoved:
												acc.numLicensesRemoved +
												Math.abs(action.QuantityChange),
										};
									}
								},
								{
									numUsersRemoved: 0,
									numLicensesRemoved: 0,
								},
							);

						const { allScheduled, allCancelled, allFinished } = getOverallGroupStatus(
							actionGroup.licenseActions,
						);

						const showSavings =
							!isNaN(totalSavingsForActionGroup) &&
							totalSavingsForActionGroup > 0 &&
							!allCancelled;

						return (
							<Fragment key={`Group-${groupId}-${index}`}>
								<TableRow>
									<TableCell
										colSpan={tableHeaders.length}
										className={styles.noBorderBottom}
									>
										<Grid
											container
											className={clsx({
												[styles.expandableRow]: true,
												[styles.borderRadiusOpen]: isOpen[groupId],
											})}
											onClick={() => handleSetIsOpen(groupId)}
										>
											<Grid
												item
												xs={3}
												className={styles.expandableRowGridItem}
											>
												<Typography>
													<IconButton
														aria-label="expand row"
														size="small"
													>
														{isOpen[groupId] ? (
															<ExpandLessIcon />
														) : (
															<ExpandMoreIcon />
														)}
													</IconButton>
												</Typography>
												<Typography fontWeight={500} ml={1}>
													{actionGroup.licenseDisplayName}
												</Typography>
											</Grid>
											<Grid
												item
												xs={2}
												className={styles.expandableRowGridItem}
											>
												{numLicensesRemoved > 0 && (
													<Grid container>
														<Grid
															className={styles.licenseIconContainer}
														>
															<LicenseIcon />
														</Grid>
														<Typography
															ml={1}
															mt={0.2}
															fontWeight={500}
														>
															{numLicensesRemoved}
														</Typography>
													</Grid>
												)}
												{numUsersRemoved > 0 && (
													<Grid container>
														<Grid className={styles.usersIconContainer}>
															{numUsersRemoved === 1 && (
																<PersonRemoveIcon
																	className={styles.icon}
																/>
															)}
															{numUsersRemoved > 1 && (
																<GroupRemoveIcon
																	className={styles.icon}
																/>
															)}
														</Grid>
														<Typography
															ml={1}
															mt={0.2}
															fontWeight={500}
														>
															{numUsersRemoved}
														</Typography>
													</Grid>
												)}
											</Grid>
											<Grid
												item
												xs={4}
												className={styles.expandableRowGridItem}
											>
												{showSavings && (
													<Typography
														className={clsx({
															[styles.costSaved]: true,
														})}
													>
														Cost saved:{" "}
														{formatCostString(
															"",
															totalSavingsForActionGroup * 12,
															",- / year",
														)}
													</Typography>
												)}
											</Grid>
											<Grid
												item
												xs={3}
												className={styles.cancelHeaderContainer}
											>
												{!allCancelled && (
													<Grid
														item
														xs={12}
														className={clsx({
															[styles.expandableRowGridItem]: true,
															[styles.cancelled]: !allScheduled,
														})}
													>
														<SecondaryButton
															action={(event) => {
																event.stopPropagation(); // prevent the row from expanding
																handleCancelActions(
																	actionGroup.licenseActions,
																	groupId,
																);
															}}
															text="Cancel actions"
															size="small"
															variantStyle="outlined"
															isLoading={isCancellingAction[groupId]}
															isDisabled={
																!allScheduled || !isActionRole
															}
														/>
													</Grid>
												)}
												{(allCancelled || allFinished) && (
													<ScheduledActionStatusLabel
														status={
															allCancelled
																? LicenseActionStatus.Cancelled
																: allFinished
																? LicenseActionStatus.Finished
																: ""
														}
														statusInfo={`All actions that have been ${
															allCancelled ? "cancelled" : "finished"
														} for ${
															actionGroup.licenseDisplayName
														} is shown here`}
													/>
												)}
											</Grid>
										</Grid>

										<Collapse in={isOpen[groupId]} timeout="auto" unmountOnExit>
											<Table>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.expandedRowHead}>
														{tableHeaders.map(
															({ title, width }, key) => {
																return (
																	<TableCell
																		key={`${title}-${key}`}
																		width={width}
																	>
																		{title}
																	</TableCell>
																);
															},
														)}
													</TableRow>
												</TableHead>
												<TableBody>
													{actionGroup.licenseActions.map(
														(licenseAction, key) => {
															const {
																ActionId,
																ExecutionDate,
																OrderedBy,
																OrderedByDisplayName,
																Status,
																StatusInfo,
															} = licenseAction;
															const {
																icon,
																actionText,
																descriptionText,
															} =
																// We don't need the descriptionText for these expanded rows, so termDuration and subscriptionEndDate can be set to empty strings
																getIconAndExplanationText(
																	licenseAction,
																	allVariantsBySubId[
																		licenseAction.TargetGUID
																	]?.termDuration,
																	"",
																);
															const currentStatusInfo =
																StatusInfo.find(
																	(info) =>
																		info.Status === Status,
																)?.Message;
															const actionIsCancellable =
																isActionRole &&
																Status ===
																	LicenseActionStatus.Scheduled;
															return (
																<TableRow
																	key={`Action-${ActionId}-${key}`}
																	className={styles.expandedRow}
																>
																	<TableCell>
																		<Grid
																			container
																			direction="row"
																			alignItems={
																				"flex-start"
																			}
																			gap={1}
																		>
																			<Grid item>{icon}</Grid>
																			<Grid item>
																				<TruncatableTypography
																					variant="body1"
																					maxCharLength={
																						30
																					}
																				>
																					{actionText}
																				</TruncatableTypography>
																				<TruncatableTypography
																					variant="description"
																					color="text.secondary"
																					maxCharLength={
																						30
																					}
																					tooltipPlacement="bottom"
																				>
																					{
																						descriptionText
																					}
																				</TruncatableTypography>
																			</Grid>
																		</Grid>
																	</TableCell>
																	<TableCell>
																		{dayjs(
																			ExecutionDate,
																		).format("DD.MM.YYYY")}
																	</TableCell>
																	<TableCell>
																		<TruncatableTypography
																			variant="description"
																			maxCharLength={40}
																		>
																			{OrderedByDisplayName ??
																				OrderedBy}
																		</TruncatableTypography>
																	</TableCell>
																	<TableCell>
																		<ScheduledActionStatusLabel
																			status={Status}
																			statusInfo={
																				currentStatusInfo ??
																				""
																			}
																		/>
																	</TableCell>
																	<TableCell>
																		<Grid
																			className={clsx({
																				[styles.expandableRowGridItem]:
																					true,
																				[styles.cancelled]:
																					!actionIsCancellable,
																			})}
																		>
																			<SecondaryButton
																				action={(event) => {
																					event.stopPropagation(); // prevent the row from expanding
																					handleCancelActions(
																						[
																							licenseAction,
																						],
																						ActionId,
																					);
																				}}
																				text="Cancel action"
																				size="small"
																				variantStyle="outlined"
																				isLoading={
																					isCancellingAction[
																						ActionId
																					]
																				}
																				isDisabled={
																					!actionIsCancellable
																				}
																			/>
																		</Grid>
																	</TableCell>
																</TableRow>
															);
														},
													)}
												</TableBody>
											</Table>
										</Collapse>
									</TableCell>
								</TableRow>
							</Fragment>
						);
					})}
					{isScheduledActionsLoading &&
						(Array.from(Array(5).keys()) as number[]).map((index) => (
							<TableRow key={`Skeleton-${index}`} className={styles.expandableRow}>
								<TableCell
									colSpan={tableHeaders.length}
									className={styles.noBorderBottom}
								>
									<Skeleton variant="rectangular" height={80} />
								</TableCell>
							</TableRow>
						))}

					{!isScheduledActionsLoading && Object.keys(groupedActions).length === 0 && (
						<TableRow>
							<TableCell
								className={clsx({
									[styles.noItemsCell]: true,
								})}
								align="center"
								colSpan={tableHeaders.length}
							>
								<Typography>{`No scheduled actions found`}</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const ScheduledActionStatusLabel = ({
	status,
	statusInfo,
}: {
	status: string;
	statusInfo: string;
}) => {
	if (!status) {
		return <></>;
	}

	const target = status.toUpperCase();

	const { style, text } = [
		{
			style: styles.cancelledLabel,
			status: LicenseActionStatus.Cancelled.toUpperCase(),
			text: "Cancelled",
		},
		{
			style: styles.scheduledLabel,
			status: LicenseActionStatus.Scheduled.toUpperCase(),
			text: "Scheduled",
		},
		{
			style: styles.errorLabel,
			status: LicenseActionStatus.Error.toUpperCase(),
			text: "Error",
		},
		{
			style: styles.finishedLabel,
			status: LicenseActionStatus.PartiallyFinished.toUpperCase(),
			text: "Partially finished",
		},
		{
			style: styles.finishedLabel,
			status: LicenseActionStatus.Finished.toUpperCase(),
			text: "Finished",
		},
		{
			style: styles.inProgressLabel,
			status: LicenseActionStatus.InProgress.toUpperCase(),
			text: "In progress",
		},
	].find(({ status }) => target.includes(status)) ?? {
		style: styles.unknownLabel,
		text: status,
	};

	if (statusInfo) {
		return (
			<HoverTooltip title={""} description={statusInfo}>
				<div className={style}>{text}</div>
			</HoverTooltip>
		);
	}

	return <span className={style}>{text}</span>;
};

const getOverallGroupStatus = (licenseActions: LicenseAction[]) => {
	const results = licenseActions.reduce(
		(acc, action) => {
			switch (action.Status) {
				case LicenseActionStatus.Scheduled:
					acc.isCancellableCount++;
					break;
				case LicenseActionStatus.Cancelled:
					acc.allCancelledCount++;
					break;
				case LicenseActionStatus.Finished:
				case LicenseActionStatus.PartiallyFinished:
					acc.allFinishedCount++;
					break;
				default:
					break;
			}
			return acc;
		},
		{
			isCancellableCount: 0,
			allCancelledCount: 0,
			allFinishedCount: 0,
		},
	);

	const allScheduled = results.isCancellableCount === licenseActions.length;
	const allCancelled = results.allCancelledCount === licenseActions.length;
	const allFinished = results.allFinishedCount === licenseActions.length;

	return {
		allScheduled,
		allCancelled,
		allFinished,
	};
};
