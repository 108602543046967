import React from "react";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { HardwarePurchaseType } from "utilities/constants/enums";
import { useAppSelector, useAuth } from "hooks/hooks";
import {
	selectHardwarePurchaseType,
	setHardwarePurchaseType,
} from "features/hardware/hardwarePage";

import styles from "./HardwarePurchaseTypeSwitch.module.scss";
import clsx from "clsx";
import { selectCustomerDetails } from "features/customer";

export const HardwarePurchaseTypeSwitch = () => {
	const { dispatch } = useAuth();

	const hardwarePurchaseType = useAppSelector(selectHardwarePurchaseType);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setHardwarePurchaseType(event.target.value));
	};

	const customerInfo = useAppSelector(selectCustomerDetails);
	if (!customerInfo.isSetupForLeasing) return null;

	return (
		<RadioGroup
			aria-label="purchase type"
			name="purchaseType"
			value={hardwarePurchaseType}
			onChange={handleChange}
			row
			className={styles.radioGroupContainer}
		>
			<FormControlLabel
				value={HardwarePurchaseType.Buy}
				control={<Radio size="small" />}
				label="Buy"
				className={clsx(styles.radio, {
					[styles.selected]: hardwarePurchaseType === HardwarePurchaseType.Buy,
				})}
			/>
			<FormControlLabel
				value={HardwarePurchaseType.Lease}
				control={<Radio size="small" />}
				label="Lease"
				className={clsx(styles.radio, {
					[styles.selected]: hardwarePurchaseType === HardwarePurchaseType.Lease,
				})}
			/>
		</RadioGroup>
	);
};
