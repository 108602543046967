import {
	ValidationRegexp,
	SubscriptionStatus,
	RiskEventTypes,
	RiskLevel,
	CollabTypes,
	ScopeTypes,
	SubHeaders,
} from "types";
import config from "authConfig";

export const PER_ONE_YEAR: Readonly<string> = "P1Y";
export const BILLED_MONTHLY: Readonly<string> = "Monthly";

export const IRONSTONE_SUPPORT_URL: Readonly<string> = "https://ironstone.freshservice.com/";
export const IRONSTONE_SUPPORT_TICKETS_URL: Readonly<string> =
	"https://ironstone.freshservice.com/support/tickets";

export const SUB_HEADER_NAMES: Readonly<SubHeaders> = {
	// Currently "My People" is the only subheader that has different values for the user and admin
	MY_PEOPLE: {
		id: "MY_PEOPLE",
		nameForUser: "Hardware",
		nameForAllOtherRoles: "Employees",
	},
};

export const SCOPES: ScopeTypes = {
	/*
	 * Scopes below are dependent on the environment
	 * Both the scope enviroment (prod | test) and the clientId's will be rewritten by transformConfig.js during build
	 */
	GRAPH_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/Graph.Read`,
	GRAPH_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/Graph.Write`,
	PARTNERCENTER_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/PartnerCenter.Read`,
	PARTNERCENTER_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/PartnerCenter.Write`,
	FRESHSERVICE_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/Freshservice.Read`,
	FRESHSERVICE_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/Freshservice.Write`,
	DATA_LAKE_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/DataLake.Read`,
	DATA_LAKE_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/DataLake.Write`,
	BILLING_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/Billing.Read`,
	COLLABORATION_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/Collaboration.Write`,
	COLLABORATION_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/Collaboration.Read`,
	HARDWARE_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/Hardware.Write`,
	HARDWARE_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/Hardware.Read`,
	FUNCTION_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/Function.Write`,
	FUNCTION_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/Function.Read`,
	REPORTS_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/Reports.Write`,
	REPORTS_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/Reports.Read`,
	PARTNER_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/Partner.Read`,
	PARTNER_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/Partner.Write`,
	SCHEDULER_READ: `${config.AUTHORITY}/${config.CLIENT_ID}/Scheduler.Read`,
	SCHEDULER_WRITE: `${config.AUTHORITY}/${config.CLIENT_ID}/Scheduler.Write`,
};

export const REGEX: Readonly<ValidationRegexp> = {
	USERNAME: /^([\p{L}\d_-])+(\s[\p{L}\d_-]+)*$/u,
	PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[^æøåÆØÅ]{8,}$/,
	SPLIT_NUMBER_BY_THOUSANDS: /\B(?=(\d{3})+(?!\d))/g,
	PHONE_NUMBER: /^\+\d+$/,
	CITY_NAME: /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
	POSTAL_CODE: /^(\d{3}\s\d{2}|\d{4,5})$/,
	MAIL_PREFIX: /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)$/,
	MAIL_COMPLETE:
		/^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/,
	INVOICE_FILE_NAME: /^\d+\.pdf$/,
};

export const SUBSCRIPTION_STATUS: Readonly<SubscriptionStatus> = {
	active: { id: "active", label: "Active" },
	suspended: { id: "paused", label: "Paused" },
	deleted: { id: "deleted", label: "Deleted" },
};

export const SEARCH_NO_MATCH: Readonly<string> = "No match";

export const DEFAULT_SORT_ORDER: RiskLevel[] = ["high", "medium", "low"];

export const RISK_EVENT_TYPES: RiskEventTypes = {
	unlikelyTravel: {
		label: "Atypical travel",
		description:
			"Identifies two sign-ins originating from geographically distant locations, where at least one of the locations may also be atypical for the user, given past behavior.",
	},
	anonymizedIPAddress: {
		label: "Anonymous IP address",
		description:
			"Indicates sign-ins from an anonymous IP address, for example, using an anonymous browser or VPN.",
	},
	maliciousIPAddress: {
		label: "Malicious IP address",
		description:
			"Indicates sign-ins from a malicious IP address. An IP address is considered malicious based on high failure rates because of invalid credentials received from the IP address or other IP reputation sources.",
	},
	unfamiliarFeatures: {
		label: "Unfamiliar sign-in properties",
		description:
			"Indicates sign-ins with characteristics that deviate from past sign-in properties.",
	},
	malwareInfectedIPAddress: {
		label: "Malware linked IP address",
		description:
			"Indicates sign-ins from IP addresses infected with malware. Deprecated and no longer generated for new detections.",
	},
	suspiciousIPAddress: {
		label: "Malicious IP address",
		description:
			"Identifies logins from IP addresses that are known to be malicious at the time of the sign in.",
	},
	leakedCredentials: {
		label: "Leaked credentials",
		description:
			"Indicates that the user's valid credentials have been leaked. This sharing is typically done by posting publicly on the dark web, paste sites, or by trading and selling the credentials on the black market. When the Microsoft leaked credentials service acquires user credentials from the dark web, paste sites, or other sources, they are checked against Azure AD users' current valid credentials to find valid matches.",
	},
	investigationsThreatIntelligence: {
		label: "Azure AD threat intelligence",
		description:
			"Indicates a sign-in activity that is unusual for the given user or is consistent with known attack patterns based on Microsoft's internal and external threat intelligence sources.",
	},
	generic: {
		label: "Additional risk detected",
		description: "Indicates that the user was not enabled for Identity Protection.",
	},
	adminConfirmedUserCompromised: {
		label: "Admin confirmed user compromised",
		description: "Indicates that an administrator has confirmed the user is compromised.",
	},
	passwordSpray: {
		label: "Password spray",
		description:
			"Indicates that multiple usernames are attacked using common passwords in a unified brute force manner to gain unauthorized access.",
	},
	anomalousToken: {
		label: "Anomalous Token",
		description:
			"Indicates that there are abnormal characteristics in the token such as an unusual token lifetime or a token that is played from an unfamiliar location.",
	},
	tokenIssuerAnomaly: {
		label: "Token Issuer Anomaly",
		description:
			"Indicates that The SAML token issuer for the associated SAML token is potentially compromised. The claims included in the token are unusual or match known attacker patterns.",
	},
	suspiciousBrowser: {
		label: "Suspicious browser",
		description:
			"Suspicious sign-in activity across multiple tenants from different countries in the same browser.",
	},
	impossibleTravel: {
		label: "Impossible travel",
		description:
			"Discovered by Microsoft Defender for Cloud Apps (MDCA). Identifies two user activities (a single or multiple sessions) originating from geographically distant locations within a time period shorter than the time it would have taken the user to travel from the first location to the second, indicating that a different user is using the same credentials.",
	},
	newCountry: {
		label: "New country",
		description:
			"This detection is discovered by Microsoft Cloud App Security (MCAS). The sign-in occurred from a location that wasn't recently or never visited by the given user.",
	},
	riskyIPAddress: {
		label: "Activity from anonymous IP address",
		description:
			"This detection is discovered by Microsoft Cloud App Security (MCAS). Users were active from an IP address that has been identified as an anonymous proxy IP address.",
	},
	mcasSuspiciousInboxManipulationRules: {
		label: "Suspicious inbox manipulation rules",
		description:
			"Discovered by Microsoft Defender for Cloud Apps (MDCA). Identifies suspicious email forwarding rules, for example, if a user created an inbox rule that forwards a copy of all emails to an external address.",
	},
	suspiciousInboxForwarding: {
		label: "Suspicious inbox forwarding",
		description:
			"This detection is discovered by Microsoft Cloud App Security (MCAS). It looks for suspicious email forwarding rules, for example, if a user created an inbox rule that forwards a copy of all emails to an external address.",
	},
	mcasImpossibleTravel: {
		label: "Impossible travel",
		description:
			"Discovered by Microsoft Defender for Cloud Apps (MDCA). Identifies two user activities (a single or multiple sessions) originating from geographically distant locations within a time period shorter than the time it would have taken the user to travel from the first location to the second, indicating that a different user is using the same credentials.",
	},
};

export const COLLABORATION_FOLDER_PATH: Readonly<string> =
	"CloudCollaboration__Configs__CollaborationConfig.json";
export const LICENSE_RECOMMENDATIONS_FOLDER_PATH: Readonly<string> =
	"CloudReports__Recommendations__LicenseSubscriptions__Latest.json";
export const AGGREGATED_COST_DATA_FOLDER_PATH: Readonly<string> =
	"CloudReports__AggregatedData__AggregatedCostData.json";

export const HARDWARE_FOLDER_PATH: Readonly<string> = "Hardware__Configs__hardwareBundles.json";
export const HARDWARE_APPROVERS_FOLDER_PATH: Readonly<string> =
	"Hardware__Configs__hardwareApprovers.json";
export const LICENSE_PRICES_FOLDER_PATH: Readonly<string> = "CustomerPriceData__licensePrices.json";

export const CUSTOMER_FACING_STATUS = {
	AWAITING_REPLY: { id: "Awaiting your reply", colorKey: "awaitingReply" },
	BEING_PROCESSED: { id: "Being processed", colorKey: "beingProcessed" },
	RESOLVED: { id: "Ticket resolved", colorKey: "resolved" },
};

export const COLLAB_TYPES: Readonly<CollabTypes> = {
	INTERNAL_COLLABORATION: {
		type: "internalCollaboration",
		displayName: "Internal Collaboration",
	},
	EXTERNAL_COLLABORATION: {
		type: "externalCollaboration",
		displayName: "External Collaboration",
	},
	INTERNAL_PROJECT: {
		type: "internalProject",
		displayName: "Internal Project",
	},
	EXTERNAL_PROJECT: {
		type: "externalProject",
		displayName: "External Project",
	},
};

export const DIRECTORY_SYNCHRONIZATION_ACCOUNT: string = "Directory Synchronization Accounts";

export const GA_TEMPLATE_ROLE_ID = "62e90394-69f5-4237-9190-012177145e10";

export const DEFAULT_ACCESS_GROUP_FILTER = {
	IT_FOR_YOUR_EMPLOYEES: ["-CWO-USER-PROD", "-CWO-PROD-USER"],
	YOUR_IT_SYSTEMS: [],
};

export const IRONSTONE_PERMISSIONS_PAGE = "https://www.ironstoneit.com/portal-permissions";

export const LICENSE_ACTION_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const HARDWARE_PAGES = {
	HARDWARE_BUNDLES: {
		path: "bundles",
		displayName: "My Bundles",
	},
	SELECT_HADRWARE: {
		path: "hardware",
		displayName: "Buy Hardware",
	},
	CHECKOUT: {
		path: "hardware-checkout",
		displayName: "Hardware Checkout",
	},
	EDIT_BUNDLE: {
		path: "edit-bundle",
		displayName: "Edit Bundle",
	},
	CREATE_BUNDLE: {
		path: "create-bundle",
		displayName: "Create Bundle",
	},
};

export const INNER_WIDTH_LARGE_ENOUGH_TO_PUSH_CONTENT = 2200;

export const DEFAULT_SELECTED_HARDWARE_GROUPS = {
	PC_NOTEBOOK_BUSINESS: false,
	PHONES_MOBILE: false,
	PC_ACCESSORIES: false,
	MICE_KEYBOARDS: false,
	SOUND: false,
	MONITOR: false,
	APPLE_PC_NOTEBOOK: false,
};

export const HARDWARE_GROUP_LABELS = {
	APPLE_PC_NOTEBOOK: "Apple laptops",
	PC_NOTEBOOK_BUSINESS: "Laptops",
	PHONES_MOBILE: "Mobile phones",
	PC_ACCESSORIES: "Accessories",
	MICE_KEYBOARDS: "Mice & keyboards",
	SOUND: "Headsets & sound",
	MONITOR: "Monitors",
};

export const DEFAULT_SELECTED_HARDWARE_MANUFACTURERS = {
	Apple: false,
	Microsoft: false,
	Lenovo: false,
	HP: false,
	Samsung: false,
	Logitech: false,
};

export const AUTOPILOT_SKU = 200625;

export const IRONSTONE_SUPPORT_MAIL = "support@ironstoneit.com";
export const DELIVERY_TIME = {
	HOME: "17:00 - 22:00",
	OFFICE: "08:00 - 17:00",
};

export const NEW_SUBSCRIPTION_INDICATOR = "NEW_SUBSCRIPTION";

export const FIVE_MINUTES = 5;

export const MUTEX_LICENSES = [
	"Microsoft 365 F1",
	"Microsoft 365 F3",
	"Microsoft 365 Business Basic",
	"Microsoft 365 Business Standard",
	"Microsoft 365 Business Premium",
	"Microsoft 365 E3",
	"Microsoft 365 E5",
];

export const TRANSPORTATION_METHODS: Record<string, string> = {
	"Postnord Norge": "Postnord",
};

export const AZURE_PORTAL_LINK = "https://portal.azure.com/";
export const MICROSOFT_ADMIN_CENTER_LINK = "https://admin.microsoft.com";
export const DEMO_REQUEST_URL = "https://ist-t-euw-portal-apim.azure-api.net/api/demo/demoRequest";

export const ROLES_CONSTANTS = {
	ADMIN: "Admin",
	PORTALREAD: "PortalRead",
	FINANCE: "Finance",
	HR: "HR",
	USER: "User",
};

export const ALL_DOCUMENT_TYPES_QUERY =
	"Agreements&pageName=Incident Reports&pageName=Operational Meetings&fileType=pdf&fileType=pptx";

export const IRONSTONE_PRODUCT_DISPLAY_NAMES = {
	YOUR_EMPLOYEES: "IT for Your Employees",
	YOUR_IT_SYSTEMS: "Your IT-Systems",
	EXTENDED_SECURITY: "Extended Security",
	EXTENDED_RESPONSE: "Extended Response",
	BACKUP: "Backup",
	DEVICE_MANAGEMENT: "Device Management",
	PRINT: "Print Where You Are",
	POS_KIOSK: "Point of Sale or Kiosk",
	FIRST_LINE_SUPPORT: "First Line Support 8 - 17",
};
