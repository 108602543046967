import clsx from "clsx";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import _ from "lodash";
import { Typography } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";

import styles from "./CostDifferenceWidget.module.scss";

interface CostDifferenceWidgetProps {
	startNumber: number;
	newNumber?: number;
	typographyVariant?: OverridableStringUnion<Variant | "inherit">;
}

const CostDifferenceWidget = ({
	startNumber,
	newNumber,
	typographyVariant = "body1",
}: CostDifferenceWidgetProps): JSX.Element => {
	const difference: number = newNumber ? newNumber - startNumber : 0; // newNumber: Final Value, i.e value this period
	const percentualChange: number = (difference / startNumber) * 100; // startNumber: Initial Value, i.e value last period
	const percentualChangeRounded: number = Math.round(percentualChange);

	// Removing the negative marker since we have an arrow telling us the trend
	const absolutePercent: number =
		_.isNaN(Math.abs(percentualChangeRounded)) || Math.abs(percentualChangeRounded) === Infinity
			? 0
			: Math.abs(percentualChangeRounded);

	return (
		<>
			{percentualChangeRounded !== 0 ? (
				<Typography
					component="span"
					variant={typographyVariant}
					classes={{
						root: clsx(styles.container, {
							[styles.increaseTrend]: difference > 0,
							[styles.decreaseTrend]: difference < 0,
							[styles.zeroTrend]: difference === 0,
						}),
					}}
					data-testid="costDifferenceWidget.container"
				>
					{percentualChangeRounded > 0 ? (
						<ArrowUpwardOutlinedIcon data-testid="costDifferenceWidget.upwardsIcon" />
					) : (
						<ArrowDownwardOutlinedIcon data-testid="costDifferenceWidget.downwardsIcon" />
					)}
					<span>{percentualChangeRounded !== 0 && `${absolutePercent}%`}</span>
				</Typography>
			) : (
				<></>
			)}
		</>
	);
};

export { CostDifferenceWidget };
