import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { LicenseAction, RemovalQuantity } from "types";
import { LicenseActionTargetType, TermDuration } from "utilities/constants/enums";

import styles from "./LicenseRemoval.module.scss";
import dayjs from "dayjs";
import { SelectableQuantity } from "components/Recommendations/LicenseRecommendation/ConfigureLicenseDrawer/LicenseDrawerContent/RemoveLicensesContent/UnassignedLicensesContent/SelectableQuantity";
import { TermDurationLabel } from "components/CostManagement/LicenseCostView/LicenseCostTable/TermDurationLabel";
import { formatCostString } from "utilities/currency/numberFormattingUtility";
import { AutorenewStatus } from "components/Common/AutorenewStatus/AutorenewStatus";
import { calculateExecutionDateForRecommendedAction } from "utilities/licenseUtils/licenseActionUtils";
import clsx from "clsx";

interface LicenseRemovalTableProps {
	availableForRemoval: number;
	scheduledLicenseActions: LicenseAction[];
	subscriptionExpirationThreshold: number;
	subscriptionsWithRemovalQuantities: Record<string, RemovalQuantity>;
	handleQuantityChange: (id: string, quantity: number) => void;
}

export const LicenseRemovalTable = ({
	availableForRemoval,
	scheduledLicenseActions,
	subscriptionExpirationThreshold,
	subscriptionsWithRemovalQuantities,
	handleQuantityChange,
}: LicenseRemovalTableProps) => {
	return (
		<TableContainer className={styles.tableContainer}>
			<Table className={styles.table}>
				<TableHead className={styles.tableHead}>
					<TableRow className={styles.tableHeadRow}>
						<TableCell>Remove quantity</TableCell>
						<TableCell>Subscription</TableCell>
						<TableCell align="right">Price per license</TableCell>
						<TableCell align="center">Auto renewal</TableCell>
						<TableCell className={styles.minWidthRemovalDate}></TableCell>
					</TableRow>
				</TableHead>
				<TableBody className={styles.tableBody}>
					{Object.entries(subscriptionsWithRemovalQuantities).map(
						([
							id,
							{
								quantity,
								removalQuantity: configuredRemovalQuantity,
								termDuration,
								unitPriceAfterDiscount,
								commitmentEndDate,
								autoRenewEnabled,
								refundableQuantity,
							},
						]) => {
							const expiresAfterThreshold = dayjs()
								.add(subscriptionExpirationThreshold, "days")
								.isBefore(commitmentEndDate);

							// If the subscription expires after the threshold, we don't want to allow the user to remove any licenses
							// If the subscription has no licenses left after scheduled removals, we don't want to allow the user to remove any licenses
							const numLicensesScheduledForRemovalOnSubscription =
								scheduledLicenseActions.reduce((acc, curr) => {
									if (
										curr.TargetGUID === id &&
										curr.TargetType === LicenseActionTargetType.Subscription
									) {
										return acc + Math.abs(curr.QuantityChange);
									}
									return acc;
								}, 0);

							// This is the maximum quantity that can be removed from one single subscription.
							// It will need to look into what we've already scheduled, in order to understand how many
							// thats "left for removal"
							const licenseQuantityAfterScheduledRemovals =
								quantity - numLicensesScheduledForRemovalOnSubscription;

							if (
								expiresAfterThreshold ||
								licenseQuantityAfterScheduledRemovals === 0
							) {
								return null;
							}

							let removalQuantity = configuredRemovalQuantity;
							// Ensure selected removal quantity is not greater than available for removal
							// This sometimes happen once a higher level component changes the availableForRemoval prop
							// Mostly the below code is a fix to remove the MUI warning that occurs when the select value is greater than the max value
							if (removalQuantity > licenseQuantityAfterScheduledRemovals) {
								removalQuantity = licenseQuantityAfterScheduledRemovals;
							}

							const maxSelectable = Math.min(
								availableForRemoval,
								licenseQuantityAfterScheduledRemovals,
							);

							const removalDate = calculateExecutionDateForRecommendedAction(
								commitmentEndDate,
								removalQuantity,
								refundableQuantity,
							);

							return (
								<TableRow key={id} className={styles.noBorderTableRow}>
									<TableCell>
										<SelectableQuantity
											id={id}
											removalQuantity={removalQuantity}
											handleQuantityChange={handleQuantityChange}
											maxSelectable={maxSelectable}
											refundableQuantity={refundableQuantity}
										/>
									</TableCell>
									<TableCell>
										<Typography variant="description" fontWeight={400}>
											<TermDurationLabel
												label={termDuration as TermDuration}
											/>
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="description" fontWeight={400}>
											{formatCostString(
												"",
												unitPriceAfterDiscount * 12,
												" kr / year",
											)}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<AutorenewStatus
											key={id}
											autoRenewEnabled={autoRenewEnabled}
											commitmentEndDate={commitmentEndDate}
										/>
									</TableCell>
									<TableCell>
										<Grid
											item
											className={clsx(styles.removalDate, {
												[styles.hide]: removalQuantity === 0,
											})}
										>
											<Typography variant="description">
												{removalQuantity > 0 && (
													<>
														{"Removal date"}
														<br />
														{dayjs(removalDate).format("DD.MM.YYYY")}
													</>
												)}
											</Typography>
										</Grid>
									</TableCell>
								</TableRow>
							);
						},
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
