import { useEffect, useMemo, useRef, useState } from "react";
import Chart from "chart.js/auto";
import dayjs from "dayjs";
import colors from "styles/utilities/_colors.scss";
import { SIGN_INS } from "data/mockResponses";
import styles from "./SignInsChart.module.scss";
import { capitalize } from "utilities/stringFormattingUtility";
import _ from "lodash";
import breakpoints from "styles/utilities/_breakpoints.scss";
import { useActionOnScreenTreshold } from "hooks/hooks";
import { LegendItem } from "components/Common/LegendItem";
import { LegendItemContainer } from "components/Common/LegendItemContainer";

const SignInsChart = ({ isLoading = false, data, settings, chartStyles }) => {
	let canvasRef = useRef(null);

	const [datasets, setDatasets] = useState([]);

	const ticksForSmallScreen = 5;
	const ticksForLargeScreen = 20;

	const [allowedTicksX, setAllowedTicksX] = useState(ticksForLargeScreen);
	const [isAboveThreshold, setIsAboveThreshold] = useState(true);

	const signInColors = useMemo(
		() => ({
			success: "#6cd699",
			failed: "#fd9a82",
			interrupted: "#f5d4ac",
		}),
		[],
	);

	useActionOnScreenTreshold(
		(isLargeScreen) => setIsAboveThreshold(isLargeScreen),
		breakpoints.sm,
	);

	useEffect(() => {
		const customColors = _.mapValues(signInColors, (color) =>
			isLoading ? colors.loading : color,
		);

		if (!isAboveThreshold && allowedTicksX > ticksForSmallScreen) {
			setAllowedTicksX(ticksForSmallScreen);
		} else if (isAboveThreshold && allowedTicksX === ticksForSmallScreen) {
			setAllowedTicksX(ticksForLargeScreen);
		}

		const dates = [];
		const successfulAttempts = [];
		const failedAttempts = [];
		const interruptedAttempts = [];

		const signInData = isLoading ? SIGN_INS : data ?? {};

		signInData?.AggregatedDateSignins.forEach((object) => {
			successfulAttempts.push(object.TotalSuccessLogons);

			failedAttempts.push(object.TotalFailedLogons);
			dates.push(dayjs.unix(parseInt(object.Date, 10)).format(settings?.dateFormat));
			interruptedAttempts.push(object.TotalInterruptedLogons);
		});

		const datasets = [
			{
				label: settings?.successLabelText,
				data: successfulAttempts,
				borderColor: customColors.success,
				backgroundColor: customColors.success,
				tension: 0.15,
			},
			{
				label: settings?.failedLabelText,
				data: failedAttempts,
				borderColor: customColors.failed,
				backgroundColor: customColors.failed,
				tension: 0.15,
			},
			{
				label: settings?.interruptedLabelText,
				data: interruptedAttempts,
				borderColor: customColors.interrupted,
				backgroundColor: customColors.interrupted,
				tension: 0.15,
			},
		];

		setDatasets(datasets);

		const chart = new Chart(canvasRef.current.getContext("2d"), {
			type: "line",
			data: {
				labels: dates,
				datasets,
			},
			options: {
				animation: {
					duration: isLoading ? 0 : 1000,
				},
				plugins: {
					legend: {
						display: false,
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					y: {
						grid: {
							color: colors.$gridLinesColor,
							lineWidth: 0.7,
						},
						border: {
							display: false,
						},
						beginAtZero: true,
						ticks: {
							display: !isLoading,
							font: {
								family: "Barlow",
								size: 12,
								weight: 400,
							},
							color: "#000000",
							maxTicksLimit: 8,
						},
					},
					x: {
						grid: {
							display: false,
						},
						ticks: {
							display: !isLoading,
							callback: function (value, index) {
								if (index === 0) {
									return null;
								}
								return this.getLabelForValue(value);
							},
							maxRotation: 0,
							minRotation: 0,
							color: "#000000",
							font: {
								family: "Barlow",
								size: 11,
								weight: 400,
							},
							maxTicksLimit: allowedTicksX,
						},
					},
				},
				elements: {
					line: {
						borderWidth: 2,
					},
					point: {
						pointHoverRadius: 5,
						pointBorderWidth: 1,
					},
				},
			},
		});

		chart.canvas.id = "securityDashboard";

		return () => chart.destroy();
	}, [data, settings, isLoading, signInColors, isAboveThreshold, allowedTicksX]);

	return (
		<>
			<div className={chartStyles}>
				<canvas ref={canvasRef} />
			</div>
			<LegendItemContainer alignWithMinWidth>
				{_(signInColors)
					.entries()
					.map(([name, color]) => (
						<LegendItem
							mainLabel={capitalize(name)}
							color={color}
							isLoading={isLoading}
							onClick={(event) => {
								const chart = Chart.getChart("securityDashboard");
								const index = datasets
									.filter(({ data }) => data.length > 0)
									.findIndex((bar) => bar.borderColor === color);
								const meta = chart?.getDatasetMeta(index);
								event.currentTarget.classList.toggle(styles.strikethrough);
								if (!chart || !meta || meta.data.length === 0) return;
								chart.getDatasetMeta(index).hidden
									? chart.show(index)
									: chart.hide(index);
							}}
							key={name}
						/>
					))
					.value()}
			</LegendItemContainer>
		</>
	);
};

export { SignInsChart };
