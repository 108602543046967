import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchServiceStatuses } from "actions/serviceStatusActions";
import { RootState } from "store";
import { Monitor, ServiceStatusGroup } from "types";

const serviceStatusesAdapter = createEntityAdapter<ServiceStatusGroup>();

const serviceStatusesSlice = createSlice({
	name: "serviceStatuses",
	initialState: serviceStatusesAdapter.getInitialState({ isLoading: true, isFetching: false }),
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchServiceStatuses.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchServiceStatuses.fulfilled, (state, { payload }) => {
				serviceStatusesAdapter.setAll(
					state,
					payload.map((status, id) => ({ id, ...status })),
				);
				state.isFetching = false;
				state.isLoading = false;
			})
			.addCase(fetchServiceStatuses.rejected, (state) => {
				serviceStatusesAdapter.setAll(state, [
					{
						name: "Status system not set up",
						groupState: "",
						monitors: [] as Monitor[],
					} as ServiceStatusGroup,
				]);
				state.isFetching = false;
				state.isLoading = false;
			});
	},
});

export const selectServiceStatuses = (state: RootState) => state.serviceStatuses;

export const serviceStatusSelectors =
	serviceStatusesAdapter.getSelectors<RootState>(selectServiceStatuses);

export default serviceStatusesSlice.reducer;
