import { createSlice } from "@reduxjs/toolkit";
import { fetchAggregatedData } from "actions/aggregatedDataActions";
import { RootState } from "store";

const aggregatedDataSlice = createSlice({
	name: "aggregatedData",
	initialState: { isLoading: true, isFetching: false },
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAggregatedData.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchAggregatedData.fulfilled, (state, { payload }) => ({
				...state,
				...payload,
				isLoading: false,
				isFetching: false,
			}));
	},
});

export const selectAggregatedData = (state: RootState) => state.aggregatedData;

export default aggregatedDataSlice.reducer;
