import { useEffect, useState } from "react";
import { SettingUpPortalDialog } from "./SettingUpPortalDialog";
import { ConsentDialog } from "./ConsentDialog";
import { successfulConsentFlow } from "utilities/consentUtils/consentFlow";
import { isGa } from "utilities/tokenUtils/getValuesFromToken";
import { handleLogout } from "utilities/handleLogout";
import { ensureEnterpriseAppApiPermissions } from "components/api/ensureEnterpriseAppApiPermissions";
import { ensureAzureApiAccess } from "components/api/ensureAzureApiAccess";
import { ensureAnyPortalAdmins } from "components/api/roles";
import { getServicePrincipal } from "components/api/servicePrincipal";
import { useAuth } from "hooks/hooks";
import { logHotjarEvent } from "utilities/hotjar/logHotjarEvent";
import { SCOPES } from "utilities/constants/constants";
import { HotjarEventTypes } from "utilities/constants/enums";

/*
 * This component is responsible for ensuring that everything is setup correctly.
 * 1. Ensure that the portal is in the tenant
 * 2. Ensure that the portal has the correct permissions
 * 3. Ensure that the portal has at least one portal admin
 *
 * The code is quite convoluted, because:
 * We have to assume that the portal is in the tenant, and that the user has the correct permissions.
 * If we don't, we have to show a dialog to the user, and wait for them to take action.
 *
 * Keeping this in mind, we need to be *very* careful about what we're doing with the responses from the API.
 * We CANNOT assume that a failed API call means that the portal is not in the tenant, or that the user doesn't have the correct permissions.
 * Thus, we need to specifically check for the things we're looking for,
 * and only then assume that the portal is not in the tenant, or that the user doesn't have the correct permissions.
 */
const SetupDialogs = () => {
	const {
		auth: { instance, account, authConfig },
	} = useAuth();

	const [portalIsInTenant, setPortalIsInTenant] = useState(true);
	const [isMissingPermissions, setIsMissingPermissions] = useState(false);
	const [anyPortalAdmins, setAnyPortalAdmins] = useState(true);
	const [openSettingUpPortalDialog, setOpenSettingUpPortalDialog] = useState(false);
	const [userRoles, setUserRoles] = useState({
		userHasCorrectRoles: true,
		checkingUserRoles: true,
	});

	useEffect(() => {
		const checkGa = async () => {
			const azureToken = await instance.acquireTokenSilent({
				scopes: [SCOPES.GRAPH_READ],
				account,
			});

			const hasAdminRole = isGa(azureToken.accessToken);

			setUserRoles({
				userHasCorrectRoles: hasAdminRole,
				checkingUserRoles: false,
			});
		};

		if (authConfig && instance) {
			checkGa();
		}
	}, [authConfig, instance, account]);

	useEffect(() => {
		if (authConfig && instance) {
			getServicePrincipal({
				instance,
				account,
				authConfig,
				appId: instance.getConfiguration().auth.clientId,
			})
				.then((servicePrincipal) => {
					if (!servicePrincipal) {
						setPortalIsInTenant(false);
						setAnyPortalAdmins(false);
						return;
					}

					// If we found a service principal, we can assume that the portal is in the tenant
					setPortalIsInTenant(true);
					// We need to check if we have any portal admins
					ensureAnyPortalAdmins(instance, account, authConfig)
						.then((anyAdmins) => {
							setAnyPortalAdmins(anyAdmins);
						})
						.catch(() => {
							// In the case that this call fails,
							// We keep state as is, i.e assume that the portal is in the tenant
						});
				})
				.catch(() => {
					// In the case that this call fails error,
					// We keep state as is, i.e assume that the portal is in the tenant
				});

			/* Only run the reconsent flow if we are not coming from a successful one already. */
			if (!successfulConsentFlow(window.location.hash)) {
				forceReconsentIfWrongAppPermissions();
			}

			// Ensure access to tenant
			ensureAzureApiAccess(instance, account, authConfig);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Force new Enterprise Application consent for customers that have missing permissions
	const forceReconsentIfWrongAppPermissions = async () => {
		const ensureEnterpriseAppApiPermissionsResponse = await ensureEnterpriseAppApiPermissions(
			instance,
			account,
			authConfig,
		);

		setIsMissingPermissions(
			(ensureEnterpriseAppApiPermissionsResponse?.missingPermissions ?? []).length > 0,
		);
	};

	const shouldShowConsentDialog = () => {
		if (successfulConsentFlow(window.location.hash)) {
			return false;
		}

		if (!portalIsInTenant) {
			return true;
		}

		if (isMissingPermissions) {
			return true;
		}

		return false;
	};

	const shouldShowSettingUpPortalDialog = () => {
		if (openSettingUpPortalDialog) {
			return true;
		}

		if (!anyPortalAdmins || !portalIsInTenant) {
			setOpenSettingUpPortalDialog(true);
			return true;
		}

		return false;
	};

	const renderDialog = () => {
		if (shouldShowConsentDialog()) {
			return (
				<ConsentDialog
					portalIsInTenant={portalIsInTenant}
					isMissingPermissions={isMissingPermissions}
					userRoles={userRoles}
					redirectUrl={authConfig.ADMIN_CONSENT_URL}
					onLogout={onLogout}
				/>
			);
		}

		if (shouldShowSettingUpPortalDialog()) {
			return (
				<>
					{logHotjarEvent({
						eventType: HotjarEventTypes.SHOW,
						eventText: "setting_up_portal_dialog",
					})}
					<SettingUpPortalDialog
						isMissingPermissions={isMissingPermissions}
						userRoles={userRoles}
						setOpenSettingUpPortalDialog={setOpenSettingUpPortalDialog}
						setPortalIsInTenant={setPortalIsInTenant}
						setAnyPortalAdmins={setAnyPortalAdmins}
					/>
				</>
			);
		}
	};

	const onLogout = () => {
		handleLogout({ account, instance });
	};

	return <>{renderDialog()}</>;
};

export { SetupDialogs };
