import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchLicenseNames } from "actions/licenseNameActions";
import { RootState } from "store";
import { LicenseName, LicenseNameDTO } from "types";

export const licenseNameAdapter = createEntityAdapter<LicenseName>({
	selectId: (licenseName) => licenseName.skuId,
});

const initialState = licenseNameAdapter.getInitialState({
	isLoading: true,
	isFetching: false,
});

const licenseNameSlice = createSlice({
	name: "licenseNames",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLicenseNames.pending, (state) => {
				state.isFetching = true;
			})
			.addCase(fetchLicenseNames.fulfilled, (state, { payload }) => {
				const licenseNames = maplicenseNames(payload);
				licenseNameAdapter.setAll(state, licenseNames);
				state.isLoading = false;
				state.isFetching = false;
			});
	},
});

const maplicenseNames = (licenseNames: LicenseNameDTO[]): LicenseName[] =>
	licenseNames.map((licenseName) => ({
		licenseDisplayName: licenseName.Product_Display_Name,
		skuId: licenseName.GUID,
		skuPartNumber: licenseName.String_Id,
	}));

export const selectlicenseNames = (state: RootState) => state.licenseNames;

export const licenseNameSelectors = licenseNameAdapter.getSelectors(selectlicenseNames);

export default licenseNameSlice.reducer;
