import { HardwareApprover, HardwareApproverObject, User } from "types";
import { ApproverType } from "./constants/enums";

// Checks two things:
// 1. If the user has a manager, and that manager is configured as a hardware approver, the function returns the manager as the approver.
// 2. If the user doesn't have a manager approver, the function returns the global hardware approvers.
export const getApprovers = (manager: User, hardwareApprovers: HardwareApproverObject) => {
	let approvers = [] as HardwareApprover[];
	let message = "";
	const globalHardwareApprovers = hardwareApprovers?.global ?? [];
	const managerHardwareApprovers = hardwareApprovers?.manager ?? [];
	const userHasManagerApprover =
		manager &&
		managerHardwareApprovers.length > 0 &&
		managerHardwareApprovers.some((approver) => approver.id === manager.id);

	if (globalHardwareApprovers.length > 0) {
		message = `Message to approver${globalHardwareApprovers.length > 1 ? "s" : ""}: `;
		approvers = globalHardwareApprovers.map((approver) => ({
			...approver,
			approverType: ApproverType.Global,
		}));
	}

	if (userHasManagerApprover) {
		message = `Message to approver ${
			manager.displayName?.replace(/\b\w/g, (c) => c.toUpperCase()) ?? ""
		}`;
		approvers = [
			...approvers,
			{
				id: manager.id,
				displayName: manager.displayName,
				mail: manager.mail,
				phone: manager.mobilePhone,
				approverType: ApproverType.Manager,
			},
		];
	}

	if (globalHardwareApprovers.length === 0 && !userHasManagerApprover) {
		message =
			"There are no hardware approvers that can handle your request. Please contact your administrator.";
	}

	return {
		message,
		approvers,
	};
};
